<template>
  <architecture-data-table
    :title="$t('string.hasBoardEngineer')"
    :permission-create="Permissions.backend.hasBoard.engineer.store"
    :permission-filter="Permissions.backend.hasBoard.engineer.filter"
    model-route-create="create-has-board-engineer"
    model-route-edit="edit-has-board-engineer"
    model-route-view="show-has-board-engineer"
    :architecture-category-id="[2]"
    :architecture-type-id="[2]"
  />
</template>

<script>
import ArchitectureDataTable from "../../architecture/Table"
import Permissions from "@/permissions"
export default {
  name: "List",
  components: {ArchitectureDataTable},
  metaInfo() {
    return {
      title: this.$t('string.hasBoardEngineer'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      Permissions
    }
  }
}
</script>

<style scoped>

</style>
