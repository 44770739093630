<template>
  <div
    id="activityLogsModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="activityLogsModalLabel"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div
      class="modal-dialog tw-w-[980px]"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4
            id="activityLogsModalLabel"
            class="modal-title"
          >
            {{ $t('string.activity_logs_detail') }}
          </h4>
        </div>
        <div class="modal-body">
          <template v-if="!log">
            <div>
              <p>{{ $t('string.no_log') }}</p>
            </div>
          </template>
          <template v-else>
            <template v-if="isLoading">
              <div>
                <p>{{ $t('string.loading') }}</p>
              </div>
            </template>
            <template v-else>
              <template v-if="logDetail">
                <div class="table-responsive">
                  <table class="table table-bordered table-condensed table-hover table-striped">
                    <tbody>
                      <tr>
                        <th class="tw-[200px]">
                          {{ $t('log.subject_type') }}
                        </th>
                        <td>{{ logDetail.subject_type }}</td>
                      </tr>
                      <tr>
                        <th class="tw-[200px]">
                          {{ $t('log.action') }}
                        </th>
                        <td>{{ logDetail.log_name }}</td>
                      </tr>
                      <tr>
                        <th class="tw-[200px]">
                          {{ $t('log.description') }}
                        </th>
                        <td>{{ logDetail.description }}</td>
                      </tr>
                      <tr>
                        <th class="tw-[200px]">
                          {{ $t('log.properties') }}
                        </th>
                        <td>
                          <vue-code-highlight>
                            {{ logDetail.properties || $t('string.na') }}
                          </vue-code-highlight>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </template>
              <template v-else>
                <div>
                  <p>{{ $t('string.log_was_not_found') }}</p>
                </div>
              </template>
            </template>
          </template>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
            @click="closeModal"
          >
            {{ $t('button.close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields"
import {component as VueCodeHighlight} from 'vue-code-highlight'

export default {
  name: "ActivityLogsDetailModal",
  components: {VueCodeHighlight},
  data () {
    return {
      logDetail: {
        subject_type: null,
        log_name: null,
        description: null,
        properties: null,
      },
      isLoading: false,
    }
  },
  computed: {
    ...mapFields('activityLogs', {
      itemLog: 'log'
    })
  },
  watch: {
    itemLog () {
      if (this.itemLog) {
        this.getLogDetail()
      }
    }
  },
  methods: {
    getLogDetail () {
      this.isLoading = true
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/activity-log/show', {
        id: this.itemLog.id
      }).then(({data}) => {
        if (data.data) {
          this.logDetail = data.data
        }
      }).catch((error) => {
        if (error.response && error.response.status && error.response.status === 422) {
          this.validations = error.response.data.errors
        } else {
          this.onResponseError(error)
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
    closeModal() {
      $('#activityLogsModal').modal('hide')
      this.itemLog = null
    }
  }
}
</script>

<style scoped>
.modal-body {
  height: 82vh;
  overflow:auto;
}
</style>
