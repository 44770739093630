import Permissions from "@/permissions"
import Index from '@/views/businessBuilders/studyPlanCompany/Index'
import Edit from '@/views/businessBuilders/studyPlanCompany/Edit'
import Show from '@/views/businessBuilders/studyPlanCompany/Show'
import List from '@/views/businessBuilders/studyPlanCompany/List'
import Create from '@/views/businessBuilders/studyPlanCompany/Create'
import ChangeCategory from '@/views/company/ChangeCategory'

export const studyPlanCompanyRouters = {
  path: 'study-plan-company',
  name: 'study-plan-company',
  component: Index,
  redirect: 'study-plan-company',
  meta: {
    permissions: [Permissions.backend.businessBuilders.studyPlanCompany.manage],
    groups: 'study-plan-company',
  },
  children: [
    {
      path: '',
      name: 'list-study-plan-company',
      component: List,
      meta: {
        permissions: [Permissions.backend.businessBuilders.studyPlanCompany.list],
        groups: 'study-plan-company',
        breadcrumb: [
          {
            name_en: 'Construction Certifier License',
            name_km: ' អាជ្ញាបណ្ណក្នុងវិស័យសំណង់'
          },
          {
            name_en: 'Study Plan Company',
            name_km: 'អាជ្ញាបណ្ណសិក្សាគម្រោងប្លង់'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-study-plan-company',
      component: Create,
      meta: {
        permissions: [Permissions.backend.businessBuilders.studyPlanCompany.store],
        groups: 'study-plan-company',
        breadcrumb: [
          {
            name_en: 'Construction Certifier License',
            name_km: ' អាជ្ញាបណ្ណក្នុងវិស័យសំណង់'
          },
          {
            name_en: 'Study Plan Company',
            name_km: 'អាជ្ញាបណ្ណសិក្សាគម្រោងប្លង់'
          },
          {
            name_en: 'Create',
            name_km: 'បន្ថែមថ្មី'
          }
        ]
      }
    },
    {
      path: 'show/:company_uuid',
      name: 'show-study-plan-company',
      component: Show,
      meta: {
        permissions: [Permissions.backend.businessBuilders.studyPlanCompany.show],
        groups: 'study-plan-company',
        breadcrumb: [
          {
            name_en: 'Construction Certifier License',
            name_km: ' អាជ្ញាបណ្ណក្នុងវិស័យសំណង់'
          },
          {
            name_en: 'Study Plan Company',
            name_km: 'អាជ្ញាបណ្ណសិក្សាគម្រោងប្លង់'
          },
          {
            name_en: 'Show',
            name_km: 'មើល'
          }
        ]
      }
    },
    {
      path: 'edit/:company_uuid',
      name: 'edit-study-plan-company',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.businessBuilders.studyPlanCompany.edit],
        groups: 'study-plan-company',
        breadcrumb: [
          {
            name_en: 'Construction Certifier License',
            name_km: ' អាជ្ញាបណ្ណក្នុងវិស័យសំណង់'
          },
          {
            name_en: 'Study Plan Company',
            name_km: 'អាជ្ញាបណ្ណសិក្សាគម្រោងប្លង់'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'change-category/:company_uuid',
      name: 'change-category-study-plan-company',
      component: ChangeCategory,
      meta: {
        permissions: [Permissions.backend.businessBuilders.studyPlanCompany.changeCategory],
        groups: 'study-plan-company',
        breadcrumb: [
          {
            name_en: 'Construction Certifier License',
            name_km: ' អាជ្ញាបណ្ណក្នុងវិស័យសំណង់'
          },
          {
            name_en: 'Study Plan Company',
            name_km: 'អាជ្ញាបណ្ណសិក្សាគម្រោងប្លង់'
          },
          {
            name_en: 'Change Category',
            name_km: 'កែប្រែប្រភេទ'
          }
        ]
      }
    },
  ]
}
