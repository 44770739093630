<template>
  <div
    v-if="allow"
    class="margin-r-5"
  >
    <span class="main-title">{{ $t('string.aboutColor') }}</span>
    <tooltip-info>
      <h3 class="box-title">
        {{ $t('color_guideline.colorGuideline') }}:
      </h3>
      <div class="margin-top-5">
        <template v-if="colors!==null">
          <ul
            type="none"
            style="padding: 0;"
          >
            <li>
              <i
                class="far fa-circle"
                :style="`color: ${colors.new};`"
              /> ៖ {{ $t('color_guideline.new') }}
            </li>
            <li>
              <i
                class="fas fa-circle"
                :style="`color: ${colors.permit};`"
              /> ៖ {{ $t('color_guideline.permit') }}
            </li>
            <li>
              <i
                class="fas fa-circle"
                :style="`color: ${colors.open};`"
              /> ៖ {{ $t('color_guideline.open') }}
            </li>
            <li>
              <i
                class="fas fa-circle"
                :style="`color: ${colors.stop};`"
              /> ៖ {{ $t('color_guideline.stop') }}
            </li>
            <li>
              <i
                class="fas fa-circle"
                :style="`color: ${colors.pending};`"
              /> ៖ {{ $t('color_guideline.pending') }}
            </li>
          </ul>
        </template>
        <template v-else>
          {{ $t('string.noColorDefined') }}
        </template>
      </div>
    </tooltip-info>
  </div>
</template>

<script>
import TooltipInfo from "./TooltipInfo"

export default {
  name: "ProjectColorTip",
  components: {TooltipInfo},
  data() {
    return {
      colors: null,
    }
  },
  computed: {
    allow() {
      let permissions = [
        this.$Permissions.backend.provinceProject.showColorTip,
        this.$Permissions.backend.project.showColorTip,
      ]
      return this.can(permissions)
    }
  },
  mounted() {
    this.getColors()
  },
  methods: {
    getColors() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project/get-colors')
        .then((response) => {
          this.colors = response.data.data
        })
    }
  }
}
</script>

<style scoped>

</style>
