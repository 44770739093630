<template>
  <div class="row">
    <template v-if="!readonly">
      <div class="col-md-12 margin-bottom">
        <input
          ref="docs"
          type="file"
          style="display: none;"
          :accept="acceptFile"
          multiple
          @change="uploadImage"
        >

        <button
          class="btn btn-primary btn-md margin-r-5"
          @click="$refs.docs.click()"
        >
          <i :class="buttonIcon" /> {{ buttonLabel }}
        </button>
        <button
          v-if="buttonRefresh"
          class="btn btn-info btn-md margin-r-5"
          @click="getImages"
        >
          <i class="fas fa-refresh" /> {{ $t('string.refresh') }}
        </button>
        <template v-if="draggable">
          <button
            class="btn btn-success btn-md margin-r-5"
            @click="updateOrder(mediaImages)"
          >
            {{ $t('string.updateOrder') }}
          </button>
        </template>
      </div>
    </template>
    <div class="col-md-12">
      <div class="row">
        <template v-if="mediaImages.length > 0">
          <template v-if="draggable">
            <draggable
              v-model="mediaImages"
              class="list-group"
              v-bind="dragOptions"
              @start="isDragging = true"
              @end="isDragging = false"
            >
              <transition-group
                type="transition"
                name="flip-list"
              >
                <template v-for="(doc, key) in mediaImages">
                  <upload-media-preview
                    :key="key"
                    :doc="doc"
                    :copiable="copiable"
                    :copy-to-model-type="copyToModelType"
                    :copy-to-model-path="copyToModelPath"
                    :model-name="modelName"
                    :model-uuid="modelUuid"
                    :readonly="readonly"
                    :class-column="column"
                    @fetchData="getImages"
                  />
                </template>
              </transition-group>
            </draggable>
          </template>
          <template v-else>
            <template v-for="(doc, key) in mediaImages">
              <upload-media-preview
                :key="key"
                :doc="doc"
                :readonly="readonly"
                :copiable="copiable"
                :copy-to-model-type="copyToModelType"
                :copy-to-model-path="copyToModelPath"
                :model-name="modelName"
                :model-uuid="modelUuid"
                :class-column="column"
                @fetchData="getImages"
              />
            </template>
          </template>
        </template>
        <template v-else>
          <div class="col-md-12">
            <p class="text-center">
              {{ $t('string.noResult') }}
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import UploadMediaPreview from "./UploadMediaPreview"

export default {
  name: "UploadMediaItem",
  components: {
    UploadMediaPreview,
    draggable
  },
  props: {
    modelName: {
      type: String,
      required: true
    },
    modelUuid: {
      type: String,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    acceptFile: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: '10240',
    },
    column: {
      type: String,
      default: 'col-md-3 col-xs-6 margin-bottom'
    },
    draggable: {
      type: Boolean,
      default: false
    },
    buttonLabel: {
      type: String,
      default () {
        return this.$t('string.choose')
      }
    },
    buttonIcon: {
      type: String,
      default: 'fa fa-image'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    copiable: {
      type: Boolean,
      default: false
    },
    copyToModelType: {
      type: String,
      default: null
    },
    copyToModelPath: {
      type: String,
      default: null,
    },
    buttonRefresh: {
      type: Boolean,
      default: false,
    },
    buttonDeleteAll: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      mediaImages: [],
      validations: null,
      isDragging: false
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    }
  },
  created() {
    this.getImages()
  },
  methods: {
    uploadImage() {
      let params = new FormData()
      if (this.$refs.docs.files.length > 0) {
        Array.from(this.$refs.docs.files).forEach((file, key) => {
          params.set(`document[${key}]`, file)
        })
      }
      params.set('model_uuid', this.modelUuid)
      params.set('model_name', this.modelName)
      params.set('path', this.path)
      params.set('type', this.type)
      params.set('size', this.size)
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/media/upload', params)
        .then((res) => {
          if (res.data.data) {
            this.showToastr()
            this.mediaImages = res.data.data
          } else {
            this.mediaImages = []
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
            this.$toastr('error', this.validations['document'][0], this.$t('string.error'))
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getImages () {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/media/get-images-option', {
        type: this.type,
        model_uuid: this.modelUuid,
        model_name: this.modelName,
      }).then((res) => {
        if (res.data.data) {
          this.mediaImages = res.data.data
        } else {
          this.mediaImages = []
        }
      }).catch((error) => {
        this.onResponseError(error)
      })
    },
    updateOrder(media) {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/media/update-order-images', {
        media: media
      }).then(() => {
        this.showToastr()
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
  }
}
</script>

<style scoped>
  .file {
    width: 100%;
    position: relative;
    height: auto;
    border: 1px solid #0d6aad;
  }

  .file-header {
    background-color: #0d6aad;
    padding: 15px;
    color: #ffffff;
  }

  .file-footer {
    visibility: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35px;
    background-color: #dddddd;
    padding: 6px;
  }

  .file:hover .file-footer {
    visibility: visible;
  }

  .file-body {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
</style>
