<template>
  <div class="row">
    <construction-info-panel :project="project" />
    <construction-address-panel :project="project" />
    <architectural-panel :project="project" />
    <construction-unit-panel :project="project" />
    <parking-station-panel :parking="getParking" />
    <investment-info-panel :project="project" />
    <permit-panel :project="project" />
    <engineering-panel :project="project" />
    <builder-information-panel :project="project" />
    <regulation-gap-panel :regulation-gaps="project.regulation_gaps" />
    <sector-permit-panel :project="project" />
  </div>
</template>

<script>
import {integrationMixins} from "../../mixins/integration"
import {optionsMixin} from "../../mixins/options"
import RegulationGapPanel from "./panels/RegulationGapPanel"
import ParkingStationPanel from "./panels/ParkingStationPanel"
import EngineeringPanel from "./panels/EngineeringPanel"
import ConstructionInfoPanel from "./panels/ConstructionInfoPanel"
import BuilderInformationPanel from "./panels/BuilderInformationPanel"
import ArchitecturalPanel from "./panels/ArchitecturalPanel"
import ConstructionAddressPanel from "./panels/ConstructionAddressPanel"
import ConstructionUnitPanel from "./panels/ConstructionUnitPanel"
import InvestmentInfoPanel from "./panels/InvestmentInfoPanel"
import PermitPanel from "./panels/PermitPanel"
import SectorPermitPanel from "./panels/SectorPermitPanel"

export default {
  name: "ProjectBoxInfo",
  components: {
    SectorPermitPanel,
    PermitPanel,
    InvestmentInfoPanel,
    ConstructionUnitPanel,
    ConstructionAddressPanel,
    ArchitecturalPanel,
    BuilderInformationPanel,
    ConstructionInfoPanel,
    EngineeringPanel,
    ParkingStationPanel,
    RegulationGapPanel
  },
  mixins: [integrationMixins, optionsMixin],
  props: {
    project: {
      type: Object,
      required: true,
    }
  },
  computed: {
    getParking() {
      return {
        number_parking_bike_permit: this.project.number_parking_bike_permit,
        number_parking_bike_request: this.project.number_parking_bike_request,
        number_parking_bike_practical: this.project.number_parking_bike_practical,
        number_parking_motor_permit: this.project.number_parking_motor_permit,
        number_parking_motor_request: this.project.number_parking_motor_request,
        number_parking_motor_practical: this.project.number_parking_motor_practical,
        number_parking_small_car_permit: this.project.number_parking_small_car_permit,
        number_parking_small_car_request: this.project.number_parking_small_car_request,
        number_parking_small_car_practical: this.project.number_parking_small_car_practical,
        number_parking_big_car_permit: this.project.number_parking_big_car_permit,
        number_parking_big_car_request: this.project.number_parking_big_car_request,
        number_parking_big_car_practical: this.project.number_parking_big_car_practical,
      }
    },
  }
}
</script>

<style scoped>

</style>
