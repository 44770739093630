<template>
  <div class="empty-data">
    <img
      :src="image"
      style="width: 25%; height: auto;"
      alt=""
    >
    <h2 class="text-center mb-5 font-secondary">
      {{ description }}
    </h2>
  </div>
</template>

<script>
export default {
  name: "EmptyData",
  props: {
    description: {
      type: String,
      default() {
        return this.$t('string.empty_data')
      }
    },
    image: {
      type: String,
      default: '/img/svgs/empty.svg'
    }
  }
}
</script>

<style scoped>
.empty-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
.empty-data img {
  margin-bottom: 15px;
}
</style>
