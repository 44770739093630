<template>
  <div
    id="carousel-example-generic"
    class="carousel slide"
    data-ride="carousel"
  >
    <ol class="carousel-indicators">
      <template v-for="(image, key) in images">
        <li
          :key="image"
          data-target="#carousel-example-generic"
          :data-slide-to="key"
          :class="{'active' : key === 0}"
        />
      </template>
    </ol>
    <div class="carousel-inner">
      <template v-for="(image, key) in images">
        <div
          :key="key + Math.random()"
          class="item"
          :class="{'active' : key === 0}"
        >
          <img
            :src="image.src"
            class="img-responsive"
            :alt="image.caption"
          >

          <div
            class="carousel-caption font-primary text-lg"
            v-html="image.caption"
          />
        </div>
      </template>
    </div>
    <a
      class="left carousel-control"
      href="#carousel-example-generic"
      data-slide="prev"
    >
      <span class="fa fa-angle-left" />
    </a>
    <a
      class="right carousel-control"
      href="#carousel-example-generic"
      data-slide="next"
    >
      <span class="fa fa-angle-right" />
    </a>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  props: {
    images: {
      type: Array,
      default: () => ([{
        id: 0,
        src: 'http://placehold.it/900x500/39CCCC/ffffff&amp;text=I+Love+Bootstrap',
        caption: 'One Slide'
      }])
    }
  }
}
</script>

<style scoped>
.carousel-caption.font-primary.text-lg {
  font-size: 2.8rem;
  text-shadow: 1px 2px 0 black;
}
</style>
