<template>
  <table
    v-if="constructionLetter!==null"
    class="table table-bordered"
  >
    <tbody>
      <tr>
        <td style="width: 130px;">
          {{ $t('string.constructionLetterNumber') }}
        </td>
        <td>{{ getNumbers(constructionLetter.number) || $t('string.na') }}</td>
      </tr>
      <tr>
        <td>{{ $t('string.constructionLetterDate') }}</td>
        <td>{{ getNumbers(getDate(constructionLetter.date, false, 'DD/MM/YYYY')) || $t('string') }}</td>
      </tr>
      <tr>
        <td>{{ $t('string.approvedBy') }}</td>
        <td>{{ constructionLetter.approved_by || $t('string.na') }}</td>
      </tr>
      <tr>
        <td>{{ $t('string.approvedAt') }}</td>
        <td>{{ getNumbers(getDate(constructionLetter.approved_at, false, 'DD/MM/YYYY hh:mm:ss A')) }}</td>
      </tr>
      <tr>
        <td>{{ $t('string.note') }}</td>
        <td>{{ constructionLetter.note || $t('string.na') }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "ConstructionLetterInfo",
  props: {
    constructionLetter: {
      type: Object,
      default: null,
    }
  },
}
</script>

<style scoped>

</style>
