<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('label.investmentBudget') }}
      </h3>
    </div>
    <div class="box-body">
      <table
        class="table table-bordered"
        style="margin-bottom: 15px !important;"
      >
        <thead>
          <tr>
            <th class="column-1" />
            <th class="column-other">
              {{ $t('label.total') }}
            </th>
            <th class="column-other">
              {{ $t('label.thisYear') }}
            </th>
            <th class="column-other">
              {{ $t('label.thisMonth') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ $t('label.investmentBudgetLocal') }}</td>
            <td>{{ investment.investment_budget_local }} $</td>
            <td>{{ investment.investment_budget_local_year }} $</td>
            <td>{{ investment.investment_budget_local_month }} $</td>
          </tr>
          <tr>
            <td>{{ $t('label.investmentBudgetOverseas') }}</td>
            <td>{{ investment.investment_budget_overseas }} $</td>
            <td>{{ investment.investment_budget_overseas_year }} $</td>
            <td>{{ investment.investment_budget_overseas_month }} $</td>
          </tr>
          <tr>
            <td>{{ $t('label.investmentBudgetTotal') }}</td>
            <td>{{ investment.investment_budget_total }} $</td>
            <td>{{ investment.investment_budget_total_year }} $</td>
            <td>{{ investment.investment_budget_total_month }} $</td>
          </tr>
        </tbody>
      </table>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th class="column-1">
              {{ $t('label.investmentBudgetByProjectType') }}
            </th>
            <th class="column-other">
              {{ $t('label.total') }}
            </th>
            <th class="column-other">
              {{ $t('label.thisYear') }}
            </th>
            <th class="column-other">
              {{ $t('label.thisMonth') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, key) in data">
            <tr :key="key">
              <td>{{ item['name_' + $i18n.locale] }}</td>
              <td>{{ item.investment_budget_total }}%</td>
              <td>{{ item.investment_budget_year }}%</td>
              <td>{{ item.investment_budget_month }}%</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListReportInvestmentBudget",
  props: {
    // eslint-disable-next-line vue/require-prop-types
    investment: {
      required: true
    },
    // eslint-disable-next-line vue/require-prop-types
    data: {
      required: true
    }
  }
}
</script>

<style scoped>
  .column-1 {
    width: 30%;
  }

  .column-other {
    width: 20%;
  }
</style>
