<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('label.editConstructionType') }}
      </h3>
      <div class="box-tools">
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <div class="form-horizontal">
        <div
          :class="'form-group row ' + (validations !== null && validations.hasOwnProperty('name_km') ? 'has-error' : '')"
        >
          <label class="col-sm-3 control-label required">{{ $t('label.nameKm') }}</label>
          <div class="col-sm-9">
            <input
              v-model="constructionType.name_km"
              :placeholder="$t('label.nameKm')"
              class="form-control"
              type="text"
            >
            <span
              v-if="validations !== null && validations.hasOwnProperty('name_km')"
              class="label-error"
            >
              {{ validations['name_km'][0] }}
            </span>
          </div>
        </div>

        <div
          :class="'form-group row ' + (validations !== null && validations.hasOwnProperty('name_en') ? 'has-error' : '')"
        >
          <label class="col-sm-3 control-label required">{{ $t('label.nameEn') }}</label>
          <div class="col-sm-9">
            <input
              v-model="constructionType.name_en"
              :placeholder="$t('label.nameEn')"
              class="form-control"
              type="text"
            >
            <span
              v-if="validations !== null && validations.hasOwnProperty('name_en')"
              class="label-error"
            >
              {{ validations['name_en'][0] }}
            </span>
          </div>
        </div>

        <!--<div
          :class="'form-group row ' + (validations !== null && validations.hasOwnProperty('project_type_id') ? 'has-error' : '')">
          <label class="col-sm-3 control-label required">{{ $t('label.projectType') }}</label>
          <div class="col-sm-9">
            <select class="form-control"
                    v-if="projectTypes.length>0"
                    v-model="constructionType.project_type_id">
              <option selected disabled value="0">{{ $t('label.projectType') }}</option>
              <option v-for="(projectType, key) in projectTypes"
                      :value="projectType.id"
                      :key="key">
                {{ projectType['name_' + $i18n.locale ]}}
              </option>
            </select>
            <span class="label-error"
                  v-if="validations !== null && validations.hasOwnProperty('project_type_id')">
                {{  validations['project_type_id'][0] }}
              </span>
          </div>
        </div>-->

        <div class="form-group row">
          <div class="col-sm-9 col-sm-offset-3">
            <button
              class="btn btn-info btn-sm"
              @click="newConstructionType"
            >
              {{ $t('button.update') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Edit",
  metaInfo() {
    return {
      title: this.$t('label.editConstructionType'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      constructionType: {},
      validations: null,
      projectTypes: [],
    }
  },
  created() {
    this.getConstructionType()
    this.getProjectTypes()
  },
  methods: {
    newConstructionType() {
      this.constructionType.construction_type_uuid = this.constructionType.uuid
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/construction-type/update', this.constructionType)
        .then(() => {
          this.showToastr()
          this.$router.push({name: 'list-construction-type'})
        })
        .catch((error) => {
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
          } else {
            this.validations = null
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getConstructionType() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/construction-type/show', {
        construction_type_uuid: this.$route.params.construction_type_uuid,
      }).then((res) => {
        this.constructionType = res.data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-construction-type'})
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    getProjectTypes() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-type/get-option')
        .then((res) => {
          if (res.data.data) {
            this.projectTypes = res.data.data
          } else {
            this.projectTypes = []
          }
        }).catch((error) => {
          this.onResponseError(error)
        })
    },
  }
}
</script>

<style scoped>

</style>
