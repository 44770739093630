<template>
  <show-architecture
    model-route-edit="edit-has-board-engineer"
    :edit-permission="$Permissions.backend.hasBoard.architecture.edit"
    :title="$t('string.showHasBoardEngineer')"
  />
</template>

<script>
import ShowArchitecture from "../../architecture/Show"

export default {
  name: "Show",
  components: {ShowArchitecture},
  metaInfo() {
    return {
      title: this.$t('string.showHasBoardEngineer'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
}
</script>

<style scoped>

</style>
