<template>
  <div class="col-md-12">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t('string.addressSection') }}
        </h3>
      </div>
      <div class="box-body">
        <div class="row">
          <div class="col-md-12 margin-bottom">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>{{ $t('label.landUniqueType') }}</th>
                  <th>{{ $t('label.landUniqueNumber') }}</th>
                  <th>{{ $t('label.landUniqueNumberRegistrationDate') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="project.land_unique_numbers && project.land_unique_numbers.length>0">
                  <tr
                    v-for="(item, key) in project.land_unique_numbers"
                    :key="key"
                  >
                    <td>
                      <template v-if="item.land_unique_number_type_id!==null">
                        {{ item.land_unique_number_type['name_' + $i18n.locale] }}
                      </template>
                      <template v-else>
                        -
                      </template>
                    </td>
                    <td>{{ item.number }}</td>
                    <td>{{ getDate(item.date, false, 'DD/MM/YYYY') }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="4">
                      <p class="text-center">
                        {{ $t('string.noRecord') }}
                      </p>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div class="col-md-12">
            <table class="table table-condensed table-striped table-bordered">
              <tbody>
                <tr>
                  <th>{{ $t('label.village') }}</th>
                  <td>
                    {{ project.village ? project.village['name_' + $i18n.locale] : $t('string.na') }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('label.commune') }}</th>
                  <td>
                    {{ project.commune ? project.commune['name_' + $i18n.locale] : $t('string.na') }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('label.district') }}</th>
                  <td>
                    {{ project.district ? project.district['name_' + $i18n.locale] : $t('string.na') }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('label.province') }}</th>
                  <td>
                    {{ project.province ? project.province['name_' + $i18n.locale] : $t('string.na') }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('label.lat') }}</th>
                  <td>
                    {{ project.lat ? project.lat : $t('string.na') }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('label.lng') }}</th>
                  <td>
                    {{ project.lng ? project.lng : $t('string.na') }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('label.x') }}</th>
                  <td>
                    {{ project.x ? project.x : $t('string.na') }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('label.y') }}</th>
                  <td>
                    {{ project.y ? project.y : $t('string.na') }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-12">
            <div class="thumbnail">
              <GmapMap
                :center="{lat:project.lat, lng:project.lng}"
                :zoom="15"
                map-type-id="terrain"
                style="width: 100%; height: 230px"
              >
                <GmapMarker
                  :icon="{url: '/img/markers/blue20x20.png'}"
                  :position="{lat:project.lat, lng:project.lng}"
                />
              </GmapMap>
            </div>
          </div>
          <div class="col-md-12">
            <div>
              <template v-if="project.lat && project.lng">
                <a
                  :href="`https://maps.google.com/?q=${project.lat},${project.lng}`"
                  target="_blank"
                >
                  <i class="fa fa-map-marker-alt" />
                  {{ project.address1 ? project.address1 : $t('string.na') }}
                </a>
              </template>
              <template v-else>
                {{ $t('string.na') }}
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConstructionAddressPanel",
  props: {
    project: {
      type: Object,
    }
  }
}
</script>

<style scoped>

</style>
