<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.createCompany') }}
      </h3>
    </div>
    <div class="box-body">
      <registration-company-form />
    </div>
  </div>
</template>

<script>
import RegistrationCompanyForm from "./Form"

export default {
  name: "Create",
  components: {RegistrationCompanyForm},
  metaInfo() {
    return {
      title: this.$t('string.createCompany'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  }
}
</script>

<style scoped>

</style>
