<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title main-title">
            {{ $t('string.welcomeTitle') }}
          </h3>
        </div>
        <div class="box-body">
          <p style="font-weight: bold;">
            {{ $t('string.welcome_description') }}
          </p>
          <div v-if="userProvinces && Array.isArray(userProvinces) && userProvinces.length > 0">
            <ul>
              <li
                v-for="(province, key) in userProvinces"
                :key="key"
              >
                <template v-if="current_province && current_province === province.id">
                  <p style="font-size: 18px; font-weight: bold; font-family: var(--secondary-font);">
                    {{ province['name_' + $i18n.locale] }}  ({{ $t('string.current') }})
                  </p>
                </template>
                <template v-else>
                  {{ province['name_' + $i18n.locale] }}
                </template>
              </li>
            </ul>
          </div>
          <div v-else>
            <p>{{ $t('string.please_contact_administration_to_assign_province_to_you') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12">
      <div class="box box-primary">
        <div class="box-body">
          <carousel :images="images" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel"
import {mapGetters} from "vuex"

export default {
  name: "Welcome",
  components: {Carousel},
  metaInfo() {
    return {
      title: this.$t('string.dashboard'),
      titleTemplate: '%s | ' + this.$t('string.appName')
    }
  },
  data() {
    return {
      images: [
        {
          id: 0,
          src: '/slideshows/23.jpg',
          caption: 'ឯកឧត្តមឧបនាយករដ្ឋមន្រ្តី ជា សុផារ៉ា ចុះពិនិត្យការដ្ឋានសាងសង់ផ្លូវក្រុងព្រះសីហនុ'
        },
        {
          id: 1,
          src: '/slideshows/See Construction Road -1.jpg',
          caption: 'ឯកឧត្តមឧបនាយករដ្ឋមន្រ្តី ជា សុផារ៉ា ពិនិត្យការសាងសង់ហេដ្ឋារចនាសម្ព័ន្ធ នៅក្រុងព្រះសីហនុ ខេត្តព្រះសីហនុ'
        },
        {
          id: 2,
          src: '/slideshows/Construction road 34 -1.jpg',
          caption: 'ពិធីបើកការដ្ឋានស្ថាបនាផ្លូវ ៣៤ខែ្ស នៅក្រុងព្រះសីហនុ ខេត្តព្រះសីហនុ'
        },
        {
          id: 3,
          src: '/slideshows/Untitled-2.jpg',
          caption: 'ឯកឧត្តមឧបនាយករដ្ឋមមន្រ្តី ជា សុផារ៉ា អញ្ជើញចែកវិញ្ញាបនបត្រសម្គាល់ម្ចាស់អចលនវត្ថុ ជូនដល់សម្បទានិកនៅខេត្តកំពង់ស្ពឺ'
        },
        {
          id: 4,
          src: '/slideshows/Doc4_001.jpg',
          caption: 'ឯកឧត្តមឧបនាយករដ្ឋមន្ត្រី ជាសុផារ៉ា លោកស្រីអនុប្រធានធនាគារពិភពលោកទទួលបន្ទុកអាស៊ីខាងកើត និងប៉ាស៊ីហ្វិក'
        },
        {
          id: 5,
          src: '/slideshows/Doc3_001.jpg',
          caption: 'ឯកឧត្តមឧបនាយករដ្ឋមន្រ្តី ជា សុផារ៉ា ដឹកនាំកិច្ចប្រជុំជាមួយប្រតិភូសហភាពពាណិជ្ជកម្មកម្ពុជា'
        },
        {
          id: 6,
          src: '/slideshows/Doc2_001.jpg',
          caption: 'ឯកឧត្តមឧបនាយករដ្ឋមន្រ្តី ជា សុផារ៉ា សំណេះសំណាលជាមួយប្រតិភូយុវជនកម្ពុជានៃកម្មវិធីយុវជនអាស៊ីអាគ្នេយ៍ និងជប៉ុន លើកទី៤៦'
        },
        {
          id: 7,
          src: '/slideshows/Doc5_001.jpg',
          caption: 'ឯកឧត្តមឧបនាយករដ្ឋមន្រ្តី ជា សុផារ៉ា ចុះពិនិត្យការដ្ឋានសំណង់នានាក្នុងក្រុងព្រះសីហនុ'
        },
      ]
    }
  },
  computed: {
    ...mapGetters('user', {
      userProvinces: 'provinces',
      user: 'getUser'
    }),
    current_province() {
      if (this.user && this.user.current_province_id) {
        return this.user.current_province_id
      }
      return null
    }
  }
}
</script>

<style scoped>

</style>
