<template>
  <div :class="wrapperClass">
    <div class="row">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "FormGroup",
  props: {
    wrapperClass: {
      type: String,
      default: 'col-sm-10 col-sm-offset-2',
    },
  }
}
</script>

<style scoped>

</style>
