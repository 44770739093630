import Permissions from "../../permissions"
import Index from '../../views/inspection/submit/Index'
import List from "../../views/inspection/submit/List"
import Show from "../../views/inspection/submit/Show"

export const inspectionSubmitRouters = {
  path: 'submit',
  name: 'inspection-submit',
  component: Index,
  redirect: 'submit',
  meta: {
    permissions: [Permissions.backend.inspection.submit.manage],
    groups: 'inspection-submit',
  },
  children: [
    {
      path: '',
      name: 'list-inspection-submit',
      component: List,
      meta: {
        permissions: [Permissions.backend.inspection.submit.list],
        groups: 'inspection-submit',
        breadcrumb: [
          {
            name_en: 'Inspection',
            name_km: 'ចុះត្រួតពិនិត្យ'
          },
          {
            name_en: 'Submit',
            name_km: 'សេចក្តីព្រាង'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'show/:inspection_uuid',
      name: 'show-inspection-submit',
      component: Show,
      meta: {
        permissions: [Permissions.backend.inspection.submit.show],
        groups: 'inspection-submit',
        breadcrumb: [
          {
            name_en: 'Inspection',
            name_km: 'ចុះត្រួតពិនិត្យ'
          },
          {
            name_en: 'Submit',
            name_km: 'សេចក្តីព្រាង'
          },
          {
            name_en: 'Show',
            name_km: 'មើល'
          }
        ]
      }
    },
  ]
}
