<template>
  <company-show
    :mode-show="true"
    :edit-permission="[Permissions.backend.businessBuilders.studyPlanCompany.edit]"
    name-route-edit="edit-study-plan-company"
    :title="$t('string.showStudyPlanCompany')"
  />
</template>

<script>
import CompanyShow from "../../company/Show"
import Permissions from "@/permissions"

export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('string.showStudyPlanCompany'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {CompanyShow},
  data () {
    return {
      Permissions
    }
  }
}
</script>

<style scoped>

</style>
