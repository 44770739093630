import CompanyType from "./../../views/settings/companyType/Index"
import List from "./../../views/settings/companyType/List"
import Create from "./../../views/settings/companyType/Create"
import Edit from "./../../views/settings/companyType/Edit"
import Show from "./../../views/settings/companyType/Show"
import Permissions from "../../permissions"

export const companyTypeRouters = {
  path: 'company-type',
  component: CompanyType,
  redirect: 'company-type',
  meta: {
    permissions: [Permissions.backend.setting.companyType.manage],
    groups: 'company-type',
  },
  children: [
    {
      path: '',
      name: 'list-company-type',
      component: List,
      meta: {
        permissions: [Permissions.backend.setting.companyType.list],
        groups: 'company-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Company Type',
            name_km: 'ប្រភេទក្រុមហ៊ុន'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-company-type',
      component: Create,
      meta: {
        permissions: [Permissions.backend.setting.companyType.store],
        groups: 'company-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'New Company Type',
            name_km: 'បន្ថែមប្រភេទក្រុមហ៊ុន'
          }
        ]
      }
    },
    {
      path: 'edit/:company_type_uuid',
      name: 'edit-company-type',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.setting.companyType.edit],
        groups: 'company-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Edit Company Type',
            name_km: 'កែប្រែប្រភេទក្រុមហ៊ុន'
          }
        ]
      }
    },
    {
      path: 'show/:company_type_uuid',
      name: 'show-company-type',
      component: Show,
      meta: {
        permissions: [Permissions.backend.setting.companyType.show],
        groups: 'company-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Show Company Type',
            name_km: 'បង្ហាញព័ត៏មានប្រភេទក្រុមហ៊ុន'
          }
        ]
      }
    }
  ]
}
