import Mission from "../views/mission/Index"
import List from "../views/mission/List"
import Create from "../views/mission/Create"
import Show from "../views/mission/Show"
import Edit from "../views/mission/Edit"
import Permissions from "../permissions"

export const missionRouters = {
  path: 'mission',
  name: 'mission',
  component: Mission,
  redirect: 'mission',
  meta: {
    permissions: [Permissions.backend.mission.manage],
    groups: 'mission',
  },
  children: [
    {
      path: '',
      name: 'list-mission',
      component: List,
      meta: {
        permissions: [Permissions.backend.mission.list],
        groups: 'mission',
        breadcrumb: [
          {
            name_en: 'Mission',
            name_km: 'បេសកម្ម'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-mission',
      component: Create,
      meta: {
        permissions: [Permissions.backend.mission.store],
        groups: 'mission',
        breadcrumb: [
          {
            name_en: 'Mission',
            name_km: 'បេសកម្ម'
          },
          {
            name_en: 'Create',
            name_km: 'បន្ថែមថ្មី'
          }
        ]
      }
    },
    {
      path: 'show/:mission_uuid',
      name: 'show-mission',
      component: Show,
      meta: {
        permissions: [Permissions.backend.mission.show],
        groups: 'mission',
        breadcrumb: [
          {
            name_en: 'Mission',
            name_km: 'បេសកម្ម'
          },
          {
            name_en: 'Show',
            name_km: 'មើល'
          }
        ]
      }
    },
    {
      path: 'edit/:mission_uuid',
      name: 'edit-mission',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.mission.edit],
        groups: 'mission',
        breadcrumb: [
          {
            name_en: 'Mission',
            name_km: 'បេសកម្ម'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    }
  ]
}
