<template>
  <span class="font-family-wingding font-size-medium">
    {{ getSymbol }}
    <template v-if="showLabel">
      <span class="font-size-normal">{{ checked ? $t('string.true') : $t('string.false') }}</span>
    </template>
  </span>
</template>

<script>
export default {
  name: "SymbolCheckBox",
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    getSymbol() {
      let string = "¨"
      if (this.checked) {
        string = "þ"
      }
      return string
    }
  }
}
</script>

<style scoped>
  .font-family-wingding {
    font-family: "wingding",serif;
  }
  .font-size-medium {
    font-size: 20px;
  }

  .font-size-normal {
    font-size: 16px;
    font-family: var(--primary-font);
  }
</style>
