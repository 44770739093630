<template>
  <div
    id="collectProjectModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="collectProjectModalLabel"
  >
    <div
      class="modal-dialog modal-lg"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            ref="close"
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4
            id="collectProjectModalLabel"
            class="modal-title"
          >
            {{ $t('string.projects') }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-12">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>{{ $t('table.owners') }}</th>
                    <th>{{ $t('table.projectNameInKhmer') }}</th>
                    <th>{{ $t('table.projectType') }}</th>
                    <th>{{ $t('table.constructionType') }}</th>
                    <th>{{ $t('table.permitNumber') }}</th>
                    <th>{{ $t('table.permitDate') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="projects && Array.isArray(projects) && projects.length >0">
                    <tr
                      v-for="(project, key) in projects"
                      :key="key"
                    >
                      <td>
                        <span v-html="project.owners" />
                      </td>
                      <td>{{ project['name_' + $i18n.locale] || $t('string.na') }}</td>
                      <td>{{ project.project_type && project.project_type['name_' + $i18n.locale] ? project.project_type['name_' + $i18n.locale] : $t('string.na') }}</td>
                      <td>{{ project.construction_type && project.construction_type['name_' + $i18n.locale] ? project.construction_type['name_' + $i18n.locale] : $t('string.na') }}</td>
                      <td>{{ project['permit_number_' + $i18n.locale] || $t('string.na') }}</td>
                      <td>{{ project.permit_date || $t('string.na') }}</td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td
                        colspan="6"
                        class="text-center text-muted"
                      >
                        {{ $t('string.no_result') }}
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="viewOnMap"
          >
            <i class="fas fa-map-marked-alt" /> {{ $t('string.view_on_map') }}
          </button>
          <button
            type="button"
            class="btn btn-info"
            @click="publish"
          >
            <i class="fas fa-bullhorn" /> {{ $t('string.publish') }}
          </button>
          <button
            type="button"
            class="btn btn-warning"
            @click="unpublished"
          >
            <i class="fas fa-eye-slash" /> {{ $t('string.unpublished') }}
          </button>
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
          >
            {{ $t('button.close') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: "CollectProjectModal",
  computed: {
    ...mapGetters('collectProjectModal', {
      projects: 'getProjects'
    }),
    project_uuids () {
      if (this.projects && Array.isArray(this.projects) && this.projects.length > 0) {
        return this.projects.map(m => m.uuid)
      }
      return []
    }
  },
  methods: {
    viewOnMap() {
      this.onConfirm({
        title: this.$t('string.view_project_on_map'),
        text: this.$t('string.are_you_ready_to_view_project_on_map'),
        confirmButtonText: this.$t('string.yes_view')
      }).then((accept) => {
        if (accept) {
          this.$isLoading(true)
          this.$axios.post(this.$base_api + '/api/backend/collect-project/store-on-map', {
            project_uuids: this.project_uuids
          }).then(({ data }) => {
            if (data.data) {
              this.$refs.close.click()
              this.showToastr()
              this.$router.push({
                name: 'view-project-on-map'
              })
            }
          }).catch((error) => {
            this.onResponseError(error)
          }).finally(() => {
            this.$isLoading(false)
          })
        }
      })
    },
    publish() {
      this.onConfirm({
        title: this.$t('string.publish_project'),
        text: this.$t('string.do_you_want_to_publish_this_project'),
        confirmButtonText: this.$t('string.yes')
      }).then((accept) => {
        if (accept) {
          this.$isLoading(true)
          this.$axios.post(this.$base_api + '/api/backend/collect-project/publish', {
            project_uuids: this.project_uuids
          }).then(({ data }) => {
            if (data.data) {
              this.$refs.close.click()
              this.showToastr()
              this.$emit('refreshList')
            }
          }).catch((error) => {
            this.onResponseError(error)
          }).finally(() => {
            this.$isLoading(false)
          })
        }
      })
    },
    unpublished() {
      this.onConfirm({
        title: this.$t('string.unpublished_project'),
        text: this.$t('string.do_you_want_to_unpublished_this_project'),
        confirmButtonText: this.$t('string.yes')
      }).then((accept) => {
        if (accept) {
          this.$isLoading(true)
          this.$axios.post(this.$base_api + '/api/backend/collect-project/unpublished', {
            project_uuids: this.project_uuids
          }).then(({ data }) => {
            if (data.data) {
              this.$refs.close.click()
              this.showToastr()
              this.$emit('refreshList')
            }
          }).catch((error) => {
            this.onResponseError(error)
          }).finally(() => {
            this.$isLoading(false)
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.modal-lg {
  width: 85% !important;
}
</style>
