<template>
  <button
    class="btn btn-default btn-sm"
    @click="backTo"
  >
    <i class="fa fa-arrow-alt-circle-left" /> {{ $t('button.back_to_list') }}
  </button>
</template>

<script>
export default {
  name: "ButtonBackToList",
  methods: {
    backTo() {
      if (this.$route.fullPath.includes('open-construction-letter')) {
        return this.$router.push({name: 'list-open-construction-letter'})
      }
      return this.$router.push({name: 'list-close-construction-letter'})
    }
  }
}
</script>

<style scoped>

</style>
