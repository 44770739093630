import {getField, updateField} from "vuex-map-fields"

const state = () => {
  return {
    modal: {
      categories: [],
    },

    constructionCertifier: {
      companyUuid: null,
      selectedLicense: null,
      editLicenseUuid: null,
      approveLicenseUuid: null,
    }
  }
}

const getters = {
  getField,
}

const mutations = {
  updateField,
  setModalCompanyCategories(state, categories) {
    state.modal.categories = categories
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
