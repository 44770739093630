import Project from "../views/project/Index"
import List from "../views/project/List"
import Map from "../views/project/Map"
import Create from "../views/project/Create"
import Edit from "../views/project/Edit"
import Show from "../views/project/Show"
import PreviewPdf from "../views/project/PreviewPdf"
import ViewOnMap from "../views/project/ViewOnMap"
import Permissions from "../permissions"

export const projectRouters = {
  path: 'project',
  name: 'project',
  component: Project,
  redirect: 'project',
  meta: {
    permissions: [
      Permissions.backend.project.manage,
      Permissions.backend.provinceProject.manage,
    ],
    groups: 'project',
  },
  children: [
    {
      path: '',
      name: 'list-project',
      component: List,
      meta: {
        permissions: [Permissions.backend.project.list],
        groups: 'project',
        breadcrumb: [
          {
            name_en: 'Project',
            name_km: 'គម្រោង'
          },
          {
            name_en: 'Project List',
            name_km: 'បញ្ជីគម្រោង'
          }
        ]
      }
    },
    {
      path: 'map',
      name: 'map-project',
      component: Map,
      meta: {
        permissions: [Permissions.backend.project.map],
        groups: 'project',
        breadcrumb: [
          {
            name_en: 'Project',
            name_km: 'គម្រោង'
          },
          {
            name_en: 'Project List',
            name_km: 'បញ្ជីគម្រោង'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-project',
      component: Create,
      meta: {
        permissions: [Permissions.backend.project.store],
        groups: 'project',
        breadcrumb: [
          {
            name_en: 'Project',
            name_km: 'គម្រោង'
          },
          {
            name_en: 'New Project',
            name_km: 'បន្ថែមគម្រោង'
          }
        ]
      }
    },
    {
      path: 'edit/:project_uuid',
      name: 'edit-project',
      component: Edit,
      meta: {
        permissions: [
          Permissions.backend.project.edit,
          Permissions.backend.provinceProject.edit,
        ],
        groups: 'project',
        breadcrumb: [
          {
            name_en: 'Project',
            name_km: 'គម្រោង'
          },
          {
            name_en: 'Edit Project',
            name_km: 'កែសម្រួលគម្រោង'
          }
        ]
      }
    },
    {
      path: 'show/:project_uuid',
      name: 'show-project',
      component: Show,
      meta: {
        permissions: [Permissions.backend.project.show],
        groups: 'project',
        breadcrumb: [
          {
            name_en: 'Project',
            name_km: 'គម្រោង'
          },
          {
            name_en: 'Show Project',
            name_km: 'បង្ហាញព័ត៌មានគម្រោង'
          }
        ]
      }
    },
    {
      path: 'preview-pdf/:path',
      name: 'preview-pdf',
      component: PreviewPdf,
      meta: {
        permissions: [Permissions.backend.project.show],
        groups: 'project',
        breadcrumb: [
          {
            name_en: 'Project',
            name_km: 'គម្រោង'
          },
          {
            name_en: 'Show Project',
            name_km: 'បង្ហាញព័ត៌មានគម្រោង'
          }
        ]
      }
    },
    {
      path: 'view-project-on-map',
      name: 'view-project-on-map',
      component: ViewOnMap,
      meta: {
        permissions: [Permissions.backend.project.show],
        groups: 'project',
        breadcrumb: [
          {
            name_en: 'Project',
            name_km: 'គម្រោង'
          },
          {
            name_en: 'View Project on Map',
            name_km: 'មើលគម្រោងនៅលើផែនទី'
          }
        ]
      }
    },
  ]
}
