<template>
  <div class="col-md-12">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t('string.builderInformation') }}
        </h3>
      </div>
      <div class="box-body">
        <table class="table table-striped table-bordered table-condensed">
          <tbody>
            <tr>
              <th>{{ $t('label.company') }}</th>
              <td>
                <template v-if="project.companies.length>0">
                  <people-chip-item
                    v-for="(company, key) in project.companies"
                    :key="key"
                    :people="getPrepareCompany(company)"
                    model="company"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.standBy') }}</th>
              <td>
                <template v-if="project.stand_by.length>0">
                  <people-chip-item
                    v-for="(architecture, key) in project.stand_by"
                    :key="key"
                    :people="getPrepareArchitecture(architecture)"
                    model="architecture"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr
              v-for="(column, key) in constructionSection"
              :key="key"
            >
              <td>{{ column.label }}</td>
              <td>
                <template v-if="project[column.key]">
                  {{ column.prefix }} {{ project[column.key] }}
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.constructionReviewedAndConfirmedByCompany') }}</th>
              <td>
                <template v-if="project.construction_confirmed_and_checked_by_companies.length>0">
                  <people-chip-item
                    v-for="(company, key) in project.construction_confirmed_and_checked_by_companies"
                    :key="key"
                    :people="getPrepareCompany(company)"
                    model="company"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.constructionReviewedAndConfirmedByArchitecture') }}</th>
              <td>
                <template v-if="project.construction_confirmed_and_checked_by_architectures.length>0">
                  <people-chip-item
                    v-for="(architecture, key) in project.construction_confirmed_and_checked_by_architectures"
                    :key="key"
                    :people="getPrepareArchitecture(architecture)"
                    model="architecture"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuilderInformationPanel",
  props: {
    project: {
      type: Object
    }
  },
  computed: {
    constructionSection() {
      return [
        {key: 'technician_team', label: this.$t('label.technicianTeam'), prefix: '',},
        {key: 'nb_workers', label: this.$t('label.nbWorkers'), prefix: '',},
        {key: 'worker_salary', label: this.$t('label.workerSalary'), prefix: '$',},
        {key: 'nb_professionals', label: this.$t('label.nbProfessional'), prefix: '',},
        {key: 'professional_salary', label: this.$t('label.professionalSalary'), prefix: '$',},
        {key: 'nb_engineers', label: this.$t('label.nbEngineer'), prefix: '',},
        {key: 'engineer_salary', label: this.$t('label.engineerSalary'), prefix: '$',},
        {key: 'nb_architects', label: this.$t('label.nbArchitects'), prefix: '',},
        {key: 'architect_salary', label: this.$t('label.architectSalary'), prefix: '$',},
      ]
    }
  }
}
</script>

<style scoped>

</style>
