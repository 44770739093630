<template>
  <div class="row">
    <div class="col-md-4">
      <Note :company="company" />
    </div>
    <div
      v-if="company"
      class="col-md-8"
    >
      <XBox :title="$t('string.companyConstructionNoteLetter')">
        <div class="row">
          <div class="col-md-12">
            <div class="pull-right">
              <button
                class="btn btn-default margin-bottom"
                @click="openUrl"
              >
                <i class="fa fa-print" /> {{ $t('label.print') }}
              </button>
            </div>
          </div>
        </div>
        <smart-iframe
          :src="noteOfLicenseUrl"
        />
      </XBox>
    </div>
  </div>
</template>

<script>
import SmartIframe from "@/components/SmartIframe"
import Note from "@/views/companyManagement/notes/Note"
import XBox from "@/components/Widgets/XBox"

export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('string.companyConstructionNoteLetter'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  components: {XBox, Note, SmartIframe},
  data() {
    return {
      company: null,
    }
  },
  computed: {
    api() {
      return `${this.baseUrl}/api/backend/company/notes`
    },
    noteOfLicenseUrl() {
      const company_uuid = this.$route.params.company_uuid
      const path = 'certificates/company/note-of-license'
      return `${this.baseUrl}/${path}/${company_uuid}`
    }
  },
  mounted() {
    this.show()
  },
  methods: {
    openUrl() {
      window.open(this.noteOfLicenseUrl, '_blank')
    },
    show() {
      this.$axios.post(`${this.api}/show`, {
        company_uuid: this.$route.params.company_uuid,
      }).then(({data}) => {
        if (data.data) {
          this.company = data.data
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
