<template>
  <div class="row">
    <div :class="divContainerClass">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('label.inspection') }}
          </h3>
          <div class="box-tools">
            <template v-if="can($Permissions.backend.inspection.filter)">
              <button
                class="btn btn-danger btn-sm"
                @click="toggleFilter"
              >
                <i class="fa fa-filter" /> {{ $t('string.filter') }}
              </button>
            </template>
          </div>
        </div>
        <div class="box-body">
          <div>
            <table
              id="inspection-table"
              class="table table-hover table-full-width table-striped table-bordered"
            >
              <thead>
                <tr>
                  <th>{{ $t('table.type') }}</th>
                  <th>{{ $t('table.permitNumber') }}</th>
                  <th>{{ $t('table.project') }}</th>
                  <th>{{ $t('table.inspector') }}</th>
                  <th>{{ $t('table.team') }}</th>
                  <th>{{ $t('table.inspectedAt') }}</th>
                  <th>{{ $t('table.action') }}</th>
                </tr>
              </thead>
              <tbody />
            </table>
          </div>
        </div>
      </div>
    </div>
    <template v-if="showFilter">
      <div class="col-md-2 pl-md-0">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('string.filter') }}
            </h3>
          </div>
          <div class="box-body">
            <div class="form-group">
              <label>{{ $t('table.permitNumber') }}</label>
              <input
                v-model="permit_number"
                type="text"
                :placeholder="$t('table.permitNumber')"
                class="form-control"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('label.permitYear') }}</label>
              <input
                v-model="permit_number_year"
                type="text"
                :placeholder="$t('label.permitYear')"
                class="form-control"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('label.landUniqueNumber') }}</label>
              <input
                v-model="land_unique_number"
                type="text"
                :placeholder="$t('label.landUniqueNumber')"
                class="form-control"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.owners') }}</label>
              <input
                v-model="owner_name"
                type="text"
                :placeholder="$t('table.owners')"
                class="form-control"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('label.typeInspection') }}</label>
              <select
                v-model="inspection_type"
                name="inspection_type"
                class="form-control"
              >
                <option
                  :value="null"
                  selected
                >
                  {{ $t('string.all') }}
                </option>
                <template v-for="(item, inspection_key) in inspectionTypes">
                  <option
                    :key="inspection_key"
                    :value="item.key"
                  >
                    {{ item['name_' + $i18n.locale] }}
                  </option>
                </template>
              </select>
            </div>
            <div class="form-group">
              <label>{{ $t('table.inspector') }}</label>
              <input
                v-model="inspector_name"
                type="text"
                :placeholder="$t('table.inspector')"
                class="form-control"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.team') }}</label>
              <select
                v-model="team_id"
                name="inspection_team"
                class="form-control"
              >
                <option
                  :value="null"
                  selected
                >
                  {{ $t('string.all') }}
                </option>
                <template v-for="(item, key_team) in teams">
                  <option
                    :key="key_team"
                    :value="item.id"
                  >
                    {{ item['name_' + $i18n.locale] }}
                  </option>
                </template>
              </select>
            </div>
            <div class="form-group">
              <label>{{ $t('table.inspectedAt') }}</label>
              <date-picker
                v-model="inspected_at"
                type="date"
                :placeholder="$t('table.inspectedAt')"
                format="DD/MM/YYYY"
                class="table-full-width"
              />
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <button
                    class="btn btn-primary btn-block btn-sm"
                    @click="search"
                  >
                    <i class="fa fa-search" />
                    {{ $t('button.search') }}
                  </button>
                </div>
                <div class="col-md-6 pl-lg-0">
                  <button
                    class="btn btn-default btn-block btn-sm"
                    @click="reset"
                  >
                    <i class="fas fa-redo-alt" />
                    {{ $t('button.reset') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'InspectionList',
  metaInfo() {
    return {
      title: this.$t('menu.inspection'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  props: {
    status: {
      type: String,
      required: true
    },
    modelRouteView: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      oTable: null,
      showFilter: false,
      inspection_type: null,
      inspector_name: null,
      inspected_at: null,
      inspected_date: null,
      owner_name: null,
      permit_number: null,
      land_unique_number: null,
      permit_number_year: null,
      teams: [],
      team_id: null,
    }
  },
  computed: {
    divContainerClass() {
      return this.showFilter ? 'col-md-10' : 'col-md-12'
    },
    inspectionTypes() {
      return [
        {
          key: 'before_approval',
          name_en: 'Controls to provide construction permit',
          name_km: 'ត្រួតពិនិត្យដើម្បីផ្ដល់លិខិតអនុញ្ញាតសាងសង់'
        },
        {
          key: 'after_approval',
          name_en: 'Controls for groundbreaking',
          name_km: 'ត្រួតពិនិត្យដើម្បីបើកការដ្ឋានសាងសង់'
        },
        {
          key: 'implementation',
          name_en: 'Construction Positioning border demarcation',
          name_km: 'ការបោះបង្គោលព្រំកំណត់ទីតាំងសាងសង់'
        },
        {key: 'following_up', name_en: 'Track construction', name_km: 'តាមដានដំណើរការសាងសង់'},
        {
          key: 'closing_construction',
          name_en: 'Construction inspection certificate',
          name_km: 'ត្រួតពិនិត្យផ្ដល់វិញ្ញាបនបត្រប្រើប្រាស់សំណង់'
        },
        {key: 'on_suing', name_en: 'Complaint Review', name_km: 'ត្រួតពិនិត្យពេលមានពាក្យបណ្ដឹង'},
      ]
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.refreshTable()
      this.oTable.draw(true)
    }
  },
  mounted() {
    this.oTable = this.refreshTable()
    this.getTeam()
    this.enabledAction()
  },
  beforeDestroy() {
    this.oTable.destroy()
    this.oTable.clear()
  },
  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter
      this.oTable.columns.adjust().draw()
    },
    search() {
      if (this.inspected_at) {
        this.inspected_date = this.$moment(this.inspected_at).format('YYYY-MM-DD')
      }
      this.oTable.draw(true)
    },
    reset() {
      this.inspection_type = null
      this.inspector_name = null
      this.inspected_at = null
      this.owner_name = null
      this.land_unique_number = null
      this.permit_number = null
      this.inspection_type = null
      this.permit_number_year = null
      this.team_id = null
      this.oTable.draw(true)
    },
    getTeam() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/team/get-option')
        .then((response) => {
          this.teams = response.data.data
        })
    },
    refreshTable() {
      let self = this
      return $('#inspection-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: process.env.VUE_APP_API + '/api/backend/inspection/datatable',
          data: (d) => {
            d.edit = this.$t('label.edit')
            d.show = this.$t('label.show')
            d.delete = this.$t('label.delete')
            d.status = this.status
            d.inspection_type = this.inspection_type
            d.inspector_name = this.inspector_name
            d.inspected_at = this.inspected_date
            d.land_unique_number = this.land_unique_number
            d.owner_name = this.owner_name
            d.permit_number = this.permit_number
            d.inspection_type = this.inspection_type
            d.permit_number_year = this.permit_number_year
            d.team_id = this.team_id
          },
          error: (xhr) => {
            this.onResponseError(xhr)
          }
        },
        columns: [
          {
            data: 'type',
            name: 'type',
            title: this.$t('table.type'),
            render: (data) => {
              if (data !== null) {
                let value = null
                self.inspectionTypes.forEach((inspection) => {
                  if (inspection.key === data) {
                    value = inspection
                  }
                })
                if (value !== null) {
                  return value['name_' + self.$i18n.locale]
                }
              }
              return self.$t('string.na')
            }
          },
          {
            data: 'permit_number',
            name: 'permit_number',
            title: this.$t('table.permitNumber'),
            render: (data) => {
              if (data !== null) {
                return data
              }
              return this.$t('string.na')
            }
          },
          {
            data: 'project',
            name: 'project',
            title: this.$t('table.project'),
            render: (data) => {
              if (data !== null) {
                return data['name_' + self.$i18n.locale]
              }
              return self.$t('string.na')
            }
          },
          {
            data: 'inspector',
            name: 'inspector',
            orderable: false,
            searchable: false,
            title: this.$t('table.inspector'),
            render: (data) => {
              if (data !== null) {
                return `${data.first_name} ${data.last_name}`
              }
              return self.$t('string.na')
            }
          },
          {
            data: 'team',
            name: 'team',
            orderable: false,
            title: this.$t('table.team'),
          },
          {
            data: 'inspected_at',
            name: 'inspected_at',
            orderable: true,
            searchable: false,
            title: this.$t('table.inspectedAt'),
            render: (data) => {
              return this.getDate(data)
            }
          },
          {
            data: 'action',
            name: 'action',
            orderable: false,
            searchable: false,
            title: this.$t('table.action'),
            render: (data, type, row) => {
              let action = ''
              if (this.can(this.$Permissions.backend.inspection.draft.show)) {
                action += `<button class="btn btn-info btn-xs btn-show" data-uuid="${row.uuid}">
                          <i class="fa fa-search"></i> ${self.$t('string.view')}
                        </button>`
              }
              return action
            }
          }
        ],
        language: {
          url: `/locale/${this.$i18n.locale}.json`
        },
        order: [
          [4, 'desc']
        ]
      })
    },
    enabledAction() {
      let self = this
      this.clearEventHandler([
        '.btn-edit',
        '.btn-show',
        '.btn-trash',
        '.sidebar-toggle',
      ])
      $(document).on('click', '.sidebar-toggle', function () {
        self.oTable.columns.adjust().draw()
      })
      $(document).on('click', '.btn-edit', function () {
        self.$router.push({
          name: 'edit-project',
          params: {
            project_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-show', function () {
        self.$router.push({
          name: self.modelRouteView,
          params: {
            inspection_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-trash', function () {
        self.deleteProject($(this).attr('data-uuid'))
      })
    },
    deleteProject(uuid) {
      let self = this
      this.$swal.fire({
        title: self.i18nSwalTitle,
        text: self.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: self.i18nSwalNo,
        confirmButtonText: self.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/project/delete', {
            project_uuid: uuid
          }).then(() => {
            this.showSwalSuccess()
            this.oTable.draw(true)
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
