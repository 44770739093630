<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('label.newLawRegulationType') }}
      </h3>
      <div class="box-tools">
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <law-regulation-form />
    </div>
  </div>
</template>

<script>
import LawRegulationForm from "./Form"

export default {
  name: "Create",
  components: {LawRegulationForm},
  metaInfo() {
    return {
      title: this.$t('label.newLawRegulationType'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
}
</script>

<style scoped>

</style>
