<template>
  <div class="row">
    <div class="col-md-4">
      <info-certificate-of-occupancy />
    </div>
    <div class="col-md-8">
      <div class="nav-tabs-custom">
        <ul
          class="nav nav-tabs"
          role="tablist"
        >
          <li
            role="presentation"
            class="active"
          >
            <a
              href="#letter"
              aria-controls="letter"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.letterCertificateOfOccupancy') }}
            </a>
          </li>
          <li role="presentation">
            <a
              href="#docs"
              aria-controls="docs"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.documentLetterTab') }}
            </a>
          </li>
          <li role="presentation">
            <a
              href="#activityLogs"
              aria-controls="activityLogs"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.activityLogs') }}
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div
            id="letter"
            role="tabpanel"
            class="tab-pane active"
          >
            <template v-if="occupancy_uuid">
              <letter-certificate-of-occupancy
                :model_uuid="occupancy_uuid"
              />
            </template>
          </div>
          <div
            id="docs"
            role="tabpanel"
            class="tab-pane"
          >
            <template v-if="occupancy_uuid">
              <upload-media
                model-name="occupancy"
                button-label="string.chooseFile"
                :draggable="false"
                preview-column="col-sm-4 col-md-3"
                :readonly="true"
                :model-uuid="occupancy_uuid"
              />
            </template>
          </div>
          <div
            id="activityLogs"
            role="tabpanel"
            class="tab-pane"
          >
            <activity-logs
              model_type="occupancy"
              :model_uuid="$route.params.occupancy_uuid"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InfoCertificateOfOccupancy from "./includes/InfoCertificateOfOccupancy"
import LetterCertificateOfOccupancy from "./includes/LetterCertificateOfOccupancy"
import UploadMedia from "../../../components/UploadMedia"
import ActivityLogs from "../../../components/ActivityLogs"

export default {
  name: "Show",
  components: {
    ActivityLogs,
    UploadMedia,
    LetterCertificateOfOccupancy,
    InfoCertificateOfOccupancy,
  },
  data() {
    return {
      occupancy_uuid: null,
    }
  },
  metaInfo() {
    return {
      title: this.$t('string.infoCertificateOfOccupancy'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  mounted() {
    this.occupancy_uuid = this.$route.params.occupancy_uuid
  }
}
</script>

<style scoped>

</style>
