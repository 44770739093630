<template>
  <people-table
    :permission-create="Permissions.backend.investorConstruction.personality.store"
    :permission-filter="Permissions.backend.investorConstruction.personality.filter"
    :title="$t('string.personality')"
    name-route-create="create-personality"
    name-route-edit="edit-personality"
    name-route-show="show-personality"
    people-category-id="1"
  />
</template>

<script>
import PeopleTable from "../../people/List"
import Permissions from "@/permissions"

export default {
  name: "List",
  components: {PeopleTable},
  metaInfo() {
    return {
      title: this.$t('string.personality'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data () {
    return {
      Permissions
    }
  }
}
</script>

<style scoped>

</style>
