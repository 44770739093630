<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('menu.registerCompany') }}
      </h3>
      <div class="box-tools">
        <template v-if="can([Permissions.backend.companyManagement.companyRegistration.store])">
          <router-link
            :to="{name: 'create-register-company'}"
            class="btn btn-primary btn-sm"
          >
            <i class="fa fa-plus" /> {{ $t('button.register') }}
          </router-link>
        </template>
      </div>
    </div>
    <div class="box-body">
      <div>
        <table
          id="company-table"
          class="table table-hover table-striped table-bordered"
        >
          <thead>
            <tr>
              <th class="width-avatar">
                {{ $t('table.logo') }}
              </th>
              <th>{{ $t('table.firstRegistrationNumber') }}</th>
              <th>{{ $t('table.name') }}</th>
              <th>{{ $t('table.companyOwner') }}</th>
              <th>{{ $t('table.ctoList') }}</th>
              <th>{{ $t('table.type') }}</th>
              <th>{{ $t('table.company_section') }}</th>
              <th>{{ $t('table.status') }}</th>
              <th class="width-created-at">
                {{ $t('table.createdAt') }}
              </th>
              <th style="width: 130px !important;">
                {{ $t('table.action') }}
              </th>
            </tr>
          </thead>
          <tbody />
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Permissions from "@/permissions"
export default {
  name: "List",
  metaInfo() {
    return {
      title: this.$t('menu.registerCompany'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  data() {
    return {
      oTable: null,
      Permissions
    }
  },
  computed: {
    api() {
      return `${process.env.VUE_APP_API}/api/backend/company/registration`
    }
  },
  mounted() {
    this.oTable = this.refreshTable()
    this.enabledAction()
  },
  beforeDestroy() {
    this.oTable.clear()
    this.oTable.destroy()
  },
  methods: {
    refreshTable() {
      let self = this
      return $('#company-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        sScrollX: "100%",
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: `${self.api}/datatable`,
          error: (xhr) => {
            self.onResponseError(xhr)
          }
        },
        columns: [
          {
            data: 'logo',
            name: 'logo',
            title: self.$t('table.logo'),
            render: (data, type, row) => {
              if (row.logo) {
                return `<img src="${process.env.VUE_APP_API}/${data}" alt="Logo" class="datatable-image-size"/>`
              }
              return `<img src="/img/company.png" alt="Logo" class="datatable-image-size"/>`
            },
            searchable: false,
            orderable: false,
          },
          {
            data: 'first_registration_number',
            name: 'first_registration_number',
            title: self.$t('table.firstRegistrationNumber'),
            render: (data) => {
              if (data) {
                return data
              } else {
                return self.$t('string.na')
              }
            },
          },
          {
            data: 'name_km',
            name: 'name_km',
            title: self.$t('table.name'),
            orderable: false,
            render: (data, type, row) => {
              if (data) {
                return row['name_' + this.$i18n.locale]
              } else {
                return self.$t('string.na')
              }
            }
          },
          {
            data: 'owner_list',
            name: 'owner_list',
            title: self.$t('table.companyOwner'),
          },
          {
            data: 'cto_list',
            name: 'cto_list',
            title: self.$t('table.ctoList')
          },
          {
            data: 'company_type.name_km',
            name: 'companyType.name_km',
            title: self.$t('table.type'),
            orderable: false,
            render: (data, type, row) => {
              if (row['company_type'] !== null) {
                return row['company_type']['name_' + this.$i18n.locale]
              }
              return this.$t('string.na')
            }
          },
          {
            data: 'company_section.name_km',
            name: 'companySection.name_km',
            title: self.$t('table.company_section'),
            orderable: false,
            render: (data, type, row) => {
              if (row['company_section'] !== null) {
                return row['company_section']['name_' + this.$i18n.locale]
              }
              return this.$t('string.na')
            }
          },
          {
            data: 'is_published',
            name: 'is_published',
            title: self.$t('table.is_published'),
            render: (data) => {
              if (data !== "1") {
                return `<span class="label label-warning"><i class="fa fa-times"></i></span>`
              }
              return `<span class="label label-primary"><i class="fa fa-check-circle"></i></span>`
            }
          },
          {
            data: 'created_at',
            name: 'created_at',
            orderable: true,
            searchable: false,
            title: self.$t('table.createdAt'),
            render: (data) => {
              return this.getDate(data)
            }
          },
          {
            data: 'action',
            name: 'action',
            orderable: false,
            searchable: false,
            title: self.$t('table.action'),
            render: (data, type, row) => {
              let action = ''
              if (this.can([this.$Permissions.backend.companyManagement.companyRegistration.show])) {
                action += `<button class="btn btn-info btn-xs btn-show" data-toggle="tooltip" data-placement="top" title="${this.$t('button.show')}" data-uuid="${row['uuid']}"><i class="fa fa-search"></i></button> `
              }
              if (this.can([this.$Permissions.backend.companyManagement.companyRegistration.edit])) {
                action += `<button class="btn btn-primary btn-xs btn-edit" data-toggle="tooltip" data-placement="top" title="${this.$t('button.edit')}" data-uuid="${row['uuid']}"><i class="fa fa-edit"></i></button> `
              }
              if (this.can([this.$Permissions.backend.companyManagement.companyRegistration.delete])) {
                action += `<button class="btn btn-danger btn-xs btn-delete" data-toggle="tooltip" data-placement="top" title="${this.$t('button.delete')}" data-uuid="${row['uuid']}"><i class="fa fa-trash"></i></button>`
              }
              return action
            }
          }
        ],
        language: {
          url: self.getLocale,
        },
        order: [
          [8, 'desc']
        ],
        drawCallback () {
          $('[data-toggle="tooltip"]').tooltip()
        }
      })
    },
    enabledAction() {
      let self = this
      this.clearEventHandler([
        '.btn-edit',
        '.btn-show',
        '.btn-delete',
        '.sidebar-toggle',
      ])
      $(document).on('click', '.sidebar-toggle', function () {
        self.oTable.columns.adjust().draw()
      })
      $(document).on('click', '.btn-edit', function () {
        self.$router.push({
          name: 'edit-register-company',
          params: {
            company_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-show', function () {
        self.$router.push({
          name: 'show-register-company',
          params: {
            company_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-delete', function () {
        self.deleteCompany($(this).attr('data-uuid'))
      })
    },
    deleteCompany(uuid) {
      this.$swal({
        title: this.i18nSwalTitle,
        text: this.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.i18nSwalNo,
        confirmButtonText: this.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(`${this.api}/delete`, {
            company_uuid: uuid
          }).then(() => {
            this.showSwalSuccess()
            this.oTable.draw(true)
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
