<template>
  <div class="tool-tip">
    <span class="tool-tip__icon">
      <i class="fa fa-info-circle" />
    </span>
    <p class="tool-tip__info">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: "TooltipInfo"
}
</script>

<style scoped>
  .tool-tip {
    display: inline-block;
    position: relative;
    margin-left: 0.5em;
  }

  .tool-tip .tool-tip__icon {
    background: #dddddd;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
  }

  .tool-tip .tool-tip__info {
    display: none;
    background: #4081ed;
    border: 1px solid #4081ed;
    border-radius: 3px;
    font-size: 0.875em;
    padding: 1em;
    position: absolute;
    left: 30px;
    top: -20px;
    width: 250px;
    z-index: 2;
    color: #ffffff;
  }

  .tool-tip .tool-tip__info:before, .tool-tip .tool-tip__info:after {
    content: "";
    position: absolute;
    left: -10px;
    top: 7px;
    border-style: solid;
    border-width: 10px 10px 10px 0;
    border-color: transparent #4081ed;
  }

  .tool-tip .tool-tip__info:after {
    left: -8px;
    border-right-color: #4081ed;
  }

  .tool-tip .tool-tip__info .info {
    display: block;
  }

  .tool-tip .tool-tip__info .info__title {
    color: #ffffff;
  }

  .tool-tip:hover .tool-tip__info, .tool-tip:focus .tool-tip__info {
    display: inline-block;
  }

  a:focus + .tool-tip .tool-tip__info {
    display: inline-block;
  }

  .tool-tip__info {
    text-align: left !important;
  }
</style>
