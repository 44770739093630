<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('label.showAnnouncement') }}
      </h3>
      <div class="box-tools">
        <button
          v-if="can([$Permissions.backend.setting.announcementReference.edit])"
          class="btn btn-primary btn-sm margin-right-5"
          @click="$router.push({
            name: 'edit-announcement-reference',
            params: {
              carbon_copy_uuid: $route.params.announcement_uuid,
            }
          })"
        >
          <i class="fa fa-edit" /> {{ $t('button.edit') }}
        </button>
        <asora-back-button />
      </div>
    </div>
    <div
      v-if="announcement!==null"
      class="box-body"
    >
      <div class="form-horizontal">
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.announcement') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ announcement.title }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.status') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              :
              {{ announcement.enabled ? $t('label.enable') : $t('label.disable') }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.createdAt') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ getDate(announcement.created_at, false) }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.updatedAt') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ getDate(announcement.updated_at, false) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('label.showAnnouncement'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      announcement: null,
    }
  },
  created() {
    this.getTeamCategory()
  },
  methods: {
    getTeamCategory() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/announcement-reference/show', {
        announcement_uuid: this.$route.params.announcement_uuid,
      }).then((res) => {
        this.announcement = res.data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-announcement-reference'})
      }).finally(() => {
        this.$isLoading(false)
      })
    },
  }
}
</script>

<style scoped>
.control-label {
  padding-top: 0 !important;
}
</style>

