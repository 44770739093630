<template>
  <div class="row">
    <div class="col-md-4">
      <show-company-detail />
    </div>
    <div class="col-md-8">
      <div class="nav-tabs-custom">
        <ul
          class="nav nav-tabs"
          role="tablist"
        >
          <li
            role="presentation"
            class="active"
          >
            <a
              href="#certificateOfLicense"
              aria-controls="certificateOfLicense"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.companyCertificateOfLicense') }}
            </a>
          </li>
          <li role="presentation">
            <a
              href="#letter"
              aria-controls="letter"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.companyConstructionLetter') }}
            </a>
          </li>
          <li role="presentation">
            <a
              href="#note"
              aria-controls="note"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.companyConstructionNoteLetter') }}
            </a>
          </li>
          <li role="presentation">
            <a
              href="#docs"
              aria-controls="docs"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.documentLetterTab') }}
            </a>
          </li>
          <li role="presentation">
            <a
              href="#activityLogs"
              aria-controls="activityLogs"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.activityLogs') }}
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div
            id="certificateOfLicense"
            role="tabpanel"
            class="tab-pane active"
          >
            <div class="row">
              <template v-if="ministryNumbers.length">
                <div class="col-sm-3">
                  <select
                    v-model="number"
                    class="form-control"
                  >
                    <option
                      v-for="(ministryNumber, key) in ministryNumbers"
                      :key="key"
                      :value="ministryNumber.qr_code"
                    >
                      {{ getNumbers(ministryNumber.number) }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-9">
                  <div class="pull-right">
                    <button
                      class="btn btn-default margin-bottom"
                      @click="openUrl('certificate-of-license', number)"
                    >
                      <i class="fa fa-print" /> {{ $t('label.print') }}
                    </button>
                  </div>
                </div>
                <div class="col-sm-12">
                  <smart-iframe
                    ref="smartiframe"
                    :src="getUrl('certificate-of-license', number)"
                  />
                </div>
              </template>
              <template v-else>
                <div class="col-sm-12">
                  <under-development
                    :box="false"
                    :description="$t('string.theCompanyHaveNoLicense')"
                    svg="/img/svgs/empty.svg"
                  />
                </div>
              </template>
            </div>
          </div>
          <div
            id="letter"
            role="tabpanel"
            class="tab-pane"
          >
            <div class="row">
              <div class="col-md-12">
                <div class="pull-right">
                  <button
                    class="btn btn-default margin-bottom"
                    @click="openUrl('letter-of-license')"
                  >
                    <i class="fa fa-print" /> {{ $t('label.print') }}
                  </button>
                </div>
              </div>
            </div>
            <smart-iframe :src="getUrl('letter-of-license')" />
          </div>
          <div
            id="note"
            role="tabpanel"
            class="tab-pane"
          >
            <div class="row">
              <div class="col-md-12">
                <div class="pull-right">
                  <button
                    class="btn btn-default margin-bottom"
                    @click="openUrl('note-of-license')"
                  >
                    <i class="fa fa-print" /> {{ $t('label.print') }}
                  </button>
                </div>
              </div>
            </div>
            <smart-iframe :src="getUrl('note-of-license')" />
          </div>
          <div
            id="docs"
            role="tabpanel"
            class="tab-pane"
          >
            <upload-media
              model-name="company"
              preview-column="col-md-4"
              :button-label="$t('label.chooseReference')"
              :model-uuid="$route.params.company_uuid"
            />
          </div>
          <div
            id="activityLogs"
            role="tabpanel"
            class="tab-pane"
          >
            <activity-logs
              model_type="company"
              :model_uuid="$route.params.company_uuid"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShowCompanyDetail from "./includes/ShowCompanyDetail"
import ActivityLogs from "@/components/ActivityLogs"
import UploadMedia from "@/components/UploadMedia"
import SmartIframe from "@/components/SmartIframe"
import UnderDevelopment from "@/components/UnderDevelopment"

export default {
  name: "Show",
  components: {
    UnderDevelopment,
    SmartIframe,
    UploadMedia,
    ActivityLogs,
    ShowCompanyDetail,
  },
  metaInfo() {
    return {
      title: this.$t('string.showCompany'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  data() {
    return {
      ministryNumbers: [],
      number: null,
    }
  },
  watch: {
    number() {
      if (this.number) {
        if (this.$refs.smartiframe) {
          this.$refs.smartiframe.reloadIframe()
        }
      }
    }
  },
  mounted() {
    this.getMinistryNumbers()
  },
  methods: {
    getMinistryNumbers() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/company/registration/get-ministry-registration-numbers', {
        company_uuid: this.$route.params.company_uuid,
      }).then(({data}) => {
        this.ministryNumbers = data.data
        if (this.ministryNumbers.length > 0) {
          this.number = this.ministryNumbers[0]['qr_code']
        }
      })
    },
    getUrl(endpoint, number = null) {
      let url = `${this.baseUrl}/certificates/company/${endpoint}/${this.$route.params.company_uuid}`
      if (number) {
        url = `${url}/${number}`
      }
      return url
    },
    openUrl(endpoint, number = null) {
      window.open(this.getUrl(endpoint, number), '_blank')
    },
  }
}
</script>

<style scoped>

</style>
