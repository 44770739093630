import Team from "../views/team/Index"
import List from "../views/team/List"
import Permissions from "../permissions"
import Create from "../views/team/Create"
import Show from "../views/team/Show"
import Edit from "../views/team/Edit"

export const teamRouters = {
  path: 'team',
  name: 'team',
  component: Team,
  redirect: 'team',
  meta: {
    permissions: [Permissions.backend.team.manage],
    groups: 'team',
  },
  children: [
    {
      path: '',
      name: 'list-team',
      component: List,
      meta: {
        permissions: [Permissions.backend.team.list],
        groups: 'team',
        breadcrumb: [
          {
            name_en: 'Team',
            name_km: 'ក្រុមការងារ'
          },
          {
            name_en: 'Team List',
            name_km: 'បញ្ជីក្រុមការងារ'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-team',
      component: Create,
      meta: {
        permissions: [Permissions.backend.team.store],
        groups: 'team',
        breadcrumb: [
          {
            name_en: 'Team',
            name_km: 'ក្រុមការងារ'
          },
          {
            name_en: 'Create Team',
            name_km: 'បន្ថែមក្រុមការងារ'
          }
        ]
      }
    },
    {
      path: 'show/:team_uuid',
      name: 'show-team',
      component: Show,
      meta: {
        permissions: [Permissions.backend.team.show],
        groups: 'team',
        breadcrumb: [
          {
            name_en: 'Team',
            name_km: 'ក្រុមការងារ'
          },
          {
            name_en: 'Show Team',
            name_km: 'បង្ហាញព័ត៌មានក្រុមការងារ'
          }
        ]
      }
    },
    {
      path: 'edit/:team_uuid',
      name: 'edit-team',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.team.edit],
        groups: 'team',
        breadcrumb: [
          {
            name_en: 'Team',
            name_km: 'ក្រុមការងារ'
          },
          {
            name_en: 'Edit Team',
            name_km: 'កែប្រែព័ត៌មានក្រុមការងារ'
          }
        ]
      }
    },
  ]
}
