import Permissions from "../../permissions"

export const provinceCertificatesOccupancyRouters = {
  path: 'occupancy',
  component: () => import('@/views/provinceCertificates/occupancy/Index'),
  meta: {
    permissions: [Permissions.backend.certificateProvince.occupancy.manage],
    groups: 'certificates-occupancy-province',
  },
  children: [
    {
      path: '',
      name: 'list-certificate-occupancy-province',
      component: () => import('@/views/provinceCertificates/occupancy/List'),
      meta: {
        permissions: [Permissions.backend.certificateProvince.occupancy.list],
        groups: 'certificates-occupancy-province',
        breadcrumb: [
          {
            name_en: 'Certificates',
            name_km: 'វិញ្ញាបនបត្រ'
          },
          {
            name_en: 'Occupancy',
            name_km: 'ប្រើប្រាស់សំណង់'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'store',
      name: 'store-certificate-occupancy-province',
      component: () => import('@/views/provinceCertificates/occupancy/Store'),
      meta: {
        permissions: [Permissions.backend.certificateProvince.occupancy.store],
        groups: 'certificates-occupancy-province',
        breadcrumb: [
          {
            name_en: 'Certificates',
            name_km: 'វិញ្ញាបនបត្រ'
          },
          {
            name_en: 'Occupancy',
            name_km: 'ប្រើប្រាស់សំណង់'
          },
          {
            name_en: 'Store',
            name_km: 'រក្សាទុក'
          }
        ]
      }
    },
    {
      path: 'show/:occupancy_uuid',
      name: 'show-certificate-occupancy-province',
      component: () => import('@/views/provinceCertificates/occupancy/Show'),
      meta: {
        permissions: [Permissions.backend.certificateProvince.occupancy.show],
        groups: 'certificates-occupancy-province',
        breadcrumb: [
          {
            name_en: 'Certificates',
            name_km: 'វិញ្ញាបនបត្រ'
          },
          {
            name_en: 'Occupancy',
            name_km: 'ប្រើប្រាស់សំណង់'
          },
          {
            name_en: 'Show',
            name_km: 'មើល'
          }
        ]
      }
    },
    {
      path: 'edit/:occupancy_uuid',
      name: 'edit-certificate-occupancy-province',
      component: () => import('@/views/provinceCertificates/occupancy/Store'),
      meta: {
        permissions: [Permissions.backend.certificateProvince.occupancy.edit],
        groups: 'certificates-occupancy-province',
        breadcrumb: [
          {
            name_en: 'Certificates',
            name_km: 'វិញ្ញាបនបត្រ'
          },
          {
            name_en: 'Occupancy',
            name_km: 'ប្រើប្រាស់សំណង់'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួលព័ត៌មាន'
          }
        ]
      }
    },
    {
      path: 'approved/:occupancy_uuid',
      name: 'approved-certificate-occupancy-province',
      component: () => import('@/views/provinceCertificates/occupancy/Approval'),
      meta: {
        permissions: [Permissions.backend.certificateProvince.occupancy.approve],
        groups: 'certificates-occupancy-province',
        breadcrumb: [
          {
            name_en: 'Certificates',
            name_km: 'វិញ្ញាបនបត្រ'
          },
          {
            name_en: 'Occupancy',
            name_km: 'ប្រើប្រាស់សំណង់'
          },
          {
            name_en: 'Approved',
            name_km: 'អនុម័ត'
          }
        ]
      }
    },
    {
      path: 'upload/:occupancy_uuid',
      name: 'upload-certificate-occupancy-province',
      component: () => import('@/views/provinceCertificates/occupancy/Upload'),
      meta: {
        permissions: [Permissions.backend.certificateProvince.occupancy.upload],
        groups: 'certificates-occupancy-province',
        breadcrumb: [
          {
            name_en: 'Certificates',
            name_km: 'វិញ្ញាបនបត្រ'
          },
          {
            name_en: 'Occupancy',
            name_km: 'ប្រើប្រាស់សំណង់'
          },
          {
            name_en: 'Upload',
            name_km: 'បញ្ជូនឯកសារ'
          }
        ]
      }
    },
  ]
}
