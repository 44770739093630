import Permissions from "../../permissions"
import Index from '../../views/constructionLetter/Index'
import {openConstructionLetterRouters} from "./open"
import {closeConstructionLetterRouters} from "./close"

export const constructionLetterRouter = {
  path: 'construction-letter',
  name: 'construction-letter',
  component: Index,
  meta: {
    permissions: [Permissions.backend.constructionLetter.manage],
    groups: 'construction-letter',
  },
  children: [
    openConstructionLetterRouters,
    closeConstructionLetterRouters,
  ]
}
