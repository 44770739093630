<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('label.showLawRegulation') }}
      </h3>
      <div class="box-tools">
        <template v-if="can($Permissions.backend.lawRegulation.edit)">
          <button
            class="btn btn-primary btn-sm margin-right-5"
            @click="$router.push({
              name: 'edit-law-regulation',
              params: {
                law_regulation_uuid: $route.params.law_regulation_uuid,
              }
            })"
          >
            <i class="fa fa-edit" /> {{ $t('button.edit') }}
          </button>
        </template>
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <div class="form-horizontal">
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.titleKm') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ lawRegulation.title_km }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.titleEn') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ lawRegulation.title_en }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.issuedBy') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ lawRegulation.issued_by ? lawRegulation.issued_by : $t('string.na') }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.issuedNumber') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ lawRegulation.issued_number ? lawRegulation.issued_number : $t('string.na') }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.lawRegulationType') }}</label>
          <div class="col-sm-9">
            <template v-if="lawRegulation.type && lawRegulation.type !== null">
              : {{ lawRegulation.type['name_' + $i18n.locale] }}
            </template>
            <template v-else>
              : {{ $t('string.na') }}
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.effectiveDate') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ getDate(lawRegulation.effective_date, false, 'DD/MM/YYYY') }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.description') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ lawRegulation.description ? lawRegulation.description : $t('string.na') }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.resource') }}</label>
          <div class="col-sm-9">
            <template v-if="lawRegulation.resource && lawRegulation.resource!==null">
              <a
                :href="`${baseUrl}/${lawRegulation.resource}`"
                class="text-red"
                target="_blank"
              >
                <i class="fas fa-5x fa-file-pdf" />
              </a>
            </template>
            <template v-else>
              : {{ $t('label.no') }}
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.isPublished') }}</label>
          <div class="col-sm-9">
            <template v-if="lawRegulation.is_published">
              <label class="label label-primary label-outline">{{ $t('label.yes') }}</label>
            </template>
            <template v-else>
              <label class="label label-warning">{{ $t('label.no') }}</label>
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.createdAt') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ getDate(lawRegulation.created_at, false) }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.updatedAt') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ getDate(lawRegulation.updated_at, false) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('label.showLawRegulation'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      lawRegulation: {}
    }
  },
  created() {
    this.getLawRegulation()
  },
  methods: {
    getLawRegulation() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/law-regulation/show', {
        law_regulation_uuid: this.$route.params.law_regulation_uuid,
      }).then((res) => {
        this.lawRegulation = res.data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-law-regulation'})
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>
.control-label {
  padding-top: 0 !important;
}
</style>
