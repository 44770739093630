<template>
  <div class="form-horizontal">
    <div
      class="form-group row"
      :class="{'has-error' : validations.company_uuid}"
    >
      <label class="col-xs-2 control-label required">{{ $t('string.searchCompany') }}</label>
      <div class="col-xs-10">
        <multiselect
          v-model="selected_company"
          :options="companies"
          :disabled="$route.params.hasOwnProperty('company_uuid')"
          track-by="uuid"
          :internal-search="false"
          :placeholder="$t('string.searchCompany')"
          label="label"
          @search-change="searchCompany"
        />
        <template v-if="validations.company_uuid">
          <span class="label-error">{{ validations['company_uuid'][0] }}</span>
        </template>
      </div>
    </div>
    <div
      class="form-group row"
      :class="{'has-error' : validations.note_letter_requested_date}"
    >
      <label class="col-xs-2 control-label required">{{ $t('label.ownerRequestedDate') }}</label>
      <div class="col-xs-10">
        <date-picker
          v-model="note_letter_requested_date"
          type="date"
          :lang="datepickerLocale[$i18n.locale].lang"
          :placeholder="$t('label.ownerRequestedDate')"
          format="DD/MM/YYYY"
          class="table-full-width"
        />
        <template v-if="validations.note_letter_requested_date">
          <span class="label-error">{{ validations['note_letter_requested_date'][0] }}</span>
        </template>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-xs-10 col-xs-offset-2">
        <h4 class="main-title">
          {{ $t('string.content') }}
        </h4>
      </div>
    </div>
    <div
      class="form-group row"
      :class="contentValidation?'has-error':null"
    >
      <label class="control-label col-sm-2" />
      <div class="col-sm-10">
        <div class="row">
          <div class="col-md-12 margin-bottom">
            <div class="input-group">
              <input
                v-model="content"
                type="text"
                :placeholder="$t('string.content')"
                class="form-control"
                @keydown.enter="addNewContent"
              >
              <span
                class="input-group-btn"
                @click="addNewContent"
              >
                <button class="btn btn-default">
                  <i class="fa fa-plus" /> {{ $t('string.add') }}
                </button>
              </span>
            </div>
            <template v-if="contentValidation">
              <span class="label-error">{{ $t('string.requiredMessage') }}</span>
            </template>
          </div>
          <div class="col-md-12">
            <template v-if="contents.length>0">
              <draggable
                v-model="contents"
                class="list-group"
                tag="ul"
                v-bind="contents"
                @start="isDragging = true"
                @end="isDragging = false"
              >
                <transition-group
                  type="transition"
                  name="flip-list"
                >
                  <li
                    v-for="element in contents"
                    :key="element.id"
                    class="list-group-item"
                  >
                    <div class="drag-item">
                      <div class="drag-icon">
                        -
                      </div>
                      <div class="drag-title">
                        {{ element.value }}
                      </div>
                      <div class="drag-action">
                        <button
                          class="btn btn-danger btn-xs"
                          @click="onDeleteContent(element)"
                        >
                          <i class="fa fa-trash-alt" />
                        </button>
                      </div>
                    </div>
                  </li>
                </transition-group>
              </draggable>
            </template>
            <template v-else>
              <p class="text-muted text-center">
                {{ $t('string.noteNoContent') }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-xs-10 col-xs-offset-2">
        <h4 class="main-title">
          {{ $t('string.constructionLetterCommand') }}
        </h4>
      </div>
    </div>
    <div
      class="form-group row"
      :class="commandValidation ?'has-error':null"
    >
      <label class="control-label col-sm-2" />
      <div class="col-sm-10">
        <div class="row">
          <div class="col-md-12 margin-bottom">
            <div class="input-group">
              <input
                v-model="command"
                type="text"
                :placeholder="$t('string.command')"
                class="form-control"
                @keydown.enter="addNewCommand"
              >
              <span
                class="input-group-btn"
                @click="addNewCommand"
              >
                <button class="btn btn-default">
                  <i class="fa fa-plus" /> {{ $t('string.add') }}
                </button>
              </span>
            </div>
            <template v-if="commandValidation">
              <span class="label-error">{{ $t('string.requiredMessage') }}</span>
            </template>
          </div>
          <div class="col-md-12">
            <template v-if="commands.length>0">
              <draggable
                v-model="commands"
                class="list-group"
                tag="ul"
                v-bind="commands"
                @start="isDragging = true"
                @end="isDragging = false"
              >
                <transition-group
                  type="transition"
                  name="flip-list"
                >
                  <li
                    v-for="element in commands"
                    :key="element.id"
                    class="list-group-item"
                  >
                    <div class="drag-item">
                      <div class="drag-icon">
                        -
                      </div>
                      <div class="drag-title">
                        {{ element.value }}
                      </div>
                      <div class="drag-action">
                        <button
                          class="btn btn-danger btn-xs"
                          @click="onDeleteCommand(element)"
                        >
                          <i class="fa fa-trash-alt" />
                        </button>
                      </div>
                    </div>
                  </li>
                </transition-group>
              </draggable>
            </template>
            <template v-else>
              <p class="text-muted text-center">
                {{ $t('string.commandsNoContent') }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-10 col-sm-offset-2">
        <div class="margin-bottom">
          <h4 class="main-title">
            {{ $t('field.label.yobl_neayokadthan') }}
          </h4>
        </div>
        <textarea
          v-model="yobl_neayokadthan"
          :placeholder="$t('field.placeholder.yobl_neayokadthan')"
          class="form-control"
          rows="3"
        />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-10 col-sm-offset-2">
        <div class="margin-bottom">
          <h4 class="main-title">
            {{ $t('field.label.yobl_akkoneayokarong') }}
          </h4>
        </div>
        <textarea
          v-model="yobl_akkoneayokarong"
          :placeholder="$t('field.placeholder.yobl_akkoneayokarong')"
          class="form-control"
          rows="3"
        />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-10 col-sm-offset-2">
        <div class="margin-bottom">
          <h4 class="main-title">
            {{ $t('field.label.yobl_ekuttam_akkoneayok') }}
          </h4>
        </div>
        <textarea
          v-model="yobl_ekuttam_akkoneayok"
          :placeholder="$t('field.placeholder.yobl_ekuttam_akkoneayok')"
          class="form-control"
          rows="3"
        />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-10 col-sm-offset-2">
        <button
          class="btn btn-primary btn-sm margin-right-5"
          @click="save"
        >
          {{ $t('button.save') }}
        </button>
        <ButtonBack />
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from "debounce"
import draggable from 'vuedraggable'
import ButtonBack from "@/components/ButtonBack"
import {validationMixins} from "@/mixins/validations"

export default {
  name: "NoteLicenseCompanyForm",
  components: {
    ButtonBack,
    draggable,
  },
  mixins: [validationMixins],
  data() {
    return {
      validations: {},
      selected_project: null,
      companies: [],

      contents: [],
      content: '',
      contentValidation: false,

      construction_letters: [],
      construction_letter_selected_uuid: null,

      commands: [],
      command: '',
      commandValidation: false,

      selected_company: null,
      note_letter_requested_date: null,

      yobl_neayokadthan: null,
      yobl_akkoneayokarong: null,
      yobl_ekuttam_akkoneayok: null
    }
  },
  computed: {
    api() {
      return `${this.baseUrl}/api/backend/company/notes`
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
  },
  watch: {
    selected_company() {
      if (this.selected_company !== null) {
        // eslint-disable-next-line no-prototype-builtins
        if (!this.$route.params.hasOwnProperty('company_uuid')) {
          this.show()
        }
      }
    }
  },
  methods: {
    addNewCommand() {
      this.commandValidation = false
      if (this.command === '' || this.command === undefined) {
        this.commandValidation = true
        return 0
      }
      this.commands.push({
        id: parseInt(this.commands.length) + 1,
        value: this.command,
        order: parseInt(this.commands.length) + 1,
      })
      this.command = ''
    },
    addNewContent() {
      this.contentValidation = false
      if (this.content === '' || this.content === undefined) {
        this.contentValidation = true
        return 0
      }
      this.contents.push({
        id: parseInt(this.contents.length) + 1,
        value: this.content,
        order: parseInt(this.contents.length) + 1,
      })
      this.content = ''
    },
    save() {
      this.validations = {}
      this.$isLoading(true)
      let note_letter_requested_date = null
      if (this.note_letter_requested_date !== '' &&
        this.note_letter_requested_date !== undefined &&
        this.$moment(this.note_letter_requested_date).isValid()) {
        note_letter_requested_date = this.$moment(this.note_letter_requested_date).locale('en').format('YYYY-MM-DD')
      }
      this.$axios.post(`${this.api}/store`, {
        contents: this.contents,
        commands: this.commands,
        company_uuid: this.selected_company ? this.selected_company.uuid : null,
        note_letter_requested_date: note_letter_requested_date,
        yobl_neayokadthan: this.yobl_neayokadthan,
        yobl_akkoneayokarong: this.yobl_akkoneayokarong,
        yobl_ekuttam_akkoneayok: this.yobl_ekuttam_akkoneayok
      }).then(({data}) => {
        if (data.data) {
          this.showToastr()
          this.$router.push({
            name: 'list-notes-company'
          })
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          this.validations = error.response.data.errors
        } else {
          this.onResponseError(error)
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    searchCompany: debounce(function (query_text = null) {
      this.$axios.post(`${this.api}/search-company`, {
        query_text: query_text
      }).then(({data}) => {
        this.companies = data.data
      })
    }),
    show() {
      let company_uuid = null
      if (this.$route.params.company_uuid) {
        company_uuid = this.$route.params.company_uuid
      } else if (this.selected_company && this.selected_company.uuid) {
        company_uuid = this.selected_company.uuid
      }

      this.$axios.post(`${this.api}/show`, {
        company_uuid: company_uuid,
      }).then(({data}) => {
        if (data.data) {
          const company = data.data

          if (company.note_letter_requested_date !== '' &&
            company.note_letter_requested_date !== undefined &&
            this.$moment(company.note_letter_requested_date).isValid()) {
            this.note_letter_requested_date = this.$moment(company.note_letter_requested_date).toDate()
          }

          // eslint-disable-next-line no-prototype-builtins
          if (this.$route.params.hasOwnProperty('company_uuid')) {
            this.selected_company = {
              uuid: company.uuid,
              label: `(${company.first_registration_number}) - (${company.name_km})`
            }
          }

          if (company.contents && company.contents.length > 0) {
            this.contents = company.contents
          }
          if (company.commands && company.commands.length > 0) {
            this.commands = company.commands
          }
          if (company.yobl_neayokadthan) {
            this.yobl_neayokadthan = company.yobl_neayokadthan.value
          }
          if (company.yobl_akkoneayokarong) {
            this.yobl_akkoneayokarong = company.yobl_akkoneayokarong.value
          }
          if (company.yobl_ekuttam_akkoneayok) {
            this.yobl_ekuttam_akkoneayok = company.yobl_ekuttam_akkoneayok.value
          }
        }
      })
    },
    onDeleteContent(element) {
      this.onConfirm()
        .then((accept) => {
          if (accept) {
            this.contents.splice(this.contents.indexOf(element), 1)
          }
        })
    },
    onDeleteCommand(element) {
      this.onConfirm()
        .then((accept) => {
          if (accept) {
            this.commands.splice(this.commands.indexOf(element), 1)
          }
        })
    }
  },
  mounted() {
    this.searchCompany()
    this.show()
  }
}
</script>

<style scoped>
.input-group-addon {
  cursor: pointer;
}

.drag-item {
  display: grid;
  grid-template-columns: 20px 1fr 30px;
}

p.text-muted {
  padding: 25px;
  background-color: #dddddd;
  width: 100%;
}
</style>
