<template>
  <show-architecture
    model-route-edit="edit-professional-study-planner"
    :edit-permission="Permissions.backend.businessBuilders.professionalStudyPlanner.edit"
    :title="$t('string.showProfessionalStudyPlanner')"
  />
</template>

<script>
import ShowArchitecture from "../../architecture/Show"
import Permissions from "@/permissions"

export default {
  name: "Show",
  components: {ShowArchitecture},
  metaInfo() {
    return {
      title: this.$t('string.showProfessionalStudyPlanner'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data () {
    return {
      Permissions
    }
  }
}
</script>

<style scoped>

</style>
