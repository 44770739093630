<template>
  <div
    class="row"
    style="position: relative"
  >
    <div class="col-md-12">
      <div
        v-if="loadingMessage"
        class="spinner-container"
      >
        <spring-spinner
          class="mx-auto spinner-loading"
          :animation-duration="3000"
          :size="50"
          color="#3c8dbc"
        />
      </div>
      <iframe
        v-show="!loadingMessage"
        id="foo"
        name="generate"
        class="modal-template-height block-ifreme"
        :src="`${$base_api}/template/preview/${inspection.uuid}`"
        @load="onLoad"
      />
    </div>
    <div
      v-show="!loadingMessage"
      class="col-md-12"
    >
      <button
        class="btn btn-default margin-right-5"
        @click="reloadIfreme()"
      >
        <i class="fas fa-redo-alt" />
        {{ $t('label.reloadPDF') }}
      </button>
      <!-- Button trigger modal -->
      <button
        type="button"
        class="btn btn-primary"
        data-toggle="modal"
        data-target="#myModal"
      >
        <i class="fab fa-telegram-plane" />
        {{ $t('label.sendToTelegram') }}
      </button>
      <div
        id="myModal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
        data-keyboard="false"
        aria-labelledby="myModalLabel"
      >
        <div
          class="modal-dialog"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="resetForm"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4
                id="myModalLabel"
                class="modal-title"
              >
                {{ $t('string.sendToTelegram') }}
              </h4>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12">
                  <table class="table table-bordered table-condensed">
                    <tbody>
                      <tr>
                        <th>{{ $t('string.inspectionTypes') }}</th>
                        <td>{{ getType }}</td>
                      </tr>
                      <template v-if="project!==null">
                        <tr>
                          <td>{{ $t('string.projectType') }}</td>
                          <td>
                            <template v-if="project.project_type && project.project_type!==null">
                              {{ project.project_type['name_' + $i18n.locale] }}
                            </template>
                            <template v-else>
                              {{ $t('string.na') }}
                            </template>
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t('string.constructionType') }}</td>
                          <td>
                            <template v-if="project.construction_type && project.construction_type!==null">
                              {{ project.construction_type['name_' + $i18n.locale] }}
                            </template>
                            <template v-else>
                              {{ $t('string.na') }}
                            </template>
                          </td>
                        </tr>
                        <tr>
                          <th>{{ $t('string.project') }}</th>
                          <td>
                            <a
                              :href="getLinkOpenNewTab({
                                name: 'show-project',
                                params: {
                                  project_uuid: project.uuid,
                                }
                              })"
                              target="_blank"
                            >{{ project['name_' + $i18n.locale] || "No Name" }}</a>
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t('string.owners') }}</td>
                          <td>
                            <template v-if="project.owners && project.owners.length>0">
                              <people-chip-item
                                v-for="(people, key) in project.owners"
                                :key="key"
                                :people="getPreparePeople(people)"
                                model="people"
                              />
                            </template>
                            <template v-else>
                              {{ $t('string.na') }}
                            </template>
                          </td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr>
                          <th>{{ $t('string.project') }}</th>
                          <td>{{ $t('string.na') }}</td>
                        </tr>
                      </template>
                      <tr>
                        <th>{{ $t('string.permitNumber') }}</th>
                        <td>{{ inspection.permit_number }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('string.team') }}</th>
                        <td>
                          <template v-if="inspection.team!==null">
                            <a href="#">
                              {{ inspection.team['name_' + $i18n.locale ] }}
                            </a>
                          </template>
                          <template v-else>
                            {{ $t('string.na') }}
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <th>{{ $t('string.inspector') }}</th>
                        <td>
                          <template v-if="inspection.inspector!==null">
                            <a href="#">
                              {{ inspection.inspector.first_name }} {{ inspection.inspector.last_name }}
                            </a>
                          </template>
                          <template v-else>
                            {{ $t('string.na') }}
                          </template>
                        </td>
                      </tr>
                      <tr>
                        <th>{{ $t('string.inspectedAt') }}</th>
                        <td>{{ $moment(inspection.inspected_at).locale('km').format('[ថ្ងៃ]Do [ខែ]MMMM  [ឆ្នាំ]YYYY, [ម៉ោង]hh:mm[នាទី] a') }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('label.buildingProgress') }}</th>
                        <td>
                          <template
                            v-if="inspection.value && inspection.value.attendance && inspection.value.attendance.building_progress"
                          >
                            <template v-if="inspection.value.attendance.building_progress.percentage">
                              {{ inspection.value.attendance.building_progress.percentage }}%
                            </template>
                          </template>
                          <template v-else>
                            {{ $t('string.na') }}
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-12">
                  <select
                    v-model="officer_uuid"
                    name="officers"
                    class="form-control required"
                  >
                    <template v-for="(item, key) in team_officers">
                      <option
                        :key="key"
                        :value="null"
                      >
                        -- {{ $t('label.selectOfficer') }} --
                      </option>
                      <optgroup
                        :key="key + Math.random()"
                        :label="item['name_'+$i18n.locale]"
                      >
                        <option
                          v-for="(leader, key_leader) in item.leader"
                          :key="key_leader"
                          :value="leader.uuid"
                        >
                          {{ leader.title ? leader.title['name_'+ $i18n.locale]: '' }} {{ leader['name_' + $i18n.locale] }}
                        </option>
                        <option
                          v-for="(member, key_member) in item.members"
                          :key="key_member"
                          :value="member.uuid"
                        >
                          {{ member.title ? member.title['name_'+ $i18n.locale]: '' }} {{ member['name_' + $i18n.locale] }}
                        </option>
                      </optgroup>
                    </template>
                  </select>
                  <span
                    v-if="validations !== null && validations.hasOwnProperty('officer_uuid')"
                    class="label-error"
                  >
                    {{ validations['officer_uuid'][0] }}
                  </span>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                ref="close"
                type="button"
                class="btn btn-default"
                data-dismiss="modal"
                @click="resetForm"
              >
                {{ $t('button.close') }}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="sendInspection"
              >
                {{ $t('button.send') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SpringSpinner} from 'epic-spinners'

export default {
  name: "InspectionFormShowData",
  components: {
    SpringSpinner
  },
  props: {
    inspection: {
      required: true
    }
  },
  data() {
    return {
      team_officers: [],
      officer_uuid: null,
      loadingMessage: true,
      validations: null
    }
  },
  computed: {
    project() {
      if (this.inspection.project) {
        return this.inspection.project
      }
      return null
    },
    getType () {
      let value = null
      this.inspectionTypes.forEach((inspection) => {
        if (inspection.key === this.inspection.type) {
          value = inspection
        }
      })
      if (value !== null) {
        return value['name_' + this.$i18n.locale]
      }
      return null
    },
    inspectionTypes() {
      return [
        {
          key: 'before_approval',
          name_en: 'Controls to provide construction permit',
          name_km: 'ត្រួតពិនិត្យដើម្បីផ្ដល់លិខិតអនុញ្ញាតសាងសង់'
        },
        {
          key: 'after_approval',
          name_en: 'Controls for groundbreaking',
          name_km: 'ត្រួតពិនិត្យដើម្បីបើកការដ្ឋានសាងសង់'
        },
        {
          key: 'implementation',
          name_en: 'Construction Positioning border demarcation',
          name_km: 'ការបោះបង្គោលព្រំកំណត់ទីតាំងសាងសង់'
        },
        {
          key: 'following_up',
          name_en: 'Track construction',
          name_km: 'តាមដានដំណើរការសាងសង់'
        },
        {
          key: 'closing_construction',
          name_en: 'Construction inspection certificate',
          name_km: 'ត្រួតពិនិត្យផ្ដល់វិញ្ញាបនបត្រប្រើប្រាស់សំណង់'
        },
        {
          key: 'on_suing',
          name_en: 'Complaint Review',
          name_km: 'ត្រួតពិនិត្យពេលមានពាក្យបណ្ដឹង'
        },
      ]
    }
  },
  mounted() {
    this.getTeamByInspection()
  },
  methods: {
    onLoad() {
      this.loadingMessage = false
    },
    resetForm () {
      this.validations = null
      this.$refs.close.click()
      this.officer_uuid = null
    },
    reloadIfreme() {
      this.loadingMessage = true
      document.getElementById('foo').src += ''
    },
    getTeamByInspection() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/team/get-team-by-inspection', {
        inspection_uuid: this.inspection.uuid
      })
        .then((response) => {
          this.team_officers = response.data.data
        }).catch((error) => {
          this.onResponseError(error)
        })
    },
    sendInspection () {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/inspection/send-inspection-to-telegram', {
        inspection_uuid: this.inspection.uuid,
        officer_uuid: this.officer_uuid
      })
        .then((response) => {
          this.resetForm()
          this.$toastr('success', response.data.message['message_' + this.$i18n.locale], this.$t('string.error'))
        }).catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          }
          this.onResponseError(error)
        })
    }
  }
}
</script>

<style scoped>
  .modal-template-height {
    height: 865px;
  }

  .block-ifreme {
    width: 100%;
    border-radius: 0.5rem;
    border: none;
  }

  .spinner-container {
    width: 100%;
    height: 775px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
