import Permissions from "../../permissions"
import Index from '../../views/investorConstruction/company/Index'
import Edit from '../../views/investorConstruction/company/Edit'
import Show from '../../views/investorConstruction/company/Show'
import List from '../../views/investorConstruction/company/List'
import Create from '../../views/investorConstruction/company/Create'
import ChangeCategory from '../../views/company/ChangeCategory'

export const companyInvestorConstructionRouters = {
  path: 'company',
  name: 'company',
  component: Index,
  redirect: 'company',
  meta: {
    permissions: [Permissions.backend.investorConstruction.company.manage],
    groups: 'company',
  },
  children: [
    {
      path: '',
      name: 'list-company',
      component: List,
      meta: {
        permissions: [Permissions.backend.investorConstruction.company.list],
        groups: 'company',
        breadcrumb: [
          {
            name_en: 'Investor Construction',
            name_km: 'អ្នកវិនិយោគក្នុងវិស័យសំណង់'
          },
          {
            name_en: 'Company',
            name_km: 'រូនវន្តបុគ្គល'
          },
          {
            name_en: 'Company List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-company',
      component: Create,
      meta: {
        permissions: [Permissions.backend.investorConstruction.company.store],
        groups: 'company',
        breadcrumb: [
          {
            name_en: 'Investor Construction',
            name_km: 'អ្នកវិនិយោគក្នុងវិស័យសំណង់'
          },
          {
            name_en: 'Company',
            name_km: 'រូនវន្តបុគ្គល'
          },
          {
            name_en: 'Create',
            name_km: 'បង្កើត'
          }
        ]
      }
    },
    {
      path: 'show/:company_uuid',
      name: 'show-company',
      component: Show,
      meta: {
        permissions: [Permissions.backend.investorConstruction.company.show],
        groups: 'company',
        breadcrumb: [
          {
            name_en: 'Investor Construction',
            name_km: 'អ្នកវិនិយោគក្នុងវិស័យសំណង់'
          },
          {
            name_en: 'Company',
            name_km: 'រូនវន្តបុគ្គល'
          },
          {
            name_en: 'Show',
            name_km: 'មើល'
          }
        ]
      }
    },
    {
      path: 'edit/:company_uuid',
      name: 'edit-company',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.investorConstruction.company.edit],
        groups: 'company',
        breadcrumb: [
          {
            name_en: 'Investor Construction',
            name_km: 'អ្នកវិនិយោគក្នុងវិស័យសំណង់'
          },
          {
            name_en: 'Company',
            name_km: 'រូនវន្តបុគ្គល'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'change-category/:company_uuid',
      name: 'change-category-company',
      component: ChangeCategory,
      meta: {
        permissions: [Permissions.backend.investorConstruction.company.changeCategory],
        groups: 'company',
        breadcrumb: [
          {
            name_en: 'Investor Construction',
            name_km: 'អ្នកវិនិយោគក្នុងវិស័យសំណង់'
          },
          {
            name_en: 'Company',
            name_km: 'រូនវន្តបុគ្គល'
          },
          {
            name_en: 'Change Category',
            name_km: 'កែប្រែប្រភេទ'
          },
        ]
      }
    },
  ]
}
