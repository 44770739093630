<template>
  <div class="row">
    <div
      v-if="config.NODE_ENV === 'development'"
      class="col-md-12"
    >
      <div class="alert alert-success alert-dismissible">
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-hidden="true"
        >
          ×
        </button>
        <h4><i class="fa fa-info-circle" /> {{ $t('string.welcomeTo') }} {{ config.VUE_APP_NAME }}.</h4>
        <p>{{ $t('string.intro') }}</p>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row">
        <small-box
          v-for="(count, key) in counts"
          :key="key"
          :bg="count.bg"
          :route="count.route"
          :title="$t(count.title)"
          :value="count.nb"
          :value-on-right="count.value_on_right"
        />
      </div>
    </div>
    <template v-if="floor">
      <div class="col-md-6">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('label.buildingHeight') }} ( {{ $t('label.floor') }} )
            </h3>
          </div>
          <div class="box-body">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>{{ $t('label.floor') }}</th>
                  <th>{{ $t('label.total') }}</th>
                  <th>{{ $t('label.thisYear') }}</th>
                  <th>{{ $t('label.thisMonth') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, key) in floor">
                  <tr :key="key">
                    <td>{{ item['name_' + $i18n.locale] }}</td>
                    <td>{{ item.total }}</td>
                    <td>{{ item.year }}</td>
                    <td>{{ item.month }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
    <template v-if="business_builders">
      <div class="col-md-6">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('menu.businessBuilder') }}
            </h3>
          </div>
          <div class="box-body">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th />
                  <th>{{ $t('label.total') }}</th>
                  <th>{{ $t('label.thisYear') }}</th>
                  <th>{{ $t('label.thisMonth') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, key) in business_builders">
                  <tr :key="key">
                    <td>{{ item['name_' + $i18n.locale] }}</td>
                    <td>{{ item.total }}</td>
                    <td>{{ item.year }}</td>
                    <td>{{ item.month }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>

    <template v-if="investment_budget">
      <div class="col-md-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('label.investmentBudgetByProjectType') }} (%)
            </h3>
          </div>
          <div class="box-body">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>{{ $t('label.projectType') }}</th>
                  <th>{{ $t('label.total') }}</th>
                  <th>{{ $t('label.thisYear') }}</th>
                  <th>{{ $t('label.thisMonth') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, key) in investment_budget">
                  <tr :key="key">
                    <td>{{ item['name_' + $i18n.locale] }}</td>
                    <td>{{ item.investment_budget_total }}%</td>
                    <td>{{ item.investment_budget_year }}%</td>
                    <td>{{ item.investment_budget_month }}%</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
    <construct-by-location />
    <construct-by-year />
    <construct-has-problem />
  </div>
</template>

<script>
import SmallBox from "../../../components/SmallBox"
import ConstructByLocation from "../sections/ConstructByLocation"
import ConstructByYear from "../sections/ConstructByYear"
import ConstructHasProblem from "../sections/ConstructHasProblem"

export default {
  name: 'DashboardLevelMinistry',
  components: {ConstructHasProblem, ConstructByYear, ConstructByLocation, SmallBox},
  metaInfo() {
    return {
      title: 'Dashboard',
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      config: process.env,
      business_builders: [],
      floor: [],
      investment_budget: [],
      counts: [
        {
          title: 'count.project',
          nb: 0,
          bg: 'card-1',
          value_on_right: true,
          route: {name: 'list-project'},
        },
        {
          title: 'count.company',
          nb: 0,
          bg: 'card-3',
          value_on_right: true,
          route: {name: 'list-construction-company'},
        },
        {
          title: 'count.personality',
          nb: 0,
          icon: 'user-cog',
          bg: 'card-2',
          value_on_right: true,
          route: {name: 'list-professional-study-planner'},
        },
      ]
    }
  },
  mounted() {
    this.getCounts()
    this.getDataDashboardSummary()
  },
  methods: {
    getCounts() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/report/get-counts')
        .then((res) => {
          let data = res.data.data
          this.counts[0].nb = [
            {'label': this.$t('label.total'), 'value': this.formatPrice(data['project'])},
            {'label': this.$t('label.thisYear'), 'value': this.formatPrice(data['project_this_year'])},
            {'label': this.$t('label.thisMonth'), 'value': this.formatPrice(data['project_this_month'])}
          ]

          this.counts[1].nb = [
            {'label': this.$t('label.total'), 'value': this.formatPrice(data['company'])},
            {'label': this.$t('label.thisYear'), 'value': this.formatPrice(data['company_this_year'])},
            {'label': this.$t('label.thisMonth'), 'value': this.formatPrice(data['company_this_month'])}
          ]

          this.counts[2].nb = [
            {'label': this.$t('label.total'), 'value': this.formatPrice(data['personality'])},
            {'label': this.$t('label.thisYear'), 'value': this.formatPrice(data['personality_this_year'])},
            {'label': this.$t('label.thisMonth'), 'value': this.formatPrice(data['personality_this_month'])}
          ]
        })
    },

    getDataDashboardSummary() {
      this.$axios.post(this.$base_api + '/api/backend/report/get-data-for-dashboard-summary')
        .then((res) => {
          let data = res.data.data
          this.business_builders = data.business_builders
          this.floor = data.floor
          this.investment_budget = data.investment_budget
        })
    },
  },
}
</script>
