<template>
  <div
    v-if="projectSubType!==null"
    class="box box-primary"
  >
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('label.showProjectSubType') }}
      </h3>
      <div class="box-tools">
        <button
          v-if="can($Permissions.backend.setting.projectSubType.edit)"
          class="btn btn-primary btn-sm margin-right-5"
          @click="$router.push({
            name: 'edit-project-sub-type',
            params: {
              project_sub_type_uuid: $route.params.project_sub_type_uuid,
            }
          })"
        >
          <i class="fa fa-edit" /> {{ $t('button.edit') }}
        </button>
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <div class="form-horizontal">
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.nameKm') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ projectSubType.name_km }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.nameEn') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ projectSubType.name_en }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.projectType') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ projectSubType.project_type['name_' + $i18n.locale] }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.createdAt') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ getDate(projectSubType.created_at, false) }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.updatedAt') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ getDate(projectSubType.updated_at, false) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('label.showConstructionType'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      projectSubType: null,
    }
  },
  mounted() {
    this.getProjectSubType()
  },
  methods: {
    getProjectSubType() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-sub-type/show', {
        project_sub_type_uuid: this.$route.params.project_sub_type_uuid,
      }).then(({data}) => {
        this.projectSubType = data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-project-sub-type'})
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>
.control-label {
  padding-top: 0 !important;
}
</style>
