<template>
  <div class="row">
    <div class="col-md-12">
      <div class="nav-tabs-custom">
        <ul
          class="nav nav-tabs"
          role="tablist"
        >
          <li
            role="presentation"
            class="active"
          >
            <a
              href="#company"
              aria-controls="company"
              role="tab"
              data-toggle="tab"
            >{{ $t('tab.company') }}</a>
          </li>
          <li role="presentation">
            <a
              href="#galleries"
              aria-controls="galleries"
              role="tab"
              data-toggle="tab"
            >{{ $t('tab.attachDocument') }}</a>
          </li>
          <li class="pull-right">
            <ButtonBack />
          </li>
        </ul>
        <div class="tab-content">
          <div
            id="company"
            role="tabpanel"
            class="tab-pane active"
          >
            <registration-company-form />
          </div>
          <div
            id="galleries"
            role="tabpanel"
            class="tab-pane"
          >
            <upload-media
              model-name="company"
              preview-column="col-md-4"
              :button-label="$t('label.chooseReference')"
              :model-uuid="$route.params.company_uuid"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegistrationCompanyForm from "./Form"
import UploadMedia from "../../../components/UploadMedia"
import ButtonBack from "@/components/ButtonBack"

export default {
  name: "Edit",
  components: {
    ButtonBack,
    UploadMedia,
    RegistrationCompanyForm
  },
  metaInfo() {
    return {
      title: this.$t("string.editCompany"),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
}
</script>

<style scoped>

</style>
