import {getField, updateField} from "vuex-map-fields"

export const state = () => {
  return {
    show_form: true
  }
}

export const getters = {
  getField
}

export const mutations = {
  updateField
}

export const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
