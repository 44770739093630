<template>
  <architecture-data-table
    :title="$t('string.professionalStudyPlanner')"
    model-route-create="create-professional-study-planner"
    model-route-edit="edit-professional-study-planner"
    model-route-view="show-professional-study-planner"
    model-route-register="register-professional-study-planner"
    :permission-create="Permissions.backend.businessBuilders.professionalStudyPlanner.store"
    :permission-filter="Permissions.backend.businessBuilders.professionalStudyPlanner.filter"
    :architecture-category-id="[3]"
    :architecture-type-id="[1,2]"
  />
</template>

<script>
import ArchitectureDataTable from "../../architecture/Table"
import Permissions from "@/permissions"

export default {
  name: "List",
  components: {ArchitectureDataTable,},
  metaInfo() {
    return {
      title: this.$t('string.professionalStudyPlanner'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data () {
    return {
      Permissions
    }
  }
}
</script>

<style scoped>

</style>
