import LandUniqueNumberType from "./../../views/settings/landUniqueNumberType/Index"
import List from "./../../views/settings/landUniqueNumberType/List"
import Create from "./../../views/settings/landUniqueNumberType/Create"
import Edit from "./../../views/settings/landUniqueNumberType/Edit"
import Show from "./../../views/settings/landUniqueNumberType/Show"
import Permissions from "../../permissions"

export const landUniqueNumberTypeRouters = {
  path: 'land-unique-number-type',
  component: LandUniqueNumberType,
  redirect: 'land-unique-number-type',
  meta: {
    permissions: [Permissions.backend.setting.landUniqueNumberType.manage],
    groups: 'land-unique-number-type',
  },
  children: [
    {
      path: '',
      name: 'list-land-unique-number-type',
      component: List,
      meta: {
        permissions: [Permissions.backend.setting.landUniqueNumberType.list],
        groups: 'land-unique-number-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Land Unique Number Type',
            name_km: 'ប្រភេទប័ណ្ណដី'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-land-unique-number-type',
      component: Create,
      meta: {
        permissions: [Permissions.backend.setting.landUniqueNumberType.store],
        groups: 'land-unique-number-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Land Unique Number Type',
            name_km: 'ប្រភេទប័ណ្ណដី'
          },
          {
            name_en: 'New',
            name_km: 'បង្កើតថ្មី'
          }
        ]
      }
    },
    {
      path: 'edit/:land_unique_number_type_uuid',
      name: 'edit-land-unique-number-type',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.setting.landUniqueNumberType.edit],
        groups: 'land-unique-number-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Land Unique Number Type',
            name_km: 'ប្រភេទប័ណ្ណដី'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'show/:land_unique_number_type_uuid',
      name: 'show-land-unique-number-type',
      component: Show,
      meta: {
        permissions: [Permissions.backend.setting.landUniqueNumberType.show],
        groups: 'land-unique-number-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Land Unique Number Type',
            name_km: 'ប្រភេទប័ណ្ណដី'
          },
          {
            name_en: 'View',
            name_km: 'មើល'
          }
        ]
      }
    }
  ]
}
