import {mapState} from 'vuex'

export const projectMixin = {
  data() {
    return {
      provinces: [],
      districts: [],
      communes: [],
      villages: [],

      province_id: '',
      district_id: '',
      commune_id: '',
      village_id: '',
      projectUnits: [],

      isFetchingOwners: false,

      opens: [],
      stops: [],
      pending: [],
      complaints: [],
      ministry_permits: [],
      ministryPermitOrLicenses: [],
      construction_confirmed_and_checked_by_companies: [],
      construction_confirmed_and_checked_by_architectures: [],
      engineering_studied_by_companies: [],
      engineering_studied_by_engineers: [],
      engineering_drawn_by_companies: [],
      engineering_drawn_by_engineers: [],
      engineering_calculated_by_companies: [],
      engineering_calculated_by_engineers: [],
      engineering_reviewed_by_companies: [],
      engineering_reviewed_by_engineers: [],
      engineering_agreed_by_companies: [],
      engineering_agreed_by_engineers: [],
      engineering_confirmed_and_checked_by_companies: [],
      engineering_confirmed_and_checked_by_engineers: [],

      ownerCto: [],
      regulationTypes: [
        {direction_id: -1, name: this.$t('string.front'), values: {law: '', request: '', practical: '',}},
        {direction_id: -1, name: this.$t('string.back'), values: {law: '', request: '', practical: '',}},
        {direction_id: -1, name: this.$t('string.left'), values: {law: '', request: '', practical: '',}},
        {direction_id: -1, name: this.$t('string.right'), values: {law: '', request: '', practical: '',}},
      ],

      landUniqueNumbers: [],

      projectBuildings: [],
    }
  },
  watch: {
    'project.project_companies'() {
      if (this.project.project_companies.length === 0) {
        this.ownerCto = []
      }
    },
    'projectBuildings': {
      handler: function () {
        let buildings = ''
        let high = ''
        let floor = ''
        let underground_floor = ''
        if (this.projectBuildings.length > 0) {
          buildings = this.projectBuildings.map(item => {
            if (item.number_building !== '' && parseFloat(item.number_building) > 0) {
              return parseFloat(item.number_building)
            }
            return 0
          })
          high = this.projectBuildings.map(item => {
            if (item.high !== '') {
              if (parseFloat(item.high) > 0) {
                return parseFloat(item.high)
              }
            }
            return 0
          })
          floor = this.projectBuildings.map(item => {
            if (item.floor !== '') {
              if (parseFloat(item.floor) > 0) {
                return parseFloat(item.floor)
              }
            }
            return 0
          })
          underground_floor = this.projectBuildings.map(item => {
            if (item.underground_floor !== '') {
              if (parseFloat(item.underground_floor) > 0) {
                return parseFloat(item.underground_floor)
              }
            }
            return 0
          })
        }
        if (buildings !== '' && buildings.length > 0) {
          this.project.nb_building_request = buildings.reduce((total, currentItem) => total + currentItem)
        }
        if (high !== '' && high.length > 0) {
          this.project.high = Math.max(...high)
        }
        if (floor !== '' && floor.length > 0) {
          this.project.nb_floor_request = Math.max(...floor)
        }
        if (underground_floor !== '' && underground_floor.length > 0) {
          this.project.nb_underground_floor_request = Math.max(...underground_floor)
        }
      },
      deep: true,
    }
  },
  methods: {
    addNewBuilding () {
      let building = {}
      this.$set(building, 'floor', '')
      this.$set(building, 'high', '')
      this.$set(building, 'number', parseInt(this.projectBuildings.length) + 1)
      this.$set(building, 'number_building', 1)
      this.$set(building, 'name', '')
      this.$set(building, 'description', '')
      this.$set(building, 'underground_floor', '')

      // practical
      this.$set(building, 'floor_practical', '')
      this.$set(building, 'high_practical', '')
      this.$set(building, 'number_building_practical', '')
      this.$set(building, 'description_practical', '')
      this.$set(building, 'underground_floor_practical', '')

      this.projectBuildings.push(building)
    },
    isValidLandUniqueNumber(landUniqueNumber) {
      landUniqueNumber.valid = true
      if (
        landUniqueNumber.land_unique_number_type_id === 1 &&
        landUniqueNumber.land_unique_number !== '' &&
        landUniqueNumber.land_unique_number.length > 0
      ) {
        if (landUniqueNumber.land_unique_number.length === 13) {
          landUniqueNumber.valid = true
        } else {
          landUniqueNumber.valid = false
        }
      }
      return landUniqueNumber.valid
    },
    async getProvince() {
      const response = await this.$axios.post(process.env.VUE_APP_API + '/api/backend/location/get-location')
      this.provinces = await response.data.data
      if (this.province_id) {
        await this.getDistrict().then(() => {
          if (this.district_id) {
            this.getCommune().then(() => {
              if (this.commune_id) {
                this.getVillage()
              }
            })
          }
        })
      }
    },
    async getDistrict() {
      const response = await this.$axios.post(process.env.VUE_APP_API + '/api/backend/location/get-location', {
        type: 'district',
        parent_id: this.province_id
      })
      this.districts = await response.data.data
    },
    async getCommune() {
      const response = await this.$axios.post(process.env.VUE_APP_API + '/api/backend/location/get-location', {
        type: 'commune',
        parent_id: this.district_id
      })
      this.communes = await response.data.data
    },
    async getVillage() {
      const response = await this.$axios.post(process.env.VUE_APP_API + '/api/backend/location/get-location', {
        type: 'village',
        parent_id: this.commune_id
      })
      this.villages = await response.data.data
    },
    getProjectUnits() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-unit/get-option',)
        .then((res) => {
          if (res.data.data.length > 0) {
            this.projectUnits = res.data.data
          } else {
            this.projectUnits = []
          }
        })
    },
    async getProject() {
      const response = await this.$axios.post(process.env.VUE_APP_API + '/api/backend/project/show', {
        project_uuid: this.$route.params.project_uuid,
      })
      let project = await response.data.data
      if (project) {
        this.setProject(project)
        if (project.project_type_id) {
          this.getProjectSubTypes(project.project_type_id)
        }
        // ProjectBuildings
        if (project.buildings && project.buildings.length > 0) {
          this.projectBuildings = project.buildings.map((building) => {
            return {
              number: building.number,
              name: building.name !== null ? building.name : '',
              number_building: building.number_building !== null ? building.number_building : '',
              floor: building.floor !== null ? building.floor : '',
              underground_floor: building.underground_floor !== null ? building.underground_floor : '',
              high: building.high !== null ? building.high : '',
              description: building.description !== null ? building.description : '',
              number_building_practical: building.number_building_practical !== null ? building.number_building_practical : '',
              floor_practical: building.floor_practical !== null ? building.floor_practical : '',
              underground_floor_practical: building.underground_floor_practical !== null ? building.underground_floor_practical : '',
              high_practical: building.high_practical !== null ? building.high_practical : '',
              description_practical: building.description_practical !== null ? building.description_practical : '',
            }
          })
        }

        // LandUniqueNumbers
        if (project.land_unique_numbers && project.land_unique_numbers.length > 0) {
          this.landUniqueNumbers = project.land_unique_numbers.map((item) => {
            if (item.date !== null && this.$moment(item.date).isValid()) {
              this.$set(item, 'land_unique_number_registration_date', this.$moment(item.date).toDate())
            } else {
              this.$set(item, 'land_unique_number_registration_date', '')
            }
            if (item.number !== null) {
              this.$set(item, 'land_unique_number', item.number)
            } else {
              this.$set(item, 'land_unique_number', '')
            }
            this.$set(item, 'valid', true)
            return item
          })
        }

        // stand_by_engineers
        if (project.stand_by.length > 0) {
          this.$set(this.project, 'stand_by_engineers', project.stand_by)
        }
        // project type
        if (project.project_type !== null) {
          this.$store.commit('constructionType/setProjectTypeId', project.project_type.id)
        }
        // engineering
        this.engineering_studied_by_companies = project.engineering_studied_by_companies
        this.engineering_studied_by_engineers = project.engineering_studied_by_engineers
        this.engineering_drawn_by_companies = project.engineering_drawn_by_companies
        this.engineering_drawn_by_engineers = project.engineering_drawn_by_engineers
        this.engineering_calculated_by_companies = project.engineering_calculated_by_companies
        this.engineering_calculated_by_engineers = project.engineering_calculated_by_engineers
        this.engineering_reviewed_by_companies = project.engineering_reviewed_by_companies
        this.engineering_reviewed_by_engineers = project.engineering_reviewed_by_engineers
        this.engineering_agreed_by_companies = project.engineering_agreed_by_companies
        this.engineering_agreed_by_engineers = project.engineering_agreed_by_engineers
        this.engineering_confirmed_and_checked_by_companies = project.engineering_confirmed_and_checked_by_companies
        this.engineering_confirmed_and_checked_by_engineers = project.engineering_confirmed_and_checked_by_engineers

        // construction company
        if (project.construction_confirmed_and_checked_by_architectures.length > 0) {
          this.construction_confirmed_and_checked_by_architectures = project.construction_confirmed_and_checked_by_architectures
        }
        if (project.construction_confirmed_and_checked_by_companies.length > 0) {
          this.construction_confirmed_and_checked_by_companies = project.construction_confirmed_and_checked_by_companies
        }

        // ministry permits
        if (project.ministry_permits && project.ministry_permits.length > 0) {
          this.ministry_permits = project.ministry_permits.map((ministry_permit) => {
            return {
              ministry_permit_or_license_id: ministry_permit.ministry_permit_or_license_id,
              number: ministry_permit.number,
              approved_by: ministry_permit.approved_by,
              type_validate: true,
              date: this.$moment(ministry_permit.date).isValid() ? this.$moment(ministry_permit.date).locale('en').toDate() : '',
            }
          })
        }

        // architectural section
        this.$set(this.project, 'architectural_studied_by_architects', project.architectural_studied_by_architects)
        this.$set(this.project, 'architectural_drawn_by_architects', project.architectural_drawn_by_architects)
        this.$set(this.project, 'architectural_confirmed_and_checked_by_architects', project.architectural_confirmed_and_checked_by_architects)
        this.$set(this.project, 'architectural_studied_by_companies', project.architectural_studied_by_companies)
        this.$set(this.project, 'architectural_drawn_by_companies', project.architectural_drawn_by_companies)
        this.$set(this.project, 'architectural_confirmed_and_checked_by_companies', project.architectural_confirmed_and_checked_by_companies)

        // land unique number
        if (project.land_unique_number_type !== null) {
          this.$set(this.project, 'land_unique_number_type', {
            id: project.land_unique_number_type.id,
            name_en: project.land_unique_number_type.name_en,
            name_km: project.land_unique_number_type.name_km,
          })
        }
        if (this.$moment(project.land_unique_number_registration_date).locale('en').isValid()) {
          this.$set(this.project, 'land_unique_number_registration_date', this.$moment(project.land_unique_number_registration_date).locale('en').toDate())
        }
        if (this.$moment(project.insurance_date).locale('en').isValid()) {
          this.$set(this.project, 'insurance_date', this.$moment(project.insurance_date).locale('en').toDate())
        }

        // project has regulation gaps
        if (project.regulation_gaps && project.regulation_gaps.length > 0 && project.regulation_gaps.length <= 4) {
          let data = project.regulation_gaps
          this.regulationTypes = [
            {
              direction_id: data[0] && data[0]['direction_front_id'] !== null ? data[0]['direction_front_id'] : -1,
              name: this.$t('string.front'),
              values: {law: data[0]['front'], request: data[1]['front'], practical: data[2]['front']}
            },
            {
              direction_id: data[0] && data[0]['direction_back_id'] !== null ? data[0]['direction_back_id'] : -1,
              name: this.$t('string.back'),
              values: {law: data[0]['back'], request: data[1]['back'], practical: data[2]['back']}
            },
            {
              direction_id: data[0] && data[0]['direction_left_id'] !== null ? data[0]['direction_left_id'] : -1,
              name: this.$t('string.left'),
              values: {law: data[0]['left'], request: data[1]['left'], practical: data[2]['left']}
            },
            {
              direction_id: data[0] && data[0]['direction_right_id'] !== null ? data[0]['direction_right_id'] : -1,
              name: this.$t('string.right'),
              values: {law: data[0]['right'], request: data[1]['right'], practical: data[2]['right']}
            },
          ]
        }

        // complaints
        if (project.complaints && project.complaints.length > 0) {
          project.complaints.forEach((complaint) => {
            this.complaints.push({
              name: complaint.name,
              date: this.$moment(complaint.date).isValid() ? this.$moment(complaint.date).locale('en').toDate() : null,
              phone: complaint.phone,
              desc: complaint.description,
              solution: complaint.solution,
            })
          })
        }

        // project owners
        this.$set(this.project, 'project_owners', [])
        if (project.owners && project.owners.length > 0) {
          project.owners.forEach((owner) => {
            this.project_owners.push(owner)
          })
        }
        // open construction numbers
        if (project.open_construction_numbers && project.open_construction_numbers.length > 0) {
          this.opens = project.open_construction_numbers.map((item) => {
            return {
              number: item.number,
              date: this.$moment(item.date).toDate(),
              uuid: item.uuid,
            }
          })
        }

        // stop construction numbers
        if (project.stop_construction_numbers && project.stop_construction_numbers.length > 0) {
          this.stops = project.stop_construction_numbers.map((item) => {
            return {
              number: item.number,
              date: this.$moment(item.date).toDate(),
              uuid: item.uuid,
            }
          })
        }

        // pending construction numbers
        if (project.pending_construction_numbers && project.pending_construction_numbers.length > 0) {
          this.pending = project.pending_construction_numbers.map((item) => {
            return {
              number: item.number,
              date: this.$moment(item.date).toDate(),
            }
          })
        }

        // address
        if (project.province_id && project.province_id !== null) {
          this.province_id = project.province_id
        }
        if (project.district_id && project.district_id !== null) {
          this.district_id = project.district_id
        }
        if (project.commune_id && project.commune_id !== null) {
          this.commune_id = project.commune_id
        }
        if (project.village_id && project.village_id !== null) {
          this.village_id = project.village_id
        }

        // unit
        if (project.project_has_units && project.project_has_units.length > 0) {
          this.projectUnits = project.project_has_units.map((unit) => {
            return {
              id: unit.id,
              name_en: unit.name_en,
              name_km: unit.name_km,
              value: unit.value,
            }
          })
        }

        // date
        for (let key in project) {
          if (project[key] !== null && this.dateFields.includes(key)) {
            this.$set(this.project, key, this.$moment(project[key]).local('en').toDate())
          }
        }
        // investment countries
        this.$set(this.project, 'investment_countries', [])
        if (project.investment_countries && project.investment_countries.length > 0) {
          project.investment_countries.forEach((country) => {
            this.project.investment_countries.push({
              id: country.id,
              name_en: country.name_en,
              name_km: country.name_km
            })
          })
        }
        // companies
        this.$set(this.project, 'project_companies', [])
        if (project.companies && project.companies.length > 0) {
          this.project.project_companies = project.companies
        }
      } else {
        this.project = null
      }
    },
    setProject(data) {
      this.project = {
        id: data.id,
        project_uuid: data.uuid,
        name_en: data.name_en,
        name_km: data.name_km,
        nb_building_request: data.nb_building_request,
        nb_building: data.nb_building,
        surface: data.surface,
        construction_surface: data.construction_surface,
        nb_floor_request: data.nb_floor_request,
        nb_floor: data.nb_floor,
        project_type: data.project_type,
        high: data.high,
        investment_budget: data.investment_budget || '',
        nb_workers: data.nb_workers,
        land_unique_number: data.land_unique_number,
        permit_number: data.permit_number,
        construction_open_number: data.construction_open_number,
        construction_stop_number: data.construction_stop_number,
        construction_pending_number: data.construction_pending_number,
        is_constructed_before_date: data.is_constructed_before_date,
        lat: data.lat,
        lng: data.lng,
        x: data.x,
        y: data.y,
        construction_type: data.construction_type,
        address1: data.address1,
        address2: data.address2,
        company_representatives: data.company_representatives,
        index_permit: data.index_permit,
        index_practical: data.index_practical,
        index_over: data.index_over,
        construction_surface_can_processed: data.construction_surface_can_processed,
        is_constructed_before_date_description: data.is_constructed_before_date_description,
        worker_salary: data.worker_salary,
        nb_professionals: data.nb_professionals,
        professional_salary: data.professional_salary,
        nb_architects: data.nb_architects,
        architect_salary: data.architect_salary,
        nb_engineers: data.nb_engineers,
        engineer_salary: data.engineer_salary,
        number_parking_bike_permit: data.number_parking_bike_permit,
        number_parking_bike_request: data.number_parking_bike_request,
        number_parking_bike_practical: data.number_parking_bike_practical,
        number_parking_motor_permit: data.number_parking_motor_permit,
        number_parking_motor_request: data.number_parking_motor_request,
        number_parking_motor_practical: data.number_parking_motor_practical,
        number_parking_small_car_permit: data.number_parking_small_car_permit,
        number_parking_small_car_request: data.number_parking_small_car_request,
        number_parking_small_car_practical: data.number_parking_small_car_practical,
        number_parking_big_car_permit: data.number_parking_big_car_permit,
        number_parking_big_car_request: data.number_parking_big_car_request,
        number_parking_big_car_practical: data.number_parking_big_car_practical,
        nb_underground_floor_request: data.nb_underground_floor_request,
        nb_underground_floor: data.nb_underground_floor,
        architectural_has_fire_system: data.architectural_has_fire_system || 'none_confirmed',
        architectural_has_water_system: data.architectural_has_water_system || 'none_confirmed',
        architectural_has_gradient_disability: data.architectural_has_gradient_disability || 'none_confirmed',
        technician_team: data.technician_team,
        insurance_company_id: data.insurance_company_id,
        insurance_number: data.insurance_number,
        surface_bottom_cell: data.surface_bottom_cell,
        surface_percentage_of_land_used_law: data.surface_percentage_of_land_used_law,
        surface_percentage_of_land_used_practical: data.surface_percentage_of_land_used_practical,
        surface_index_over: data.surface_index_over,
        land_unique_number_type_id: data.land_unique_number_type_id,
        construction_accessories_about_id: data.construction_accessories_about_id,
        is_rent_land: data.is_rent_land,
        owner_land_name: data.owner_land_name,
        owner_land_national_id_card: data.owner_land_national_id_card,
        owner_land_phone_number: data.owner_land_phone_number,
        project_sub_type_id: data.project_sub_type_id,
      }
    },
    getOwnerCto() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project/get-owner-cto', {
        company_ids: this.project.project_companies.map(company => company.id),
      }).then((res) => {
        if (res.data.data) {
          this.ownerCto = res.data.data
        } else {
          this.ownerCto = []
        }
      }).catch((error) => {
        this.onResponseError(error)
      })
    },
    getInsuranceCompanies() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project/get-insurance-companies')
        .then((res) => {
          if (res.data.data) {
            this.insuranceCompanies = res.data.data
          }
        }).catch((error) => {
          this.onResponseError(error)
        })
    },
    getMinistryLicenses() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project/get-ministry-permit-or-licenses')
        .then((res) => {
          if (res.data.data) {
            this.ministryPermitOrLicenses = res.data.data
          }
        }).catch((error) => {
          this.onResponseError(error)
        })
    }
  },
  computed: {
    projectTypeValidation() {
      return this.getValidationColumn(this.validations, 'project_type_id')
    },
    constructionTypeValidation() {
      return this.getValidationColumn(this.validations, 'construction_type_id')
    },
    ownersValidation() {
      return this.getValidationColumn(this.validations, 'owners')
    },
    ...mapState({
      storage: state => state.project.data
    }),
    permitTableClasses() {
      return 'table table-condensed table-bordered'
    }
  },
  async created() {
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty('project_uuid')) {
      await this.getProject().then(() => {
        this.getProvince()
      })
    } else {
      this.getProjectUnits()
      await this.getProvince()
    }
  }
}
