<template>
  <button
    :class="btnClass"
    :title="btnTooltip"
    @click="togglePublishing"
  >
    <i :class="btnIcon" />
  </button>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: "PublishCompanyButton",
  computed: {
    btnClass() {
      return this.is_published ? "btn btn-warning btn-sm margin-right-5" : "btn btn-danger btn-sm margin-right-5"
    },
    btnIcon () {
      return this.is_published ? "fas fa-eye-slash" : "fas fa-bullhorn"
    },
    ...mapGetters('project', {
      is_published: 'project_is_published'
    }),
    btnTooltip () {
      return this.is_published ? this.$t('string.unpublished') : this.$t('string.published')
    }
  },
  methods: {
    togglePublishing () {
      const { company_uuid } = this.$route.params
      if (!company_uuid) {
        return 0
      }

      this.$swal({
        title: this.$t('string.publishing_company'),
        text: this.$t('string.do_you_want_to_toggle_company_mobile_app'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('string.no'),
        confirmButtonText: this.$t('string.yes')
      }).then((result) => {
        if (result.value) {
          this.$isLoading(true)
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/company/toggle-published', {company_uuid})
            .then(({data}) => {
              if (data.data) {
                this.$store.dispatch('project/setProjectIsPublished', !this.is_published)
              }
            })
            .catch((error) => {
              if (error.response && error.response.status && error.response.status === 422) {
                this.validations = error.response.data.errors
              } else {
                this.onResponseError(error)
              }
            })
            .finally(() => {
              this.$isLoading(false)
            })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
