import University from "./../../views/settings/university/Index"
import List from "./../../views/settings/university/List"
import Create from "./../../views/settings/university/Create"
import Edit from "./../../views/settings/university/Edit"
import Show from "./../../views/settings/university/Show"
import Permissions from "../../permissions"

export const universityRouters = {
  path: 'university',
  component: University,
  redirect: 'university',
  meta: {
    permissions: [Permissions.backend.setting.university.manage],
    groups: 'university',
  },
  children: [
    {
      path: '',
      name: 'list-university',
      component: List,
      meta: {
        permissions: [Permissions.backend.setting.university.list],
        groups: 'university',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'University',
            name_km: 'សាកលវិទ្យាល័យ'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-university',
      component: Create,
      meta: {
        permissions: [Permissions.backend.setting.university.store],
        groups: 'university',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'University',
            name_km: 'សាកលវិទ្យាល័យ'
          },
          {
            name_en: 'New',
            name_km: 'បង្កើតថ្មី'
          }
        ]
      }
    },
    {
      path: 'edit/:university_uuid',
      name: 'edit-university',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.setting.university.edit],
        groups: 'university',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'University',
            name_km: 'សាកលវិទ្យាល័យ'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'show/:university_uuid',
      name: 'show-university',
      component: Show,
      meta: {
        permissions: [Permissions.backend.setting.university.show],
        groups: 'university',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'University',
            name_km: 'សាកលវិទ្យាល័យ'
          },
          {
            name_en: 'View',
            name_km: 'មើល'
          }
        ]
      }
    }
  ]
}
