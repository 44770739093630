<template>
  <div class="row">
    <div class="col-md-5">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('string.allPlaces') }}
          </h3>
          <div class="box-tools">
            <button class="btn btn-info btn-sm margin-right-5">
              <i class="fa fa-refresh" /> {{ $t('button.update') }}
            </button>
            <button class="btn btn-primary btn-sm">
              <i class="fa fa-plus" /> {{ $t('button.add') }}
            </button>
          </div>
        </div>
        <div class="box-body">
          <Tree
            :value="places"
            fold-all-after-mounted
          >
            <div
              slot-scope="{node, path, tree}"
              class="place-wrapper"
            >
              <div class="place-collapse">
                <span @click="tree.toggleFold(node, path)">
                  <template v-if="node.$folded">
                    <i class="fa fa-plus-circle" />
                  </template>
                  <template v-else>
                    <i class="fa fa-minus-circle" />
                  </template>
                </span>
              </div>
              <div class="place-label">
                {{ node['name_' + $i18n.locale] }}
              </div>
              <div class="place-action">
                <button
                  class="btn btn-primary btn-xs margin-right-5"
                  @click="switchView('add')"
                >
                  <i class="fa fa-plus" />
                </button>
                <button
                  class="btn btn-info btn-xs margin-right-5"
                  @click="switchView('show')"
                >
                  <i class="fa fa-search" />
                </button>
                <button
                  class="btn btn-success btn-xs margin-right-5"
                  @click="switchView('edit')"
                >
                  <i class="fa fa-edit" />
                </button>
                <button
                  class="btn btn-danger btn-xs margin-right-5"
                  @click="deleteOffice(node.uuid)"
                >
                  <i class="fa fa-trash" />
                </button>
              </div>
            </div>
          </Tree>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <div v-if="currentView==='add'">
        <tree-create-office />
      </div>
      <div v-if="currentView==='edit'">
        <tree-edit-office />
      </div>
      <div v-if="currentView==='show'">
        <tree-people-office />
      </div>
    </div>
  </div>
</template>

<script>
import 'he-tree-vue/dist/he-tree-vue.css'
import {Draggable, Fold, Tree} from 'he-tree-vue'
import TreeCreateOffice from "./includes/Create"
import TreeEditOffice from "./includes/Edit"
import TreePeopleOffice from "./includes/People"

export default {
  name: "TreeOffice",
  components: {
    TreePeopleOffice,
    TreeEditOffice,
    TreeCreateOffice,
    Tree: Tree.mixPlugins([Draggable, Fold]),
  },
  data() {
    return {
      oTable: null,
      places: [],
      currentView: 'none',
    }
  },
  mounted() {
    this.getPlaces()
  },
  methods: {
    switchView(view) {
      this.currentView = view
    },
    deleteOffice(uuid) {
      let self = this
      this.$swal.fire({
        title: self.i18nSwalTitle,
        text: self.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: self.i18nSwalNo,
        confirmButtonText: self.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/office/delete', {
            office_uuid: uuid
          }).then(() => {
            this.showSwalSuccess()
            this.getPlaces()
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
    getPlaces() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/office/get-places')
        .then(({data}) => {
          this.places = data.data
        }).catch(error => {
          this.$isLoading(false)
          this.onResponseError(error)
        }).finally(() => {
          this.$isLoading(false)
        })
    }
  }
}
</script>

<style scoped>
.place-wrapper {
  display: grid;
  grid-template-columns: 20px 1fr auto;
}

.place-action {
  visibility: hidden;
}

.place-wrapper:hover .place-action {
  visibility: visible;
}
</style>
