<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.newVillage') }}
      </h3>
    </div>
    <div class="box-body">
      <village-form />
    </div>
  </div>
</template>

<script>
import VillageForm from "./Form"

export default {
  name: "Create",
  metaInfo() {
    return {
      title: this.$t('string.newVillage'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {VillageForm}
}
</script>

<style scoped>

</style>
