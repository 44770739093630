<template>
  <span
    class="input-group-addon button-addon-add"
    :data-target="target"
    data-toggle="modal"
    @click="setCategories"
  >
    <i class="fa fa-plus" /> {{ $t('string.add') }}
  </span>
</template>

<script>
export default {
  name: "ProjectButtonAddonModal",
  props: {
    target: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      default: null,
    },
    architectureTypeId: {
      type: Number,
      default: null,
    }
  },
  methods: {
    setCategories() {
      if (this.categories !== null && this.categories.length > 0) {
        this.$store.commit('company/setModalCompanyCategories', this.categories)
      } else {
        this.$store.commit('company/setModalCompanyCategories', [])
      }

      if (this.architectureTypeId !== null) {
        this.$store.commit('architecture/setModalArchitectureTypeId', this.architectureTypeId)
      } else {
        this.$store.commit('architecture/setModalArchitectureTypeId', null)
      }
    }
  }
}
</script>

<style scoped>

</style>
