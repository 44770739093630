<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('label.showCarbonCopy') }}
      </h3>
      <div class="box-tools">
        <button
          v-if="can([$Permissions.backend.setting.carbonCopy.edit])"
          class="btn btn-primary btn-sm margin-right-5"
          @click="$router.push({
            name: 'edit-carbon-copy',
            params: {
              carbon_copy_uuid: $route.params.carbon_copy_uuid,
            }
          })"
        >
          <i class="fa fa-edit" /> {{ $t('button.edit') }}
        </button>
        <asora-back-button />
      </div>
    </div>
    <div
      v-if="carbonCopy!==null"
      class="box-body"
    >
      <div class="form-horizontal">
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.copy') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ carbonCopy.title }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.status') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{
                carbonCopy.enable ? $t('label.enable') : $t('label.disable')
              }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.createdAt') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ getDate(carbonCopy.created_at, false) }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.updatedAt') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ getDate(carbonCopy.updated_at, false) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('label.showCarbonCopy'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      carbonCopy: null,
    }
  },
  created() {
    this.getTeamCategory()
  },
  methods: {
    getTeamCategory() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/carbon-copy/show', {
        carbon_copy_uuid: this.$route.params.carbon_copy_uuid,
      }).then((res) => {
        this.carbonCopy = res.data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-carbon-copy'})
      }).finally(() => {
        this.$isLoading(false)
      })
    },
  }
}
</script>

<style scoped>
.control-label {
  padding-top: 0 !important;
}
</style>
