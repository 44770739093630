import Index from "@/views/companyManagement/companies/Index"
import List from "@/views/companyManagement/companies/List"
import Create from "@/views/companyManagement/companies/Create"
import Edit from "@/views/companyManagement/companies/Edit"
import Show from "@/views/companyManagement/companies/Show"

import FirstRegistration from "@/views/companyManagement/companies/FirstRegistration"
import RenewLicense from "@/views/companyManagement/companies/RenewLicense"
import UpDowngradeType from "@/views/companyManagement/companies/UpDowngradeType"

import Permissions from "@/permissions"

const permissions = Permissions.backend.companyManagement.companyRegistration

const getMeta = (permissions, link) => {
  return {
    permissions,
    groups: 'register-company',
    breadcrumb: [
      {
        name_en: 'Company',
        name_km: 'ក្រុមហ៊ុន'
      },
      link
    ]
  }
}

export const registerCompanyRouters = {
  path: 'company',
  component: Index,
  redirect: 'company',
  meta: {
    permissions: [permissions.manage],
    groups: 'register-company',
  },
  children: [
    {
      path: '',
      name: 'list-register-company',
      component: List,
      meta: getMeta([permissions.list], {
        name_en: 'List',
        name_km: 'បញ្ជី'
      })
    },
    {
      path: 'create',
      name: 'create-register-company',
      component: Create,
      meta: getMeta([permissions.store], {
        name_en: 'Add company',
        name_km: 'បន្ថែមក្រុមហ៊ុនថ្មី',
      })
    },
    {
      path: 'edit/:company_uuid',
      name: 'edit-register-company',
      component: Edit,
      meta: getMeta([permissions.edit], {
        name_en: 'Edit',
        name_km: 'កែសម្រួល'
      })
    },
    {
      path: 'show/:company_uuid',
      name: 'show-register-company',
      component: Show,
      meta: getMeta([permissions.show], {
        name_en: 'Show',
        name_km: 'មើល'
      })
    },
    {
      path: 'first-registration/:company_uuid',
      name: 'first-registration-register-company',
      component: FirstRegistration,
      meta: getMeta([], {
        name_en: 'First Registration',
        name_km: 'ស្នើសុំចុះបញ្ជីលើកដំបូង'
      })
    },
    {
      path: 'renew-license/:company_uuid',
      name: 'renew-license-register-company',
      component: RenewLicense,
      meta: getMeta([], {
        name_en: 'Renew License',
        name_km: 'ស្នើសុំបន្តសុពលភាព'
      })
    },
    {
      path: 'up-downgrade-type/:company_uuid',
      name: 'up-downgrade-type-register-company',
      component: UpDowngradeType,
      meta: getMeta([], {
        name_en: 'Up or Downgrade Type',
        name_km: 'ស្នើសុំតម្លើង រឺបន្ថយប្រភេទ'
      })
    },
  ]
}
