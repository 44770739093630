<template>
  <div class="row">
    <div :class="containerColumn">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ title }}
          </h3>
          <div class="box-tools">
            <template v-if="modeEdit">
              <template v-if="architecture.hasOwnProperty('uuid')">
                <template v-if="architecture.hasOwnProperty('is_published') && architecture.is_published">
                  <button
                    class="btn btn-warning btn-sm margin-right-5"
                    @click="togglePublishedArchitecture(architecture.uuid)"
                  >
                    <i class="fa fa-eye-slash" />
                  </button>
                </template>
                <template v-else>
                  <button
                    class="btn btn-danger btn-sm margin-right-5"
                    @click="togglePublishedArchitecture(architecture.uuid)"
                  >
                    <i class="fas fa-bullhorn" />
                  </button>
                </template>
              </template>
            </template>
            <asora-back-button />
          </div>
        </div>
        <div class="box-body">
          <div class="form-horizontal">
            <div
              class="form-group row"
              :class="architectureRequired.required ? ' has-error' : null"
            >
              <label class="col-sm-3 control-label required">{{ $t('label.type') }}</label>
              <div class="col-sm-9">
                <select
                  v-model="architecture.architecture_type_id"
                  class="form-control"
                >
                  <option value="2">
                    {{ $t('label.engineer') }}
                  </option>
                  <option value="1">
                    {{ $t('label.architecture') }}
                  </option>
                </select>
                <template v-if="architectureRequired.required">
                  <span class="label-error">{{ architectureRequired.message }}</span>
                </template>
              </div>
            </div>

            <div
              class="form-group row"
              :class="registrationNumberRequired.required ? ' has-error' : null"
            >
              <label class="col-sm-3 control-label">{{ $t('label.uniqueNumber') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="architecture.registration_number"
                  type="text"
                  :placeholder="$t('label.uniqueNumber')"
                  class="form-control"
                >
                <template v-if="registrationNumberRequired.required">
                  <span class="label-error">{{ registrationNumberRequired.message }}</span>
                </template>
              </div>
            </div>

            <div
              class="form-group row"
              :class="uniqueNumberRequired.required ? ' has-error' : null"
            >
              <label class="col-sm-3 control-label">{{ $t('label.declarationNumberOfMinistry') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="architecture.unique_number"
                  type="text"
                  :placeholder="$t('label.declarationNumberOfMinistry')"
                  class="form-control"
                >
                <template v-if="uniqueNumberRequired.required">
                  <span class="label-error">{{ uniqueNumberRequired.message }}</span>
                </template>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.registration_expired_date') }}</label>
              <div class="col-sm-9">
                <date-picker
                  v-model="architecture.registration_expired_date"
                  :lang="datepickerLocale[$i18n.locale].lang"
                  :placeholder="$t('label.registration_expired_date')"
                  type="date"
                  format="DD/MM/YYYY"
                  class="table-full-width"
                />
              </div>
            </div>

            <div
              class="form-group row"
              :class="nameKmRequired.required ? ' has-error' : null"
            >
              <label class="col-sm-3 control-label required">{{ $t('label.nameKm') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="architecture.name_km"
                  type="text"
                  :placeholder="$t('label.nameKm')"
                  class="form-control"
                >
                <template v-if="nameKmRequired.required">
                  <span class="label-error">{{ nameEnRequired.message }}</span>
                </template>
              </div>
            </div>

            <div
              class="form-group row"
              :class="nameEnRequired.required ? ' has-error' : null"
            >
              <label class="col-sm-3 control-label required">{{ $t('label.nameEn') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="architecture.name_en"
                  type="text"
                  :placeholder="$t('label.nameEn')"
                  class="form-control"
                >
                <template v-if="nameEnRequired.required">
                  <span class="label-error">{{ nameEnRequired.message }}</span>
                </template>
              </div>
            </div>

            <div
              class="form-group row"
              :class="genderRequired.required ? ' has-error' : null"
            >
              <label class="col-sm-3 control-label required">{{ $t('label.gender') }}</label>
              <div class="col-sm-9">
                <select
                  v-model="architecture.gender_id"
                  class="form-control"
                >
                  <option value="1">
                    {{ $t('label.male') }}
                  </option>
                  <option value="2">
                    {{ $t('label.female') }}
                  </option>
                </select>
                <template v-if="genderRequired.required">
                  <span class="label-error">{{ genderRequired.message }}</span>
                </template>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.dob') }}</label>
              <div class="col-sm-9">
                <date-picker
                  v-model="architecture.dob"
                  :lang="datepickerLocale[$i18n.locale].lang"
                  :placeholder="$t('label.dob')"
                  type="date"
                  format="DD/MM/YYYY"
                  class="table-full-width"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.placeOfBirth') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="architecture.place_of_birth"
                  type="text"
                  :placeholder="$t('label.placeOfBirth')"
                  class="form-control"
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.nationalIdCard') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="architecture.national_id_card"
                  type="text"
                  :placeholder="$t('label.nationalIdCard')"
                  class="form-control"
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.nationalIdCardCreationDate') }}</label>
              <div class="col-sm-9">
                <date-picker
                  v-model="architecture.national_id_card_creation_date"
                  :lang="datepickerLocale[$i18n.locale].lang"
                  :placeholder="$t('label.nationalIdCardCreationDate')"
                  type="date"
                  format="DD/MM/YYYY"
                  class="table-full-width"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.passportNumber') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="architecture.passport_number"
                  type="text"
                  :placeholder="$t('label.passportNumber')"
                  class="form-control"
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.nationality') }}</label>
              <div class="col-sm-9">
                <select
                  v-model="architecture.nationality_id"
                  class="form-control"
                >
                  <option value="">
                    {{ $t('string.none') }}
                  </option>
                  <option
                    v-for="(nationality, key) in nationalities"
                    :key="key"
                    :value="nationality.id"
                  >
                    {{ nationality['name_' + $i18n.locale] }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.degree') }}</label>
              <div class="col-sm-9">
                <select
                  v-if="degrees.length>0"
                  v-model="architecture.degree_id"
                  class="form-control"
                >
                  <option
                    v-for="(degree, key) in degrees"
                    :key="key"
                    :value="degree.id"
                  >
                    {{ degree['name_' + $i18n.locale] }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.certificate_number') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="architecture.certificate_number"
                  type="text"
                  :placeholder="$t('label.certificate_number')"
                  class="form-control"
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.graduateDate') }}</label>
              <div class="col-sm-9">
                <date-picker
                  v-model="architecture.graduation_date"
                  :lang="datepickerLocale[$i18n.locale].lang"
                  :placeholder="$t('label.graduateDate')"
                  type="date"
                  format="DD/MM/YYYY"
                  class="table-full-width"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.fromUniversity') }}</label>
              <div class="col-sm-9">
                <multiselect
                  v-model="architecture.university_id"
                  track-by="id"
                  :placeholder="$t('string.chooseUniversity')"
                  :label="'name_' + $i18n.locale"
                  :options="universities"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.salary') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="architecture.salary"
                  type="text"
                  :placeholder="$t('label.salary')"
                  class="form-control"
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.phone') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="architecture.phone"
                  type="tel"
                  :placeholder="$t('label.phone')"
                  class="form-control"
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.email') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="architecture.email"
                  type="email"
                  :placeholder="$t('label.email')"
                  class="form-control"
                >
              </div>
            </div>

            <location-input />

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.photo') }}</label>
              <div class="col-sm-9">
                <input
                  ref="avatar"
                  type="file"
                  name="avatar"
                > <br>
                <template v-if="architecture.avatar">
                  <render-image-tag :src="architecture.avatar ? `${baseUrl}/${architecture.avatar}` : null" />
                </template>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-9 col-sm-offset-3">
                <template v-if="button.handle==='create'">
                  <button
                    :class="`btn btn-${button.btnClass} btn-sm`"
                    @click="storeArchitecture"
                  >
                    {{ button.label }}
                  </button>
                </template>
                <template v-else>
                  <button
                    :class="`btn btn-${button.btnClass} btn-sm`"
                    @click="storeArchitecture"
                  >
                    {{ button.label }}
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="modeEdit"
      class="col-md-6"
    >
      <upload-media
        model-name="architecture"
        preview-column="col-md-4"
        :readonly="modeShow"
        :button-label="$t('label.chooseReference')"
        :model-uuid="$route.params.architecture_uuid"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import LocationInput from "../../components/LocationInput"
import RenderImageTag from "../../components/RenderImageTag"
import UploadMedia from "../../components/UploadMedia"
import {debounce} from 'debounce'

export default {
  name: "ArchitectureFrom",
  components: {
    UploadMedia,
    RenderImageTag,
    LocationInput,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    button: {
      type: Object,
      required: true
    },
    architectureTypeId: {
      type: String,
      required: true
    },
    architectureCategoryId: {
      type: String,
      required: true
    },
    modeEdit: {
      type: Boolean,
      default: false,
    },
    modeShow: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      architecture: {},
      validations: null,
      params: new FormData(),
      nationalities: [],
      universities: [],
      degrees: [],
    }
  },
  computed: {
    ...mapState({
      address: state => state.location.address
    }),
    labelColumns() {
      return this.modeEdit ? 'col-md-12' : 'col-sm-3 control-label'
    },
    inputColumns() {
      return this.modeEdit ? "col-md-12" : "col-sm-9"
    },
    containerColumn() {
      return this.modeEdit ? 'col-md-6' : 'col-md-12'
    },
    nameKmRequired() {
      return this.getValidationColumn(this.validations, 'name_km')
    },
    nameEnRequired() {
      return this.getValidationColumn(this.validations, 'name_en')
    },
    registrationNumberRequired() {
      return this.getValidationColumn(this.validations, 'registration_number')
    },
    uniqueNumberRequired() {
      return this.getValidationColumn(this.validations, 'unique_number')
    },
    genderRequired() {
      return this.getValidationColumn(this.validations, 'gender_id')
    },
    architectureRequired() {
      return this.getValidationColumn(this.validations, 'architecture_type_id')
    },
    urlAction() {
      return this.modeEdit ? 'update' : 'create'
    }
  },
  watch: {
    'architecture.national_id_card'() {
      if (this.architecture.national_id_card !== '') {
        this.architecture.nationality_id = 33
      }
    }
  },
  methods: {
    storeArchitecture() {
      this.architecture.is_registered = true
      if (this.architecture.uuid) {
        this.architecture.architecture_uuid = this.architecture.uuid
      }
      this.prepareParams()
      this.$isLoading(true)
      this.$axios.post(`${process.env.VUE_APP_API}/api/backend/architecture/${this.urlAction}`, this.params)
        .then(() => {
          this.showToastr()
          this.$router.back()
        })
        .catch((error) => {
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
            this.$store.commit('location/setValidations', res.data.errors)
          } else {
            this.$store.commit('location/setValidations', null)
            this.validations = null
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    prepareParams() {
      this.params.set('architecture_category_id', 3) // នកវិជ្ជាជីវៈសិក្សាគម្រោងប្លង់
      for (let key in this.architecture) {
        if (key === 'dob') {
          if ((this.$moment(this.architecture[key])).isValid()) {
            this.params.set(key, this.$moment(this.architecture[key]).locale('en').format('YYYY-MM-DD'))
          }
        } else if (key === 'graduation_date') {
          if ((this.$moment(this.architecture[key])).isValid()) {
            this.params.set(key, this.$moment(this.architecture[key]).locale('en').format('YYYY-MM-DD'))
          }
        } else if (key === 'registration_expired_date') {
          if ((this.$moment(this.architecture[key])).isValid()) {
            this.params.set(key, this.$moment(this.architecture[key]).locale('en').format('YYYY-MM-DD'))
          }
        } else if (key === 'national_id_card_creation_date') {
          if ((this.$moment(this.architecture[key])).isValid()) {
            this.params.set(key, this.$moment(this.architecture[key]).locale('en').format('YYYY-MM-DD'))
          }
        } else {
          let value = typeof (this.architecture[key]) === 'string' ? (this.architecture[key]).trim() : this.architecture[key]
          if (value === null || value === '') {
            this.params.delete(key)
          } else {
            // eslint-disable-next-line no-prototype-builtins
            if (value.hasOwnProperty('id')) {
              value = value.id
            }
            this.params.set(key, value)
          }
        }
      }

      if (this.$refs.avatar.files.length > 0) {
        this.params.set('avatar', this.$refs.avatar.files[0])
      } else {
        this.params.delete('avatar')
      }

      for (let key in this.address) {
        if (this.address[key] !== '' && this.address[key] !== null) {
          this.params.set(key, this.address[key])
        } else {
          this.params.delete(key)
        }
      }
    },
    getArchitecture() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/architecture/show', {
        architecture_uuid: this.$route.params.architecture_uuid,
      }).then((res) => {
        this.architecture = res.data.data
        if (this.$moment(this.architecture.dob).isValid()) {
          this.architecture.dob = this.$moment(this.architecture.dob).toDate()
        }
        if (this.$moment(this.architecture.national_id_card_creation_date).isValid()) {
          this.architecture.national_id_card_creation_date = this.$moment(this.architecture.national_id_card_creation_date).toDate()
        }
        if (this.$moment(this.architecture.registration_expired_date).isValid()) {
          this.architecture.registration_expired_date = this.$moment(this.architecture.registration_expired_date).toDate()
        }
        if (this.$moment(this.architecture.graduation_date).isValid()) {
          this.architecture.graduation_date = this.$moment(this.architecture.graduation_date).toDate()
        }
        if (this.architecture.university !== null) {
          this.architecture.university_id = this.architecture.university
        }
        this.$store.commit('location/setAddress', {
          province_id: this.architecture.province_id,
          district_id: this.architecture.district_id,
          commune_id: this.architecture.commune_id,
          village_id: this.architecture.village_id,
          home_number: this.architecture.home_number,
          street_number: this.architecture.street_number,
          reload: true,
        })
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      })
    },
    getNationalities() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/nationality/get-nationalities')
        .then((res) => {
          this.nationalities = res.data.data
        }).catch((error) => {
          this.onResponseError(error)
          this.$router.back()
        })
    },
    getUniversities: debounce(function (query_text = '') {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/architecture/get-universities', {
        query_text: query_text,
      })
        .then((res) => {
          this.universities = res.data.data
        }).catch((error) => {
          this.onResponseError(error)
          this.$router.back()
        })
    }, 200),
    getDegrees() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/degree/get-option')
        .then(({data}) => {
          this.degrees = data.data
        }).catch((error) => {
          this.onResponseError(error)
          this.$router.back()
        })
    },
    togglePublishedArchitecture(uuid) {
      this.$swal({
        title: this.$t('string.publishingArchitecture'),
        text: this.$t('string.doYouWantToToggleArchitectureOnMobileApp?'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('string.no'),
        confirmButtonText: this.$t('string.yes')
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/architecture/toggle-published', {
            architecture_uuid: uuid
          }).then(({data}) => {
            this.architecture.is_published = data.data.is_published
            this.showToastr()
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
  },
  created() {
    this.$set(this.architecture, 'architecture_type_id', this.architectureTypeId)
    this.$set(this.architecture, 'architecture_category_id', this.architectureCategoryId)
    this.getNationalities()
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty('architecture_uuid')) {
      this.getArchitecture()
    }
    this.getUniversities()
    this.getDegrees()
  }
}
</script>

<style scoped>

</style>
