<template>
  <div class="row">
    <div class="col-md-12">
      <div class="nav-tabs-custom">
        <ul class="nav nav-tabs">
          <li class="active">
            <a
              href="#profile"
              data-toggle="tab"
            >{{ $t('label.profile') }}</a>
          </li>
          <li>
            <a
              href="#resetPassword"
              data-toggle="tab"
            >{{ $t('label.resetPassword') }}</a>
          </li>
          <li>
            <a
              href="#changeAvatar"
              data-toggle="tab"
            >{{ $t('label.changeAvatar') }}</a>
          </li>
        </ul>
        <div class="tab-content">
          <div
            id="profile"
            class="active tab-pane"
          >
            <profile />
          </div>
          <div
            id="resetPassword"
            class="tab-pane"
          >
            <reset-password />
          </div>
          <div
            id="changeAvatar"
            class="tab-pane"
          >
            <change-avatar />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResetPassword from './includes/ResetPassword'
import ChangeAvatar from './includes/ChangeAvatar'
import Profile from './includes/Profile'

export default {
  name: 'Account',
  components: {Profile, ChangeAvatar, ResetPassword},
  metaInfo() {
    return {
      title: this.$t('string.profile'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
}
</script>

<style scoped>
  .tab-content {
    border: none !important;
  }
</style>
