<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.inspectionInfo') }}
      </h3>
      <div class="box-tools">
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <table class="table table-bordered table-condensed">
        <tbody>
          <tr>
            <td colspan="2">
              <img
                :src="`/images/inspection_types/${inspection.type}.png`"
                width="80px"
                alt="Image"
              >
            </td>
          </tr>
          <tr>
            <th>{{ $t('string.type') }}</th>
            <td>{{ getType }}</td>
          </tr>
          <template v-if="project!==null">
            <tr>
              <td>{{ $t('string.projectType') }}</td>
              <td>
                <template v-if="project.project_type && project.project_type!==null">
                  {{ project.project_type['name_' + $i18n.locale] }}
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <td>{{ $t('string.constructionType') }}</td>
              <td>
                <template v-if="project.construction_type && project.construction_type!==null">
                  {{ project.construction_type['name_' + $i18n.locale] }}
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('string.project') }}</th>
              <td>
                <a
                  :href="getLinkOpenNewTab({
                    name: 'show-project',
                    params: {
                      project_uuid: project.uuid,
                    }
                  })"
                  target="_blank"
                >{{ project['name_' + $i18n.locale] || "No Name" }}</a>
              </td>
            </tr>
            <tr>
              <td>{{ $t('string.owners') }}</td>
              <td>
                <template v-if="project.owners && project.owners.length>0">
                  <people-chip-item
                    v-for="(people, key) in project.owners"
                    :key="key"
                    :people="getPreparePeople(people)"
                    model="people"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <th>{{ $t('string.project') }}</th>
              <td>{{ $t('string.na') }}</td>
            </tr>
          </template>
          <tr>
            <th>{{ $t('string.permitNumber') }}</th>
            <td>{{ inspection.permit_number }}</td>
          </tr>
          <tr>
            <th>{{ $t('string.team') }}</th>
            <td>
              <template v-if="inspection.team!==null">
                <a href="#">
                  {{ inspection.team['name_' + $i18n.locale ] }}
                </a>
              </template>
              <template v-else>
                {{ $t('string.na') }}
              </template>
            </td>
          </tr>
          <tr>
            <th>{{ $t('string.inspector') }}</th>
            <td>
              <template v-if="inspection.inspector!==null">
                <a href="#">
                  {{ inspection.inspector.first_name }} {{ inspection.inspector.last_name }}
                </a>
              </template>
              <template v-else>
                {{ $t('string.na') }}
              </template>
            </td>
          </tr>
          <tr>
            <th>{{ $t('string.inspectedAt') }}</th>
            <td>{{ getDate(inspection.inspected_at, false) }}</td>
          </tr>
          <tr>
            <th>{{ $t('label.buildingProgress') }}</th>
            <td>
              <template
                v-if="inspection.value && inspection.value.attendance && inspection.value.attendance.building_progress"
              >
                <template v-if="inspection.value.attendance.building_progress.percentage">
                  {{ inspection.value.attendance.building_progress.percentage }}%
                </template>
              </template>
              <template v-else>
                {{ $t('string.na') }}
              </template>
            </td>
          </tr>
        <!--      ទុកប្រើពេលក្រោយ-->
        <!--        <tr>-->
        <!--          <th>{{ $t('table.status') }}</th>-->
        <!--          <td>-->
        <!--            <template v-if="inspection.status!==null">-->
        <!--              <snap :class="inspection.status === 'Rejected' ? 'text-danger': ''">-->
        <!--                {{inspection.status}}-->
        <!--              </snap>-->
        <!--            </template>-->
        <!--            <template v-else>{{ $t('string.na') }}</template>-->
        <!--          </td>-->
        <!--        </tr>-->
        </tbody>
      </table>
      <!--      ទុកប្រើពេលក្រោយ-->
      <!--      <div class="row margin-top-10">-->
      <!--        <div class="col-md-12 text-right">-->
      <!--          <button class="btn btn-success margin-right-5"-->
      <!--                  @click="changeStatus('Approved')">-->
      <!--            <i class="far fa-check-circle"></i> Approve-->
      <!--          </button>-->
      <!--          <button class="btn btn-danger" @click="changeStatus('Rejected')">-->
      <!--            <i class="far fa-times-circle"></i> Reject-->
      <!--          </button>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "InspectionInfo",
  props: {
    inspection: {
      type: Object,
      required: true,
    }
  },
  computed: {
    project() {
      if (this.inspection.project) {
        return this.inspection.project
      }
      return null
    },
    getType() {
      let value = null
      this.inspectionTypes.forEach((inspection) => {
        if (inspection.key === this.inspection.type) {
          value = inspection
        }
      })
      if (value !== null) {
        return value['name_' + this.$i18n.locale]
      }
      return null
    },
    inspectionTypes() {
      return [
        {
          key: 'before_approval',
          name_en: 'Controls to provide construction permit',
          name_km: 'ត្រួតពិនិត្យដើម្បីផ្ដល់លិខិតអនុញ្ញាតសាងសង់'
        },
        {
          key: 'after_approval',
          name_en: 'Controls for groundbreaking',
          name_km: 'ត្រួតពិនិត្យដើម្បីបើកការដ្ឋានសាងសង់'
        },
        {
          key: 'implementation',
          name_en: 'Construction Positioning border demarcation',
          name_km: 'ការបោះបង្គោលព្រំកំណត់ទីតាំងសាងសង់'
        },
        {
          key: 'following_up',
          name_en: 'Track construction',
          name_km: 'តាមដានដំណើរការសាងសង់'
        },
        {
          key: 'closing_construction',
          name_en: 'Construction inspection certificate',
          name_km: 'ត្រួតពិនិត្យផ្ដល់វិញ្ញាបនបត្រប្រើប្រាស់សំណង់'
        },
        {
          key: 'on_suing',
          name_en: 'Complaint Review',
          name_km: 'ត្រួតពិនិត្យពេលមានពាក្យបណ្ដឹង'
        },
      ]
    }
  },
  methods: {
    changeStatus(status) {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/inspection/change-status', {
        inspection_uuid: this.inspection.uuid,
        status: status
      }).then(() => {
        this.$emit('callFunction')
      }).catch((error) => {
        this.onResponseError(error)
      })
    }
  }
}
</script>

<style scoped>

</style>
