<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.approvedDeikarbanhchoun') }}
      </h3>
    </div>
    <div class="box-body">
      <div class="form-horizontal">
        <div
          :class="approvedByValidations.required? 'has-error' : null"
          class="form-group row"
        >
          <label class="col-sm-3 control-label required">{{ $t('string.approvedBy') }}</label>
          <div class="col-sm-9">
            <input
              v-model="deikarbanhchoun.approved_by"
              :placeholder="$t('string.approvedBy')"
              class="form-control"
              type="text"
            >
            <template v-if="approvedByValidations.required">
              <span class="label-error">{{ approvedByValidations.message }}</span>
            </template>
          </div>
        </div>
        <div
          :class="approvedAtValidations.required? 'has-error' : null"
          class="form-group row"
        >
          <label class="col-sm-3 control-label required">{{ $t('string.approvedAt') }}</label>
          <div class="col-sm-9">
            <date-picker
              v-model="deikarbanhchoun.approved_at"
              :lang="datepickerLocale[$i18n.locale].lang"
              :placeholder="$t('string.approvedAt')"
              class="table-full-width"
              format="DD/MM/YYYY"
              type="date"
            />
            <template v-if="approvedAtValidations.required">
              <span class="label-error">{{ approvedAtValidations.message }}</span>
            </template>
          </div>
        </div>
        <div
          :class="issuedNumberValidations.required? 'has-error' : null"
          class="form-group row"
        >
          <label class="col-sm-3 control-label required">{{ $t('string.issuedNumber') }}</label>
          <div class="col-sm-9">
            <input
              v-model="deikarbanhchoun.issued_number"
              :placeholder="$t('string.issuedNumber')"
              class="form-control"
              type="text"
            >
            <template v-if="issuedNumberValidations.required">
              <span class="label-error">{{ issuedNumberValidations.message }}</span>
            </template>
          </div>
        </div>
        <div
          :class="issuedDateValidations.required? 'has-error' : null"
          class="form-group row"
        >
          <label class="col-sm-3 control-label required">{{ $t('string.issuedDate') }}</label>
          <div class="col-sm-9">
            <date-picker
              v-model="deikarbanhchoun.issued_date"
              :lang="datepickerLocale[$i18n.locale].lang"
              :placeholder="$t('string.issuedDate')"
              class="table-full-width"
              format="DD/MM/YYYY"
              type="date"
            />
            <template v-if="issuedDateValidations.required">
              <span class="label-error">{{ issuedDateValidations.message }}</span>
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('string.note') }}</label>
          <div class="col-sm-9">
            <textarea
              v-model="deikarbanhchoun.note"
              :placeholder="$t('string.note')"
              class="form-control"
              rows="3"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-9 col-sm-offset-3">
            <button
              class="btn btn-primary btn-sm margin-r-5"
              @click="save"
            >
              {{ $t('button.save') }}
            </button>
            <asora-back-button />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {companyValidationMixins} from "@/mixins/companyValidations"

export default {
  name: "Approved",
  metaInfo() {
    return {
      title: this.$t('string.approvedDeikarbanhchoun'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  mixins: [companyValidationMixins],
  data() {
    return {
      deikarbanhchoun: {},
      validations: null,
    }
  },
  mounted() {
    this.deikarbanhchoun.deikarbanhchoun_uuid = this.$route.params.deikarbanhchoun_uuid
  },
  methods: {
    save() {
      this.validations = null
      for (let key in this.deikarbanhchoun) {
        let value = this.deikarbanhchoun[key]
        if (key.endsWith('_date') || key.endsWith('_at')) {
          if (this.$moment(value).isValid()) {
            value = this.$moment(value).locale('en').format('YYYY-MM-DD')
            this.deikarbanhchoun[key] = value
          }
        }
      }
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/company/deikarbanhchouns/approved', this.deikarbanhchoun)
        .then(() => {
          this.$router.push({
            name: 'list-deikarbanhchouns-company'
          })
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  }
}
</script>

<style scoped>

</style>
