import Deikarbanhchouns from '@/views/roubavontbokkols/deikarbanhchouns/Index'
import List from '@/views/roubavontbokkols/deikarbanhchouns/List'
import Create from '@/views/roubavontbokkols/deikarbanhchouns/Create'
import Edit from '@/views/roubavontbokkols/deikarbanhchouns/Edit'
import Show from '@/views/roubavontbokkols/deikarbanhchouns/Show'
import Approved from '@/views/roubavontbokkols/deikarbanhchouns/Approved'
import Permissions from "@/permissions"

const deikarbanhchoun = Permissions.backend.roubavontbokkol.deikarbanhchoun

const getMeta = (permissions, link) => {
  return {
    permissions,
    groups: 'deikarbanhchouns-roubavontbokkols',
    breadcrumb: [
      {
        name_en: 'Deikarbanhchouns',
        name_km: 'ដីការបញ្ជូន'
      },
      link
    ]
  }
}

export const roubavontbokkolDeikarbanhchounsRouters = {
  path: 'deikarbanhchouns',
  component: Deikarbanhchouns,
  redirect: 'deikarbanhchouns',
  meta: {
    permissions: [deikarbanhchoun.manage],
    groups: 'deikarbanhchouns-roubavontbokkols',
  },
  children: [
    {
      path: '',
      name: 'list-deikarbanhchouns-roubavontbokkols',
      component: List,
      meta: getMeta([deikarbanhchoun.list], {
        name_en: 'List',
        name_km: 'បញ្ជី'
      })
    },
    {
      path: 'create',
      name: 'create-deikarbanhchouns-roubavontbokkols',
      component: Create,
      meta: getMeta([deikarbanhchoun.store], {
        name_en: 'Create',
        name_km: 'បង្កើត'
      })
    },
    {
      path: 'edit/:roubavontbokkol_deikarbanhchoun_uuid',
      name: 'edit-deikarbanhchouns-roubavontbokkols',
      component: Edit,
      meta: getMeta([deikarbanhchoun.edit], {
        name_en: 'Edit',
        name_km: 'កែសម្រួល'
      })
    },
    {
      path: 'show/:roubavontbokkol_deikarbanhchoun_uuid',
      name: 'show-deikarbanhchouns-roubavontbokkols',
      component: Show,
      meta: getMeta([deikarbanhchoun.show], {
        name_en: 'Show',
        name_km: 'មើល'
      })
    },
    {
      path: 'approve/:roubavontbokkol_deikarbanhchoun_uuid',
      name: 'approve-deikarbanhchouns-roubavontbokkols',
      component: Approved,
      meta: getMeta([deikarbanhchoun.approve], {
        name_en: 'Approve',
        name_km: 'អនុម័ត'
      })
    }
  ]
}
