import Index from "./../../views/constructionLetter/close/Index"
import List from "./../../views/constructionLetter/close/List"
import Create from "./../../views/constructionLetter/close/Create"
import Edit from "./../../views/constructionLetter/close/Edit"
import Show from "./../../views/constructionLetter/close/Show"
import Upload from "../../views/constructionLetter/close/Upload"
import Approval from "../../views/constructionLetter/close/Approval"
import Note from "../../views/constructionLetter/close/Note"
import Permissions from "../../permissions"

export const closeConstructionLetterRouters = {
  path: 'close-construction-letter',
  component: Index,
  redirect: 'close-construction-letter',
  meta: {
    permissions: [Permissions.backend.constructionLetter.closeConstructionLetter.manage],
    groups: 'close-construction-letter',
  },
  children: [
    {
      path: '',
      name: 'list-close-construction-letter',
      component: List,
      meta: {
        permissions: [Permissions.backend.constructionLetter.closeConstructionLetter.list],
        groups: 'close-construction-letter',
        breadcrumb: [
          {
            name_en: 'Construction Letter',
            name_km: 'លិខិតសម្រាប់ការដ្ឋាន'
          },
          {
            name_en: 'Close Construction Letter',
            name_km: 'លិខិតបិទការដ្ឋាន'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-close-construction-letter',
      component: Create,
      meta: {
        permissions: [Permissions.backend.constructionLetter.closeConstructionLetter.store],
        groups: 'close-construction-letter',
        breadcrumb: [
          {
            name_en: 'Construction Letter',
            name_km: 'លិខិតសម្រាប់ការដ្ឋាន'
          },
          {
            name_en: 'Close Construction Letter',
            name_km: 'លិខិតបិទការដ្ឋាន'
          },
          {
            name_en: 'Create',
            name_km: 'បង្កើត'
          }
        ]
      }
    },
    {
      path: 'edit/:construction_letter_uuid',
      name: 'edit-close-construction-letter',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.constructionLetter.closeConstructionLetter.edit],
        groups: 'close-construction-letter',
        breadcrumb: [
          {
            name_en: 'Construction Letter',
            name_km: 'លិខិតសម្រាប់ការដ្ឋាន'
          },
          {
            name_en: 'Close Construction Letter',
            name_km: 'លិខិតបិទការដ្ឋាន'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'show/:construction_letter_uuid',
      name: 'show-close-construction-letter',
      component: Show,
      meta: {
        permissions: [Permissions.backend.constructionLetter.closeConstructionLetter.show],
        groups: 'close-construction-letter',
        breadcrumb: [
          {
            name_en: 'Construction Letter',
            name_km: 'លិខិតសម្រាប់ការដ្ឋាន'
          },
          {
            name_en: 'Close Construction Letter',
            name_km: 'លិខិតបិទការដ្ឋាន'
          },
          {
            name_en: 'Show',
            name_km: 'បង្ហាញព័ត៏មាន'
          }
        ]
      }
    },
    {
      path: 'upload/:construction_letter_uuid',
      name: 'upload-close-construction-letter',
      component: Upload,
      meta: {
        permissions: [Permissions.backend.constructionLetter.closeConstructionLetter.show],
        groups: 'close-construction-letter',
        breadcrumb: [
          {
            name_en: 'Construction Letter',
            name_km: 'លិខិតសម្រាប់ការដ្ឋាន'
          },
          {
            name_en: 'Close Construction Letter',
            name_km: 'លិខិតបិទការដ្ឋាន'
          },
          {
            name_en: 'Upload',
            name_km: 'បញ្ជូនឯកសារ'
          }
        ]
      }
    },
    {
      path: 'approval/:construction_letter_uuid',
      name: 'approval-close-construction-letter',
      component: Approval,
      meta: {
        permissions: [Permissions.backend.constructionLetter.closeConstructionLetter.approve],
        groups: 'close-construction-letter',
        breadcrumb: [
          {
            name_en: 'Construction Letter',
            name_km: 'លិខិតសម្រាប់ការដ្ឋាន'
          },
          {
            name_en: 'Close Construction Letter',
            name_km: 'លិខិតបិទការដ្ឋាន'
          },
          {
            name_en: 'Approve',
            name_km: 'អនុម័ត'
          }
        ]
      }
    },
    {
      path: 'create-note',
      name: 'create-note-close-construction-letter',
      component: Note,
      meta: {
        permissions: [Permissions.backend.constructionLetter.closeConstructionLetter.store],
        groups: 'close-construction-letter',
        breadcrumb: [
          {
            name_en: 'Construction Letter',
            name_km: 'លិខិតសម្រាប់ការដ្ឋាន'
          },
          {
            name_en: 'Close Construction Letter',
            name_km: 'លិខិតបិទការដ្ឋាន'
          },
          {
            name_en: 'Create',
            name_km: 'បង្កើត'
          }
        ]
      }
    },
    {
      path: 'edit-note/:construction_letter_uuid',
      name: 'edit-note-close-construction-letter',
      component: Note,
      meta: {
        permissions: [Permissions.backend.constructionLetter.closeConstructionLetter.store],
        groups: 'close-construction-letter',
        breadcrumb: [
          {
            name_en: 'Construction Letter',
            name_km: 'លិខិតសម្រាប់ការដ្ឋាន'
          },
          {
            name_en: 'Close Construction Letter',
            name_km: 'លិខិតបិទការដ្ឋាន'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
  ]
}
