<template>
  <div
    class="form-group row"
    :class="{'has-error' : validator}"
  >
    <label :class="`${labelClass} ${required ? 'required' : ''}`">{{ label }}</label>
    <div :class="inputWrapperClass">
      <date-picker
        class="table-full-width"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <template v-if="validator">
        <span class="label-error">{{ validator[0] }}</span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "XDatePicker",
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true
    },
    validator: {
      type: Array,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    labelClass: {
      type: String,
      default: 'col-sm-4 control-label'
    },
    inputWrapperClass: {
      type: String,
      default: 'col-sm-8'
    }
  }
}
</script>

<style scoped>

</style>
