const state = {
  data: {
    reload: false,
  }
}


const getters = {}

const mutations = {
  toggleReload(state) {
    state.reload = !state.reload
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
