<template>
  <XBox :title="$t('string.requesting_for_first_registration')">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-horizontal">
          <XInput
            v-model="company.certificate_of_mef_number"
            type="text"
            :placeholder="$t('label.certificateOfMefNumber')"
            :label="$t('label.certificateOfMefNumber')"
            required
            :validator="validations.certificate_of_mef_number"
          />

          <XDatePicker
            v-model="company.certificate_of_mef_date"
            type="date"
            :lang="datepickerLocale[$i18n.locale].lang"
            :placeholder="$t('label.certificateOfMefDate')"
            format="DD/MM/YYYY"
            :label="$t('label.certificateOfMefDate')"
            required
            :validator="validations.certificate_of_mef_date"
          />

          <XInput
            v-model="company.patent_number"
            type="text"
            :label="$t('label.patantNumber')"
            :placeholder="$t('label.patantNumber')"
            required
            :validator="validations.patent_number"
          />

          <XDatePicker
            v-model="company.patent_date"
            type="date"
            :lang="datepickerLocale[$i18n.locale].lang"
            :placeholder="$t('label.patantDate')"
            :label="$t('label.patantDate')"
            format="DD/MM/YYYY"
            required
            :validator="validations.patent_date"
          />

          <XInput
            v-model="company.first_registration_number"
            type="text"
            :placeholder="$t('label.firstRegistrationNumber')"
            :label="$t('label.firstRegistrationNumber')"
            required
            :validator="validations.first_registration_number"
          />

          <XDatePicker
            v-model="company.first_registration_date"
            :lang="datepickerLocale[$i18n.locale].lang"
            :placeholder="$t('label.first_registration_date')"
            type="date"
            format="DD/MM/YYYY"
            :label="$t('label.first_registration_date')"
            required
            :validator="validations.first_registration_date"
          />

          <XInput
            v-model="company.year"
            type="number"
            :label="$t('label.firstRegistrationYear')"
            :placeholder="$t('label.firstRegistrationYear')"
            :validator="validations.year"
            required
          />

          <AboutFRNumber
            :first_registration_number_en="first_registration_number_en"
            :first_registration_number_km="first_registration_number_km"
            :company="company"
          />

          <div class="form-group row">
            <div class="col-sm-8 col-sm-offset-4">
              <button
                class="btn btn-primary btn-sm margin-right-5"
                @click="save"
              >
                {{ $t('button.save') }}
              </button>
              <ButtonBack />
            </div>
          </div>
        </div>
      </div>
    </div>
  </XBox>
</template>

<script>
import XBox from "@/components/Widgets/XBox"
import ButtonBack from "@/components/ButtonBack"
import XInput from "@/components/Forms/XInput"
import XDatePicker from "@/components/Forms/XDatePicker"
import AboutFRNumber from "@/views/companyManagement/companies/AboutFRNumber"

export default {
  name: "FirstRegistration",
  metaInfo() {
    return {
      title: this.$t('string.requesting_for_first_registration'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  components: {AboutFRNumber, XDatePicker, XInput, ButtonBack, XBox},
  data() {
    return {
      company: {
        certificate_of_mef_number: null,
        certificate_of_mef_date: null,
        patent_number: null,
        patent_date: null,
        first_registration_date: null,
        first_registration_number: null,
        year: null,
      },
      validations: {}
    }
  },
  computed: {
    first_registration_number_km() {
      const {first_registration_number_km} = this.getCompanyFirstRegistrationNumber()
      return first_registration_number_km
    },
    first_registration_number_en() {
      const {first_registration_number_en} = this.getCompanyFirstRegistrationNumber()
      return first_registration_number_en
    }
  },
  mounted() {
    this.getCompany()
  },
  methods: {
    save() {
      const params = new FormData()
      params.set('company_uuid', this.$route.params.company_uuid)
      params.set('first_registration_number_km', this.first_registration_number_km)
      params.set('first_registration_number_en', this.first_registration_number_en)

      Object.keys(this.company).forEach((key) => {
        if (!(['first_registration_number_km', 'first_registration_number_en']).includes(key)) {
          let value = this.company[key]
          if (key.endsWith('_date')) {
            if (value && this.$moment(value).isValid()) {
              value = this.$moment(value).locale('en').format('YYYY-MM-DD')
            }
          }

          if (value) {
            params.set(key, value)
          } else {
            params.delete(key)
          }
        }
      })

      this.validations = {}
      this.$isLoading(true)
      this.$axios.post(this.$base_api + '/api/backend/company/first-registration-number/store', params)
        .then(({data}) => {
          if (data.data) {
            this.showToastr()
            this.$router.push({
              name: 'show-register-company',
              params: {
                company_uuid: this.$route.params.company_uuid
              }
            })
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getCompanyFirstRegistrationNumber() {
      let format = {
        construction: {
          khmer: {
            km: "$NUMBER$ នសគប / $YEAR$ ស",
            en: "$NUMBER$ NSKB / $YEAR$ S",
          },
          latin: {
            km: "$NUMBER$ នសគប / $YEAR$ សបរ",
            en: "$NUMBER$ NSKB / $YEAR$ SBR",
          },
        },
        projectDesign: {
          khmer: {
            km: "$NUMBER$ នសគប / $YEAR$ សប",
            en: "$NUMBER$ NSKB / $YEAR$ SB",
          },
          latin: {
            km: "$NUMBER$ នសគប / $YEAR$ សបបរ",
            en: "$NUMBER$ NSKB / $YEAR$ SBBR",
          },
        }
      }

      let first_registration_number_km = null
      let first_registration_number_en = null

      if (this.company.first_registration_number && this.company.year) {
        let number = this.company.first_registration_number

        first_registration_number_km = this.convert_number_to_km(number)
        first_registration_number_en = this.convert_number_to_en(number)

        if (this.company && this.company.company_section && this.company.country_id) {
          let selected_format = null
          let year = parseInt((this.company.year).toString().slice(-2))
          if (this.company.company_section && this.company.company_section.name_en.includes('Project Design')) {
            selected_format = format.projectDesign
          } else {
            selected_format = format.construction
          }
          if (selected_format !== null) {
            if (this.company.country_id === parseInt(process.env.VUE_APP_NATIONAL_ID)) {
              selected_format = selected_format.khmer
            } else {
              selected_format = selected_format.latin
            }

            let selected_format_en = selected_format.en
            let selected_format_km = selected_format.km

            selected_format_en = selected_format_en.replace("$NUMBER$", number).replace("$YEAR$", year)
            selected_format_km = selected_format_km.replace("$NUMBER$", number).replace("$YEAR$", year)

            first_registration_number_km = this.convert_number_to_km(selected_format_km)
            first_registration_number_en = this.convert_number_to_en(selected_format_en)
          }
        }
      }
      return {
        first_registration_number_km,
        first_registration_number_en
      }
    },
    getCompany() {
      this.validations = {}
      this.$isLoading(true)
      this.$axios.post(this.$base_api + '/api/backend/company/first-registration-number/show', {
        company_uuid: this.$route.params.company_uuid
      }).then(({data}) => {
        if (data.data) {
          const company = data.data
          this.company = company

          Object.keys(company).forEach((key) => {
            let value = company[key]
            if (key.endsWith('_date')) {
              if (value && this.$moment(value).isValid()) {
                value = this.$moment(value).locale('en').toDate()
                // eslint-disable-next-line no-prototype-builtins
                if (value && this.company.hasOwnProperty(key)) {
                  this.company[key] = value
                }
              }
            }
          })
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
