<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.newPlace') }}
      </h3>
    </div>
    <div class="box-body">
      <div class="form-group" />
    </div>
  </div>
</template>

<script>
export default {
  name: "TreeCreateOffice"
}
</script>

<style scoped>

</style>
