<template>
  <div class="row">
    <div
      v-if="enabledTitle"
      class="col-md-12"
    >
      <h4 class="font-secondary">
        {{ title }}
      </h4>
    </div>
    <template v-if="projectUuid && buildings.length">
      <div class="col-md-12">
        <div
          v-for="(building, key) in buildings"
          :key="key"
          class="building margin-bottom"
        >
          <div class="building-item margin-bottom">
            <div class="building-item-action">
              <div class="building-item-title">
                <h4 class="font-secondary text-bold">
                  {{ key + 1 }} - {{ building.name }}
                </h4>
              </div>
              <div
                v-if="enabledAction"
                class="building-item-buttons"
              >
                <button
                  class="btn btn-primary btn-xs margin-right-5"
                  @click="editBuilding(building)"
                >
                  <i class="fa fa-edit" />
                </button>
                <button
                  class="btn btn-danger btn-xs"
                  @click="deleteBuilding(building)"
                >
                  <i class="fa fa-trash" />
                </button>
              </div>
            </div>
            <div class="building-item-list">
              <table>
                <tbody>
                  <tr>
                    <td>ឆ្នាំសាងសង់៖ <strong>{{ building.year || '-' }}</strong></td>
                    <td>មុខងារប្រើប្រាស់សំណង់៖ <strong>{{ building.usage_function || '-' }}</strong></td>
                  </tr>
                  <tr>
                    <td>ផ្ទៃក្រឡាសំណង់៖ <strong>{{ building.total_construction_area || '-' }}</strong></td>
                    <td>ផ្ទៃក្រឡាសំណង់បានការ៖ <strong>{{ building.total_construction_area_practical || '-' }}</strong></td>
                  </tr>
                  <tr>
                    <td>ចំនួនជាន់៖ <strong>{{ building.total_floors || '-' }}</strong></td>
                    <td />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <template v-if="building.floors">
            <table
              cellpadding="0"
              border="1"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th>{{ $t('buildingDetail.table.floorName') }}</th>
                  <th>{{ $t('buildingDetail.table.totalConstructionArea') }}</th>
                  <th>{{ $t('buildingDetail.table.totalConstructionAreaPractical') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="building.floors && building.floors.length">
                  <tr
                    v-for="(floor, _key) in building.floors"
                    :key="_key"
                  >
                    <td>{{ floor.name }}</td>
                    <td>{{ floor.total_construction_area || '-' }}</td>
                    <td>{{ floor.total_construction_area_practical || '-' }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td
                      colspan="3"
                      class="text-center"
                    >
                      {{ $t('buildingDetail.string.noResult') }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="col-md-12">
        <div class="callout callout-warning">
          <h4>{{ title }}</h4>

          <p>{{ $t('buildingDetail.string.noResult') }}</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ProjectBuildingDetail",
  props: {
    projectUuid: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default() {
        return this.$t('buildingDetail.string.buildingDetail')
      }
    },
    enabledAction: {
      type: Boolean,
      default: true,
    },
    enabledTitle: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      buildings: [],
    }
  },
  mounted() {
    if (this.projectUuid) {
      this.getProjectBuildingDetail()
    }
  },
  methods: {
    getProjectBuildingDetail() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-building/show', {
        project_uuid: this.projectUuid,
      }).then((response) => {
        if (response.data && response.data.data) {
          this.buildings = response.data.data
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    deleteBuilding(building) {
      const self = this
      this.$swal.fire({
        title: self.i18nSwalTitle,
        text: self.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: self.i18nSwalNo,
        confirmButtonText: self.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-building/delete-building', {
            building_id: building.id
          }).then(() => {
            this.showSwalSuccess()
            this.getProjectBuildingDetail()
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
    editBuilding (building) {
      this.$store.commit('building/setBuildingId', building.id)
    }
  }
}
</script>

<style scoped>
.building {
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 4px;
}

.building-item-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

tr td, tr th {
  padding: 4px;
}
</style>
