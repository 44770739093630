<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('label.newUser') }}
      </h3>
    </div>
    <div class="box-body">
      <div class="row">
        <div class="col-md-12">
          <div class="form-horizontal">
            <div
              class="form-group"
              :class="{'has-error' : validations.first_name }"
            >
              <label class="required col-sm-3 control-label">{{ $t('label.firstName') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="user.first_name"
                  type="text"
                  autofocus
                  class="form-control"
                  :placeholder="$t('label.firstName')"
                >
                <span
                  v-if="validations.first_name"
                  class="label-error"
                >
                  {{ validations['first_name'][0] }}
                </span>
              </div>
            </div>
            <div
              class="form-group"
              :class="{'has-error' : validations.last_name}"
            >
              <label class="required col-sm-3 control-label">{{ $t('label.lastName') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="user.last_name"
                  type="text"
                  autofocus
                  class="form-control"
                  :placeholder="$t('label.lastName')"
                >
                <span
                  v-if="validations.last_name"
                  class="label-error"
                >
                  {{ validations['last_name'][0] }}
                </span>
              </div>
            </div>
            <div
              class="form-group"
              :class="{'has-error' : validations.username}"
            >
              <label class="required col-sm-3 control-label">{{ $t('label.username') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="user.username"
                  type="text"
                  autofocus
                  class="form-control"
                  :placeholder="$t('label.emailOrPhoneNumber')"
                >
                <span
                  v-if="validations.username"
                  class="label-error"
                >
                  {{ validations['username'][0] }}
                </span>
              </div>
            </div>
            <div
              class="form-group"
              :class="{'has-error' : validations.email }"
            >
              <label class="col-sm-3 control-label">{{ $t('label.email') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="user.email"
                  type="email"
                  autofocus
                  class="form-control"
                  :placeholder="$t('label.email')"
                >
                <span
                  v-if="validations.email"
                  class="label-error"
                >
                  {{ validations['email'][0] }}
                </span>
              </div>
            </div>
            <div
              class="form-group"
              :class="{'has-error' : validations.phone}"
            >
              <label class="col-sm-3 control-label">{{ $t('label.phone') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="user.phone"
                  type="tel"
                  autofocus
                  class="form-control"
                  :placeholder="$t('label.phone')"
                >
                <span
                  v-if="validations.phone"
                  class="label-error"
                >
                  {{ validations['phone'][0] }}
                </span>
              </div>
            </div>
            <div
              class="form-group"
              :class="{'has-error' : validations.password}"
            >
              <label class="required col-sm-3 control-label">{{ $t('label.password') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="user.password"
                  type="password"
                  autofocus
                  class="form-control"
                  :placeholder="$t('label.password')"
                >
                <span
                  v-if="validations.password"
                  class="label-error"
                >
                  {{ validations['password'][0] }}
                </span>
              </div>
            </div>
            <div
              class="form-group"
              :class="{'has-error' : validations.password_confirmation}"
            >
              <label class="required col-sm-3 control-label">{{ $t('label.passwordConfirmation') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="user.password_confirmation"
                  type="password"
                  autofocus
                  class="form-control"
                  :placeholder="$t('label.passwordConfirmation')"
                >
                <span
                  v-if="validations.password_confirmation"
                  class="label-error"
                >
                  {{ validations['password_confirmation'][0] }}
                </span>
              </div>
            </div>
            <div
              class="form-group"
              :class="{'has-error': validations.gender_id}"
            >
              <label class="required col-sm-3 control-label">{{ $t('label.gender') }}</label>
              <div class="col-sm-9">
                <select
                  v-model="user.gender_id"
                  class="form-control"
                >
                  <option
                    selected
                    disabled
                    :value="null"
                  >
                    {{ $t('string.selectGender') }}
                  </option>
                  <option value="1">
                    {{ $t('label.male') }}
                  </option>
                  <option value="2">
                    {{ $t('label.female') }}
                  </option>
                </select>
                <span
                  v-if="validations.gender_id"
                  class="label-error"
                >
                  {{ validations['gender_id'][0] }}
                </span>
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-3 control-label">{{ $t('label.province') }}</label>
              <div class="col-sm-9">
                <multiselect
                  v-model="user.provinces"
                  :label="`name_` + $i18n.locale"
                  track-by="id"
                  :placeholder="$t('label.province')"
                  :select-label="$t('string.pressEnterToSelect')"
                  :deselect-label="$t('string.pressEnterToRemove')"
                  :selected-label="$t('string.selected')"
                  :multiple="true"
                  :options="provinces"
                />
              </div>
            </div>
            <div class="form-group">
              <label class="required col-sm-3 control-label">{{ $t('label.active') }}</label>
              <div class="col-sm-9">
                <label>
                  <input
                    v-model="user.active"
                    type="checkbox"
                    :value="true"
                  > {{ $t('label.active?') }}
                </label>
              </div>
            </div>
            <div
              class="form-group"
              :class="{'has-error': validations.role}"
            >
              <label class="required col-sm-3 control-label">{{ $t('label.role') }}</label>
              <div class="col-sm-9">
                <div class="row">
                  <template v-if="rolesOption.length > 0">
                    <div
                      v-for="(role, key) in rolesOption"
                      :key="key"
                      class="col-md-12"
                    >
                      <label
                        style="color: #333333"
                      >
                        <input
                          v-model="user.roles"
                          type="checkbox"
                          :value="role"
                        > {{ role['display_name_' + $i18n.locale] || role.name }}
                      </label>
                    </div>
                  </template>
                  <span
                    v-if="validations.role"
                    class="label-error"
                  >
                    {{ validations['role'][0] }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="form-group"
              :class="{'has-error': validations.permissions}"
            >
              <label class="col-sm-3 control-label">{{ $t('label.additionalPermissions') }}</label>
              <div class="col-sm-9">
                <div
                  class="permission-tree-wrapper"
                  :class="{'red-border': validations.permissions}"
                >
                  <permission-tree
                    v-model="user.permissions"
                    :disabled-permissions="disabledPermissions"
                    :disable-all="disableAll"
                    :selected="selected"
                  />
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="col-sm-9 col-sm-offset-3">
                <button
                  class="btn btn-primary btn-sm margin-r-5"
                  @click="onSubmit"
                >
                  <i class="fa fa-check-circle" /> {{ $t('button.save') }}
                </button>
                <a
                  class="btn btn-default btn-sm"
                  @click="$router.back()"
                >
                  <i class="fa fa-arrow-circle-left" /> {{ $t('button.back') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PermissionTree from "@/components/PermissionTree"

export default {
  name: "Create",
  metaInfo() {
    return {
      title: this.$t('label.newUser'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {
    PermissionTree
  },
  data() {
    return {
      validations: {},
      user: {
        first_name: null,
        last_name: null,
        username: null,
        password: null,
        password_confirmation: null,
        email: null,
        phone: null,
        gender_id: null,
        provinces: [],
        active: true,
        roles: [],
        permissions: []
      },
      rolesOption: [],
      provinces: [],
      selected: null,
      apiUrl: process.env.VUE_APP_API,
    }
  },
  computed: {
    disabledPermissions() {
      if (this.user && this.user.roles && Array.isArray(this.user.roles) && this.user.roles.length > 0) {
        const permissions = this.user.roles.map(role => role.permissions || []).reduce((p1, p2) => p1.concat(p2))
        if (Array.isArray(permissions) && permissions.length > 0) {
          return permissions.map(permission => permission.id)
        }
      }
      return []
    },
    disableAll() {
      if (this.user && Array.isArray(this.user) && this.user.length > 0) {
        return this.user.roles.some(role => role.name === process.env.VUE_APP_ROLE_ADMIN)
      }
      return null
    }
  },
  watch: {
    user() {
      this.selected = this.user.permissions
    }
  },
  async mounted() {
    await this.fetchProvinces()
    await this.fetchRoles()
  },
  methods: {
    async fetchRoles() {
      await this.$axios.post(process.env.VUE_APP_API + '/api/backend/role/get-roles')
        .then((response) => {
          this.rolesOption = response.data.data
        }).catch(error => {
          this.onResponseError(error)
        }).finally(() => {
          this.$isLoading(false)
        })
    },
    async fetchProvinces() {
      this.$isLoading(true)
      await this.$axios.post(process.env.VUE_APP_API + '/api/auth/user/get-provinces')
        .then(({data}) => {
          if (data && data.data) {
            this.provinces = data.data
          }
        })
        .catch(error => {
          this.onResponseError(error)
        })
    },
    onSubmit() {
      let permissions = []
      if (this.user && this.user.permissions && Array.isArray(this.user.permissions) && this.user.permissions.length > 0) {
        permissions = this.user.permissions.map(permission => ({
          id: permission.id,
          name: permission.name
        }))
      }

      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/user/store', {
        ...{...this.user, permissions: permissions}
      }).then(() => {
        this.$toastr('success', this.$t('string.theRequestHaveBeenProcessed'), this.$t('string.success'))
        this.$router.push({
          name: 'list-user'
        })
      }).catch((error) => {
        if (error.response.status === 422) {
          this.validations = error.response.data.errors
        } else {
          this.onResponseError(error)
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
