<template>
  <div class="row" v-if="note">
    <div class="col-md-4">
      <roubavontbokkols-note-information :note="note"/>
    </div>
    <div class="col-md-8">
      <roubavontbokkols-show-note-tabs :note="note"/>
    </div>
  </div>
</template>

<script>
import RoubavontbokkolsShowNoteTabs from "@/views/roubavontbokkols/_components/RoubavontbokkolsShowNoteTabs"
import RoubavontbokkolsNoteInformation from "@/views/roubavontbokkols/_components/RoubavontbokkolsNoteInformation"

export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('string.showRoubavontbokkolNote'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  components: {
    RoubavontbokkolsNoteInformation,
    RoubavontbokkolsShowNoteTabs
  },
  data() {
    return {
      note: null,
    }
  },
  computed: {
    api() {
      return `${this.baseUrl}/api/backend/roubavontbokkols/note`
    },
  },
  methods: {
    async show() {
      this.$isLoading(true)
      this.$axios.post(`${this.api}/show`, {
        uuid: this.$route.params.roubavontbokkols_uuid,
      }).then((response) => {
        if (response.data && response.data.data) {
          this.note = response.data.data
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  },
  mounted() {
    this.show()
  }
}
</script>

<style scoped>

</style>
