<template>
  <XBox :title="$t('string.requesting_updowngrade_type')">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-horizontal">
          <div class="form-horizontal">
            <XMultiSelect
              v-model="company_type"
              :label="`name_${$i18n.locale}`"
              track-by="id"
              :placeholder="$t('string.chooseCompanyType')"
              :options="companyTypes"
              :label-input="$t('label.companyType')"
              required
              :validator="validations.company_type_id"
            />
          </div>

          <div class="form-group row">
            <div class="col-sm-8 col-sm-offset-4">
              <button
                class="btn btn-primary btn-sm margin-right-5"
                @click="save"
              >
                {{ $t('button.save') }}
              </button>
              <ButtonBack />
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-8 col-sm-offset-4">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>{{ $t('table.no_') }}</th>
              <th>{{ $t('table.company_type') }}</th>
              <th>{{ $t('table.start_at') }}</th>
              <th>{{ $t('table.end_at') }}</th>
              <th>{{ $t('table.updated_by_user') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="histories && Array.isArray(histories) && histories.length > 0">
              <tr
                v-for="(history, key) in histories"
                :key="key"
              >
                <td>{{ key + 1 }}</td>
                <td>{{ history.company_type ? history.company_type['name_' + $i18n.locale] : $t('string.na') }}</td>
                <td>{{ history.start_at }}</td>
                <td>{{ history.end_at ? history.end_at : $t('string.current') }}</td>
                <td>{{ history.user ? history.user.first_name + ' ' + history.user.last_name : $t('string.na') }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td
                  colspan="5"
                  class="text-muted text-center"
                >
                  {{ $t('string.no_result') }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </XBox>
</template>

<script>
import XBox from "@/components/Widgets/XBox"
import ButtonBack from "@/components/ButtonBack"
import {mapFields} from "vuex-map-fields"
import {mapActions} from "vuex"
import XMultiSelect from "@/components/Forms/XMultiSelect"

export default {
  name: "UpDowngradeType",
  components: {XMultiSelect, ButtonBack, XBox},
  metaInfo() {
    return {
      title: this.$t('string.requesting_updowngrade_type'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  data() {
    return {
      company_type: null,
      validations: {},
      histories: []
    }
  },
  computed: {
    ...mapFields('options', {
      companyTypes: 'company_management.companyTypes'
    })
  },
  methods: {
    ...mapActions('options', {
      fetchCompanyManagementOptions: 'fetchCompanyManagementOptions'
    }),
    save() {
      this.validations = {}
      this.$isLoading(true)
      this.$axios.post(this.$base_api + '/api/backend/company/up-downgrade-company-type/store', {
        company_uuid: this.$route.params.company_uuid,
        company_type_id: this.company_type ? this.company_type.id : null
      }).then(({data}) => {
        if (data.data) {
          this.showToastr()
          this.$router.push({
            name: 'show-register-company',
            params: {
              company_uuid: this.$route.params.company_uuid
            }
          })
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          this.validations = error.response.data.errors
        } else {
          this.onResponseError(error)
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    getHistories() {
      this.validations = {}
      this.$isLoading(true)
      this.$axios.post(this.$base_api + '/api/backend/company/up-downgrade-company-type/list', {
        company_uuid: this.$route.params.company_uuid,
      }).then(({data}) => {
        if (data.data) {
          this.histories = data.data
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  },
  mounted() {
    this.getHistories()
    this.fetchCompanyManagementOptions(this)
  }
}
</script>

<style scoped>

</style>
