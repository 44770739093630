<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('label.newArchitectureType') }}
      </h3>
      <div class="box-tools">
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <div class="form-horizontal">
        <div
          :class="nameKmRequired.required ? ' has-error' : null"
          class="form-group row"
        >
          <label class="col-sm-3 control-label required">{{ $t('label.nameKm') }}</label>
          <div class="col-sm-9">
            <input
              v-model="architectureType.name_km"
              :placeholder="$t('label.nameKm')"
              class="form-control"
              type="text"
            >
            <template v-if="nameKmRequired.required">
              <span class="label-error">{{ nameKmRequired.message }}</span>
            </template>
          </div>
        </div>

        <div
          :class="nameEnRequired.required ? ' has-error' : null"
          class="form-group row"
        >
          <label class="col-sm-3 control-label required">{{ $t('label.nameEn') }}</label>
          <div class="col-sm-9">
            <input
              v-model="architectureType.name_en"
              :placeholder="$t('label.nameEn')"
              class="form-control"
              type="text"
            >
            <template v-if="nameEnRequired.required">
              <span class="label-error">{{ nameEnRequired.message }}</span>
            </template>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-9 col-sm-offset-3">
            <button
              class="btn btn-primary btn-sm"
              @click="newArchitectureType"
            >
              {{ $t('button.save') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  metaInfo() {
    return {
      title: this.$t('label.newArchitectureType'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      architectureType: {},
      validations: null,
      projectTypes: [],
    }
  },
  computed: {
    nameKmRequired() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.validations !== null && this.validations.hasOwnProperty('name_km')) {
        return {
          required: true,
          message: this.validations['name_km'][0]
        }
      }
      return {
        required: false,
        message: null,
      }
    },
    nameEnRequired() {
      // eslint-disable-next-line no-prototype-builtins
      if (this.validations !== null && this.validations.hasOwnProperty('name_en')) {
        return {
          required: true,
          message: this.validations['name_en'][0]
        }
      }
      return {
        required: false,
        message: null,
      }
    }
  },
  methods: {
    newArchitectureType() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/architecture-type/create', this.architectureType)
        .then(() => {
          this.showToastr()
          this.$router.push({name: 'list-architecture-type'})
        })
        .catch((error) => {
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
          } else {
            this.validations = null
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  }
}
</script>

<style scoped>

</style>
