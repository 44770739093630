<template>
  <div class="row">
    <div class="col-md-12">
      <XBox :title="$t('string.content')">
        <template slot="box-tools">
          <template v-if="company">
            <router-link
              class="btn btn-primary btn-sm margin-r-5"
              :to="{
                name: 'edit-notes-company',
                params: {
                  company_uuid: company.uuid
                }
              }"
            >
              <i class="fa fa-edit" />
            </router-link>
          </template>
          <ButtonBack />
        </template>

        <div class="row">
          <div class="col-sm-12">
            <template v-if="contents && contents.length > 0">
              <ul>
                <li
                  v-for="(item, key) in contents"
                  :key="key"
                >
                  {{ item.value }}
                </li>
              </ul>
            </template>
            <template v-else>
              <p class="text-center">
                {{ $t('string.noteNoContent') }}
              </p>
            </template>
          </div>
        </div>
      </XBox>
    </div>
    <div class="col-md-12">
      <XBox :title="$t('string.constructionLetterCommand')">
        <div class="row">
          <div class="col-md-12">
            <template v-if="commands && commands.length > 0">
              <ul>
                <li
                  v-for="(item, key) in commands"
                  :key="key"
                >
                  {{ item.value }}
                </li>
              </ul>
            </template>
            <template v-else>
              <p class="text-center">
                {{ $t('string.commandsNoContent') }}
              </p>
            </template>
          </div>
        </div>
      </XBox>
    </div>
    <div class="col-md-12">
      <XBox :title="$t('field.label.yobl_neayokadthan')">
        <div class="row">
          <div class="col-md-12">
            <template v-if="company && company.yobl_neayokadthan">
              <p>{{ company.yobl_neayokadthan.value }}</p>
            </template>
            <template v-else>
              <p class="text-center">
                {{ $t('string.no_yobl_neayokadthan') }}
              </p>
            </template>
          </div>
        </div>
      </XBox>
    </div>
    <div class="col-md-12">
      <XBox :title="$t('field.label.yobl_akkoneayokarong')">
        <div class="row">
          <div class="col-md-12">
            <template v-if="company && company.yobl_akkoneayokarong">
              <p>{{ company.yobl_akkoneayokarong.value }}</p>
            </template>
            <template v-else>
              <p class="text-center">
                {{ $t('string.no_yobl_akkoneayokarong') }}
              </p>
            </template>
          </div>
        </div>
      </XBox>
    </div>
    <div class="col-md-12">
      <XBox :title="$t('field.label.yobl_ekuttam_akkoneayok')">
        <div class="row">
          <div class="col-md-12">
            <template v-if="company && company.yobl_ekuttam_akkoneayok">
              <p>{{ company.yobl_ekuttam_akkoneayok.value }}</p>
            </template>
            <template v-else>
              <p class="text-center">
                {{ $t('string.no_yobl_ekuttam_akkoneayok') }}
              </p>
            </template>
          </div>
        </div>
      </XBox>
    </div>
  </div>
</template>

<script>
import XBox from "@/components/Widgets/XBox"
import ButtonBack from "@/components/ButtonBack"

export default {
  name: "Note",
  components: {ButtonBack, XBox},
  props: {
    company: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Object | null,
      default: null
    }
  },
  computed: {
    commands () {
      if (this.company && this.company.commands && Array.isArray(this.company.commands)) {
        return this.company.commands
      }
      return []
    },
    contents () {
      if (this.company && this.company.contents && Array.isArray(this.company.contents)) {
        return this.company.contents
      }
      return []
    }
  }
}
</script>

<style scoped>

</style>
