<template>
  <role-form
    :role="role"
    :validations="validations"
    :title="$t('label.editRole')"
    @submit="onSubmit"
  />
</template>

<script>
import RoleForm from './Form'

export default {
  name: "EditRole",
  metaInfo() {
    return {
      title: this.$t('string.editRole'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {
    RoleForm
  },
  data() {
    return {
      role: undefined,
      validations: null
    }
  },
  mounted() {
    this.getRole(this.$route.params.id)
  },
  methods: {
    onSubmit(role) {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/role/edit', {
        'name': role.name,
        'guard_name': 'web',
        'permissions': role.permissions.map(permission => (
          {
            id: permission.id,
            name: permission.name
          }
        )),
        'display_name_en': role.display_name_en,
        'display_name_km': role.display_name_km,
        'id': this.$route.params.id
      }).then(() => {
        this.$swal({
          text: this.$t('label.swal.success'),
          title: this.$t('label.roleUpdated'),
          type: 'success',
          confirmButtonText: this.$t('button.ok')
        })
        this.$router.back()
      }).catch((error) => {
        if (error.response.status === 422) {
          this.validations = error.response.data.errors
        } else {
          this.onResponseError(error)
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    getRole(id) {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/role/get-role', {
        id: id
      }).then((response) => {
        this.role = response.data.data
      }).catch(error => {
        this.onResponseError(error)
      })
    },
  }
}
</script>

<style scoped>

</style>
