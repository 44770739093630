<template>
  <div
    v-if="parking!==null"
    class="col-md-12"
  >
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t('string.parking') }}
        </h3>
      </div>
      <div class="box-body">
        <table class="table table-striped table-bordered table-condensed">
          <thead>
            <tr>
              <th>{{ $t('string.type') }}</th>
              <th>{{ $t('label.permit') }}</th>
              <th>{{ $t('label.request') }}</th>
              <th>{{ $t('label.practical') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ $t('label.numberParkingMotor') }}</td>
              <td>
                {{
                  parking.number_parking_motor_permit !== null ? parking.number_parking_motor_permit : $t('string.na')
                }}
              </td>
              <td>
                {{
                  parking.number_parking_motor_request !== null ? parking.number_parking_motor_request : $t('string.na')
                }}
              </td>
              <td>
                {{
                  parking.number_parking_motor_practical !== null ? parking.number_parking_motor_practical : $t('string.na')
                }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('string.smallCarParking') }}</td>
              <td>
                {{
                  parking.number_parking_small_car_permit !== null ? parking.number_parking_small_car_permit : $t('string.na')
                }}
              </td>
              <td>
                {{
                  parking.number_parking_small_car_request !== null ? parking.number_parking_small_car_request : $t('string.na')
                }}
              </td>
              <td>
                {{
                  parking.number_parking_small_car_practical !== null ? parking.number_parking_small_car_practical : $t('string.na')
                }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('string.bigCarParking') }}</td>
              <td>
                {{
                  parking.number_parking_big_car_permit !== null ? parking.number_parking_big_car_permit : $t('string.na')
                }}
              </td>
              <td>
                {{
                  parking.number_parking_big_car_request !== null ? parking.number_parking_big_car_request : $t('string.na')
                }}
              </td>
              <td>
                {{
                  parking.number_parking_big_car_practical !== null ? parking.number_parking_big_car_practical : $t('string.na')
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ParkingStation",
  props: {
    parking: {
      type: Object,
      default: null,
    }
  }
}
</script>

<style scoped>

</style>
