import Village from "./../../views/settings/village/Index"
import List from "./../../views/settings/village/List"
import Show from "./../../views/settings/village/Show"
import Create from "./../../views/settings/village/Create"
import Edit from "./../../views/settings/village/Edit"
import Permissions from "../../permissions"

export const villageRouters = {
  path: 'village',
  component: Village,
  redirect: 'village',
  meta: {
    groups: 'village',
    permissions: [Permissions.backend.setting.village.manage]
  },
  children: [
    {
      path: '',
      name: 'list-village',
      component: List,
      meta: {
        groups: 'village',
        permissions: [Permissions.backend.setting.village.list],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Village',
            name_km: 'ភូមិ'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          },
        ]
      }
    },
    {
      path: 'create',
      name: 'create-village',
      component: Create,
      meta: {
        groups: 'village',
        permissions: [Permissions.backend.setting.village.store],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Village',
            name_km: 'ភូមិ'
          },
          {
            name_en: 'Create',
            name_km: 'បង្កើត'
          },
        ]
      }
    },
    {
      path: 'edit/:village_id',
      name: 'edit-village',
      component: Edit,
      meta: {
        groups: 'village',
        permissions: [Permissions.backend.setting.village.edit],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Village',
            name_km: 'ភូមិ'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          },
        ]
      }
    },
    {
      path: 'show/:village_id',
      name: 'show-village',
      component: Show,
      meta: {
        groups: 'village',
        permissions: [Permissions.backend.setting.village.show],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Village',
            name_km: 'ភូមិ'
          },
          {
            name_en: 'Show',
            name_km: 'មើល'
          },
        ]
      }
    }
  ]
}
