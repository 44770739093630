<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('label.province') }}
      </h3>
      <div class="box-tools">
        <template v-if="can($Permissions.backend.setting.province.store)">
          <router-link
            :to="{name: 'create-province'}"
            class="btn btn-primary btn-sm"
          >
            <i class="fa fa-plus" /> {{ $t('string.add') }}
          </router-link>
        </template>
      </div>
    </div>
    <div class="box-body">
      <div>
        <table
          id="province-table"
          class="table table-hover table-full-width table-striped table-bordered"
        >
          <thead>
            <tr>
              <th>{{ $t('table.nameInKhmer') }}</th>
              <th>{{ $t('table.nameInEnglish') }}</th>
              <th>{{ $t('label.certificate_occupancy_header_km') }}</th>
              <th>{{ $t('label.certificate_occupancy_header_en') }}</th>
              <th>{{ $t('table.createdAt') }}</th>
              <th>{{ $t('table.action') }}</th>
            </tr>
          </thead>
          <tbody />
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'List',
  metaInfo() {
    return {
      title: this.$t('menu.province'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      oTable: null
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.refreshTable()
      this.oTable.draw(true)
    }
  },
  mounted() {
    this.oTable = this.refreshTable()
    this.enabledAction()
  },
  beforeDestroy() {
    this.oTable.clear()
    this.oTable.destroy()
  },
  methods: {
    refreshTable() {
      let self = this
      return $('#province-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: process.env.VUE_APP_API + '/api/backend/province/datatable',
          data: (d) => {
            d.edit = this.$t('string.edit')
            d.show = this.$t('string.show')
            d.delete = this.$t('string.delete')
          },
          error: (xhr) => {
            self.onResponseError(xhr)
          }
        },
        columns: [
          {data: 'name_km', name: 'name_km', title: self.$t('table.nameInKhmer')},
          {data: 'name_en', name: 'name_en', title: self.$t('table.nameInEnglish')},
          {
            data: 'certificate_occupancy_header_km',
            name: 'certificate_occupancy_header_km',
            title: self.$t('label.certificate_occupancy_header_km'),
            render: (data) => {
              return data || this.$t('string.na')
            }
          },
          {
            data: 'certificate_occupancy_header_en',
            name: 'certificate_occupancy_header_en',
            title: self.$t('label.certificate_occupancy_header_en'),
            render: (data) => {
              return data || this.$t('string.na')
            }
          },
          {
            data: 'created_at',
            name: 'created_at',
            searchable: false,
            title: self.$t('table.createdAt'),
            render: (data) => {
              return this.getDate(data)
            }
          },
          {
            data: 'action',
            name: 'action',
            orderable: false,
            searchable: false,
            title: self.$t('table.action'),
          },
        ],
        language: {
          url: self.getLocale,
        },
        fixedColumns: true,
        order: [
          [2, 'desc']
        ]
      })
    },
    enabledAction() {
      let self = this
      this.clearEventHandler([
        '.btn-show',
        '.btn-edit',
        '.btn-delete',
      ])
      $(document).on('click', '.btn-show', function () {
        self.$router.push({
          name: 'show-province',
          params: {
            province_id: $(this).attr('data-id')
          }
        })
      })
      $(document).on('click', '.btn-edit', function () {
        self.$router.push({
          name: 'edit-province',
          params: {
            province_id: $(this).attr('data-id')
          }
        })
      })
      $(document).on('click', '.btn-delete', function () {
        self.onDelete({
          url: '/api/backend/province/delete',
          params: {
            id: $(this).attr('data-id')
          }
        })
      })
    },
    onDelete({url, params}) {
      this.$swal({
        title: this.i18nSwalTitle,
        text: this.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.i18nSwalNo,
        confirmButtonText: this.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$isLoading(true)
          this.$axios.post(`${process.env.VUE_APP_API}/${url}`, params)
            .then(() => {
              this.showSwalSuccess()
              this.oTable.draw()
            })
            .catch(error => {
              this.onResponseError(error)
            })
            .finally(() => {
              this.$isLoading(false)
            })
        }
      })
    }
  },
}
</script>

<style scoped>

</style>
