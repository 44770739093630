<template>
  <table
    id="auth-table"
    class="table table-hover table-full-width table-striped table-bordered"
  >
    <thead>
      <tr>
        <th>{{ $t('table.user') }}</th>
        <th>{{ $t('table.type') }}</th>
        <th>{{ $t('table.userAgent') }}</th>
        <th>{{ $t('table.ipAddress') }}</th>
        <th>{{ $t('table.createdAt') }}</th>
      </tr>
    </thead>
    <tbody />
  </table>
</template>

<script>
export default {
  name: "AuthActivityTable",
  data() {
    return {
      authTable: null,
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.authTable.clear()
      this.authTable.destroy()
      this.authTable = this.refreshTable()
      this.authTable.draw(true)
    }
  },
  mounted() {
    this.authTable = this.refreshTable()
  },
  beforeDestroy() {
    this.authTable.clear().destroy()
  },
  methods: {
    reRenderColumn() {
      this.authTable.draw()
      this.authTable.columns.adjust()
    },
    refreshTable() {
      let self = this
      return $('#auth-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: process.env.VUE_APP_API + '/api/backend/auth-activity/datatable',
          data: (d) => {
            d.edit = this.$t('label.edit')
            d.show = this.$t('label.show')
            d.delete = this.$t('label.delete')
          },
          error: (xhr) => {
            self.onResponseError(xhr)
          }
        },
        columns: [
          {
            data: 'user_name',
            name: 'user_name',
            title: 'User',
            width: '100px',
          },
          {
            data: 'type',
            name: 'type',
            title: 'Type',
            render: (data) => {
              let label = data
              let className = 'primary'
              if (label === 'logout') className = 'info'
              return `<label class="label label-${className}">${label}</label>`
            }
          },
          {
            data: 'user_agent',
            name: 'user_agent',
            title: 'User Agent',
          },
          {
            data: 'ip_address',
            name: 'ip_address',
            title: 'IP Address',
            width: '100px',
          },
          {
            data: 'created_at',
            name: 'created_at',
            width: '160px',
            title: 'Creation Date',
            render: (data) => {
              if (data !== null) {
                return this.$moment(data).locale('en').format('DD/MM/YYYY HH:m:s A')
              }
            },
          },
        ],
        language: {
          url: self.getLocale,
        },
        order: [
          [4, 'desc']
        ]
      })
    },
  }
}
</script>

<style scoped>

</style>
