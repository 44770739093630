<template>
  <table
    v-if="project!==null"
    class="table table-bordered"
  >
    <tbody>
      <tr>
        <td style="width: 130px !important;">
          {{ $t('string.projectName') }}
        </td>
        <td>{{ project['name_' + $i18n.locale ] || $t('string.na') }}</td>
      </tr>
      <tr>
        <td>{{ $t('string.projectType') }}</td>
        <td>
          <template v-if="project['project_type']!==null">
            {{ project['project_type']['name_' + $i18n.locale ] }}
          </template>
          <template v-else>
            {{ $t('string.na') }}
          </template>
        </td>
      </tr>
      <tr>
        <td>{{ $t('string.constructionType') }}</td>
        <td>
          <template v-if="project['construction_type']!==null">
            {{ project['construction_type']['name_' + $i18n.locale ] }}
          </template>
          <template v-else>
            {{ $t('string.na') }}
          </template>
        </td>
      </tr>
      <tr>
        <td>{{ $t('string.owners') }}</td>
        <td>
          <template v-if="project.owners && project.owners.length>0">
            <template v-for="(owner, key) in project.owners">
              <span :key="key">
                {{ owner['name_' + $i18n.locale ] }}
                <template v-if="!(key===(project.owners.length-1))">, </template>
              </span>
            </template>
          </template>
          <template v-else>
            {{ $t('string.na') }}
          </template>
        </td>
      </tr>
      <tr>
        <td>{{ $t('string.representativeCompany') }}</td>
        <td>
          <template v-if="project.company_representatives && project.company_representatives.length>0">
            <template v-for="(company, key) in project.company_representatives">
              <span :key="key">
                <b>{{ company['name_' + $i18n.locale] }}</b>
                <template v-if="!(key===(project.company_representatives.length-1))">, </template>
              </span>
            </template>
          </template>
          <template v-else>
            {{ $t('string.na') }}
          </template>
        </td>
      </tr>
      <tr>
        <td>{{ $t('string.permitNumberStr') }}</td>
        <td>{{ getNumbers(project.permit_number) || $t('string.na') }}</td>
      </tr>
      <tr>
        <td>{{ $t('string.permitDateStr') }}</td>
        <td>{{ getNumbers(getDate(project.permit_date, false, 'DD/MM/YYYY')) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "ConstructionLetterProject",
  props: {
    project: {
      type: Object,
      default: null,
    }
  }
}
</script>

<style scoped>

</style>
