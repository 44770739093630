<template>
  <div
    v-if="roubavontbokkol"
    class="row"
  >
    <div class="col-md-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('string.roubavontbokkolsDetail') }}
          </h3>
          <div class="box-tools">
            <asora-back-button />
          </div>
        </div>
        <div class="box-body">
          <template v-if="roubavontbokkol">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td colspan="2">
                    <render-image-tag :src="roubavontbokkol.avatar ? `${baseUrl}/${roubavontbokkol.avatar}` : null" />
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('string.name') }}</td>
                  <td>{{ roubavontbokkol['name_' + $i18n.locale ] }}</td>
                </tr>
                <tr>
                  <td>{{ $t('label.architectureType') }}</td>
                  <td>
                    <template v-if="roubavontbokkol.architecture_type">
                      {{ roubavontbokkol.architecture_type['name_' + $i18n.locale ] }}
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('label.uniqueNumber') }}</td>
                  <td>{{ roubavontbokkol.unique_number || $t('string.na') }}</td>
                </tr>
                <tr>
                  <td>{{ $t('label.declarationNumberOfMinistry') }}</td>
                  <td>{{ roubavontbokkol.registration_number || $t('string.na') }}</td>
                </tr>
                <tr>
                  <td>{{ $t('label.registration_expired_date') }}</td>
                  <td>{{ getDate(roubavontbokkol.registration_expired_date, false, 'DD/MM/YYYY') }}</td>
                </tr>
                <tr>
                  <td>{{ $t('label.gender') }}</td>
                  <td>
                    {{ roubavontbokkol.gender ? roubavontbokkol.gender['name_' + $i18n.locale] : $t('string.na') }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('label.dob') }}</td>
                  <td>
                    {{ getDate(roubavontbokkol.dob, false, 'DD/MM/YYYY') }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('label.placeOfBirth') }}</td>
                  <td>
                    {{ roubavontbokkol.place_of_birth || $t('string.na') }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('label.degree') }}</td>
                  <td>
                    <template v-if="roubavontbokkol.degree">
                      {{ roubavontbokkol.degree['name_' + $i18n.locale ] }}
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('label.salary') }}</td>
                  <td>{{ roubavontbokkol.salary || $t('string.na') }}</td>
                </tr>
                <tr>
                  <td>{{ $t('label.certificate_number') }}</td>
                  <td>{{ roubavontbokkol.certificate_number || $t('string.na') }}</td>
                </tr>
                <tr>
                  <td>{{ $t('label.graduateDate') }}</td>
                  <td>{{ getDate(roubavontbokkol.graduation_date, false, 'DD/MM/YYYY') }}</td>
                </tr>
                <tr>
                  <td>{{ $t('label.fromUniversity') }}</td>
                  <td>
                    <template v-if="roubavontbokkol.university">
                      {{ roubavontbokkol.university['name_' + $i18n.locale] }}
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('label.email') }}</td>
                  <td>{{ roubavontbokkol.email ? roubavontbokkol.email : $t('string.na') }}</td>
                </tr>
                <tr>
                  <td>{{ $t('label.phone') }}</td>
                  <td>{{ roubavontbokkol.phone ? roubavontbokkol.phone : $t('string.na') }}</td>
                </tr>
                <tr>
                  <td>{{ $t('label.nationality') }}</td>
                  <td>
                    {{ roubavontbokkol.nationality ? roubavontbokkol.nationality['name_' + $i18n.locale] : $t('string.na')
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('label.nationalIdCard') }}</td>
                  <td>{{ roubavontbokkol.national_id_card || $t('string.na') }}</td>
                </tr>
                <tr>
                  <td>{{ $t('label.nationalIdCardCreationDate') }}</td>
                  <td>
                    {{ getDate(roubavontbokkol.national_id_card_creation_date, false, 'DD/MM/YYYY') }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('label.passportNumber') }}</td>
                  <td>{{ roubavontbokkol.passport_number || $t('string.na') }}</td>
                </tr>
                <tr>
                  <td>{{ $t('label.homeNumber') }}</td>
                  <td>{{ roubavontbokkol.home_number || $t('string.na') }}</td>
                </tr>
                <tr>
                  <td>{{ $t('label.streetNumber') }}</td>
                  <td>{{ roubavontbokkol.street_number || $t('string.na') }}</td>
                </tr>
                <tr>
                  <td>{{ $t('label.groupNumber') }}</td>
                  <td>{{ roubavontbokkol.group_number || $t('string.na') }}</td>
                </tr>
                <tr>
                  <td>{{ $t('label.province') }}</td>
                  <td>
                    {{ roubavontbokkol.province ? roubavontbokkol.province['name_' + $i18n.locale] : $t('string.na') }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('label.district') }}</td>
                  <td>
                    {{ roubavontbokkol.district ? roubavontbokkol.district['name_' + $i18n.locale] : $t('string.na') }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('label.commune') }}</td>
                  <td>
                    {{ roubavontbokkol.commune ? roubavontbokkol.commune['name_' + $i18n.locale] : $t('string.na') }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('label.village') }}</td>
                  <td>
                    {{ roubavontbokkol.village ? roubavontbokkol.village['name_' + $i18n.locale] : $t('string.na') }}
                  </td>
                </tr>
                <tr>
                  <td>{{ $t('label.createdAt') }}</td>
                  <td>{{ getDate(roubavontbokkol.created_at, false) }}</td>
                </tr>
                <tr>
                  <td>{{ $t('label.updatedAt') }}</td>
                  <td>{{ getDate(roubavontbokkol.updated_at, false) }}</td>
                </tr>
              </tbody>
            </table>
          </template>
          <template v-else>
            <p class="text-center text-muted">
              {{ $t('string.noResult') }}
            </p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RenderImageTag from "@/components/RenderImageTag"

export default {
  name: "RoubavontbokkolsInformation",
  components: {RenderImageTag},
  props: {
    roubavontbokkol: {
      type: Object,
      default: null,
    }
  },
}
</script>

<style scoped>

</style>
