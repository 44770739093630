<template>
  <div
    v-if="chips && chips.data && Array.isArray(chips.data) && chips.data.length > 0"
    :class="wrapperClass"
  >
    <template v-if="model === 'people'">
      <PeopleChipItem
        v-for="(person, key) in chips.data"
        :key="key"
        :people="person"
        :model="model"
      />
    </template>
  </div>
</template>

<script>
import PeopleChipItem from '@/components/PeopleChipItem'

export default {
  name: "DisplayPeopleChip",
  components: {PeopleChipItem},
  props: {
    chips: {
      type: Object,
      default() {
        return {
          model: '',
          data: []
        }
      }
    },
    wrapperClass: {
      type: String,
      default: 'col-sm-8 col-sm-offset-4 margin-top-15'
    }
  },
  computed: {
    model() {
      if (this.chips && this.chips.model) {
        return this.chips.model
      }
      return null
    }
  }
}
</script>

<style scoped>

</style>
