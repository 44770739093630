<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.newCommune') }}
      </h3>
    </div>
    <div class="box-body">
      <commune-form />
    </div>
  </div>
</template>

<script>

import CommuneForm from "./Form"

export default {
  name: "Create",
  metaInfo() {
    return {
      title: this.$t('string.newCommune'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {CommuneForm}
}
</script>

<style scoped>

</style>
