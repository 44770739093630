<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('menu.businessBuilder') }}
      </h3>
    </div>
    <div class="box-body">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th
              class="column-1"
              colspan="2"
            />
            <th class="column-other">
              {{ $t('label.total') }}
            </th>
            <th class="column-other">
              {{ $t('label.thisYear') }}
            </th>
            <th class="column-other">
              {{ $t('label.thisMonth') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in data">
            <tr :key="JSON.stringify(item)">
              <td colspan="2">
                <strong>{{ item['name_' + $i18n.locale] }}</strong>
              </td>
              <td><strong>{{ item.total }}</strong></td>
              <td><strong>{{ item.year }}</strong></td>
              <td><strong>{{ item.month }}</strong></td>
            </tr>
            <template v-for="subitem in item.children">
              <tr :key="`${JSON.stringify(subitem)}-${Math.random()}`">
                <td />
                <td>{{ subitem['name_' + $i18n.locale] }}</td>
                <td>{{ subitem.total }}</td>
                <td>{{ subitem.year }}</td>
                <td>{{ subitem.month }}</td>
              </tr>
            </template>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "ListReportBusinessBuilder",
  props: {
    data: {
      required: true
    }
  }
}
</script>

<style scoped>
  .column-1 {
    width: 30%;
  }

  .column-other {
    width: 20%;
  }
</style>
