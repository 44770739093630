import axios from "axios"

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': '*',
  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  'Accept': 'application/json',
  'Content-Type': 'application/json',
}

function returnAccessDenied() {
  localStorage.clear()
}

function setUpToken() {
  if (localStorage.getItem(process.env.VUE_APP_TOKEN)) {
    axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN)
    $.ajaxSetup({
      headers: {
        'Accept': 'application/json',
        'Authorization': "Bearer " + localStorage.getItem(process.env.VUE_APP_TOKEN),
      }
    })
  }
}

setUpToken()

axios.interceptors.request.use(function (config) {
  return config
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status !== 401) {
    return Promise.reject(error)
  }

  return axios.post(process.env.VUE_APP_API + '/api/auth/backend/refresh-token', {
    'rft': localStorage.getItem(process.env.VUE_APP_REFRESH_TOKEN)
  }).then((response) => {
    // eslint-disable-next-line no-prototype-builtins
    if (response.data && !response.data.hasOwnProperty('data')) {
      return returnAccessDenied()
    }

    const {access_token, refresh_token} = response.data.data

    localStorage.setItem(process.env.VUE_APP_TOKEN, access_token)
    localStorage.setItem(process.env.VUE_APP_REFRESH_TOKEN, refresh_token)
    window.location.reload()
  }).catch(() => {
    return returnAccessDenied()
  })
})

export default axios

