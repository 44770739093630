import Index from "@/views/roubavontbokkols/registrations/Index"
import List from "@/views/roubavontbokkols/registrations/List"
import Create from "@/views/roubavontbokkols/registrations/Create"
import Show from "@/views/roubavontbokkols/registrations/Show"
import Edit from "@/views/roubavontbokkols/registrations/Edit"
import Permissions from "@/permissions"

const roubavontbokkolRegistration = Permissions.backend.roubavontbokkol.roubavontbokkolRegistration

const getMeta = (permissions, link) => {
  return {
    permissions,
    groups: 'registration-roubavontbokkols',
    breadcrumb: [
      {
        name_en: 'Roubavontbokkols',
        name_km: 'រូបវន្តបុគ្គល'
      },
      link
    ]
  }
}

export const roubavontbokkolRegistrationRouters = {
  path: 'registration',
  component: Index,
  redirect: 'registration',
  meta: {
    permissions: [roubavontbokkolRegistration.manage],
    groups: 'registration-roubavontbokkols',
  },
  children: [
    {
      path: '',
      name: 'list-roubavontbokkols',
      component: List,
      meta: getMeta([roubavontbokkolRegistration.list], {
        name_en: 'List',
        name_km: 'បញ្ជី'
      })
    },
    {
      path: 'create',
      name: 'create-roubavontbokkols',
      component: Create,
      meta: getMeta([roubavontbokkolRegistration.store], {
        name_en: 'Create',
        name_km: 'បង្កើត'
      })
    },
    {
      path: 'edit/:roubavontbokkols_uuid',
      name: 'edit-roubavontbokkols',
      component: Edit,
      meta: getMeta([roubavontbokkolRegistration.edit], {
        name_en: 'Edit',
        name_km: 'កែសម្រួល'
      })
    },
    {
      path: 'show/:roubavontbokkols_uuid',
      name: 'show-roubavontbokkols',
      component: Show,
      meta: getMeta([roubavontbokkolRegistration.show], {
        name_en: 'Show',
        name_km: 'មើល'
      })
    },
  ]
}
