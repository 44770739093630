export const validationMixins = {
  computed: {
    nameKmRequired() {
      return this.getValidationColumn(this.validations, 'name_km')
    },
    nameEnRequired() {
      return this.getValidationColumn(this.validations, 'name_en')
    },
    registrationNumberRequired() {
      return this.getValidationColumn(this.validations, 'registration_number')
    },
    uniqueNumberRequired() {
      return this.getValidationColumn(this.validations, 'unique_number')
    },
    genderRequired() {
      return this.getValidationColumn(this.validations, 'gender_id')
    },
    architectureRequired() {
      return this.getValidationColumn(this.validations, 'architecture_type_id')
    },
    ownerValidation() {
      return this.getValidationColumn(this.validations, 'owners')
    },
    ctoValidation() {
      return this.getValidationColumn(this.validations, 'cto')
    },
    ceoValidation() {
      return this.getValidationColumn(this.validations, 'ceo')
    },
    companyTypeValidation() {
      return this.getValidationColumn(this.validations, 'company_type_id')
    },
    companySectionValidation() {
      return this.getValidationColumn(this.validations, 'company_section_id')
    },
    countryValidation() {
      return this.getValidationColumn(this.validations, 'country_id')
    },
    registrationNumber() {
      return this.getValidationColumn(this.validations, 'registration_number')
    },
    referenceNumberValidation() {
      return this.getValidationColumn(this.validations, 'reference_number')
    },
    descriptionValidation() {
      return this.getValidationColumn(this.validations, 'description')
    },
    titleValidation() {
      return this.getValidationColumn(this.validations, 'title')
    },
    projectValidation() {
      return this.getValidationColumn(this.validations, 'project_id')
    },
    dateValidation() {
      return this.getValidationColumn(this.validations, 'start_date') || this.getValidationColumn(this.validations, 'end_date')
    },
    missionTypeValidation() {
      return this.getValidationColumn(this.validations, 'mission_type_id')
    },
    provinceValidation() {
      return this.getValidationColumn(this.validations, 'province_id')
    },
    districtValidation() {
      return this.getValidationColumn(this.validations, 'district_id')
    },
    communeValidation() {
      return this.getValidationColumn(this.validations, 'commune_id')
    },
    villageValidation() {
      return this.getValidationColumn(this.validations, 'village_id')
    },
    projectTypeValidation() {
      return this.getValidationColumn(this.validations, 'project_type_id')
    },
    approvedByValidation() {
      return this.getValidationColumn(this.validations, 'approved_by')
    },
    issuedNumberValidation() {
      return this.getValidationColumn(this.validations, 'issued_number')
    },
    issuedNumberKmValidation() {
      return this.getValidationColumn(this.validations, 'issued_number_km')
    },
    issuedNumberEnValidation() {
      return this.getValidationColumn(this.validations, 'issued_number_en')
    },
    issuedAtValidation() {
      return this.getValidationColumn(this.validations, 'issued_at')
    },
    requestedByValidation() {
      return this.getValidationColumn(this.validations, 'requested_by')
    },
    requestedDateValidation() {
      return this.getValidationColumn(this.validations, 'requested_date')
    },
    openConstructionNumberValidation() {
      return this.getValidationColumn(this.validations, 'open_construction_number')
    },
    openConstructionDateValidation() {
      return this.getValidationColumn(this.validations, 'open_construction_date')
    },
    closeConstructionNumberValidation() {
      return this.getValidationColumn(this.validations, 'close_construction_number')
    },
    closeConstructionDateValidation() {
      return this.getValidationColumn(this.validations, 'close_construction_date')
    },
    getProjectValidation() {
      return this.getValidationColumn(this.validations, 'project')
    },
    getSelectedProjectValidation() {
      return this.getValidationColumn(this.validations, 'selected_project_id')
    },
  }
}
