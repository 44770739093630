import Project from "../views/provinceProject/Index"
import List from "../views/provinceProject/List"
import Map from "../views/provinceProject/Map"
import Create from "../views/provinceProject/Create"
import Edit from "../views/provinceProject/Edit"
import Show from "../views/provinceProject/Show"
import provinceConstructionDataIndex from "../views/provinceProject/ConstructinData/Index"
import provinceConstructionDataList from "../views/provinceProject/ConstructinData/List"
import provinceConstructionDataCreate from "../views/provinceProject/ConstructinData/Create"
import provinceConstructionDataEdit from "../views/provinceProject/ConstructinData/Edit"
import provinceConstructionDataShow from "../views/provinceProject/ConstructinData/Show"
import Permissions from "../permissions"

export const provinceProjectRouters = {
  path: 'province-project',
  name: 'province-project',
  component: Project,
  redirect: 'province-project',
  meta: {
    permissions: [
      Permissions.backend.provinceProject.manage,
      Permissions.backend.provinceConstructionData.manage
    ],
    groups: 'province-project',
  },
  children: [
    {
      path: '',
      name: 'list-province-project',
      component: List,
      meta: {
        permissions: [Permissions.backend.provinceProject.list],
        groups: 'province-project',
        breadcrumb: [
          {
            name_en: 'Project',
            name_km: 'គម្រោង'
          },
          {
            name_en: 'Project List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'map',
      name: 'map-province-project',
      component: Map,
      meta: {
        permissions: [Permissions.backend.provinceProject.map],
        groups: 'province-project',
        breadcrumb: [
          {
            name_en: 'Project',
            name_km: 'គម្រោង'
          },
          {
            name_en: 'List',
            name_km: 'ផែនទី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-province-project',
      component: Create,
      meta: {
        permissions: [Permissions.backend.provinceProject.store],
        groups: 'map-province-project',
        breadcrumb: [
          {
            name_en: 'Project',
            name_km: 'គម្រោង'
          },
          {
            name_en: 'New',
            name_km: 'បង្កើត'
          }
        ]
      }
    },
    {
      path: 'edit/:project_uuid',
      name: 'edit-province-project',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.provinceProject.edit],
        groups: 'province-project',
        breadcrumb: [
          {
            name_en: 'Project',
            name_km: 'គម្រោង'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'show/:project_uuid',
      name: 'show-province-project',
      component: Show,
      meta: {
        permissions: [Permissions.backend.provinceProject.show],
        groups: 'province-project',
        breadcrumb: [
          {
            name_en: 'Project',
            name_km: 'គម្រោង'
          },
          {
            name_en: 'Show',
            name_km: 'មើល'
          }
        ]
      }
    },

    //route for construction data
    {
      path: 'construction-data',
      name: 'construction-data',
      component: provinceConstructionDataIndex,
      redirect: 'construction-data',
      meta: {
        permissions: [Permissions.backend.provinceConstructionData.manage],
        groups: 'province-construction-data',
      },
      children: [
        {
          path: '',
          name: 'list-construction-data',
          component: provinceConstructionDataList,
          meta: {
            permissions: [Permissions.backend.provinceConstructionData.list],
            groups: 'province-construction-data',
            breadcrumb: [
              {
                name_en: 'Project',
                name_km: 'គម្រោង'
              },
              {
                name_en: 'construction data',
                name_km: 'សម្រង់ទិន្នន័យសំណង់'
              },
              {
                name_en: 'List',
                name_km: 'បញ្ជី'
              }
            ]
          },
        },
        {
          path: 'create',
          name: 'create-construction-data',
          component: provinceConstructionDataCreate,
          meta: {
            permissions: [Permissions.backend.provinceConstructionData.store],
            groups: 'province-construction-data',
            breadcrumb: [
              {
                name_en: 'Project',
                name_km: 'គម្រោង'
              },
              {
                name_en: 'construction data',
                name_km: 'សម្រង់ទិន្នន័យសំណង់'
              },
              {
                name_en: 'New',
                name_km: 'បង្កើត'
              }
            ]
          },
        },
        {
          path: 'edit/:construction_data_uuid',
          name: 'edit-construction-data',
          component: provinceConstructionDataEdit,
          meta: {
            permissions: [Permissions.backend.provinceConstructionData.edit],
            groups: 'province-construction-data',
            breadcrumb: [
              {
                name_en: 'Project',
                name_km: 'គម្រោង'
              },
              {
                name_en: 'construction data',
                name_km: 'សម្រង់ទិន្នន័យសំណង់'
              },
              {
                name_en: 'Edit',
                name_km: 'កែសម្រួល'
              }
            ]
          },
        },
        {
          path: 'show/:code',
          name: 'show-construction-data',
          component: provinceConstructionDataShow,
          meta: {
            permissions: [Permissions.backend.provinceConstructionData.show],
            groups: 'province-construction-data',
            breadcrumb: [
              {
                name_en: 'Project',
                name_km: 'គម្រោង'
              },
              {
                name_en: 'construction data',
                name_km: 'សម្រង់ទិន្នន័យសំណង់'
              },
              {
                name_en: 'Show',
                name_km: 'មើល'
              }
            ]
          },
        },

      ]
    },
  ]
}
