import LawRegulation from "./../views/lawRegulation/Index"
import List from "./../views/lawRegulation/List"
import Create from "./../views/lawRegulation/Create"
import Edit from "./../views/lawRegulation/Edit"
import Show from "./../views/lawRegulation/Show"
import Permissions from "../permissions"

export const lawRegulationRouters = {
  path: 'law-regulation',
  component: LawRegulation,
  redirect: 'law-regulation',
  meta: {
    permissions: [Permissions.backend.lawRegulation.manage],
    groups: 'law-regulation',
  },
  children: [
    {
      path: '',
      name: 'list-law-regulation',
      component: List,
      meta: {
        permissions: [Permissions.backend.lawRegulation.list],
        groups: 'law-regulation',
        breadcrumb: [
          {
            name_en: 'Law Regulation',
            name_km: 'ច្បាប់បទប្បញ្ញត្តិសំណង់'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-law-regulation',
      component: Create,
      meta: {
        permissions: [Permissions.backend.lawRegulation.store],
        groups: 'law-regulation',
        breadcrumb: [
          {
            name_en: 'Law Regulation',
            name_km: 'ច្បាប់បទប្បញ្ញត្តិសំណង់'
          },
          {
            name_en: 'Create',
            name_km: 'បន្ថែមថ្មី'
          }
        ]
      }
    },
    {
      path: 'edit/:law_regulation_uuid',
      name: 'edit-law-regulation',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.lawRegulation.edit],
        groups: 'law-regulation',
        breadcrumb: [
          {
            name_en: 'Law Regulation',
            name_km: 'ច្បាប់បទប្បញ្ញត្តិសំណង់'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'show/:law_regulation_uuid',
      name: 'show-law-regulation',
      component: Show,
      meta: {
        permissions: [Permissions.backend.lawRegulation.show],
        groups: 'law-regulation',
        breadcrumb: [
          {
            name_en: 'Law Regulation',
            name_km: 'ច្បាប់បទប្បញ្ញត្តិសំណង់'
          },
          {
            name_en: 'Show',
            name_km: 'មើល'
          }
        ]
      }
    }
  ]
}
