export const unitCalculationMixins = {
  computed: {
    getSurfacePercentageOfLandUseLaw() {
      let value = 0
      if (this.project.surface_bottom_cell > 0 && this.project.surface > 0) {
        value = parseFloat((this.project.surface_bottom_cell / this.project.surface) * 100).toFixed(2)
      }
      this.project.surface_percentage_of_land_used_law = value
      return value
    },
    getSurfaceIndexOver() {
      let value = 0
      if (this.project.surface > 0) {
        value = this.project.index_over * this.project.surface
        if (value>0) {
          value = parseFloat(value).toFixed(2)
        }
      }
      this.project.surface_index_over = value
      return value
    },
    getProjectIndexOver() {
      let value = 0
      if (this.project.index_practical > 0 && this.project.index_permit > 0) {
        if (this.project.index_practical < this.project.index_permit) {
          value = this.project.index_permit - this.project.index_practical
          if (value>0) {
            value = parseFloat(value).toFixed(2)
          }
        }
      }
      this.project.index_over = value
      return value
    },
    getProjectPermitIndex() {
      let value = 0
      if (this.project.surface > 0 && this.project.construction_surface_can_processed > 0) {
        value = this.project.construction_surface_can_processed / this.project.surface
        if (value>0) {
          value = parseFloat(value).toFixed(2)
        }
      }
      this.project.index_permit = value
      return value
    }
  }
}
