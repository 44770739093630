<template>
  <show-architecture
    :edit-permission="$Permissions.backend.hasBoard.engineer.edit"
    :title="$t('string.showHasBoardArchitecture')"
    model-route-edit="edit-has-board-architecture"
  />
</template>

<script>
import ShowArchitecture from "../../architecture/Show"

export default {
  name: "Show",
  components: {ShowArchitecture},
  metaInfo() {
    return {
      title: this.$t('string.showHasBoardArchitecture'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
}
</script>

<style scoped>

</style>
