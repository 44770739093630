import ArchitectureType from "./../../views/settings/ministryPermitOrLicense/Index"
import List from "./../../views/settings/ministryPermitOrLicense/List"
import Create from "./../../views/settings/ministryPermitOrLicense/Create"
import Edit from "./../../views/settings/ministryPermitOrLicense/Edit"
import Show from "./../../views/settings/ministryPermitOrLicense/Show"
import Permissions from "../../permissions"

export const ministryPermitOrLicenseRouters = {
  path: 'ministry-permit-or-license',
  component: ArchitectureType,
  redirect: 'ministry-permit-or-license',
  meta: {
    permissions: [Permissions.backend.setting.ministryPermitOrLicense.manage],
    groups: 'ministry-permit-or-license',
  },
  children: [
    {
      path: '',
      name: 'list-ministry-permit-or-license',
      component: List,
      meta: {
        permissions: [Permissions.backend.setting.ministryPermitOrLicense.list],
        groups: 'ministry-permit-or-license',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Ministry Permit or License',
            name_km: 'ការអនុញ្ញាតពីក្រសួងរឺអាជ្ញាប័ណ្ណ'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-ministry-permit-or-license',
      component: Create,
      meta: {
        permissions: [Permissions.backend.setting.ministryPermitOrLicense.store],
        groups: 'ministry-permit-or-license',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Ministry Permit or License',
            name_km: 'ការអនុញ្ញាតពីក្រសួងរឺអាជ្ញាប័ណ្ណ'
          },
          {
            name_en: 'New',
            name_km: 'បង្កើតថ្មី'
          }
        ]
      }
    },
    {
      path: 'edit/:ministry_permit_or_license_uuid',
      name: 'edit-ministry-permit-or-license',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.setting.ministryPermitOrLicense.edit],
        groups: 'ministry-permit-or-license',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Ministry Permit or License',
            name_km: 'ការអនុញ្ញាតពីក្រសួងរឺអាជ្ញាប័ណ្ណ'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'show/:ministry_permit_or_license_uuid',
      name: 'show-ministry-permit-or-license',
      component: Show,
      meta: {
        permissions: [Permissions.backend.setting.ministryPermitOrLicense.show],
        groups: 'ministry-permit-or-license',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Ministry Permit or License',
            name_km: 'ការអនុញ្ញាតពីក្រសួងរឺអាជ្ញាប័ណ្ណ'
          },
          {
            name_en: 'View',
            name_km: 'មើល'
          }
        ]
      }
    }
  ]
}
