<template>
  <div class="form-horizontal">
    <div
      :class="valueRequired.required ? ' has-error' : null"
      class="form-group row"
    >
      <label class="col-sm-3 control-label required">{{ $t('string.command') }}</label>
      <div class="col-sm-9">
        <input
          v-model="construction_letter_command.value"
          :placeholder="$t('string.command')"
          class="form-control"
          type="text"
        >
        <template v-if="valueRequired.required">
          <span class="label-error">{{ valueRequired.message }}</span>
        </template>
      </div>
    </div>

    <div
      :class="typeRequired.required ? ' has-error' : null"
      class="form-group row"
    >
      <label class="col-sm-3 control-label required">{{ $t('string.type') }}</label>
      <div class="col-sm-9">
        <select
          v-model="construction_letter_command.type"
          class="form-control"
        >
          <option
            :value="null"
            disabled
            selected
          >
            {{ $t('string.type') }}
          </option>
          <option value="open_construction">
            {{ $t('string.openConstruction') }}
          </option>
          <option value="close_construction">
            {{ $t('string.closeConstruction') }}
          </option>
          <option value="pending_construction">
            {{ $t('string.pendingConstruction') }}
          </option>
        </select>
        <template v-if="typeRequired.required">
          <span class="label-error">{{ typeRequired.message }}</span>
        </template>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-offset-3 col-sm-9">
        <div class="checkbox">
          <label>
            <input
              v-model="construction_letter_command.enable"
              type="checkbox"
            > {{ $t('label.enable') }}
          </label>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-9 col-sm-offset-3">
        <button
          class="btn btn-primary btn-sm"
          @click="store"
        >
          {{ $t('button.save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConstructionLetterCommandForm",
  data() {
    return {
      construction_letter_command: {
        enable: false,
        type: null,
      },
      validations: null,
    }
  },
  computed: {
    valueRequired() {
      return this.getValidationColumn(this.validations, 'value')
    },
    typeRequired() {
      return this.getValidationColumn(this.validations, 'type')
    },
    urlAction() {
      // eslint-disable-next-line no-prototype-builtins
      return this.$route.params.hasOwnProperty('construction_letter_command_uuid') ? 'update' : 'create'
    }
  },
  mounted() {
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty("construction_letter_command_uuid")) {
      this.getConstructionLetterCommand()
    }
  },
  methods: {
    store() {
      this.$isLoading(true)
      if (this.construction_letter_command.uuid) {
        this.construction_letter_command.construction_letter_command_uuid = this.construction_letter_command.uuid
      }
      this.$axios.post(`${process.env.VUE_APP_API}/api/backend/construction-letter-command/${this.urlAction}`, this.construction_letter_command)
        .then(() => {
          this.showToastr()
          this.$router.push({name: 'list-construction-letter-command'})
        })
        .catch((error) => {
          this.validations = null
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getConstructionLetterCommand() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/construction-letter-command/show', {
        construction_letter_command_uuid: this.$route.params.construction_letter_command_uuid,
      }).then((res) => {
        this.construction_letter_command = res.data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-construction-letter-command'})
      }).finally(() => {
        this.$isLoading(false)
      })
    },
  }
}
</script>

<style scoped>

</style>
