<template>
  <div class="row">
    <div class="col-md-3">
      <div class="list-group">
        <a
          v-for="(item, key) in sidebar"
          :key="key"
          :class="item.clicked?'active':null"
          class="list-group-item"
          href="javascript:void(0)"
          @click="switchTab(item)"
        >{{ item.name }}</a>
      </div>
    </div>
    <div class="col-md-9">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ sidebar[clicked]['name'] }}
          </h3>
        </div>
        <div class="box-body">
          <div v-show="clicked===0">
            <auth-activity-table ref="authActivityTableRef" />
          </div>
          <div v-show="clicked===1">
            <log-activity-table ref="logActivityTableRef" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogActivityTable from "./tables/LogActivityTable"
import AuthActivityTable from "./tables/AuthActivityTable"

export default {
  name: "List",
  components: {AuthActivityTable, LogActivityTable},
  metaInfo() {
    return {
      title: this.$t('string.activityList'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      showAuthActivityTable: true,
      showLogActivityTable: true,
      sidebar: [
        {name: this.$t('activity.auth'), value: 'auth', clicked: true,},
        {name: this.$t('activity.log'), value: 'log', clicked: false,},
      ],
      clicked: 0,
    }
  },
  methods: {
    switchTab(item) {
      this.sidebar.forEach(item => item.clicked = false)
      let index = this.sidebar.lastIndexOf(item)
      let sidebarClicked = this.sidebar[index]
      sidebarClicked.clicked = true
      this.clicked = index
      if (index === 0) {
        this.$refs.authActivityTableRef.reRenderColumn()
      } else {
        this.$refs.logActivityTableRef.reRenderColumn()
      }
    },
  }
}
</script>

<style scoped>

</style>
