import Province from "./../../views/settings/province/Index"
import List from "./../../views/settings/province/List"
import Create from "./../../views/settings/province/Create"
import Show from "./../../views/settings/province/Show"
import Edit from "./../../views/settings/province/Edit"
import Permissions from "../../permissions"

export const provinceRouters = {
  path: 'province',
  component: Province,
  redirect: 'province',
  meta: {
    groups: 'province',
    permissions: [Permissions.backend.setting.province.manage]
  },
  children: [
    {
      path: '',
      name: 'list-province',
      component: List,
      meta: {
        groups: 'province',
        permissions: [Permissions.backend.setting.province.list],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Province',
            name_km: 'ខេត្ត/រាជធានី'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-province',
      component: Create,
      meta: {
        groups: 'province',
        permissions: [Permissions.backend.setting.province.store],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Province',
            name_km: 'ខេត្ត/រាជធានី'
          },
          {
            name_en: 'Create',
            name_km: 'បង្កើត'
          }
        ]
      }
    },
    {
      path: 'edit/:province_id',
      name: 'edit-province',
      component: Edit,
      meta: {
        groups: 'province',
        permissions: [Permissions.backend.setting.province.edit],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Province',
            name_km: 'ខេត្ត/រាជធានី'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'show/:province_id',
      name: 'show-province',
      component: Show,
      meta: {
        groups: 'province',
        permissions: [Permissions.backend.setting.province.show],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Province',
            name_km: 'ខេត្ត/រាជធានី'
          },
          {
            name_en: 'Show',
            name_km: 'មើល'
          }
        ]
      }
    }
  ]
}
