import Permissions from "@/permissions"
import Index from '@/views/businessBuilders/professionalStudyPlanner/Index'
import Edit from '@/views/businessBuilders/professionalStudyPlanner/Edit'
import Show from '@/views/businessBuilders/professionalStudyPlanner/Show'
import List from '@/views/businessBuilders/professionalStudyPlanner/List'
import Create from '@/views/businessBuilders/professionalStudyPlanner/Create'

export const professionalStudyPlannerRouters = {
  path: 'professional-study-planner',
  name: 'professional-study-planner',
  component: Index,
  redirect: 'professional-study-planner',
  meta: {
    permissions: [Permissions.backend.businessBuilders.professionalStudyPlanner.manage],
    groups: 'professional-study-planner',
  },
  children: [
    {
      path: '',
      name: 'list-professional-study-planner',
      component: List,
      meta: {
        permissions: [Permissions.backend.businessBuilders.professionalStudyPlanner.list],
        groups: 'professional-study-planner',
        breadcrumb: [
          {
            name_en: 'Construction Certifier License',
            name_km: ' អាជ្ញាបណ្ណក្នុងវិស័យសំណង់'
          },
          {
            name_en: 'Professional Study Planner',
            name_km: 'អ្នកវិជ្ជាជីវៈសិក្សាគម្រោងប្លង់'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-professional-study-planner',
      component: Create,
      meta: {
        permissions: [Permissions.backend.businessBuilders.professionalStudyPlanner.store],
        groups: 'professional-study-planner',
        breadcrumb: [
          {
            name_en: 'Construction Certifier License',
            name_km: ' អាជ្ញាបណ្ណក្នុងវិស័យសំណង់'
          },
          {
            name_en: 'Professional Study Planner',
            name_km: 'អ្នកវិជ្ជាជីវៈសិក្សាគម្រោងប្លង់'
          },
          {
            name_en: 'Create',
            name_km: 'បន្ថែមថ្មី'
          }
        ]
      }
    },
    {
      path: 'show/:architecture_uuid',
      name: 'show-professional-study-planner',
      component: Show,
      meta: {
        permissions: [Permissions.backend.businessBuilders.professionalStudyPlanner.show],
        groups: 'professional-study-planner',
        breadcrumb: [
          {
            name_en: 'Construction Certifier License',
            name_km: ' អាជ្ញាបណ្ណក្នុងវិស័យសំណង់'
          },
          {
            name_en: 'Professional Study Planner',
            name_km: 'អ្នកវិជ្ជាជីវៈសិក្សាគម្រោងប្លង់'
          },
          {
            name_en: 'Show',
            name_km: 'មើល'
          }
        ]
      }
    },
    {
      path: 'edit/:architecture_uuid',
      name: 'edit-professional-study-planner',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.businessBuilders.professionalStudyPlanner.edit],
        groups: 'professional-study-planner',
        breadcrumb: [
          {
            name_en: 'Construction Certifier License',
            name_km: ' អាជ្ញាបណ្ណក្នុងវិស័យសំណង់'
          },
          {
            name_en: 'Professional Study Planner',
            name_km: 'អ្នកវិជ្ជាជីវៈសិក្សាគម្រោងប្លង់'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
  ]
}
