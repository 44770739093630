import {provinceCertificatesOccupancyRouters} from "./occupancy"
import Permissions from "../../permissions"

export const certificatesProvinceRouters = {
  path: 'certificates-province',
  name: 'certificates-province',
  component: () => import('@/views/provinceCertificates/Index'),
  meta: {
    permissions: [Permissions.backend.certificateProvince.manage],
    groups: 'certificates-province',
  },
  children: [
    provinceCertificatesOccupancyRouters,
  ]
}
