<template>
  <div
    id="countryInvestmentModal"
    aria-labelledby="countryInvestmentModalLabel"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4
            id="countryInvestmentModalLabel"
            class="modal-title"
          >
            {{ $t('string.newCountry') }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="form-horizontal">
            <div
              :class="'form-group row ' + (validations !== null && validations.hasOwnProperty('name_km') ? 'has-error' : '')"
            >
              <label class="col-sm-3 control-label required">{{ $t('label.nameKm') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="country.name_km"
                  :placeholder="$t('label.nameKm')"
                  class="form-control"
                  type="text"
                >
                <span
                  v-if="validations !== null && validations.hasOwnProperty('name_km')"
                  class="label-error"
                >
                  {{ validations['name_km'][0] }}
                </span>
              </div>
            </div>

            <div
              :class="'form-group row ' + (validations !== null && validations.hasOwnProperty('name_en') ? 'has-error' : '')"
            >
              <label class="col-sm-3 control-label required">{{ $t('label.nameEn') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="country.name_en"
                  :placeholder="$t('label.nameEn')"
                  class="form-control"
                  type="text"
                >
                <span
                  v-if="validations !== null && validations.hasOwnProperty('name_en')"
                  class="label-error"
                >
                  {{ validations['name_en'][0] }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            ref="close"
            class="btn btn-default"
            data-dismiss="modal"
            type="button"
          >
            {{ $t('button.close') }}
          </button>
          <button
            class="btn btn-primary"
            type="button"
            @click="newCountry"
          >
            {{ $t('button.save') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CountryInvestmentModal",
  data() {
    return {
      country: {},
      validations: null,
    }
  },
  methods: {
    newCountry() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/country/create', this.country)
        .then(() => {
          this.showToastr()
          this.$emit('fetchData')
          this.$refs.close.click()
        })
        .catch((error) => {
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
          } else {
            this.validations = null
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  }
}
</script>

<style scoped>

</style>
