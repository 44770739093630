<template>
  <router-view />
</template>

<script>
export default {
  name: "TeamCategory"
}
</script>

<style scoped>

</style>
