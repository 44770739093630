<template>
  <div class="col-md-12">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t('string.constructionInfo') }}
        </h3>
        <div class="box-tools">
          <template v-if="can([$Permissions.backend.provinceProject.edit, $Permissions.backend.project.edit])">
            <button
              class="btn btn-primary btn-sm margin-right-5"
              @click="$router.push({
                name: isProvinceDataLevel ? 'edit-province-project' : 'edit-project',
                params: {
                  project_uuid: $route.params.project_uuid,
                }
              })"
            >
              <i class="fa fa-edit" /> {{ $t('button.edit') }}
            </button>
          </template>
          <template v-if="can([$Permissions.backend.provinceProject.showHide])">
            <template v-if="isPublished">
              <button
                class="btn btn-warning btn-sm margin-right-5"
                @click="unpublishedProject(project.uuid)"
              >
                <i class="fas fa-eye-slash" />
              </button>
            </template>
            <template v-else>
              <button
                :title="$t('string.published')"
                class="btn btn-danger btn-sm margin-right-5"
                @click="publishedProject(project.uuid)"
              >
                <i class="fas fa-bullhorn" />
              </button>
            </template>
          </template>
          <asora-back-button />
        </div>
      </div>
      <div class="box-body">
        <table class="table table-bordered table-striped table-condensed">
          <tbody>
            <tr>
              <th style="width: 120px">
                {{ $t('label.projectType') }}
              </th>
              <td>
                <template v-if="project.project_type">
                  <b>{{ project.project_type['name_' + $i18n.locale] }}</b>
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th style="width: 120px">
                {{ $t('string.projectSubType') }}
              </th>
              <td>
                <template v-if="project.project_sub_type_id">
                  <b>{{ project.project_sub_type['name_' + $i18n.locale] }}</b>
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.constructionType') }}</th>
              <td>
                <template v-if="project.construction_type">
                  <b>{{ project.construction_type['name_' + $i18n.locale] }}</b>
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.projectName') }}</th>
              <td>
                <template v-if="project.name_km">
                  <b>{{ project['name_' + $i18n.locale] }}</b>
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.owners') }}</th>
              <td>
                <template v-if="project.owners && project.owners.length>0">
                  <template v-for="(owner, key) in project.owners">
                    <people-chip-item
                      :key="key"
                      :people="getPreparePeople(owner)"
                      model="people"
                    />
                  </template>
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.representedByCompany') }}</th>
              <td>
                <template v-if="project.company_representatives.length>0">
                  <people-chip-item
                    v-for="(company, key) in project.company_representatives"
                    :key="key"
                    :people="getPrepareCompany(company)"
                    model="company"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.isRentLand?') }}</th>
              <td>
                <symbol-check-box :checked="project.is_rent_land" />
              </td>
            </tr>
            <template v-if="project.is_rent_land">
              <tr>
                <th>{{ $t('label.ownerLandName') }}</th>
                <td>{{ project.owner_land_name }}</td>
              </tr>
              <tr>
                <th>{{ $t('label.ownerLandNationalIDCard') }}</th>
                <td>{{ project.owner_land_national_id_card || $t('string.na') }}</td>
              </tr>
              <tr>
                <th>{{ $t('label.ownerLandPhoneNumber') }}</th>
                <td>{{ project.owner_land_phone_number || $t('string.na') }}</td>
              </tr>
            </template>
            <tr>
              <th>{{ $t('label.createdAt') }}</th>
              <td>{{ getDate(project.created_at, false) }}</td>
            </tr>
            <tr>
              <th>{{ $t('label.updatedAt') }}</th>
              <td>{{ getDate(project.updated_at, false) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import SymbolCheckBox from "@/components/SymbolCheckBox"
import {integrationMixins} from "@/views/project/mixins/integration"
import {mapGetters} from "vuex"

export default {
  name: "ConstructionInfo",
  components: {SymbolCheckBox},
  mixins: [integrationMixins],
  props: {
    project: {
      type: Object,
    }
  },
  computed: {
    ...mapGetters('project', {
      isProvinceDataLevel: 'isProvinceDataLevel'
    })
  },
  mounted() {
    this.is_published = this.project.is_published
  }
}
</script>

<style scoped>

</style>
