import Layout from "../../views/administrations/user/Index"
import List from "../../views/administrations/user/List"
import Create from "../../views/administrations/user/Create"
import Show from "../../views/administrations/user/Show"
import Edit from "../../views/administrations/user/Edit"
import ResetPassword from "../../views/administrations/user/ResetPassword"
import Permissions from "../../permissions"

export const userRouters = {
  path: 'user',
  component: Layout,
  redirect: 'user',
  meta: {
    permissions: [Permissions.backend.administration.user.manage],
    groups: 'user',
  },
  children: [
    {
      path: '',
      name: 'list-user',
      component: List,
      meta: {
        permissions: [Permissions.backend.administration.user.list],
        groups: 'user',
        breadcrumb: [
          {
            name_en: 'Administration',
            name_km: 'រដ្ឋបាល'
          },
          {
            name_en: 'User List',
            name_km: 'បញ្ជីអ្នកប្រើប្រាស់'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-user',
      component: Create,
      meta: {
        permissions: [Permissions.backend.administration.user.store],
        groups: 'user',
        breadcrumb: [
          {
            name_en: 'Administration',
            name_km: 'រដ្ឋបាល'
          },
          {
            name_en: 'User List',
            name_km: 'បញ្ជីអ្នកប្រើប្រាស់',
            route: {name: 'list-user'}
          },
          {
            name_en: 'Create User',
            name_km: 'បន្ថែមអ្នកប្រើប្រាស់ថ្មី'
          }
        ]
      }
    },
    {
      path: 'edit/:uuid',
      name: 'edit-user',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.administration.user.edit],
        groups: 'user',
        breadcrumb: [
          {
            name_en: 'Administration',
            name_km: 'រដ្ឋបាល'
          },
          {
            name_en: 'User List',
            name_km: 'បញ្ជីអ្នកប្រើប្រាស់',
            route: {name: 'list-user'}
          },
          {
            name_en: 'Edit User',
            name_km: 'កែប្រែព័ត៌មានអ្នកប្រើប្រាស់'
          }
        ]
      }
    },
    {
      path: 'show/:uuid',
      name: 'show-user',
      component: Show,
      meta: {
        permissions: [Permissions.backend.administration.user.show],
        groups: 'user',
        breadcrumb: [
          {
            name_en: 'Administration',
            name_km: 'រដ្ឋបាល'
          },
          {
            name_en: 'User List',
            name_km: 'បញ្ជីអ្នកប្រើប្រាស់',
            route: {name: 'list-user'}
          },
          {
            name_en: 'Use Detail',
            name_km: 'បង្ហាញព័ត៌មានអ្នកប្រើប្រាស់'
          }
        ]
      }
    },
    {
      path: 'reset-password/:uuid',
      name: 'reset-password',
      component: ResetPassword,
      meta: {
        permissions: [Permissions.backend.administration.user.changePassword],
        groups: 'user',
        breadcrumb: [
          {
            name_en: 'Administration',
            name_km: 'រដ្ឋបាល'
          },
          {
            name_en: 'User List',
            name_km: 'បញ្ជីអ្នកប្រើប្រាស់',
            route: {name: 'list-user'}
          },
          {
            name_en: 'Reset User Password',
            name_km: 'កំណត់ពាក្យសម្ងាត់របស់អ្នកប្រើប្រាស់ឡើងវិញ'
          }
        ]
      }
    }
  ]
}
