<template>
  <div class="form-horizontal">
    <project-header-section :title="$t('string.constructionInfo')" />
    <form-section>
      <form-group>
        <form-input
          :column-validation="projectTypeValidation"
          :label="$t('label.projectType')"
        >
          <div class="input-group">
            <multiselect
              v-model="project.project_type"
              :deselect-label="$t('string.press_enter_to_remove')"
              :label="`name_${$i18n.locale}`"
              :options="projectTypes"
              :placeholder="$t('string.chooseProjectType')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @input="onChangedGetProjectSubTypes"
            />
            <button-addon-modal target="#projectTypeModal" />
          </div>
        </form-input>
        <form-input
          :column-validation="constructionTypeValidation"
          :label="$t('label.constructionType')"
        >
          <div class="input-group">
            <multiselect
              v-model="project.construction_type"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :options="constructionTypes"
              :placeholder="$t('string.chooseConstructionType')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getConstructionTypes"
            />
            <button-addon-modal target="#constructionTypeModal" />
          </div>
        </form-input>
      </form-group>
      <form-group>
        <form-input
          :label="$t('string.projectSubType')"
          label-class="col-sm-3 control-label"
        >
          <select
            v-model="project.project_sub_type_id"
            class="form-control"
          >
            <option
              :value="null"
              disabled
              selected
            >
              {{ $t('string.projectSubType') }}
            </option>
            <template v-if="projectSubTypes && Array.isArray(projectSubTypes) && projectSubTypes.length > 0">
              <option
                v-for="(projectSubType, key) in projectSubTypes"
                :key="key"
                :value="projectSubType.id"
              >
                {{ projectSubType['name_' + $i18n.locale] }}
              </option>
            </template>
          </select>
        </form-input>
      </form-group>
      <form-group>
        <form-input :label="$t('label.projectNameKm')">
          <input
            v-model="project.name_km"
            :placeholder="$t('label.projectNameKm')"
            class="form-control"
            type="text"
          >
        </form-input>
        <form-input :label="$t('label.projectNameEn')">
          <input
            v-model="project.name_en"
            :placeholder="$t('label.projectNameEn')"
            class="form-control"
            type="text"
          >
        </form-input>
      </form-group>
      <form-group>
        <form-input
          :column-validation="ownersValidation"
          :label="$t('label.owners')"
        >
          <div class="input-group">
            <multiselect
              v-model="project_owners"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="people"
              :placeholder="$t('string.chooseOwners')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getPeople"
            >
              <vue-multiple-select-option :array-objects="project_owners.length" />
            </multiselect>
            <button-addon-modal target="#peopleModal" />
          </div>
          <div class="col-md-12 margin-top-15">
            <template v-if="project_owners && project_owners.length>0">
              <div class="row">
                <template v-for="(project_owner, key) in project_owners">
                  <people-chip-item
                    :key="key"
                    :people="getPreparePeople(project_owner)"
                    model="people"
                  />
                </template>
              </div>
            </template>
          </div>
        </form-input>
        <form-input :label="$t('string.representative')">
          <div class="input-group">
            <multiselect
              v-model="project.company_representatives"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="companies"
              :placeholder="$t('string.chooseRepresentative')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getCompany"
            >
              <vue-multiple-select-option :array-objects="project.company_representatives" />
            </multiselect>
            <button-addon-modal
              :categories="[1]"
              target="#companyRepresentativeModal"
            />
          </div>
          <template v-if="project.company_representatives && project.company_representatives.length>0">
            <div class="row padding-top-15">
              <div class="col-md-12">
                <people-chip-item
                  v-for="(company, key) in project.company_representatives"
                  :key="key"
                  :people="getPrepareCompany(company)"
                  model="company"
                  @click="project.company_representatives.splice(project.company_representatives.indexOf(company), 1)"
                />
              </div>
            </div>
          </template>
        </form-input>
      </form-group>
      <form-group>
        <form-input :label="$t('label.rentLand')">
          <label class="margin-right-15 margin-top-5">
            <input
              v-model="project.is_rent_land"
              :value="true"
              name="rentLand"
              type="radio"
            > {{ $t('label.rent') }}
          </label>
          <label class="margin-top-5">
            <input
              v-model="project.is_rent_land"
              :value="false"
              name="rentLand"
              type="radio"
            > {{ $t('label.notRent') }}
          </label>
        </form-input>
      </form-group>
      <form-group v-if="project.is_rent_land">
        <form-input :label="$t('label.ownerLand')">
          <div class="input-group">
            <input
              v-model="project.owner_land_name"
              :placeholder="$t('label.ownerLandName')"
              class="form-control"
              type="text"
            >
            <span class="input-group-addon bg-gray" />
            <input
              v-model="project.owner_land_national_id_card"
              :placeholder="$t('label.ownerLandNationalIDCard')"
              class="form-control"
              type="text"
            >
          </div>
        </form-input>
        <form-input :label="$t('label.ownerLandPhoneNumber')">
          <input
            v-model="project.owner_land_phone_number"
            :placeholder="$t('label.ownerLandPhoneNumber')"
            class="form-control"
            type="tel"
          >
        </form-input>
      </form-group>
    </form-section>
    <project-header-section :title="$t('string.addressSection')" />
    <form-section>
      <form-group wrapper-class="col-xs-12">
        <form-input
          :label="$t('label.landUniqueNumber')"
          wrapper-class="col-sm-12"
        >
          <div class="row">
            <div class="col-md-12">
              <button
                class="btn btn-primary btn-sm"
                @click="landUniqueNumbers.push({
                  land_unique_number_type_id: 1,
                  land_unique_number: '',
                  land_unique_number_registration_date: '',
                  valid: false,
                })"
              >
                <i class="fa fa-plus-circle" /> {{ $t('string.add') }}
              </button>
            </div>
            <div class="col-md-12">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>{{ $t('string.type') }}</th>
                    <th>{{ $t('label.number') }}</th>
                    <th>{{ $t('label.landUniqueNumberRegistrationDate') }}</th>
                    <th style="width: 100px;">
                      {{ $t('table.action') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="landUniqueNumbers.length>0">
                    <tr
                      v-for="(landUniqueNumber, key) in landUniqueNumbers"
                      :key="key"
                    >
                      <td>
                        <select
                          v-model="landUniqueNumber.land_unique_number_type_id"
                          class="form-control"
                        >
                          <template
                            v-if="landUniqueNumberTypes && Array.isArray(landUniqueNumberTypes) && landUniqueNumberTypes.length >0"
                          >
                            <option
                              v-for="(item, landUniqueNumberTypeKey) in landUniqueNumberTypes"
                              :key="landUniqueNumberTypeKey"
                              :value="item.id"
                            >
                              {{ item['name_' + $i18n.locale] }}
                            </option>
                          </template>
                        </select>
                      </td>
                      <td>
                        <template v-if="landUniqueNumber.land_unique_number_type_id===1">
                          <the-mask
                            v-model="landUniqueNumber.land_unique_number"
                            :class="landUniqueNumber.valid?'':'border-red'"
                            :masked="true"
                            class="form-control"
                            mask="XXXXXXXX-XXXX"
                            placeholder="XXXXXXXX-XXXX"
                            type="text"
                            @input="isValidLandUniqueNumber(landUniqueNumber)"
                          />
                        </template>
                        <template v-else>
                          <input
                            v-model="landUniqueNumber.land_unique_number"
                            :placeholder="$t('label.landUniqueNumber')"
                            class="form-control"
                            type="text"
                            @input="isValidLandUniqueNumber(landUniqueNumber)"
                          >
                        </template>
                      </td>
                      <td>
                        <date-picker
                          v-model="landUniqueNumber.land_unique_number_registration_date"
                          :lang="datepickerLocale[$i18n.locale].lang"
                          :placeholder="$t('label.landUniqueNumberRegistrationDate')"
                          class="table-full-width"
                          format="DD/MM/YYYY"
                        />
                      </td>
                      <td>
                        <template v-if="landUniqueNumber.land_unique_number_type_id===1">
                          <button
                            class="btn btn-success btn-xs margin-r-5"
                            @click="getLocationByCode(landUniqueNumber)"
                          >
                            <i class="fa fa-map-marker" />
                          </button>
                        </template>
                        <button
                          class="btn btn-danger btn-xs"
                          @click="landUniqueNumbers.splice(landUniqueNumbers.indexOf(landUniqueNumber), 1)"
                        >
                          <i class="fa fa-trash-alt" />
                        </button>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="4">
                        <p class="text-center">
                          {{ $t('string.noRecord') }}
                        </p>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </form-input>
      </form-group>
      <form-group>
        <form-input :label="$t('label.addressInKhmer')">
          <input
            v-model="project.address1"
            :placeholder="$t('label.addressInKhmer')"
            class="form-control"
            type="text"
          >
        </form-input>
        <form-input :label="$t('label.addressInEnglish')">
          <input
            v-model="project.address2"
            :placeholder="$t('label.addressInEnglish')"
            class="form-control"
            type="text"
          >
        </form-input>
      </form-group>
      <div class="col-sm-10 col-sm-offset-2">
        <div class="row">
          <div class="col-sm-6">
            <div
              v-if="provinces.length>0"
              class="form-group row"
            >
              <label class="col-sm-3 control-label">
                {{ $t('label.province') }}
              </label>
              <div class="col-sm-9">
                <template v-if="isProvinceDataLevel">
                  <select
                    v-model="province_id"
                    :disabled="{'disabled' : current_province_id}"
                    class="form-control"
                    @change="getDistrict"
                  >
                    <option
                      v-for="(province, key) in provinces"
                      :key="key"
                      :value="province.id"
                    >
                      {{ province['name_' + $i18n.locale] }}
                    </option>
                  </select>
                </template>
                <template v-else>
                  <select
                    v-model="province_id"
                    class="form-control"
                    @change="getDistrict"
                  >
                    <option
                      v-for="(province, key) in provinces"
                      :key="key"
                      :value="province.id"
                    >
                      {{ province['name_' + $i18n.locale] }}
                    </option>
                  </select>
                </template>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-3 control-label">
                {{ $t('label.district') }}</label>
              <div class="col-sm-9">
                <select
                  v-model="district_id"
                  :disabled="!province_id"
                  class="form-control"
                  @change="getCommune"
                >
                  <option
                    v-for="(district, key) in districts"
                    :key="key"
                    :value="district.id"
                  >
                    {{ district['name_' + $i18n.locale] }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-10 col-sm-offset-2">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.commune') }}</label>
              <div class="col-sm-9">
                <select
                  v-model="commune_id"
                  :disabled="!district_id"
                  class="form-control"
                  @change="getVillage"
                >
                  <option
                    v-for="(commune, key) in communes"
                    :key="key"
                    :value="commune.id"
                  >
                    {{ commune['name_' + $i18n.locale] }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-3 control-label">
                {{ $t('label.village') }}
              </label>
              <div class="col-sm-9">
                <select
                  v-model="village_id"
                  :disabled="!commune_id"
                  class="form-control"
                >
                  <option
                    v-for="(village, key) in villages"
                    :key="key"
                    :value="village.id"
                  >
                    {{ village['name_' + $i18n.locale] }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-10 col-sm-offset-2">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-3 control-label">
                {{ $t('label.lat') }}
              </label>
              <div class="col-sm-9">
                <input
                  v-model="project.lat"
                  :placeholder="$t('label.lat')"
                  class="form-control"
                  type="number"
                >
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-3 control-label">
                {{ $t('label.lng') }}
              </label>
              <div class="col-sm-9">
                <input
                  v-model="project.lng"
                  :placeholder="$t('label.lng')"
                  class="form-control"
                  type="number"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-10 col-sm-offset-2">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-3 control-label">
                {{ $t('label.x') }}
              </label>
              <div class="col-sm-9">
                <input
                  v-model="project.x"
                  :placeholder="$t('label.x')"
                  class="form-control"
                  type="number"
                >
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-3 control-label">
                {{ $t('label.y') }}
              </label>
              <div class="col-sm-9">
                <input
                  v-model="project.y"
                  :placeholder="$t('label.y')"
                  class="form-control"
                  type="number"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-10 col-sm-offset-2">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.map') }}</label>
              <div class="col-sm-9">
                <div class="thumbnail no-margin-bottom">
                  <GmapMap
                    :center="{lat:project.lat, lng:project.lng}"
                    :zoom="15"
                    map-type-id="terrain"
                    style="width: 100%; height: 230px"
                  >
                    <GmapMarker
                      :clickable="true"
                      :draggable="true"
                      :icon="{url: '/img/markers/blue20x20.png'}"
                      :position="{lat:project.lat, lng:project.lng}"
                      @dragend="movePinOnGoogleMap"
                    />
                  </GmapMap>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form-section>
    <project-header-section :title="$t('string.architecturesSection')" />
    <form-section>
      <form-group>
        <form-input :label="$t('label.drawnByCompany')">
          <div class="input-group">
            <multiselect
              v-model="project.architectural_drawn_by_companies"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="companies"
              :placeholder="$t('string.chooseCompany')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getCompany"
            >
              <vue-multiple-select-option :array-objects="project.architectural_drawn_by_companies" />
            </multiselect>
            <button-addon-modal
              :categories="[2]"
              target="#companyModal"
            />
          </div>
          <template
            v-if="project.architectural_drawn_by_companies && project.architectural_drawn_by_companies.length>0"
          >
            <div class="row padding-top-15">
              <div class="col-md-12">
                <people-chip-item
                  v-for="(company, key) in project.architectural_drawn_by_companies"
                  :key="key"
                  :people="getPrepareCompany(company)"
                  model="company"
                />
              </div>
            </div>
          </template>
        </form-input>
        <form-input :label="$t('label.drawnBy')">
          <div class="input-group">
            <multiselect
              v-model="project.architectural_drawn_by_architects"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="architectures"
              :placeholder="$t('string.chooseArchitecture')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getArchitectures"
            >
              <vue-multiple-select-option :array-objects="project.architectural_drawn_by_architects" />
            </multiselect>
            <button-addon-modal
              :architecture-type-id="1"
              target="#architectureModal"
            />
          </div>
          <div class="col-md-12 margin-top-15">
            <template
              v-if="project.architectural_drawn_by_architects && project.architectural_drawn_by_architects.length>0"
            >
              <div class="row">
                <template v-for="(architecture, key) in project.architectural_drawn_by_architects">
                  <people-chip-item
                    :key="key"
                    :people="getPrepareArchitecture(architecture)"
                    model="architecture"
                  />
                </template>
              </div>
            </template>
          </div>
        </form-input>
      </form-group>
      <form-group>
        <form-input :label="$t('label.studyByCompany')">
          <div class="input-group">
            <multiselect
              v-model="project.architectural_studied_by_companies"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="companies"
              :placeholder="$t('string.chooseCompany')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getCompany"
            >
              <vue-multiple-select-option :array-objects="project.architectural_studied_by_companies" />
            </multiselect>
            <button-addon-modal
              :categories="[2]"
              target="#companyModal"
            />
          </div>
          <template
            v-if="project.architectural_studied_by_companies && project.architectural_studied_by_companies.length>0"
          >
            <div class="row padding-top-15">
              <div class="col-md-12">
                <people-chip-item
                  v-for="(company, key) in project.architectural_studied_by_companies"
                  :key="key"
                  :people="getPrepareCompany(company)"
                  model="company"
                />
              </div>
            </div>
          </template>
        </form-input>
        <form-input :label="$t('label.studiedBy')">
          <div class="input-group">
            <multiselect
              v-model="project.architectural_studied_by_architects"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="architectures"
              :placeholder="$t('string.chooseArchitecture')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getArchitectures"
            >
              <vue-multiple-select-option :array-objects="project.architectural_studied_by_architects" />
            </multiselect>
            <button-addon-modal
              :architecture-type-id="1"
              target="#architectureModal"
            />
          </div>
          <div class="col-md-12 margin-top-15">
            <template
              v-if="project.architectural_studied_by_architects && project.architectural_studied_by_architects.length>0"
            >
              <div class="row">
                <template v-for="(architecture, key) in project.architectural_studied_by_architects">
                  <people-chip-item
                    :key="key"
                    :people="getPrepareArchitecture(architecture)"
                    model="architecture"
                  />
                </template>
              </div>
            </template>
          </div>
        </form-input>
      </form-group>
      <form-group>
        <form-input :label="$t('label.confirmAndReviewedBy')">
          <div class="input-group">
            <multiselect
              v-model="project.architectural_confirmed_and_checked_by_companies"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="companies"
              :placeholder="$t('string.chooseCompany')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getCompany"
            >
              <vue-multiple-select-option :array-objects="project.architectural_confirmed_and_checked_by_companies" />
            </multiselect>
            <button-addon-modal
              :categories="[4]"
              target="#companyModal"
            />
          </div>
          <template
            v-if="project.architectural_confirmed_and_checked_by_companies && project.architectural_confirmed_and_checked_by_companies.length>0"
          >
            <div class="row padding-top-15">
              <div class="col-md-12">
                <people-chip-item
                  v-for="(company, key) in project.architectural_confirmed_and_checked_by_companies"
                  :key="key"
                  :people="getPrepareCompany(company)"
                  model="company"
                />
              </div>
            </div>
          </template>
        </form-input>
        <form-input :label="$t('label.confirmAndCheckBy')">
          <div class="input-group">
            <multiselect
              v-model="project.architectural_confirmed_and_checked_by_architects"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="architectures"
              :placeholder="$t('string.chooseArchitecture')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getArchitectures"
            >
              <vue-multiple-select-option :array-objects="project.architectural_confirmed_and_checked_by_architects" />
            </multiselect>
            <button-addon-modal
              :architecture-type-id="1"
              target="#architectureModal"
            />
          </div>
          <div class="col-md-12 margin-top-15">
            <template
              v-if="project.architectural_confirmed_and_checked_by_architects && project.architectural_confirmed_and_checked_by_architects.length>0"
            >
              <div class="row">
                <template v-for="(architecture, key) in project.architectural_confirmed_and_checked_by_architects">
                  <people-chip-item
                    :key="key"
                    :people="getPrepareArchitecture(architecture)"
                    model="architecture"
                  />
                </template>
              </div>
            </template>
          </div>
        </form-input>
      </form-group>
      <form-group>
        <form-input :label="$t('label.fireExtinguisherSystem')">
          <label class="margin-right-15 margin-top-5">
            <input
              v-model="project.architectural_has_fire_system"
              name="fireExtinguisherSystem"
              type="radio"
              value="have"
            > {{ $t('string.have') }}
          </label>
          <label class="margin-right-15 margin-top-5">
            <input
              v-model="project.architectural_has_fire_system"
              name="fireExtinguisherSystem"
              type="radio"
              value="does_not_have"
            > {{ $t('string.noHave') }}
          </label>
          <label class="margin-top-5">
            <input
              v-model="project.architectural_has_fire_system"
              name="fireExtinguisherSystem"
              type="radio"
              value="none_confirmed"
            > {{ $t('string.noneConfirmed') }}
          </label>
        </form-input>
      </form-group>
      <form-group>
        <form-input :label="$t('label.wastewaterTreatmentTank')">
          <label class="margin-right-15 margin-top-5">
            <input
              v-model="project.architectural_has_water_system"
              name="wastewaterTreatmentTank"
              type="radio"
              value="have"
            > {{ $t('string.have') }}
          </label>
          <label class="margin-right-15 margin-top-5">
            <input
              v-model="project.architectural_has_water_system"
              name="wastewaterTreatmentTank"
              type="radio"
              value="does_not_have"
            > {{ $t('string.noHave') }}
          </label>
          <label class="margin-top-5">
            <input
              v-model="project.architectural_has_water_system"
              name="wastewaterTreatmentTank"
              type="radio"
              value="none_confirmed"
            > {{ $t('string.noneConfirmed') }}
          </label>
        </form-input>
      </form-group>
      <form-group>
        <form-input :label="$t('label.disabilityGradient')">
          <label class="margin-right-15 margin-top-5">
            <input
              v-model="project.architectural_has_gradient_disability"
              name="disabilityGradient"
              type="radio"
              value="have"
            > {{ $t('string.have') }}
          </label>
          <label class="margin-right-15 margin-top-5">
            <input
              v-model="project.architectural_has_gradient_disability"
              name="disabilityGradient"
              type="radio"
              value="does_not_have"
            > {{ $t('string.noHave') }}
          </label>
          <label class="margin-top-5">
            <input
              v-model="project.architectural_has_gradient_disability"
              name="disabilityGradient"
              type="radio"
              value="none_confirmed"
            > {{ $t('string.noneConfirmed') }}
          </label>
        </form-input>
      </form-group>
    </form-section>
    <project-header-section :title="$t('string.unit')" />
    <div class="row">
      <div class="col-sm-10 col-sm-offset-2">
        <div class="row">
          <div class="col-sm-6">
            <div class="row">
              <div class="col-xs-12">
                <div class="form-group row">
                  <label class="col-sm-3 control-label" />
                  <div class="col-sm-9">
                    <div class="row">
                      <div class="col-md-4">
                        <b>{{ $t('label.permit') }}</b>
                      </div>
                      <div class="col-md-4">
                        <b>{{ $t('label.request') }}</b>
                      </div>
                      <div class="col-md-4">
                        <b>{{ $t('label.practical') }}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12">
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.numberParkingMotor') }}</label>
                  <div class="col-sm-9">
                    <div class="input-group">
                      <input
                        v-model="project.number_parking_motor_permit"
                        :placeholder="$t('label.permit')"
                        class="form-control"
                        type="number"
                      >
                      <span class="input-group-addon bg-gray" />
                      <input
                        v-model="project.number_parking_motor_request"
                        :placeholder="$t('label.request')"
                        class="form-control"
                        type="number"
                      >
                      <span class="input-group-addon bg-gray" />
                      <input
                        v-model="project.number_parking_motor_practical"
                        :placeholder="$t('label.practical')"
                        class="form-control"
                        type="number"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12">
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.numberParkingSmallCar') }}</label>
                  <div class="col-sm-9">
                    <div class="input-group">
                      <input
                        v-model="project.number_parking_small_car_permit"
                        :placeholder="$t('label.permit')"
                        class="form-control"
                        type="number"
                      >
                      <span class="input-group-addon bg-gray" />
                      <input
                        v-model="project.number_parking_small_car_request"
                        :placeholder="$t('label.request')"
                        class="form-control"
                        type="number"
                      >
                      <span class="input-group-addon bg-gray" />
                      <input
                        v-model="project.number_parking_small_car_practical"
                        :placeholder="$t('label.practical')"
                        class="form-control"
                        type="number"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12">
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.numberParkingBigCar') }}</label>
                  <div class="col-sm-9">
                    <div class="input-group">
                      <input
                        v-model="project.number_parking_big_car_permit"
                        :placeholder="$t('label.permit')"
                        class="form-control"
                        type="number"
                      >
                      <span class="input-group-addon bg-gray" />
                      <input
                        v-model="project.number_parking_big_car_request"
                        :placeholder="$t('label.request')"
                        class="form-control"
                        type="number"
                      >
                      <span class="input-group-addon bg-gray" />
                      <input
                        v-model="project.number_parking_big_car_practical"
                        :placeholder="$t('label.practical')"
                        class="form-control"
                        type="number"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="projectUnits.length>0"
                class="col-xs-12"
              >
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.unit') }}</label>
                  <div class="col-sm-9">
                    <div
                      v-for="(projectUnit, key) in projectUnits"
                      :key="key"
                      class="row"
                    >
                      <div class="col-md-12">
                        <div class="input-group margin-bottom-5">
                          <input
                            v-model="projectUnit.name_km"
                            class="form-control"
                            disabled
                            type="text"
                          >
                          <span class="input-group-addon bg-gray">=</span>
                          <input
                            v-model="projectUnit.value"
                            :placeholder="$t('string.exampleNumber')"
                            class="form-control"
                            type="number"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12">
                <div class="form-group row">
                  <label class="col-sm-3 control-label" />
                  <div class="col-sm-9">
                    <div class="row">
                      <div class="col-md-6 no-margin">
                        <b>{{ $t('label.request') }}</b>
                      </div>
                      <div class="col-md-6 no-margin">
                        <b>{{ $t('label.practical') }}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12">
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.nbBuilding') }}</label>
                  <div class="col-sm-9">
                    <div class="input-group">
                      <input
                        v-model="project.nb_building_request"
                        :placeholder="$t('label.request')"
                        class="form-control"
                        readonly
                        type="number"
                      >
                      <span class="input-group-addon bg-gray" />
                      <input
                        v-model="project.nb_building"
                        :placeholder="$t('label.practical')"
                        class="form-control"
                        type="number"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12">
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.nbFloor') }}</label>
                  <div class="col-sm-9">
                    <div class="input-group">
                      <input
                        v-model="project.nb_floor_request"
                        :placeholder="$t('label.request')"
                        class="form-control"
                        readonly
                        type="number"
                      >
                      <span class="input-group-addon bg-gray" />
                      <input
                        v-model="project.nb_floor"
                        :placeholder="$t('label.practical')"
                        class="form-control"
                        type="number"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12">
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.nbUndergroundFloor') }}</label>
                  <div class="col-sm-9">
                    <div class="input-group">
                      <input
                        v-model="project.nb_underground_floor_request"
                        :placeholder="$t('label.request')"
                        class="form-control"
                        readonly
                        type="number"
                      >
                      <span class="input-group-addon bg-gray" />
                      <input
                        v-model="project.nb_underground_floor"
                        :placeholder="$t('label.practical')"
                        class="form-control"
                        type="number"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12">
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.high') }}</label>
                  <div class="col-sm-9">
                    <div class="input-group">
                      <input
                        v-model="project.high"
                        :placeholder="$t('label.high')"
                        class="form-control"
                        readonly
                        type="number"
                      >
                      <span class="input-group-addon">m</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-sm-5 control-label">{{ $t('label.surface') }}</label>
                  <div class="col-sm-7">
                    <div class="input-group">
                      <input
                        v-model="project.surface"
                        :placeholder="$t('label.surface')"
                        class="form-control"
                        type="number"
                      >
                      <span class="input-group-addon">m<sup>2</sup></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-sm-5 control-label">{{ $t('label.surface_bottom_cell') }}</label>
                  <div class="col-sm-7">
                    <div class="input-group">
                      <input
                        v-model="project.surface_bottom_cell"
                        :placeholder="$t('label.surface_bottom_cell')"
                        class="form-control"
                        type="number"
                      >
                      <span class="input-group-addon">m<sup>2</sup></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-sm-5 control-label">{{ $t('label.constructionSurface') }}</label>
                  <div class="col-sm-7">
                    <div class="input-group">
                      <input
                        v-model="project.construction_surface"
                        :placeholder="$t('label.constructionSurface')"
                        class="form-control"
                        type="number"
                      >
                      <span class="input-group-addon">m<sup>2</sup></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-sm-5 control-label">{{ $t('label.constructionSurfaceCanProcessed') }}</label>
                  <div class="col-sm-7">
                    <div class="input-group">
                      <input
                        v-model="project.construction_surface_can_processed"
                        :placeholder="$t('label.constructionSurfaceCanProcessed')"
                        class="form-control"
                        type="number"
                      >
                      <span class="input-group-addon">m<sup>2</sup></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-sm-5 control-label">{{ $t('label.index') }}</label>
                  <div class="col-sm-7">
                    <input
                      :disabled="true"
                      :placeholder="$t('label.index')"
                      :value="getProjectPermitIndex"
                      class="form-control"
                      type="number"
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-sm-5 control-label">{{ $t('label.indexPractical') }}</label>
                  <div class="col-sm-7">
                    <input
                      v-model="project.index_practical"
                      :placeholder="$t('label.indexPractical')"
                      class="form-control"
                      type="number"
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-sm-5 control-label">{{ $t('label.indexOver') }}</label>
                  <div class="col-sm-7">
                    <input
                      :disabled="true"
                      :placeholder="$t('label.indexOver')"
                      :value="getProjectIndexOver"
                      class="form-control"
                      type="number"
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-sm-5 control-label">{{ $t('label.surface_index_over') }}</label>
                  <div class="col-sm-7">
                    <div class="input-group">
                      <input
                        :disabled="true"
                        :placeholder="$t('label.surface_index_over')"
                        :value="getSurfaceIndexOver"
                        class="form-control"
                        type="number"
                      >
                      <span class="input-group-addon">m<sup>2</sup></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-sm-5 control-label">{{ $t('label.surface_percentage_of_land_used') }}</label>
                  <div class="col-sm-7">
                    <div class="input-group">
                      <input
                        :disabled="true"
                        :placeholder="$t('label.surface_percentage_of_land_used_law')"
                        :value="getSurfacePercentageOfLandUseLaw"
                        class="form-control"
                        type="number"
                      >
                      <span class="input-group-addon">%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group row">
                  <label class="col-sm-5 control-label">{{ $t('label.surface_percentage_of_bottom') }}</label>
                  <div class="col-sm-7">
                    <div class="input-group">
                      <input
                        v-model="project.surface_percentage_of_land_used_practical"
                        :placeholder="$t('label.surface_percentage_of_bottom')"
                        class="form-control"
                        type="number"
                      >
                      <span class="input-group-addon">%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group row">
              <label class="col-xs-1 control-label" />
              <div class="col-xs-11 padding-left-70">
                <div class="row">
                  <div class="col-md-12 margin-bottom">
                    <project-building-form :project-uuid="$route.params.project_uuid" />
                  </div>
                  <!--<div class="col-md-12 margin-bottom">
                    <button class="btn btn-primary btn-sm"
                            @click="addNewBuilding">
                      <i class="fa fa-plus-circle"></i> {{ $t('string.addBuilding') }}
                    </button>
                  </div>
                  <div class="col-md-12">
                    <table class="table table-striped table-bordered">
                      <thead>
                      <tr>
                        <th style="width: 80px;">{{ $t('string.lr') }}</th>
                        <th>{{ $t('string.buildingName') }}</th>
                        <th style="width: 150px;">{{ $t('string.numberBuildings') }}</th>
                        <th style="width: 150px;">{{ $t('string.over_floor') }}</th>
                        <th style="width: 150px;">{{ $t('string.underground_floor') }}</th>
                        <th style="width: 200px;">{{ $t('string.high') }}</th>
                        <th>{{ $t('string.description') }}</th>
                        <th style="width: 100px;">{{ $t('table.action') }}</th>
                      </tr>
                      </thead>
                      <tbody>
                      <template v-if="projectBuildings.length>0">
                        <template v-for="(building, key) in projectBuildings">
                          <tr>
                            <td>
                              <input type="number"
                                     readonly
                                     :placeholder="$t('string.buildingNumber')"
                                     class="form-control"
                                     v-model="building.number"/>
                            </td>
                            <td>
                              <input type="text"
                                     :placeholder="$t('string.buildingName')"
                                     class="form-control"
                                     v-model="building.name"/>
                            </td>
                            <td>
                              <input type="number"
                                     :placeholder="$t('string.numberBuildings')"
                                     class="form-control"
                                     v-model="building.number_building"/>
                            </td>
                            <td>
                              <input type="number"
                                     :placeholder="$t('string.over_floor')"
                                     class="form-control"
                                     v-model="building.floor"/>
                            </td>
                            <td>
                              <input type="number"
                                     :placeholder="$t('string.underground_floor')"
                                     class="form-control"
                                     v-model="building.underground_floor"/>
                            </td>
                            <td>
                              <div class="input-group">
                                <input type="number"
                                       :placeholder="$t('string.buildingHigh')"
                                       class="form-control"
                                       v-model="building.high"/>
                                <span class="input-group-addon bg-gray">m</span>
                              </div>
                            </td>
                            <td>
                              <input :placeholder="$t('string.description')"
                                     type="text"
                                     class="form-control"
                                     v-model="building.description"/>
                            </td>
                            <td rowspan="2">
                              <button class="btn btn-danger btn-xs"
                                      @click="projectBuildings.splice(projectBuildings.indexOf(building), 1)">
                                <i class="fa fa-trash-alt"></i>
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" class="text-right text-middle">
                              <strong>{{ $t('string.practical') }}</strong>
                            </td>
                            <td>
                              <input type="number"
                                     :placeholder="$t('string.numberBuildings')"
                                     class="form-control"
                                     v-model="building.number_building_practical"/>
                            </td>
                            <td>
                              <input type="number"
                                     :placeholder="$t('string.over_floor')"
                                     class="form-control"
                                     v-model="building.floor_practical"/>
                            </td>
                            <td>
                              <input type="number"
                                     :placeholder="$t('string.underground_floor')"
                                     class="form-control"
                                     v-model="building.underground_floor_practical"/>
                            </td>
                            <td>
                              <div class="input-group">
                                <input type="number"
                                       :placeholder="$t('string.buildingHigh')"
                                       class="form-control"
                                       v-model="building.high_practical"/>
                                <span class="input-group-addon bg-gray">m</span>
                              </div>
                            </td>
                            <td>
                              <input :placeholder="$t('string.description')"
                                     type="text"
                                     class="form-control"
                                     v-model="building.description_practical"/>
                            </td>
                          </tr>
                        </template>
                      </template>
                      <template v-else>
                        <tr>
                          <td colspan="8">
                            <p class="text-center">{{ $t('string.noRecord') }}</p>
                          </td>
                        </tr>
                      </template>
                      </tbody>
                    </table>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <project-header-section :title="$t('string.regulationGapSection')" />
    <form-section>
      <div class="col-xs-10 col-xs-offset-2">
        <table class="table table-condensed table-striped">
          <tbody>
            <tr>
              <th />
              <th>{{ $t('string.direction') }}</th>
              <th>{{ $t('string.law') }}</th>
              <th>{{ $t('string.request') }}</th>
              <th>{{ $t('string.practical') }}</th>
            </tr>
            <tr
              v-for="(side, index) in regulationTypes"
              :key="index"
            >
              <td>{{ side.name }}</td>
              <td>
                <select
                  v-model="side.direction_id"
                  class="form-control"
                >
                  <option
                    disabled
                    selected
                    value="-1"
                  >
                    {{ $t('string.chooseDirection') }}
                  </option>
                  <option
                    v-for="(direction, key) in directions"
                    :key="key"
                    :value="direction.id"
                  >
                    {{ direction['name_' + $i18n.locale] }}
                  </option>
                </select>
              </td>
              <td>
                <div class="input-group">
                  <input
                    v-model="side.values.law"
                    class="form-control"
                    type="number"
                  >
                  <span class="input-group-addon bg-gray">m</span>
                </div>
              </td>
              <td>
                <div class="input-group">
                  <input
                    v-model="side.values.request"
                    class="form-control"
                    type="number"
                  >
                  <span class="input-group-addon bg-gray">m</span>
                </div>
              </td>
              <td>
                <div class="input-group">
                  <input
                    v-model="side.values.practical"
                    class="form-control"
                    type="number"
                  >
                  <span class="input-group-addon bg-gray">m</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </form-section>
    <project-header-section :title="$t('string.investmentSection')" />
    <form-section>
      <div class="col-sm-10 col-sm-offset-2">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-3 control-label">
                {{ $t('label.investmentCountry') }}
              </label>
              <div class="col-sm-9">
                <div class="input-group">
                  <multiselect
                    v-model="project.investment_countries"
                    :deselect-label="$t('string.press_enter_to_remove')"
                    :internal-search="false"
                    :label="`name_${$i18n.locale}`"
                    :multiple="true"
                    :options="countries"
                    :placeholder="$t('string.chooseInvestmentCountry')"
                    :select-label="$t('string.press_enter_to_select')"
                    track-by="id"
                    @search-change="getCountries"
                  >
                    <template slot="noOptions">
                      {{ $t('string.noOptions') }}
                    </template>
                    <template slot="noResult">
                      {{ $t('string.noResult') }}
                    </template>
                  </multiselect>
                  <span
                    class="input-group-addon button-addon-add"
                    data-target="#countryInvestmentModal"
                    data-toggle="modal"
                  >
                    <i class="fa fa-plus" /> {{ $t('string.add') }}
                  </span>
                </div>
                <country-investment-modal @fetchData="getCountries" />
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-3 control-label">
                {{ $t('label.investmentBudget') }}
              </label>
              <div class="col-sm-9">
                <div class="input-group">
                  <vue-numeric
                    v-model="project.investment_budget"
                    :max="99999999999999999999"
                    :min="0"
                    :placeholder="$t('label.investmentBudget')"
                    :precision="2"
                    class="form-control"
                    decimal-separator="."
                    separator=","
                  />
                  <span class="input-group-addon">{{ $t('currency.usd') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form-section>
    <project-header-section :title="$t('string.permit')" />
    <form-section>
      <div class="col-sm-11 col-sm-offset-1">
        <div class="form-group row">
          <label class="col-sm-3 control-label">
            {{ $t('string.permitSection') }}
          </label>
          <div class="col-sm-9">
            <div class="row">
              <div class="col-md-4">
                <input
                  v-model="project.permit_number"
                  :placeholder="$t('label.permitNumber')"
                  class="form-control"
                  type="text"
                >
              </div>
              <div class="col-md-8">
                <div class="form-group row">
                  <label class="col-md-3 control-label">
                    {{ $t('string.permitDate') }}
                  </label>
                  <label class="col-md-9">
                    <date-picker
                      v-model="project.permit_date"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('string.permitDate')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-11 col-sm-offset-1">
        <div class="form-group row">
          <label class="col-sm-3 control-label">
            {{ $t('string.openConstructionInfo') }}
          </label>
          <div class="col-sm-9">
            <button
              class="btn btn-primary btn-sm"
              @click="opens.push({number: '', date: '', phone: ''})"
            >
              <i class="fa fa-plus" /> {{ $t('string.add') }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-xs-11 col-xs-offset-1">
        <div class="form-group row">
          <div class="col-sm-offset-3 col-sm-9">
            <template v-if="opens.length>0">
              <table :class="permitTableClasses">
                <thead>
                  <tr>
                    <th>{{ $t('string.permitNumber') }}</th>
                    <th>{{ $t('string.permitDate') }}</th>
                    <!--<th>{{ $t('string.permitPhone') }}</th>-->
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(open, key) in opens"
                    :key="key"
                  >
                    <td>
                      <input
                        v-model="open.number"
                        :placeholder="$t('string.permitNumber')"
                        class="form-control"
                        type="text"
                      >
                    </td>
                    <td>
                      <date-picker
                        v-model="open.date"
                        :lang="datepickerLocale[$i18n.locale].lang"
                        :placeholder="$t('string.permitDate')"
                        class="table-full-width"
                        format="DD/MM/YYYY"
                      />
                    </td>
                    <!--<td>
                <input type="text"
                       class="form-control"
                       :placeholder="$t('string.permitPhone')"
                       v-model="open.phone">
              </td>-->
                    <td>
                      <button
                        class="btn btn-danger btn-xs"
                        @click="opens.splice(opens.indexOf(open), 1)"
                      >
                        <i class="fa fa-trash-alt" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </div>
        </div>
      </div>
      <div class="col-sm-11 col-sm-offset-1">
        <div class="form-group row">
          <label class="col-sm-3 control-label">
            {{ $t('string.stopConstructionInfo') }}
          </label>
          <div class="col-sm-9">
            <button
              class="btn btn-primary btn-sm"
              @click="stops.push({number: '', date: '', phone: '',})"
            >
              <i class="fa fa-plus" /> {{ $t('string.add') }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-xs-11 col-xs-offset-1">
        <div class="form-group row">
          <div class="col-sm-offset-3 col-sm-9">
            <template v-if="stops.length>0">
              <table :class="permitTableClasses">
                <thead>
                  <tr>
                    <th>{{ $t('string.permitNumber') }}</th>
                    <th>{{ $t('string.permitDate') }}</th>
                    <!--<th>{{ $t('string.permitPhone') }}</th>-->
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(stop, key) in stops"
                    :key="key"
                  >
                    <td>
                      <input
                        v-model="stop.number"
                        :placeholder="$t('string.permitNumber')"
                        class="form-control"
                        type="text"
                      >
                    </td>
                    <td>
                      <date-picker
                        v-model="stop.date"
                        :lang="datepickerLocale[$i18n.locale].lang"
                        :placeholder="$t('string.permitDate')"
                        class="table-full-width"
                        format="DD/MM/YYYY"
                      />
                    </td>
                    <!--<td>
                <input type="text"
                       :placeholder="$t('string.permitPhone')"
                       v-model="stop.phone"
                       class="form-control">
              </td>-->
                    <td>
                      <button
                        class="btn btn-danger btn-xs"
                        @click="stops.splice(stops.indexOf(stop), 1)"
                      >
                        <i class="fa fa-trash-alt" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </div>
        </div>
      </div>
      <div class="col-sm-11 col-sm-offset-1">
        <div class="form-group row">
          <label class="col-sm-3 control-label">
            {{ $t('string.pendingConstructionInfo') }}
          </label>
          <div class="col-sm-9">
            <button
              class="btn btn-primary btn-sm"
              @click="pending.push({number: '', date: '', phone: '',})"
            >
              <i class="fa fa-plus" /> {{ $t('string.add') }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-xs-11 col-xs-offset-1">
        <div class="form-group row">
          <div class="col-sm-offset-3 col-sm-9">
            <template v-if="pending.length>0">
              <table :class="permitTableClasses">
                <thead>
                  <tr>
                    <th>{{ $t('string.permitNumber') }}</th>
                    <th>{{ $t('string.permitDate') }}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, key) in pending"
                    :key="key"
                  >
                    <td>
                      <input
                        v-model="item.number"
                        :placeholder="$t('string.permitNumber')"
                        class="form-control"
                        type="text"
                      >
                    </td>
                    <td>
                      <date-picker
                        v-model="item.date"
                        :lang="datepickerLocale[$i18n.locale].lang"
                        :placeholder="$t('string.permitDate')"
                        class="table-full-width"
                        format="DD/MM/YYYY"
                      />
                    </td>
                    <td>
                      <button
                        class="btn btn-danger btn-xs"
                        @click="pending.splice(pending.indexOf(item), 1)"
                      >
                        <i class="fa fa-trash-alt" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </div>
        </div>
      </div>
      <div class="col-sm-11 col-sm-offset-1">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group row">
              <label class="col-sm-3 control-label">
                {{ $t('label.complaint') }}
              </label>
              <div class="col-sm-9">
                <button
                  class="btn btn-primary btn-sm"
                  @click="complaints.push({name: '', date: '', desc: '', solution: '',})"
                >
                  <i class="fa fa-plus" /> {{ $t('string.add') }}
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="complaints.length > 0"
            class="col-sm-12"
          >
            <div class="form-group row">
              <div class="col-sm-9 col-sm-offset-3">
                <table :class="permitTableClasses">
                  <thead>
                    <tr>
                      <th>{{ $t('string.complaintName') }}</th>
                      <th>{{ $t('string.complaintDate') }}</th>
                      <th>{{ $t('string.complaintPhone') }}</th>
                      <th>{{ $t('string.complaintDesc') }}</th>
                      <th>{{ $t('string.complaintSolution') }}</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(complaint, key) in complaints"
                      :key="key"
                    >
                      <td>
                        <input
                          v-model="complaint.name"
                          :placeholder="$t('string.complaintName')"
                          class="form-control"
                          type="text"
                        >
                      </td>
                      <td>
                        <date-picker
                          v-model="complaint.date"
                          :lang="datepickerLocale[$i18n.locale].lang"
                          :placeholder="$t('string.complaintDate')"
                          class="table-full-width"
                          format="DD/MM/YYYY"
                        />
                      </td>
                      <td>
                        <input
                          v-model="complaint.phone"
                          :placeholder="$t('string.complaintPhone')"
                          class="form-control"
                          type="text"
                        >
                      </td>
                      <td>
                        <textarea
                          v-model="complaint.desc"
                          :placeholder="$t('string.complaintDesc')"
                          class="form-control"
                          rows="1"
                        />
                      </td>
                      <td>
                        <textarea
                          v-model="complaint.solution"
                          :placeholder="$t('string.complaintSolution')"
                          class="form-control"
                          rows="1"
                        />
                      </td>
                      <td>
                        <button
                          class="btn btn-danger btn-xs"
                          @click="complaints.splice(complaints.indexOf(complaint), 1)"
                        >
                          <i class="fa fa-trash-alt" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-11 col-sm-offset-1">
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.isConstructedBeforeDate?') }}</label>
          <div class="col-sm-9">
            <label class="margin-right-15 margin-top-5">
              <input
                v-model="project.is_constructed_before_date"
                :value="false"
                type="radio"
              >
              {{ $t('string.noHave') }}
            </label>
            <label class="margin-top-5">
              <input
                v-model="project.is_constructed_before_date"
                :value="true"
                type="radio"
              >
              {{ $t('string.have') }}
            </label>
          </div>
        </div>
      </div>
      <div
        v-if="project.is_constructed_before_date"
        class="col-sm-11 col-sm-offset-1"
      >
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.description') }}</label>
          <div class="col-sm-9">
            <textarea
              v-model="project.is_constructed_before_date_description"
              :placeholder="$t('label.description')"
              class="form-control"
              rows="6"
            />
          </div>
        </div>
      </div>
    </form-section>
    <project-header-section :title="$t('string.engineersSection')" />
    <form-section>
      <form-group>
        <form-input :label="$t('string.constructionAccessoriesAbout')">
          <select
            v-model="project.construction_accessories_about_id"
            class="form-control"
          >
            <option
              disabled
              selected
            >
              {{ $t('string.constructionAccessoriesAbout') }}
            </option>
            <option
              v-for="(option, key) in constructionAccessoriesAbout"
              :key="key"
              :value="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </form-input>
      </form-group>
      <form-group>
        <form-input :label="$t('label.calculatedByCompany')">
          <div class="input-group">
            <multiselect
              v-model="engineering_calculated_by_companies"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="companies"
              :placeholder="$t('string.chooseCompany')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getCompany"
            >
              <vue-multiple-select-option :array-objects="engineering_calculated_by_companies" />
            </multiselect>
            <button-addon-modal
              :categories="[2]"
              target="#companyModal"
            />
          </div>
          <template v-if="engineering_calculated_by_companies.length>0">
            <div class="row padding-top-15">
              <div class="col-md-12">
                <people-chip-item
                  v-for="(company, key) in engineering_calculated_by_companies"
                  :key="key"
                  :people="getPrepareCompany(company)"
                  model="company"
                />
              </div>
            </div>
          </template>
        </form-input>
        <form-input :label="$t('label.calculatedBy')">
          <div class="input-group">
            <multiselect
              v-model="engineering_calculated_by_engineers"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="architectures"
              :placeholder="$t('string.chooseEngineer')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getArchitectures"
            >
              <vue-multiple-select-option :array-objects="engineering_calculated_by_engineers" />
            </multiselect>
            <button-addon-modal
              :architecture-type-id="2"
              target="#architectureModal"
            />
          </div>
          <div class="col-md-12 margin-top-15">
            <template v-if="engineering_calculated_by_engineers.length>0">
              <div class="row">
                <template v-for="(architecture, key) in engineering_calculated_by_engineers">
                  <people-chip-item
                    :key="key"
                    :people="getPrepareArchitecture(architecture)"
                    model="architecture"
                  />
                </template>
              </div>
            </template>
          </div>
        </form-input>
      </form-group>
      <form-group>
        <form-input :label="$t('label.confirmAndReviewedBy')">
          <div class="input-group">
            <multiselect
              v-model="engineering_reviewed_by_companies"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="companies"
              :placeholder="$t('string.chooseCompany')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getCompany"
            >
              <vue-multiple-select-option :array-objects="engineering_reviewed_by_companies" />
            </multiselect>
            <button-addon-modal
              :categories="[2]"
              target="#companyModal"
            />
          </div>
          <template v-if="engineering_reviewed_by_companies.length>0">
            <div class="row padding-top-15">
              <div class="col-md-12">
                <people-chip-item
                  v-for="(company, key) in engineering_reviewed_by_companies"
                  :key="key"
                  :people="getPrepareCompany(company)"
                  model="company"
                />
              </div>
            </div>
          </template>
        </form-input>
        <form-input :label="$t('label.reviewedBy')">
          <div class="input-group">
            <multiselect
              v-model="engineering_reviewed_by_engineers"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="architectures"
              :placeholder="$t('string.chooseEngineer')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getArchitectures"
            >
              <vue-multiple-select-option :array-objects="engineering_reviewed_by_engineers" />
            </multiselect>
            <button-addon-modal
              :architecture-type-id="2"
              target="#architectureModal"
            />
          </div>
          <div class="col-md-12 margin-top-15">
            <template v-if="engineering_reviewed_by_engineers.length>0">
              <div class="row">
                <template v-for="(architecture, key) in engineering_reviewed_by_engineers">
                  <people-chip-item
                    :key="key"
                    :people="getPrepareArchitecture(architecture)"
                    model="architecture"
                  />
                </template>
              </div>
            </template>
          </div>
        </form-input>
      </form-group>
      <form-group>
        <form-input :label="$t('label.agreedByCompany')">
          <div class="input-group">
            <multiselect
              v-model="engineering_agreed_by_companies"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="companies"
              :placeholder="$t('string.chooseCompany')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getCompany"
            >
              <vue-multiple-select-option :array-objects="engineering_agreed_by_companies" />
            </multiselect>
            <button-addon-modal
              :categories="[2]"
              target="#companyModal"
            />
          </div>
          <template v-if="engineering_agreed_by_companies.length>0">
            <div class="row padding-top-15">
              <div class="col-md-12">
                <people-chip-item
                  v-for="(company, key) in engineering_agreed_by_companies"
                  :key="key"
                  :people="getPrepareCompany(company)"
                  model="company"
                />
              </div>
            </div>
          </template>
        </form-input>
        <form-input :label="$t('label.agreedBy')">
          <div class="input-group">
            <multiselect
              v-model="engineering_agreed_by_engineers"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="architectures"
              :placeholder="$t('string.chooseEngineer')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getArchitectures"
            >
              <vue-multiple-select-option :array-objects="engineering_agreed_by_engineers" />
            </multiselect>
            <button-addon-modal
              :architecture-type-id="2"
              target="#architectureModal"
            />
          </div>
          <div class="col-md-12 margin-top-15">
            <template v-if="engineering_agreed_by_engineers.length>0">
              <div class="row">
                <template v-for="(architecture, key) in engineering_agreed_by_engineers">
                  <people-chip-item
                    :key="key"
                    :people="getPrepareArchitecture(architecture)"
                    model="architecture"
                  />
                </template>
              </div>
            </template>
          </div>
        </form-input>
      </form-group>
      <form-group>
        <form-input :label="$t('label.confirmAndReviewedByCompany')">
          <div class="input-group">
            <multiselect
              v-model="engineering_confirmed_and_checked_by_companies"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="companies"
              :placeholder="$t('string.chooseCompany')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getCompany"
            >
              <vue-multiple-select-option :array-objects="engineering_confirmed_and_checked_by_companies" />
            </multiselect>
            <button-addon-modal
              :categories="[2]"
              target="#companyModal"
            />
          </div>
          <template
            v-if="engineering_confirmed_and_checked_by_companies.length>0"
          >
            <div class="row padding-top-15">
              <div class="col-md-12">
                <people-chip-item
                  v-for="(company, key) in engineering_confirmed_and_checked_by_companies"
                  :key="key"
                  :people="getPrepareCompany(company)"
                  model="company"
                />
              </div>
            </div>
          </template>
        </form-input>
        <form-input :label="$t('label.confirmAndCheckBy')">
          <div class="input-group">
            <multiselect
              v-model="engineering_confirmed_and_checked_by_engineers"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="architectures"
              :placeholder="$t('string.chooseEngineer')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getArchitectures"
            >
              <vue-multiple-select-option :array-objects="engineering_confirmed_and_checked_by_engineers" />
            </multiselect>
            <button-addon-modal
              :architecture-type-id="2"
              target="#architectureModal"
            />
          </div>
          <div class="col-md-12 margin-top-15">
            <template
              v-if="engineering_confirmed_and_checked_by_engineers.length>0"
            >
              <div class="row">
                <template v-for="(architecture, key) in engineering_confirmed_and_checked_by_engineers">
                  <people-chip-item
                    :key="key"
                    :people="getPrepareArchitecture(architecture)"
                    model="architecture"
                  />
                </template>
              </div>
            </template>
          </div>
        </form-input>
      </form-group>
    </form-section>
    <project-header-section :title="$t('string.builderInformation')" />
    <form-section>
      <form-group>
        <form-input :label="$t('label.companies')">
          <div class="input-group">
            <multiselect
              v-model="project.project_companies"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="companies"
              :placeholder="$t('string.chooseCompanies')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getCompany"
            >
              <vue-multiple-select-option :array-objects="project.project_companies" />
            </multiselect>
            <button-addon-modal
              :categories="[3]"
              target="#companyModal"
            />
          </div>
        </form-input>
        <form-input :label="$t('label.technicianTeam')">
          <input
            v-model="project.technician_team"
            :placeholder="$t('label.technicianTeam')"
            class="form-control"
            type="text"
          >
        </form-input>
      </form-group>
      <form-group>
        <div class="row">
          <div
            v-if="project.project_companies && project.project_companies.length>0"
            class="col-md-12"
          >
            <div class="padding-15">
              <div class="form-group row">
                <div class="col-sm-11 col-sm-offset-1 padding-left-70">
                  <div class="row">
                    <div class="col-md-12">
                      <button
                        class="btn btn-primary btn-md margin-bottom"
                        @click="getOwnerCto"
                      >
                        <i class="fa fa-info" /> {{ $t('string.getMoreInfo') }}
                      </button>
                    </div>
                    <div
                      v-if="ownerCto && ownerCto.length>0"
                      class="col-md-12"
                    >
                      <table class="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>{{ $t('string.company') }}</th>
                            <th>{{ $t('string.owner') }}</th>
                            <th>{{ $t('string.cto') }}</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(company, index) in ownerCto"
                            :key="index"
                          >
                            <td>
                              <people-chip-item
                                :key="index"
                                :people="getPrepareCompany(company)"
                                model="company"
                              />
                            </td>
                            <td>
                              <template v-if="company.owners.length>0">
                                <people-chip-item
                                  v-for="(architecture, key) in company.owners"
                                  :key="key"
                                  :people="getPrepareArchitecture(architecture)"
                                  model="architecture"
                                />
                              </template>
                              <template v-else>
                                <p>{{ $t('string.na') }}</p>
                              </template>
                            </td>
                            <td>
                              <template v-if="company.ctos.length>0">
                                <people-chip-item
                                  v-for="(architecture, key) in company.ctos"
                                  :key="key"
                                  :people="getPrepareArchitecture(architecture)"
                                  model="architecture"
                                />
                              </template>
                              <template v-else>
                                <p>{{ $t('string.na') }}</p>
                              </template>
                            </td>
                            <td>
                              <button
                                class="btn btn-danger btn-xs"
                                @click="ownerCto.splice(ownerCto.indexOf(company), 1)"
                              >
                                <i class="fa fa-trash-alt" />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form-group>
      <form-group>
        <form-input :label="$t('label.standByEngineer')">
          <div class="input-group">
            <multiselect
              v-model="project.stand_by_engineers"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="architectures"
              :placeholder="$t('string.standByEngineer')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getArchitectures"
            >
              <vue-multiple-select-option :array-objects="project.stand_by_engineers" />
            </multiselect>
            <button-addon-modal
              :architecture-type-id="2"
              target="#architectureModal"
            />
          </div>
          <div class="col-md-12 margin-top-15">
            <template v-if="project.stand_by_engineers && project.stand_by_engineers.length>0">
              <div class="row">
                <template v-for="(architecture, key) in project.stand_by_engineers">
                  <people-chip-item
                    :key="key"
                    :people="getPrepareArchitecture(architecture)"
                    model="architecture"
                  />
                </template>
              </div>
            </template>
          </div>
        </form-input>
      </form-group>
      <form-group>
        <form-input :label="$t('label.nbWorkers')">
          <input
            v-model="project.nb_workers"
            :placeholder="$t('label.nbWorkers')"
            class="form-control"
            type="number"
          >
        </form-input>
        <form-input :label="$t('label.workerSalary')">
          <div class="input-group">
            <input
              v-model="project.worker_salary"
              :placeholder="$t('label.workerSalary')"
              class="form-control"
              type="number"
            >
            <span class="input-group-addon">{{ $t('currency.usd') }}</span>
          </div>
        </form-input>
      </form-group>
      <form-group>
        <form-input :label="$t('label.nbProfessional')">
          <input
            v-model="project.nb_professionals"
            :placeholder="$t('label.nbProfessional')"
            class="form-control"
            type="number"
          >
        </form-input>
        <form-input :label="$t('label.professionalSalary')">
          <div class="input-group">
            <input
              v-model="project.professional_salary"
              :placeholder="$t('label.professionalSalary')"
              class="form-control"
              type="number"
            >
            <span class="input-group-addon">{{ $t('currency.usd') }}</span>
          </div>
        </form-input>
      </form-group>
      <form-group>
        <form-input :label="$t('label.nbEngineer')">
          <input
            v-model="project.nb_engineers"
            :placeholder="$t('label.nbEngineer')"
            class="form-control"
            type="number"
          >
        </form-input>
        <form-input :label="$t('label.engineerSalary')">
          <div class="input-group">
            <input
              v-model="project.engineer_salary"
              :placeholder="$t('label.engineerSalary')"
              class="form-control"
              type="number"
            >
            <span class="input-group-addon">{{ $t('currency.usd') }}</span>
          </div>
        </form-input>
      </form-group>
      <form-group>
        <form-input :label="$t('label.nbArchitects')">
          <input
            v-model="project.nb_architects"
            :placeholder="$t('label.nbArchitects')"
            class="form-control"
            type="number"
          >
        </form-input>
        <form-input :label="$t('label.architectSalary')">
          <div class="input-group">
            <input
              v-model="project.architect_salary"
              :placeholder="$t('label.architectSalary')"
              class="form-control"
              type="number"
            >
            <span class="input-group-addon">{{ $t('currency.usd') }}</span>
          </div>
        </form-input>
      </form-group>
      <form-group>
        <form-input :label="$t('label.certificateOfInsurance')">
          <div class="input-group">
            <select
              v-model="project.insurance_company_id"
              class="form-control"
            >
              <template v-if="insuranceCompanies.length>0">
                <option
                  v-for="(insurance, key) in insuranceCompanies"
                  :key="key"
                  :value="insurance.id"
                >
                  {{ insurance['name_' + $i18n.locale] }}
                </option>
              </template>
            </select>
            <span class="input-group-addon bg-gray" />
            <input
              v-model="project.insurance_number"
              class="form-control"
              type="text"
            >
          </div>
        </form-input>
        <form-input :label="$t('string.insurance_date')">
          <date-picker
            v-model="project.insurance_date"
            :lang="datepickerLocale[$i18n.locale].lang"
            :placeholder="$t('string.insurance_date')"
            class="table-full-width"
            format="DD/MM/YYYY"
          />
        </form-input>
      </form-group>
      <form-group>
        <form-input :label="$t('label.constructionReviewedAndConfirmedByCompany')">
          <div class="input-group">
            <multiselect
              v-model="construction_confirmed_and_checked_by_companies"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="companies"
              :placeholder="$t('string.chooseCompany')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getCompany"
            >
              <vue-multiple-select-option
                :array-objects="construction_confirmed_and_checked_by_companies"
              />
            </multiselect>
            <button-addon-modal
              :categories="[5]"
              target="#companyModal"
            />
          </div>
          <template
            v-if="construction_confirmed_and_checked_by_companies
              && construction_confirmed_and_checked_by_companies.length>0"
          >
            <div class="row padding-top-15">
              <div class="col-md-12">
                <people-chip-item
                  v-for="(company, key) in construction_confirmed_and_checked_by_companies"
                  :key="key"
                  :people="getPrepareCompany(company)"
                  model="company"
                />
              </div>
            </div>
          </template>
        </form-input>
        <form-input :label="$t('label.constructionReviewedAndConfirmedByArchitecture')">
          <div class="input-group">
            <multiselect
              v-model="construction_confirmed_and_checked_by_architectures"
              :deselect-label="$t('string.press_enter_to_remove')"
              :internal-search="false"
              :label="`name_${$i18n.locale}`"
              :limit="1"
              :multiple="true"
              :options="architectures"
              :placeholder="$t('string.chooseArchitecture')"
              :select-label="$t('string.press_enter_to_select')"
              track-by="id"
              @search-change="getArchitectures"
            >
              <vue-multiple-select-option
                :array-objects="construction_confirmed_and_checked_by_architectures"
              />
            </multiselect>
            <button-addon-modal
              :architecture-type-id="2"
              target="#architectureModal"
            />
          </div>
          <div class="col-md-12 margin-top-15">
            <template
              v-if="construction_confirmed_and_checked_by_architectures
                && construction_confirmed_and_checked_by_architectures.length>0"
            >
              <div class="row">
                <template v-for="(architecture, key) in construction_confirmed_and_checked_by_architectures">
                  <people-chip-item
                    :key="key"
                    :people="getPrepareArchitecture(architecture)"
                    model="architecture"
                  />
                </template>
              </div>
            </template>
          </div>
        </form-input>
      </form-group>
    </form-section>
    <project-header-section :title="$t('string.otherSectorInformation')" />
    <form-section>
      <form-group>
        <div class="col-sm-6">
          <button
            class="btn btn-primary btn-sm margin-bottom"
            @click="ministry_permits.push({ministry_permit_or_license_id: '', number: '', date: '', approved_by: '', type_validate: true})"
          >
            <i class="fa fa-plus" /> {{ $t('string.add') }}
          </button>
        </div>
        <div class="col-sm-12">
          <table class="table table-condensed table-striped table-bordered">
            <thead>
              <tr>
                <th>{{ $t('string.ministry') }}</th>
                <th>{{ $t('string.number') }}</th>
                <th>{{ $t('string.date') }}</th>
                <th>{{ $t('string.approvedBy') }}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <template v-if="ministry_permits.length>0">
                <tr
                  v-for="(ministry_permit, key) in ministry_permits"
                  :key="key"
                >
                  <td>
                    <select
                      v-model="ministry_permit.ministry_permit_or_license_id"
                      :class="!ministry_permit.type_validate?'border-red': null"
                      class="form-control"
                    >
                      <option
                        v-for="(ministryPermitOrLicense, ministryPermitOrLicenseKey) in ministryPermitOrLicenses"
                        :key="ministryPermitOrLicenseKey"
                        :value="ministryPermitOrLicense.id"
                      >
                        {{ ministryPermitOrLicense['name_' + $i18n.locale] }}
                      </option>
                    </select>
                    <template v-if="!ministry_permit.type_validate">
                      <span class="label-error">
                        {{ $t('string.theFieldIsRequired') }}
                      </span>
                    </template>
                  </td>
                  <td>
                    <input
                      v-model="ministry_permit.number"
                      :placeholder="$t('string.number')"
                      class="form-control"
                      type="number"
                    >
                  </td>
                  <td>
                    <date-picker
                      v-model="ministry_permit.date"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.landUniqueNumberRegistrationDate')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                    />
                  </td>
                  <td>
                    <input
                      v-model="ministry_permit.approved_by"
                      :placeholder="$t('string.approvedBy')"
                      class="form-control"
                      type="text"
                    >
                  </td>
                  <td>
                    <button
                      class="btn btn-danger btn-xs"
                      @click="ministry_permits.splice(ministry_permits.indexOf(ministry_permit), 1)"
                    >
                      <i class="fas fa-trash-alt" />
                    </button>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td
                    class="text-center"
                    colspan="4"
                  >
                    {{ $t('string.noRecord') }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </form-group>
    </form-section>
    <form-section>
      <div class="col-sm-10 col-sm-offset-2">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <div class="col-sm-offset-3 col-sm-9">
                <button
                  class="btn btn-primary btn-sm margin-right-5"
                  @click="newProject"
                >
                  {{ $t('button.save') }}
                </button>
                <asora-back-button />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form-section>
    <people-modal @fetchData="getPeople" />
    <architecture-modal @fetchData="getArchitectures" />
    <company-modal @fetchData="getCompany" />
    <project-type-modal @fetchData="getProjectTypes" />
    <construction-type-modal @fetchConstructionType="onChangedGetProjectSubTypes" />
    <company-representative-modal @fetchData="getCompany" />
  </div>
</template>

<script>

import {debounce} from 'debounce'
import {projectMixin} from "./mixins"
import ProjectTypeModal from "./includes/modals/ProjectTypeModal"
import ConstructionTypeModal from "./includes/modals/ConstructionTypeModal"
import CountryInvestmentModal from "./includes/modals/CountryInvestmentModal"
import PeopleModal from "./includes/modals/PeopleModal"
import ArchitectureModal from "./includes/modals/ArchitectureModal"
import CompanyModal from "./includes/modals/CompanyModal"
import PeopleChipItem from "../../components/PeopleChipItem"
import VueMultipleSelectOption from "../../components/VueMultipleSelectOption"
import ProjectHeaderSection from "../../components/ProjectHeaderSection"
import FormSection from "../../components/ProjectFormSection"
import ButtonAddonModal from "../../components/ProjectButtonAddonModal"
import FormGroup from "../../components/ProjectFormGroup"
import FormInput from "../../components/ProjectFormInput"
import {optionsMixin} from "./mixins/options"
import CompanyRepresentativeModal from "./includes/modals/CompanyRepresentativeModal"
import {unitCalculationMixins} from "./mixins/unitCalculation"
import ProjectBuildingForm from "@/views/project/includes/components/ProjectBuildingForm"
import {mapGetters} from "vuex"

export default {
  name: "ProjectForm",
  components: {
    ProjectBuildingForm,
    CompanyRepresentativeModal,
    FormGroup,
    FormInput,
    ButtonAddonModal,
    FormSection,
    ProjectHeaderSection,
    VueMultipleSelectOption,
    PeopleChipItem,
    CompanyModal,
    ArchitectureModal,
    PeopleModal,
    CountryInvestmentModal,
    ConstructionTypeModal,
    ProjectTypeModal
  },
  mixins: [
    projectMixin,
    optionsMixin,
    unitCalculationMixins,
  ],
  props: {
    options: {
      type: Object,
      required: true
    },
    isProvinceDataLevel: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      validations: null,
      project: {
        project_type: null,
        construction_type: null,
        project_sub_type_id: null,
        name_km: null,
        name_en: null,
        company_representatives: [],
        lat: 11.576310,
        lng: 104.923104,
        is_rent_land: false,
        investment_budget: 0
      },

      constructionTypes: [],
      projectTypes: [],
      countries: [],
      people: [],
      companies: [],
      landUniqueNumberTypes: [],
      insuranceCompanies: [],

      params: new FormData(),
      architectures: [],
      dateFields: [
        'permit_date',
        'construction_open_date',
        'construction_stop_date',
        'construction_pending_date',
        'company_license_expired_at',
        'owner_requested_at',
        'released_at',
        'entry_created_at',
        'entry_released_at',
        'cadastre_created_at',
        'cadastre_released_at',
        'land_urbanization_created_at',
        'land_urbanization_released_at',
        'don_created_at',
        'don_released_at',
        'asn_created_at',
        'asn_released_at',
        'don_released_at',
        'land_unique_number_registration_date',
        'insurance_date',
      ],

      // owner cards
      owners: [],
      project_owners: []
    }
  },
  computed: {
    ...mapGetters('user', {
      current_province_id: 'current_province_id'
    })
  },
  created() {
    this.project.architectural_has_fire_system = 'none_confirmed'
    this.project.architectural_has_water_system = 'none_confirmed'
    this.project.architectural_has_gradient_disability = 'none_confirmed'

    this.getMinistryLicenses()
    this.getInsuranceCompanies()
    this.getLandUniqueNumberTypes()
    if (this.storage.architectures.length > 0) {
      this.architectures = this.storage.architectures
    } else {
      this.getArchitectures()
    }
    if (this.storage.people.length > 0) {
      this.people = this.storage.people
    } else {
      this.getPeople()
    }
    if (this.storage.companies.length > 0) {
      this.companies = this.storage.companies
    } else {
      this.getCompany()
    }
    if (this.storage.countries.length > 0) {
      this.countries = this.storage.countries
    } else {
      this.getCountries()
    }
    if (this.storage.constructionTypes.length > 0) {
      this.constructionTypes = this.storage.constructionTypes
    } else {
      this.getConstructionTypes()
    }
    if (this.storage.projectTypes.length > 0) {
      this.projectTypes = this.storage.projectTypes
    } else {
      this.getProjectTypes()
    }
  },
  mounted() {
    if (this.current_province_id) {
      this.province_id = this.current_province_id
    }
  },
  methods: {
    getLocationByCode(item) {
      let code = item.land_unique_number
      if (code === '' || code === null || code === undefined) {
        this.$toastr('error', 'Land unique number is required', 'Land Unique Number')
        return 0
      }
      code = code.split("-")
      if (code.length !== 2) {
        this.$toastr('error', 'Land unique number is invalid', 'Land Unique Number')
        return 0
      }
      code = code[0]
      if (!(code.length >= 7 && code.length <= 8)) {
        this.$toastr('error', 'Land unique number is invalid', 'Land Unique Number')
        return 0
      }
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project/get-location-by-code', {
        code: code,
      }).then(({data}) => {
        let location = data.data

        if (location !== null) {
          this.districts = location.districts
          this.communes = location.communes
          this.villages = location.villages

          this.province_id = location.province_id
          this.district_id = location.district_id
          this.commune_id = location.commune_id
          this.village_id = location.village_id
        } else {
          this.districts = []
          this.communes = []
          this.villages = []

          this.province_id = null
          this.district_id = null
          this.commune_id = null
          this.village_id = null

          this.$toastr('error', 'Land unique number does not match', 'Land unique number')
        }
      }).catch((error) => {
        this.$isLoading(false)
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    getLandUniqueNumberTypes() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project/get-land-unique-number-types')
        .then((res) => {
          this.landUniqueNumberTypes = res.data.data
        })
        .catch((error) => {
          this.onResponseError(error)
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    movePinOnGoogleMap(e) {
      let latLng = e.latLng
      this.project.lat = parseFloat(parseFloat(latLng.lat()).toFixed(6))
      this.project.lng = parseFloat(parseFloat(latLng.lng()).toFixed(6))
    },
    newProject() {
      if (!this.isValidLandUniqueNumber) {
        this.$toastr('warning', this.$t('string.theUniqueNumberIsInvalid'), this.$t('string.attention'))
        return 0
      }
      this.prepareParams()
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project/store', this.params)
        .then((res) => {
          if (res.data.data) {
            this.showToastr()
            // open construction letters
            let open_construction_letters = res.data.data.open_construction_numbers
            if (open_construction_letters !== null && open_construction_letters.length > 0) {
              this.opens = open_construction_letters.map((item) => {
                return {
                  number: item.number,
                  date: this.$moment(item.date).toDate(),
                  uuid: item.uuid,
                }
              })
            }

            // close construction letters
            let close_construction_letters = res.data.data.stop_construction_numbers
            if (close_construction_letters !== null && close_construction_letters.length > 0) {
              this.stops = close_construction_letters.map((item) => {
                return {
                  number: item.number,
                  date: this.$moment(item.date).toDate(),
                  uuid: item.uuid,
                }
              })
            }

            // eslint-disable-next-line no-prototype-builtins
            if (!this.$route.params.hasOwnProperty('project_uuid')) {
              if (this.can([this.$Permissions.backend.project.edit]) || this.can([this.$Permissions.backend.provinceProject.edit])) {
                this.$router.push({
                  name: this.isProvinceDataLevel ? 'edit-province-project' : 'edit-project',
                  params: {
                    project_uuid: res.data.data.uuid,
                  },
                })
              } else {
                this.$router.push({
                  name: this.isProvinceDataLevel ? 'list-province-project' : 'list-project'
                })
              }
            }
          } else {
            this.$router.push({
              name: this.isProvinceDataLevel ? 'list-province-project' : 'list-project'
            })
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
            this.validations = null
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    onChangedGetProjectSubTypes() {
      if (!(this.project.project_type && this.project.project_type.id)) {
        return 0
      }
      this.getProjectSubTypes(this.project.project_type.id)
    },
    getConstructionTypes: debounce(function (query_text = '') {
      // this.$store.commit('constructionType/setProjectTypeId', this.project.project_type.id)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/construction-type/get-option-by-project-type', {
        query_text: query_text,
      }).then(({data}) => {
        this.constructionTypes = data.data
        this.$store.commit('project/setConstructionTypes', this.constructionTypes)
      }).catch((error) => {
        this.onResponseError(error)
      })
    }, 200),
    getProjectTypes() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-type/get-project-types')
        .then((response) => {
          if (response.data.data) {
            this.projectTypes = response.data.data
          } else {
            this.projectTypes = []
          }
          this.$store.commit('project/setProjectTypes', this.projectTypes)
        })
        .catch((error) => {
          this.onResponseError(error)
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getPeople: debounce(function (query_text) {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/people/remote-option', {
        query_text: query_text
      })
        .then((response) => {
          if (response.data.data) {
            this.people = response.data.data
          } else {
            this.people = []
          }
          this.$store.commit('project/setPeople', this.people)
        })
        .catch((error) => {
          this.onResponseError(error)
        })
    }, 200),
    getCountries: debounce(function (query_text = null) {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/country/remote-countries', {
        query_text: query_text
      }).then((res) => {
        if (res.data.data) {
          this.countries = res.data.data
        } else {
          this.countries = []
        }
        this.$store.commit('project/setCountries', this.countries)
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-project'})
      })
    }, 200),
    getCompany: debounce(function (query_text) {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/company/remote-option', {
        query_text: query_text,
      }).then((res) => {
        if (res.data.data) {
          this.companies = res.data.data
        } else {
          this.companies = []
        }
        this.$store.commit('project/setCompanies', this.companies)
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-project'})
      })
    }, 200),
    getArchitectures: debounce(function (query_text) {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/architecture/remote-option', {
        query_text: query_text
      }).then((res) => {
        if (res.data.data) {
          this.architectures = res.data.data
        } else {
          this.architectures = []
        }
        this.$store.commit('project/setArchitectures', this.architectures)
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-project'})
      })
    }, 200),
    prepareParams() {
      // remove items before resend
      let str_prefix = [
        'complaints',
        'open_construction_numbers',
        'stop_construction_numbers',
        'pending_construction_numbers',
        'regulation_gaps',
        'ministry_permits',
        'construction_confirmed_and_checked_by_companies',
        'construction_confirmed_and_checked_by_architectures',
        'engineering_studied_by_companies',
        'engineering_studied_by_engineers',
        'engineering_drawn_by_companies',
        'engineering_drawn_by_engineers',
        'engineering_calculated_by_companies',
        'engineering_calculated_by_engineers',
        'engineering_reviewed_by_companies',
        'engineering_reviewed_by_engineers',
        'engineering_agreed_by_companies',
        'engineering_agreed_by_engineers',
        'engineering_confirmed_and_checked_by_companies',
        'engineering_confirmed_and_checked_by_engineers',
        'land_unique_numbers',
        'project_buildings',
      ]
      for (let input of this.params.entries()) {
        for (let name of str_prefix) {
          if (input[0].toString().includes(name)) {
            this.params.delete(input[0])
          }
        }
      }
      // engineering
      let dataEngineering = [
        {key: 'engineering_studied_by_companies', data: this.engineering_studied_by_companies},
        {key: 'engineering_studied_by_engineers', data: this.engineering_studied_by_engineers},
        {key: 'engineering_drawn_by_companies', data: this.engineering_drawn_by_companies},
        {key: 'engineering_drawn_by_engineers', data: this.engineering_drawn_by_engineers},
        {key: 'engineering_calculated_by_companies', data: this.engineering_calculated_by_companies},
        {key: 'engineering_calculated_by_engineers', data: this.engineering_calculated_by_engineers},
        {key: 'engineering_reviewed_by_companies', data: this.engineering_reviewed_by_companies},
        {key: 'engineering_reviewed_by_engineers', data: this.engineering_reviewed_by_engineers},
        {key: 'engineering_agreed_by_companies', data: this.engineering_agreed_by_companies},
        {key: 'engineering_agreed_by_engineers', data: this.engineering_agreed_by_engineers},
        {
          key: 'engineering_confirmed_and_checked_by_companies',
          data: this.engineering_confirmed_and_checked_by_companies
        },
        {
          key: 'engineering_confirmed_and_checked_by_engineers',
          data: this.engineering_confirmed_and_checked_by_engineers
        },
      ]
      dataEngineering.forEach((section) => {
        if (section.data.length > 0) {
          section.data.forEach((item, key) => {
            this.params.set(`${section.key}[${key}]`, item.id)
          })
        }
      })
      // construction section
      if (this.construction_confirmed_and_checked_by_companies.length > 0) {
        this.construction_confirmed_and_checked_by_companies.forEach((company, key) => {
          this.params.set(`construction_confirmed_and_checked_by_companies[${key}]`, company.id)
        })
      }
      if (this.construction_confirmed_and_checked_by_architectures.length > 0) {
        this.construction_confirmed_and_checked_by_architectures.forEach((architecture, key) => {
          this.params.set(`construction_confirmed_and_checked_by_architectures[${key}]`, architecture.id)
        })
      }
      // Ministry Permits
      this.ministry_permits = this.ministry_permits.map((ministry_permit) => {
        if (ministry_permit.ministry_permit_or_license_id === '') {
          ministry_permit.type_validate = false
        } else {
          ministry_permit.type_validate = true
        }
        return ministry_permit
      })
      if (this.ministry_permits.some(item => item.type_validate === false)) {
        return
      }
      this.ministry_permits.forEach((ministry_permit, key) => {
        this.params.set(`ministry_permits[${key}][ministry_permit_or_license_id]`, ministry_permit['ministry_permit_or_license_id'])
        if (ministry_permit['number'] !== '') {
          this.params.set(`ministry_permits[${key}][number]`, ministry_permit['number'])
        } else {
          this.params.delete(`ministry_permits[${key}][number]`)
        }
        if (this.$moment(ministry_permit['date']).isValid()) {
          this.params.set(`ministry_permits[${key}][date]`, this.$moment(ministry_permit['date']).locale('en').format('YYYY-MM-DD'))
        } else {
          this.params.delete(`ministry_permits[${key}][date]`)
        }
        if (ministry_permit['approved_by'] !== '') {
          this.params.set(`ministry_permits[${key}][approved_by]`, ministry_permit['approved_by'])
        } else {
          this.params.delete(`ministry_permits[${key}][approved_by]`)
        }
      })

      // regulation gaps
      this.params.set(`regulation_gaps[0][regulation_gap_type_en]`, 'Law')
      this.params.set(`regulation_gaps[0][regulation_gap_type_km]`, 'បញ្ញត្តិ')
      this.params.set(`regulation_gaps[1][regulation_gap_type_en]`, 'Request')
      this.params.set(`regulation_gaps[1][regulation_gap_type_km]`, 'ស្នើសុំ')
      this.params.set(`regulation_gaps[2][regulation_gap_type_en]`, 'Practical')
      this.params.set(`regulation_gaps[2][regulation_gap_type_km]`, 'ជាក់ស្តែង')
      let pattern = ['front', 'back', 'left', 'right']
      pattern.forEach((item, key) => {
        this.params.set(`regulation_gaps[0][direction_${item}_id]`, this.regulationTypes[key]['direction_id'])
        this.params.set(`regulation_gaps[1][direction_${item}_id]`, this.regulationTypes[key]['direction_id'])
        this.params.set(`regulation_gaps[2][direction_${item}_id]`, this.regulationTypes[key]['direction_id'])
      })
      this.regulationTypes.forEach((item, key) => {
        if (item.values.law !== '' && item.values.law !== null) {
          this.params.set(`regulation_gaps[0][${pattern[key]}]`, item.values.law)
        } else {
          this.params.set(`regulation_gaps[0][${pattern[key]}]`, '')
        }
        if (item.values.request !== '' && item.values.request !== null) {
          this.params.set(`regulation_gaps[1][${pattern[key]}]`, item.values.request)
        } else {
          this.params.set(`regulation_gaps[1][${pattern[key]}]`, '')
        }
        if (item.values.practical !== '' && item.values.practical !== null) {
          this.params.set(`regulation_gaps[2][${pattern[key]}]`, item.values.practical)
        } else {
          this.params.set(`regulation_gaps[2][${pattern[key]}]`, '')
        }
      })
      // is_province_data_level
      this.params.set('is_province_data_level', this.options.is_province_data_level)
      // address
      let address = {
        province_id: this.province_id,
        district_id: this.district_id,
        commune_id: this.commune_id,
        village_id: this.village_id,
      }
      for (let key in address) {
        if (address[key] !== '') {
          this.params.set(key, address[key])
        } else {
          this.params.delete(key)
        }
      }

      // project owners
      if (this.project_owners && this.project_owners.length > 0) {
        this.project.project_owners = this.project_owners
      }

      // open construction numbers
      this.params.delete('open_construction_numbers')
      if (this.opens.length > 0) {
        this.opens.forEach((item, key) => {
          if (this.$moment(item.date).isValid()) {
            this.params.set(`open_construction_numbers[${key}][date]`, this.$moment(item.date).locale('en').format('YYYY-MM-DD'))
          } else {
            this.params.delete(`open_construction_numbers[${key}][date]`)
          }
          if (item.number !== '' && item.number !== null) {
            this.params.set(`open_construction_numbers[${key}][number]`, item.number)
          } else {
            this.params.delete(`open_construction_numbers[${key}][number]`)
          }
          // eslint-disable-next-line no-prototype-builtins
          if (item.hasOwnProperty('uuid')) {
            this.params.set(`open_construction_numbers[${key}][uuid]`, item.uuid)
          } else {
            this.params.delete(`open_construction_numbers[${key}][uuid]`)
          }
        })
      }

      // complaints
      for (let input of this.params.entries()) {
        if (input[0].toString().includes('complaints')) {
          this.params.delete(input[0])
        }
      }

      // stop construction numbers
      this.params.delete('stop_construction_numbers')
      if (this.stops.length > 0) {
        this.stops.forEach((item, key) => {
          if (this.$moment(item.date).isValid()) {
            this.params.set(`stop_construction_numbers[${key}][date]`, this.$moment(item.date).locale('en').format('YYYY-MM-DD'))
          } else {
            this.params.delete(`stop_construction_numbers[${key}][date]`)
          }
          if (item.number !== '' && item.number !== null) {
            this.params.set(`stop_construction_numbers[${key}][number]`, item.number)
          } else {
            this.params.delete(`stop_construction_numbers[${key}][number]`)
          }
          // eslint-disable-next-line no-prototype-builtins
          if (item.hasOwnProperty('uuid')) {
            this.params.set(`stop_construction_numbers[${key}][uuid]`, item.uuid)
          } else {
            this.params.delete(`stop_construction_numbers[${key}][uuid]`)
          }
        })
      }

      // pending construction numbers
      if (this.pending.length > 0) {
        this.pending.forEach((item, key) => {
          if (this.$moment(item.date).isValid()) {
            this.params.set(`pending_construction_numbers[${key}][date]`, this.$moment(item.date).locale('en').format('YYYY-MM-DD'))
          } else {
            this.params.delete(`pending_construction_numbers[${key}][date]`)
          }
          if (item.number !== '' && item.number !== null) {
            this.params.set(`pending_construction_numbers[${key}][number]`, item.number)
          } else {
            this.params.delete(`pending_construction_numbers[${key}][number]`)
          }
        })
      }

      // complaints
      if (this.complaints.length > 0) {
        this.complaints.forEach((complaint, key) => {
          if (complaint.name !== '') {
            this.params.set(`complaints[${key}][name]`, complaint.name)
          }
          if (complaint.desc !== '') {
            this.params.set(`complaints[${key}][description]`, complaint.desc)
          }
          if (complaint.phone !== '') {
            this.params.set(`complaints[${key}][phone]`, complaint.phone)
          }
          if (this.$moment(complaint.date).isValid()) {
            this.params.set(`complaints[${key}][date]`, this.$moment(complaint.date).locale('en').format('YYYY-MM-DD'))
          }
          if (complaint.solution !== '') {
            this.params.set(`complaints[${key}][solution]`, complaint.solution)
          }
        })
      }

      // project unit
      if (this.projectUnits.length > 0) {
        this.projectUnits.forEach((unit, key) => {
          if (unit.value && parseFloat(unit.value) > 0) {
            this.params.set(`project_units[${key}][id]`, unit.id)
            this.params.set(`project_units[${key}][value]`, unit.value)
          } else {
            this.params.set(`project_units[${key}][id]`, unit.id)
          }
        })
      }

      // landUniqueNumbers
      if (this.landUniqueNumbers.length > 0) {
        this.landUniqueNumbers.forEach((landUniqueNumber, key) => {
          if (landUniqueNumber.land_unique_number_type_id !== '' &&
            landUniqueNumber.land_unique_number_type_id !== null) {
            this.params.set(
              `land_unique_numbers[${key}][land_unique_number_type_id]`,
              landUniqueNumber.land_unique_number_type_id
            )
            if (landUniqueNumber.land_unique_number !== '') {
              this.params.set(
                `land_unique_numbers[${key}][number]`,
                landUniqueNumber.land_unique_number
              )
            }
            if (
              landUniqueNumber.land_unique_number_registration_date !== '' &&
              this.$moment(landUniqueNumber.land_unique_number_registration_date).isValid()
            ) {
              this.params.set(
                `land_unique_numbers[${key}][date]`,
                this.$moment(landUniqueNumber.land_unique_number_registration_date)
                  .locale('en')
                  .format('YYYY-MM-DD')
              )
            }
          }
        })
      }

      this.params.set('is_province_data_level', this.isProvinceDataLevel)
      for (let key in this.project) {
        let value = this.project[key]
        if (value === null || value === '' || value === undefined) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.params.hasOwnProperty(key)) {
            this.params.delete(key)
          }
        } else {
          if (key === 'project_owners' && this.project[key].length > 0) {
            this.project[key].forEach((owner, index) => {
              this.params.set(`owners[${index}]`, owner.id)
            })
          } else if (key === 'project_companies' && this.project[key].length > 0) {
            this.project[key].forEach((company, index) => {
              this.params.set(`companies[${index}]`, company.id)
            })
          } else if (key === 'company_representatives' && this.project[key].length > 0) {
            this.project[key].forEach((company, index) => {
              this.params.set(`company_representatives[${index}]`, company.id)
            })
          } else if (key === 'stand_by_engineers' && this.project[key].length > 0) {
            this.project[key].forEach((architecture, index) => {
              this.params.set(`standBy[${index}]`, architecture.id)
            })
          } else if (key === 'architectural_drawn_by_companies' && this.project[key].length > 0) {
            this.project[key].forEach((company, index) => {
              this.params.set(`architectural_drawn_by_companies[${index}]`, company.id)
            })
          } else if (key === 'architectural_studied_by_companies' && this.project[key].length > 0) {
            this.project[key].forEach((company, index) => {
              this.params.set(`architectural_studied_by_companies[${index}]`, company.id)
            })
          } else if (key === 'architectural_confirmed_and_checked_by_companies' && this.project[key].length > 0) {
            this.project[key].forEach((company, index) => {
              this.params.set(`architectural_confirmed_and_checked_by_companies[${index}]`, company.id)
            })
          } else if (key === 'architectural_confirmed_and_checked_by_architects' && this.project[key].length > 0) {
            this.project[key].forEach((architect, index) => {
              this.params.set(`architectural_confirmed_and_checked_by_architects[${index}]`, architect.id)
            })
          } else if (key === 'architectural_studied_by_architects' && this.project[key].length > 0) {
            this.project[key].forEach((architect, index) => {
              this.params.set(`architectural_studied_by_architects[${index}]`, architect.id)
            })
          } else if (key === 'architectural_drawn_by_architects' && this.project[key].length > 0) {
            this.project[key].forEach((architect, index) => {
              this.params.set(`architectural_drawn_by_architects[${index}]`, architect.id)
            })
          } else if (key === 'project_type') {
            this.params.set('project_type_id', value.id)
          } else if (key === 'construction_type') {
            this.params.set('construction_type_id', value.id)
          } else if (key === 'investment_countries') {
            this.project.investment_countries.forEach((country, key) => {
              this.params.set(`investment_countries[${key}]`, country.id)
            })
          } else {
            if (this.project[key] !== null && this.dateFields.includes(key)) {
              if (this.$moment(value).local('en').isValid()) {
                value = this.$moment(value).local('en').format('YYYY-MM-DD')
              }
            }
            this.params.set(key, value)
          }
        }
      }
      if (this.project.project_type && this.project.project_type.id !== 1) {
        this.params.set('housing_type_id', '')
      }
    }
  }
}
</script>

<style scoped>

</style>
