<template>
  <div>
    <div class="wrapper">
      <asora-navbar />
      <asora-header />
      <div class="content-wrapper">
        <section class="content-header">
          <h1 class="main-title font-size-20">
            {{ $t('string.appName') }}
          </h1>
          <template v-if="$route.name === 'level-ministry'">
            <div class="btn-export-dashboard">
              <button
                class="btn btn-success btn-sm"
                @click="exportDashboard"
              >
                <i class="fa fa-download" /> {{ $t('string.exportToExcel') }}
              </button>
            </div>
          </template>
          <ol
            v-if="$route.meta.breadcrumb"
            class="breadcrumb"
          >
            <li>
              <router-link :to="{name: 'admin'}">
                <i class="fas fa-tachometer-alt" /> <span>{{ $t('menu.dashboard') }}</span>
              </router-link>
            </li>
            <li
              v-for="(step, index) in $route.meta.breadcrumb"
              :key="index"
              :class="{active: index === $route.meta.breadcrumb.length - 1}"
            >
              <template v-if="step.route">
                <router-link :to="step.route">
                  {{ getName(step) }}
                </router-link>
              </template>
              <template v-else>
                <span>{{ getName(step) }}</span>
              </template>
            </li>
          </ol>
        </section>
        <section class="content container-fluid">
          <vue-page-transition name="fade">
            <router-view />
          </vue-page-transition>
        </section>
      </div>
      <asora-footer />
    </div>
    <portal-target name="modal" />
  </div>
</template>

<script>
import AsoraNavbar from "../partials/AsoraNavbar"
import AsoraHeader from "../partials/AsoraHeader"
import AsoraFooter from "../partials/AsoraFooter"

export default {
  name: "Primary",
  components: {
    AsoraFooter,
    AsoraHeader,
    AsoraNavbar
  },
  data() {
    return {
      config: process.env
    }
  },
  methods: {
    exportDashboard () {
      window.open(process.env.VUE_APP_API + '/api/backend/report/export-dashboard', '_blank')
    },
  }
}
</script>

<style scoped>
  .breadcrumb span {
    font-family: var(--secondary-font);
  }

  .btn-export-dashboard {
    float: right;
    background: transparent;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    padding: 7px 5px;
    position: absolute;
    top: 3px;
    right: 10px;
    border-radius: 2px;
  }
</style>
