<template>
  <team-form
    :button="{
      label: $t('button.update'),
      btnClass: 'info',
      handle: 'edit'
    }"
    :title="$t('label.showTeam')"
    button-classes="col-sm-12"
    input-classes="col-sm-12"
    label-classes="col-sm-12"
  />
</template>

<script>
import TeamForm from "./Form"

export default {
  name: "Show",
  components: {TeamForm},
  metaInfo() {
    return {
      title: this.$t('label.showTeam'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      team: {}
    }
  },
  created() {
    this.getTeam()
  },
  methods: {
    getTeam() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/team/show', {
        team_uuid: this.$route.params.team_uuid,
      }).then((res) => {
        this.team = res.data.data
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>
.control-label {
  padding-top: 0 !important;
}

.column-officer-avatar {
  text-align: center;
  width: 15%;
}

.column-officer-avatar img.thumbnail {
  display: inline-table;
  width: 100%;
}

.table > tbody > tr > td {
  vertical-align: middle !important;
}

@media screen and (max-width: 1200px) {
  .column-officer-avatar {
    width: 25%;
  }
}
</style>
