import CarbonCopy from "./../../views/settings/carbonCopy/Index"
import List from "./../../views/settings/carbonCopy/DataTable"
import Create from "./../../views/settings/carbonCopy/Create"
import Edit from "./../../views/settings/carbonCopy/Edit"
import Show from "./../../views/settings/carbonCopy/Show"
import Permissions from "../../permissions"

export const carbonCopyRouters = {
  path: 'carbon-copy',
  component: CarbonCopy,
  redirect: 'carbon-copy',
  meta: {
    groups: 'carbon-copy',
    permissions: [Permissions.backend.setting.carbonCopy.manage]
  },
  children: [
    {
      path: '',
      name: 'list-carbon-copy',
      component: List,
      meta: {
        groups: 'carbon-copy',
        permissions: [Permissions.backend.setting.carbonCopy.list],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Carbon Copy',
            name_km: 'ការចម្លងជួន'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-carbon-copy',
      component: Create,
      meta: {
        groups: 'carbon-copy',
        permissions: [Permissions.backend.setting.carbonCopy.store],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Carbon Copy',
            name_km: 'ការចម្លងជួន'
          },
          {
            name_en: 'New',
            name_km: 'បង្កើតថ្មី'
          }
        ]
      }
    },
    {
      path: 'edit/:carbon_copy_uuid',
      name: 'edit-carbon-copy',
      component: Edit,
      meta: {
        groups: 'carbon-copy',
        permissions: [Permissions.backend.setting.carbonCopy.edit],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Carbon Copy',
            name_km: 'ការចម្លងជួន'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'show/:carbon_copy_uuid',
      name: 'show-carbon-copy',
      component: Show,
      meta: {
        groups: 'carbon-copy',
        permissions: [Permissions.backend.setting.carbonCopy.show],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Carbon Copy',
            name_km: 'ការចម្លងជួន'
          },
          {
            name_en: 'View',
            name_km: 'មើល'
          }
        ]
      }
    }
  ]
}
