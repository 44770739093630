import Country from "./../../views/settings/country/Index"
import List from "./../../views/settings/country/List"
import Create from "./../../views/settings/country/Create"
import Edit from "./../../views/settings/country/Edit"
import Show from "./../../views/settings/country/Show"
import Permissions from "../../permissions"

export const countryRouters = {
  path: 'country',
  component: Country,
  redirect: 'country',
  meta: {
    permissions: [Permissions.backend.setting.country.manage],
    groups: 'country',
  },
  children: [
    {
      path: '',
      name: 'list-country',
      component: List,
      meta: {
        permissions: [Permissions.backend.setting.country.list],
        groups: 'country',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Country',
            name_km: 'ប្រទេស'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-country',
      component: Create,
      meta: {
        permissions: [Permissions.backend.setting.country.store],
        groups: 'country',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'New Country',
            name_km: 'បន្ថែមប្រទេស'
          }
        ]
      }
    },
    {
      path: 'edit/:country_uuid',
      name: 'edit-country',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.setting.country.edit],
        groups: 'country',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Edit Country',
            name_km: 'កែប្រែប្រទេស'
          }
        ]
      }
    },
    {
      path: 'show/:country_uuid',
      name: 'show-country',
      component: Show,
      meta: {
        permissions: [Permissions.backend.setting.country.show],
        groups: 'country',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Show Country',
            name_km: 'បង្ហាញព័ត៏មានប្រទេស'
          }
        ]
      }
    }
  ]
}
