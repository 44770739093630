<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('label.showOffice') }}
      </h3>
      <div class="box-tools">
        <button
          v-if="can($Permissions.backend.office.edit)"
          class="btn btn-primary btn-sm margin-right-5"
          @click="$router.push({
            name: 'edit-office',
            params: {
              office_uuid: $route.params.office_uuid,
            }
          })"
        >
          <i class="fa fa-edit" /> {{ $t('button.edit') }}
        </button>
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <div class="form-horizontal">
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.nameKm') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ office.name_km }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.nameEn') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ office.name_en }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.code') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ office.code }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.headDepartment') }}</label>
          <div class="col-sm-9">
            <template v-if="office.head_department && office.head_department.length>0">
              <p
                v-for="(staff, key) in office.head_department"
                :key="key"
              >
                <template v-if="staff.phone">
                  <strong>{{ staff.name }} <a :href="`tel:${staff.phone}`">
                    ({{ staff.phone }})
                  </a></strong>
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </p>
            </template>
            <template v-else>
              {{ $t('string.na') }}
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.voicePresidentDepartment') }}</label>
          <div class="col-sm-9">
            <template v-if="office.voice_president_departments && office.voice_president_departments.length>0">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>{{ $t('string.name') }}</th>
                    <th>{{ $t('string.phone') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(staff, key) in office.voice_president_departments"
                    :key="key"
                  >
                    <td>{{ staff.name }}</td>
                    <td>{{ staff.phone ? staff.phone : $t('string.na') }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
            <template v-else>
              {{ $t('string.na') }}
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.headOffice') }}</label>
          <div class="col-sm-9">
            <template v-if="office.head_offices && office.head_offices.length>0">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>{{ $t('string.name') }}</th>
                    <th>{{ $t('string.phone') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(staff, key) in office.head_offices"
                    :key="key"
                  >
                    <td>{{ staff.name }}</td>
                    <td>{{ staff.phone ? staff.phone : $t('string.na') }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
            <template v-else>
              {{ $t('string.na') }}
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.homeNumber') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              :
              {{ office.home_number ? office.home_number : $t('string.na') }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.province') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              {{ office.province ? office.province['name_' + $i18n.locale] : $t('string.na') }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.district') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ office.district ? office.district['name_' + $i18n.locale] : $t('string.na') }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.commune') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ office.commune ? office.commune['name_' + $i18n.locale] : $t('string.na') }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.village') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ office.village ? office.village['name_' + $i18n.locale] : $t('string.na') }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.lat') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ office.lat ? office.lat : $t('string.na') }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.lng') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ office.lng ? office.lng : $t('string.na') }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.map') }}</label>
          <div class="col-sm-9">
            <GmapMap
              :center="{lat:office.lat, lng:office.lng}"
              :zoom="15"
              map-type-id="terrain"
              style="width: 100%; height: 430px"
            >
              <GmapMarker
                :icon="{url: '/img/markers/blue20x20.png'}"
                :position="{lat:office.lat, lng:office.lng}"
              />
            </GmapMap>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.createdAt') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ getDate(office.created_at, false) }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.updatedAt') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ getDate(office.updated_at, false) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Show",
  metaInfo: {
    title: process.env.VUE_APP_NAME,
    titleTemplate: 'Show Office | %s'
  },
  data() {
    return {
      office: {
        lat: 0,
        lng: 0,
      }
    }
  },
  created() {
    this.getOffice()
  },
  methods: {
    getOffice() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/office/show', {
        office_uuid: this.$route.params.office_uuid,
      }).then((res) => {
        this.office = res.data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-office'})
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>
.control-label {
  padding-top: 0 !important;
}
</style>
