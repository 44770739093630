import { render, staticRenderFns } from "./RoubavontbokkolsInformation.vue?vue&type=template&id=26bd54c7&scoped=true&"
import script from "./RoubavontbokkolsInformation.vue?vue&type=script&lang=js&"
export * from "./RoubavontbokkolsInformation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26bd54c7",
  null
  
)

export default component.exports