<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('label.constructionLetterCommand') }}
      </h3>
      <div class="box-tools">
        <button
          v-if="can([$Permissions.backend.setting.constructionLetterCommand.store])"
          class="btn btn-primary btn-sm"
          @click="$router.push({name: 'create-construction-letter-command'})"
        >
          <i class="fa fa-plus" /> {{ $t('button.add') }}
        </button>
      </div>
    </div>
    <div class="box-body">
      <div>
        <table
          id="construction-letter-command-table"
          class="table table-hover table-full-width table-striped table-bordered"
        >
          <thead>
            <tr>
              <th>{{ $t('string.command') }}</th>
              <th>{{ $t('table.type') }}</th>
              <th>{{ $t('table.status') }}</th>
              <th>{{ $t('table.createdAt') }}</th>
              <th>{{ $t('table.action') }}</th>
            </tr>
          </thead>
          <tbody />
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataTable',
  metaInfo() {
    return {
      title: this.$t('string.constructionLetterCommand'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      oTable: null
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.refreshTable()
      this.oTable.draw(true)
    }
  },
  mounted() {
    this.oTable = this.refreshTable()
    this.enabledAction()
  },
  beforeDestroy() {
    this.oTable.clear()
    this.oTable.destroy()
  },
  methods: {
    refreshTable() {
      let self = this
      return $('#construction-letter-command-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: process.env.VUE_APP_API + '/api/backend/construction-letter-command/datatable',
          data: (d) => {
            d.edit = this.$t('label.edit')
            d.show = this.$t('label.show')
            d.delete = this.$t('label.delete')
            d.enable = this.$t('label.enable')
            d.disable = this.$t('label.disable')
          },
          error: (xhr) => {
            self.onResponseError(xhr)
          }
        },
        columns: [
          {data: 'value', name: 'value', title: this.$t('string.command')},
          {
            data: 'type',
            name: 'type',
            title: this.$t('table.type'),
            render: (data) => {
              if (data === 'open_construction') {
                return this.$t('string.openConstruction')
              } else if (data === 'close_construction') {
                return this.$t('string.closeConstruction')
              } else if (data === 'pending_construction') {
                return this.$t('string.pendingConstruction')
              } else {
                return this.$t('string.na')
              }
            }
          },
          {
            data: 'enable',
            name: 'enable',
            title: this.$t('table.status'),
            render: (data) => {
              if (data) {
                return `<span class="label label-primary">${this.$t('label.enable')}</span>`
              }
              return `<span class="label label-warning">${this.$t('label.disable')}</span>`
            }
          },
          {
            data: 'created_at',
            name: 'created_at',
            orderable: true,
            searchable: false,
            title: this.$t('table.createdAt'),
            render: (data) => {
              return this.getDate(data)
            }
          },
          {
            data: 'action',
            name: 'action',
            orderable: false,
            searchable: false,
            title: this.$t('table.action'),
          }
        ],
        language: {
          url: self.getLocale,
        },
        order: [
          [2, 'desc']
        ]
      })
    },
    enabledAction() {
      let self = this
      this.clearEventHandler([
        '.btn-edit',
        '.btn-show',
        '.btn-trash',
      ])
      $(document).on('click', '.btn-edit', function () {
        self.$router.push({
          name: 'edit-construction-letter-command',
          params: {
            construction_letter_command_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-show', function () {
        self.$router.push({
          name: 'show-construction-letter-command',
          params: {
            construction_letter_command_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-trash', function () {
        self.deleteRecord($(this).attr('data-uuid'))
      })
    },
    deleteRecord(uuid) {
      let self = this
      this.$swal.fire({
        title: self.i18nSwalTitle,
        text: self.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: self.i18nSwalNo,
        confirmButtonText: self.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/construction-letter-command/delete', {
            construction_letter_command_uuid: uuid
          }).then(() => {
            this.showSwalSuccess()
            this.oTable.draw(true)
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
