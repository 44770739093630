<template>
  <company-form
    :title="$t('string.newConstructionCompany')"
    specialist-type="Construction"
    :company-category-ids="[3]"
    :button="buttonProperties"
  />
</template>

<script>
import CompanyForm from "../../company/Form"

export default {
  name: "Create",
  components: {CompanyForm},
  metaInfo() {
    return {
      title: this.$t('string.newConstructionCompany'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  computed: {
    buttonProperties() {
      return {
        label: this.$t('button.save'),
        btnClass: 'primary',
        handle: 'create'
      }
    }
  }
}
</script>

<style scoped>

</style>
