<template>
  <div
    id="constructionTypeModal"
    aria-labelledby="constructionTypeModalLabel"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
            @click="resetForm"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4
            id="constructionTypeModalLabel"
            class="modal-title"
          >
            {{ $t('string.newConstructionType') }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="col-md-12">
            <div
              :class="nameKmValidation.required?'has-error':''"
              class="form-group"
            >
              <label class="required">{{ $t('label.nameKm') }}</label>
              <input
                v-model="constructionType.name_km"
                :placeholder="$t('label.nameKm')"
                class="form-control"
                type="text"
              >
              <template v-if="nameKmValidation.required">
                <span class="label-error">{{ nameKmValidation.message }}</span>
              </template>
            </div>

            <div
              :class="nameEnValidation.required?'has-error':''"
              class="form-group"
            >
              <label class="required">{{ $t('label.nameEn') }}</label>
              <input
                v-model="constructionType.name_en"
                :placeholder="$t('label.nameEn')"
                class="form-control"
                type="text"
              >
              <template v-if="nameEnValidation.required">
                <span class="label-error">{{ nameEnValidation.message }}</span>
              </template>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            ref="close"
            class="btn btn-default"
            data-dismiss="modal"
            type="button"
            @click="resetForm"
          >
            {{ $t('button.close') }}
          </button>
          <button
            class="btn btn-primary"
            type="button"
            @click="store"
          >
            {{ $t('button.save') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: "ConstructionTypeModal",
  data() {
    return {
      constructionType: {
        project_type_id: null,
      },
      validations: null,
    }
  },
  watch: {
    project_type_id() {
      this.constructionType.project_type_id = this.project_type_id
    },
  },
  computed: {
    ...mapState({
      project_type_id: state => state.constructionType.data.project_type_id,
    }),
    nameKmValidation() {
      return this.getValidationColumn(this.validations, 'name_km')
    },
    nameEnValidation() {
      return this.getValidationColumn(this.validations, 'name_en')
    },
  },
  methods: {
    store() {
      this.validations = null
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/construction-type/create', this.constructionType)
        .then(() => {
          this.showToastr()
          this.$refs.close.click()
          this.constructionType = {
            project_type_id: this.project_type_id,
          }
          this.$emit('fetchConstructionType')
        })
        .catch((error) => {
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
          } else {
            this.validations = null
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    resetForm() {
      this.$refs.close.click()
      this.constructionType = {
        project_type_id: this.project_type_id,
      }
      this.validations = null
    }
  },
}
</script>

<style scoped>

</style>
