<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.newsDetails') }}
      </h3>
      <div class="box-tools">
        <template v-if="can([$Permissions.backend.news.edit])">
          <router-link
            :to="{
              name: 'edit-news',
              params: {
                news_id: $route.params.news_id
              }
            }"
            class="btn btn-primary btn-sm margin-r-5"
          >
            <i class="fa fa-edit" />
          </router-link>
        </template>
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <smart-iframe :src="`${baseUrl}/news/show/${$route.params.news_id}`" />
    </div>
  </div>
</template>

<script>
import SmartIframe from "@/components/SmartIframe"

export default {
  name: "Show",
  components: {SmartIframe},
  metaInfo() {
    return {
      title: this.$t('string.newsDetails'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  }
}
</script>

<style scoped>

</style>
