import Officer from "../views/officer/Index"
import List from "../views/officer/List"
import Permissions from "../permissions"
import Create from "../views/officer/Create"
import Show from "../views/officer/Show"
import Edit from "../views/officer/Edit"

export const officerRouters = {
  path: 'officer',
  name: 'officer',
  component: Officer,
  redirect: 'officer',
  meta: {
    permissions: [Permissions.backend.officer.manage],
    groups: 'officer',
  },
  children: [
    {
      path: '',
      name: 'list-officer',
      component: List,
      meta: {
        permissions: [Permissions.backend.officer.list],
        groups: 'officer',
        breadcrumb: [
          {
            name_en: 'Officer',
            name_km: 'មន្រ្តី'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-officer',
      component: Create,
      meta: {
        permissions: [Permissions.backend.officer.store],
        groups: 'officer',
        breadcrumb: [
          {
            name_en: 'Officer',
            name_km: 'មន្រ្តី'
          },
          {
            name_en: 'Create',
            name_km: 'បន្ថែម'
          }
        ]
      }
    },
    {
      path: 'show/:officer_uuid',
      name: 'show-officer',
      component: Show,
      meta: {
        permissions: [Permissions.backend.officer.show],
        groups: 'officer',
        breadcrumb: [
          {
            name_en: 'Officer',
            name_km: 'មន្រ្តី'
          },
          {
            name_en: 'Show',
            name_km: 'មើល'
          }
        ]
      }
    },
    {
      path: 'edit/:officer_uuid',
      name: 'edit-officer',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.officer.edit],
        groups: 'officer',
        breadcrumb: [
          {
            name_en: 'Officer',
            name_km: 'មន្រ្តី'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
  ]
}
