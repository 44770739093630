import Index from "@/views/companyManagement/notes/Index"
import List from "@/views/companyManagement/notes/List"
import Create from "@/views/companyManagement/notes/Create"
import Edit from "@/views/companyManagement/notes/Edit"
import Show from "@/views/companyManagement/notes/Show"
import Permissions from "@/permissions"

const note = Permissions.backend.companyManagement.note

const getMeta = (permissions, link) => {
  return {
    permissions,
    groups: 'notes-company',
    breadcrumb: [
      {
        name_en: 'The Certificate Company',
        name_km: 'វិញ្ញាបនប័ត្រក្រុមហ៊ុន'
      },
      link
    ]
  }
}

export const noteLicenseCompanyRouters = {
  path: 'notes',
  component: Index,
  redirect: 'notes',
  meta: {
    permissions: [note.manage],
    groups: 'notes-company',
  },
  children: [
    {
      path: '',
      name: 'list-notes-company',
      component: List,
      meta: getMeta([note.list], {
        name_en: 'List',
        name_km: 'បញ្ជី'
      })
    },
    {
      path: 'create',
      name: 'create-notes-company',
      component: Create,
      meta: getMeta([note.store], {
        name_en: 'Create',
        name_km: 'បង្កើត'
      })
    },
    {
      path: 'edit/:company_uuid',
      name: 'edit-notes-company',
      component: Edit,
      meta: getMeta([note.edit], {
        name_en: 'Create',
        name_km: 'បង្កើត'
      })
    },
    {
      path: 'show/:company_uuid',
      name: 'show-notes-company',
      component: Show,
      meta: getMeta([note.edit], {
        name_en: 'Show',
        name_km: 'មើល'
      })
    }
  ]
}
