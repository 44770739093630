import AnnouncementReference from "./../../views/settings/announcementReference/Index"
import List from "./../../views/settings/announcementReference/List"
import Create from "./../../views/settings/announcementReference/Create"
import Edit from "./../../views/settings/announcementReference/Edit"
import Show from "./../../views/settings/announcementReference/Show"
import Permissions from "../../permissions"

export const announcementReferenceRouters = {
  path: 'announcement-reference',
  component: AnnouncementReference,
  redirect: 'announcement-reference',
  meta: {
    groups: 'announcement-reference',
    permissions: [Permissions.backend.setting.announcementReference.manage]
  },
  children: [
    {
      path: '',
      name: 'list-announcement-reference',
      component: List,
      meta: {
        groups: 'announcement-reference',
        permissions: [Permissions.backend.setting.announcementReference.list],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Announcement Reference',
            name_km: 'ឯកសារយោង'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-announcement-reference',
      component: Create,
      meta: {
        groups: 'announcement-reference',
        permissions: [Permissions.backend.setting.announcementReference.store],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Announcement Reference',
            name_km: 'ឯកសារយោង'
          },
          {
            name_en: 'New',
            name_km: 'បង្កើតថ្មី'
          }
        ]
      }
    },
    {
      path: 'edit/:announcement_uuid',
      name: 'edit-announcement-reference',
      component: Edit,
      meta: {
        groups: 'announcement-reference',
        permissions: [Permissions.backend.setting.announcementReference.edit],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Announcement Reference',
            name_km: 'ឯកសារយោង'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'show/:announcement_uuid',
      name: 'show-announcement-reference',
      component: Show,
      meta: {
        groups: 'announcement-reference',
        permissions: [Permissions.backend.setting.announcementReference.show],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Announcement Reference',
            name_km: 'ឯកសារយោង'
          },
          {
            name_en: 'View',
            name_km: 'មើល'
          }
        ]
      }
    }
  ]
}
