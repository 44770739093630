import District from "./../../views/settings/district/Index"
import List from "./../../views/settings/district/List"
import Create from "../../views/settings/district/Create"
import Edit from "../../views/settings/district/Edit"
import Show from "../../views/settings/district/Show"
import Permissions from "../../permissions"

export const districtRouters = {
  path: 'district',
  component: District,
  redirect: 'district',
  meta: {
    groups: 'district',
    permissions: [Permissions.backend.setting.village.manage]
  },
  children: [
    {
      path: '',
      name: 'list-district',
      component: List,
      meta: {
        groups: 'district',
        permissions: [Permissions.backend.setting.district.list],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'District',
            name_km: 'ស្រុក/ខណ្ឌ'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-district',
      component: Create,
      meta: {
        groups: 'district',
        permissions: [Permissions.backend.setting.district.store],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'District',
            name_km: 'ខេត្ត/រាជធានី'
          },
          {
            name_en: 'Create',
            name_km: 'បង្កើត'
          }
        ]
      }
    },
    {
      path: 'edit/:district_id',
      name: 'edit-district',
      component: Edit,
      meta: {
        groups: 'district',
        permissions: [Permissions.backend.setting.district.edit],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'District',
            name_km: 'ស្រុក/ខណ្ឌ'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'show/:district_id',
      name: 'show-district',
      component: Show,
      meta: {
        groups: 'district',
        permissions: [Permissions.backend.setting.district.show],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'District',
            name_km: 'ស្រុក/ខណ្ឌ'
          },
          {
            name_en: 'Show',
            name_km: 'មើល'
          }
        ]
      }
    },
  ]
}
