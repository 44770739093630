<template>
  <div class="error-page">
    <h2 class="headline text-yellow">
      404
    </h2>
    <div class="error-content">
      <h3>
        <i class="fa fa-warning text-yellow" /> {{ $t('string.error_title') }}
      </h3>
      <p>
        We could not find the page you were looking for
      </p>
      <button
        class="btn btn-primary btn-sm"
        @click="goDashboard"
      >
        Go to Dashboard
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error",
  metaInfo() {
    return {
      title: '404',
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  }
}
</script>

<style scoped>
</style>
