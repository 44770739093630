<template>
  <div class="form-group row">
    <div class="col-sm-8 col-sm-offset-4">
      <div
        class="alert alert-default alert-dismissible"
        style="border: 1px solid #ddd;"
      >
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-hidden="true"
        >
          ×
        </button>
        <h4><i class="fa fa-info-circle" /> {{ $t('string.about_company') }}</h4>
        <ul>
          <li>{{ $t('field.label.company_section') }}: <strong>{{ company_section }}</strong></li>
          <li>{{ $t('field.label.country') }}: <strong>{{ country }}</strong></li>
        </ul>
      </div>

      <div>
        <p class="text-muted well well-sm no-shadow main-title">
          - {{ first_registration_number_km }} <br>
          - {{ first_registration_number_en }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutFRNumber",
  props: {
    first_registration_number_km: {
      type: String,
      default: null
    },
    first_registration_number_en: {
      type: String,
      default: null
    },
    company: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Object | null,
      default: null
    }
  },
  computed: {
    company_section() {
      if (this.company && this.company.company_section) {
        return this.company.company_section['name_' + this.$i18n.locale]
      }
      return null
    },
    country() {
      if (this.company && this.company.country) {
        return this.company.country['name_' + this.$i18n.locale]
      }
      return null
    }
  }
}
</script>

<style scoped>

</style>
