<template>
  <div
    id="companyModal"
    aria-labelledby="companyModalLabel"
    class="modal fade"
    data-backdrop="static"
    data-keyboard="false"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal-dialog modal-lg-custom"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
            @click="resetForm"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4
            id="companyModalLabel"
            class="modal-title"
          >
            {{ $t('string.newCompany') }}
          </h4>
        </div>
        <div class="modal-body modal-body-custom">
          <div class="row">
            <div class="col-sm-6 col-md-4">
              <div class="form-section">
                <div class="box-header with-border">
                  <h3 class="box-title">
                    {{ $t('string.aboutCompany') }}
                  </h3>
                </div>
                <div class="box-body">
                  <div
                    :class="nameKmRequired.required?'has-error': null"
                    class="form-group row"
                  >
                    <label class="col-sm-12 required">{{ $t('label.nameKm') }}</label>
                    <div class="col-sm-12">
                      <input
                        v-model="company.name_km"
                        :placeholder="$t('label.nameKm')"
                        class="form-control"
                        type="text"
                      >
                      <template v-if="nameKmRequired.required">
                        <span class="label-error">{{ nameKmRequired.message }}</span>
                      </template>
                    </div>
                  </div>
                  <div
                    :class="nameEnRequired.required?'has-error':null"
                    class="form-group row"
                  >
                    <label class="col-sm-12 required">{{ $t('label.nameEn') }}</label>
                    <div class="col-sm-12">
                      <input
                        v-model="company.name_en"
                        :placeholder="$t('label.nameEn')"
                        class="form-control"
                        type="text"
                      >
                      <template v-if="nameEnRequired.required">
                        <span class="label-error">{{ nameEnRequired.message }}</span>
                      </template>
                    </div>
                  </div>
                  <div
                    :class="ownerValidation.required?'has-error':null"
                    class="form-group row"
                  >
                    <label class="col-sm-12 required">{{ $t('label.companyOwner') }}</label>
                    <div class="col-sm-12">
                      <multiselect
                        v-model="company.owners"
                        :allow-empty="false"
                        :clear-on-select="false"
                        :hide-selected="true"
                        :internal-search="false"
                        :label="`name_${$i18n.locale}`"
                        :multiple="true"
                        :options="people"
                        :placeholder="$t('string.typingToFindOwner')"
                        :searchable="true"
                        :show-no-results="false"
                        track-by="id"
                        @search-change="searchOwners"
                      >
                        <template slot="noOptions">
                          {{ $t('string.noOptions') }}
                        </template>
                        <template slot="noResult">
                          {{ $t('string.noResult') }}
                        </template>
                      </multiselect>
                      <template v-if="ownerValidation.required">
                        <span class="label-error">{{ ownerValidation.message }}</span>
                      </template>
                    </div>
                  </div>
                  <div
                    :class="ceoValidation.required?'has-error':null"
                    class="form-group row"
                  >
                    <label class="col-sm-12">{{ $t('label.ceo') }}</label>
                    <div class="col-sm-12">
                      <multiselect
                        v-model="company.ceo"
                        :clear-on-select="false"
                        :internal-search="false"
                        :label="`name_${$i18n.locale}`"
                        :multiple="true"
                        :options="people"
                        :placeholder="$t('string.typingToFindCeo')"
                        :searchable="true"
                        track-by="id"
                        @search-change="searchOwners"
                      >
                        <template slot="noOptions">
                          {{ $t('string.noOptions') }}
                        </template>
                        <template slot="noResult">
                          {{ $t('string.noResult') }}
                        </template>
                      </multiselect>
                      <template v-if="ceoValidation.required">
                        <span class="label-error">{{ ceoValidation.message }}</span>
                      </template>
                    </div>
                  </div>
                  <div
                    :class="ctoValidation.required?'has-error':null"
                    class="form-group row"
                  >
                    <label class="col-sm-12">{{ $t('label.cto') }}</label>
                    <div class="col-sm-12">
                      <multiselect
                        v-model="company.cto"
                        :clear-on-select="false"
                        :internal-search="false"
                        :label="`name_${$i18n.locale}`"
                        :multiple="true"
                        :options="people"
                        :placeholder="$t('string.typingToFindCto')"
                        :searchable="true"
                        :tags="true"
                        track-by="id"
                        @search-change="searchOwners"
                      >
                        <template slot="noOptions">
                          {{ $t('string.noOptions') }}
                        </template>
                        <template slot="noResult">
                          {{ $t('string.noResult') }}
                        </template>
                      </multiselect>
                      <template v-if="ctoValidation">
                        <span class="label-error">{{ ctoValidation.message }}</span>
                      </template>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-12">{{ $t('label.firstRegistrationNumber') }}</label>
                    <div class="col-sm-12">
                      <input
                        v-model="company.first_registration_number"
                        :placeholder="$t('label.firstRegistrationNumber')"
                        class="form-control"
                        type="text"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-12">{{ $t('label.ministryRegistrationNumber') }}</label>
                    <div class="col-sm-12">
                      <input
                        v-model="company.ministry_registration_number"
                        :placeholder="$t('label.ministryRegistrationNumber')"
                        class="form-control"
                        type="text"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-12">{{ $t('label.issuedDate') }}</label>
                    <div class="col-sm-12">
                      <date-picker
                        v-model="company.issued_date"
                        :lang="datepickerLocale[$i18n.locale].lang"
                        :placeholder="$t('label.issuedDate')"
                        class="table-full-width"
                        format="DD/MM/YYYY"
                        type="date"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-12">{{ $t('label.effectiveDate') }}</label>
                    <div class="col-sm-12">
                      <date-picker
                        v-model="company.effective_date"
                        :lang="datepickerLocale[$i18n.locale].lang"
                        :placeholder="$t('label.effectiveDate')"
                        class="table-full-width"
                        format="DD/MM/YYYY"
                        type="date"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <div class="form-section">
                <div class="box-header with-border">
                  <h3 class="box-title">
                    {{ $t('string.additionalInformation') }}
                  </h3>
                </div>
                <div class="box-body">
                  <div
                    :class="companyTypeValidation.required?'has-error': null"
                    class="form-group row"
                  >
                    <label class="col-sm-12 required">{{ $t('label.companyType') }}</label>
                    <div class="col-sm-12">
                      <multiselect
                        v-model="company.company_type"
                        :clear-on-select="false"
                        :internal-search="false"
                        :label="`name_${$i18n.locale}`"
                        :options="companyTypes"
                        :placeholder="$t('string.chooseCompanyType')"
                        :searchable="true"
                        track-by="id"
                        @search-change="getCompanyTypes"
                      >
                        <template slot="noOptions">
                          {{ $t('string.noOptions') }}
                        </template>
                        <template slot="noResult">
                          {{ $t('string.noResult') }}
                        </template>
                      </multiselect>
                      <template v-if="companyTypeValidation.required">
                        <span class="label-error">{{ companyTypeValidation.message }}</span>
                      </template>
                    </div>
                  </div>
                  <div
                    :class="companySectionValidation.required?'has-error':null"
                    class="form-group row"
                  >
                    <label class="col-sm-12 required">{{ $t('label.companySection') }}</label>
                    <div class="col-sm-12">
                      <multiselect
                        v-model="company.company_section"
                        :clear-on-select="false"
                        :internal-search="false"
                        :label="`name_${$i18n.locale}`"
                        :options="companySections"
                        :placeholder="$t('string.chooseCompanySection')"
                        :searchable="true"
                        track-by="id"
                        @search-change="getCompanySections"
                      >
                        <template slot="noOptions">
                          {{ $t('string.noOptions') }}
                        </template>
                        <template slot="noResult">
                          {{ $t('string.noResult') }}
                        </template>
                      </multiselect>
                      <template v-if="companySectionValidation.required">
                        <span class="label-error">{{ companySectionValidation.message }}</span>
                      </template>
                    </div>
                  </div>
                  <div
                    :class="countryValidation.required?'has-error' : ''"
                    class="form-group row"
                  >
                    <label class="col-sm-12 required">{{ $t('label.country') }}</label>
                    <div class="col-sm-12">
                      <select
                        v-model="company.country_id"
                        class="form-control"
                      >
                        <option value="">
                          {{ $t('string.none') }}
                        </option>
                        <option
                          v-for="(country, key) in countries"
                          :key="key"
                          :value="country.id"
                        >
                          {{ country['name_' + $i18n.locale] }}
                        </option>
                      </select>
                      <template v-if="countryValidation.required">
                        <span class="label-error">{{ countryValidation.message }}</span>
                      </template>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-12">{{ $t('label.nationality') }}</label>
                    <div class="col-sm-12">
                      <select
                        v-model="company.nationality_id"
                        class="form-control"
                      >
                        <option value="">
                          {{ $t('string.none') }}
                        </option>
                        <option
                          v-for="(nationality, key) in nationalities"
                          :key="key"
                          :value="nationality.id"
                        >
                          {{ nationality['name_' + $i18n.locale] }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-12">{{ $t('label.phone') }}</label>
                    <div class="col-sm-12">
                      <input
                        v-model="company.phone"
                        :placeholder="$t('label.phone')"
                        class="form-control"
                        type="tel"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-12">{{ $t('label.email') }}</label>
                    <div class="col-sm-12">
                      <input
                        v-model="company.email"
                        :placeholder="$t('label.email')"
                        class="form-control"
                        type="email"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-12">{{ $t('label.website') }}</label>
                    <div class="col-sm-12">
                      <input
                        v-model="company.website"
                        :placeholder="$t('label.website')"
                        class="form-control"
                        type="url"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <label>{{ $t('label.description') }}</label>
                      <textarea
                        v-model="company.description"
                        :placeholder="$t('label.description')"
                        class="form-control"
                        rows="5"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-4">
              <div class="form-section">
                <div class="box-header with-border">
                  <h3 class="box-title">
                    {{ $t('string.aboutAddress') }}
                  </h3>
                </div>
                <div class="box-body">
                  <location-input
                    input-classes="col-sm-12"
                    label-classes="col-sm-12"
                  />
                  <div class="form-group row">
                    <label class="col-sm-12">{{ $t('label.lat') }}</label>
                    <div class="col-sm-12">
                      <input
                        v-model="company.lat"
                        :placeholder="$t('label.lat')"
                        class="form-control"
                        type="number"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-12">{{ $t('label.lng') }}</label>
                    <div class="col-sm-12">
                      <input
                        v-model="company.lng"
                        :placeholder="$t('label.lng')"
                        class="form-control"
                        type="number"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-sm-12">{{ $t('label.logo') }}</label>
                    <div class="col-sm-12">
                      <input
                        ref="logo"
                        name="logo"
                        type="file"
                      > <br>
                      <template v-if="company.logo">
                        <RenderImageTag
                          :src="company.logo ? `${baseUrl}/${company.logo}` : null"
                        />
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            ref="close"
            class="btn btn-default"
            data-dismiss="modal"
            type="button"
            @click="resetForm"
          >
            {{ $t('button.close') }}
          </button>
          <button
            class="btn btn-primary"
            type="button"
            @click="store"
          >
            {{ $t('button.save') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import {debounce} from "debounce"
import LocationInput from "@/components/LocationInput"
import {validationMixins} from "@/mixins/validations"
import RenderImageTag from "@/components/RenderImageTag"

export default {
  name: "CompanyModal",
  components: {RenderImageTag, LocationInput},
  mixins: [validationMixins],
  data() {
    return {
      company: {
        lat: 11.576310,
        lng: 104.923104
      },
      validations: null,
      params: new FormData(),
      companyTypes: [],
      companySections: [],
    }
  },
  computed: {
    ...mapState({
      address: state => state.location.address,
      countries: state => state.project.data.countries,
      nationalities: state => state.project.data.nationalities,
      people: state => state.project.data.people,
      storage: state => state.project.data,
      modal: state => state.company.modal,
    }),
  },
  methods: {
    searchOwners: debounce(function (query) {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/people/remote-option', {
        query_text: query
      }).then((res) => {
        if (res.data.data.length > 0) {
          this.$store.commit('project/setPeople', res.data.data)
        } else {
          this.$store.commit('project/setPeople', [])
        }
      }).catch((error) => {
        this.onResponseError(error)
      })
    }, 200),
    movePinOnGoogleMap(e) {
      let latLng = e.latLng
      this.company.lat = parseFloat(parseFloat(latLng.lat()).toFixed(6))
      this.company.lng = parseFloat(parseFloat(latLng.lng()).toFixed(6))
    },
    store() {
      this.$isLoading(true)
      this.prepareParams()
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/company/create', this.params)
        .then(() => {
          this.showToastr()
          this.$emit('fetchData')
          this.resetForm()
        })
        .catch((error) => {
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
            this.$store.commit('location/setValidations', res.data.errors)
          } else {
            this.$store.commit('location/setValidations', null)
            this.onResponseError(error)
            this.validations = null
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    prepareParams() {
      for (let key in this.company) {
        if (key === 'effective_date' || key === 'issued_date') {
          this.params.set(key, this.$moment(this.company[key]).locale('en').format('YYYY-MM-DD'))
        } else {
          let value = typeof (this.company[key]) === 'string' ? (this.company[key]).trim() : this.company[key]
          if (value === null || value === '') {
            this.params.delete(key)
          } else {
            if (key === 'owners' && value.length > 0) {
              value.forEach((item, i) => {
                this.params.set(`${key}[${i}]`, item.id)
              })
            } else if (key === 'ceo' && value.length > 0) {
              value.forEach((item, i) => {
                this.params.set(`${key}[${i}]`, item.id)
              })
            } else if (key === 'cto' && value.length > 0) {
              value.forEach((item, i) => {
                this.params.set(`${key}[${i}]`, item.id)
              })
            } else if (key === 'company_type') {
              this.params.set('company_type_id', value.id)
            } else if (key === 'company_section') {
              this.params.set('company_section_id', value.id)
            } else {
              this.params.set(key, value)
            }
          }
        }
      }

      // logo
      if (this.$refs.logo.files.length > 0) {
        this.params.set('logo', this.$refs.logo.files[0])
      } else {
        this.params.delete('logo')
      }

      // address
      for (let key in this.address) {
        if (this.address[key] !== '' && this.address[key] !== null) {
          this.params.set(key, this.address[key])
        }
      }

      // company categories
      if (this.modal.categories.length > 0) {
        this.modal.categories.forEach((id, key) => {
          this.params.set(`company_category_ids[${key}]`, id)
        })
      }
    },
    getCompanyTypes: debounce(function (query) {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/company-type/get-company-types', {
        query_text: query,
      }).then((res) => {
        if (res.data.data.length > 0) {
          this.companyTypes = res.data.data
        } else {
          this.companyTypes = []
        }
        this.$store.commit('project/setCompanyTypes', this.companyTypes)
      }).catch((error) => {
        this.onResponseError(error)
      })
    }, 200),
    getCompanySections: debounce(function (query) {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/company-section/get-company-sections', {
        query_text: query
      }).then((res) => {
        if (res.data.data.length > 0) {
          this.companySections = res.data.data
        } else {
          this.companySections = []
        }
        this.$store.commit('project/setCompanySections', this.companySections)
      }).catch((error) => {
        this.onResponseError(error)
      })
    }),
    resetForm() {
      this.validations = null
      this.$refs.close.click()
      for (let key of this.params.keys()) {
        this.params.delete(key)
      }
      this.company = {
        lat: 11.576310,
        lng: 104.923104
      }
    }
  },
  created() {
    if (this.storage.companySections.length > 0) {
      this.companySections = this.storage.companySections
    } else {
      this.getCompanySections()
    }
    if (this.storage.companyTypes.length > 0) {
      this.companyTypes = this.storage.companyTypes
    } else {
      this.getCompanyTypes()
    }
  }
}
</script>

<style scoped>

</style>
