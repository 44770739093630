import Roubavontbokkol from '@/views/roubavontbokkols/Index'
import {roubavontbokkolNoteRouters} from "@/routers/roubavontbokkol/note"
import {roubavontbokkolRegistrationRouters} from "@/routers/roubavontbokkol/roubavontbokkols"
import {roubavontbokkolDeikarbanhchounsRouters} from "@/routers/roubavontbokkol/deikarbanhchouns"
import Permissions from "@/permissions"

export const roubavontbokkolRouters = {
  path: 'roubavontbokkol',
  name: 'roubavontbokkol',
  component: Roubavontbokkol,
  meta: {
    permissions: [Permissions.backend.roubavontbokkol.manage],
    groups: 'roubavontbokkol',
  },
  children: [
    roubavontbokkolNoteRouters,
    roubavontbokkolRegistrationRouters,
    roubavontbokkolDeikarbanhchounsRouters,
  ]
}
