<template>
  <div class="col-md-12">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t('string.engineersSection') }}
        </h3>
      </div>
      <div class="box-body">
        <table class="table table-striped table-bordered table-condensed">
          <tbody>
            <tr>
              <th>{{ $t('string.constructionAccessoriesAbout') }}</th>
              <td>
                <template v-if="project.construction_ccessories_about_id!==null">
                  {{ getConstructionAccessoriesAboutLabel(project.construction_ccessories_about_id) }}
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.studyByCompany') }}</th>
              <td>
                <template v-if="project.engineering_studied_by_companies.length>0">
                  <people-chip-item
                    v-for="(company, key) in project.engineering_studied_by_companies"
                    :key="key"
                    :people="getPrepareCompany(company)"
                    model="company"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.studiedByEngineer') }}</th>
              <td>
                <template v-if="project.engineering_studied_by_engineers.length>0">
                  <people-chip-item
                    v-for="(engineer, key) in project.engineering_studied_by_engineers"
                    :key="key"
                    :people="getPrepareArchitecture(engineer)"
                    model="architecture"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.drawnByCompany') }}</th>
              <td>
                <template v-if="project.engineering_drawn_by_companies.length>0">
                  <people-chip-item
                    v-for="(company, key) in project.engineering_drawn_by_companies"
                    :key="key"
                    :people="getPrepareCompany(company)"
                    model="company"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.drawnByEngineer') }}</th>
              <td>
                <template v-if="project.engineering_drawn_by_engineers.length>0">
                  <people-chip-item
                    v-for="(engineer, key) in project.engineering_drawn_by_engineers"
                    :key="key"
                    :people="getPrepareArchitecture(engineer)"
                    model="architecture"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.calculatedByCompany') }}</th>
              <td>
                <template v-if="project.engineering_calculated_by_companies.length>0">
                  <people-chip-item
                    v-for="(company, key) in project.engineering_calculated_by_companies"
                    :key="key"
                    :people="getPrepareCompany(company)"
                    model="company"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.calculatedByEngineer') }}</th>
              <td>
                <template v-if="project.engineering_calculated_by_engineers.length>0">
                  <people-chip-item
                    v-for="(engineer, key) in project.engineering_calculated_by_engineers"
                    :key="key"
                    :people="getPrepareArchitecture(engineer)"
                    model="architecture"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.reviewedByCompany') }}</th>
              <td>
                <template v-if="project.engineering_reviewed_by_companies.length>0">
                  <people-chip-item
                    v-for="(company, key) in project.engineering_reviewed_by_companies"
                    :key="key"
                    :people="getPrepareCompany(company)"
                    model="company"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.reviewedByEngineer') }}</th>
              <td>
                <template v-if="project.engineering_reviewed_by_engineers.length>0">
                  <people-chip-item
                    v-for="(engineer, key) in project.engineering_reviewed_by_engineers"
                    :key="key"
                    :people="getPrepareArchitecture(engineer)"
                    model="architecture"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.agreedByCompany') }}</th>
              <td>
                <template v-if="project.engineering_agreed_by_companies.length>0">
                  <people-chip-item
                    v-for="(company, key) in project.engineering_agreed_by_companies"
                    :key="key"
                    :people="getPrepareCompany(company)"
                    model="company"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.agreedByEngineer') }}</th>
              <td>
                <template v-if="project.engineering_agreed_by_engineers.length>0">
                  <people-chip-item
                    v-for="(engineer, key) in project.engineering_agreed_by_engineers"
                    :key="key"
                    :people="getPrepareArchitecture(engineer)"
                    model="architecture"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.confirmedAndCheckedByCompany') }}</th>
              <td>
                <template v-if="project.engineering_confirmed_and_checked_by_companies.length>0">
                  <people-chip-item
                    v-for="(company, key) in project.engineering_confirmed_and_checked_by_companies"
                    :key="key"
                    :people="getPrepareCompany(company)"
                    model="company"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.confirmedAndCheckedByEngineer') }}</th>
              <td>
                <template v-if="project.engineering_confirmed_and_checked_by_engineers.length>0">
                  <people-chip-item
                    v-for="(engineer, key) in project.engineering_confirmed_and_checked_by_engineers"
                    :key="key"
                    :people="getPrepareArchitecture(engineer)"
                    model="architecture"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {optionsMixin} from "../../../mixins/options"

export default {
  name: "Engineering",
  mixins: [optionsMixin],
  props: {
    project: {
      type: Object,
    },
  }
}
</script>

<style scoped>

</style>
