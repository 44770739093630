<template>
  <aside class="main-sidebar">
    <section class="sidebar">
      <asora-navbar-user-panel />
      <ul
        class="sidebar-menu"
      >
        <li
          v-if="welcome"
          :class="$route.name === 'welcome'?'active':''"
        >
          <router-link :to="{name: 'welcome'}">
            <i class="fas fa-home" /> {{ $t('string.welcome') }}
          </router-link>
        </li>
        <template v-for="(menu, key) in menus">
          <template v-if="menu.route === null && menu.children === null">
            <template v-if="can(menu.permissions) && hasRoles(menu.roles)">
              <li
                :key="key"
                class="header"
              >
                {{ menu.label }}
              </li>
            </template>
          </template>
          <template v-else-if="menu.route !== null && menu.children === null">
            <template v-if="can(menu.permissions) && hasRoles(menu.roles)">
              <li
                :key="key"
                v-ripple
                :class="{active: $route.meta.groups === menu.route.groups}"
              >
                <router-link :to="menu.route">
                  <i :class="menu.icon" /> <span>{{ menu.label }}</span>
                </router-link>
              </li>
            </template>
          </template>
          <template v-else>
            <template v-if="can(menu.permissions) && hasRoles(menu.roles)">
              <tree-menu
                :key="key"
                :menu="menu"
              />
            </template>
          </template>
        </template>
      </ul>
    </section>
  </aside>
</template>

<script>
import AsoraNavbarUserPanel from "@/components/AsoraNavbarUserPanel"
import TreeMenu from "@/components/TreeMenu"

export default {
  name: "AsoraNavbar",
  components: {AsoraNavbarUserPanel, TreeMenu},
  data() {
    return {
      locale: this.$i18n.locale,
      appName: process.env.VUE_APP_NAME,
    }
  },
  computed: {
    welcome() {
      let permission = [
        this.$Permissions.backend.dashboard.summary.manage,
        this.$Permissions.backend.dashboard.ministryLevel.manage,
        this.$Permissions.backend.dashboard.provinceLevel.manage,
      ]
      return !this.can(permission)
    },
    menus() {
      return [
        {
          label: this.$t('menu.dashboard'),
          route: null,
          icon: null,
          children: null,
          permissions: [
            this.$Permissions.backend.dashboard.summary.manage,
            this.$Permissions.backend.dashboard.ministryLevel.manage,
            this.$Permissions.backend.dashboard.provinceLevel.manage,
          ]
        },
        {
          label: this.$t('menu.dashboard'),
          route: {name: 'admin', groups: 'summary'},
          icon: 'fa fa-home',
          children: [
            {
              label: this.$t('menu.ministryAuthority'),
              route: {name: 'level-ministry', groups: 'level-ministry'},
              permissions: [this.$Permissions.backend.dashboard.ministryLevel.manage]
            },
            {
              label: this.$t('menu.provinceAuthority'),
              route: {name: 'level-province', groups: 'level-province'},
              permissions: [this.$Permissions.backend.dashboard.provinceLevel.manage]
            }
          ],
          permissions: [
            this.$Permissions.backend.dashboard.summary.manage,
            this.$Permissions.backend.dashboard.ministryLevel.manage,
            this.$Permissions.backend.dashboard.provinceLevel.manage,
          ]
        },
        {
          label: this.$t('menu.lawRegulation'),
          route: {name: 'list-law-regulation', groups: 'law-regulation'},
          icon: 'fa fa-bookmark',
          children: null,
          permissions: [this.$Permissions.backend.lawRegulation.manage]
        },
        {
          label: this.$t('menu.news'),
          route: {name: 'list-news', groups: 'news'},
          icon: 'fa fa-newspaper-o',
          children: null,
          permissions: [this.$Permissions.backend.news.manage]
        },
        {
          label: this.$t('menu.project'),
          route: {name: 'list-project'},
          icon: 'fa fa-server',
          permissions: [this.$Permissions.backend.project.manage],
          children: [
            {
              label: this.$t('menu.table'),
              route: {name: 'list-project', groups: 'project'},
              permissions: [this.$Permissions.backend.project.list]
            },
            {
              label: this.$t('menu.map'),
              route: {name: 'map-project', groups: 'project-map'},
              permissions: [this.$Permissions.backend.project.map]
            },
          ]
        },
        {
          label: this.$t('string.occupancy'),
          route: null,
          icon: 'fa fa-certificate',
          permissions: [
            this.$Permissions.backend.certificate.manage,
            this.$Permissions.backend.certificateProvince.manage,
          ],
          children: [
            {
              label: this.$t('string.occupancyMinistryLevel'),
              route: {name: 'list-certificate-occupancy', groups: 'occupancy'},
              permissions: [this.$Permissions.backend.certificate.occupancy.manage]
            },
            {
              label: this.$t('string.occupancyProvinceLevel'),
              route: {name: 'list-certificate-occupancy-province', groups: 'certificates-occupancy-province'},
              permissions: [this.$Permissions.backend.certificateProvince.occupancy.manage]
            },
          ]
        },
        {
          label: this.$t('menu.mission'),
          route: {name: 'list-mission', groups: 'mission'},
          icon: 'fa fa-calendar-check',
          children: null,
          permissions: [this.$Permissions.backend.mission.manage]
        },
        {
          label: this.$t('menu.inspection'),
          route: null,
          icon: 'fa fa-tasks',
          permissions: [this.$Permissions.backend.inspection.manage],
          children: [
            {
              label: this.$t('menu.inspectionSubmit'),
              route: {name: 'list-inspection-submit', groups: 'inspection-submit'},
              permissions: [this.$Permissions.backend.inspection.submit.list]
            },
            {
              label: this.$t('menu.inspectionDraft'),
              route: {name: 'list-inspection-draft', groups: 'inspection-draft'},
              permissions: [this.$Permissions.backend.inspection.draft.list]
            },
          ],
        },
        {
          label: this.$t('menu.projectProvince'),
          route: {name: 'list-province-project'},
          icon: 'fa fa-server',
          permissions: [
            this.$Permissions.backend.provinceProject.manage,
            this.$Permissions.backend.provinceConstructionData.manage
          ],
          children: [
            {
              label: this.$t('menu.table'),
              route: {name: 'list-province-project', groups: 'province-project'},
              permissions: [this.$Permissions.backend.provinceProject.list]
            },
            {
              label: this.$t('menu.map'),
              route: {name: 'map-province-project', groups: 'map-province-project'},
              permissions: [this.$Permissions.backend.provinceProject.map]
            },
            {
              label: this.$t('menu.constructionProvinceData'),
              route: {name: 'list-construction-data', groups: 'province-construction-data'},
              permissions: [this.$Permissions.backend.provinceConstructionData.manage]
            },
          ]
        },
        {
          label: this.$t('menu.businessBuilder'),
          route: null,
          icon: 'fa fa-university',
          permissions: [this.$Permissions.backend.businessBuilders.manage],
          children: [
            {
              label: this.$t('menu.professionalStudyPlanner'),
              route: {name: 'list-professional-study-planner', groups: 'professional-study-planner'},
              permissions: [this.$Permissions.backend.businessBuilders.professionalStudyPlanner.list]
            },
            {
              label: this.$t('menu.study_plan_company_certifier_license'), // this.$t('menu.studyPlanCompany')
              route: {name: 'list-study-plan-company', groups: 'study-plan-company'},
              permissions: [this.$Permissions.backend.businessBuilders.studyPlanCompany.list]
            },
            {
              label: this.$t('menu.construction_company_certifier_license'), // this.$t('menu.constructionCompany')
              route: {name: 'list-construction-company', groups: 'construction-company'},
              permissions: [this.$Permissions.backend.businessBuilders.constructionCompany.list]
            },
            {
              // this.$t('menu.checkAndConfirmPlanCompany')
              // this.$t('menu.checkAndConfirmConstructionCompany')
              label: this.$t('menu.check_and_confirm_construction_certifier_company'),
              route: {
                name: 'list-check-and-confirm-construction-company',
                groups: 'check-and-confirm-construction-company'
              },
              permissions: [
                this.$Permissions.backend.businessBuilders.checkAndConfirmPlanCompany.list,
                this.$Permissions.backend.businessBuilders.checkAndConfirmConstructionCompany.list
              ]
            },
          ]
        },
        {
          label: this.$t('menu.noneBoard'),
          route: {name: null, groups: 'none-board'},
          icon: 'fas fa-user-times',
          permissions: [this.$Permissions.backend.noneBoard.manage],
          children: [
            {
              label: this.$t('menu.engineerList'),
              route: {name: 'list-none-board-engineer', groups: 'none-board-engineer'},
              permissions: [this.$Permissions.backend.noneBoard.engineer.list]
            },
            {
              label: this.$t('menu.architectureList'),
              route: {name: 'list-none-board-architecture', groups: 'none-board-architecture'},
              permissions: [this.$Permissions.backend.noneBoard.architecture.list]
            },
          ]
        },
        {
          label: this.$t('menu.hasBoard'),
          route: {name: null, groups: 'has-board'},
          icon: 'fas fa-user-tag',
          permissions: [this.$Permissions.backend.hasBoard.manage],
          children: [
            {
              label: this.$t('menu.engineerList'),
              route: {name: 'list-has-board-engineer', groups: 'has-board-engineer'},
              permissions: [this.$Permissions.backend.hasBoard.engineer.list]
            },
            {
              label: this.$t('menu.architectureList'),
              route: {name: 'list-has-board-architecture', groups: 'has-board-architecture'},
              permissions: [this.$Permissions.backend.hasBoard.architecture.list]
            },
          ]
        },
        {
          label: this.$t('menu.people'),
          route: null,
          icon: 'fa fa-users',
          permissions: [this.$Permissions.backend.investorConstruction.manage],
          children: [
            {
              label: this.$t('string.personality'),
              route: {name: 'list-personality', groups: 'personality'},
              permissions: [this.$Permissions.backend.investorConstruction.personality.list]
            },
            {
              label: this.$t('string.company'),
              route: {name: 'list-company', groups: 'company'},
              permissions: [this.$Permissions.backend.investorConstruction.company.list]
            },
          ]
        },
        {
          label: this.$t('menu.officer'),
          route: {name: 'list-officer', groups: 'officer'},
          icon: 'fas fa-house-user',
          children: null,
          permissions: [this.$Permissions.backend.officer.manage]
        },
        {
          label: this.$t('menu.team'),
          route: {name: 'list-team', groups: 'team'},
          icon: 'fas fa-people-carry',
          children: null,
          permissions: [this.$Permissions.backend.team.manage]
        },
        {
          label: this.$t('menu.constructionLetter'),
          route: null,
          icon: 'fa fa-file-text',
          permissions: [this.$Permissions.backend.constructionLetter.manage],
          children: [
            {
              label: this.$t('string.openConstructionLetter'),
              route: {name: 'list-open-construction-letter', groups: 'open-construction-letter'},
              permissions: [this.$Permissions.backend.constructionLetter.openConstructionLetter.manage]
            },
            {
              label: this.$t('string.closeConstructionLetter'),
              route: {name: 'list-close-construction-letter', groups: 'close-construction-letter'},
              permissions: [this.$Permissions.backend.constructionLetter.closeConstructionLetter.manage]
            },
          ]
        },
        {
          label: this.$t('menu.roubavontbokkols'),
          icon: 'fas fa-user-shield',
          route: null,
          permissions: [this.$Permissions.backend.roubavontbokkol.manage],
          children: [
            {
              label: this.$t('menu.registerRoubavontbokkol'),
              route: {name: 'list-roubavontbokkols', groups: 'registration-roubavontbokkols'},
              permissions: [this.$Permissions.backend.roubavontbokkol.roubavontbokkolRegistration.manage]
            },
            {
              label: this.$t('menu.notesRoubavontbokkol'),
              route: {name: 'list-note-roubavontbokkols', groups: 'note-roubavontbokkols'},
              permissions: [this.$Permissions.backend.roubavontbokkol.note.manage]
            },
            {
              label: this.$t('menu.deikarbanhchounsRoubavontbokkol'),
              route: {name: 'list-deikarbanhchouns-roubavontbokkols', groups: 'deikarbanhchouns-roubavontbokkols'},
              permissions: [this.$Permissions.backend.roubavontbokkol.deikarbanhchoun.manage]
            },
          ]
        },
        {
          label: this.$t('menu.companyRegistrations'),
          route: null,
          icon: 'fa fa-building',
          permissions: [this.$Permissions.backend.companyManagement.manage],
          children: [
            {
              label: this.$t('menu.registerCompany'),
              route: {name: 'list-register-company', groups: 'register-company'},
              permissions: [
                this.$Permissions.backend.companyManagement.companyRegistration.manage,
              ]
            },
            {
              label: this.$t('menu.noteLicenseCompany'),
              route: {name: 'list-notes-company', groups: 'notes-company'},
              permissions: [
                this.$Permissions.backend.companyManagement.note.manage,
              ]
            },
            {
              label: this.$t('menu.deikarbanhchoun'),
              route: {name: 'list-deikarbanhchouns-company', groups: 'deikarbanhchouns-company'},
              permissions: [
                this.$Permissions.backend.companyManagement.deikarbanhchoun.manage,
              ]
            },
          ]
        },
        {
          label: this.$t('menu.system'),
          route: null,
          icon: null,
          children: null,
          permissions: [this.$Permissions.backend.setting.manage]
        },
        {
          label: this.$t('menu.settings'),
          route: null,
          icon: 'fa fa-cogs',
          permissions: [this.$Permissions.backend.setting.manage],
          children: [
            {
              label: this.$t('menu.title'),
              route: {name: 'list-title', groups: 'title'},
              permissions: [this.$Permissions.backend.setting.title.manage]
            },
            {
              label: this.$t('menu.teamCategory'),
              route: {name: 'list-team-category', groups: 'team-category'},
              permissions: [this.$Permissions.backend.setting.teamCategory.manage]
            },
            {
              label: this.$t('menu.ministryPermitOrLicense'),
              route: {name: 'list-ministry-permit-or-license', groups: 'ministry-permit-or-license'},
              permissions: [this.$Permissions.backend.setting.ministryPermitOrLicense.manage]
            },
            {
              label: this.$t('menu.missionType'),
              route: {name: 'list-mission-type', groups: 'mission-type'},
              permissions: [this.$Permissions.backend.setting.missionType.manage]
            },
            {
              label: this.$t('menu.university'),
              route: {name: 'list-university', groups: 'university'},
              permissions: [this.$Permissions.backend.setting.university.manage]
            },
            {
              label: this.$t('menu.landUniqueNumberType'),
              route: {name: 'list-land-unique-number-type', groups: 'land-unique-number-type'},
              permissions: [this.$Permissions.backend.setting.landUniqueNumberType.manage]
            },
            {
              label: this.$t('menu.insuranceCompany'),
              route: {name: 'list-insurance-company', groups: 'insurance-company'},
              permissions: [this.$Permissions.backend.setting.insuranceCompany.manage]
            },
            {
              label: this.$t('menu.architectureType'),
              route: {name: 'list-architecture-type', groups: 'architecture-type'},
              permissions: [this.$Permissions.backend.setting.architectureType.manage]
            },
            {
              label: this.$t('menu.companyType'),
              route: {name: 'list-company-type', groups: 'company-type'},
              permissions: [this.$Permissions.backend.setting.companyType.manage]
            },
            {
              label: this.$t('menu.companySection'),
              route: {name: 'list-company-section', groups: 'company-section'},
              permissions: [this.$Permissions.backend.setting.companySection.manage]
            },
            {
              label: this.$t('menu.country'),
              route: {name: 'list-country', groups: 'country'},
              permissions: [this.$Permissions.backend.setting.country.manage]
            },
            {
              label: this.$t('menu.nationality'),
              route: {name: 'list-nationality', groups: 'nationality'},
              permissions: [this.$Permissions.backend.setting.nationality.manage]
            },
            {
              label: this.$t('menu.projectType'),
              route: {name: 'list-project-type', groups: 'project-type'},
              permissions: [this.$Permissions.backend.setting.projectType.manage]
            },
            {
              label: this.$t('menu.projectSubType'),
              route: {name: 'list-project-sub-type', groups: 'project-sub-type'},
              permissions: [this.$Permissions.backend.setting.projectSubType.manage]
            },
            {
              label: this.$t('menu.constructionType'),
              route: {name: 'list-construction-type', groups: 'construction-type'},
              permissions: [this.$Permissions.backend.setting.constructionType.manage]
            },
            {
              label: this.$t('menu.projectUnit'),
              route: {name: 'list-project-unit', groups: 'project-unit'},
              permissions: [this.$Permissions.backend.setting.projectUnit.manage]
            },
            {
              label: this.$t('menu.position'),
              route: {name: 'list-position', groups: 'position'},
              permissions: [this.$Permissions.backend.setting.position.manage]
            },
            {
              label: this.$t('menu.lawRegulationType'),
              route: {name: 'list-law-regulation-type', groups: 'law-regulation-type'},
              permissions: [this.$Permissions.backend.setting.lawRegulationType.manage]
            },
            {
              label: this.$t('menu.province'),
              route: {name: 'list-province', groups: 'province'},
              permissions: [this.$Permissions.backend.setting.province.manage]
            },
            {
              label: this.$t('menu.district'),
              route: {name: 'list-district', groups: 'district'},
              permissions: [this.$Permissions.backend.setting.district.manage]
            },
            {
              label: this.$t('menu.commune'),
              route: {name: 'list-commune'},
              permissions: [this.$Permissions.backend.setting.commune.manage]
            },
            {
              label: this.$t('menu.village'),
              route: {name: 'list-village', groups: 'village'},
              permissions: [this.$Permissions.backend.setting.village.manage]
            },
            {
              label: this.$t('menu.gender'),
              route: {name: 'list-gender', groups: 'gender'},
              permissions: [this.$Permissions.backend.setting.gender.manage]
            },
            {
              label: this.$t('menu.carbonCopy'),
              route: {name: 'list-carbon-copy', groups: 'carbon-copy'},
              permissions: [this.$Permissions.backend.setting.carbonCopy.manage]
            },
            {
              label: this.$t('menu.announcementReference'),
              route: {name: 'list-announcement-reference', groups: 'announcement-reference'},
              permissions: [this.$Permissions.backend.setting.announcementReference.manage]
            },
            {
              label: this.$t('menu.constructionLetterCommand'),
              route: {name: 'list-construction-letter-command', groups: 'construction-letter-command'},
              permissions: [this.$Permissions.backend.setting.constructionLetterCommand.manage]
            },
            {
              label: this.$t('menu.activityLogs'),
              route: {name: 'list-activity', groups: 'activity'},
              permissions: [this.$Permissions.backend.setting.activityLogs.manage]
            },
          ]
        },
        {
          label: this.$t('menu.administrations'),
          route: null,
          icon: 'fa fa-code',
          permissions: [this.$Permissions.backend.administration.manage],
          children: [
            {
              label: this.$t('menu.user'),
              route: {name: 'list-user', groups: 'user'},
              permissions: [this.$Permissions.backend.administration.user.list]
            },
            {
              label: this.$t('menu.role'),
              route: {name: 'list-role', groups: 'role'},
              permissions: [this.$Permissions.backend.administration.role.list]
            },
            {
              label: this.$t('menu.permission'),
              route: {name: 'list-permission', groups: 'permission'},
              permissions: [this.$Permissions.backend.administration.permission.list]
            }
          ]
        }
      ]
    }
  }
}
</script>

<style scoped>
ul.sidebar li > a {
  color: #444 !important;
}

ul li, a {
  font-family: var(--secondary-font)
}

.sidebar-menu > li,
.treeview-menu > li > a {
  font-size: 14px !important;
}
</style>
