<template>
  <company-data-table
    :title="$t('menu.construction_company_certifier_license')"
    name-route-create="create-construction-company"
    name-route-edit="edit-construction-company"
    name-route-show="show-construction-company"
    name-route-change-category="change-category-construction-company"
    :company-category-ids="[3]"
    :permission-create="[Permissions.backend.businessBuilders.constructionCompany.store]"
    :permission-filter="[Permissions.backend.businessBuilders.constructionCompany.filter]"
  />
</template>

<script>
import CompanyDataTable from "../../company/DataTable"
import Permissions from "@/permissions"

export default {
  name: "List",
  components: {CompanyDataTable},
  metaInfo() {
    return {
      title: this.$t('string.constructionCompany'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data () {
    return {
      Permissions
    }
  }
}
</script>

<style scoped>

</style>
