<template>
  <div class="nav-tabs-custom">
    <ul
      class="nav nav-tabs"
      role="tablist"
    >
      <li
        role="presentation"
        class="active"
      >
        <a
          href="#home"
          aria-controls="home"
          role="tab"
          data-toggle="tab"
        >
          {{ $t('string.formApplication') }}
        </a>
      </li>
      <li role="presentation">
        <a
          href="#profile"
          aria-controls="profile"
          role="tab"
          data-toggle="tab"
        >
          {{ $t('string.referenceDocument') }}
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div
        id="home"
        role="tabpanel"
        class="tab-pane active"
      >
        <inspection-form-show-data :inspection="inspection" />
      </div>
      <div
        id="profile"
        role="tabpanel"
        class="tab-pane"
      >
        <template v-if="inspection.media.length>0">
          <div class="row margin-bottom-15">
            <div class="col-md-12 text-right">
              <button
                class="btn btn-success btn-sm"
                @click="downloadAttachment"
              >
                <i class="fa fa-download" /> {{ $t('label.DownloadAllAttachments') }}
              </button>
            </div>
          </div>
          <div class="row">
            <upload-media-preview
              v-for="(doc, key) in inspection.media"
              :key="key"
              class-column="col-sm-4 col-md-3"
              :readonly="true"
              :doc="doc"
            />
          </div>
        </template>
        <template v-else>
          <div class="row">
            <div class="col-md-12">
              <h3 class="text-center">
                {{ $t('string.noResult') }}
              </h3>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import UploadMediaPreview from "../../../components/UploadMediaPreview"
import InspectionFormShowData from "./InspectionFormShowData"

export default {
  name: "InspectionForm",
  components: {InspectionFormShowData, UploadMediaPreview},
  props: {
    inspection: {
      type: Object,
      default: null,
    }
  },
  mounted() {
    if (this.inspection.inspection_geos.length > 0) {
      return this.locations = [
        this.center
      ]
    } else {
      this.locations = this.inspection.inspection_geos.map((item) => {
        return {lat: parseFloat(item.lat), lng: parseFloat(item.lng)}
      })
    }
  },
  methods: {
    downloadAttachment() {
      let mainRoute = '/api/backend/inspection/download-attachment/'
      window.open(this.$base_api + `${mainRoute}${this.inspection.uuid}&${localStorage.getItem(process.env.VUE_APP_TOKEN)}`)
    }
  }
}
</script>

<style scoped>

</style>
