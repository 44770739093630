<template>
  <div>
    <template v-if="haveAccessRight">
      <router-view />
    </template>
    <template v-else>
      <welcome />
    </template>
  </div>
</template>

<script>
import Welcome from "./pages/Welcome"

export default {
  name: 'Dashboard',
  components: {Welcome},
  computed: {
    haveAccessRight() {
      let permissions = [
        this.$Permissions.backend.dashboard.summary.manage,
        this.$Permissions.backend.dashboard.ministryLevel.manage,
        this.$Permissions.backend.dashboard.provinceLevel.manage,
      ]
      return this.can(permissions)
    }
  },
  mounted() {
    $(function () {
      $(window).resize()
      $(window).resize()
    })
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
  }
}
</script>
