<template>
  <XBox :title="$t('string.requesting_renew_license')">
    <div class="row">
      <div class="col-sm-12">
        <div class="form-horizontal">
          <XInput
            v-model="number"
            type="text"
            :placeholder="$t('label.declarationNumberOfMinistry')"
            required
            :validator="validations.number"
            :label="$t('label.declarationNumberOfMinistry')"
          />

          <XDatePicker
            v-model="issued_date"
            type="date"
            :label="$t('field.label.issued_date')"
            :lang="datepickerLocale[$i18n.locale].lang"
            :placeholder="$t('field.placeholder.issued_date')"
            format="DD/MM/YYYY"
            required
            :validator="validations.issued_date"
          />

          <XDatePicker
            v-model="effective_date"
            type="date"
            :disabled="true"
            :lang="datepickerLocale[$i18n.locale].lang"
            :placeholder="$t('field.placeholder.effective_date')"
            format="DD/MM/YYYY"
            :label="$t('field.label.effective_date')"
            required
            :validator="validations.effective_date"
          />

          <div class="form-group row">
            <div class="col-sm-8 col-sm-offset-4">
              <button
                class="btn btn-primary btn-sm margin-right-5"
                @click="save"
              >
                {{ $t('button.save') }}
              </button>
              <ButtonBack />
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-8 col-sm-offset-4">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>{{ $t('table.no_') }}</th>
              <th>{{ $t('label.declarationNumberOfMinistry') }}</th>
              <th>{{ $t('table.start_at') }}</th>
              <th>{{ $t('table.end_at') }}</th>
              <th>{{ $t('table.updated_by_user') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="licenses && Array.isArray(licenses) && licenses.length > 0">
              <tr
                v-for="(item, key) in licenses"
                :key="key"
              >
                <td>{{ key + 1 }}</td>
                <td>{{ item.number }}</td>
                <td>{{ item.issued_date }}</td>
                <td>{{ item.effective_date }}</td>
                <td>{{ item.user ? item.user.first_name + ' ' + item.user.last_name : $t('string.na') }}</td>
              </tr>
            </template>
            <template v-else>
              <tr>
                <td
                  colspan="5"
                  class="text-muted text-center"
                >
                  {{ $t('string.no_result') }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </XBox>
</template>

<script>
import XBox from "@/components/Widgets/XBox"
import ButtonBack from "@/components/ButtonBack"
import XInput from "@/components/Forms/XInput"
import XDatePicker from "@/components/Forms/XDatePicker"

export default {
  name: "RenewLicense",
  metaInfo() {
    return {
      title: this.$t('string.requesting_renew_license'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  components: {XDatePicker, XInput, ButtonBack, XBox},
  data() {
    return {
      number: null,
      issued_date: null,
      company: {},
      validations: {},
      licenses: []
    }
  },
  computed: {
    effective_date() {
      if (this.issued_date) {
        if (this.$moment(this.issued_date).isValid()) {
          return this.$moment(this.issued_date).add(3, 'M').toDate()
        }
      }
      return null
    }
  },
  mounted() {
    this.getLicenseList()
  },
  methods: {
    save() {
      this.validations = {}
      this.$isLoading(true)
      this.$axios.post(this.$base_api + '/api/backend/company/registration-number/create', {
        company_uuid: this.$route.params.company_uuid,
        number: this.number,
        issued_date: this.issued_date ? this.$moment(this.issued_date).locale('en').format('YYYY-MM-DD') : null,
        effective_date: this.effective_date ? this.$moment(this.effective_date).locale('en').format('YYYY-MM-DD') : null,
      }).then(({data}) => {
        if (data.data) {
          this.showToastr()
          this.$router.push({
            name: 'show-register-company',
            params: {
              company_uuid: this.$route.params.company_uuid
            }
          })
        }
      }).catch((error) => {
        if (error.response.status === 422) {
          this.validations = error.response.data.errors
        } else {
          this.onResponseError(error)
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    getLicenseList() {
      this.validations = {}
      this.$isLoading(true)
      this.$axios.post(this.$base_api + '/api/backend/company/registration-number/list', {
        company_uuid: this.$route.params.company_uuid,
      }).then(({data}) => {
        if (data.data) {
          this.licenses = data.data
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
