<template>
  <div class="row">
    <div class="col-sm-12">
      <h4 class="main-title">
        {{ $t('string.companyInformation') }}
      </h4>
    </div>

    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-horizontal">
            <XSelect
              v-model="company.title"
              :label="$t('string.companyClassTypes')"
              required
              :validator="validations.title"
              :options="[
                {label: $t('string.companyClass'), id: 'company'},
                {label: $t('string.enterpriseClass'), id: 'enterprise'}
              ]"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-horizontal">
            <XInput
              v-model="company.name_km"
              type="text"
              required
              :label="$t('label.nameKm')"
              :placeholder="$t('label.nameKm')"
              :validator="validations['name_km']"
            />
            <XSelect
              v-model="company.nationality_id"
              :label="$t('label.nationality')"
              :validator="validations.nationality_id"
              :options="nationalities"
              required
            />

            <XTextarea
              v-model="company.description"
              rows="5"
              :label="$t('label.description')"
              :placeholder="$t('label.description')"
            />

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.logo') }}</label>
              <div class="col-sm-8">
                <input
                  ref="logo"
                  type="file"
                  class="form-control"
                  name="logo"
                > <br>
                <template v-if="company.logo">
                  <render-image-tag :src="company.logo ? `${baseUrl}/${company.logo}` : null" />
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-horizontal">
            <XInput
              v-model="company.name_en"
              type="text"
              required
              :validator="validations.name_en"
              :label="$t('label.nameEn')"
              :placeholder="$t('label.nameEn')"
            />

            <XSelect
              v-model="company.country_id"
              required
              :label="$t('label.country')"
              :validator="validations.country_id"
              :options="countries"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12">
      <h4 class="main-title">
        {{ $t('string.companyRequestLicenseType') }}
      </h4>
    </div>

    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-horizontal">
            <XMultiSelect
              v-model="company.company_section"
              :label="`name_${$i18n.locale}`"
              track-by="id"
              :placeholder="$t('string.chooseCompanySection')"
              :options="companySections"
              :label-input="$t('label.companySection')"
              required
              :validator="validations.company_section_id"
            />

            <XMultiSelect
              v-model="company.categories"
              :label="`name_${$i18n.locale}`"
              :multiple="true"
              track-by="id"
              :placeholder="$t('label.companyCategory')"
              :options="companyCategories"
              :label-input="$t('label.companyCategory')"
              required
              :validator="validations.categories"
            />
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-horizontal">
            <XMultiSelect
              v-model="company.company_type"
              :label="`name_${$i18n.locale}`"
              track-by="id"
              :placeholder="$t('string.chooseCompanyType')"
              :options="companyTypes"
              :label-input="$t('label.companyType')"
              required
              :validator="validations['company_type_id']"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12">
      <h4 class="main-title">
        {{ $t('string.companyPeople') }}
      </h4>
    </div>

    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-horizontal">
            <XMultiSelect
              v-model="company.owners"
              :label="`name_${$i18n.locale}`"
              track-by="id"
              :placeholder="$t('string.typingToFindOwner')"
              :multiple="true"
              :options="people"
              :searchable="true"
              :internal-search="false"
              :clear-on-select="false"
              :label-input="$t('label.companyOwner')"
              required
              :validator="validations.owners"
              @search-change="searchOwners"
            >
              <DisplayPeopleChip
                :chips="{
                  model: 'people',
                  data: company.owners
                }"
              />
            </XMultiSelect>

            <XMultiSelect
              v-model="company.project_managers"
              :label="`name_${$i18n.locale}`"
              track-by="id"
              :placeholder="$t('string.typingToFindProjectManager')"
              :multiple="true"
              :options="people"
              :searchable="true"
              :internal-search="false"
              :clear-on-select="false"
              :label-input="$t('label.projectManagers')"
              @search-change="searchOwners"
            >
              <DisplayPeopleChip
                :chips="{
                  model: 'people',
                  data: company.project_managers
                }"
              />
            </XMultiSelect>

            <XMultiSelect
              v-model="company.technicians"
              track-by="id"
              :label="`name_${$i18n.locale}`"
              :placeholder="$t('string.typingToFindTechnicians')"
              :multiple="true"
              :options="people"
              :searchable="true"
              :internal-search="false"
              :clear-on-select="false"
              :label-input="$t('label.technicians')"
              @search-change="searchOwners"
            >
              <DisplayPeopleChip
                :chips="{
                  model: 'people',
                  data: company.technicians
                }"
              />
            </XMultiSelect>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-horizontal">
            <XMultiSelect
              v-model="company.ctos"
              :label="`name_${$i18n.locale}`"
              track-by="id"
              :tags="true"
              :placeholder="$t('string.typingToFindCto')"
              :multiple="true"
              :options="people"
              :searchable="true"
              :internal-search="false"
              :clear-on-select="false"
              :label-input="$t('label.ctos')"
              @search-change="searchOwners"
            >
              <DisplayPeopleChip
                :chips="{
                  model: 'people',
                  data: company.ctos
                }"
              />
            </XMultiSelect>

            <XMultiSelect
              v-model="company.ceos"
              :label="`name_${$i18n.locale}`"
              track-by="id"
              :placeholder="$t('string.typingToFindCeo')"
              :multiple="true"
              :options="people"
              :searchable="true"
              :internal-search="false"
              :clear-on-select="false"
              :label-input="$t('label.ceos')"
              @search-change="searchOwners"
            >
              <DisplayPeopleChip
                :chips="{
                  model: 'people',
                  data: company.ceos
                }"
              />
            </XMultiSelect>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12">
      <h4 class="main-title">
        {{ $t('string.companyAddress') }}
      </h4>
    </div>

    <div class="col-sm-12">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-horizontal">
            <XInput
              v-model="company.email"
              type="email"
              :label="$t('label.email')"
              :placeholder="$t('label.email')"
            />

            <XInput
              v-model="company.phone"
              type="text"
              :label="$t('label.phone')"
              :placeholder="$t('label.phone')"
            />

            <XInput
              v-model="company.website"
              type="url"
              :label="$t('label.website')"
              :placeholder="$t('label.website')"
            />

            <location-input
              label-classes="col-sm-4 control-label"
              input-classes="col-sm-8"
            />

            <XInput
              v-model="company.lat"
              type="number"
              :label="$t('label.lat')"
              :placeholder="$t('label.lat')"
            />

            <XInput
              v-model="company.lng"
              type="number"
              :placeholder="$t('label.lng')"
              :label="$t('label.lng')"
            />

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.map') }}</label>
              <div class="col-sm-8">
                <div class="thumbnail no-margin-bottom">
                  <GmapMap
                    :center="{lat:company.lat, lng:company.lng}"
                    :zoom="15"
                    map-type-id="terrain"
                    style="width: 100%; height: 430px"
                  >
                    <GmapMarker
                      :clickable="true"
                      :draggable="true"
                      :icon="{url: '/img/markers/blue20x20.png'}"
                      :position="{lat:company.lat, lng:company.lng}"
                      @dragend="movePinOnGoogleMap"
                    />
                  </GmapMap>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-8 col-sm-offset-4">
                <button
                  class="btn btn-primary btn-sm margin-r-5"
                  @click="save"
                >
                  {{ $t('button.save') }}
                </button>
                <ButtonBack />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapState} from "vuex"
import {debounce} from "debounce"
import LocationInput from "@/components/LocationInput"
import RenderImageTag from "@/components/RenderImageTag"
import {companyValidationMixins} from "@/mixins/companyValidations"
import {mapFields} from "vuex-map-fields"
import XInput from "@/components/Forms/XInput"
import ButtonBack from "@/components/ButtonBack"
import XSelect from "@/components/Forms/XSelect"
import XTextarea from "@/components/Forms/XTextarea"
import XMultiSelect from "@/components/Forms/XMultiSelect"
import DisplayPeopleChip from "@/components/DisplayPeopleChip"

export default {
  name: "RegistrationCompanyForm",
  components: {
    DisplayPeopleChip,
    XMultiSelect,
    XTextarea,
    XSelect,
    ButtonBack,
    XInput,
    RenderImageTag,
    LocationInput
  },
  mixins: [companyValidationMixins],
  data() {
    return {
      company: {
        company_uuid: null,
        title: null,
        name_en: null,
        name_km: null,
        nationality_id: null,
        description: null,
        country_id: null,
        company_section: null,
        company_type: null,
        categories: [],
        owners: [],
        project_managers: [],
        technicians: [],
        ctos: [],
        ceos: [],
        email: null,
        phone: null,
        website: null,
        home_number: null,
        street_number: null,
        group_number: null,
        province_id: null,
        district_id: null,
        commune_id: null,
        village: null,
        lat: 11.576310,
        lng: 104.923104,
      },
      validations: {},
      people: []
    }
  },
  computed: {
    api() {
      return `${process.env.VUE_APP_API}/api/backend/company/registration`
    },
    ...mapState({
      address: state => state.location.address
    }),
    ...mapFields('options', {
      nationalities: 'company_management.nationalities',
      countries: 'company_management.countries',
      companyTypes: 'company_management.companyTypes',
      companySections: 'company_management.companySections',
      companyCategories: 'company_management.companyCategories',
    })
  },
  methods: {
    searchOwners: debounce(function (query = null) {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/people/remote-option', {
        query_text: query
      }).then((res) => {
        if (res.data.data.length > 0) {
          this.people = res.data.data
        } else {
          this.people = []
        }
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      })
    }, 200),
    movePinOnGoogleMap(e) {
      let latLng = e.latLng
      this.company.lat = parseFloat(parseFloat(latLng.lat()).toFixed(6))
      this.company.lng = parseFloat(parseFloat(latLng.lng()).toFixed(6))
    },
    save() {
      this.validations = {}
      const params = new FormData()

      for (let key in this.company) {
        let value = this.company[key]
        if (value !== null && value !== '' && value !== undefined) {
          // eslint-disable-next-line no-prototype-builtins
          if (value.hasOwnProperty('id')) { // company_type, company_section
            params.set(`${key}_id`, value.id)
          } else if (Array.isArray(value)) {
            if (value.length > 0) {
              value.forEach((item, k) => {
                params.set(`${key}[${k}]`, item.id)
              })
            } else {
              params.delete(key)
            }
          } else {
            if (key.includes("_date") && this.$moment(value).isValid()) {
              value = this.$moment(value).locale('en').format('YYYY-MM-DD')
            }
            params.set(key, value)
          }
        } else {
          params.delete(key)
        }
      }

      if (this.$refs.logo.files.length > 0) {
        params.set('logo', this.$refs.logo.files[0])
      } else {
        params.delete('logo')
      }

      for (let key in this.address) {
        if (this.address[key] !== null && this.address[key] !== '') {
          params.set(key, this.address[key])
        } else {
          params.delete(key)
        }
      }

      this.$isLoading(true)
      this.$axios.post(`${this.api}/store`, params)
        .then(({data}) => {
          if (data.data) {
            this.showToastr()
            this.$router.push({
              name: 'list-register-company'
            })
          }
        })
        .catch((error) => {
          let response = error.response
          if (response && response.status && response.status === 422) {
            this.validations = response.data.errors
            this.$store.commit('location/setValidations', response.data.errors)
          } else {
            this.$store.commit('location/setValidations', null)
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getCompany() {
      this.$isLoading(true)
      this.$axios.post(`${this.api}/show`, {
        company_uuid: this.$route.params.company_uuid,
      }).then(({data}) => {
        if (data.data) {
          let company = data.data
          this.company = company
          this.company['company_uuid'] = company.uuid
          this.company.lat = company.lat ? company.lat : this.company.lat
          this.company.lng = company.lng ? company.lng : this.company.lng

          this.setAddress({
            province_id: this.company.province_id,
            district_id: this.company.district_id,
            commune_id: this.company.commune_id,
            village_id: this.company.village_id,
            home_number: this.company.home_number,
            street_number: this.company.street_number,
            group_number: this.company.group_number,
            reload: true
          })
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    ...mapActions('options', {
      fetchCompanyManagementOptions: 'fetchCompanyManagementOptions'
    }),
    ...mapActions('location', {
      setAddress: 'setAddress'
    })
  },
  mounted() {
    this.fetchCompanyManagementOptions(this)
    this.searchOwners()
    if (this.$route.params.company_uuid) {
      this.getCompany()
    }
  }
}
</script>

<style scoped>
.no-margin-bottom {
  margin-bottom: 0 !important;
}
</style>
