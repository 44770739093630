import Index from "@/views/companyManagement/deikarbanhchouns/Index"
import List from "@/views/companyManagement/deikarbanhchouns/List"
import Create from "@/views/companyManagement/deikarbanhchouns/Create"
import Show from "@/views/companyManagement/deikarbanhchouns/Show"
import Approved from "@/views/companyManagement/deikarbanhchouns/Approved"

import Permissions from "@/permissions"

const deikarbanhchoun = Permissions.backend.companyManagement.deikarbanhchoun

const getMeta = (permissions, link) => {
  return {
    permissions,
    groups: 'deikarbanhchouns-company',
    breadcrumb: [
      {
        name_en: 'Deikarbanhchoun',
        name_km: 'ដីការបញ្ជូន'
      },
      link
    ]
  }
}

export const deikarbanhchounRouters = {
  path: 'deikarbanhchouns',
  component: Index,
  redirect: 'deikarbanhchouns',
  meta: {
    permissions: [Permissions.backend.companyManagement.deikarbanhchoun.manage],
    groups: 'deikarbanhchouns-company',
  },
  children: [
    {
      path: '',
      name: 'list-deikarbanhchouns-company',
      component: List,
      meta: getMeta([deikarbanhchoun.list], {
        name_en: 'List',
        name_km: 'បញ្ជី'
      })
    },
    {
      path: 'create',
      name: 'create-deikarbanhchouns-company',
      component: Create,
      meta: getMeta([deikarbanhchoun.store], {
        name_en: 'Create',
        name_km: 'បង្កើត'
      })
    },
    {
      path: 'edit/:deikarbanhchoun_uuid',
      name: 'edit-deikarbanhchouns-company',
      component: Create,
      meta: getMeta([deikarbanhchoun.edit], {
        name_en: 'Edit',
        name_km: 'កែសម្រួល'
      })
    },
    {
      path: 'show/:deikarbanhchoun_uuid',
      name: 'show-deikarbanhchouns-company',
      component: Show,
      meta: getMeta([deikarbanhchoun.show], {
        name_en: 'Show',
        name_km: 'មើល'
      })
    },
    {
      path: 'approved/:deikarbanhchoun_uuid',
      name: 'approved-deikarbanhchouns-company',
      component: Approved,
      meta: getMeta([deikarbanhchoun.approve], {
        name_en: 'Approve',
        name_km: 'អនុម័ត'
      })
    },
  ]
}
