<template>
  <office-form
    :button="{
      label: $t('button.update'),
      btnClass: 'info',
      handle: 'edit'
    }"
    :title="$t('label.editOffice')"
  />
</template>

<script>
import OfficeForm from "./Form"

export default {
  name: "Edit",
  components: {OfficeForm},
  metaInfo: {
    title: process.env.VUE_APP_NAME,
    titleTemplate: 'Edit Office | %s'
  }
}
</script>

<style scoped>

</style>
