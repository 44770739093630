<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('label.editPosition') }}
      </h3>
      <div class="box-tools">
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <div class="form-horizontal">
        <div
          :class="nameKmValidation.required ? ' has-error' : null"
          class="form-group row"
        >
          <label class="col-sm-3 control-label required">{{ $t('label.nameKm') }}</label>
          <div class="col-sm-9">
            <input
              v-model="position.name_km"
              :placeholder="$t('label.nameKm')"
              class="form-control"
              type="text"
            >
            <template v-if="nameKmValidation.required">
              <span class="label-error">{{ nameKmValidation.message }}</span>
            </template>
          </div>
        </div>

        <div
          :class="nameEnValidation.required ? ' has-error' : null"
          class="form-group row"
        >
          <label class="col-sm-3 control-label required">{{ $t('label.nameEn') }}</label>
          <div class="col-sm-9">
            <input
              v-model="position.name_en"
              :placeholder="$t('label.nameEn')"
              class="form-control"
              type="text"
            >
            <template v-if="nameEnValidation.required">
              <span class="label-error">{{ nameEnValidation.message }}</span>
            </template>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-9 col-sm-offset-3">
            <button
              class="btn btn-info btn-sm"
              @click="updatePosition"
            >
              {{ $t('button.update') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Edit",
  metaInfo() {
    return {
      title: this.$t('label.editPosition'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      position: {},
      validations: null,
    }
  },
  computed: {
    nameKmValidation() {
      return this.getValidationColumn(this.validations, 'name_km')
    },
    nameEnValidation() {
      return this.getValidationColumn(this.validations, 'name_en')
    }
  },
  created() {
    this.getPosition()
  },
  methods: {
    updatePosition() {
      this.position.position_uuid = this.position.uuid
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/position/update', this.position)
        .then(() => {
          this.showToastr()
          this.$router.push({name: 'list-position'})
        })
        .catch((error) => {
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
          } else {
            this.validations = null
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getPosition() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/position/show', {
        position_uuid: this.$route.params.position_uuid,
      }).then((res) => {
        this.position = res.data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-position'})
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
