<template>
  <company-show
    :edit-permission="$Permissions.backend.investorConstruction.company.edit"
    :investor-company="true"
    :mode-show="true"
    :title="$t('string.showCompany')"
    name-route-edit="edit-company"
  />
</template>

<script>
import CompanyShow from "../../company/Show"

export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('string.showCompany'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {CompanyShow},
}
</script>

<style scoped>

</style>
