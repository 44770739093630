<template>
  <div class="row">
    <div class="col-sm-3">
      <div class="box box-primary margin-bottom">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('string.letterDetail') }}
          </h3>
          <div class="box-tools">
            <asora-back-button />
          </div>
        </div>
        <div class="box-body">
          <construction-letter-info :construction-letter="construction_letter" />
        </div>
      </div>
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('string.project') }}
          </h3>
        </div>
        <div class="box-body">
          <construction-letter-project :project="project" />
        </div>
      </div>
    </div>
    <div class="col-sm-9">
      <div class="nav-tabs-custom">
        <ul
          class="nav nav-tabs"
          role="tablist"
        >
          <li
            role="presentation"
            class="active"
          >
            <a
              href="#previewLetterTab"
              aria-controls="home"
              role="tab"
              data-toggle="tab"
            >
              {{ typeLetter }}
            </a>
          </li>
          <li role="presentation">
            <a
              href="#noteLetterTab"
              aria-controls="note"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.noteLetterTab') }}
            </a>
          </li>
          <li role="presentation">
            <a
              href="#documentLetterTab"
              aria-controls="home"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.documentLetterTab') }}
            </a>
          </li>
          <li role="presentation">
            <a
              href="#activityLogs"
              aria-controls="activityLogs"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.activityLogs') }}
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div
            id="previewLetterTab"
            role="tabpanel"
            class="tab-pane active"
          >
            <div class="col-xs-12 margin-bottom">
              <div class="col-xs-12">
                <button
                  class="btn btn-default pull-right"
                  @click="printLetter"
                >
                  <i class="fa fa-print" /> {{ $t('label.print') }}
                </button>
              </div>
            </div>
            <smart-iframe :src="`${letterUrl}`" />
          </div>
          <div
            id="noteLetterTab"
            role="tabpanel"
            class="tab-pane"
          >
            <div class="col-xs-12 margin-bottom">
              <div class="col-xs-12">
                <button
                  class="btn btn-default pull-right"
                  @click="printNote"
                >
                  <i class="fa fa-print" /> {{ $t('label.print') }}
                </button>
              </div>
            </div>
            <smart-iframe :src="`${noteLetterUrl}`" />
          </div>
          <div
            id="documentLetterTab"
            role="tabpanel"
            class="tab-pane"
          >
            <construction-upload-document :readonly="true" />
          </div>
          <div
            id="activityLogs"
            role="tabpanel"
            class="tab-pane"
          >
            <activity-logs
              model_type="construction_letter"
              :model_uuid="$route.params.construction_letter_uuid"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConstructionLetterProject from "./ConstructionLetterProject"
import ConstructionLetterInfo from "./ConstructionLetterInfo"
import ConstructionUploadDocument from "./ConstructionUploadDocument"
import ActivityLogs from "../../../components/ActivityLogs"
import SmartIframe from "../../../components/SmartIframe"

export default {
  name: "DetailLetter",
  components: {
    SmartIframe,
    ActivityLogs,
    ConstructionUploadDocument,
    ConstructionLetterInfo,
    ConstructionLetterProject,
  },
  props: {
    typeLetter: {
      type: String,
      default () {
        return this.$t('string.letter')
      },
    }
  },
  data() {
    return {
      construction_letter: null,
      project: null,
    }
  },
  computed: {
    letterUrl() {
      let baseUrl = `${this.baseUrl}/construction-letter`
      let construction_letter_uuid = this.$route.params.construction_letter_uuid
      if (this.$route.name === 'show-open-construction-letter') {
        return `${baseUrl}/open-construction-letter/preview/${construction_letter_uuid}`
      }
      return `${baseUrl}/close-construction-letter/preview/${construction_letter_uuid}`
    },
    noteLetterUrl() {
      let baseUrl = `${this.baseUrl}/construction-letter`
      let construction_letter_uuid = this.$route.params.construction_letter_uuid
      if (this.$route.name === 'show-open-construction-letter') {
        return `${baseUrl}/open-construction-letter/note/preview/${construction_letter_uuid}`
      }
      return `${baseUrl}/close-construction-letter/note/preview/${construction_letter_uuid}`
    },
    apiUrl() {
      let baseUrl = `${this.baseUrl}/api/backend/construction-letter`
      if (this.$route.name === 'show-open-construction-letter') {
        return `${baseUrl}/open-construction-letter/get-construction-letter`
      }
      return `${baseUrl}/close-construction-letter/get-construction-letter`
    },
  },
  mounted() {
    this.getConstructionLetter()
      .then(() => {
        if (this.construction_letter.project !== null) {
          this.project = this.construction_letter.project
        }
      })
      .finally(() => {
        this.$isLoading(false)
      })
  },
  methods: {
    async getConstructionLetter() {
      this.$isLoading(true)
      let response = await this.$axios.post(this.apiUrl, {
        construction_letter_uuid: this.$route.params.construction_letter_uuid,
      })
      this.construction_letter = await response.data.data
    },
    printLetter() {
      window.open(this.letterUrl, '_blank')
    },
    printNote() {
      window.open(this.noteLetterUrl, '_blank')
    },
  }
}
</script>

<style scoped>
  iframe {
    width: 100%;
    height: 80vh;
    border: none;
  }
</style>
