<template>
  <construction-data-form
    :button="{
      label: $t('button.update'),
      btnClass: 'info',
      handle: 'edit'
    }"
    :title="$t('string.editConstructionData')"
  />
</template>

<script>
import ConstructionDataForm from "./Form"

export default {
  name: "Edit",
  metaInfo() {
    return {
      title: this.$t('string.editConstructionData'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {ConstructionDataForm}
}
</script>

<style scoped>

</style>
