<template>
  <button
    class="btn btn-default btn-sm"
    @click="$router.back()"
  >
    <i class="fa fa-arrow-alt-circle-left" /> {{ $t('button.back') }}
  </button>
</template>

<script>
export default {
  name: "AsoraBackButton",
}
</script>

<style scoped>

</style>
