const Permissions = {
  backend: {
    name: {
      en: 'Backend',
      km: 'ផ្ទាំងក្រោយ'
    },
    manage: 'manage-backend',
    dashboard: {
      name: {
        en: 'Dashboard',
        km: 'ផ្ទាំងគ្រប់គ្រង',
      },
      manage: 'mange-dashboard',
      summary: {
        name: {
          en: 'Summary',
          km: 'ផ្ទាំងគ្រប់គ្រងសង្ខេប',
        },
        manage: 'manage-summary',
      },
      ministryLevel: {
        name: {
          en: 'Ministry Level',
          km: 'សមត្ថកិច្ចក្រសួង',
        },
        manage: 'manage-ministry-level',
      },
      provinceLevel: {
        name: {
          en: 'Province Level',
          km: 'សមត្ថកិច្ចរាជធានី ខេត្ត',
        },
        manage: 'manage-province-level',
      }
    },
    lawRegulation: {
      name: {
        en: 'Law Regulation',
        km: 'ច្បាប់បទប្បញ្ញត្តិសំណង់',
      },
      manage: 'manage-law-regulation',
      list: 'list-law-regulation',
      show: 'show-law-regulation',
      store: 'store-law-regulation',
      edit: 'edit-law-regulation',
      filter: 'filter-law-regulation',
      delete: 'delete-law-regulation',
      toggle: 'toggle-law-regulation',
    },
    news: {
      name: {
        en: 'News',
        km: 'ព័ត៌មានប្រចាំថ្ងៃក្រសួង',
      },
      manage: 'manage-news',
      list: 'list-news',
      show: 'show-news',
      store: 'store-news',
      edit: 'edit-news',
      toggle: 'toggle-news',
      delete: 'delete-news',
    },
    project: {
      name: {
        en: 'Project',
        km: 'សំណង់៖ សមត្ថកិច្ចក្រសួង'
      },
      manage: 'manage-project',
      list: 'list-project',
      map: 'map-project',
      show: 'show-project',
      store: 'store-project',
      edit: 'edit-project',
      delete: 'delete-project',
      filter: 'filter-project',
      sync: 'sync-project',
      showHide: 'show-hide-project',
      showColorTip: 'show-color-tip-project',
      storeProcessRequesting: 'store-process-requesting-project',
      showProcessRequesting: 'show-process-requesting-project',
    },
    projectOption: {
      name: {
        en: 'Project Option',
        km: 'បន្ថែមជម្រើស',
      },
      manage: 'mange-project-option',
      addOwner: 'add-owner-project-option',
      addRepresentative: 'add-representative-project-option',
      addUnit: 'add-unit-project-option',
      addCountry: 'add-country-project-option',
      addCompany: 'add-company-project-option',
      addArchitecture: 'add-architecture-project-option',
      addEngineer: 'add-engineer-project-option',
      addMinistryOther: 'add-ministry-other-project-option',
      addOpeningConstruction: 'add-opening-construction-project-option',
      addStopConstruction: 'add-stop-construction-project-option',
      addPendingConstruction: 'add-pending-construction-project-option',
      addProjectType: 'add-project-type-option',
      addConstructionType: 'add-construction-type-project-option',
    },
    mission: {
      name: {
        en: 'Mission',
        km: 'បេសកម្ម'
      },
      manage: 'manage-mission',
      list: 'list-mission',
      show: 'show-mission',
      store: 'store-mission',
      edit: 'edit-mission',
      delete: 'delete-mission',
      filter: 'filter-mission',
    },
    inspection: {
      name: {
        en: 'Inspection',
        km: 'ចុះត្រួតពិនិត្យ'
      },
      manage: 'manage-inspection',
      list: 'list-inspection',
      show: 'show-inspection',
      store: 'store-inspection',
      edit: 'edit-inspection',
      delete: 'delete-inspection',
      filter: 'filter-inspection',
      draft: {
        name: {
          en: 'Draft',
          km: 'សេចក្តីព្រាង'
        },
        manage: 'manage-inspection-draft',
        list: 'list-inspection-draft',
        show: 'show-inspection-draft',
        store: 'store-inspection-draft',
        edit: 'edit-inspection-draft',
        filter: 'filter-inspection-draft',
      },
      submit: {
        name: {
          en: 'Submit',
          km: 'បានបញ្ជូន'
        },
        manage: 'manage-inspection-submit',
        list: 'list-inspection-submit',
        show: 'show-inspection-submit',
        store: 'store-inspection-submit',
        edit: 'edit-inspection-submit',
        filter: 'filter-inspection-submit',
      },
    },
    provinceProject: {
      name: {
        en: 'Province Project',
        km: 'សំណង់៖ សមត្ថកិច្ចរាជធានី ខេត្ត'
      },
      manage: 'manage-province-project',
      list: 'list-province-project',
      listAll: 'list-all-province-project',
      map: 'map-province-project',
      show: 'show-province-project',
      store: 'store-province-project',
      edit: 'edit-province-project',
      delete: 'delete-province-project',
      filter: 'filter-province-project',
      showColorTip: 'show-color-tip-province-project',
      sync: 'sync-province-project',
      showHide: 'show-hide-province-project',
      storeProcessRequesting: 'store-process-requesting-province-project',
      showProcessRequesting: 'show-process-requesting-province-project',
    },
    provinceConstructionData: {
      name: {
        en: 'Province Construction Data',
        km: 'សម្រង់ទិន្នន័យសំណង់រាជធានី ខេត្ត'
      },
      manage: 'manage-province-construction-data',
      list: 'list-province-construction-data',
      show: 'show-province-construction-data',
      store: 'store-province-construction-data',
      edit: 'edit-province-construction-data',
      delete: 'delete-province-construction-data',
      filter: 'filter-province-construction-data',
    },
    businessBuilders: {
      name: {
        en: 'Business Builder',
        km: 'អ្នកប្រកបអាជីវកម្មក្នុងវិស័យសំណង់'
      },
      manage: 'manage-business-builder',
      professionalStudyPlanner: {
        name: {
          en: 'Professional Study Planner',
          km: 'អ្នកប្រកបអាជីវកម្មក្នុងវិស័យសំណង់'
        },
        manage: 'manage-professional-study-planner',
        list: 'list-professional-study-planner',
        show: 'show-professional-study-planner',
        store: 'store-professional-study-planner',
        edit: 'edit-professional-study-planner',
        delete: 'delete-professional-study-planner',
        filter: 'filter-professional-study-planner',
        toggle: 'toggle-professional-study-planner',
      },
      studyPlanCompany: {
        name: {
          en: 'Study Plan Company',
          km: 'ក្រុមហ៊ុនសិក្សាគម្រោងប្លង់'
        },
        manage: 'manage-study-plan-company',
        list: 'list-study-plan-company',
        show: 'show-study-plan-company',
        store: 'store-study-plan-company',
        edit: 'edit-study-plan-company',
        delete: 'delete-study-plan-company',
        changeCategory: 'change-category-study-plan-company',
        filter: 'filter-study-plan-company',
        toggle: 'toggle-study-plan-company',
      },
      constructionCompany: {
        name: {
          en: 'Construction Company',
          km: 'ក្រុមហ៊ុនសាងសង់'
        },
        manage: 'manage-construction-company',
        list: 'list-construction-company',
        show: 'show-construction-company',
        store: 'store-construction-company',
        edit: 'edit-construction-company',
        delete: 'delete-construction-company',
        changeCategory: 'change-category-construction-company',
        filter: 'filter-category-construction-company',
        toggle: 'toggle-construction-company',
      },
      checkAndConfirmPlanCompany: {
        name: {
          en: 'Check and Confirm Plan',
          km: 'អ្នកត្រួតពិនិត្យនិងបញ្ចាក់គម្រោងប្លង់'
        },
        manage: 'manage-check-and-confirm-plan-company',
        list: 'list-check-and-confirm-plan-company',
        show: 'show-check-and-confirm-plan-company',
        store: 'store-check-and-confirm-plan-company',
        edit: 'edit-check-and-confirm-plan-company',
        delete: 'delete-check-and-confirm-plan-company',
        changeCategory: 'change-category-check-and-confirm-plan-company',
        filter: 'filter-category-check-and-confirm-plan-company',
        toggle: 'toggle-check-and-confirm-plan-company',
      },
      checkAndConfirmConstructionCompany: {
        name: {
          en: 'Check and Confirm Construction',
          km: 'អ្នកត្រួតពិនិត្យនិងបញ្ចាក់គម្រោងប្លង់'
        },
        manage: 'manage-check-and-confirm-construction-company',
        list: 'list-check-and-confirm-construction-company',
        show: 'show-check-and-confirm-construction-company',
        store: 'store-check-and-confirm-construction-company',
        edit: 'edit-check-and-confirm-construction-company',
        delete: 'delete-check-and-confirm-construction-company',
        changeCategory: 'change-category-check-and-confirm-construction-company',
        filter: 'filter-category-check-and-confirm-construction-company',
        toggle: 'toggle-check-and-confirm-construction-company',
      },
    },
    noneBoard: {
      name: {
        en: 'None Board',
        km: 'អ្នកវិជ្ជាជីវៈមិនទាន់មានគណៈ'
      },
      manage: 'manage-none-board',
      architecture: {
        name: {
          en: 'None Board Architecture',
          km: 'គណៈស្ថាបត្យករកម្ពុជា'
        },
        manage: 'manage-none-board-architecture',
        list: 'list-none-board-architecture',
        show: 'show-none-board-architecture',
        store: 'store-none-board-architecture',
        edit: 'edit-none-board-architecture',
        delete: 'delete-none-board-architecture',
        filter: 'filter-none-board-architecture',
        toggle: 'toggle-none-board-architecture',
      },
      engineer: {
        name: {
          en: 'None Board Engineer',
          km: 'គណៈវិស្វករកម្ពុជា'
        },
        manage: 'manage-none-board-engineer',
        list: 'list-none-board-engineer',
        show: 'show-none-board-engineer',
        store: 'store-none-board-engineer',
        edit: 'edit-none-board-engineer',
        delete: 'delete-none-board-engineer',
        filter: 'filter-none-board-engineer',
        toggle: 'toggle-none-board-engineer',
      }
    },
    hasBoard: {
      name: {
        en: 'Has Board',
        km: 'គណៈវិជ្ជាជីវៈ'
      },
      manage: 'manage-has-board',
      architecture: {
        name: {
          en: 'Has Board Architecture',
          km: 'គណៈស្ថាបត្យករកម្ពុជា'
        },
        manage: 'manage-has-board-architecture',
        list: 'list-has-board-architecture',
        show: 'show-has-board-architecture',
        store: 'store-has-board-architecture',
        edit: 'edit-has-board-architecture',
        delete: 'delete-has-board-architecture',
        filter: 'filter-has-board-architecture',
        toggle: 'toggle-has-board-architecture',
      },
      engineer: {
        name: {
          en: 'Has Board Engineer',
          km: 'គណៈវិស្វករកម្ពុជា'
        },
        manage: 'manage-has-board-engineer',
        list: 'list-has-board-engineer',
        show: 'show-has-board-engineer',
        store: 'store-has-board-engineer',
        edit: 'edit-has-board-engineer',
        delete: 'delete-has-board-engineer',
        filter: 'filter-has-board-engineer',
        toggle: 'toggle-has-board-engineer',
      }
    },
    investorConstruction: {
      name: {
        en: 'Investor Construction',
        km: 'អ្នកវិនិយោគក្នុងវិស័យសំណង់'
      },
      manage: 'manage-investor-construction',
      personality: {
        name: {
          en: 'Personality',
          km: 'រូនវន្តបុគ្គល'
        },
        manage: 'manage-personality',
        list: 'list-personality',
        show: 'show-personality',
        store: 'store-personality',
        edit: 'edit-personality',
        delete: 'delete-personality',
        filter: 'filter-personality',
      },
      company: {
        name: {
          en: 'Company',
          km: 'ក្រុមហ៊ុន'
        },
        manage: 'manage-company',
        list: 'list-company',
        show: 'show-company',
        store: 'store-company',
        edit: 'edit-company',
        delete: 'delete-company',
        changeCategory: 'change-category-company',
        filter: 'filter-company',
        toggle: 'toggle-company',
      }
    },
    office: {
      name: {
        en: 'Office',
        km: 'ការិយាល័យ'
      },
      manage: 'manage-office',
      list: 'list-office',
      show: 'show-office',
      store: 'store-office',
      edit: 'edit-office',
      delete: 'delete-office',
      map: 'map-office',
      filter: 'filter-office',
    },
    officer: {
      name: {
        en: 'Officer',
        km: 'មន្ត្រី'
      },
      manage: 'manage-officer',
      list: 'list-officer',
      show: 'show-officer',
      store: 'store-officer',
      edit: 'edit-officer',
      delete: 'delete-officer',
      filter: 'filter-officer',
    },
    team: {
      name: {
        en: 'Team',
        km: 'ក្រុម'
      },
      manage: 'manage-team',
      list: 'list-team',
      show: 'show-team',
      store: 'store-team',
      edit: 'edit-team',
      delete: 'delete-team',
      filter: 'filter-team',
    },
    constructionLetter: {
      name: {
        en: 'Construction Letter',
        km: 'លិខិតអនុញ្ញាតសម្រាប់ការដ្ឋាន'
      },
      manage: 'manage-construction-letter',
      openConstructionLetter: {
        name: {
          en: 'Open Construction Letter',
          km: 'លិខិតបើកការដ្ឋាន',
        },
        manage: 'manage-open-construction-letter',
        list: 'list-open-construction-letter',
        show: 'show-open-construction-letter',
        store: 'store-open-construction-letter',
        edit: 'edit-open-construction-letter',
        delete: 'delete-open-construction-letter',
        print: 'print-open-construction-letter',
        export: 'export-open-construction-letter',
        approve: 'approve-open-construction-letter',
      },
      closeConstructionLetter: {
        name: {
          en: 'Close Construction Letter',
          km: 'លិខិតបិទការដ្ឋាន',
        },
        manage: 'manage-close-construction-letter',
        list: 'list-close-construction-letter',
        show: 'show-close-construction-letter',
        store: 'store-close-construction-letter',
        edit: 'edit-close-construction-letter',
        delete: 'delete-close-construction-letter',
        print: 'print-close-construction-letter',
        export: 'export-close-construction-letter',
        approve: 'approve-close-construction-letter',
      },
    },
    certificate: {
      name: {
        en: 'Certificate',
        km: 'វិញ្ញាបនបត្រ'
      },
      manage: 'manage-certificate',
      occupancy: {
        name: {
          en: 'Certificate of Occupancy',
          km: 'វិញ្ញាបនបត្រប្រើប្រាស់សំណង់',
        },
        manage: 'manage-certificate-of-occupancy',
        list: 'list-certificate-of-occupancy',
        show: 'show-certificate-of-occupancy',
        store: 'store-certificate-of-occupancy',
        edit: 'edit-certificate-of-occupancy',
        delete: 'delete-certificate-of-occupancy',
        print: 'print-certificate-of-occupancy',
        export: 'export-certificate-of-occupancy',
        approve: 'approve-certificate-of-occupancy',
        upload: 'upload-certificate-of-occupancy',
        filter: 'filter-certificate-of-occupancy'
      },
    },
    certificateProvince: {
      name: {
        en: 'Certificate Province',
        km: 'វិញ្ញាបនបត្រថ្នាក់ខេត្ត'
      },
      manage: 'manage-certificate-province',
      occupancy: {
        name: {
          en: 'Certificate of Occupancy Province',
          km: 'វិញ្ញាបនបត្រប្រើប្រាស់សំណង់ថ្នាក់ខេត្ត',
        },
        manage: 'manage-certificate-of-occupancy-province',
        list: 'list-certificate-of-occupancy-province',
        listAll: 'list-all-certificate-of-occupancy-province',
        show: 'show-certificate-of-occupancy-province',
        store: 'store-certificate-of-occupancy-province',
        edit: 'edit-certificate-of-occupancy-province',
        delete: 'delete-certificate-of-occupancy-province',
        print: 'print-certificate-of-occupancy-province',
        export: 'export-certificate-of-occupancy-province',
        approve: 'approve-certificate-of-occupancy-province',
        upload: 'upload-certificate-of-occupancy-province',
        filter: 'filter-certificate-of-occupancy-province'
      },
    },
    roubavontbokkol: {
      name: {
        en: 'Roubavontbokkol Management',
        km: 'រូបវន្តបុគ្គល'
      },
      manage: 'manage-roubavontbokkol',
      roubavontbokkolRegistration: {
        name: {
          en: 'Roubavontbokkol Registration',
          km: 'ការចុះបញ្ជីរូបវន្តបុគ្គល',
        },
        manage: 'manage-roubavontbokkol-registration',
        list: 'list-roubavontbokkol-registration',
        show: 'show-roubavontbokkol-registration',
        store: 'store-roubavontbokkol-registration',
        edit: 'edit-roubavontbokkol-registration',
        delete: 'delete-roubavontbokkol-registration',
      },
      note: {
        name: {
          en: 'Note',
          km: 'លិខិតកំណត់បង្ហាញ',
        },
        manage: 'manage-roubavontbokkol-note',
        list: 'list-roubavontbokkol-note',
        show: 'show-roubavontbokkol-note',
        store: 'store-roubavontbokkol-note',
        edit: 'edit-roubavontbokkol-note',
        delete: 'delete-roubavontbokkol-note',
      },
      deikarbanhchoun: {
        name: {
          en: 'Deikarbanhchoun',
          km: 'ដីការបញ្ជូន',
        },
        manage: 'manage-roubavontbokkol-deikarbanhchoun',
        list: 'list-roubavontbokkol-deikarbanhchoun',
        show: 'show-roubavontbokkol-deikarbanhchoun',
        store: 'store-roubavontbokkol-deikarbanhchoun',
        edit: 'edit-roubavontbokkol-deikarbanhchoun',
        delete: 'delete-roubavontbokkol-deikarbanhchoun',
        approve: 'approve-roubavontbokkol-deikarbanhchoun',
      },
    },
    companyManagement: {
      name: {
        en: 'Company Management',
        km: 'ការគ្រប់គ្រងក្រុមហ៊ុន'
      },
      manage: 'manage-company-management',
      companyRegistration: {
        name: {
          en: 'Company Registration',
          km: 'ការចុះបញ្ជីក្រុមហ៊ុន',
        },
        manage: 'manage-company-registration',
        list: 'list-company-registration',
        show: 'show-company-registration',
        store: 'store-company-registration',
        edit: 'edit-company-registration',
        delete: 'delete-company-registration',
      },
      note: {
        name: {
          en: 'Note',
          km: 'លិខិតកំណត់បង្ហាញ',
        },
        manage: 'manage-company-note',
        list: 'list-company-note',
        show: 'show-company-note',
        store: 'store-company-note',
        edit: 'edit-company-note',
        delete: 'delete-company-note',
      },
      deikarbanhchoun: {
        name: {
          en: 'Deikarbanhchoun',
          km: 'ដីការបញ្ជូន',
        },
        manage: 'manage-company-deikarbanhchoun',
        list: 'list-company-deikarbanhchoun',
        show: 'show-company-deikarbanhchoun',
        store: 'store-company-deikarbanhchoun',
        edit: 'edit-company-deikarbanhchoun',
        delete: 'delete-company-deikarbanhchoun',
        approve: 'approve-company-deikarbanhchoun',
      },
      licenses: {
        name: {
          en: 'Licenses',
          km: 'Licenses',
        },
        manage: 'manage-company-licenses',
        list: 'list-company-licenses',
        show: 'show-company-licenses',
        store: 'store-company-licenses',
        edit: 'edit-company-licenses',
        delete: 'delete-company-licenses',
      },
      certificates: {
        name: {
          en: 'Certificates',
          km: 'Certificates',
        },
        manage: 'manage-company-certificates',
        list: 'list-company-certificates',
        show: 'show-company-certificates',
        store: 'store-company-certificates',
        edit: 'edit-company-certificates',
        delete: 'delete-company-certificates',
      },
    },
    setting: {
      name: {
        en: 'Settings',
        km: 'ការកំណត់'
      },
      manage: 'manage-setting',
      title: {
        name: {
          en: 'Title',
          km: 'ងារ',
        },
        manage: 'manage-title',
        list: 'list-title',
        show: 'show-title',
        store: 'store-title',
        edit: 'edit-title',
        delete: 'delete-title',
      },
      teamCategory: {
        name: {
          en: 'Team Category',
          km: 'ប្រភេទក្រុមមន្រ្តី',
        },
        manage: 'manage-team-category',
        list: 'list-team-category',
        show: 'show-team-category',
        store: 'store-team-category',
        edit: 'edit-team-category',
        delete: 'delete-team-category',
      },
      carbonCopy: {
        name: {
          en: 'Carbon Copy',
          km: 'ចម្លងជូន'
        },
        manage: 'manage-carbon-copy',
        list: 'list-carbon-copy',
        show: 'show-carbon-copy',
        store: 'store-carbon-copy',
        edit: 'edit-carbon-copy',
        delete: 'delete-carbon-copy',
      },
      constructionLetterCommand: {
        name: {
          en: 'Construction Letter Command',
          km: 'យោលបល់ការិយាល័យជំនាញ'
        },
        manage: 'manage-construction-letter-command',
        list: 'list-construction-letter-command',
        show: 'show-construction-letter-command',
        store: 'store-construction-letter-command',
        edit: 'edit-construction-letter-command',
        delete: 'delete-construction-letter-command',
      },
      announcementReference: {
        name: {
          en: 'Announcement Reference',
          km: 'ឯកសារយោង'
        },
        manage: 'manage-announcement-reference',
        list: 'list-announcement-reference',
        show: 'show-announcement-reference',
        store: 'store-announcement-reference',
        edit: 'edit-announcement-reference',
        delete: 'delete-announcement-reference',
      },
      ministryPermitOrLicense: {
        name: {
          en: 'Ministry Permit or License',
          km: 'ការអនុញ្ញាតពីក្រសួងរឺអាជ្ញាប័ណ្ណ',
        },
        manage: 'manage-ministry-permit-or-license',
        list: 'list-ministry-permit-or-license',
        show: 'show-ministry-permit-or-license',
        store: 'store-ministry-permit-or-license',
        edit: 'edit-ministry-permit-or-license',
        delete: 'delete-ministry-permit-or-license',
      },
      missionType: {
        name: {
          en: 'Mission Type',
          km: 'ប្រភេទបេសកម្ម',
        },
        manage: 'manage-mission-type',
        list: 'list-mission-type',
        show: 'show-mission-type',
        store: 'store-mission-type',
        edit: 'edit-mission-type',
        delete: 'delete-mission-type',
      },
      university: {
        name: {
          en: 'University',
          km: 'សាកលវិទ្យាល័យ',
        },
        manage: 'manage-university',
        list: 'list-university',
        show: 'show-university',
        store: 'store-university',
        edit: 'edit-university',
        delete: 'delete-university',
      },
      landUniqueNumberType: {
        name: {
          en: 'Land Unique Number',
          km: 'ប្រភេទប័ណ្ណដី',
        },
        manage: 'manage-land-unique-number-type',
        list: 'list-land-unique-number-type',
        show: 'show-land-unique-number-type',
        store: 'store-land-unique-number-type',
        edit: 'edit-land-unique-number-type',
        delete: 'delete-land-unique-number-type',
      },
      insuranceCompany: {
        name: {
          en: 'Insurance Company',
          km: 'ក្រុមហ៊ុនធានារ៉ាប់រង',
        },
        manage: 'manage-insurance-company',
        list: 'list-insurance-company',
        show: 'show-insurance-company',
        store: 'store-insurance-company',
        edit: 'edit-insurance-company',
        delete: 'delete-insurance-company',
      },
      architectureType: {
        name: {
          en: 'Architecture Type',
          km: 'ប្រភេទវិស្វករ',
        },
        manage: 'manage-architecture-type',
        list: 'list-architecture-type',
        show: 'show-architecture-type',
        store: 'store-architecture-type',
        edit: 'edit-architecture-type',
        delete: 'delete-architecture-type',
      },
      companySection: {
        name: {
          en: 'Company Section',
          km: 'ផ្នែកក្រុមហ៊ុន',
        },
        manage: 'manage-company-section',
        list: 'list-company-section',
        show: 'show-company-section',
        store: 'store-company-section',
        edit: 'edit-company-section',
        delete: 'delete-company-section',
      },
      companyType: {
        name: {
          en: 'Company Type',
          km: 'ប្រភេទក្រុមហ៊ុន',
        },
        manage: 'manage-company-type',
        list: 'list-company-type',
        show: 'show-company-type',
        store: 'store-company-type',
        edit: 'edit-company-type',
        delete: 'delete-company-type',
      },
      country: {
        name: {
          en: 'Country',
          km: 'ប្រទេស',
        },
        manage: 'manage-country',
        list: 'list-country',
        show: 'show-country',
        store: 'store-country',
        edit: 'edit-country',
        delete: 'delete-country',
      },
      nationality: {
        name: {
          en: 'Nationality',
          km: 'សញ្ជាតិ',
        },
        manage: 'manage-nationality',
        list: 'list-nationality',
        show: 'show-nationality',
        store: 'store-nationality',
        edit: 'edit-nationality',
        delete: 'delete-nationality',
      },
      constructionType: {
        name: {
          en: 'Construction Type',
          km: 'ប្រភេទសំណង់',
        },
        manage: 'manage-construction-type',
        list: 'list-construction-type',
        show: 'show-construction-type',
        store: 'store-construction-type',
        edit: 'edit-construction-type',
        delete: 'delete-construction-type',
      },
      projectType: {
        name: {
          en: 'Project Type',
          km: 'ប្រភេទគម្រោង',
        },
        manage: 'manage-project-type',
        list: 'list-project-type',
        show: 'show-project-type',
        store: 'store-project-type',
        edit: 'edit-project-type',
        delete: 'delete-project-type',
      },
      projectSubType: {
        name: {
          en: 'Project Sub Type',
          km: 'អនុប្រភេទគម្រោង',
        },
        manage: 'manage-project-sub-type',
        list: 'list-project-sub-type',
        show: 'show-project-sub-type',
        store: 'store-project-sub-type',
        edit: 'edit-project-sub-type',
        delete: 'delete-project-sub-type',
      },
      projectUnit: {
        name: {
          en: 'Project Unit',
          km: 'ខ្នាតគម្រោង',
        },
        manage: 'manage-project-unit',
        list: 'list-project-unit',
        show: 'show-project-unit',
        store: 'store-project-unit',
        delete: 'delete-project-unit',
      },
      position: {
        name: {
          en: 'Position',
          km: 'មុខតំណែង',
        },
        manage: 'manage-position',
        list: 'list-position',
        show: 'show-position',
        store: 'store-position',
        edit: 'edit-position',
        delete: 'delete-position',
      },
      lawRegulationType: {
        name: {
          en: 'Law Regulation Type',
          km: 'ប្រភេទច្បាប់បទប្បញ្ញត្តិសំណង់',
        },
        manage: 'manage-law-regulation-type',
        list: 'list-law-regulation-type',
        show: 'show-law-regulation-type',
        store: 'store-law-regulation-type',
        edit: 'edit-law-regulation-type',
        delete: 'delete-law-regulation-type',
      },
      province: {
        name: {
          en: 'Province',
          km: 'ខេត្ត/ក្រុង',
        },
        manage: 'manage-province',
        list: 'list-province',
        show: 'show-province',
        store: 'store-province',
        edit: 'edit-province',
        delete: 'delete-province',
      },
      district: {
        name: {
          en: 'District',
          km: 'ស្រុក/ខណ្ឌ',
        },
        manage: 'manage-district',
        list: 'list-district',
        show: 'show-district',
        store: 'store-district',
        edit: 'edit-district',
        delete: 'delete-district',
      },
      commune: {
        name: {
          en: 'Commune',
          km: 'ឃុំ/សង្កាត់',
        },
        manage: 'manage-commune',
        list: 'list-commune',
        show: 'show-commune',
        store: 'store-commune',
        edit: 'edit-commune',
        delete: 'delete-commune',
      },
      village: {
        name: {
          en: 'Village',
          km: 'ភូមិ',
        },
        manage: 'manage-village',
        list: 'list-village',
        show: 'show-village',
        store: 'store-village',
        edit: 'edit-village',
        delete: 'delete-village',
      },
      gender: {
        name: {
          en: 'Gender',
          km: 'ភេទ'
        },
        manage: 'manage-gender',
        list: 'list-gender'
      },
      activityLogs: {
        name: {
          en: 'Activity Logs',
          km: 'Activity Logs',
        },
        manage: 'manage-activity-logs',
        list: 'list-activity-logs',
        show: 'show-activity-logs',
        store: 'store-activity-logs',
        edit: 'edit-activity-logs',
        delete: 'delete-activity-logs',
      },
    },
    administration: {
      name: {
        en: 'Administration',
        km: 'រដ្ឋបាល'
      },
      manage: 'manage-administration',
      user: {
        name: {
          en: 'User',
          km: 'អ្នកប្រើប្រាស់',
        },
        manage: 'manage-user',
        list: 'list-user',
        show: 'show-user',
        store: 'store-user',
        edit: 'edit-user',
        changePassword: 'change-user-password'
      },
      role: {
        name: {
          en: 'Role',
          km: 'តួនាទី'
        },
        manage: 'manage-role',
        list: 'list-role',
        show: 'show-role',
        store: 'store-role',
        edit: 'edit-role',
        delete: 'delete-role'
      },
      permission: {
        name: {
          en: 'Permission',
          km: 'សិទ្ធិ'
        },
        manage: 'manage-permission',
        list: 'list-permission'
      },
    },
  }
}

export const dependencies = {
  'show-user': [
    'list-user'
  ],
  'edit-user': [
    'show-user'
  ],
  'change-user-password': [
    'show-user'
  ],
  'delete-user': [
    'show-user'
  ],
  'edit-role': [
    'show-role'
  ]
}

export const naming = {
  approve: {
    en: 'Approve $$',
    km: 'អនុម័ត $$',
  },
  print: {
    en: 'Print $$',
    km: 'បោះពុម្ភ $$',
  },
  export: {
    en: 'Export $$',
    km: 'ទាញយក $$',
  },
  addOwner: {
    en: 'Add Owner $$',
    km: 'ម្ចាស់សំណង់ $$',
  },
  addRepresentative: {
    en: 'Add Representative $$',
    km: 'បន្ថែំមតំណាងអោយ$$',
  },
  addUnit: {
    en: 'Add Unit $$',
    km: 'បន្ថែមខ្នាត$$',
  },
  addCountry: {
    en: 'Add Country $$',
    km: 'បន្ថែមប្រទេស$$',
  },
  addCompany: {
    en: 'Add Company $$',
    km: 'បន្ថែមក្រុមហ៊ុន$$',
  },
  addArchitecture: {
    en: 'Add Architecture $$',
    km: 'បន្ថែមស្ថាបត្យករ$$',
  },
  addEngineer: {
    en: 'Add Engineer $$',
    km: 'បន្ថែមវិស្វករ$$',
  },
  addMinistryOther: {
    en: 'Add Ministry Other $$',
    km: 'បន្ថែមក្រសួងផ្សេងៗ$$',
  },
  addOpeningConstruction: {
    en: 'Add Opening Construction $$',
    km: 'បន្ថែមលខិតបើកការដ្ឋាន$$',
  },
  addStopConstruction: {
    en: 'Add Stop Construction $$',
    km: 'បន្ថែមលិខិតបិទការដ្ឋាន$$',
  },
  addPendingConstruction: {
    en: 'Add Pending Construction $$',
    km: 'បន្ថែមលិខិតផ្អាកការដ្ឋាន$$',
  },
  addProjectType: {
    en: 'Add Project Type $$',
    km: 'បន្ថែមប្រភេទគម្រោង$$',
  },
  addConstructionType: {
    en: 'Add Construction Type $$',
    km: 'បន្ថែមប្រភេទសំណង់$$',
  },
  storeProcessRequesting: {
    en: "Store Procession Requesting $$",
    km: "រក្សាទុកបិបថស្នើសុំ$$",
  },
  showProcessRequesting: {
    en: "Show Procession Requesting $$",
    km: "មើលបិបថស្នើរសុំ",
  },
  sync: {
    en: "Sync $$",
    km: "ធ្វើអោយដូចគ្នា$$",
  },
  showHide: {
    en: "Show/Hide $$",
    km: "បង្ហាញ/បិទ$$",
  },
  showColorTip: {
    en: "Show Color Tip $$",
    km: "អំពីពណ៌$$",
  },
  changeCategory: {
    en: "Change Category $$",
    km: "ប្តូរប្រភេទ$$",
  },
  filter: {
    en: 'Filter $$',
    km: 'តម្រង$$'
  },
  map: {
    en: 'Map $$',
    km: 'ផែនទី $$'
  },
  manage: {
    en: 'Manage $$',
    km: 'គ្រប់គ្រង$$'
  },
  list: {
    en: 'List $$',
    km: 'មើលបញ្ជី$$'
  },
  show: {
    en: 'View $$ Detail',
    km: 'បង្ហាញព័ត៌មានលម្អិត$$'
  },
  store: {
    en: 'Store $$',
    km: 'រក្សាទិន្នន័យ$$'
  },
  edit: {
    en: 'Edit $$',
    km: 'កែសម្រួលទិន្នន័យ$$'
  },
  delete: {
    en: 'Delete $$',
    km: 'លុបទិន្នន័យ$$'
  },
  changePassword: {
    en: 'Change $$\'s Password',
    km: 'កែពាក្យសម្ងាត់របស់$$'
  },
  toggle: {
    en: 'Toggle $$',
    km: 'បិទ ឬ បើក $$'
  },
  showHistory: {
    en: 'Show $$\'s History',
    km: 'បង្ហាញប្រវត្តិរបស់$$'
  },
  search: {
    en: 'Search $$',
    km: 'ស្វែងរក$$'
  },
  makeVisibility: {
    en: 'Change $$ Visibility',
    km: 'កែប្រែភាពមើលឃើញនៃ$$'
  },
  sendEmail: {
    en: 'Send E-mail about $$',
    km: 'ផ្ញើអ៊ីមែលអំពី$$'
  },
  activate: {
    en: 'Activate $$',
    km: 'បើកឲ្យដំណើរការ$$'
  },
  archive: {
    en: 'Archive $$',
    km: 'ទុក$$នៅមួយឡែក'
  },
  unarchive: {
    en: 'Unarchive $$',
    km: 'យក$$ទៅទុកវិញ'
  },
  add: {
    en: 'Add $$',
    km: 'បន្ថែម$$'
  },
  remove: {
    en: 'Remove $$',
    km: 'ដកចេញ$$'
  },
  undraft: {
    en: 'Undraft $$',
    km: 'រក្សាទុក$$ពិតប្រាកដ'
  },
  process: {
    en: 'Process $$',
    km: 'ដំណើរការ$$'
  },
  upload: {
    en: 'Upload $$',
    km: 'ផ្ទុកឯកសារ$$'
  },
  listAll: {
    en: 'List all $$',
    km: 'មើលទំាងអស់$$'
  }
}

export const generatePhpArray = () => {
  // Permissions convert to PHP array
  let php = ''
  let obj

  const tabs = (count = 1) => {
    let t = ""
    for (let i = 0; i < count; i++) {
      t += "\t"
    }
    return t
  }

  const recurse = (permission, tab = 1) => {
    const keyOrder = []
    for (const key in permission) {
      if (typeof permission[key] === 'object' && key !== 'name') {
        keyOrder.unshift(key)
      } else if (!['manage', 'name'].includes(key)) {
        keyOrder.push(key)
      }
    }
    for (const key of keyOrder) {
      if (typeof permission[key] === 'object' && key !== 'name') {
        obj = permission[key]
        php += tabs(tab) + '[\n'
        php += tabs(tab + 1) + `'name' => '${permission[key].manage}',\n`
        php += tabs(tab + 1) + `'display_name_en' => '${naming.manage.en.replace("$$", permission[key].name.en).replace('\'', '\\\'')}',\n`
        php += tabs(tab + 1) + `'display_name_km' => '${naming.manage.km.replace("$$", permission[key].name.km).replace('\'', '\\\'')}',\n`
        php += tabs(tab + 1) + `'children' => [\n`
        recurse(permission[key], tab + 2)
        php += tabs(tab + 1) + `],\n`
        if (dependencies[permission[key].manage]) {
          php += tabs(tab + 1) + `'dependencies' => [\n`
          php += dependencies[permission[key].manage].map(dep => tabs(tab + 2) + `'${dep}',\n`)
          php += tabs(tab + 1) + `],\n`
        } else {
          php += tabs(tab + 1) + `'dependencies' => [],\n`
        }
        php += tabs(tab) + '],\n'
      } else if (!['manage', 'name'].includes(key)) {
        php += tabs(tab) + '[\n'
        php += tabs(tab + 1) + `'name' => '${obj[key]}',\n`
        php += tabs(tab + 1) + `'display_name_en' => '${naming[key].en.replace('$$', obj.name.en).replace('\'', '\\\'')}',\n`
        php += tabs(tab + 1) + `'display_name_km' => '${naming[key].km.replace('$$', obj.name.km).replace('\'', '\\\'')}',\n`
        php += tabs(tab + 1) + `'children' => [],\n`
        if (dependencies[obj[key]]) {
          php += tabs(tab + 1) + `'dependencies' => [\n`
          php += dependencies[obj[key]].map(dep => tabs(tab + 2) + `'${dep}',\n`)
          php += tabs(tab + 1) + `],\n`
        } else {
          php += tabs(tab + 1) + `'dependencies' => [],\n`
        }
        php += tabs(tab) + '],\n'
      }
    }
  }

  php += '$permissions = [\n'
  recurse(Permissions)
  php += '];'
  return php
}

export const copyPermission = () => {
  const textArea = document.createElement("textarea")
  textArea.value = generatePhpArray()
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  document.execCommand('copy')
  document.body.removeChild(textArea)
}

export default Permissions
