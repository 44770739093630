<template>
  <people-form
    :button="buttonProperties"
    :mode-edit="true"
    :title="$t('string.editPersonality')"
    people-category-id="1"
  />
</template>

<script>
import PeopleForm from "../../people/Form"

export default {
  name: "Edit",
  components: {PeopleForm},
  metaInfo() {
    return {
      title: this.$t('string.editPersonality'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  computed: {
    buttonProperties() {
      return {
        label: this.$t('button.update'),
        btnClass: 'info',
        handle: 'edit'
      }
    }
  }
}
</script>

<style scoped>

</style>
