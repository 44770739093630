<template>
  <div>
    <template slot="noOptions">
      {{ $t('string.noOptions') }}
    </template>
    <template slot="noResult">
      {{ $t('string.noResult') }}
    </template>
    <template v-if="arrayObjects && Array.isArray(arrayObjects) && arrayObjects.length>0">
      <template
        v-if="hasLimit"
        slot="limit"
      >
        <strong class="multiselect__strong">
          {{ $t('string.andMore', {total: parseFloat((arrayObjects.length).toString())-1}) }}
        </strong>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "VueMultipleSelectOption",
  props: {
    arrayObjects: {
      type: Array,
      required: true,
    },
    hasLimit: {
      type: Boolean,
      default: true,
    }
  }
}
</script>

<style scoped>

</style>
