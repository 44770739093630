<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.previewPdfFile') }}
      </h3>
    </div>
    <div class="box-body">
      <iframe
        :src="`${baseUrl}/${path}`"
        allowfullscreen="true"
        style="width: 100%;height: 100vh;overflow-y: scroll; border:none;"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "PreviewPdf",
  metaInfo() {
    return {
      title: this.$t('string.previewPdfFile'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      path: '',
    }
  },
  mounted() {
    this.path = this.$route.params.path
  }
}
</script>

<style scoped>
</style>
