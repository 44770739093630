<template>
  <div
    class="col-lg-3 col-xs-6"
    style="padding-bottom: 15px"
  >
    <div
      class="card small-box"
      :class="bg"
    >
      <div class="content">
        <div class="row">
          <div :class="valueOnRight ? 'col-md-12' : 'col-md-12'">
            <div
              style="color: white; padding: 10px; font-size: 20px; width: 100%; font-family: var(--secondary-font)"
            >
              {{ title }}
              <span style="font-size: 13px; width: 100%">
                {{ subtitle }}
              </span>
            </div>
          </div>
          <template v-if="valueOnRight">
            <div
              class="col-md-12"
              style="text-align: left;"
            >
              <template v-if="typeof value === 'object' && value.length > 0">
                <template v-for="(item, key) in value">
                  <div
                    :key="key"
                    style="color: white; padding: 0 10px 5px; font-size: 15px; font-family: var(--secondary-font)"
                  >
                    {{ item.label }} : <span v-html="item.value" />
                  </div>
                </template>
              </template>
            </div>
          </template>
        </div>
        <template v-if="!valueOnRight">
          <div class="row">
            <div class="col-md-12">
              <div
                style="color: white; padding: 10px; font-size: 25px; width: 100%"
              >
                <template v-if="typeof value !== 'object'">
                  {{ value }}
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
      <router-link
        class="small-box-footer"
        :to="route"
        style="position: absolute; bottom: 0; left: 0; right: 0"
      >
        {{ $t('string.moreInfo') }} <i class="fa fa-arrow-circle-right" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "SmallBox",
  props: {
    bg: {
      type: String,
      default: 'aqua'
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    value: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Object | Number | String,
      required: true
    },
    icon: {
      type: String,
      default: 'user',
    },
    route: {
      type: Object,
      required: true,
    },
    valueOnRight: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
  .card {
    position: relative;
    margin-right: 20px;
  }

  .card, .card::after, .card::before {
    height: 175px;
    width: 100%;
    border-radius: 10px;
  }

  .content {
    width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .card::after {
    background-size: cover;
    background-position-x: 0;
    background-position-y: 0;
    opacity: 0.4;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  .card::before {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }

  .card > .content {
    z-index: 1;
  }

  .card-1::after {
    background-image: url('./../../public/img/test1.png');
  }

  .card-1::before {
    background-color: rgb(49, 49, 161);
  }

  .card-2::after {
    background-image: url('./../../public/img/test2.png');
  }

  .card-2::before {
    background-color: rgb(49, 161, 64);
  }

  .card-3::after {
    background-image: url('./../../public/img/test3.png');
  }

  .card-3::before {
    background-color: rgb(161, 99, 49);
  }

  .card-4::after {
    background-image: url('./../../public/img/test10.png');
  }

  .card-4::before {
    background-color: rgb(161, 49, 58);
  }

  .card-5::after {
    background-image: url('./../../public/img/test5.png');
  }

  .card-5::before {
    background-color: rgb(161, 153, 49);
  }

  .card-7::after {
    background-image: url('./../../public/img/test7.png');
  }

  .card-7::before {
      background-color: rgba(121, 118, 98, 0.9);
  }

  .card-8::after {
    background-image: url('./../../public/img/test8.png');
  }

  .card-8::before {
    background-color: rgb(86, 142, 154);
  }

  .card-6::after {
    background-image: url('./../../public/img/test9.png');
  }

  .card-6::before {
    background-color: rgb(60, 141, 188);
  }
</style>
