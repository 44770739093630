import ArchitectureType from "./../../views/settings/insuranceCompany/Index"
import List from "./../../views/settings/insuranceCompany/List"
import Create from "./../../views/settings/insuranceCompany/Create"
import Edit from "./../../views/settings/insuranceCompany/Edit"
import Show from "./../../views/settings/insuranceCompany/Show"
import Permissions from "../../permissions"

export const insuranceCompanyRouters = {
  path: 'insurance-company',
  component: ArchitectureType,
  redirect: 'insurance-company',
  meta: {
    permissions: [Permissions.backend.setting.insuranceCompany.manage],
    groups: 'insurance-company',
  },
  children: [
    {
      path: '',
      name: 'list-insurance-company',
      component: List,
      meta: {
        permissions: [Permissions.backend.setting.insuranceCompany.list],
        groups: 'insurance-company',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Insurance Company',
            name_km: 'ក្រុមហ៊ុន​ធានារ៉ាប់រង'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-insurance-company',
      component: Create,
      meta: {
        permissions: [Permissions.backend.setting.insuranceCompany.store],
        groups: 'insurance-company',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Insurance Company',
            name_km: 'ក្រុមហ៊ុន​ធានារ៉ាប់រង'
          },
          {
            name_en: 'New',
            name_km: 'បង្កើតថ្មី'
          }
        ]
      }
    },
    {
      path: 'edit/:insurance_company_uuid',
      name: 'edit-insurance-company',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.setting.insuranceCompany.edit],
        groups: 'insurance-company',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Insurance Company',
            name_km: 'ក្រុមហ៊ុន​ធានារ៉ាប់រង'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'show/:insurance_company_uuid',
      name: 'show-insurance-company',
      component: Show,
      meta: {
        permissions: [Permissions.backend.setting.insuranceCompany.show],
        groups: 'insurance-company',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Insurance Company',
            name_km: 'ក្រុមហ៊ុន​ធានារ៉ាប់រង'
          },
          {
            name_en: 'View',
            name_km: 'មើល'
          }
        ]
      }
    }
  ]
}
