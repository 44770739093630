<template>
  <div class="form-horizontal">
    <div
      :class="{'has-error': validations.name_km}"
      class="form-group row"
    >
      <label class="control-label col-sm-3 required">{{ $t('label.nameInKm') }}</label>
      <div class="col-sm-9">
        <input
          v-model="province.name_km"
          :placeholder="$t('label.nameInKm')"
          class="form-control"
          type="text"
        >
        <template v-if="validations.name_km">
          <span class="label-error">{{ validations['name_km'][0] }}</span>
        </template>
      </div>
    </div>
    <div
      :class="{'has-error': validations.name_en}"
      class="form-group row"
    >
      <label class="control-label col-sm-3 required">{{ $t('label.nameInEn') }}</label>
      <div class="col-sm-9">
        <input
          v-model="province.name_en"
          :placeholder="$t('label.nameInEn')"
          class="form-control"
          type="text"
        >
        <template v-if="validations.name_en">
          <span class="label-error">{{ validations['name_en'][0] }}</span>
        </template>
      </div>
    </div>
    <div class="form-group row">
      <label class="control-label col-sm-3">{{ $t('label.certificate_occupancy_header_km') }}</label>
      <div class="col-sm-9">
        <input
          v-model="province.certificate_occupancy_header_km"
          :placeholder="$t('placeholder.certificate_occupancy_header_km')"
          class="form-control"
          type="text"
        >
      </div>
    </div>
    <div class="form-group row">
      <label class="control-label col-sm-3">{{ $t('label.certificate_occupancy_header_en') }}</label>
      <div class="col-sm-9">
        <input
          v-model="province.certificate_occupancy_header_en"
          :placeholder="$t('placeholder.certificate_occupancy_header_en')"
          class="form-control"
          type="text"
        >
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-9 col-sm-offset-3">
        <button
          class="btn btn-primary btn-sm margin-r-5"
          @click="store"
        >
          {{ $t('button.save') }}
        </button>
        <asora-back-button />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProvinceForm",
  data() {
    return {
      validations: {},
      province: {
        name_en: null,
        name_km: null,
        certificate_occupancy_header_km: null,
        certificate_occupancy_header_en: null
      },
    }
  },
  computed: {
    urlAction() {
      if (this.$route.name === 'create-province') {
        return `${process.env.VUE_APP_API}/api/backend/province/create`
      }
      return `${process.env.VUE_APP_API}/api/backend/province/update`
    },
  },
  mounted() {
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty('province_id')) {
      this.getProvince()
    }
  },
  methods: {
    store() {
      this.$isLoading(true)
      this.$axios.post(this.urlAction, this.province)
        .then(() => {
          this.showToastr()
          this.$router.back()
        })
        .catch((error) => {
          this.validations = null
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getProvince() {
      this.$axios.post(`${process.env.VUE_APP_API}/api/backend/province/show`, {
        id: this.$route.params.province_id,
      }).then(({data}) => {
        this.province = data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
