<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('label.newAnnouncement') }}
      </h3>
      <div class="box-tools">
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <announcement-form />
    </div>
  </div>
</template>

<script>
import AnnouncementForm from "./Form"

export default {
  name: "Create",
  metaInfo() {
    return {
      title: this.$t('label.newAnnouncement'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {AnnouncementForm}
}
</script>

<style scoped>

</style>
