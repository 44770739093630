<template>
  <div class="form-horizontal">
    <div
      :class="nameKmRequired.required ? ' has-error' : null"
      class="form-group row"
    >
      <label class="col-sm-3 control-label required">{{ $t('label.nameKm') }}</label>
      <div class="col-sm-9">
        <input
          v-model="missionType.name_km"
          :placeholder="$t('label.nameKm')"
          class="form-control"
          type="text"
        >
        <template v-if="nameKmRequired.required">
          <span class="label-error">{{ nameKmRequired.message }}</span>
        </template>
      </div>
    </div>

    <div
      :class="nameEnRequired.required ? ' has-error' : null"
      class="form-group row"
    >
      <label class="col-sm-3 control-label required">{{ $t('label.nameEn') }}</label>
      <div class="col-sm-9">
        <input
          v-model="missionType.name_en"
          :placeholder="$t('label.nameEn')"
          class="form-control"
          type="text"
        >
        <template v-if="nameEnRequired.required">
          <span class="label-error">{{ nameEnRequired.message }}</span>
        </template>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-9 col-sm-offset-3">
        <button
          class="btn btn-primary btn-sm"
          @click="store"
        >
          {{ $t('button.save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MissionTypeForm",
  data() {
    return {
      missionType: {},
      validations: null,
      projectTypes: [],
    }
  },
  computed: {
    nameKmRequired() {
      return this.getValidationColumn(this.validations, 'name_km')
    },
    nameEnRequired() {
      return this.getValidationColumn(this.validations, 'name_en')
    },
    urlAction() {
      // eslint-disable-next-line no-prototype-builtins
      return this.$route.params.hasOwnProperty('mission_type_uuid') ? 'update' : 'create'
    }
  },
  mounted() {
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty("mission_type_uuid")) {
      this.getMissionType()
    }
  },
  methods: {
    store() {
      this.$isLoading(true)
      if (this.missionType.uuid) {
        this.missionType.mission_type_uuid = this.missionType.uuid
      }
      this.$axios.post(`${process.env.VUE_APP_API}/api/backend/mission-type/${this.urlAction}`, this.missionType)
        .then(() => {
          this.showToastr()
          this.$router.push({name: 'list-mission-type'})
        })
        .catch((error) => {
          this.validations = null
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getMissionType() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/mission-type/show', {
        mission_type_uuid: this.$route.params.mission_type_uuid,
      }).then((res) => {
        this.missionType = res.data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-mission-type'})
      }).finally(() => {
        this.$isLoading(false)
      })
    },
  }
}
</script>

<style scoped>

</style>
