<template>
  <div class="row">
    <div class="col-md-4">
      <roubavontbokkols-information :roubavontbokkol="roubavontbokkol" />
    </div>
    <div class="col-md-8">
      <roubavontbokkols-tabs :architecture="roubavontbokkol" />
    </div>
  </div>
</template>

<script>
import RoubavontbokkolsInformation from "../_components/RoubavontbokkolsInformation"
import RoubavontbokkolsTabs from "../_components/RoubavontbokkolsTabs"

export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('string.roubavontbokkolsDetail'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  components: {
    RoubavontbokkolsTabs,
    RoubavontbokkolsInformation,
  },
  data() {
    return {
      roubavontbokkol: null,
    }
  },
  mounted() {
    this.getRoubavontbokkol()
  },
  methods: {
    getRoubavontbokkol() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/roubavontbokkols/show', {
        roubavonbokkol_uuid: this.$route.params.roubavontbokkols_uuid,
      }).then(({data}) => {
        this.roubavontbokkol = data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
