import LawRegulationType from "./../../views/settings/lawRegulationType/Index"
import List from "./../../views/settings/lawRegulationType/List"
import Create from "./../../views/settings/lawRegulationType/Create"
import Edit from "./../../views/settings/lawRegulationType/Edit"
import Show from "./../../views/settings/lawRegulationType/Show"
import Permissions from "../../permissions"

export const lawRegulationTypeRouters = {
  path: 'law-regulation-type',
  component: LawRegulationType,
  redirect: 'law-regulation-type',
  meta: {
    permissions: [Permissions.backend.setting.lawRegulationType.manage],
    groups: 'law-regulation-type',
  },
  children: [
    {
      path: '',
      name: 'list-law-regulation-type',
      component: List,
      meta: {
        permissions: [Permissions.backend.setting.lawRegulationType.list],
        groups: 'law-regulation-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Law Regulation Type',
            name_km: 'ប្រភេទច្បាប់បទប្បញ្ញត្តិសំណង់'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-law-regulation-type',
      component: Create,
      meta: {
        permissions: [Permissions.backend.setting.lawRegulationType.store],
        groups: 'law-regulation-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'New Law Regulation Type',
            name_km: 'ប្រភេទច្បាប់បទប្បញ្ញត្តិសំណង់ថ្មី'
          }
        ]
      }
    },
    {
      path: 'edit/:law_regulation_type_uuid',
      name: 'edit-law-regulation-type',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.setting.lawRegulationType.edit],
        groups: 'law-regulation-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Edit Law Regulation Type',
            name_km: 'កែប្រែប្រភេទច្បាប់បទប្បញ្ញត្តិសំណង់'
          }
        ]
      }
    },
    {
      path: 'show/:law_regulation_type_uuid',
      name: 'show-law-regulation-type',
      component: Show,
      meta: {
        permissions: [Permissions.backend.setting.lawRegulationType.show],
        groups: 'law-regulation-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Show Law Regulation Type',
            name_km: 'បង្ហាញព័ត៌មានប្រភេទច្បាប់បទប្បញ្ញត្តិសំណង់'
          }
        ]
      }
    }
  ]
}
