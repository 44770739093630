<template>
  <div class="btn-group">
    <button
      type="button"
      class="btn btn-primary btn-sm dropdown-toggle margin-right-5"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {{ $t('string.action') }} <span class="caret" />
    </button>
    <ul class="dropdown-menu">
      <li>
        <router-link
          :to="{
            name: 'first-registration-register-company',
            params: {
              company_uuid: companyUuid
            }
          }"
        >
          {{ $t('string.requesting_for_first_registration') }}
        </router-link>
      </li>
      <li>
        <router-link
          :to="{
            name: 'renew-license-register-company',
            params: {
              company_uuid: companyUuid
            }
          }"
        >
          {{ $t('string.requesting_renew_license') }}
        </router-link>
      </li>
      <li>
        <router-link
          :to="{
            name: 'up-downgrade-type-register-company',
            params: {
              company_uuid: companyUuid
            }
          }"
        >
          {{ $t('string.requesting_upgrade_type') }}
        </router-link>
      </li>
      <li>
        <router-link
          :to="{
            name: 'up-downgrade-type-register-company',
            params: {
              company_uuid: companyUuid
            }
          }"
        >
          {{ $t('string.requesting_downgrade_type') }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ButtonActions",
  computed: {
    companyUuid() {
      return this.$route.params.company_uuid
    }
  }
}
</script>

<style scoped>

</style>
