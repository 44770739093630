<template>
  <div class="row">
    <div class="col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('string.showMinistryOfLandOfficer') }}
          </h3>
          <div class="box-tools">
            <asora-back-button />
          </div>
        </div>
        <div class="box-body">
          <div
            v-if="province"
            class="form-horizontal"
          >
            <h4 class="main-title">
              សរុបទិន្នន័យសំណង់ {{ province['type_km'] }}{{ province['name_km'] }}
            </h4>
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>{{ $t('string.startDateConstructionData') }}</th>
                  <th>{{ $t('string.endDateConstructionData') }}</th>
                  <th>{{ $t('table.action') }}</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="listProvincialData && listProvincialData.length > 0">
                  <template v-for="(item, key) in listProvincialData">
                    <tr :key="key">
                      <td>{{ date_format_km_en(item.start_date, $i18n.locale) }}</td>
                      <td>{{ date_format_km_en(item.end_date, $i18n.locale) }}</td>
                      <td>
                        <button
                          class="btn btn-info btn-xs margin-right-5"
                          @click="getProvinceDataDetail(item.uuid)"
                        >
                          <i class="fa fa-search" />
                        </button>
                        <router-link
                          :to="{name: 'edit-construction-data', params: { construction_data_uuid: item.uuid}}"
                          class="btn btn-primary btn-xs margin-right-5"
                        >
                          <i class="fa fa-edit" />
                        </router-link>
                        <button
                          class="btn btn-danger btn-xs"
                          @click="deleteRecord(item.uuid)"
                        >
                          <i class="fa fa-trash" />
                        </button>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr>
                    <td
                      class="text-center"
                      colspan="3"
                    >
                      {{ $t('string.noResult') }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('label.projectType') }}សំណង់
          </h3>
        </div>
        <div class="box-body">
          <div class="form-horizontal">
            <table class="table table-striped table-bordered">
              <tbody>
                <template v-if="provincialDataDetail && provincialDataDetail.length > 0">
                  <template v-for="(item, key) in provincialDataDetail">
                    <tr :key="key">
                      <th
                        class="main-title"
                        colspan="2"
                      >
                        {{ item['name_' + $i18n.locale] }}
                      </th>
                    </tr>
                    <tr :key="key + 1 + Math.random()">
                      <td>{{ $t('label.has_rule') }}</td>
                      <td>{{ item.has_rule || '-' }}</td>
                    </tr>
                    <tr :key="key + 2 + Math.random()">
                      <td>{{ $t('label.no_rule') }}</td>
                      <td>{{ item.no_rule || '-' }}</td>
                    </tr>
                    <tr :key="key + 3 + Math.random()">
                      <td>{{ $t('label.nbBuildingOrHouse') }}</td>
                      <td>{{ item.number_building || '-' }}</td>
                    </tr>
                    <tr :key="key + 4 + Math.random()">
                      <td>{{ $t('table.totalFloorArea') }}</td>
                      <td>{{ item.area || '-' }}</td>
                    </tr>
                    <tr :key="key + 5 + Math.random()">
                      <td>{{ $t('label.ProjectPrice') }}</td>
                      <td>{{ item.investment_budget || '-' }}</td>
                    </tr>
                    <tr :key="key + 6 + Math.random()">
                      <td>{{ $t('label.nbWorkers') }}</td>
                      <td>{{ item.number_worker || '-' }}</td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr>
                    <td>
                      {{ $t('string.noResult') }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('menu.constructionProvinceData'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      province: null,
      listProvincialData: [],
      provincialDataDetail: []
    }
  },
  mounted() {
    if (this.$route.params.code) {
      this.getListOfProvincialData(this.$route.params.code)
    }
  },
  methods: {
    getListOfProvincialData(code) {
      this.$axios.post(this.$base_api + '/api/backend/project-data-provincial-level/list-provincial-data', {
        code: code
      }).then((res) => {
        this.listProvincialData = res.data.data.content
        this.province = res.data.data.province
      }).catch((error) => {
        this.onResponseError(error)
      })
    },
    getProvinceDataDetail(uuid) {
      this.$axios.post(this.$base_api + '/api/backend/project-data-provincial-level/get-provincial-data-detail', {
        project_provincial_level_uuid: uuid
      }).then((res) => {
        this.provincialDataDetail = res.data.data
      }).catch((error) => {
        this.onResponseError(error)
      })
    },
    deleteRecord(uuid) {
      let self = this
      this.$swal.fire({
        title: self.i18nSwalTitle,
        text: self.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: self.i18nSwalNo,
        confirmButtonText: self.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-data-provincial-level/delete', {
            uuid: uuid
          }).then(() => {
            this.showSwalSuccess()
            this.provincialDataDetail = []
            this.getListOfProvincialData(this.$route.params.code)
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
