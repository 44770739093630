import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import location from './modules/location'
import project from './modules/project'
import media from './modules/media'
import constructionType from './modules/constructionType'
import company from './modules/company'
import architecture from './modules/architecture'
import building from './modules/building'
import collectProjectModal from './modules/collectProjectModal'
import options from './modules/options'
import imageOptionTab from "@/store/modules/imageOptionTab"
import certificateOfOccupancy from "@/store/modules/certificateOfOccupancy"
import general from "@/store/modules/general"
import activityLogs from "@/store/modules/activityLogs"
import constructionCertifierLicense from "@/store/modules/constructionCertifierLicense"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    location,
    project,
    media,
    constructionType,
    company,
    architecture,
    building,
    collectProjectModal,
    options,
    imageOptionTab,
    certificateOfOccupancy,
    general,
    activityLogs,
    constructionCertifierLicense
  }
})
