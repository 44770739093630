<template>
  <div
    id="approveConstructionLicenseModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="approveConstructionLicenseModalLabel"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div
      class="modal-dialog modal-lg"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            aria-label="Close"
            @click="onClose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4
            id="approveConstructionLicenseModalLabel"
            class="modal-title"
          >
            {{ $t('string.approve_construction_license') }}
          </h4>
        </div>
        <div class="modal-body">
          <div
            class="form-group"
            :class="{'has-error' : validations.certificate_number}"
          >
            <label class="control-label required">{{ $t('field.label.certificate_number_of_ccl') }}</label>
            <div>
              <input
                v-model="certifierLicense.certificate_number"
                type="text"
                class="form-control"
                :placeholder="$t('field.placeholder.certificate_number_of_ccl')"
              >
              <template v-if="validations.certificate_number">
                <FiledIsRequired :msg="validations['certificate_number'][0]" />
              </template>
            </div>
          </div>

          <div
            class="form-group"
            :class="{'has-error' : validations.approved_by}"
          >
            <label class="control-label required">{{ $t('field.label.approved_by') }}</label>
            <div>
              <input
                v-model="certifierLicense.approved_by"
                type="text"
                class="form-control"
                :placeholder="$t('field.placeholder.approved_by')"
              >
              <template v-if="validations.approved_by">
                <FiledIsRequired :msg="validations['approved_by'][0]" />
              </template>
            </div>
          </div>

          <div class="form-group">
            <label class="control-label">{{ $t('field.label.note') }}</label>
            <div>
              <textarea
                v-model="certifierLicense.note"
                type="text"
                class="form-control"
                :placeholder="$t('field.placeholder.note')"
                rows="5"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            @click="onClose"
          >
            {{ $t('button.close') }}
          </button>
          <button
            type="button"
            class="btn btn-success"
            @click="update"
          >
            {{ $t('button.approve') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields"
import FiledIsRequired from "@/components/FiledIsRequired"

export default {
  name: "ApproveConstructionLicenseModal",
  components: {FiledIsRequired},
  data () {
    return {
      certifierLicense: {
        certificate_number: null,
        approved_by: null,
        note: null
      },
      validations: {}
    }
  },
  computed: {
    ...mapFields('company', {
      approveLicenseUuid: 'constructionCertifier.approveLicenseUuid',
    })
  },
  beforeDestroy() {
    this.approveLicenseUuid = null
  },
  methods: {
    update () {
      this.validations = {}
      this.certifierLicense.construction_certifier_license_uuid = this.approveLicenseUuid
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/construction-certifier-license/approve', this.certifierLicense)
        .then(({data}) => {
          if (data.data) {
            this.showToastr()
            this.resetForm()
            $('#approveConstructionLicenseModal').modal('hide')
            this.$emit('refreshList')
          }
        })
        .catch((error) => {
          if (error.response && error.response.status && error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    resetForm () {
      this.approveLicenseUuid = null
      this.certifierLicense = {
        certificate_number: null,
        approved_by: null,
        note: null
      }
    },
    onClose () {
      this.resetForm()
      $('#approveConstructionLicenseModal').modal('hide')
    }
  }
}
</script>

<style scoped>

</style>
