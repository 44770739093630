<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ title }}
      </h3>
      <div class="box-tools">
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <div class="form-horizontal">
        <div
          :class="nameKmValidation.required?'has-error': null"
          class="form-group row"
        >
          <label class="col-sm-3 control-label required">{{ $t('label.nameKm') }}</label>
          <div class="col-sm-9">
            <input
              v-model="office.name_km"
              :placeholder="$t('label.nameKm')"
              class="form-control"
              type="text"
            >
            <template v-if="nameKmValidation.required">
              <span class="label-error">{{ nameKmValidation.message }}</span>
            </template>
          </div>
        </div>

        <div
          :class="nameEnValidation.required ? 'has-error' : null"
          class="form-group row"
        >
          <label class="col-sm-3 control-label required">{{ $t('label.nameEn') }}</label>
          <div class="col-sm-9">
            <input
              v-model="office.name_en"
              :placeholder="$t('label.nameEn')"
              class="form-control"
              type="text"
            >
            <template v-if="nameEnValidation.required">
              <span class="label-error">{{ nameEnValidation.message }}</span>
            </template>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 control-label required">{{ $t('label.code') }}</label>
          <div class="col-sm-9">
            <input
              v-model="office.code"
              :placeholder="$t('label.code')"
              class="form-control"
              type="text"
            >
            <template v-if="codeValidation.required">
              <span class="label-error">{{ codeValidation.message }}</span>
            </template>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.headDepartment') }}</label>
          <div class="col-sm-9">
            <div class="row">
              <div class="col-md-6">
                <div :class="head_department.invalid?' has-error':null">
                  <input
                    v-model="head_department.name"
                    :placeholder="$t('string.name')"
                    class="form-control"
                    type="text"
                  >
                  <template v-if="head_department.invalid">
                    <span class="label-error">{{ $t('string.theFieldIsRequired') }}</span>
                  </template>
                </div>
              </div>
              <div class="col-md-6">
                <input
                  v-model="head_department.phone"
                  :placeholder="$t('string.phone')"
                  class="form-control"
                  type="text"
                >
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.voicePresidentDepartment') }}</label>
          <div class="col-sm-9">
            <div class="row">
              <div class="col-md-12 margin-bottom-10">
                <button
                  class="btn btn-primary"
                  @click="voice_presidents.push({name: '', phone: '', invalid: false,})"
                >
                  <i class="fa fa-plus" /> {{ $t('string.add') }}
                </button>
              </div>
              <template v-if="voice_presidents.length>0">
                <div
                  v-for="(staff, key) in voice_presidents"
                  :key="key"
                  class="col-md-12 margin-bottom-5"
                >
                  <div :class="staff.invalid">
                    <div class="input-group">
                      <input
                        v-model="staff.name"
                        :class="staff.invalid ? 'border-red':null"
                        :placeholder="$t('string.name')"
                        class="form-control"
                        type="text"
                      >
                      <span class="input-group-addon input-no-space" />
                      <input
                        v-model="staff.phone"
                        :placeholder="$t('string.phone')"
                        class="form-control"
                        type="text"
                      >
                      <div class="input-group-btn">
                        <button
                          class="btn btn-danger"
                          @click="voice_presidents.splice(voice_presidents.indexOf(staff), 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </button>
                      </div>
                    </div>
                    <template v-if="staff.invalid">
                      <span class="label-error">{{ $t('string.theFieldIsRequired') }}</span>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.headOffice') }}</label>
          <div class="col-sm-9">
            <div class="row">
              <div class="col-md-12 margin-bottom-10">
                <button
                  class="btn btn-primary"
                  @click="head_offices.push({name: '', phone: '', invalid: false,})"
                >
                  <i class="fa fa-plus" /> {{ $t('string.add') }}
                </button>
              </div>
              <template v-if="head_offices.length>0">
                <div
                  v-for="(staff, key) in head_offices"
                  :key="key"
                  class="col-md-12 margin-bottom-5"
                >
                  <div :class="staff.invalid">
                    <div class="input-group">
                      <input
                        v-model="staff.name"
                        :class="staff.invalid ? 'border-red':null"
                        :placeholder="$t('string.name')"
                        class="form-control"
                        type="text"
                      >
                      <span class="input-group-addon input-no-space" />
                      <input
                        v-model="staff.phone"
                        :placeholder="$t('string.phone')"
                        class="form-control"
                        type="text"
                      >
                      <div class="input-group-btn">
                        <button
                          class="btn btn-danger"
                          @click="head_offices.splice(head_offices.indexOf(staff), 1)"
                        >
                          <i class="fas fa-trash-alt" />
                        </button>
                      </div>
                    </div>
                    <template v-if="staff.invalid">
                      <span class="label-error">{{ $t('string.theFieldIsRequired') }}</span>
                    </template>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <location-input />

        <div class="form-group row">
          <div class="col-sm-3 control-label">
            {{ $t('label.lat') }}
          </div>
          <div class="col-sm-9">
            <input
              v-model="office.lat"
              :placeholder="$t('label.lat')"
              class="form-control"
              type="number"
            >
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-3 control-label">
            {{ $t('label.lng') }}
          </div>
          <div class="col-sm-9">
            <input
              v-model="office.lng"
              :placeholder="$t('label.lng')"
              class="form-control"
              type="number"
            >
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.map') }}</label>
          <div class="col-sm-9">
            <div class="thumbnail no-margin-bottom">
              <GmapMap
                :center="{lat:office.lat, lng:office.lng}"
                :zoom="15"
                map-type-id="terrain"
                style="width: 100%; height: 430px"
              >
                <GmapMarker
                  :clickable="true"
                  :draggable="true"
                  :icon="{url: '/img/markers/blue20x20.png'}"
                  :position="{lat:office.lat, lng:office.lng}"
                  @dragend="movePinOnGoogleMap"
                />
              </GmapMap>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-9 col-sm-offset-3">
            <template v-if="button.handle==='create'">
              <button
                :class="`btn btn-${button.btnClass} btn-sm`"
                @click="storeOffice"
              >
                {{ button.label }}
              </button>
            </template>
            <template v-else>
              <button
                :class="`btn btn-${button.btnClass} btn-sm`"
                @click="storeOffice"
              >
                {{ button.label }}
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from 'vuex'
import LocationInput from "../../components/LocationInput"

export default {
  name: "OfficeFrom",
  components: {
    LocationInput,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    button: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      office: {
        lat: 11.576310,
        lng: 104.923104,
      },
      validations: null,
      head_department: {
        name: '',
        phone: '',
        invalid: false,
      },
      voice_presidents: [
        {name: '', phone: '', invalid: false,}
      ],
      head_offices: [
        {name: '', phone: '', invalid: false,}
      ]
    }
  },
  computed: {
    nameEnValidation() {
      return this.getValidationColumn(this.validations, 'name_en')
    },
    nameKmValidation() {
      return this.getValidationColumn(this.validations, 'name_km')
    },
    codeValidation() {
      return this.getValidationColumn(this.validations, 'code')
    },
    ...mapState({
      address: state => state.location.address
    }),
    urlAction() {
      return this.$route.name === 'edit-office' ? 'update' : 'create'
    }
  },
  created() {
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty('office_uuid')) {
      this.getOffice()
    }
  },
  methods: {
    movePinOnGoogleMap(e) {
      let latLng = e.latLng
      this.office.lat = parseFloat(parseFloat(latLng.lat()).toFixed(6))
      this.office.lng = parseFloat(parseFloat(latLng.lng()).toFixed(6))
    },
    checkValidation(data, column) {
      if (data.length > 0) {
        return data.map((item) => {
          if (item[column] === '') {
            item.invalid = true
          } else {
            item.invalid = false
          }
          return item
        })
      } else {
        if (data[column] === '') {
          data.invalid = true
        } else {
          data.invalid = false
        }
        return data
      }
    },
    checkFormValidation() {
      let headDept = this.head_department.name === ''
      let voiceDept = this.voice_presidents.some(item => item.name === '')
      let headOffice = this.head_offices.some(item => item.name === '')
      if (headDept) return true
      if (voiceDept) return true
      if (headOffice) return true
      return false
    },
    storeOffice() {
      for (let key in this.address) {
        if (this.address[key] !== '') {
          this.office[key] = this.address[key]
        }
      }
      if (this.office.uuid) {
        this.office.office_uuid = this.office.uuid
      }
      this.head_department = this.checkValidation(this.head_department, 'name')
      this.voice_presidents = this.checkValidation(this.voice_presidents, 'name')
      this.head_offices = this.checkValidation(this.head_offices, 'name')
      if (this.checkFormValidation()) return 0

      this.office.head_department = this.head_department
      if (this.voice_presidents.length > 0) {
        this.office.voice_presidents = this.voice_presidents
      }
      if (this.head_offices.length > 0) {
        this.office.head_offices = this.head_offices
      }
      this.$isLoading(true)
      this.$axios.post(`${process.env.VUE_APP_API}/api/backend/office/${this.urlAction}`, this.office)
        .then(() => {
          this.showToastr()
          this.$router.push({name: 'list-office'})
        })
        .catch((error) => {
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
          } else {
            this.validations = null
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getOffice() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/office/show', {
        office_uuid: this.$route.params.office_uuid,
      }).then((res) => {
        let office = res.data.data

        if (office.head_department.length > 0) {
          let head_department = office.head_department[0]
          this.head_department = {
            name: head_department.name,
            phone: head_department.phone,
            invalid: false,
          }
        }
        delete office.head_department

        if (office.head_offices.length > 0) {
          this.head_offices = office.head_offices.map((item) => {
            return {
              name: item.name,
              phone: item.phone,
              invalid: false,
            }
          })
        } else {
          this.head_offices = []
        }
        delete office.head_offices

        if (office.voice_president_departments.length > 0) {
          this.voice_presidents = office.voice_president_departments.map((item) => {
            return {
              name: item.name,
              phone: item.phone,
              invalid: false,
            }
          })
        } else {
          this.voice_presidents = []
        }
        delete office.voice_president_departments

        this.office = office
        this.$store.commit('location/setAddress', {
          province_id: this.office.province_id,
          district_id: this.office.district_id,
          commune_id: this.office.commune_id,
          village_id: this.office.village_id,
          home_number: this.office.home_number,
          street_number: this.office.street_number,
          reload: true,
        })
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-office'})
      })
    }
  }
}
</script>

<style scoped>
.border-red {
  border: 1px red solid;
}
</style>
