<template>
  <div class="row">
    <div class="col-md-3">
      <div class="box box-primary">
        <div class="box-body box-profile">
          <template v-if="user.avatar">
            <img
              class="profile-user-img img-responsive img-circle"
              :src="`${apiUrl}/${user.avatar}`"
              alt="User profile picture"
            >
          </template>
          <template v-else>
            <img
              class="profile-user-img img-responsive img-circle"
              src="/images/avatar/avatar.png"
              alt="User profile picture"
            >
          </template>
          <h3 class="profile-username text-center">
            {{ user.full_name }}
          </h3>
          <ul class="list-group list-group-unbordered">
            <li class="list-group-item">
              <strong>
                <i class="fas fa-map-marker-alt margin-r-5" />
                {{ $t('label.location') }}
              </strong>
              <p class="text-muted">
                {{ user.address }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-9">
      <router-view />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'Layout',
  data() {
    return {
      apiUrl: process.env.VUE_APP_API
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.data
    })
  }
}
</script>

<style scoped>

</style>
