<template>
  <div class="row">
    <div class="col-md-4">
      <div class="row">
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">
                {{ $t('string.showDeikarbanhchoun') }}
              </h3>
              <div class="box-tools">
                <template v-if="deikarbanhchoun && deikarbanhchoun.approved_at === null">
                  <router-link
                    :to="{
                      name: 'edit-deikarbanhchouns-company',
                      params: {
                        deikarbanhchoun_uuid: deikarbanhchoun.uuid,
                      }
                    }"
                    class="btn btn-primary btn-sm margin-r-5"
                  >
                    <i class="fa fa-edit" />
                  </router-link>
                </template>
                <asora-back-button />
              </div>
            </div>
            <div class="box-body">
              <template v-if="deikarbanhchoun">
                <table class="table table-striped table-bordered">
                  <tbody>
                    <tr v-if="deikarbanhchoun.company">
                      <td>{{ $t('string.company') }}</td>
                      <td>
                        <a href="">{{ deikarbanhchoun.company['name_' + $i18n.locale] }}</a>
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('string.issuedNumber') }}</td>
                      <td>{{ getNumbers(deikarbanhchoun.issued_number) || $t('string.na') }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('string.issuedDate') }}</td>
                      <td>{{ getNumbers(getDate(deikarbanhchoun.issued_date, false, 'DD/MM/YYYY')) }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('string.approvedBy') }}</td>
                      <td>{{ deikarbanhchoun.approved_by || $t('string.na') }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('string.approvedAt') }}</td>
                      <td>{{ getNumbers(getDate(deikarbanhchoun.approved_at, false, 'DD/MM/YYYY')) }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('string.note') }}</td>
                      <td>{{ deikarbanhchoun.note || $t('string.na') }}</td>
                    </tr>
                  </tbody>
                </table>
              </template>
              <template v-else>
                <p class="text-center">
                  {{ $t('string.no_result') }}
                </p>
              </template>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">
                {{ $t('string.references') }}
              </h3>
            </div>
            <div
              v-if="deikarbanhchoun"
              class="box-body"
            >
              <div class="row">
                <div class="col-md-12">
                  <template v-if="company_deikarbanhchoun_items">
                    <ul>
                      <li
                        v-for="(item, key) in company_deikarbanhchoun_items"
                        :key="key"
                      >
                        {{ item.value }}
                      </li>
                    </ul>
                  </template>
                  <template v-else>
                    <p class="text-muted text-center">
                      {{ $t('string.no_deikarbanhchoun_reference') }}
                    </p>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('string.letter') }}
          </h3>
        </div>
        <div
          v-if="deikarbanhchoun"
          class="box-body"
        >
          <div class="row">
            <div class="col-md-12">
              <div class="pull-right">
                <button
                  class="btn btn-default margin-bottom"
                  @click="openUrl"
                >
                  <i class="fa fa-print" /> {{ $t('label.print') }}
                </button>
              </div>
            </div>
          </div>
          <smart-iframe
            :src="`${baseUrl}/certificates/company/deikarbanhchoun/${deikarbanhchoun.uuid}`"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmartIframe from "@/components/SmartIframe"

export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('string.showDeikarbanhchoun'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  components: {SmartIframe},
  data() {
    return {
      deikarbanhchoun: null,
    }
  },
  computed: {
    api() {
      return `${this.baseUrl}/api/backend/company/deikarbanhchouns`
    },
    company_deikarbanhchoun_items() {
      if (
        this.deikarbanhchoun &&
        this.deikarbanhchoun.company_deikarbanhchoun_items &&
        Array.isArray(this.deikarbanhchoun.company_deikarbanhchoun_items) &&
        this.deikarbanhchoun.company_deikarbanhchoun_items.length > 0
      ) {
        return this.deikarbanhchoun.company_deikarbanhchoun_items
      }
      return null
    }
  },
  mounted() {
    this.show()
  },
  methods: {
    openUrl() {
      window.open(`${this.baseUrl}/certificates/company/deikarbanhchoun/${this.deikarbanhchoun.uuid}`, '_blank')
    },
    async show() {
      let response = await this.$axios.post(`${this.api}/show`, {
        deikarbanhchoun_uuid: this.$route.params.deikarbanhchoun_uuid,
      })

      let deikarbanhchoun = await response.data.data
      if (deikarbanhchoun) {
        this.deikarbanhchoun = deikarbanhchoun
      }
    }
  }
}
</script>

<style scoped>

</style>
