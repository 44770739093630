<template>
  <div class="col-md-12">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t('string.regulationGapSection') }}
        </h3>
      </div>
      <div class="box-body">
        <template v-if="regulationGaps.length>0">
          <table class="table table-striped table-bordered table-condensed">
            <thead>
              <tr>
                <th>{{ $t('string.type') }}</th>
                <th>{{ $t('string.direction') }}</th>
                <th>{{ $t('string.law') }}</th>
                <th>{{ $t('string.request') }}</th>
                <th>{{ $t('string.practical') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(side, index) in ['front', 'back', 'left', 'right']"
                :key="index"
              >
                <td>{{ $t(`string.${side}`) }}</td>
                <td>{{ getDirectionLabel(regulationGaps[0][`direction_${side}_id`]) }}</td>
                <td>{{ getValue(regulationGaps[0][side]) }}</td>
                <td>{{ getValue(regulationGaps[1][side]) }}</td>
                <td>{{ getValue(regulationGaps[2][side]) }}</td>
              </tr>
            </tbody>
          </table>
        </template>
        <template v-else>
          <h3 class="text-center">
            {{ $t('string.na') }}
          </h3>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {optionsMixin} from "../../../mixins/options"

export default {
  name: "RegulationGap",
  mixins: [optionsMixin],
  props: {
    regulationGaps: {
      type: Array
    }
  },
  methods: {
    getValue(val) {
      if (val !== null) {
        return `${this.getNumbers(val)} ${this.$t('string.m')}`
      }
      return this.$t('string.na')
    }
  }
}
</script>

<style scoped>

</style>
