<template>
  <div class="row">
    <template v-if="!readonly">
      <div class="col-md-12 margin-bottom">
        <input
          ref="docs"
          type="file"
          style="display: none;"
          :accept="acceptFile"
          multiple
          @change="uploadImage"
        >

        <button
          class="btn btn-primary btn-md margin-r-5"
          @click="$refs.docs.click()"
        >
          <i :class="buttonIcon" /> {{ buttonLabel }}
        </button>
        <button
          v-if="buttonRefresh"
          class="btn btn-info btn-md margin-r-5"
          @click="getImages"
        >
          <i class="fas fa-refresh" /> {{ $t('string.refresh') }}
        </button>
        <template v-if="draggable">
          <button
            class="btn btn-success btn-md margin-r-5"
            @click="updateOrder(mediaImages)"
          >
            {{ $t('string.updateOrder') }}
          </button>
        </template>
      </div>
    </template>
    <div class="col-md-12">
      <template v-if="mediaImages.length > 0">
        <template v-if="draggable">
          <div class="row media-item-head">
            <div class="col-xs-9">
              {{ $t('label.filename') }}
            </div>
            <div class="col-xs-3 text-right">
              {{ $t('table.action') }}
            </div>
          </div>
          <draggable
            v-model="mediaImages"
            class="list-group"
            v-bind="dragOptions"
            @start="isDragging = true"
            @end="isDragging = false"
          >
            <transition-group
              type="transition"
              name="flip-list"
            >
              <template v-for="(doc, key) in mediaImages">
                <div
                  :key="key"
                  class="row media-item"
                >
                  <div class="col-xs-9">
                    <div>{{ doc.filename ? doc.filename : 'unknown.' + doc.mine_type }}</div>
                  </div>
                  <div class="col-xs-3 text-right">
                    <button
                      v-if="!readonly"
                      class="btn btn-danger btn-xs margin-r-5"
                      @click="deleteMedia(doc.uuid)"
                    >
                      <i class="fas fa-trash-alt" />
                    </button>
                    <button
                      class="btn btn-info btn-xs margin-r-5"
                      @click="preview(doc)"
                    >
                      <i class="fa fa-search" />
                    </button>
                    <a
                      :href="`${getSrc(doc.src)}`"
                      download
                      target="_blank"
                      class="btn btn-success btn-xs margin-r-5"
                    >
                      <i class="fa fa-download" />
                    </a>
                    <template v-if="copiable">
                      <button
                        class="btn btn-primary btn-xs"
                        @click="copyMedia(doc)"
                      >
                        <i class="far fa-copy" />
                      </button>
                    </template>
                  </div>
                </div>
              </template>
            </transition-group>
          </draggable>
        </template>
        <template v-else>
          <template v-for="(doc, key) in mediaImages">
            <upload-media-preview
              :key="key"
              :doc="doc"
              :readonly="readonly"
              :copiable="copiable"
              :copy-to-model-type="copyToModelType"
              :copy-to-model-path="copyToModelPath"
              :model-name="modelName"
              :model-uuid="modelUuid"
              :class-column="column"
              @fetchData="getImages"
            />
          </template>
        </template>
      </template>
      <template v-else>
        <div class="col-md-12">
          <p class="text-center">
            {{ $t('string.noResult') }}
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import UploadMediaPreview from "./UploadMediaPreview"

export default {
  name: "UploadMediaItemList",
  components: {
    UploadMediaPreview,
    draggable
  },
  props: {
    modelName: {
      type: String,
      required: true
    },
    modelUuid: {
      type: String,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    acceptFile: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: '10240',
    },
    column: {
      type: String,
      default: 'col-md-3 col-xs-6 margin-bottom'
    },
    draggable: {
      type: Boolean,
      default: false
    },
    buttonLabel: {
      type: String,
      default () {
        return this.$t('string.choose')
      }
    },
    buttonIcon: {
      type: String,
      default: 'fa fa-image'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    copiable: {
      type: Boolean,
      default: false
    },
    copyToModelType: {
      type: String,
      default: null
    },
    copyToModelPath: {
      type: String,
      default: null,
    },
    buttonRefresh: {
      type: Boolean,
      default: false,
    },
    buttonDeleteAll: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      mediaImages: [],
      validations: null,
      checkAll: null,
      checkedItems: [],
      isDragging: false
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    }
  },
  created() {
    this.getImages()
  },
  methods: {
    checkedItem(item) {
      if (item) {
        this.checkedItems.push(item)
      }
    },
    uploadImage() {
      let params = new FormData()
      if (this.$refs.docs.files.length > 0) {
        Array.from(this.$refs.docs.files).forEach((file, key) => {
          params.set(`document[${key}]`, file)
        })
      }
      params.set('model_uuid', this.modelUuid)
      params.set('model_name', this.modelName)
      params.set('path', this.path)
      params.set('type', this.type)
      params.set('size', this.size)
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/media/upload', params)
        .then((res) => {
          if (res.data.data) {
            this.showToastr()
            this.mediaImages = res.data.data
          } else {
            this.mediaImages = []
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
            this.$toastr('error', this.validations['document'][0], this.$t('string.error'))
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getImages() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/media/get-images-option', {
        type: this.type,
        model_uuid: this.modelUuid,
        model_name: this.modelName,
      }).then((res) => {
        if (res.data.data) {
          this.mediaImages = res.data.data
        } else {
          this.mediaImages = []
        }
      }).catch((error) => {
        this.onResponseError(error)
      })
    },
    updateOrder(media) {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/media/update-order-images', {
        media: media
      }).then(() => {
        this.showToastr()
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    deleteMedia(uuid) {
      this.$swal({
        title: this.i18nSwalTitle,
        text: this.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.i18nSwalNo,
        confirmButtonText: this.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/media/delete', {
            media_uuid: uuid
          }).then(() => {
            this.showSwalSuccess()
            this.$emit('fetchData')
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
    preview(doc) {
      if (doc.mine_type === 'pdf') {
        this.onClickOpenNewTab({
          name: 'preview-pdf',
          params: {
            path: doc.src
          }
        })
      } else {
        window.open(`${this.baseUrl}/${doc.src}`, '_blank')
      }
    },
    copyMedia(media) {
      this.$swal({
        title: this.$t('string.copyMedia'),
        text: this.$t('string.doYouWantToCopyMedia?'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('string.no'),
        confirmButtonText: this.$t('string.yesIAgree')
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/media/copy', {
            media_uuid: media.uuid,
            model_name: this.modelName,
            model_uuid: this.modelUuid,
            to_model_type: this.copyToModelType,
            to_model_path: this.copyToModelPath,
          }).then(() => {
            this.$swal({
              type: 'success',
              text: this.$t('string.theDocumentHaveBeenSuccessfullyCopied'),
              title: this.$t('string.copied'),
              confirmButtonText: this.i18nSwalYes
            })
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .file {
    width: 100%;
    position: relative;
    height: auto;
    border: 1px solid #0d6aad;
  }

  .file-header {
    background-color: #0d6aad;
    padding: 15px;
    color: #ffffff;
  }

  .file-footer {
    visibility: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35px;
    background-color: #dddddd;
    padding: 6px;
  }

  .file:hover .file-footer {
    visibility: visible;
  }

  .file-body {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .media-item-head,
  .media-item {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .media-item-head {
    font-weight: bold;
    background-color: #dddddd;
  }

  .media-item:hover {
    background-color: #f9f9f9;
    cursor: pointer;
  }
</style>
