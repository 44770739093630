<template>
  <div>
    <div class="col-xs-12">
      <div
        v-if="loadingMessage"
        class="spinner-container"
      >
        <spring-spinner
          class="mx-auto spinner-loading"
          :animation-duration="3000"
          :size="50"
          color="#3c8dbc"
        />
      </div>
      <iframe
        v-show="!loadingMessage"
        id="foo"
        name="generate"
        class="modal-template-height block-ifreme"
        :src="`${$base_api}/certificates/occupancy/preview/${model_uuid}`"
        @load="onLoad"
      />
    </div>
    <div
      v-show="!loadingMessage"
      class="col-md-12"
    >
      <button
        class="btn btn-default margin-right-5"
        @click="reloadIfreme()"
      >
        <i class="fas fa-redo-alt" />
        {{ $t('label.reloadPDF') }}
      </button>
    </div>
  </div>
</template>

<script>
import {SpringSpinner} from 'epic-spinners'
export default {
  name: "BlockIframe",
  components: {
    SpringSpinner
  },
  props: {
    model_uuid: {
      required: true
    }
  },
  data () {
    return {
      loadingMessage: true,
    }
  },
  methods: {
    onLoad() {
      this.loadingMessage = false
    },
    reloadIfreme() {
      this.loadingMessage = true
      document.getElementById('foo').src += ''
    },
  }
}
</script>

<style scoped>
  .modal-template-height {
    height: 865px;
  }

  .block-ifreme {
    width: 100%;
    border-radius: 0.5rem;
    border: none;
  }

  .spinner-container {
    width: 100%;
    height: 775px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
