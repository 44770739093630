<template>
  <div class="row">
    <div class="col-md-12">
      <ul
        v-if="inspections.length>0"
        class="timeline"
      >
        <li
          v-for="(inspection, key) in inspections"
          :key="key"
        >
          <i class="fa fa-check-circle bg-blue" />
          <div class="timeline-item">
            <span class="time"><i class="fa fa-clock-o" /> {{
              getDate(inspection.inspected_at, false, 'HH:ss A')
            }}</span>
            <h3 class="timeline-header">
              {{ getInspectionType(inspection.type) }}
            </h3>
            <div class="timeline-body">
              <div class="form-horizontal">
                <div class="form-group row">
                  <label class="col-xs-12 col-sm-4 control-label">{{ $t('label.inspectedAt') }}</label>
                  <div class="col-sm-8">
                    {{ getDate(inspection.inspected_at, false) }}
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-xs-12 col-sm-4 control-label">{{ $t('label.inspectedBy') }}</label>
                  <div class="col-sm-8">
                    <template v-if="inspection.inspector!==null">
                      <b>{{ inspection.inspector.first_name }} {{ inspection.inspector.last_name }}</b>
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-8 col-sm-offset-4">
                    <button
                      class="btn btn-primary btn-sm"
                      @click="showInspection(inspection.uuid)"
                    >
                      {{ $t('button.viewConstrictionInspectionReport') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <i class="fa fa-circle" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectInspectionTimeline",
  props: {
    inspections: {
      type: Array,
      required: true,
    }
  },
  computed: {
    inspectionTypes() {
      return [
        {
          key: 'before_approval',
          name_en: 'Controls to provide construction permit',
          name_km: 'ត្រួតពិនិត្យដើម្បីផ្ដល់លិខិតអនុញ្ញាតសាងសង់'
        },
        {
          key: 'after_approval',
          name_en: 'Controls for groundbreaking',
          name_km: 'ត្រួតពិនិត្យដើម្បីបើកការដ្ឋានសាងសង់'
        },
        {
          key: 'implementation',
          name_en: 'Construction Positioning border demarcation',
          name_km: 'ការបោះបង្គោលព្រំកំណត់ទីតាំងសាងសង់'
        },
        {key: 'following_up', name_en: 'Track construction', name_km: 'តាមដានដំណើរការសាងសង់'},
        {
          key: 'closing_construction',
          name_en: 'Construction inspection certificate',
          name_km: 'ត្រួតពិនិត្យផ្ដល់វិញ្ញាបនបត្រប្រើប្រាស់សំណង់'
        },
        {key: 'on_suing', name_en: 'Complaint Review', name_km: 'ត្រួតពិនិត្យពេលមានពាក្យបណ្ដឹង'},
      ]
    }
  },
  methods: {
    getInspectionType(type) {
      let value = null
      this.inspectionTypes.forEach((inspection) => {
        if (inspection.key === type) {
          value = inspection
        }
      })
      if (value !== null) {
        return value['name_' + this.$i18n.locale]
      }
      return this.$t('string.na')
    },
    showInspection(uuid) {
      window.open(`${this.$base_api}/template/preview/${uuid}`, '_blank')
    },
  }
}
</script>

<style scoped>
.control-label {
  padding: 0 !important;
}

.timeline-header {
  font-weight: bolder;
}
</style>
