<template>
  <div class="row">
    <div :class="mapContainerClass">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('label.map') }} <span>({{ totalProjects }} {{ $t('string.project') }})</span>
          </h3>
          <div class="box-tools">
            <div class="form-inline">
              <button
                class="btn btn-danger btn-sm"
                @click="showFilter = !showFilter"
              >
                <i class="fa fa-filter" /> {{ $t('string.filter') }}
              </button>
            </div>
          </div>
        </div>
        <div class="box-body">
          <div class="row">
            <div class="col-md-12">
              <div
                v-if="locations.length > 0"
                class="thumbnail"
              >
                <GmapMap
                  :center="center"
                  :zoom="8"
                  map-type-id="roadmap"
                  style="width: 100%; height: 80vh;"
                >
                  <GmapInfoWindow
                    :opened="infoWinOpen"
                    :options="infoOptions"
                    :position="infoWindowPos"
                    @closeclick="infoWinOpen=false"
                  />
                  <GmapCluster>
                    <GmapMarker
                      v-for="(m, i) in locations"
                      :key="i"
                      :clickable="true"
                      :icon="{url: '/img/markers/blue20x20.png'}"
                      :position="m"
                      @click="toggleInfoWindow(m,i)"
                    />
                  </GmapCluster>
                </GmapMap>
              </div>
              <template v-else>
                <p class="text-center text-muted">
                  {{ $t('string.noResultFound') }}
                </p>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="showFilter">
      <div class="col-md-2">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('string.filter') }}
            </h3>
            <div class="box-tools">
              <button
                class="btn btn-primary btn-sm margin-right-5"
                @click="getProjectLatLng"
              >
                <i class="fa fa-search" />
                {{ $t('string.apply') }}
              </button>
              <button
                class="btn btn-default btn-sm"
                @click="resetSearchOption"
              >
                <i class="fas fa-redo-alt" />
              </button>
            </div>
          </div>
          <div class="box-body">
            <div class="form-group">
              <label class="text-bold text-mlmupc-primary">{{ $t('table.is_published') }}</label>
              <select
                v-model="search.project_published"
                class="form-control"
                name="project_published"
              >
                <option
                  :value="null"
                  selected
                >
                  {{ $t('string.all') }}
                </option>
                <option :value="true">
                  {{ $t('table.is_published') }}
                </option>
                <option :value="false">
                  {{ $t('label.not_published') }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label class="text-bold text-mlmupc-primary">{{ $t('label.currentWorkYear') }}</label>
              <select
                v-model="search.current_work_year"
                class="form-control"
              >
                <option :value="null">
                  {{ $t('string.all') }}
                </option>
                <option
                  v-for="(year, key) in years"
                  :key="key"
                  :value="year"
                >
                  {{ year }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label class="text-bold text-mlmupc-primary">{{ $t('table.permitNumber') }}</label>
              <input
                v-model="search.permit_number"
                :placeholder="$t('table.permitNumber')"
                class="form-control"
                type="text"
              >
            </div>

            <div class="form-group">
              <label class="text-bold text-mlmupc-primary">{{ $t('label.permitYear') }}</label>
              <select
                v-model="search.year"
                class="form-control"
              >
                <option
                  disabled
                  selected
                  value="0"
                >
                  {{ $t('label.permitYear') }}
                </option>
                <option :value="-1">
                  {{ $t('string.all') }}
                </option>
                <option
                  v-for="(year, key) in years"
                  :key="key"
                  :value="year"
                >
                  {{ year }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label>{{ $t('table.permitDate') }}</label>
              <date-picker
                v-model="dateRange"
                :placeholder="$t('table.permitDate')"
                class="table-full-width"
                format="DD/MM/YYYY"
                range
                type="date"
              />
            </div>
            <div class="form-group">
              <label>{{ $t('table.projectType') }}</label>
              <select
                v-model="search.project_type_id"
                class="form-control"
              >
                <option
                  disabled
                  selected
                  value="0"
                >
                  {{ $t('string.projectType') }}
                </option>
                <option :value="-1">
                  {{ $t('string.all') }}
                </option>
                <option
                  v-for="(projectType, key) in projectTypes"
                  :key="key"
                  :value="projectType.id"
                >
                  {{ projectType['name_' + $i18n.locale] }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label>{{ $t('string.location') }}</label>
              <select
                v-model="search.province_id"
                class="form-control"
              >
                <option
                  disabled
                  selected
                  value="0"
                >
                  {{ $t('string.province') }}
                </option>
                <option :value="-1">
                  {{ $t('string.all') }}
                </option>
                <option
                  v-for="(province, key) in provinces"
                  :key="key"
                  :value="province.id"
                >
                  {{ province['name_' + $i18n.locale] }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>{{ $t('table.projectNameInKhmer') }}</label>
              <input
                v-model="search.project_name"
                :placeholder="$t('table.projectNameInKhmer')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.owners') }}</label>
              <input
                v-model="search.owner_name"
                :placeholder="$t('table.owners')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('label.projectSituation') }}</label>
              <select
                v-model="search.project_situation"
                class="form-control"
                name="project_situation"
              >
                <option
                  :value="null"
                  selected
                >
                  {{ $t('string.all') }}
                </option>
                <option value="new">
                  {{ $t('color_guideline.new') }}
                </option>
                <option value="permit">
                  {{ $t('color_guideline.permit') }}
                </option>
                <option value="open">
                  {{ $t('color_guideline.open') }}
                </option>
                <option value="stop">
                  {{ $t('color_guideline.stop') }}
                </option>
                <option value="pending">
                  {{ $t('color_guideline.pending') }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label>{{ $t('label.is_pushed') }}</label>
              <select
                v-model="search.project_pushed"
                class="form-control"
                name="project_pushed"
              >
                <option
                  :value="null"
                  selected
                >
                  {{ $t('string.all') }}
                </option>
                <option value="show">
                  {{ $t('label.show') }}
                </option>
                <option value="notShow">
                  {{ $t('label.notShow') }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>{{ $t('label.floor') }}</label>
              <vue-slider
                v-model="search.number_floor"
                :enable-cross="false"
                :max="200"
              />
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <button
                    class="btn btn-primary btn-block btn-sm"
                    @click="getProjectLatLng"
                  >
                    {{ $t('string.apply') }}
                  </button>
                </div>
                <div class="col-md-6">
                  <button
                    class="btn btn-default btn-block btn-sm"
                    @click="resetSearchOption"
                  >
                    {{ $t('string.reset') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import GmapCluster from 'vue2-google-maps/src/components/cluster'

export default {
  name: 'List',
  metaInfo() {
    return {
      title: this.$t('label.map'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {GmapCluster},
  data() {
    return {
      showFilter: false,
      center: {
        lat: 12.71112,
        lng: 104.88873
      },
      infoWindowPos: null,
      infoWinOpen: true,
      currentMidx: null,
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      },

      locations: [],
      provinces: [],
      projectTypes: [],
      dateRange: [],
      search: {
        permit_number: '',
        year: parseInt(((new Date()).getFullYear()).toString()),
        date_start: null,
        date_end: null,
        project_type_id: 0,
        project_name: '',
        owner_name: '',
        project_situation: null,
        project_published: null,
        project_pushed: null,
        number_floor: [0, 200],
        province_id: 0,
        is_province_data_level: false,
        current_work_year: null,
      },

      years: [],
    }
  },
  computed: {
    mapContainerClass() {
      return this.showFilter ? 'col-md-10' : 'col-md-12'
    },
    totalProjects() {
      if (this.locations !== null && this.locations.length > 0) {
        return this.locations.length
      }
      return 0
    }
  },
  watch: {
    "$i18n.locale"() {
      this.infoWinOpen = false
    }
  },
  created() {
    this.getProjectLatLng()
    this.getProjectType()
    this.getProvinces()
    this.enabledActions()
    this.years = this.generateYears()
  },
  methods: {
    resetSearchOption() {
      this.dateRange = []
      this.search = {
        permit_number: '',
        year: parseInt(((new Date()).getFullYear()).toString()),
        date_start: null,
        date_end: null,
        project_type_id: 0,
        project_name: '',
        owner_name: '',
        project_situation: null,
        project_published: null,
        project_pushed: null,
        number_floor: [0, 200],
        province_id: 0,
        current_work_year: null,
        is_province_data_level: false
      }
      this.getProjectLatLng()
    },
    getProjectLatLng() {
      this.$isLoading(true)
      if (this.dateRange.length > 0) {
        if (this.dateRange[0] && this.dateRange[1]) {
          this.search.date_start = this.$moment(this.dateRange[0]).locale('en').format('YYYY-MM-DD')
          this.search.date_end = this.$moment(this.dateRange[1]).locale('en').format('YYYY-MM-DD')
        } else {
          this.search.date_start = null
          this.search.date_end = null
        }
      }
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project/get-project-latlng', this.search)
        .then((response) => {
          if (response.data.data) {
            this.locations = response.data.data
          } else {
            this.locations = [
              {lat: 12.71112, lng: 104.88873}
            ]
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    toggleInfoWindow(marker) {
      this.infoWinOpen = true
      this.infoWindowPos = marker
      this.center = marker
      let thumbnail1 = marker.cci_images[0] ? this.getSrc(marker.cci_images[0].src) : '/img/placeholder.png'
      let thumbnail2 = marker.cci_images[1] ? this.getSrc(marker.cci_images[1].src) : '/img/placeholder.png'
      let thumbnail3 = marker.cci_images[2] ? this.getSrc(marker.cci_images[2].src) : '/img/placeholder.png'
      this.infoOptions.content = `
          <div class="project-window"
               style="width: 300px !important;
               height: auto !important;
               font-family: var(--secondary-font);
               overflow-x: hidden !important;
               position: relative !important;">
            <div class="project-window-header">
                <h4 class="project-title"
                style="font-family: var(--secondary-font);"
                data-uuid="${marker.uuid}">
                    <a href="">${marker.construction_type !== null ? marker.construction_type['name_' + this.$i18n.locale] : this.$t('string.na')}</a>
                </h4>
            </div>
            <div class="project-window-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="image-container">
                            <div class="image-first"
                            style="background-image: url(${thumbnail1})"></div>
                            <div class="image-other">
                                <div class="image-other-first"
                                style="background-image: url(${thumbnail2})"></div>
                                <div class="image-other-second"
                                style="background-image: url(${thumbnail3})">
                                +${marker.cci_images.length > 3 ? (parseFloat(marker.cci_images.length) - 3) : '0'}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <table class="table table-condensed">
                            <tbody>
                              <tr>
                                <th>${this.$t('label.surface')}</th>
                                <td class="text-right">${marker.surface ? marker.surface : this.$t('string.na')}</td>
                              </tr>
                              <tr>
                                <th>${this.$t('label.nbFloor')}</th>
                                <td class="text-right">${marker.nb_floor ? marker.nb_floor : this.$t('string.na')}</td>
                              </tr>
                              <tr>
                                <th>${this.$t('label.landUniqueNumber')}</th>
                                <td class="text-right">${marker.land_unique_numbers ? marker.land_unique_numbers : this.$t('string.na')}</td>
                              </tr>
                              <tr>
                                <th>${this.$t('label.permitNumber')}</th>
                                <td class="text-right">${marker.permit_number ? marker.permit_number : this.$t('string.na')}</td>
                              </tr>
                              <tr>
                                <th>${this.$t('label.permitDate')}</th>
                                <td class="text-right">${this.getDate(marker.permit_date, false, 'DD/MM/YYYY')}</td>
                              </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
          </div>
        `
    },
    getProvinces() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/location/get-location')
        .then((res) => {
          if (res.data.data.length > 0) {
            this.provinces = res.data.data
          } else {
            this.provinces = []
          }
        })
    },
    getProjectType() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-type/get-option')
        .then((res) => {
          if (res.data.data.length > 0) {
            this.projectTypes = res.data.data
          } else {
            this.projectTypes = []
          }
        })
    },
    enabledActions() {
      let self = this
      this.clearEventHandler([
        '.btn-learn-more',
        '.project-title',
      ])
      $(document).on('click', '.btn-learn-more', function () {
        self.$router.push({
          name: 'show-project',
          params: {
            project_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.project-title', function () {
        self.$router.push({
          name: 'show-project',
          params: {
            project_uuid: $(this).attr('data-uuid')
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.project-window {
  width: 300px !important;
  height: auto !important;
  position: relative !important;
  font-family: "KHMERMEF1", serif !important;
}

.gm-style-iw {
  height: auto !important;
  max-height: none !important;
}

.gm-style-iw-d {
  height: auto !important;
  max-height: none !important;
}
</style>
