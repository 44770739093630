<template>
  <div v-if="deikarbanhchoun" class="row">
    <div class="col-md-4">
      <div class="row">
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">{{ $t('string.showDeikarbanhchoun') }}</h3>
              <div class="box-tools">
                <template v-if="deikarbanhchoun && deikarbanhchoun.approved_at === null">
                  <router-link :to="{
                                  name: 'edit-deikarbanhchouns-roubavontbokkols',
                                  params: {
                                    roubavontbokkol_deikarbanhchoun_uuid: deikarbanhchoun.uuid,
                                  }
                                }"
                               class="btn btn-primary btn-sm margin-r-5">
                    <i class="fa fa-edit"></i>
                  </router-link>
                </template>
                <asora-back-button/>
              </div>
            </div>
            <div class="box-body">
              <table class="table table-striped table-bordered">
                <tbody>
                <template v-if="deikarbanhchoun.architecture">
                  <tr>
                    <td>{{ $t('string.roubavontbokkol') }}</td>
                    <td>
                      <a href="">{{ deikarbanhchoun.architecture['name_' + $i18n.locale] }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('string.unique_number') }}</td>
                    <td>
                      {{ deikarbanhchoun.architecture.unique_number || $t('string.na') }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('string.registration_number') }}</td>
                    <td>
                      {{ deikarbanhchoun.architecture.registration_number || $t('string.na') }}
                    </td>
                  </tr>
                </template>
                <tr>
                  <td>{{ $t('string.issuedNumber') }}</td>
                  <td>{{ getNumbers(deikarbanhchoun.issued_number) || $t('string.na') }}</td>
                </tr>
                <tr>
                  <td>{{ $t('string.issuedDate') }}</td>
                  <td>{{ getNumbers(getDate(deikarbanhchoun.issued_date, false, 'DD/MM/YYYY')) }}</td>
                </tr>
                <tr>
                  <td>{{ $t('string.approvedBy') }}</td>
                  <td>{{ deikarbanhchoun.approved_by || $t('string.na') }}</td>
                </tr>
                <tr>
                  <td>{{ $t('string.approvedAt') }}</td>
                  <td>{{ getNumbers(getDate(deikarbanhchoun.approved_at, false, 'DD/MM/YYYY')) }}</td>
                </tr>
                <tr>
                  <td>{{ $t('string.note') }}</td>
                  <td>{{ deikarbanhchoun.note || $t('string.na') }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">{{ $t('string.references') }}</h3>
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-md-12">
                  <template v-if="Array.isArray(deikarbanhchoun.references) && deikarbanhchoun.references.length">
                    <ul>
                      <li v-for="(item, key) in deikarbanhchoun.references"
                          :key="key">{{ item.value }}
                      </li>
                    </ul>
                  </template>
                  <template v-else>
                    <p class="text-center text-muted">{{ $t('string.noteNoContent') }}</p>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">{{ $t('string.letter') }}</h3>
        </div>
        <div class="box-body">
          <div class="row">
            <div class="col-md-12">
              <div class="pull-right">
                <button class="btn btn-default margin-bottom"
                        @click="openUrl">
                  <i class="fa fa-print"></i> {{ $t('label.print') }}
                </button>
              </div>
            </div>
          </div>

          <smart-iframe
            :src="`${baseUrl}/roubavontbokkol/deikarbanhchoun/${deikarbanhchoun.uuid}`"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmartIframe from "@/components/SmartIframe"

export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('string.showDeikarbanhchoun'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  components: {
    SmartIframe,
  },
  data() {
    return {
      deikarbanhchoun: null,
    }
  },
  computed: {
    api() {
      return `${this.baseUrl}/api/backend/roubavontbokkols/deikarbanhchoun`
    },
  },
  methods: {
    async show() {
      this.$axios.post(`${this.api}/show`, {
        roubavontbokkol_deikarbanhchoun_uuid: this.$route.params.roubavontbokkol_deikarbanhchoun_uuid,
      }).then(({data}) => {
        if (data.data) {
          this.deikarbanhchoun = data.data
        }
      })
    },
    openUrl() {
      const deikarbanhchoun_uuid = this.$route.params.roubavontbokkol_deikarbanhchoun_uuid
      window.open(`${this.baseUrl}/roubavontbokkol/deikarbanhchoun/${deikarbanhchoun_uuid}`, '_blank')
    },
  },
  async mounted() {
    await this.show()
  }
}
</script>

<style scoped>

</style>
