<template>
  <div class="row">
    <div class="col-md-12">
      <router-link
        class="btn btn-success btn-sm pull-right"
        :to="{name: 'show-project', param: {project_uuid: $route.params.project_uuid}}"
      >
        <i class="fa fa-search" /> {{ $t('button.goPublishedInformationPage') }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "DirectProjectSync"
}
</script>

<style scoped>

</style>
