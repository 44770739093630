<template>
  <div class="row">
    <div class="hide">
      {{ $t('menu.constructionSurface') }}
    </div>
    <small-box
      v-for="(count, key) in counts"
      v-show="count.show"
      :key="key"
      :bg="count.bg"
      :route="count.route"
      :title="$t(count.title)"
      :subtitle="$t(count.subtitle)"
      :value="count.nb"
      :value-on-right="count.value_on_right"
    />
  </div>
</template>

<script>
import SmallBox from "./SmallBox"
export default {
  name: "DashboardCard",
  components: {SmallBox},
  props: {
    level_province: {
      default: false
    },
    subTitle: {
      type: String,
      default: 'menu.ministryAuthority'
    }
  },
  data () {
    return {
      counts: [
        {
          show: true,
          title: 'count.project',
          subtitle: this.subTitle,
          nb: 0,
          bg: 'card-1',
          value_on_right: true,
          route: {name: 'list-project'},
        },
        {
          show: true,
          title: 'label.investmentBudget',
          subtitle: '',
          nb: 0,
          icon: 'user-cog',
          bg: 'card-4',
          value_on_right: true,
          route: {name: 'list-project'},
        },
        {
          show: true,
          title: 'menu.constructionSurface',
          subtitle: '',
          nb: 0,
          bg: 'card-6',
          value_on_right: true,
          route: {name: 'list-professional-study-planner'},
        },
        {
          show: this.$route.name !== 'level-province',
          title: 'menu.businessBuilder',
          subtitle: '',
          nb: 0,
          icon: 'user-cog',
          bg: 'card-2',
          value_on_right: true,
          route: {name: 'list-study-plan-company'},
        },
        {
          show: this.$route.name !== 'level-province',
          title: 'menu.studyPlanCompany',
          subtitle: '',
          nb: 0,
          icon: 'user-cog',
          bg: 'card-3',
          value_on_right: true,
          route: {name: 'list-study-plan-company'},
        },
        {
          show: this.$route.name !== 'level-province',
          title: 'menu.constructionCompany',
          subtitle: '',
          nb: 0,
          icon: 'user-cog',
          bg: 'card-7',
          value_on_right: true,
          route: {name: 'list-construction-company'},
        },
        {
          show: this.$route.name !== 'level-province',
          title: 'menu.checkAndConfirmPlanCompany',
          subtitle: '',
          nb: 0,
          icon: 'user-cog',
          bg: 'card-5',
          value_on_right: true,
          route: {name: 'list-check-and-confirm-construction-company'},
        },
        {
          show: this.$route.name !== 'level-province',
          title: 'menu.checkAndConfirmConstructionCompany',
          subtitle: '',
          nb: 0,
          icon: 'user-cog',
          bg: 'card-8',
          value_on_right: true,
          route: {name: 'list-check-and-confirm-construction-company'},
        },
      ]
    }
  },
  created() {
    this.getCounts()
  },
  methods: {
    getCounts() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/report/get-counts', {
        is_province_data_level: this.level_province
      })
        .then((res) => {
          let data = res.data.data
          this.counts[0].nb = [
            {'label': this.$t('label.total'), 'value': this.formatPrice(data['project'])},
            {'label': this.$t('label.thisYear'), 'value': this.formatPrice(data['project_this_year'])},
            {'label': this.$t('label.thisMonth'), 'value': this.formatPrice(data['project_this_month'])}
          ]

          this.counts[1].nb = [
            {
              'label': this.$t('label.total'),
              'value': this.formatPrice(parseFloat(data['investment_budget'])) + ' $'
            },
            {
              'label': this.$t('label.thisYear'),
              'value': this.formatPrice(parseFloat(data['investment_budget_year'])) + ' $'
            },
            {
              'label': this.$t('label.thisMonth'),
              'value': this.formatPrice(parseFloat(data['investment_budget_month'])) + ' $'
            }
          ]

          this.counts[2].nb = [
            {
              'label': this.$t('label.total'),
              'value': this.formatPrice(data['construction_surface']) + ' m<sup>2</sup>'
            },
            {
              'label': this.$t('label.thisYear'),
              'value': this.formatPrice(data['construction_surface_year']) + ' m<sup>2</sup>'
            },
            {
              'label': this.$t('label.thisMonth'),
              'value': this.formatPrice(data['construction_surface_month']) + ' m<sup>2</sup>'
            }
          ]
          this.counts[3].nb = [
            {'label': this.$t('label.total'), 'value': this.formatPrice(data['personality_study_plan_total'])},
            {'label': this.$t('label.thisYear'), 'value': this.formatPrice(data['personality_study_plan_year'])},
            {'label': this.$t('label.thisMonth'), 'value': this.formatPrice(data['personality_study_plan_month'])}
          ]

          this.counts[4].nb = [
            {'label': this.$t('label.total'), 'value': this.formatPrice(data['study_plan_company_total'])},
            {'label': this.$t('label.thisYear'), 'value': this.formatPrice(data['study_plan_company_year'])},
            {'label': this.$t('label.thisMonth'), 'value': this.formatPrice(data['study_plan_company_month'])}
          ]

          this.counts[5].nb = [
            {'label': this.$t('label.total'), 'value': this.formatPrice(data['construction_company_total'])},
            {'label': this.$t('label.thisYear'), 'value': this.formatPrice(data['construction_company_year'])},
            {'label': this.$t('label.thisMonth'), 'value': this.formatPrice(data['construction_company_month'])}
          ]

          this.counts[6].nb = [
            {'label': this.$t('label.total'), 'value': this.formatPrice(data['check_and_confirm_company_total'])},
            {'label': this.$t('label.thisYear'), 'value': this.formatPrice(data['check_and_confirm_company_year'])},
            {'label': this.$t('label.thisMonth'), 'value': this.formatPrice(data['check_and_confirm_company_month'])}
          ]

          this.counts[7].nb = [
            {
              'label': this.$t('label.total'),
              'value': this.formatPrice(data['check_and_confirm_construction_company_total'])
            },
            {
              'label': this.$t('label.thisYear'),
              'value': this.formatPrice(data['check_and_confirm_construction_company_year'])
            },
            {
              'label': this.$t('label.thisMonth'),
              'value': this.formatPrice(data['check_and_confirm_construction_company_month'])
            }
          ]
        })
    },
  }
}
</script>

<style scoped>

</style>
