<template>
  <create-people
    :title="$t('string.addPersonality')"
    people-category-id="1"
  />
</template>

<script>
import CreatePeople from "../../people/Create"

export default {
  name: "Create",
  components: {CreatePeople},
  metaInfo() {
    return {
      title: this.$t('string.addPersonality'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
}
</script>

<style scoped>

</style>
