<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.markConstructionLetter') }}
      </h3>
      <div class="box-tools">
        <button-back-to-list />
      </div>
    </div>
    <div class="box-body">
      <div class="form-horizontal">
        <div
          class="form-group row"
          :class="approvedByValidation.required?'has-error':null"
        >
          <label class="col-xs-3 control-label required">{{ $t('string.approvedBy') }}</label>
          <div class="col-xs-9">
            <input
              v-model="approved_by"
              type="text"
              class="form-control"
            >
            <template v-if="approvedByValidation.required">
              <label class="label-error">{{ approvedByValidation.message }}</label>
            </template>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xs-3 control-label">{{ $t('string.note') }}</label>
          <div class="col-xs-9">
            <textarea
              v-model="note"
              class="form-control"
              :placeholder="$t('string.note')"
              rows="3"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xs-3 control-label">{{ $t('string.openConstructionNumber') }}</label>
          <div class="col-xs-9">
            <input
              v-model="open_construction_number"
              type="number"
              :placeholder="$t('string.openConstructionNumber')"
              class="form-control"
            >
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xs-3 control-label">{{ $t('string.openConstructionDate') }}</label>
          <div class="col-xs-9">
            <date-picker
              v-model="open_construction_date"
              format="DD/MM/YYYY"
              class="table-full-width"
              :lang="datepickerLocale[$i18n.locale].lang"
              :placeholder="$t('string.openConstructionDate')"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-xs-9 col-xs-offset-3">
            <button
              class="btn btn-primary btn-sm"
              @click="save"
            >
              {{ $t('button.save') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {validationMixins} from "@/mixins/validations"
import ButtonBackToList from "../includes/ButtonBackToList"

export default {
  name: "Approval",
  components: {ButtonBackToList},
  mixins: [validationMixins],
  metaInfo() {
    return {
      title: this.$t('string.markConstructionLetter'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  data() {
    return {
      approved_by: 'ឯក. ឆាន់ សូរ្យផល',
      note: 'ឯកសារនេះបានដាក់អោយប្រើប្រាស់ជាផ្លូវការចាប់ពីពេលនេះទៅ។',
      validations: null,
      open_construction_number: null,
      open_construction_date: null,
    }
  },
  computed: {
    api() {
      return `${this.baseUrl}/api/backend/construction-letter/open-construction-letter`
    },
  },
  mounted() {
    this.getOpenConstructionLetter().finally(() => {
      this.$isLoading(false)
    })
  },
  methods: {
    save() {
      this.$isLoading(true)
      let date = null
      if (this.$moment(this.open_construction_date).isValid()) {
        date = this.$moment(this.open_construction_date).locale('en').format('YYYY-MM-DD')
      }
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/construction-letter/open-construction-letter/mark-letter-approved', {
        approved_by: this.approved_by,
        construction_letter_uuid: this.$route.params.construction_letter_uuid,
        note: this.note,
        construction_number: this.open_construction_number,
        construction_date: date,
      }).then(() => {
        this.showToastr()
        this.$router.push({
          name: 'upload-open-construction-letter',
          params: {
            construction_letter_uuid: this.$route.params.construction_letter_uuid,
          }
        })
      })
        .catch((error) => {
          this.$isLoading(false)
          this.validations = null
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    async getOpenConstructionLetter() {
      this.$isLoading(true)
      let response = await this.$axios.post(`${this.api}/show`, {
        construction_letter_uuid: this.$route.params.construction_letter_uuid,
      })

      let data = await response.data.data
      this.open_construction_number = data.number
      if (this.$moment(data.date).isValid()) {
        this.open_construction_date = this.$moment(data.date).locale('en').toDate()
      }
    },
  }
}
</script>

<style scoped>

</style>
