<template>
  <div class="form-horizontal">
    <div class="form-group row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <div
              class="form-group row"
              :class="getProjectValidation.required?'has-error':null"
            >
              <label class="col-xs-4 control-label required">{{ $t('string.searchProject') }}</label>
              <div class="col-xs-8">
                <multiselect
                  v-model="selected_project"
                  :options="projects"
                  track-by="id"
                  :internal-search="false"
                  :placeholder="$t('string.searchProject')"
                  label="label"
                  @search-change="searchProject"
                />
                <template v-if="getProjectValidation.required">
                  <span class="label-error">{{ getProjectValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <div
              class="form-group row"
              :class="requestedByValidation.required?'has-error':null"
            >
              <label class="col-xs-4 control-label required">{{ $t('string.openRequestedBy') }}</label>
              <div class="col-xs-8">
                <input
                  v-model="open_construction_letter.requested_by"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.openRequestedBy')"
                >
                <template v-if="requestedByValidation.required">
                  <span class="label-error">{{ requestedByValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div
              class="form-group row"
              :class="requestedDateValidation.required?'has-error':null"
            >
              <label class="col-xs-4 control-label required">{{ $t('string.requestedAt') }}</label>
              <div class="col-xs-8">
                <date-picker
                  v-model="open_construction_letter.requested_date"
                  format="DD/MM/YYYY"
                  class="table-full-width"
                  :lang="datepickerLocale[$i18n.locale].lang"
                  :placeholder="$t('string.requestedAt')"
                />
                <template v-if="requestedDateValidation.required">
                  <span class="label-error">{{ requestedDateValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <div
              class="form-group row"
              :class="openConstructionNumberValidation.required?'has-error':null"
            >
              <label class="col-xs-4 control-label">{{ $t('string.openConstructionNumber') }}</label>
              <div class="col-xs-8">
                <input
                  v-model="open_construction_letter.open_construction_number"
                  type="number"
                  class="form-control"
                  :placeholder="$t('string.openConstructionNumber')"
                >
                <template v-if="openConstructionNumberValidation.required">
                  <span class="label-error">{{ openConstructionNumberValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div
              class="form-group row"
              :class="openConstructionDateValidation.required?'has-error':null"
            >
              <label class="col-xs-4 control-label">{{ $t('string.openConstructionDate') }}</label>
              <div class="col-xs-8">
                <date-picker
                  v-model="open_construction_letter.open_construction_date"
                  format="DD/MM/YYYY"
                  class="table-full-width"
                  :lang="datepickerLocale[$i18n.locale].lang"
                  :placeholder="$t('string.openConstructionDate')"
                />
                <template v-if="openConstructionDateValidation.required">
                  <span class="label-error">{{ openConstructionDateValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="references.length>0"
      class="form-group row"
    >
      <div class="col-xs-12">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group row">
              <label class="col-sm-2 control-label">{{ $t('string.announcementReferences') }}</label>
              <div class="col-sm-10">
                <draggable
                  v-model="references"
                  class="list-group"
                  tag="ul"
                  v-bind="dragOptions"
                  @start="isDragging = true"
                  @end="isDragging = false"
                >
                  <transition-group
                    type="transition"
                    name="flip-list"
                  >
                    <li
                      v-for="element in references"
                      :key="element.order"
                      class="list-group-item"
                    >
                      <div class="drag-item">
                        <div class="drag-icon">
                          -
                        </div>
                        <div class="drag-title">
                          {{ element.name }}
                        </div>
                        <div class="drag-action">
                          <button
                            class="btn btn-danger btn-xs"
                            @click="references.splice(references.indexOf(element), 1)"
                          >
                            <i class="fa fa-trash-alt" />
                          </button>
                        </div>
                      </div>
                    </li>
                  </transition-group>
                </draggable>
                <div class="row">
                  <div class="col-md-12">
                    <div
                      class="form-group row"
                      :class="reference_validation?'has-error':null"
                    >
                      <div class="col-md-12">
                        <div class="input-group">
                          <input
                            v-model="new_reference"
                            type="text"
                            :placeholder="$t('string.addNewReferences')"
                            class="form-control"
                            @keydown.enter="addNewReferences"
                          >
                          <span
                            class="input-group-addon bg-gray"
                            @click="addNewReferences"
                          >
                            <i class="fa fa-plus" /> {{ $t('string.add') }}
                          </span>
                        </div>
                        <template v-if="reference_validation">
                          <span class="label-error">{{ $t('string.requiredMessage') }}</span>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group row">
              <label class="col-sm-2 control-label" />
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-xs-12">
                    <project-building-detail :project-uuid="selected_project ? selected_project.uuid: null" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label" />
              <div class="col-sm-8">
                <h4 class="main-title">
                  {{ $t('string.engineersSection') }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('string.constructionAccessoriesAbout') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.construction_accessories_about"
                  :options="constructionAccessoriesAbout"
                  :placeholder="$t('string.constructionAccessoriesAbout')"
                  track-by="id"
                  label="name"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.drawnBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.drawn_by_engineers"
                  :options="drawn_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.drawnBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.calculatedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.calculated_by_companies"
                  :options="calculated_by_companies"
                  :placeholder="$t('label.calculatedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :multiple="true"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.calculatedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.calculated_by_engineers"
                  :options="calculated_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.calculatedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.confirmAndReviewedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.reviewed_by_companies"
                  :options="reviewed_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.confirmAndReviewedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.reviewedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.reviewed_by_engineers"
                  :options="reviewed_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.reviewedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.agreedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.agreed_by_companies"
                  :options="agreed_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.agreedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.agreedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.agreed_by_engineers"
                  :options="agreed_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.agreedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.confirmAndReviewedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.confirmed_and_checked_by_companies"
                  :options="confirmed_and_checked_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.confirmAndReviewedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.confirmAndCheckBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.confirmed_and_checked_by_engineers"
                  :options="confirmed_and_checked_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.confirmAndCheckBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label" />
              <div class="col-sm-8">
                <h4 class="main-title">
                  {{ $t('string.mechanicSystem') }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-sm-offset-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.drawnBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.mechanic_drawn_by_engineers"
                  :options="drawn_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.drawnBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.calculatedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.mechanic_calculated_by_companies"
                  :options="calculated_by_companies"
                  :placeholder="$t('label.calculatedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :multiple="true"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.calculatedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.mechanic_calculated_by_engineers"
                  :options="calculated_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.calculatedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.confirmAndReviewedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.mechanic_reviewed_by_companies"
                  :options="reviewed_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.confirmAndReviewedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.reviewedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.mechanic_reviewed_by_engineers"
                  :options="reviewed_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.reviewedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.agreedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.mechanic_agreed_by_companies"
                  :options="agreed_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.agreedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.agreedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.mechanic_agreed_by_engineers"
                  :options="agreed_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.agreedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.confirmAndReviewedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.mechanic_confirmed_and_checked_by_companies"
                  :options="confirmed_and_checked_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.confirmAndReviewedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.confirmAndCheckBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.mechanic_confirmed_and_checked_by_engineers"
                  :options="confirmed_and_checked_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.confirmAndCheckBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label" />
              <div class="col-sm-8">
                <h4 class="main-title">
                  {{ $t('string.waterSystem') }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-sm-offset-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.drawnBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.water_drawn_by_engineers"
                  :options="drawn_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.drawnBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.calculatedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.water_calculated_by_companies"
                  :options="calculated_by_companies"
                  :placeholder="$t('label.calculatedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :multiple="true"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.calculatedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.water_calculated_by_engineers"
                  :options="calculated_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.calculatedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.confirmAndReviewedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.water_reviewed_by_companies"
                  :options="reviewed_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.confirmAndReviewedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.reviewedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.water_reviewed_by_engineers"
                  :options="reviewed_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.reviewedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.agreedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.water_agreed_by_companies"
                  :options="agreed_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.agreedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.agreedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.water_agreed_by_engineers"
                  :options="agreed_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.agreedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.confirmAndReviewedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.water_confirmed_and_checked_by_companies"
                  :options="confirmed_and_checked_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.confirmAndReviewedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.confirmAndCheckBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.water_confirmed_and_checked_by_engineers"
                  :options="confirmed_and_checked_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.confirmAndCheckBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label" />
              <div class="col-sm-8">
                <h4 class="main-title">
                  {{ $t('string.electricSystem') }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-sm-offset-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.drawnBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.electric_drawn_by_engineers"
                  :options="drawn_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.drawnBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.calculatedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.electric_calculated_by_companies"
                  :options="calculated_by_companies"
                  :placeholder="$t('label.calculatedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :multiple="true"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.calculatedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.electric_calculated_by_engineers"
                  :options="calculated_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.calculatedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.confirmAndReviewedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.electric_reviewed_by_companies"
                  :options="reviewed_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.confirmAndReviewedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.reviewedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.electric_reviewed_by_engineers"
                  :options="reviewed_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.reviewedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.agreedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.electric_agreed_by_companies"
                  :options="agreed_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.agreedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.agreedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.electric_agreed_by_engineers"
                  :options="agreed_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.agreedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.confirmAndReviewedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.electric_confirmed_and_checked_by_companies"
                  :options="confirmed_and_checked_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.confirmAndReviewedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.confirmAndCheckBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.electric_confirmed_and_checked_by_engineers"
                  :options="confirmed_and_checked_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.confirmAndCheckBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label" />
              <div class="col-sm-8">
                <h4 class="main-title">
                  {{ $t('string.fireSystem') }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-sm-offset-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.drawnBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.fire_drawn_by_engineers"
                  :options="drawn_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.drawnBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.calculatedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.fire_calculated_by_companies"
                  :options="calculated_by_companies"
                  :placeholder="$t('label.calculatedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :multiple="true"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.calculatedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.fire_calculated_by_engineers"
                  :options="calculated_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.calculatedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.confirmAndReviewedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.fire_reviewed_by_companies"
                  :options="reviewed_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.confirmAndReviewedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.reviewedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.fire_reviewed_by_engineers"
                  :options="reviewed_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.reviewedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.agreedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.fire_agreed_by_companies"
                  :options="agreed_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.agreedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.agreedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.fire_agreed_by_engineers"
                  :options="agreed_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.agreedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.confirmAndReviewedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.fire_confirmed_and_checked_by_companies"
                  :options="confirmed_and_checked_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.confirmAndReviewedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.confirmAndCheckBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.fire_confirmed_and_checked_by_engineers"
                  :options="confirmed_and_checked_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.confirmAndCheckBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label" />
              <div class="col-sm-8">
                <h4 class="main-title">
                  {{ $t('string.airConditionerSystem') }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 col-sm-offset-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.drawnBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.air_conditioner_drawn_by_engineers"
                  :options="drawn_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.drawnBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.calculatedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.air_conditioner_calculated_by_companies"
                  :options="calculated_by_companies"
                  :placeholder="$t('label.calculatedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :multiple="true"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.calculatedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.air_conditioner_calculated_by_engineers"
                  :options="calculated_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.calculatedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.confirmAndReviewedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.air_conditioner_reviewed_by_companies"
                  :options="reviewed_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.confirmAndReviewedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.reviewedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.air_conditioner_reviewed_by_engineers"
                  :options="reviewed_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.reviewedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.agreedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.air_conditioner_agreed_by_companies"
                  :options="agreed_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.agreedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.agreedBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.air_conditioner_agreed_by_engineers"
                  :options="agreed_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.agreedBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.confirmAndReviewedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.air_conditioner_confirmed_and_checked_by_companies"
                  :options="confirmed_and_checked_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.confirmAndReviewedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.confirmAndCheckBy') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.air_conditioner_confirmed_and_checked_by_engineers"
                  :options="confirmed_and_checked_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.confirmAndCheckBy')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label" />
              <div class="col-sm-8">
                <h4 class="main-title">
                  {{ $t('string.builderInformation') }}
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.companies') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.construction_by_companies"
                  :options="construction_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.calculatedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchOwnerProjectCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.technicianTeam') }}</label>
              <div class="col-sm-8">
                <input
                  v-model="open_construction_letter.technician_team"
                  type="text"
                  :placeholder="$t('label.technicianTeam')"
                  class="form-control"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.standByEngineer') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.stand_by_engineers"
                  :options="stand_by_engineers"
                  :multiple="true"
                  :placeholder="$t('label.standByEngineer')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.nbWorkers') }}</label>
              <div class="col-sm-8">
                <input
                  v-model="open_construction_letter.nb_workers"
                  type="number"
                  class="form-control"
                  :placeholder="$t('label.nbWorkers')"
                >
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.workerSalary') }}</label>
              <div class="col-sm-8">
                <div class="input-group">
                  <input
                    v-model="open_construction_letter.worker_salary"
                    type="number"
                    class="form-control"
                    :placeholder="$t('label.workerSalary')"
                  >
                  <span class="input-group-addon">{{ $t('currency.usd') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.nbProfessional') }}</label>
              <div class="col-sm-8">
                <input
                  v-model="open_construction_letter.nb_professionals"
                  type="number"
                  class="form-control"
                  :placeholder="$t('label.nbProfessional')"
                >
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.professionalSalary') }}</label>
              <div class="col-sm-8">
                <div class="input-group">
                  <input
                    v-model="open_construction_letter.professional_salary"
                    type="number"
                    class="form-control"
                    :placeholder="$t('label.professionalSalary')"
                  >
                  <span class="input-group-addon">{{ $t('currency.usd') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.nbEngineer') }}</label>
              <div class="col-sm-8">
                <input
                  v-model="open_construction_letter.nb_engineers"
                  type="number"
                  class="form-control"
                  :placeholder="$t('label.nbEngineer')"
                >
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.engineerSalary') }}</label>
              <div class="col-sm-8">
                <div class="input-group">
                  <input
                    v-model="open_construction_letter.engineer_salary"
                    type="number"
                    class="form-control"
                    :placeholder="$t('label.engineerSalary')"
                  >
                  <span class="input-group-addon">{{ $t('currency.usd') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.nbArchitects') }}</label>
              <div class="col-sm-8">
                <input
                  v-model="open_construction_letter.nb_architects"
                  type="number"
                  class="form-control"
                  :placeholder="$t('label.nbArchitects')"
                >
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.architectSalary') }}</label>
              <div class="col-sm-8">
                <div class="input-group">
                  <input
                    v-model="open_construction_letter.architect_salary"
                    type="number"
                    class="form-control"
                    :placeholder="$t('label.architectSalary')"
                  >
                  <span class="input-group-addon">{{ $t('currency.usd') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.certificateOfInsurance') }}</label>
              <div class="col-sm-8">
                <div class="input-group">
                  <select
                    v-if="insurances.length>0"
                    v-model="open_construction_letter.insurance_company_id"
                    class="form-control"
                  >
                    <option
                      v-for="(item, key) in insurances"
                      :key="key"
                      :value="item.id"
                    >
                      {{ item['name_' + $i18n.locale] }}
                    </option>
                  </select>
                  <span class="input-group-addon bg-gray" />
                  <input
                    v-model="open_construction_letter.insurance_number"
                    type="text"
                    :placeholder="$t('label.certificateOfInsurance')"
                    class="form-control"
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('string.insurance_date') }}</label>
              <div class="col-sm-8">
                <date-picker
                  v-model="open_construction_letter.insurance_date"
                  format="DD/MM/YYYY"
                  class="table-full-width"
                  :lang="datepickerLocale[$i18n.locale].lang"
                  :placeholder="$t('string.insurance_date')"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.constructionReviewedAndConfirmedByCompany') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.construction_confirmed_and_checked_by_companies"
                  :options="construction_confirmed_and_checked_by_companies"
                  :multiple="true"
                  :placeholder="$t('label.constructionReviewedAndConfirmedByCompany')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchCompanies"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-4 control-label">
                {{ $t('label.constructionReviewedAndConfirmedByArchitecture') }}
              </label>
              <div class="col-sm-8">
                <multiselect
                  v-model="open_construction_letter.construction_confirmed_and_checked_by_architectures"
                  :options="construction_confirmed_and_checked_by_architectures"
                  :multiple="true"
                  :placeholder="$t('label.constructionReviewedAndConfirmedByArchitecture')"
                  track-by="id"
                  :internal-search="false"
                  :label="`name_${$i18n.locale}`"
                  @search-change="searchConstructors"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <div class="form-group row">
          <label class="col-sm-2 control-label">{{ $t('string.technicalInstructions') }}</label>
          <div class="col-sm-10">
            <textarea
              v-model="open_construction_letter.technical_instructions"
              class="form-control"
              :placeholder="$t('string.technicalInstructions')"
              rows="4"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="carbonCopies.length>0"
      class="form-group row"
    >
      <div class="col-xs-12">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group row">
              <label class="col-sm-2 control-label">{{ $t('string.carbonCopies') }}</label>
              <div class="col-sm-10">
                <draggable
                  v-model="carbonCopies"
                  class="list-group"
                  tag="ul"
                  v-bind="dragOptions"
                  @start="isDragging = true"
                  @end="isDragging = false"
                >
                  <transition-group
                    type="transition"
                    name="flip-list"
                  >
                    <li
                      v-for="element in carbonCopies"
                      :key="element.order"
                      class="list-group-item"
                    >
                      <div class="drag-item">
                        <div class="drag-icon">
                          -
                        </div>
                        <div class="drag-title">
                          {{ element.name }}
                        </div>
                        <div class="drag-action">
                          <button
                            class="btn btn-danger btn-xs"
                            @click="carbonCopies.splice(carbonCopies.indexOf(element), 1)"
                          >
                            <i class="fa fa-trash-alt" />
                          </button>
                        </div>
                      </div>
                    </li>
                  </transition-group>
                </draggable>
                <div class="row">
                  <div class="col-md-12">
                    <div
                      class="form-group row"
                      :class="carbon_copy_validation?'has-error':null"
                    >
                      <div class="col-md-12">
                        <div class="input-group">
                          <input
                            v-model="new_carbon_copy"
                            type="text"
                            :placeholder="$t('string.addNewCarbonCopy')"
                            class="form-control"
                            @keydown.enter="addNewCarbonCopy"
                          >
                          <span
                            class="input-group-addon bg-gray"
                            @click="addNewCarbonCopy"
                          >
                            <i class="fa fa-plus" /> {{ $t('string.add') }}
                          </span>
                        </div>
                        <template v-if="carbon_copy_validation">
                          <span class="label-error">{{ $t('string.requiredMessage') }}</span>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-xs-10 col-xs-offset-2">
        <button
          v-if="!hideSaveButton"
          class="btn btn-primary btn-sm margin-r-5"
          @click="saveAndUploadFile"
        >
          {{ $t('button.save') }}
        </button>
        <button
          v-if="!hideSaveButton && hasPreviewButton"
          class="btn btn-success btn-sm margin-r-5"
          @click="saveAndPreview"
        >
          {{ $t('button.save') }} & {{ $t('string.preview') }}
        </button>
        <template v-if="$route.params.hasOwnProperty('construction_letter_uuid')">
          <button
            class="btn btn-default btn-sm"
            @click="skip"
          >
            {{ $t('button.skip') }}
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from 'debounce'
import draggable from 'vuedraggable'
import {validationMixins} from "@/mixins/validations"
import ProjectBuildingDetail from "@/views/project/includes/components/ProjectBuildingDetail"
import {mapFields} from "vuex-map-fields"

export default {
  name: "OpenConstructionLetterForm",
  components: {
    ProjectBuildingDetail,
    draggable,
  },
  mixins: [validationMixins],
  data() {
    return {
      carbon_copy_validation: false,
      new_carbon_copy: '',

      reference_validation: false,
      new_reference: '',
      validations: null,

      open_construction_letter: {},
      projects: [],

      selected_project: null,
      project: null,

      references: [],
      carbonCopies: [],
      insurances: [],

      drawn_by_engineers: [],
      calculated_by_companies: [],
      calculated_by_engineers: [],
      reviewed_by_companies: [],
      reviewed_by_engineers: [],
      agreed_by_companies: [],
      agreed_by_engineers: [],
      confirmed_and_checked_by_companies: [],
      confirmed_and_checked_by_engineers: [],

      construction_by_companies: [],
      stand_by_engineers: [],
      construction_confirmed_and_checked_by_architectures: [],
      construction_confirmed_and_checked_by_companies: [],

      buildings: [],
    }
  },
  computed: {
    api() {
      return `${this.baseUrl}/api/backend/construction-letter/open-construction-letter`
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
    constructionAccessoriesAbout() {
      return [
        {id: 1, name: this.$t('string.mainConcrete'), code: 'បេតុងអារមេ៉'},
        {id: 2, name: this.$t('string.steelConcrete'), code: 'បេតុងដែក'},
      ]
    },
    hideSaveButton() {
      return this.open_construction_letter &&
        // eslint-disable-next-line no-prototype-builtins
        this.open_construction_letter.hasOwnProperty('status') &&
        this.open_construction_letter.status === 'Approved'
    },
    previewURL() {
      let baseUrl = `${this.baseUrl}/construction-letter`
      let construction_letter_uuid = this.$route.params.construction_letter_uuid
      if (this.$route.name === 'edit-open-construction-letter') {
        return `${baseUrl}/open-construction-letter/preview/${construction_letter_uuid}`
      }
      return `${baseUrl}/close-construction-letter/preview/${construction_letter_uuid}`
    },
    hasPreviewButton () {
      return this.$route.params.construction_letter_uuid
    },
    ...mapFields('general', {
      show_form: 'show_form'
    })
  },
  watch: {
    selected_project() {
      if (this.selected_project !== null) {
        this.open_construction_letter.project = this.selected_project
        this.getProject()
          .then(() => {
            this.getReferences()
          })
          .then(() => {
            this.getCarbonCopies()
          })
          .finally(() => {
            this.$isLoading(false)
          })
      }
    }
  },
  methods: {
    skip() {
      this.$router.push({
        name: 'upload-open-construction-letter',
        params: {
          construction_letter_uuid: this.$route.params.construction_letter_uuid,
        }
      })
    },
    addNewCarbonCopy() {
      this.carbon_copy_validation = false
      let order = Math.max(...this.carbonCopies.map(item => parseInt(item.order)))
      if (this.new_carbon_copy === '' || this.new_carbon_copy === undefined) {
        this.carbon_copy_validation = true
        return 0
      }
      this.carbonCopies.push({
        id: parseInt(order) + 1,
        name: this.new_carbon_copy,
        type: 'Other',
        order: parseInt(order) + 1,
      })
      this.new_carbon_copy = ''
    },
    addNewReferences() {
      this.reference_validation = false
      let order = Math.max(...this.references.map(item => parseInt(item.order)))
      if (this.new_reference === '' || this.new_reference === undefined) {
        this.reference_validation = true
        return 0
      }
      this.references.push({
        id: parseInt(order) + 1,
        name: this.new_reference,
        type: 'Other',
        order: parseInt(order) + 1,
      })
      this.new_reference = ''
    },
    addNewBuilding() {},
    saveAndPreview() {
      const self = this
      this.save(function () {
        self.show_form = false
      })
    },
    saveAndUploadFile() {
      const self = this
      this.save(function (data) {
        if (data.data) {
          self.showToastr()
          self.$router.push({
            name: 'upload-open-construction-letter',
            params: {
              construction_letter_uuid: data.data.uuid,
            }
          })
        }
      })
    },
    save(callback) {
      this.$set(this.open_construction_letter, 'project', this.selected_project)
      this.$set(this.open_construction_letter, 'carbon_copies', this.carbonCopies)
      this.$set(this.open_construction_letter, 'references', this.references)
      // this.$set(this.open_construction_letter, 'buildings', this.buildings)

      // eslint-disable-next-line no-prototype-builtins
      if (this.$route.params.hasOwnProperty('construction_letter_uuid')) {
        this.$set(this.open_construction_letter, 'uuid', this.$route.params.construction_letter_uuid)
      }

      let requested_date = null
      if (this.open_construction_letter.requested_date !== '' &&
        this.open_construction_letter.requested_date !== undefined &&
        this.$moment(this.open_construction_letter.requested_date).isValid()
      ) {
        requested_date = this.$moment(this.open_construction_letter.requested_date).locale('en').format('YYYY-MM-DD')
      }
      this.$set(this.open_construction_letter, 'requested_date', requested_date)

      let open_construction_date = null
      if (this.open_construction_letter.open_construction_date !== '' &&
        this.open_construction_letter.open_construction_date !== undefined &&
        this.$moment(this.open_construction_letter.open_construction_date).isValid()
      ) {
        open_construction_date = this.$moment(this.open_construction_letter.open_construction_date).locale('en').format('YYYY-MM-DD')
      }
      this.$set(this.open_construction_letter, 'open_construction_date', open_construction_date)

      let insurance_date = null
      if (this.open_construction_letter.insurance_date !== '' &&
        this.open_construction_letter.insurance_date !== undefined &&
        this.$moment(this.open_construction_letter.insurance_date).isValid()
      ) {
        insurance_date = this.$moment(this.open_construction_letter.insurance_date).locale('en').format('YYYY-MM-DD')
      }
      this.$set(this.open_construction_letter, 'insurance_date', insurance_date)

      this.validations = null
      this.$isLoading(true)
      this.$axios.post(`${this.api}/store`, this.open_construction_letter)
        .then(({data}) => {
          this.$isLoading(false)
          callback(data)
        })
        .catch((error) => {
          this.$isLoading(false)
          if (error.response && error.response.status && error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    async getProject() {
      this.$isLoading(true)
      let response = await this.$axios.post(`${this.api}/get-project`, {
        project_id: this.selected_project.id,
        construction_letter_uuid: this.$route.params.construction_letter_uuid,
      })
      this.project = await response.data.data
      this.open_construction_letter = await response.data.data
      let construction_letter = await response.data.data.construction_letter
      this.open_construction_letter.uuid = null

      // number, date, requested by and requested date
      if (construction_letter !== null) {
        if (construction_letter.requested_date && this.$moment(construction_letter.requested_date).isValid()) {
          this.$set(this.open_construction_letter, 'requested_date', this.$moment(construction_letter.requested_date).locale('en').toDate())
        }
        if (construction_letter.date && this.$moment(construction_letter.date).isValid()) {
          this.$set(this.open_construction_letter, 'open_construction_date', this.$moment(construction_letter.date).locale('en').toDate())
        }
        if (construction_letter.number) {
          this.open_construction_letter.open_construction_number = construction_letter.number
        }
        if (construction_letter.technical_instructions) {
          this.open_construction_letter.technical_instructions = construction_letter.technical_instructions
        }
      }

      // insurance date
      if (this.project.insurance_date && this.$moment(this.project.insurance_date).isValid()) {
        this.$set(this.open_construction_letter, 'insurance_date', this.$moment(this.project.insurance_date).locale('en').toDate())
      }

      if (this.project.buildings.length > 0) {
        this.buildings = this.project.buildings
      } else {
        this.buildings = []
      }

      if (this.project.owners !== null && this.project.owners.length > 0) {
        this.open_construction_letter.requested_by = this.project.owners.map((item) => {
          return item.name_km
        }).join(', ')
      }

      if (this.project && this.project.requested_date) {
        if (this.$moment(this.project.requested_date).isValid()) {
          this.open_construction_letter.requested_date = this.$moment(this.project.requested_date).locale('en').toDate()
        }
      }

      if (this.project.construction_accessories_about_id !== null) {
        this.open_construction_letter.construction_accessories_about = this.constructionAccessoriesAbout[this.project.construction_accessories_about_id - 1]
      }

      // engineering and construction section
      this.setEngineerCompanyConstructionData(this.project)
      // mechanic, water, electricity and fire system
      this.setProjectSystemsValues(this.project)
    },
    async getReferences() {
      this.$isLoading(true)
      let construction_letter_uuid = null
      // eslint-disable-next-line no-prototype-builtins
      if (this.$route.params.hasOwnProperty('construction_letter_uuid')) {
        construction_letter_uuid = this.$route.params.construction_letter_uuid
      }
      let response = await this.$axios.post(`${this.api}/get-references`, {
        project_id: this.selected_project !== null ? this.selected_project.id : null,
        construction_letter_uuid: construction_letter_uuid,
      })
      this.references = response.data.data.map((item) => {
        return {id: item.id, name: item.title, order: item.id, type: item.type}
      })
    },
    async getCarbonCopies() {
      this.$isLoading(true)
      let construction_letter_uuid = null
      // eslint-disable-next-line no-prototype-builtins
      if (this.$route.params.hasOwnProperty('construction_letter_uuid')) {
        construction_letter_uuid = this.$route.params.construction_letter_uuid
      }
      let response = await this.$axios.post(`${this.api}/get-carbon-copies`, {
        project_id: this.selected_project !== null ? this.selected_project.id : null,
        construction_letter_uuid: construction_letter_uuid,
      })
      this.carbonCopies = response.data.data.map((item) => {
        return {
          id: item.id,
          name: item.title,
          order: item.id,
          type: item.type,
        }
      })
    },
    async getOpenConstructionLetter() {
      this.$isLoading(true)
      let response = await this.$axios.post(`${this.api}/show`, {
        construction_letter_uuid: this.$route.params.construction_letter_uuid,
      })
      let data = await response.data.data
      this.$set(this.open_construction_letter, 'uuid', data.uuid)

      this.$set(this.open_construction_letter, 'open_construction_number', data.number)
      if (this.$moment(data.date).isValid()) {
        this.$set(this.open_construction_letter, 'open_construction_date', this.$moment(data.date).locale('en').toDate())
      }

      this.open_construction_letter.requested_by = data.requested_by
      if (this.$moment(data.requested_date).isValid()) {
        this.$set(this.open_construction_letter, 'requested_date', this.$moment(data.requested_date).locale('en').toDate())
      }

      this.selected_project = data.selected_project
      this.open_construction_letter.status = data.status
      this.$set(this.open_construction_letter, 'technical_instructions', data.technical_instructions)

      this.setEngineerCompanyConstructionData(data)

      // mechanic, water, electricity and fire system
      this.setProjectSystemsValues(data)
    },
    setEngineerCompanyConstructionData(data) {
      // buildings
      this.buildings = data.buildings
      let relationships = [
        'drawn_by_engineers',
        'calculated_by_companies',
        'calculated_by_engineers',
        'reviewed_by_companies',
        'reviewed_by_engineers',
        'agreed_by_engineers',
        'agreed_by_companies',
        'confirmed_and_checked_by_engineers',
        'confirmed_and_checked_by_companies',
      ]
      // engineering section
      relationships.forEach((relationship) => {
        let tmp = data[`engineering_${relationship}`]
        if (tmp !== null && tmp !== undefined && tmp.length > 0) {
          this.$set(this.open_construction_letter, relationship, tmp)
        }
      })

      // construction section
      try {
        if (data.companies !== null && data.companies.length > 0) {
          this.$set(this.open_construction_letter, 'construction_by_companies', data.companies)
        }
        if (data.stand_by !== null && data.stand_by.length > 0) {
          this.$set(this.open_construction_letter, 'stand_by_engineers', data.stand_by)
        }
        if (data.construction_confirmed_and_checked_by_companies !== null && data.construction_confirmed_and_checked_by_companies.length > 0) {
          this.$set(this.open_construction_letter, 'construction_confirmed_and_checked_by_companies', data.construction_confirmed_and_checked_by_companies)
        }
        if (data.construction_confirmed_and_checked_by_architectures !== null && data.construction_confirmed_and_checked_by_architectures.length > 0) {
          this.$set(this.open_construction_letter, 'construction_confirmed_and_checked_by_architectures', data.construction_confirmed_and_checked_by_architectures)
        }
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
    },
    setProjectSystemsValues(data) {
      (['mechanic_', 'water_', 'electric_', 'fire_', 'air_conditioner_']).forEach((prefix) => {
        ([
          'drawn_by_engineers',
          'calculated_by_companies',
          'calculated_by_engineers',
          'reviewed_by_companies',
          'reviewed_by_engineers',
          'agreed_by_companies',
          'agreed_by_engineers',
          'confirmed_and_checked_by_companies',
          'confirmed_and_checked_by_engineers',
        ]).forEach((subfix) => {
          let key = `${prefix}${subfix}`
          if (data && data[key] && data[key] !== null && data[key].length > 0) {
            this.$set(this.open_construction_letter, key, data[key])
          } else {
            this.$set(this.open_construction_letter, key, [])
          }
        })
      })
    },
    async getInsurances() {
      let response = await this.$axios.post(`${this.api}/get-insurances`)
      this.insurances = await response.data.data
    },
    searchProject: debounce(function (query_text = '') {
      this.$axios.post(`${this.api}/search-project`, {
        query_text: query_text
      }).then(({data}) => {
        if (data.data !== null && data.data.length > 0) {
          this.projects = data.data.map((project) => {
            let item = {
              id: project.id,
              label: '',
              uuid: project.uuid,
            }
            if (project.permit_number !== null) {
              if (project.year !== null) {
                item.label += ` (${project.permit_number}-${project.year})`
              } else {
                item.label += ` (${project.permit_number})`
              }
            }
            if (project.construction_type !== null) {
              item.label += project.construction_type.name_km
            }
            return item
          })
        }
      })
    }),
    searchCompanies: debounce(function (query_text = '') {
      this.$axios.post(`${this.api}/get-companies`, {
        query_text: query_text
      }).then(({data}) => {
        if (data.data.length > 0) {
          let companies = data.data
          this.calculated_by_companies = companies
          this.reviewed_by_companies = companies
          this.agreed_by_companies = companies
          this.confirmed_and_checked_by_companies = companies
          this.construction_confirmed_and_checked_by_companies = companies
        }
      })
    }),
    searchConstructors: debounce(function (query_text = '') {
      this.$axios.post(`${this.api}/get-constructors`, {
        query_text: query_text
      }).then(({data}) => {
        if (data.data.length > 0) {
          let engineers = data.data
          this.drawn_by_engineers = engineers
          this.calculated_by_engineers = engineers
          this.reviewed_by_engineers = engineers
          this.agreed_by_engineers = engineers
          this.confirmed_and_checked_by_engineers = engineers
          this.stand_by_engineers = engineers
          this.construction_confirmed_and_checked_by_architectures = engineers
        }
      })
    }),
    searchOwnerProjectCompanies: debounce(function (query_text = '') {
      this.$axios.post(`${this.api}/get-owner-project-companies`, {
        query_text: query_text
      }).then(({data}) => {
        this.construction_by_companies = data.data
      })
    }),
  },
  mounted() {
    this.getCarbonCopies().then(() => {
      this.getReferences().then(() => [
        this.$isLoading(false)
      ])
    })

    this.searchProject()
    this.getInsurances()
    this.searchCompanies()
    this.searchConstructors()
    this.searchOwnerProjectCompanies()
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty('construction_letter_uuid')) {
      this.getOpenConstructionLetter()
    }
  }
}
</script>

<style scoped>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}

.drag-item {
  display: grid;
  grid-template-columns: 20px 1fr 30px;
}

.input-group-addon {
  cursor: pointer;
}
</style>
