<template>
  <div
    v-if="user !== null"
    class="table-responsive"
  >
    <div class="mb-20 clearfix">
      <template v-if="isUpdating">
        <button
          class="btn btn-danger pull-right"
          @click="switchUpdateMode(false)"
        >
          <i class="fa fa-times-circle" /> {{ $t('button.cancel') }}
        </button>
        <button
          class="btn btn-success pull-right mr-5"
          @click="updateProfile"
        >
          <i class="fa fa-save" /> {{ $t('button.save') }}
        </button>
      </template>
      <template v-else>
        <button
          class="btn btn-primary pull-right"
          @click="switchUpdateMode(true)"
        >
          <i class="fa fa-edit" /> {{ $t('button.update') }}
        </button>
      </template>
    </div>
    <table class="table table-striped table-hover">
      <tbody>
        <tr>
          <th>{{ $t('fields.firstName') }}</th>
          <template v-if="isUpdating">
            <td>
              <div
                class="form-group no-margin"
                :class="{
                  'has-error': validations !== null && validations.hasOwnProperty('first_name')
                }"
              >
                <div>
                  <input
                    v-model="userForm.first_name"
                    type="text"
                    class="form-control"
                    :placeholder="$t('placeholder.firstName')"
                  >
                  <span
                    v-if="validations !== null && validations.hasOwnProperty('first_name')"
                    class="label-error"
                  >
                    {{ validations['first_name'][0] }}
                  </span>
                </div>
              </div>
            </td>
          </template>
          <template v-else>
            <td>{{ user.first_name }}</td>
          </template>
        </tr>
        <tr>
          <th>{{ $t('fields.lastName') }}</th>
          <template v-if="isUpdating">
            <td>
              <div
                class="form-group no-margin"
                :class="{
                  'has-error': validations !== null && validations.hasOwnProperty('last_name')
                }"
              >
                <div>
                  <input
                    v-model="userForm.last_name"
                    type="text"
                    class="form-control"
                    :placeholder="$t('placeholder.lastName')"
                  >
                  <span
                    v-if="validations !== null && validations.hasOwnProperty('last_name')"
                    class="label-error"
                  >
                    {{ validations['last_name'][0] }}
                  </span>
                </div>
              </div>
            </td>
          </template>
          <template v-else>
            <td>{{ user.last_name }}</td>
          </template>
        </tr>
        <tr>
          <th>{{ $t('fields.email') }}</th>
          <template v-if="isUpdating">
            <td>
              <div
                class="form-group no-margin"
                :class="{
                  'has-error': validations !== null && validations.hasOwnProperty('email')
                }"
              >
                <div>
                  <input
                    v-model="userForm.email"
                    type="email"
                    class="form-control"
                    :placeholder="$t('placeholder.email')"
                  >
                  <span
                    v-if="validations !== null && validations.hasOwnProperty('email')"
                    class="label-error"
                  >
                    {{ validations['email'][0] }}
                  </span>
                </div>
              </div>
            </td>
          </template>
          <template v-else>
            <td>{{ user.email }}</td>
          </template>
        </tr>
        <tr>
          <th>{{ $t('fields.gender') }}</th>
          <template v-if="isUpdating">
            <td>
              <div
                class="form-group no-margin"
                :class="{
                  'has-error': validations !== null && validations.hasOwnProperty('email')
                }"
              >
                <div>
                  <select
                    v-model="userForm.gender_id"
                    class="form-control"
                  >
                    <option
                      selected
                      disabled
                      value="0"
                    >
                      {{ $t('string.selectGender') }}
                    </option>
                    <option value="1">
                      {{ $t('label.male') }}
                    </option>
                    <option value="2">
                      {{ $t('label.female') }}
                    </option>
                  </select>
                  <span
                    v-if="validations !== null && validations.hasOwnProperty('gender_id')"
                    class="label-error"
                  >
                    {{ validations['gender_id'][0] }}
                  </span>
                </div>
              </div>
            </td>
          </template>
          <template v-else>
            <td>
              {{ user.gender ? user.gender['name_' + $i18n.locale] : 'N/A' }}
            </td>
          </template>
        </tr>
        <template v-if="!isUpdating">
          <tr>
            <th>{{ $t('fields.createdAt') }}</th>
            <td>{{ getDate(user.created_at, false) }}</td>
          </tr>
          <tr>
            <th>{{ $t('fields.updatedAt') }}</th>
            <td>{{ getDate(user.updated_at, false) }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'Profile',
  computed: {
    ...mapState({
      user: state => state.user.data
    })
  },
  data() {
    return {
      isUpdating: false,
      userForm: {},
      validations: null
    }
  },
  methods: {
    switchUpdateMode(isUpdating) {
      if (isUpdating) {
        this.userForm = {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email,
          gender_id: this.user.gender_id
        }
        this.validations = null
      }
      this.isUpdating = isUpdating
    },
    updateProfile() {
      this.$axios.post(process.env.VUE_APP_API + '/api/auth/backend/update-information', this.userForm)
        .then((response) => {
          let user = response.data.data
          this.$store.dispatch('user/setUser', {user: user})
          this.userForm = {
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            gender_id: user.gender_id,
            created_at: user.created_at,
            updated_at: user.updated_at,
          }
          this.switchUpdateMode(false)
          this.$toastr('success', this.$t('string.profileUpdate'), this.$t('string.success'))
        }).catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
    }
  }
}
</script>

<style scoped>
.mb-20 {
  margin-bottom: 20px;
}

.mr-5 {
  margin-right: 5px;
}

.no-margin {
  margin-bottom: 0 !important;
}
</style>
