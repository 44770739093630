<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.showProvince') }}
      </h3>
      <div class="box-tools">
        <template v-if="can($Permissions.backend.setting.province.edit)">
          <router-link
            :to="{
              name: 'edit-province',
              params: {
                province_id: $route.params.province_id
              }
            }"
            class="btn btn-primary btn-sm margin-r-5"
          >
            <i class="fa fa-edit" />
          </router-link>
        </template>
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <template v-if="province!==null">
        <table class="table table-striped">
          <tbody>
            <tr>
              <th>{{ $t('label.nameInKm') }}</th>
              <td>{{ province.name_km }}</td>
            </tr>
            <tr>
              <th>{{ $t('label.nameInEn') }}</th>
              <td>{{ province.name_en }}</td>
            </tr>
            <tr>
              <th>{{ $t('label.createdAt') }}</th>
              <td>{{ getDate(province.created_at, false) }}</td>
            </tr>
            <tr>
              <th>{{ $t('label.updatedAt') }}</th>
              <td>{{ getDate(province.updated_at, false) }}</td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        <p class="text-bold">
          {{ $t('string.noResult') }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('string.showProvince'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      province: null,
    }
  },
  mounted() {
    this.getProvince()
  },
  methods: {
    getProvince() {
      this.$axios.post(`${process.env.VUE_APP_API}/api/backend/province/show`, {
        id: this.$route.params.province_id,
      }).then(({data}) => {
        this.province = data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
