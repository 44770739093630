import Permissions from "../../permissions"
import Index from '../../views/businessBuilders/Index'
import {professionalStudyPlannerRouters} from "./professionalStudyPlannerRouters"
import {studyPlanCompanyRouters} from "./studyPlanCompanyRouters"
import {constructionCompanyRouters} from "./consturctionCompanyRouters"
import {checkAndConfirmConstructionCompanyRouters} from "./checkAndConfirmConstructionCompany"

export const businessBuilderRouters = {
  path: 'business-builder',
  name: 'business-builder',
  component: Index,
  meta: {
    permissions: [Permissions.backend.businessBuilders.manage],
    groups: 'business-builder',
  },
  children: [
    professionalStudyPlannerRouters,
    studyPlanCompanyRouters,
    constructionCompanyRouters,
    checkAndConfirmConstructionCompanyRouters,
  ]
}
