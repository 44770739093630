<template>
  <div class="form-horizontal">
    <div
      class="form-group row"
      :class="{'has-error' : validations.hasOwnProperty('architecture_id')}"
    >
      <label class="col-xs-2 control-label required">{{ $t('field.searchRoubavontbokkols') }}</label>
      <div class="col-xs-10">
        <multiselect
          v-model="note.architecture"
          :options="architectures"
          track-by="id"
          label="name"
          :disabled="$route.params.hasOwnProperty('roubavontbokkols_uuid')"
          :internal-search="false"
          :placeholder="$t('field.searchRoubavontbokkols')"
          @search-change="searchArchitectures"
        />
        <template v-if="validations.hasOwnProperty('architecture_id')">
          <span class="label-error">{{ validations['architecture_id'][0] }}</span>
        </template>
      </div>
    </div>
    <div
      class="form-group row"
      :class="{'has-error' : validations.hasOwnProperty('owner_requested_at')}"
    >
      <label class="col-xs-2 control-label required">{{ $t('field.ownerRequestedAt') }}</label>
      <div class="col-xs-10">
        <date-picker
          v-model="note.owner_requested_at"
          type="date"
          :lang="datepickerLocale[$i18n.locale].lang"
          :placeholder="$t('field.ownerRequestedAt')"
          format="DD/MM/YYYY"
          class="table-full-width"
        />
        <template v-if="validations.hasOwnProperty('owner_requested_at')">
          <span class="label-error">{{ validations['owner_requested_at'][0] }}</span>
        </template>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-xs-10 col-xs-offset-2">
        <h4 class="main-title">
          {{ $t('field.referenceRoubavontbokkols') }}
        </h4>
      </div>
    </div>
    <div
      class="form-group row"
      :class="{ 'has-error' : referenceValidation }"
    >
      <label class="control-label col-sm-2" />
      <div class="col-sm-10">
        <div class="row">
          <div class="col-md-12 margin-bottom">
            <div class="input-group">
              <input
                v-model="reference"
                type="text"
                :placeholder="$t('field.reference')"
                class="form-control"
                @keydown.enter="addNewReference"
              >
              <span
                class="input-group-btn"
                @click="addNewReference"
              >
                <button class="btn btn-default">
                  <i class="fa fa-plus" /> {{ $t('string.add') }}
                </button>
              </span>
            </div>
            <template v-if="referenceValidation">
              <span class="label-error">{{ $t('string.requiredMessage') }}</span>
            </template>
          </div>
          <div class="col-md-12">
            <template v-if="references.length>0">
              <draggable
                v-model="references"
                class="list-group"
                tag="ul"
                v-bind="references"
                @start="isDragging = true"
                @end="isDragging = false"
              >
                <transition-group
                  type="transition"
                  name="flip-list"
                >
                  <li
                    v-for="(element, key) in references"
                    :key="`${1 + key + Math.random()}`"
                    class="list-group-item"
                  >
                    <div class="drag-item">
                      <div class="drag-icon">
                        -
                      </div>
                      <div class="drag-title">
                        {{ element.value }}
                      </div>
                      <div class="drag-action">
                        <button
                          class="btn btn-danger btn-xs"
                          @click="onDeleteReference(element)"
                        >
                          <i class="fa fa-trash-alt" />
                        </button>
                      </div>
                    </div>
                  </li>
                </transition-group>
              </draggable>
            </template>
            <template v-else>
              <p class="text-muted text-center">
                {{ $t('string.noteNoContent') }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-xs-10 col-xs-offset-2">
        <h4 class="main-title">
          {{ $t('string.constructionLetterCommand') }}
        </h4>
      </div>
    </div>
    <div
      class="form-group row"
      :class="{'has-error' : officerCommentValidation}"
    >
      <label class="control-label col-sm-2" />
      <div class="col-sm-10">
        <div class="row">
          <div class="col-md-12 margin-bottom">
            <div class="input-group">
              <input
                v-model="officerComment"
                type="text"
                :placeholder="$t('field.officerComment')"
                class="form-control"
                @keydown.enter="addNewOfficerComment"
              >
              <span
                class="input-group-btn"
                @click="addNewOfficerComment"
              >
                <button class="btn btn-default">
                  <i class="fa fa-plus" /> {{ $t('string.add') }}
                </button>
              </span>
            </div>
            <template v-if="officerCommentValidation">
              <span class="label-error">{{ $t('string.requiredMessage') }}</span>
            </template>
          </div>
          <div class="col-md-12">
            <template v-if="officerComments.length>0">
              <draggable
                v-model="officerComments"
                class="list-group"
                tag="ul"
                v-bind="officerComments"
                @start="isDragging = true"
                @end="isDragging = false"
              >
                <transition-group
                  type="transition"
                  name="flip-list"
                >
                  <li
                    v-for="(element, key) in officerComments"
                    :key="`${1 + key + Math.random()}`"
                    class="list-group-item"
                  >
                    <div class="drag-item">
                      <div class="drag-icon">
                        -
                      </div>
                      <div class="drag-title">
                        {{ element.value }}
                      </div>
                      <div class="drag-action">
                        <button
                          class="btn btn-danger btn-xs"
                          @click="onDeleteComment(element)"
                        >
                          <i class="fa fa-trash-alt" />
                        </button>
                      </div>
                    </div>
                  </li>
                </transition-group>
              </draggable>
            </template>
            <template v-else>
              <p class="text-muted text-center">
                {{ $t('string.commandsNoContent') }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-10 col-sm-offset-2">
        <button
          class="btn btn-primary btn-sm margin-right-5"
          @click="save"
        >
          {{ $t('button.save') }}
        </button>
        <ButtonBack />
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from "debounce"
import draggable from 'vuedraggable'
import ButtonBack from "@/components/ButtonBack"

export default {
  name: "RoubavontbokkolsNoteForm",
  components: {
    ButtonBack,
    draggable,
  },
  data() {
    return {
      note: {
        architecture: null,
        owner_requested_at: null,
        references: [],
        officerComments: [],
      },
      validations: {},
      architectureSelected: null,
      architectures: [],

      references: [],
      reference: '',
      referenceValidation: false,

      officerComments: [],
      officerComment: '',
      officerCommentValidation: false,
    }
  },
  computed: {
    api() {
      return `${this.baseUrl}/api/backend/roubavontbokkols/note`
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
  },
  async mounted() {
    this.searchArchitectures()
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty('roubavontbokkols_uuid')) {
      await this.show()
    }
  },
  methods: {
    addNewOfficerComment() {
      this.officerCommentValidation = false
      if (!this.officerComment) {
        this.officerCommentValidation = true
        return 0
      }
      this.officerComments.push({
        id: parseInt(this.officerComments.length.toString()) + 1,
        value: this.officerComment,
        order: parseInt(this.officerComments.length.toString()) + 1,
      })
      this.officerComment = ''
    },
    addNewReference() {
      this.referenceValidation = false
      if (!this.reference) {
        this.referenceValidation = true
        return 0
      }
      this.references.push({
        id: parseInt(this.references.length.toString()) + 1,
        value: this.reference,
        order: parseInt(this.references.length.toString()) + 1,
      })
      this.reference = ''
    },
    save() {
      this.validations = {}
      this.$isLoading(true)
      let owner_requested_at = null
      let architecture_id = null
      let uuid = null
      // eslint-disable-next-line no-prototype-builtins
      if (this.$route.params.hasOwnProperty('roubavontbokkols_uuid')) {
        uuid = this.$route.params.roubavontbokkols_uuid
      }
      if (this.note.architecture) {
        architecture_id = this.note.architecture.id
      }
      if (this.note.owner_requested_at && this.$moment(this.note.owner_requested_at).isValid()) {
        owner_requested_at = this.$moment(this.note.owner_requested_at).locale('en').format('YYYY-MM-DD')
      }
      this.$axios.post(`${this.api}/store`, {
        references: this.references,
        officerComments: this.officerComments,
        architecture_id: architecture_id,
        owner_requested_at: owner_requested_at,
        uuid: uuid,
      })
        .then(() => {
          this.showToastr()
          this.$router.push({
            name: 'list-note-roubavontbokkols'
          })
        })
        .catch((error) => {
          this.$isLoading(false)
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    searchArchitectures: debounce(function (query_text = '') {
      this.$isLoading(true)
      this.$axios.post(`${this.api}/search-architecture`, {
        query_text: query_text,
      })
        .then((response) => {
          if (response.data && response.data.data) {
            const data = response.data.data
            this.architectures = data.architectures
          }
        })
        .catch((error) => {
          this.onResponseError(error)
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }),
    async show() {
      this.$isLoading(true)
      this.$axios.post(`${this.api}/show`, {
        uuid: this.$route.params.roubavontbokkols_uuid,
      }).then((response) => {
        if (response.data && response.data.data) {
          const data = response.data.data
          const architecture = data.architecture
          if (architecture) {
            this.$set(this.note, 'architecture', {
              id: architecture.id,
              name: `${architecture.unique_number} - (${architecture.name_km} - ${architecture.name_en})`
            })
          }
          if (data.owner_requested_at && this.$moment(data.owner_requested_at).isValid()) {
            this.$set(this.note, 'owner_requested_at', this.$moment(data.owner_requested_at).toDate())
          }
          if (data.references && Array.isArray(data.references)) {
            this.references = data.references.map((item) => {
              return {
                id: item.id,
                value: item.value,
                order: item.order
              }
            })
          }
          const {officer_comments} = data
          if (officer_comments && Array.isArray(officer_comments)) {
            this.officerComments = officer_comments.map((item) => {
              return {
                id: item.id,
                value: item.value,
                order: item.order
              }
            })
          }
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    onDeleteComment(element) {
      this.onConfirm()
        .then((accept) => {
          if (accept) {
            this.officerComments.splice(this.officerComments.indexOf(element), 1)
          }
        })
    },
    onDeleteReference(element) {
      this.onConfirm()
        .then((accept) => {
          if (accept) {
            this.references.splice(this.references.indexOf(element), 1)
          }
        })
    }
  }
}
</script>

<style scoped>
.input-group-addon {
  cursor: pointer;
}

.drag-item {
  display: grid;
  grid-template-columns: 20px 1fr 30px;
}

p.text-muted {
  padding: 25px;
  background-color: #dddddd;
  width: 100%;
}
</style>
