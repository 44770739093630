import Permissions from "../../permissions"
import Index from '../../views/noneBoard/engineer/Index'
import Edit from '../../views/noneBoard/engineer/Edit'
import Show from '../../views/noneBoard/engineer/Show'
import List from '../../views/noneBoard/engineer/List'
import Create from '../../views/noneBoard/engineer/Create'

export const noneBoardEngineerRouters = {
  path: 'engineer',
  name: 'none-board-engineer',
  component: Index,
  redirect: 'engineer',
  meta: {
    permissions: [Permissions.backend.noneBoard.engineer.manage],
    groups: 'none-board-engineer',
  },
  children: [
    {
      path: '',
      name: 'list-none-board-engineer',
      component: List,
      meta: {
        permissions: [Permissions.backend.noneBoard.engineer.list],
        groups: 'none-board-engineer',
        breadcrumb: [
          {
            name_en: 'None Board of the professional',
            name_km: 'អ្នកវិជ្ជាជីវៈមិនទាន់មានគណៈ'
          },
          {
            name_en: 'None Board Engineer',
            name_km: 'គណៈវិស្វករកម្ពុជា'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-none-board-engineer',
      component: Create,
      meta: {
        permissions: [Permissions.backend.noneBoard.engineer.store],
        groups: 'none-board-engineer',
        breadcrumb: [
          {
            name_en: 'None Board of the professional',
            name_km: 'អ្នកវិជ្ជាជីវៈមិនទាន់មានគណៈ'
          },
          {
            name_en: 'None Board Engineer',
            name_km: 'គណៈវិស្វករកម្ពុជា'
          },
          {
            name_en: 'Create',
            name_km: 'បន្ថែមថ្មី'
          }
        ]
      }
    },
    {
      path: 'show/:architecture_uuid',
      name: 'show-none-board-engineer',
      component: Show,
      meta: {
        permissions: [Permissions.backend.noneBoard.engineer.show],
        groups: 'none-board-engineer',
        breadcrumb: [
          {
            name_en: 'None Board of the professional',
            name_km: 'អ្នកវិជ្ជាជីវៈមិនទាន់មានគណៈ'
          },
          {
            name_en: 'None Board Engineer',
            name_km: 'គណៈវិស្វករកម្ពុជា'
          },
          {
            name_en: 'Show',
            name_km: 'មើល'
          }
        ]
      }
    },
    {
      path: 'edit/:architecture_uuid',
      name: 'edit-none-board-engineer',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.noneBoard.engineer.edit],
        groups: 'none-board-engineer',
        breadcrumb: [
          {
            name_en: 'None Board of the professional',
            name_km: 'អ្នកវិជ្ជាជីវៈមិនទាន់មានគណៈ'
          },
          {
            name_en: 'None Board Engineer',
            name_km: 'គណៈវិស្វករកម្ពុជា'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
  ]
}
