<template>
  <XBox :title="$t('string.editRoubavontbokkolsNote')">
    <roubavontbokkols-note-form />
  </XBox>
</template>

<script>
import RoubavontbokkolsNoteForm from "@/views/roubavontbokkols/notes/Form"
import XBox from "@/components/Widgets/XBox"

export default {
  name: "Edit",
  components: {XBox, RoubavontbokkolsNoteForm},
  metaInfo() {
    return {
      title: this.$t('string.editRoubavontbokkolsNote'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
}
</script>

<style scoped>

</style>
