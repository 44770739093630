<template>
  <div class="row">
    <div class="col-xs-12">
      <div
        v-if="showSpinner"
        class="spinner-container"
      >
        <spring-spinner
          class="mx-auto spinner-loading"
          :animation-duration="3000"
          :size="50"
          color="#3c8dbc"
        />
      </div>
      <iframe
        v-show="!showSpinner"
        id="smart-iframe"
        name="generate"
        class="modal-template-height block-ifreme"
        :src="src"
        @load="onLoad"
      />
    </div>
    <div
      v-show="!showSpinner"
      class="col-md-12"
    >
      <button
        class="btn btn-default margin-right-5"
        @click="reloadIframe()"
      >
        <i class="fas fa-redo-alt" />
        {{ $t('label.reloadPDF') }}
      </button>
    </div>
  </div>
</template>

<script>
import {SpringSpinner} from 'epic-spinners'

export default {
  name: "SmartIframe",
  components: {
    SpringSpinner
  },
  props: {
    src: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showSpinner: true
    }
  },
  watch: {
    src () {
      this.showSpinner = true
    }
  },
  mounted() {
    const self = this
    $('#smart-iframe').on('load', function () {
      self.showSpinner = false
    })
  },
  methods: {
    onLoad() {
      this.showSpinner = false
    },
    reloadIframe() {
      this.showSpinner = true
      document.getElementById('smart-iframe').src += ''
    },
  }
}
</script>

<style scoped>
  .modal-template-height {
    height: 865px;
  }

  .block-ifreme {
    width: 100%;
    border-radius: 0.5rem;
    border: none;
  }

  .spinner-container {
    width: 100%;
    height: 775px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
