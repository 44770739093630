<template>
  <div class="row">
    <div :class="mapContainerClass">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('label.map') }}
          </h3>
          <div class="box-tools">
            <ButtonBack />
          </div>
        </div>
        <div class="box-body">
          <div class="row">
            <div
              v-if="isLoading"
              class="col-md-12"
            >
              <div style="display: flex; align-items: center;justify-content: center; width: 100%;height: 80vh;">
                <h3>{{ $t('string.loading') }}</h3>
              </div>
            </div>
            <div
              v-else
              class="col-md-12"
            >
              <div
                v-if="locations.length > 0"
                class="thumbnail"
              >
                <GmapMap
                  :center="center"
                  :zoom="8"
                  map-type-id="roadmap"
                  style="width: 100%; height: 80vh;"
                >
                  <GmapInfoWindow
                    :opened="infoWinOpen"
                    :options="infoOptions"
                    :position="infoWindowPos"
                    @closeclick="infoWinOpen=false"
                  />
                  <GmapCluster>
                    <GmapMarker
                      v-for="(m, i) in locations"
                      :key="i"
                      :clickable="true"
                      :icon="{url: '/img/markers/blue20x20.png'}"
                      :position="m"
                      @click="toggleInfoWindow(m,i)"
                    />
                  </GmapCluster>
                </GmapMap>
              </div>
              <template v-else>
                <p class="text-center text-muted">
                  {{ $t('string.noResultFound') }}
                </p>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GmapCluster from 'vue2-google-maps/src/components/cluster'
import ButtonBack from "@/components/ButtonBack"

export default {
  name: 'List',
  metaInfo() {
    return {
      title: this.$t('label.map'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {ButtonBack, GmapCluster},
  data() {
    return {
      center: {
        lat: 12.71112,
        lng: 104.88873
      },
      infoWindowPos: null,
      infoWinOpen: true,
      currentMidx: null,
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      },

      locations: [],
      isLoading: false
    }
  },
  computed: {
    mapContainerClass() {
      return this.showFilter ? 'col-md-10' : 'col-md-12'
    }
  },
  watch: {
    "$i18n.locale"() {
      this.infoWinOpen = false
    }
  },
  created() {
    this.getProjects()
    this.enabledActions()
  },
  methods: {
    getProjects() {
      this.isLoading = true
      this.$isLoading(this.isLoading)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/collect-project/view-on-map')
        .then(({data}) => {
          if (data.data) {
            this.locations = data.data
          } else {
            this.locations = [
              {lat: 12.71112, lng: 104.88873}
            ]
          }
        })
        .finally(() => {
          this.isLoading = false
          this.$isLoading(this.isLoading)
        })
    },
    toggleInfoWindow(marker) {
      this.infoWinOpen = true
      this.infoWindowPos = marker
      this.center = marker
      let thumbnail1 = marker.cci_images[0] ? this.getSrc(marker.cci_images[0].src) : '/img/placeholder.png'
      let thumbnail2 = marker.cci_images[1] ? this.getSrc(marker.cci_images[1].src) : '/img/placeholder.png'
      let thumbnail3 = marker.cci_images[2] ? this.getSrc(marker.cci_images[2].src) : '/img/placeholder.png'
      this.infoOptions.content = `
          <div class="project-window"
               style="width: 300px !important;
               height: auto !important;
               font-family: var(--secondary-font);
               overflow-x: hidden !important;
               position: relative !important;">
            <div class="project-window-header">
                <h4 class="project-title"
                style="font-family: var(--secondary-font);"
                data-uuid="${marker.uuid}">
                    <a href="">${marker.construction_type !== null ? marker.construction_type['name_' + this.$i18n.locale] : this.$t('string.na')}</a>
                </h4>
            </div>
            <div class="project-window-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="image-container">
                            <div class="image-first"
                            style="background-image: url(${thumbnail1})"></div>
                            <div class="image-other">
                                <div class="image-other-first"
                                style="background-image: url(${thumbnail2})"></div>
                                <div class="image-other-second"
                                style="background-image: url(${thumbnail3})">
                                +${marker.cci_images.length > 3 ? (parseFloat(marker.cci_images.length) - 3) : '0'}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <table class="table table-condensed">
                            <tbody>
                              <tr>
                                <th>${this.$t('label.surface')}</th>
                                <td class="text-right">${marker.surface ? marker.surface : this.$t('string.na')}</td>
                              </tr>
                              <tr>
                                <th>${this.$t('label.nbFloor')}</th>
                                <td class="text-right">${marker.nb_floor ? marker.nb_floor : this.$t('string.na')}</td>
                              </tr>
                              <tr>
                                <th>${this.$t('label.landUniqueNumber')}</th>
                                <td class="text-right">${marker.land_unique_numbers ? marker.land_unique_numbers : this.$t('string.na')}</td>
                              </tr>
                              <tr>
                                <th>${this.$t('label.permitNumber')}</th>
                                <td class="text-right">${marker.permit_number ? marker.permit_number : this.$t('string.na')}</td>
                              </tr>
                              <tr>
                                <th>${this.$t('label.permitDate')}</th>
                                <td class="text-right">${this.getDate(marker.permit_date, false, 'DD/MM/YYYY')}</td>
                              </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
          </div>
        `
    },
    enabledActions() {
      let self = this
      this.clearEventHandler([
        '.btn-learn-more',
        '.project-title',
      ])
      $(document).on('click', '.btn-learn-more', function () {
        self.$router.push({
          name: 'show-project',
          params: {
            project_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.project-title', function () {
        self.$router.push({
          name: 'show-project',
          params: {
            project_uuid: $(this).attr('data-uuid')
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.project-window {
  width: 300px !important;
  height: auto !important;
  position: relative !important;
  font-family: "KHMERMEF1", serif !important;
}

.gm-style-iw {
  height: auto !important;
  max-height: none !important;
}

.gm-style-iw-d {
  height: auto !important;
  max-height: none !important;
}

.loading-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80vh;
}
</style>
