export const integrationMixins = {
  data() {
    return {
      is_published: null,
    }
  },
  computed: {
    isPublished() {
      return this.is_published
    }
  },
  methods: {
    publishedProject(uuid) {
      this.$swal({
        title: this.$t('string.titlePublished'),
        text: this.$t('string.doYouWantToPublishedProject?'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('string.no'),
        confirmButtonText: this.$t('string.btnPublished')
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/integration/published', {
            uuid: uuid,
          }).then((response) => {
            let project = response.data.data
            this.is_published = project.is_published
            this.$swal({
              type: 'success',
              text: this.$t('string.projectHaveBeenSuccessfullyPublished'),
              title: this.$t('string.btnPublished'),
              confirmButtonText: this.$t('string.ok'),
            })
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
    unpublishedProject(uuid) {
      this.$swal({
        title: this.$t('string.unpublished'),
        text: this.$t('string.doYouWantToUnpublishedProject?'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('string.no'),
        confirmButtonText: this.$t('string.unpublished')
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/integration/unpublished', {
            uuid: uuid,
          }).then((response) => {
            let project = response.data.data
            this.is_published = project.is_published
            this.$swal({
              type: 'success',
              text: this.$t('string.projectHaveBeenSuccessfullyUnpublished'),
              title: this.$t('string.unpublished'),
              confirmButtonText: this.$t('string.ok'),
            })
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
    createOrUpdateProject(uuid) {
      this.$swal({
        title: this.$t('string.syncProject'),
        text: this.$t('string.doYouWantToSyncProject?'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('string.no'),
        confirmButtonText: this.$t('string.yes'),
      }).then((result) => {
        if (result.value) {
          this.$isLoading(true)
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/integration/create-or-update-project', {
            project_uuid: uuid,
          }).then(() => {
            this.$swal({
              type: 'success',
              text: this.$t('string.projectHaveBeenSuccessfullySynced'),
              title: this.$t('string.synced'),
              confirmButtonText: this.$t('string.ok')
            })
          }).catch(error => {
            this.onResponseError(error)
          }).finally(() => {
            this.$isLoading(false)
          })
        }
      })
    },
  }
}
