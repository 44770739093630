<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('label.editProjectType') }}
      </h3>
      <div class="box-tools">
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <div class="form-horizontal">
        <div
          :class="'form-group row ' + (validations !== null && validations.hasOwnProperty('name_km') ? 'has-error' : '')"
        >
          <label class="col-sm-3 control-label required">{{ $t('label.nameKm') }}</label>
          <div class="col-sm-9">
            <input
              v-model="projectType.name_km"
              :placeholder="$t('label.nameKm')"
              class="form-control"
              type="text"
            >
            <span
              v-if="validations !== null && validations.hasOwnProperty('name_km')"
              class="label-error"
            >
              {{ validations['name_km'][0] }}
            </span>
          </div>
        </div>

        <div
          :class="'form-group row ' + (validations !== null && validations.hasOwnProperty('name_en') ? 'has-error' : '')"
        >
          <label class="col-sm-3 control-label required">{{ $t('label.nameEn') }}</label>
          <div class="col-sm-9">
            <input
              v-model="projectType.name_en"
              :placeholder="$t('label.nameEn')"
              class="form-control"
              type="text"
            >
            <span
              v-if="validations !== null && validations.hasOwnProperty('name_en')"
              class="label-error"
            >
              {{ validations['name_en'][0] }}
            </span>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-9 col-sm-offset-3">
            <button
              class="btn btn-info btn-sm"
              @click="newProjectType"
            >
              {{ $t('button.update') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Edit",
  metaInfo() {
    return {
      title: this.$t('label.editProjectType'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      projectType: {},
      validations: null,
    }
  },
  created() {
    this.getProjectType()
  },
  methods: {
    newProjectType() {
      this.projectType.project_type_uuid = this.projectType.uuid
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-type/update', this.projectType)
        .then(() => {
          this.showToastr()
          this.$router.push({name: 'list-project-type'})
        })
        .catch((error) => {
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
          } else {
            this.validations = null
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getProjectType() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-type/show', {
        project_type_uuid: this.$route.params.project_type_uuid,
      }).then((res) => {
        this.projectType = res.data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-project-type'})
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
