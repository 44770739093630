<template>
  <div
    v-if="company"
    class="row"
  >
    <div class="col-md-4">
      <CompanyDetail
        :investor-company="investorCompany"
        :company="company"
        :mode-show="modeShow"
        :title="title"
        :name-route-edit="nameRouteEdit"
        :edit-permission="editPermission"
      />
    </div>
    <div class="col-md-8">
      <div class="nav-tabs-custom">
        <ul class="nav nav-tabs">
          <li class="active">
            <a
              href="#certificate"
              data-toggle="tab"
            >{{ $t('tab.construction_certifier_license') }}</a>
          </li>
          <li>
            <a
              href="#documents"
              data-toggle="tab"
            >{{ $t('tab.documents') }}</a>
          </li>
          <li>
            <a
              href="#activityLogs"
              data-toggle="tab"
            >{{ $t('tab.activity_logs') }}</a>
          </li>
        </ul>
        <div class="tab-content">
          <div
            id="certificate"
            class="tab-pane active"
          >
            <ManageConstructionLicense />

            <template v-if="selectedLicense">
              <div v-if="selectedLicense.uuid">
                <SmartIframe
                  :src="`${baseUrl}/certificates/construction-certifier-license/preview/${selectedLicense.uuid}`"
                />
              </div>
            </template>
            <template v-else>
              <UnderDevelopment
                :box="false"
                :description="$t('string.company_has_no_the_construction_certifier_license')"
                svg="/img/svgs/empty.svg"
              />
            </template>
          </div>
          <div
            id="documents"
            class="tab-pane"
          >
            <upload-media
              model-name="company"
              :readonly="modeShow"
              preview-column="col-md-4"
              :button-label="$t('label.chooseReference')"
              :model-uuid="$route.params.company_uuid"
            />
          </div>
          <div
            id="activityLogs"
            class="tab-pane"
          >
            <ActivityLogs
              :model_uuid="$route.params.company_uuid"
              model_type="company"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields"
import UploadMedia from "@/components/UploadMedia"
import CompanyDetail from "@/views/company/includes/CompanyDetail"
import ActivityLogs from "@/components/ActivityLogs"
import SmartIframe from "@/components/SmartIframe"
import ManageConstructionLicense from "@/views/company/includes/ManageConstructionLicense"
import UnderDevelopment from "@/components/UnderDevelopment"

export default {
  name: "CompanyShow",
  components: {
    UnderDevelopment,
    ManageConstructionLicense,
    SmartIframe,
    ActivityLogs,
    CompanyDetail,
    UploadMedia,
  },
  props: {
    modeShow: {
      type: Boolean,
      default: true,
    },
    nameRouteEdit: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    editPermission: {
      type: Array,
      default: null
    },
    investorCompany: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      company: {
        lat: 10,
        lng: 10,
        technicians: [],
      }
    }
  },
  computed: {
    ...mapFields('company', {
      selectedLicense: 'constructionCertifier.selectedLicense'
    })
  },
  mounted() {
    this.getCompany()
  },
  methods: {
    getCompany() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/company/show', {
        company_uuid: this.$route.params.company_uuid,
      }).then((res) => {
        if (res.data.data) {
          let company = res.data.data
          if (company.lat !== null && company.lng !== null) {
            company.lat = parseFloat(company.lat)
            company.lng = parseFloat(company.lng)
          }
          this.company = company
          const { is_published } = company
          this.$store.dispatch('project/setProjectIsPublished', is_published)
        } else {
          this.company = {}
        }
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>
.control-label {
  padding-top: 0 !important;
}
</style>
