<template>
  <div class="form-horizontal">
    <div
      class="form-group row"
      :class="{'has-error' : validations.hasOwnProperty('architecture_id')}"
    >
      <label class="col-xs-2 control-label required">{{ $t('field.searchRoubavontbokkols') }}</label>
      <div class="col-xs-10">
        <multiselect
          v-model="architecture"
          :options="architectures"
          track-by="id"
          :disabled="disabled"
          label="name"
          :internal-search="false"
          :placeholder="$t('field.searchRoubavontbokkols')"
          @search-change="searchArchitectures"
        />
        <template v-if="validations.hasOwnProperty('architecture_id')">
          <span class="label-error">{{ validations['architecture_id'][0] }}</span>
        </template>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-xs-10 col-xs-offset-2">
        <h4 class="main-title">
          {{ $t('string.deikarbanhchounReference') }}
        </h4>
      </div>
    </div>
    <div
      :class="{'has-error' : referencesValidation}"
      class="form-group row"
    >
      <label class="control-label col-sm-2" />
      <div class="col-sm-10">
        <div class="row">
          <div class="col-md-12 margin-bottom">
            <div class="input-group">
              <input
                v-model="reference"
                :placeholder="$t('string.content')"
                class="form-control"
                type="text"
                @keydown.enter="addNewReference"
              >
              <span
                class="input-group-btn"
                @click="addNewReference"
              >
                <button class="btn btn-default">
                  <i class="fa fa-plus" /> {{ $t('string.add') }}
                </button>
              </span>
            </div>
            <template v-if="referencesValidation">
              <span class="label-error">{{ $t('string.requiredMessage') }}</span>
            </template>
          </div>
          <div class="col-md-12">
            <template v-if="references.length>0">
              <draggable
                v-model="references"
                v-bind="references"
                class="list-group"
                tag="ul"
                @end="isDragging = false"
                @start="isDragging = true"
              >
                <transition-group
                  name="flip-list"
                  type="transition"
                >
                  <li
                    v-for="(element, key) in references"
                    :key="`${1+ key + Math.random()}`"
                    class="list-group-item"
                  >
                    <div class="drag-item">
                      <div class="drag-icon">
                        -
                      </div>
                      <div class="drag-title">
                        {{ element.value }}
                      </div>
                      <div class="drag-action">
                        <button
                          class="btn btn-danger btn-xs"
                          @click="onDeleteReference(element)"
                        >
                          <i class="fa fa-trash-alt" />
                        </button>
                      </div>
                    </div>
                  </li>
                </transition-group>
              </draggable>
            </template>
            <template v-else>
              <p class="text-muted text-center">
                {{ $t('string.noteNoContent') }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-10 col-sm-offset-2">
        <button
          class="btn btn-primary btn-sm margin-r-5"
          @click="save"
        >
          {{ $t('button.save') }}
        </button>
        <ButtonBack />
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import ButtonBack from "@/components/ButtonBack"
import {debounce} from 'debounce'

export default {
  name: "RoubavontbokkolDeikarbanhchounForm",
  components: {
    ButtonBack,
    draggable,
  },
  data() {
    return {
      references: [],
      reference: '',
      referencesValidation: false,
      architecture: null,
      architectures: [],
      validations: {}
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
    disabled () {
      return !!this.$route.params.roubavontbokkol_deikarbanhchoun_uuid
    }
  },
  watch: {
    architecture() {
      if (this.architecture && this.architecture.id && !this.$route.params.roubavontbokkol_deikarbanhchoun_uuid) {
        this.show()
      }
    }
  },
  mounted() {
    this.searchArchitectures()
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty('roubavontbokkol_deikarbanhchoun_uuid')) {
      this.show()
    }
  },
  methods: {
    addNewReference() {
      this.referencesValidation = false
      if (!this.reference) {
        this.referencesValidation = true
        return 0
      }
      this.references.push({
        id: parseInt(this.references.length.toString()) + 1,
        value: this.reference,
        order: parseInt(this.references.length.toString()) + 1,
      })
      this.reference = ''
    },
    save() {
      this.validations = {}
      let action = this.$route.params.roubavontbokkol_deikarbanhchoun_uuid ? 'update' : 'create'
      this.$isLoading(true)
      this.$axios.post(`${this.baseUrl}/api/backend/roubavontbokkols/deikarbanhchoun/${action}`, {
        architecture_id: this.architecture && this.architecture.id ? this.architecture.id : null,
        references: this.references,
        roubavontbokkol_deikarbanhchoun_uuid: this.$route.params.roubavontbokkol_deikarbanhchoun_uuid
      }).then(() => {
        this.showToastr()
        this.$router.push({
          name: 'list-deikarbanhchouns-roubavontbokkols'
        })
      }).catch((error) => {
        if (error.response.status === 422) {
          this.validations = error.response.data.errors
        } else {
          this.onResponseError(error)
        }
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    async show() {
      let api = `${this.baseUrl}/api/backend/roubavontbokkols/deikarbanhchoun/get-deikarbanhchoun-by-architecture`
      let params = {
        architecture_id: this.architecture ? this.architecture.id : null
      }

      if (this.$route.params.roubavontbokkol_deikarbanhchoun_uuid) {
        api = `${this.baseUrl}/api/backend/roubavontbokkols/deikarbanhchoun/show`
        params = {
          roubavontbokkol_deikarbanhchoun_uuid: this.$route.params.roubavontbokkol_deikarbanhchoun_uuid
        }
      }

      this.$axios.post(api, params)
        .then(({data}) => {
          if (data.data) {
            this.references = data.data.references
            if (data.data.architecture && this.$route.params.roubavontbokkol_deikarbanhchoun_uuid) {
              const architecture = data.data.architecture
              this.architecture = {
                id: architecture.id,
                name: `${architecture.unique_number} - (${architecture.name_km} ${architecture.name_en})`
              }
            }
          }
        })
        .catch((error) => {
          this.onResponseError(error)
        })
    },
    onDeleteReference(element) {
      this.onConfirm()
        .then((accept) => {
          if (accept) {
            this.references.splice(this.references.indexOf(element), 1)
          }
        })
    },
    searchArchitectures: debounce(function (query_text = null) {
      this.$isLoading(true)
      this.$axios.post(`${this.baseUrl}/api/backend/roubavontbokkols/note/search-architecture`, {
        query_text: query_text,
      }).then((response) => {
        if (response.data && response.data.data) {
          const data = response.data.data
          this.architectures = data.architectures
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    }),
  }
}
</script>

<style scoped>
.drag-item {
  display: grid;
  grid-template-columns: 20px 1fr 30px;
}

p.text-muted {
  padding: 25px;
  background-color: #dddddd;
  width: 100%;
}
</style>
