import Permissions from "../../permissions"
import Index from '../../views/investorConstruction/Index'
import {personalityInvestorConstructionRouters} from "./personalityRouters"
import {companyInvestorConstructionRouters} from "./company"

export const investorConstructionRouters = {
  path: 'investor-construction',
  name: 'investor-construction',
  component: Index,
  meta: {
    permissions: [Permissions.backend.investorConstruction.manage],
    groups: 'investor-construction',
  },
  children: [
    personalityInvestorConstructionRouters,
    companyInvestorConstructionRouters,
  ]
}
