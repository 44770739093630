<template>
  <div class="gender">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t('label.gender') }}
        </h3>
      </div>
      <div class="box-body">
        <div>
          <table
            id="gender-table"
            class="table table-hover table-full-width table-striped table-bordered"
          >
            <thead>
              <tr>
                <th>{{ $t('table.nameInKhmer') }}</th>
                <th>{{ $t('table.nameInEnglish') }}</th>
                <th>{{ $t('table.createdAt') }}</th>
              </tr>
            </thead>
            <tbody />
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListGenders',
  metaInfo() {
    return {
      title: this.$t('menu.gender'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      oTable: null
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.refreshTable()
      this.oTable.draw(true)
    }
  },
  mounted() {
    this.oTable = this.refreshTable()
  },
  beforeDestroy() {
    this.oTable.clear()
    this.oTable.destroy()
  },
  methods: {
    refreshTable() {
      let self = this
      return $('#gender-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: process.env.VUE_APP_API + '/api/backend/gender/datatable',
          error: (xhr) => {
            self.onResponseError(xhr)
          }
        },
        columns: [
          {data: 'name_km', name: 'name_km', title: self.$t('table.nameInKhmer')},
          {data: 'name_en', name: 'name_en', title: self.$t('table.nameInEnglish')},
          {
            data: 'created_at',
            name: 'created_at',
            orderable: false,
            searchable: false,
            width: '18%',
            title: self.$t('table.createdAt'),
            render: (data) => {
              return this.getDate(data)
            }
          }
        ],
        language: {
          url: self.getLocale,
        },
        fixedColumns: true
      })
    }
  },
}
</script>

<style scoped>

</style>
