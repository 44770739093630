<template>
  <table class="table table-bordered table-striped">
    <thead>
      <tr>
        <th
          colspan="2"
          class="column-1"
        >
          {{ $t('label.projectType') }}
        </th>
        <th class="column-other">
          {{ $t('label.total') }}
        </th>
        <th class="column-other">
          {{ $t('label.thisYear') }}
        </th>
        <th class="column-other">
          {{ $t('label.thisMonth') }}
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(item, key) in arrayData">
        <tr :key="`${100 + key}`">
          <td
            colspan="2"
            style="vertical-align: middle"
          >
            <div style="display: inline-block">
              <div
                class="circle"
                :class="opened.includes(item.uuid) ? 'closed' : 'opened'"
                @click="toggle(item.uuid)"
              >
                <i
                  class="fas icon"
                  :class="opened.includes(item.uuid) ? 'fa-angle-up' : 'fa-angle-down'"
                />
              </div>
            </div>
            <div style="display: inline-block">
              {{ item['name_' + $i18n.locale] }}
            </div>
          </td>
          <td>
            <div>
              <span>{{ $t('count.project') }} : </span>
              <span class="text-bold">{{ item.project_total }}</span><br>

              <span>{{ $t('label.investmentBudget') }} : </span>
              <span class="text-bold">{{ formatPrice(item.budget_total) }} $</span><br>

              <span>{{ $t('label.surface') }} : </span>
              <span class="text-bold">{{ formatPrice(item.surface_total) }} m<sup>2</sup></span><br>
            </div>
          </td>
          <td>
            <div>
              <span>{{ $t('count.project') }} : </span>
              <span class="text-bold">{{ item.project_year }}</span><br>

              <span>{{ $t('label.investmentBudget') }} : </span>
              <span class="text-bold">{{ formatPrice(item.budget_year) }} $</span><br>

              <span>{{ $t('label.surface') }} : </span>
              <span class="text-bold">{{ formatPrice(item.surface_year) }} m<sup>2</sup></span><br>
            </div>
          </td>
          <td>
            <div>
              <span>{{ $t('count.project') }} : </span>
              <span class="text-bold">{{ item.project_month }}</span><br>

              <span>{{ $t('label.investmentBudget') }} : </span>
              <span class="text-bold">{{ formatPrice(item.budget_month) }} $</span><br>

              <span>{{ $t('label.surface') }} : </span>
              <span class="text-bold">{{ formatPrice(item.surface_month) }} m<sup>2</sup></span><br>
            </div>
          </td>
        </tr>
        <tr
          v-if="opened.includes(item.uuid)"
          :key="`${200 + key}`"
        >
          <td colspan="2" />
          <td>
            <div>
              <span>{{ $t('label.building') }} : </span>
              <span class="text-bold">{{ formatPrice(item.building_total) }}</span><br>
              <template v-if="item.unit_total && item.unit_total.length > 0">
                <template
                  v-for="(unit_item, key_unit_item) in item.unit_total"
                >
                  <div :key="key_unit_item">
                    <span>{{ unit_item['name_'+ $i18n.locale] }} : </span>
                    <span class="text-bold">{{ unit_item.value ? unit_item.value.sum ? formatPrice(unit_item.value.sum) : 0 : 0 }}</span>
                  </div>
                </template>
              </template>
            </div>
          </td>
          <td>
            <div>
              <span>{{ $t('label.building') }} : </span>
              <span class="text-bold">{{ formatPrice(item.building_year) }}</span><br>
              <template v-if="item.unit_year.length > 0">
                <template v-for="(unit_item, key_unit_item_year) in item.unit_year">
                  <div :key="key_unit_item_year">
                    <span>{{ unit_item['name_'+ $i18n.locale] }} : </span>
                    <span class="text-bold">{{ unit_item.value ? unit_item.value.sum ? unit_item.value.sum : 0 : 0 }}</span>
                  </div>
                </template>
              </template>
            </div>
          </td>
          <td>
            <div>
              <span>{{ $t('label.building') }} : </span>
              <span class="text-bold">{{ formatPrice(item.building_month) }}</span><br>
              <template v-if="item.unit_month.length > 0">
                <template v-for="(unit_item, key_unit_item_month) in item.unit_month">
                  <div :key="key_unit_item_month">
                    <span>{{ unit_item['name_'+ $i18n.locale] }} : </span>
                    <span class="text-bold">{{ unit_item.value ? unit_item.value.sum ? unit_item.value.sum : 0 : 0 }}</span>
                  </div>
                </template>
              </template>
            </div>
          </td>
        </tr>
        <template v-if="item.child">
          <template
            v-for="(sub_item, key_sub_item) in item.child"
          >
            <tr :key="key_sub_item">
              <td />
              <td style="vertical-align: middle">
                <div style="display: inline-block">
                  <div
                    class="circle"
                    :class="opened.includes(sub_item.uuid) ? 'closed' : 'opened'"
                    @click="toggle(sub_item.uuid)"
                  >
                    <i
                      class="fas icon"
                      :class="opened.includes(sub_item.uuid) ? 'fa-angle-up' : 'fa-angle-down'"
                    />
                  </div>
                </div>
                <div style="display: inline-block">
                  {{ sub_item['name_' + $i18n.locale] }}
                </div>
              </td>
              <td>
                <div>
                  <span>{{ $t('count.project') }} : </span><span class="text-bold">{{ sub_item.project_total }}</span><br>
                  <span>{{ $t('label.investmentBudget') }} : </span><span class="text-bold">{{ formatPrice(sub_item.budget_total) }} $</span><br>
                  <span>{{ $t('label.surface') }} : </span><span class="text-bold">{{ formatPrice(sub_item.surface_total) }} m<sup>2</sup></span>
                </div>
              </td>
              <td>
                <div>
                  <span>{{ $t('count.project') }} : </span><span class="text-bold">{{ sub_item.project_year }}</span><br>
                  <span>{{ $t('label.investmentBudget') }} : </span><span class="text-bold">{{ formatPrice(sub_item.budget_year) }} $</span><br>
                  <span>{{ $t('label.surface') }} : </span><span class="text-bold">{{ formatPrice(sub_item.surface_year) }} m<sup>2</sup></span>
                </div>
              </td>
              <td>
                <div>
                  <span>{{ $t('count.project') }} : </span><span class="text-bold">{{ sub_item.project_month }}</span><br>
                  <span>{{ $t('label.investmentBudget') }} : </span><span class="text-bold">{{ formatPrice(sub_item.budget_month) }} $</span><br>
                  <span>{{ $t('label.surface') }} : </span><span class="text-bold">{{ formatPrice(sub_item.surface_month) }} m<sup>2</sup></span>
                </div>
              </td>
            </tr>
            <tr
              v-if="opened.includes(sub_item.uuid)"
              :key="key_sub_item + Math.random()"
            >
              <td />
              <td />
              <td>
                <div>
                  <span>{{ $t('label.building') }} : </span>
                  <span class="text-bold">{{ formatPrice(sub_item.building_total) }}</span><br>
                  <template v-if="sub_item.unit_total.length > 0">
                    <template v-for="(unit_item, key_unit_item_total) in sub_item.unit_total">
                      <div :key="key_unit_item_total">
                        <span>{{ unit_item['name_'+ $i18n.locale] }} : </span>
                        <span class="text-bold">{{ unit_item.value ? unit_item.value.sum ? formatPrice(unit_item.value.sum) : 0 : 0 }}</span>
                      </div>
                    </template>
                  </template>
                </div>
              </td>
              <td>
                <div>
                  <span>{{ $t('label.building') }} : </span>
                  <span class="text-bold">{{ formatPrice(sub_item.building_year) }}</span><br>
                  <template v-if="sub_item.unit_year.length > 0">
                    <template v-for="(unit_item, key_unit_item_year) in sub_item.unit_year">
                      <div :key="key_unit_item_year">
                        <span>{{ unit_item['name_'+ $i18n.locale] }} : </span>
                        <span class="text-bold">{{ unit_item.value ? unit_item.value.sum ? unit_item.value.sum : 0 : 0 }}</span>
                      </div>
                    </template>
                  </template>
                </div>
              </td>
              <td>
                <div>
                  <span>{{ $t('label.building') }} : </span>
                  <span class="text-bold">{{ formatPrice(sub_item.building_month) }}</span><br>
                  <template v-if="sub_item.unit_month.length > 0">
                    <template v-for="(unit_item, key_unit_item_month) in sub_item.unit_month">
                      <div :key="key_unit_item_month">
                        <span>{{ unit_item['name_'+ $i18n.locale] }} : </span>
                        <span class="text-bold">{{ unit_item.value ? unit_item.value.sum ? unit_item.value.sum : 0 : 0 }}</span>
                      </div>
                    </template>
                  </template>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "TableReportProjectByType",
  props: {
    arrayData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      opened: [],
    }
  },
  methods: {
    toggle(uuid) {
      const index = this.opened.indexOf(uuid)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(uuid)
      }
    },
  }
}
</script>

<style scoped>
  .column-1 {
    width: 30%;
  }

  .column-other {
    width: 20%;
  }

  td div span {
    line-height: 2;
  }

  .opened {
    background: var(--blue);
  }

  .closed {
    background: var(--red);
  }

  .circle {
    width: 25px;
    height: 25px;
    position: relative;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .circle:hover {
    cursor: pointer;
  }

  .icon {
    color: var(--white);
  }
</style>
