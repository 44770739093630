import Title from "./../../views/settings/title/Index"
import List from "./../../views/settings/title/List"
import Create from "./../../views/settings/title/Create"
import Edit from "./../../views/settings/title/Edit"
import Show from "./../../views/settings/title/Show"
import Permissions from "../../permissions"

export const titleRouters = {
  path: 'title',
  component: Title,
  redirect: 'title',
  meta: {
    groups: 'title',
    permissions: [Permissions.backend.setting.title.manage]
  },
  children: [
    {
      path: '',
      name: 'list-title',
      component: List,
      meta: {
        groups: 'title',
        permissions: [Permissions.backend.setting.title.list],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Title',
            name_km: 'ងារ',
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-title',
      component: Create,
      meta: {
        groups: 'title',
        permissions: [Permissions.backend.setting.title.store],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Title',
            name_km: 'ងារ',
          },
          {
            name_en: 'New',
            name_km: 'បង្កើតថ្មី'
          }
        ]
      }
    },
    {
      path: 'edit/:title_uuid',
      name: 'edit-title',
      component: Edit,
      meta: {
        groups: 'title',
        permissions: [Permissions.backend.setting.title.edit],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Title',
            name_km: 'ងារ',
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'show/:title_uuid',
      name: 'show-title',
      component: Show,
      meta: {
        groups: 'title',
        permissions: [Permissions.backend.setting.title.show],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Title',
            name_km: 'ងារ',
          },
          {
            name_en: 'View',
            name_km: 'មើល'
          }
        ]
      }
    }
  ]
}
