import Index from "../views/dashboard/Index"
import Welcome from "../views/dashboard/pages/Welcome"
import Summary from "../views/dashboard/pages/Summary"
import LevelMinistry from "../views/dashboard/pages/LevelMinistry"
import LevelProvince from "../views/dashboard/pages/LevelProvince"
import Permissions from "../permissions"

export const dashboardRoutes = {
  path: 'dashboard',
  name: 'admin',
  component: Index,
  redirect: 'dashboard/level-ministry',
  meta: {
    groups: 'admin'
  },
  children: [
    {
      path: 'welcome',
      name: 'welcome',
      component: Welcome,
      meta: {
        groups: 'welcome',
      }
    },
    {
      path: 'summary',
      name: 'summary',
      component: Summary,
      meta: {
        permissions: [Permissions.backend.dashboard.summary.manage],
        groups: 'summary',
      }
    },
    {
      path: 'level-ministry',
      name: 'level-ministry',
      component: LevelMinistry,
      meta: {
        permissions: [Permissions.backend.dashboard.ministryLevel.manage],
        groups: 'level-ministry',
      }
    },
    {
      path: 'level-province',
      name: 'level-province',
      component: LevelProvince,
      meta: {
        permissions: [Permissions.backend.dashboard.provinceLevel.manage],
        groups: 'level-province',
      }
    },
  ]
}
