<template>
  <li
    class="treeview"
    :class="{'menu-open': opened}"
  >
    <a href="javascript:void(0)">
      <i :class="menu.icon" /> <span>{{ menu.label }}</span>
      <span class="pull-right-container">
        <i class="fa fa-angle-left pull-right" />
      </span>
    </a>
    <ul
      class="treeview-menu"
      :style="opened ? 'display: block' : 'display: none'"
    >
      <template v-for="(child, childKey) in menu.children">
        <template v-if="can(child.permissions) && hasRoles(child.roles)">
          <template v-if="child.route.hasOwnProperty('groups') && $route.meta.hasOwnProperty('groups')">
            <li
              :key="childKey"
              v-ripple
              :class="{active: $route.meta.groups === child.route.groups}"
            >
              <router-link :to="child.route">
                {{ child.label }}
              </router-link>
            </li>
          </template>
          <template v-else>
            <li
              :key="childKey"
              v-ripple
              :class="{active: $route.name === child.route.name}"
            >
              <router-link :to="child.route">
                {{ child.label }}
              </router-link>
            </li>
          </template>
        </template>
      </template>
    </ul>
  </li>
</template>

<script>
export default {
  name: "TreeMenu",
  props: {
    menu: {
      label: String,
      route: String,
      icon: String,
      permissions: Array,
      children: Array
    },
  },
  computed: {
    opened() {
      try {
        return this.menu.children.some(child => child.route.groups === this.$route.meta.groups)
      } catch (e) {
        return this.menu.children.some(child => child.route.name === this.$route.name)
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      $(document.body).tree({selector: ".sidebar-menu"})
    })
  }
}
</script>

<style scoped>
.treeview-menu > li > a {
  font-size: 14px;
  font-family: var(--secondary-font);
}
</style>
