import Layout from "./../../views/settings/gender/Index"
import List from "./../../views/settings/gender/List"
import Permissions from "../../permissions"

export const genderRouters = {
  path: 'gender',
  component: Layout,
  redirect: 'gender',
  meta: {
    permissions: [Permissions.backend.setting.gender.manage],
    groups: 'gender',
  },
  children: [
    {
      path: '',
      name: 'list-gender',
      component: List,
      meta: {
        permissions: [Permissions.backend.setting.gender.list],
        groups: 'gender',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Gender',
            name_km: 'ភេទ'
          }
        ]
      }
    }
  ]
}
