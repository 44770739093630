export const occupancyValidationMixins = {
  computed: {
    permitNumberValidation() {
      return this.getValidationColumn(this.validations, 'building_permit_number')
    },
    permitDateValidation() {
      return this.getValidationColumn(this.validations, 'building_permit_number')
    },
    constructionOnTheLandParcelValidation() {
      return this.getValidationColumn(this.validations, 'construction_on_the_land_parcel')
    },
    openConstructionValidation() {
      return this.getValidationColumn(this.validations, 'construction_letter_id')
    },
    addressInKhmerValidation() {
      return this.getValidationColumn(this.validations, 'address_km')
    },
    addressInEnglishValidation() {
      return this.getValidationColumn(this.validations, 'address_en')
    },
    asBuildDrawingNumberValidation() {
      return this.getValidationColumn(this.validations, 'as_build_drawing_number')
    },
    asBuildDrawingDateValidation() {
      return this.getValidationColumn(this.validations, 'as_build_drawing_date')
    },
    constructionOwnerKmValidation() {
      return this.getValidationColumn(this.validations, 'construction_owner_km')
    },
    constructionOwnerEnValidation() {
      return this.getValidationColumn(this.validations, 'construction_owner_en')
    },
    certificateOfComplianceNumberValidation() {
      return this.getValidationColumn(this.validations, 'compliance_number')
    },
    certificateOfComplianceDateValidation() {
      return this.getValidationColumn(this.validations, 'compliance_date')
    },
    certificateOfComplianceByKmValidation() {
      return this.getValidationColumn(this.validations, 'compliance_by_km')
    },
    certificateOfComplianceByEnValidation() {
      return this.getValidationColumn(this.validations, 'compliance_by_en')
    },
    constructionOccupancyKmValidation() {
      return this.getValidationColumn(this.validations, 'construction_occupancy_km')
    },
    constructionOccupancyEnValidation() {
      return this.getValidationColumn(this.validations, 'construction_occupancy_en')
    },
    totalFloorAreaValidation() {
      return this.getValidationColumn(this.validations, 'total_floor_area')
    },
    architectureDesignByKmValidation() {
      return this.getValidationColumn(this.validations, 'architecture_design_by_km')
    },
    architectureDesignByEnValidation() {
      return this.getValidationColumn(this.validations, 'architecture_design_by_en')
    },
    architectureDesignReviewedByKmValidation() {
      return this.getValidationColumn(this.validations, 'architecture_design_reviewed_by_km')
    },
    architectureDesignReviewedByEnValidation() {
      return this.getValidationColumn(this.validations, 'architecture_design_reviewed_by_en')
    },
    structuralDesignKmValidation() {
      return this.getValidationColumn(this.validations, 'structural_design_km')
    },
    structuralDesignEnValidation() {
      return this.getValidationColumn(this.validations, 'structural_design_en')
    },
    structuralDesignByKmValidation() {
      return this.getValidationColumn(this.validations, 'structural_design_by_km')
    },
    structuralDesignByEnValidation() {
      return this.getValidationColumn(this.validations, 'structural_design_by_en')
    },
    structuralDesignReviewedByKmValidation() {
      return this.getValidationColumn(this.validations, 'structural_design_reviewed_by_km')
    },
    structuralDesignReviewedByEnValidation() {
      return this.getValidationColumn(this.validations, 'structural_design_reviewed_by_en')
    },
    mepSystemReviewedByKmValidation() {
      return this.getValidationColumn(this.validations, 'mep_system_reviewed_by_km')
    },
    mepSystemReviewedByEnValidation() {
      return this.getValidationColumn(this.validations, 'mep_system_reviewed_by_en')
    },
    fireSafetySystemInspectedByKmValidation() {
      return this.getValidationColumn(this.validations, 'fire_safety_system_inspected_by_km')
    },
    fireSafetySystemInspectedByEnValidation() {
      return this.getValidationColumn(this.validations, 'fire_safety_system_inspected_by_en')
    },
    buildingWorkByKmValidation() {
      return this.getValidationColumn(this.validations, 'building_work_by_km')
    },
    buildingWorkByEnValidation() {
      return this.getValidationColumn(this.validations, 'building_work_by_en')
    },
  }
}
