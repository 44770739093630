import { render, staticRenderFns } from "./AsoraBackButton.vue?vue&type=template&id=6661ec7c&scoped=true&"
import script from "./AsoraBackButton.vue?vue&type=script&lang=js&"
export * from "./AsoraBackButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6661ec7c",
  null
  
)

export default component.exports