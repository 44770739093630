import ProjectUnit from "./../../views/settings/projectUnit/Index"
import List from "./../../views/settings/projectUnit/List"
import Create from "./../../views/settings/projectUnit/Create"
import Edit from "./../../views/settings/projectUnit/Edit"
import Show from "./../../views/settings/projectUnit/Show"
import Permissions from "../../permissions"

export const projectUnitRouters = {
  path: 'project-unit',
  component: ProjectUnit,
  redirect: 'project-unit',
  meta: {
    permissions: [Permissions.backend.setting.projectUnit.manage],
    groups: 'project-unit',
  },
  children: [
    {
      path: '',
      name: 'list-project-unit',
      component: List,
      meta: {
        permissions: [Permissions.backend.setting.projectUnit.list],
        groups: 'project-unit',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Project Unit',
            name_km: 'ខ្នាតគម្រោង'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-project-unit',
      component: Create,
      meta: {
        permissions: [Permissions.backend.setting.projectUnit.store],
        groups: 'project-unit',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'New Project Unit',
            name_km: 'បន្ថែមខ្នាតគម្រោង'
          }
        ]
      }
    },
    {
      path: 'edit/:project_unit_uuid',
      name: 'edit-project-unit',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.setting.projectUnit.edit],
        groups: 'project-unit',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Edit Project Unit',
            name_km: 'កែប្រែខ្នាតគម្រោង'
          }
        ]
      }
    },
    {
      path: 'show/:project_unit_uuid',
      name: 'show-project-unit',
      component: Show,
      meta: {
        permissions: [Permissions.backend.setting.projectUnit.show],
        groups: 'project-unit',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Show Project Unit',
            name_km: 'បង្ហាញព័ត៌មានខ្នាតគម្រោង'
          }
        ]
      }
    }
  ]
}
