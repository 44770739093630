<template>
  <div class="row">
    <div :class="divContainerClass">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('label.team') }}
          </h3>
          <div class="box-tools">
            <button
              v-if="can([Permissions.backend.team.store])"
              class="btn btn-primary btn-sm margin-r-5"
              @click="$router.push({name: 'create-team'})"
            >
              <i class="fa fa-plus" /> {{ $t('button.newTeam') }}
            </button>
            <template v-if="can([Permissions.backend.team.filter])">
              <button
                class="btn btn-danger btn-sm"
                @click="toggleFilter"
              >
                <i class="fa fa-filter" /> {{ $t('string.filter') }}
              </button>
            </template>
          </div>
        </div>
        <div class="box-body">
          <div>
            <table
              id="team-table"
              class="table table-hover table-full-width table-striped table-bordered"
            >
              <thead>
                <tr>
                  <th style="width: 100px;">
                    {{ $t('label.name') }}
                  </th>
                  <th style="width: 200px;">
                    {{ $t('label.team_category') }}
                  </th>
                  <th style="width: 100px;">
                    {{ $t('table.teamLeader') }}
                  </th>
                  <th>{{ $t('table.teamMembers') }}</th>
                  <th style="width: 180px;">
                    {{ $t('table.province') }}
                  </th>
                  <th class="width-created-at">
                    {{ $t('table.createdAt') }}
                  </th>
                  <th class="width-action">
                    {{ $t('table.action') }}
                  </th>
                </tr>
              </thead>
              <tbody />
            </table>
          </div>
        </div>
      </div>
    </div>
    <template v-if="can([Permissions.backend.team.filter]) && showFilter">
      <div class="col-md-2 pl-md-0">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('string.filter') }}
            </h3>
          </div>
          <div class="box-body">
            <div class="form-group">
              <label>{{ $t('label.teamCategory') }}</label>
              <select
                v-model="team_category_id"
                class="form-control"
                name="team-category"
              >
                <option
                  :value="null"
                  selected
                >
                  {{ $t('string.all') }}
                </option>
                <template v-for="(item, key) in team_categories">
                  <option
                    :key="key"
                    :value="item.id"
                  >
                    {{ item['name_' + $i18n.locale] }}
                  </option>
                </template>
              </select>
            </div>
            <div class="form-group">
              <label>{{ $t('table.teamLeader') }}</label>
              <input
                v-model="team_leader"
                :placeholder="$t('table.teamLeader')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.teamMembers') }}</label>
              <input
                v-model="member"
                :placeholder="$t('table.teamMembers')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.province') }}</label>
              <select
                v-model="province"
                class="form-control"
                name="province"
              >
                <option
                  :value="null"
                  selected
                >
                  {{ $t('string.all') }}
                </option>
                <template v-for="(item, key) in provinces">
                  <option
                    :key="key"
                    :value="item.code"
                  >
                    {{ item['name_' + $i18n.locale] }}
                  </option>
                </template>
              </select>
            </div>
            <div class="form-group">
              <label>{{ $t('table.teamName') }}</label>
              <input
                v-model="name"
                :placeholder="$t('table.teamName')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <div class="row">
                <div class="form-group col-md-6">
                  <button
                    class="btn btn-primary btn-block btn-sm"
                    @click="search"
                  >
                    <i class="fa fa-search" />
                    {{ $t('button.search') }}
                  </button>
                </div>
                <div class="form-group col-md-6 pl-lg-0">
                  <button
                    class="btn btn-default btn-block btn-sm"
                    @click="reset"
                  >
                    <i class="fas fa-redo-alt" />
                    {{ $t('button.reset') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Permissions from "@/permissions"
export default {
  name: "List",
  metaInfo() {
    return {
      title: this.$t('menu.team'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      Permissions,
      showFilter: false,
      team_leader: null,
      member: null,
      province: null,
      provinces: [],
      team_categories: [],
      team_category_id: null,
      oTable: null,
      name: null
    }
  },
  computed: {
    divContainerClass() {
      return this.showFilter ? 'col-md-10' : 'col-md-12'
    }
  },
  watch: {
    '$i18n.locale' () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.refreshTable()
      this.oTable.draw(true)
    }
  },
  mounted() {
    this.oTable = this.refreshTable()
    this.getTeamCategory()
    this.enabledAction()
    this.getProvinces()

  },
  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter
      this.oTable.columns.adjust().draw()
    },
    search() {
      this.oTable.draw(true)
    },
    reset() {
      this.team_leader = null
      this.member = null
      this.province = null
      this.team_category_id = null
      this.name = null
      this.oTable.draw(true)
    },
    getProvinces() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/location/get-location')
        .then((response) => {
          this.provinces = response.data.data
        })
        .catch((error) => {
          this.onResponseError(error)
        })
    },
    getTeamCategory() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/team-category/get-option')
        .then((response) => {
          this.team_categories = response.data.data
        })
    },
    refreshTable() {
      let self = this
      return $('#team-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        sScrollX: "100%",
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: process.env.VUE_APP_API + '/api/backend/team/datatable',
          data: (d) => {
            d.edit = self.$t('label.edit')
            d.show = self.$t('label.show')
            d.delete = self.$t('label.delete')
            d.na = self.$t('string.na')
            d.team_leader = self.team_leader
            d.member = self.member
            d.province = self.province
            d.name = self.name
            d.team_category_id = self.team_category_id
          },
          error: (xhr) => {
            self.onResponseError(xhr)
          }
        },
        columns: [
          {
            data: 'name_km',
            name: 'name_km',
            title: self.$t('label.name'),
            render: (data, type, row) => {
              return row['name_' + this.$i18n.locale]
            }
          },
          {
            data: 'category.name_km',
            name: 'category.name_km',
            title: self.$t('label.team_category'),
            render: (data, type, row) => {
              if (row.category !== null) {
                return row['category']['name_' + this.$i18n.locale]
              }
              return this.$t('string.na')
            }
          },
          {
            data: 'team_leader',
            name: 'team_leader',
            title: self.$t('table.teamLeader'),
            orderable: false,
          },
          {
            data: 'team_members',
            name: 'team_members',
            title: self.$t('table.teamMembers'),
            orderable: false,
          },
          {
            data: 'response_provinces',
            name: 'response_provinces',
            title: self.$t('table.responseProvinces'),
            orderable: false,
          },
          {
            data: 'created_at',
            name: 'created_at',
            orderable: true,
            searchable: false,
            title: self.$t('table.createdAt'),
            render: (data) => {
              return this.getDate(data)
            }
          },
          {
            data: 'action',
            name: 'action',
            orderable: false,
            searchable: false,
            title: self.$t('table.action')
          }
        ],
        language: {
          url: self.getLocale,
        },
        order: [
          [5, 'desc']
        ],
        drawCallback () {
          $('[data-toggle="tooltip"]').tooltip()
        }
      })
    },
    enabledAction() {
      let self = this
      this.clearEventHandler([
        '.btn-edit',
        '.btn-show',
        '.btn-trash',
        '.sidebar-toggle',
      ])
      $(document).on('click', '.sidebar-toggle', function () {
        self.oTable.columns.adjust().draw()
      })
      $(document).on('click', '.btn-edit', function () {
        self.$router.push({
          name: 'edit-team',
          params: {
            team_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-show', function () {
        self.$router.push({
          name: 'show-team',
          params: {
            team_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-trash', function () {
        self.deleteTeam($(this).attr('data-uuid'))
      })
    },
    deleteTeam(uuid) {
      let self = this
      this.$swal.fire({
        title: self.i18nSwalTitle,
        text: self.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: self.i18nSwalNo,
        confirmButtonText: self.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/team/delete', {
            team_uuid: uuid
          }).then(() => {
            this.showSwalSuccess()
            this.oTable.draw(true)
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
