import Permissions from "../../permissions"
import Index from '../../views/hasBoard/Index'
import {hasBoardArchitectureRouters} from "./architecture"
import {hasBoardEngineerRouters} from "./engineer"

export const hasBoardRouters = {
  path: 'has-board',
  name: 'has-board',
  component: Index,
  meta: {
    permissions: [Permissions.backend.hasBoard.manage],
    groups: 'has-board',
  },
  children: [
    hasBoardArchitectureRouters,
    hasBoardEngineerRouters,
  ]
}
