<template>
  <div class="row">
    <div :class="divContainerClass">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('label.projectProvinceLevel') }}
          </h3>
          <div class="box-tools">
            <template v-if="can($Permissions.backend.provinceConstructionData.store)">
              <button
                v-if="can($Permissions.backend.provinceConstructionData.store)"
                class="btn btn-primary btn-sm margin-r-5"
                @click="$router.push({name: 'create-construction-data'})"
              >
                <i class="fa fa-plus" /> {{ $t('button.newCompany') }}
              </button>
            </template>
            <template v-if="can($Permissions.backend.provinceConstructionData.filter)">
              <button
                class="btn btn-danger btn-sm"
                @click="toggleFilter"
              >
                <i class="fa fa-filter" /> {{ $t('string.filter') }}
              </button>
            </template>
          </div>
        </div>
        <div class="box-body">
          <div>
            <table
              id="project-provincial-level-table"
              class="table table-hover table-full-width table-striped table-bordered"
            >
              <thead>
                <tr>
                  <th>{{ $t('label.province') }}</th>
                  <th>{{ $t('table.action') }}</th>
                </tr>
              </thead>
              <tbody />
            </table>
          </div>
        </div>
      </div>
    </div>
    <template v-if="showFilter">
      <div class="col-md-2 pl-md-0">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('string.filter') }}
            </h3>
          </div>
          <div class="box-body">
            <div class="form-group">
              <label>{{ $t('string.location') }}</label>
              <select
                v-model="province_id"
                class="form-control"
              >
                <option :value="null">
                  {{ $t('string.all') }}
                </option>
                <option
                  v-for="(province, key) in provinces"
                  :key="key"
                  :value="province.id"
                >
                  {{ province['name_' + $i18n.locale] }}
                </option>
              </select>
            </div>

            <div class="form-group">
              <label>{{ $t('string.startDateConstructionData') }}</label>
              <date-picker
                v-model="start_date"
                :lang="datepickerLocale[$i18n.locale].lang"
                :placeholder="$t('label.date')"
                class="table-full-width"
                format="DD/MM/YYYY"
              />
            </div>

            <div class="form-group">
              <label>{{ $t('string.endDateConstructionData') }}</label>
              <date-picker
                v-model="end_date"
                :lang="datepickerLocale[$i18n.locale].lang"
                :placeholder="$t('label.date')"
                class="table-full-width"
                format="DD/MM/YYYY"
              />
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <button
                    class="btn btn-primary btn-block btn-sm"
                    @click="search"
                  >
                    <i class="fa fa-search" />
                    {{ $t('button.search') }}
                  </button>
                </div>
                <div class="col-md-6 pl-lg-0">
                  <button
                    class="btn btn-default btn-block btn-sm"
                    @click="reset"
                  >
                    <i class="fas fa-redo-alt" />
                    {{ $t('button.reset') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "List",
  metaInfo() {
    return {
      title: this.$t('menu.constructionProvinceData'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      showFilter: false,
      oTable: null,
      provinces: [],
      province_id: null,
      start_date: null,
      end_date: null,
    }
  },
  computed: {
    divContainerClass() {
      return this.showFilter ? 'col-md-10' : 'col-md-12'
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.refreshTable()
      this.oTable.draw(true)
    }
  },
  mounted() {
    this.oTable = this.refreshTable()
    this.enabledAction()
    this.getProvinces()
  },
  beforeDestroy() {
    this.oTable.clear()
    this.oTable.destroy()
  },
  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter
      this.oTable.columns.adjust().draw()
    },
    reset() {
      this.province_id = null
      this.start_date = null
      this.end_date = null
      this.oTable.draw(true)
    },

    search() {
      this.oTable.draw(true)
    },
    getProvinces() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/location/get-location')
        .then((res) => {
          if (res.data.data.length > 0) {
            this.provinces = res.data.data
          } else {
            this.provinces = []
          }
        })
    },
    refreshTable() {
      let self = this
      return $('#project-provincial-level-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: process.env.VUE_APP_API + '/api/backend/project-data-provincial-level/datatable',
          data: (d) => {
            d.show = self.$t('label.show')
          },
          error: (xhr) => {
            self.onResponseError(xhr)
          }
        },
        columns: [
          {
            data: 'name_en',
            name: 'name_en',
            title: self.$t('label.province'),
            render: (data, type, row) => {
              if (row !== null) {
                return `${row['name_' + self.$i18n.locale]}`
              }
              return self.$t('string.na')
            }
          },
          {
            data: 'action',
            name: 'action',
            width: '130px',
            orderable: false,
            searchable: false,
            title: self.$t('table.action')
          }
        ],
        language: {
          url: self.getLocale,
        },
      })
    },
    enabledAction() {
      let self = this
      this.clearEventHandler([
        '.btn-edit',
        '.btn-show',
        '.btn-trash',
        '.sidebar-toggle',
      ])
      $(document).on('click', '.sidebar-toggle', function () {
        self.oTable.columns.adjust().draw()
      })

      $(document).on('click', '.btn-show', function () {
        self.$router.push({
          name: 'show-construction-data',
          params: {code: $(this).attr('data-uuid')}
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
