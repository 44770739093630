import ProjectType from "./../../views/settings/projectType/Index"
import List from "./../../views/settings/projectType/List"
import Create from "./../../views/settings/projectType/Create"
import Edit from "./../../views/settings/projectType/Edit"
import Show from "./../../views/settings/projectType/Show"
import Permissions from "../../permissions"

export const projectTypeRouters = {
  path: 'project-type',
  component: ProjectType,
  redirect: 'project-type',
  meta: {
    permissions: [Permissions.backend.setting.projectType.manage],
    groups: 'project-type',
  },
  children: [
    {
      path: '',
      name: 'list-project-type',
      component: List,
      meta: {
        permissions: [Permissions.backend.setting.projectType.list],
        groups: 'project-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Project Type',
            name_km: 'ប្រភេទគម្រោង'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-project-type',
      component: Create,
      meta: {
        permissions: [Permissions.backend.setting.projectType.store],
        groups: 'project-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'New Project Type',
            name_km: 'បន្ថែមប្រភេទគម្រោង'
          }
        ]
      }
    },
    {
      path: 'edit/:project_type_uuid',
      name: 'edit-project-type',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.setting.projectType.edit],
        groups: 'project-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Edit Project Type',
            name_km: 'កែប្រែគម្រោង'
          }
        ]
      }
    },
    {
      path: 'show/:project_type_uuid',
      name: 'show-project-type',
      component: Show,
      meta: {
        permissions: [Permissions.backend.setting.projectType.show],
        groups: 'project-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Show Project Type',
            name_km: 'បង្ហាញព័ត៏មានប្រភេទគម្រោង'
          }
        ]
      }
    }
  ]
}
