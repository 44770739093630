<template>
  <div class="row">
    <template v-if="inspection!==null">
      <div class="col-md-4">
        <inspection-info
          :inspection="inspection"
          @callFunction="getInspection"
        />
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('menu.map') }}
            </h3>
          </div>
          <div class="box-body">
            <div class="row">
              <div class="col-md-12">
                <GmapMap
                  :center="center"
                  :zoom="15"
                  map-type-id="roadmap"
                  style="width: 100%; height: 405px;"
                >
                  <GmapMarker
                    v-for="(m, i) in locations"
                    :key="i"
                    :icon="{url: '/img/markers/blue20x20.png'}"
                    :clickable="true"
                    :position="m"
                  />
                </GmapMap>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <inspection-form :inspection="inspection" />
      </div>
    </template>
  </div>
</template>

<script>
import InspectionInfo from "./InspectionInfo"
import InspectionForm from "./InspectionForm"

export default {
  name: "InspectionShow",
  components: {InspectionForm, InspectionInfo},
  metaInfo() {
    return {
      title: this.$t('string.viewInspection'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      inspection: null,
      locations: [],
      center: {
        lat: 11.5760397,
        lng: 104.9230519
      },
    }
  },
  created() {
    this.getInspection()
  },
  methods: {
    getInspection() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/inspection/show', {
        inspection_uuid: this.$route.params.inspection_uuid,
      }).then((res) => {
        this.inspection = res.data.data
        if (this.inspection && this.inspection.inspection_geos.length > 0) {
          return this.locations = [
            this.center
          ]
        } else {
          this.locations = this.inspection.inspection_geos.map((item) => {
            return {lat: parseFloat(item.lat), lng: parseFloat(item.lng)}
          })
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
