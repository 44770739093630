<template>
  <div class="row">
    <template v-if="!isLoading">
      <div class="col-sm-4">
        <div class="row">
          <div class="col-sm-12">
            <template v-if="project!==null">
              <project-box-info :project="project" />
            </template>
          </div>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="row">
          <div class="col-md-12">
            <div class="nav-tabs-custom">
              <ul
                class="nav nav-tabs"
                role="tablist"
              >
                <li
                  class="active"
                  role="presentation"
                >
                  <a
                    aria-controls="home"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                  >
                    {{ $t('label.constructionInspection') }}
                  </a>
                </li>
                <template v-if="can([$Permissions.backend.provinceProject.showProcessRequesting])">
                  <li role="presentation">
                    <a
                      aria-controls="profile"
                      data-toggle="tab"
                      href="#profile"
                      role="tab"
                    >
                      {{ $t('string.processRequested') }}
                    </a>
                  </li>
                </template>
                <li
                  role="presentation"
                  @click="onTabDocument"
                >
                  <a
                    aria-controls="messages"
                    data-toggle="tab"
                    href="#messages"
                    role="tab"
                  >
                    {{ $t('tab.attachDocument') }}
                  </a>
                </li>
                <li role="presentation">
                  <a
                    aria-controls="projectActivityLogs"
                    data-toggle="tab"
                    href="#projectActivityLogs"
                    role="tab"
                  >
                    {{ $t('tab.projectActivityLogs') }}
                  </a>
                </li>
              </ul>
              <div class="tab-content">
                <div
                  id="home"
                  class="tab-pane active"
                  role="tabpanel"
                >
                  <template v-if="project && project.hasOwnProperty('inspections') && project.inspections.length>0">
                    <project-inspection-timeline :inspections="project.inspections" />
                  </template>
                  <template v-else>
                    <EmptyData :description="$t('string.noInspectionRecord')" />
                  </template>
                </div>
                <template v-if="can([$Permissions.backend.provinceProject.showProcessRequesting])">
                  <div
                    id="profile"
                    class="tab-pane"
                    role="tabpanel"
                  >
                    <project-requesting-tab />
                  </div>
                </template>
                <div
                  id="messages"
                  class="tab-pane"
                  role="tabpanel"
                >
                  <template
                    v-if="project.media_allow_inspection_app && Array.isArray(project.media_allow_inspection_app) && project.media_allow_inspection_app.length > 0"
                  >
                    <div class="row margin-bottom-15">
                      <div class="col-md-12 text-right">
                        <button
                          class="btn btn-success btn-sm"
                          @click="downloadAttachment"
                        >
                          <i class="fa fa-download" /> {{ $t('label.DownloadAllAttachments') }}
                        </button>
                      </div>
                    </div>
                  </template>
                  <template v-if="fetchDocuments">
                    <upload-media
                      :draggable="false"
                      :model-uuid="$route.params.project_uuid"
                      :readonly="true"
                      button-label="string.chooseFile"
                      model-name="project"
                      preview-column="col-sm-4 col-md-3"
                    />
                  </template>
                </div>
                <div
                  id="projectActivityLogs"
                  class="tab-pane"
                  role="tabpanel"
                >
                  <project-activity-logs />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <h3 class="text-center">
        {{ $t('string.processing') }}
      </h3>
    </template>
  </div>
</template>

<script>
import ProjectInspectionTimeline from "./includes/sections/ProjectInspectionTimeline"
import ProjectBoxInfo from "./includes/sections/ProjectBoxInfo"
import UploadMedia from "../../components/UploadMedia"
import ProjectRequestingTab from "./includes/tabs/ProjectRequestingTab"
import ProjectActivityLogs from "./includes/tabs/ProjectActivityLogs"
import EmptyData from "@/components/EmptyData"
import {mapActions, mapGetters} from "vuex"

export default {
  name: "ProjectShow",
  components: {
    EmptyData,
    ProjectActivityLogs,
    ProjectRequestingTab,
    UploadMedia,
    ProjectBoxInfo,
    ProjectInspectionTimeline,
  },
  metaInfo() {
    return {
      title: this.$t('string.constructionInfo'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      project: null,
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters('imageOptionTab', {
      fetchDocuments: 'fetchDocuments'
    })
  },
  methods: {
    ...mapActions('imageOptionTab', {
      onTabDocument: 'onTabDocument',
      resetFetchDocument: 'resetFetchDocument'
    }),
    getProject() {
      this.$isLoading(this.isLoading)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project/show', {
        project_uuid: this.$route.params.project_uuid,
      }).then(({data}) => {
        if (data.data) {
          this.project = data.data
        } else {
          this.project = null
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.isLoading = false
        this.$isLoading(this.isLoading)
      })
    },
    downloadAttachment() {
      let mainRoute = '/api/backend/project/download-attachment/'
      window.open(this.$base_api + `${mainRoute}${this.project.uuid}&${localStorage.getItem(process.env.VUE_APP_TOKEN)}`)
    }
  },
  created() {
    this.getProject()
  },
  beforeDestroy() {
    this.resetFetchDocument()
  }
}
</script>

<style scoped>

</style>
