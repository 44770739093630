<template>
  <project-show />
</template>

<script>
import ProjectShow from "../project/Show"

export default {
  name: "Show",
  components: {ProjectShow},
  metaInfo() {
    return {
      title: this.$t('string.constructionInfo'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  }
}
</script>

<style scoped>

</style>
