<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('menu.licenseCompany') }}
      </h3>
    </div>
    <div class="box-body">
      <h3>{{ $t('menu.licenseCompany') }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "List",
  metaInfo() {
    return {
      title: this.$t('menu.licenseCompany'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
}
</script>

<style scoped>

</style>
