<template>
  <mission-form
    :title="$t('label.editMission')"
    label-classes="col-md-12"
    input-classes="col-md-12"
    :button="{bg: 'info', handle: 'edit'}"
  />
</template>

<script>
import MissionForm from "./Form"

export default {
  name: "Edit",
  metaInfo() {
    return {
      title: this.$t('label.editMission'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {MissionForm},
}
</script>

<style scoped>

</style>
