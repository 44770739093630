<template>
  <news-form />
</template>

<script>
import NewsForm from "./Form"

export default {
  name: "Edit",
  metaInfo() {
    return {
      title: this.$t('string.edit_a_news'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {NewsForm},
}
</script>

<style scoped>

</style>
