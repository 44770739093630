import Office from "../views/office/Index"
import List from "../views/office/List"
import Map from "../views/office/Map"
import Permissions from "../permissions"
import Create from "../views/office/Create"
import Show from "../views/office/Show"
import Edit from "../views/office/Edit"
import Tree from "../views/office/Tree"

export const officeRouters = {
  path: 'office',
  name: 'office',
  component: Office,
  redirect: 'office',
  meta: {
    permissions: [Permissions.backend.office.manage],
    groups: 'office',
  },
  children: [
    {
      path: '',
      name: 'list-office',
      component: List,
      meta: {
        permissions: [Permissions.backend.office.list],
        groups: 'office',
        breadcrumb: [
          {
            name_en: 'Office',
            name_km: 'ការិយាល័យ'
          },
          {
            name_en: 'Office List',
            name_km: 'បញ្ជីការិយាល័យ'
          }
        ]
      }
    },
    {
      path: 'tree',
      name: 'tree-office',
      component: Tree,
      meta: {
        permissions: [Permissions.backend.office.list],
        groups: 'office',
        breadcrumb: [
          {
            name_en: 'Office',
            name_km: 'ការិយាល័យ'
          },
          {
            name_en: 'Office List',
            name_km: 'បញ្ជីការិយាល័យ'
          }
        ]
      }
    },
    {
      path: 'map',
      name: 'view-office-on-map',
      component: Map,
      meta: {
        permissions: [Permissions.backend.office.map],
        groups: 'office',
        breadcrumb: [
          {
            name_en: 'Office',
            name_km: 'ការិយាល័យ'
          },
          {
            name_en: 'Map',
            name_km: 'ផែនទី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-office',
      component: Create,
      meta: {
        permissions: [Permissions.backend.office.store],
        groups: 'office',
        breadcrumb: [
          {
            name_en: 'Office',
            name_km: 'ការិយាល័យ'
          },
          {
            name_en: 'Create',
            name_km: 'បន្ថែមថ្មី'
          }
        ]
      }
    },
    {
      path: 'show/:office_uuid',
      name: 'show-office',
      component: Show,
      meta: {
        permissions: [Permissions.backend.office.show],
        groups: 'office',
        breadcrumb: [
          {
            name_en: 'Office',
            name_km: 'ការិយាល័យ'
          },
          {
            name_en: 'Show',
            name_km: 'មើល'
          }
        ]
      }
    },
    {
      path: 'edit/:office_uuid',
      name: 'edit-office',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.office.edit],
        groups: 'office',
        breadcrumb: [
          {
            name_en: 'Office',
            name_km: 'ការិយាល័យ'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
  ]
}
