import { render, staticRenderFns } from "./LetterCertificateOfOccupancy.vue?vue&type=template&id=81f5e654&scoped=true&"
import script from "./LetterCertificateOfOccupancy.vue?vue&type=script&lang=js&"
export * from "./LetterCertificateOfOccupancy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "81f5e654",
  null
  
)

export default component.exports