<template>
  <header class="main-header">
    <a
      href="javascript:void(0)"
      class="logo"
      @click="goDashboard"
    >
      <span class="logo-mini main-title">CCI</span>
      <span class="logo-lg main-title">{{ $t('string.shortAppName') }}</span>
    </a>

    <nav
      class="navbar navbar-static-top"
      role="navigation"
    >
      <a
        href="#"
        class="sidebar-toggle"
        data-toggle="push-menu"
        role="button"
      >
        <span class="sr-only">Toggle navigation</span>
      </a>
      <div
        class="navbar-custom-menu-left"
        v-if="showMunicipalOptions"
      >
        <ul class="nav navbar-nav">
          <MunicipalOptions />
        </ul>
      </div>
      <div class="navbar-custom-menu">
        <ul class="nav navbar-nav">
          <li class="dropdown">
            <a
              href="javascript:void(0)"
              data-toggle="dropdown"
              class="dropdown-toggle"
              @click="onChangeLocale"
            >
              <img
                alt="flag"
                :src="srcFlagImg"
                class="flag"
              > {{ $t('string.locale') }}
            </a>
          </li>
          <asora-header-user />
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import AsoraHeaderUser from "../components/AsoraHeaderUser"
import MunicipalOptions from "@/components/MunicipalOptions"

export default {
  name: "AsoraHeader",
  components: {MunicipalOptions, AsoraHeaderUser},
  data() {
    return {
      config: process.env
    }
  },
  computed: {
    srcFlagImg() {
      if (this.$i18n.locale === 'km') {
        return '/img/flags/km.png'
      }
      return '/img/flags/en.png'
    },
    showMunicipalOptions () {
      if (this.$route) {
        const {name} = this.$route
        return name === 'list-certificate-occupancy-province'
      }
      return false
    }
  },
  methods: {
    onChangeLocale() {
      const _locale = this.$i18n.locale
      this.$i18n.locale = _locale === 'en' ? 'km' : 'en'
      localStorage.setItem('locale', _locale)
    }
  }
}
</script>

<style scoped>
  .flag {
    height: 16px !important;
  }
</style>
