<template>
  <div
    :class="{'has-error' : validator}"
    class="form-group row"
  >
    <label
      :class="`${labelClass} ${required ? 'required': ''}`"
    >{{ labelInput }}</label>
    <div :class="inputWrapperClass">
      <multiselect
        v-bind="$attrs"
        v-on="$listeners"
      >
        <template slot="noOptions">
          {{ $t('string.noOptions') }}
        </template>
        <template slot="noResult">
          {{ $t('string.noResult') }}
        </template>
      </multiselect>
      <template v-if="validator">
        <span class="label-error">{{ validator[0] }}</span>
      </template>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "XMultiSelect",
  props: {
    labelInput: {
      type: String,
      required: true
    },
    validator: {
      type: Array,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    labelClass: {
      type: String,
      default: 'col-sm-4 control-label'
    },
    inputWrapperClass: {
      type: String,
      default: 'col-sm-8'
    }
  }
}
</script>

<style scoped>

</style>
