<template>
  <div class="form-horizontal">
    <div class="form-group row">
      <label class="control-label col-sm-3">{{ $t('label.province') }}</label>
      <div class="col-sm-9">
        <select
          v-model="province_id"
          class="form-control"
          @change="getDistrict(province_id)"
        >
          <option
            v-for="(province, key) in provinces"
            :key="key"
            :value="province.id"
          >
            {{ province['name_' + $i18n.locale] }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="control-label col-sm-3">{{ $t('label.district') }}</label>
      <div class="col-sm-9">
        <select
          v-model="district_id"
          :disabled="province_id===null"
          class="form-control"
          @change="getCommunes(district_id)"
        >
          <option
            v-for="(district, key) in districts"
            :key="key"
            :value="district.id"
          >
            {{ district['name_' + $i18n.locale] }}
          </option>
        </select>
      </div>
    </div>
    <div
      :class="communeValidation.required?'has-error':null"
      class="form-group row"
    >
      <label class="control-label col-sm-3 required">{{ $t('label.commune') }}</label>
      <div class="col-sm-9">
        <select
          v-model="village.commune_id"
          :disabled="district_id===null"
          class="form-control"
        >
          <option
            v-for="(commune, key) in communes"
            :key="key"
            :value="commune.id"
          >
            {{ commune['name_' + $i18n.locale] }}
          </option>
        </select>
        <template v-if="communeValidation.required">
          <span class="label-error">{{ communeValidation.message }}</span>
        </template>
      </div>
    </div>
    <div
      :class="nameKmRequired.required?'has-error':null"
      class="form-group row"
    >
      <label class="control-label col-sm-3 required">{{ $t('string.nameVillageInKm') }}</label>
      <div class="col-sm-9">
        <input
          v-model="village.name_km"
          :placeholder="$t('string.nameVillageInKm')"
          class="form-control"
          type="text"
        >
        <template v-if="nameKmRequired.required">
          <span class="label-error">{{ nameKmRequired.message }}</span>
        </template>
      </div>
    </div>
    <div
      :class="nameEnRequired.required?'has-error':null"
      class="form-group row"
    >
      <label class="control-label col-sm-3 required">{{ $t('string.nameVillageInEn') }}</label>
      <div class="col-sm-9">
        <input
          v-model="village.name_en"
          :placeholder="$t('string.nameVillageInEn')"
          class="form-control"
          type="text"
        >
        <template v-if="nameEnRequired.required">
          <span class="label-error">{{ nameEnRequired.message }}</span>
        </template>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-9 col-sm-offset-3">
        <button
          class="btn btn-primary btn-sm margin-r-5"
          @click="store"
        >
          {{ $t('button.save') }}
        </button>
        <asora-back-button />
      </div>
    </div>
  </div>
</template>

<script>
import {validationMixins} from "@/mixins/validations"

export default {
  name: "VillageForm",
  mixins: [validationMixins],
  data() {
    return {
      validations: null,
      village: {},
      provinces: [],
      districts: [],
      communes: [],
      province_id: null,
      district_id: null,
    }
  },
  computed: {
    urlAction() {
      if (this.$route.name === 'create-village') {
        return `${process.env.VUE_APP_API}/api/backend/village/create`
      }
      return `${process.env.VUE_APP_API}/api/backend/village/update`
    },
  },
  mounted() {
    this.getProvinces()
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty('village_id')) {
      this.getVillage()
    }
  },
  methods: {
    store() {
      this.$isLoading(true)
      this.$axios.post(this.urlAction, this.village)
        .then(() => {
          this.showToastr()
          this.$router.back()
        })
        .catch((error) => {
          this.validations = null
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getVillage() {
      this.$isLoading(true)
      this.$axios.post(`${process.env.VUE_APP_API}/api/backend/village/show`, {
        id: this.$route.params.village_id,
      }).then(({data}) => {
        this.village = data.data
        if (this.village.commune.district.province) {
          this.province_id = this.village.commune.district.province.id
          this.getDistrict(this.province_id)
        }
        if (this.village.commune.district) {
          this.district_id = this.village.commune.district.id
          this.getCommunes(this.district_id)
        }
        if (this.village.commune) {
          this.commune_id = this.village.commune.id
        }
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    async getProvinces() {
      let response = await this.$axios.post(`${process.env.VUE_APP_API}/api/backend/village/get-provinces`)
      this.provinces = await response.data.data
      await this.getDistrict(this.province_id)
    },
    async getDistrict(province_id) {
      let response = await this.$axios.post(`${process.env.VUE_APP_API}/api/backend/village/get-districts`, {
        province_id: province_id,
      })
      this.districts = await response.data.data
      if (this.district_id === null) {
        this.district_id = this.districts[0]['id']
        await this.getCommunes(this.district_id)
      }
    },
    async getCommunes(district_id) {
      let response = await this.$axios.post(`${process.env.VUE_APP_API}/api/backend/village/get-communes`, {
        district_id: district_id,
      })
      this.communes = await response.data.data
      if (this.commune_id === null) {
        this.commune_id = this.communes[0]['id']
      }
    },
  }
}
</script>

<style scoped>

</style>
