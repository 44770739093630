<template>
  <div class="row">
    <div :class="divContainerClass">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ title }}
          </h3>
          <div class="box-tools">
            <button
              v-if="can(permissionCreate)"
              class="btn btn-primary btn-sm margin-r-5"
              @click="$router.push({name: modelRouteCreate})"
            >
              <i class="fa fa-plus" /> {{ getButtonLabelCreate }}
            </button>
            <template v-if="can(permissionFilter)">
              <button
                class="btn btn-danger btn-sm"
                @click="toggleFilter"
              >
                <i class="fa fa-filter" /> {{ $t('string.filter') }}
              </button>
            </template>
          </div>
        </div>
        <div class="box-body">
          <div>
            <table
              id="architecture-table"
              class="table table-hover table-full-width table-striped table-bordered"
            >
              <thead>
                <tr>
                  <th>{{ $t('table.avatar') }}</th>
                  <th>{{ $t('table.registrationNumber') }}</th>
                  <th>{{ $t('table.uniqueNumber') }}</th>
                  <th>{{ $t('table.name') }}</th>
                  <th>{{ $t('table.type') }}</th>
                  <th>{{ $t('table.gender') }}</th>
                  <th>{{ $t('table.email') }}</th>
                  <th>{{ $t('table.phone') }}</th>
                  <th>{{ $t('table.dob') }}</th>
                  <th>{{ $t('table.status') }}</th>
                  <th>{{ $t('table.createdAt') }}</th>
                  <th>{{ $t('table.action') }}</th>
                </tr>
              </thead>
              <tbody />
            </table>
          </div>
        </div>
      </div>
    </div>
    <template v-if="can(permissionFilter) && showFilter">
      <div class="col-md-2 pl-md-0">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('string.filter') }}
            </h3>
            <div class="box-tools">
              <template v-if="permissionFilter === 'filter-professional-study-planner'">
                <button
                  class="btn btn-success btn-sm"
                  @click="exportProfessionalStudyPlan"
                >
                  <i class="fa fa-download" /> {{ $t('string.exportFile') }}
                </button>
              </template>
            </div>
          </div>
          <div class="box-body">
            <div class="form-group">
              <label>{{ $t('table.name') }}</label>
              <input
                v-model="name"
                type="text"
                :placeholder="$t('table.name')"
                class="form-control"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.registrationNumber') }}</label>
              <input
                v-model="registration_number"
                type="text"
                :placeholder="$t('table.registrationNumber')"
                class="form-control"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('label.uniqueNumberArchitecture') }}</label>
              <input
                v-model="unique_number"
                type="text"
                :placeholder="$t('label.uniqueNumberArchitecture')"
                class="form-control"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.phone') }}</label>
              <input
                v-model="phone"
                type="text"
                :placeholder="$t('table.phone')"
                class="form-control"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.email') }}</label>
              <input
                v-model="email"
                type="text"
                :placeholder="$t('table.email')"
                class="form-control"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('label.nationalIdCard') }}</label>
              <input
                v-model="national_id_card"
                type="text"
                :placeholder="$t('label.nationalIdCard')"
                class="form-control"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('label.passport') }}</label>
              <input
                v-model="passport"
                type="text"
                :placeholder="$t('label.passport')"
                class="form-control"
              >
            </div>

            <div class="form-group">
              <div class="row">
                <div class="form-group col-md-6">
                  <button
                    class="btn btn-primary btn-block btn-sm"
                    @click="search"
                  >
                    <i class="fa fa-search" />
                    {{ $t('button.search') }}
                  </button>
                </div>
                <div class="form-group col-md-6 pl-lg-0">
                  <button
                    class="btn btn-default btn-block btn-sm"
                    @click="reset"
                  >
                    <i class="fas fa-redo-alt" />
                    {{ $t('button.reset') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ArchitectureDataTable',
  props: {
    title: {
      type: String,
      required: true,
    },
    architectureTypeId: {
      type: Array,
      required: true,
    },
    architectureCategoryId: {
      type: Array,
      required: true,
    },
    modelRouteCreate: {
      type: String,
      required: true,
    },
    modelRouteEdit: {
      type: String,
      required: true,
    },
    modelRouteView: {
      type: String,
      required: true,
    },
    permissionCreate: {
      type: String,
      required: true,
    },
    permissionFilter: {
      type: String,
      required: true,
    },
    buttonLabelCreate: {
      type: String,
      default: 'string.add',
    },
  },
  data() {
    return {
      registration_number: null,
      unique_number: null,
      name: null,
      showFilter: false,
      oTable: null,
      phone: null,
      email: null,
      national_id_card: null,
      passport: null,
    }
  },
  computed: {
    getButtonLabelCreate() {
      return this.buttonLabelCreate === 'string.add' ? this.$t('string.add') : this.buttonLabelCreate
    },
    divContainerClass() {
      return this.showFilter ? 'col-md-10' : 'col-md-12'
    }
  },
  watch: {
    '$i18n.locale' () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.refreshTable()
      this.oTable.draw(true)
    }
  },
  mounted() {
    this.oTable = this.refreshTable()
    this.enabledAction()
  },
  beforeDestroy() {
    this.oTable.clear()
    this.oTable.destroy()
  },
  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter
      this.oTable.columns.adjust().draw()
    },
    search() {
      this.oTable.draw(true)
    },
    reset() {
      this.registration_number = null
      this.unique_number = null
      this.name = null
      this.phone = null
      this.email = null
      this.national_id_card = null
      this.passport = null
      this.oTable.draw(true)
    },

    exportProfessionalStudyPlan() {
      let renum = null
      if (this.registration_number) {
        renum = this.registration_number.replace('/', '-')
      }
      let mainRoute = '/api/backend/architecture/export-professional-study-plan/filter:'
      let subRoute = `renum=${renum}&unum=${this.unique_number}&name=${this.name}&nic=${this.national_id_card}&pass=${this.passport}&phone=${this.phone}&email=${this.email}`
      window.open(this.$base_api + `${mainRoute}${subRoute}`, '_blank')
      console.log(this.$base_api + `${mainRoute}${subRoute}`)
    },
    refreshTable() {
      let self = this
      return $('#architecture-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: process.env.VUE_APP_API + '/api/backend/architecture/datatable',
          data: (d) => {
            d.edit = self.$t('label.edit')
            d.show = self.$t('label.show')
            d.delete = self.$t('label.delete')
            d.architecture_type_id = self.architectureTypeId
            d.architecture_category_id = self.architectureCategoryId
            d.name = self.name
            d.registration_number = self.registration_number
            d.unique_number = self.unique_number
            d.phone = self.phone
            d.email = self.email
            d.national_id_card = self.national_id_card
            d.passport = self.passport
          },
          error: (xhr) => {
            self.onResponseError(xhr)
          }
        },
        columns: [
          {
            data: 'avatar',
            name: 'avatar',
            title: self.$t('table.avatar'),
            render: (data, type, row) => {
              if (row.avatar) {
                return `<img src="${process.env.VUE_APP_API}/${data}" alt="Avatar" class="datatable-image-size profile-user-img img-circle"/>`
              }
              return `<img src="/images/avatar/avatar.png" alt="Avatar" class="datatable-image-size profile-user-img img-circle"/>`
            },
            searchable: false,
            orderable: false,
          },
          {
            data: 'registration_number',
            name: 'registration_number',
            title: self.$t('table.registrationNumber'),
            render: (data) => {
              if (data !== null) {
                return data
              }
              return this.$t('string.na')
            }
          },
          {
            data: 'unique_number',
            name: 'unique_number',
            title: self.$t('table.uniqueNumber'),
            render: (data) => {
              if (data !== null) {
                return data
              }
              return this.$t('string.na')
            },
          },
          {
            data: 'name_km',
            name: 'name_km',
            title: self.$t('table.name'),
            render: (data, type, row) => {
              return row['name_' + this.$i18n.locale]
            }
          },
          {
            data: 'architecture_type.name_en',
            name: 'architectureType.name_en',
            title: self.$t('table.type'),
            render: (data, type, row) => {
              if (row['architecture_type_id'] !== null) {
                return row['architecture_type']['name_' + self.$i18n.locale]
              }
              return self.$t('string.na')
            }
          },
          {
            data: 'gender.name_en',
            name: 'gender.name_en',
            title: self.$t('table.gender'),
            render: (data, type, row) => {
              // eslint-disable-next-line no-prototype-builtins
              if (row.hasOwnProperty('gender')) {
                return row['gender']['name_' + this.$i18n.locale]
              }
              return self.$t('string.na')
            }
          },
          {
            data: 'email',
            name: 'email',
            title: self.$t('table.email'),
            render: (email) => {
              if (email === null) {
                return self.$t('string.na')
              }
              return email
            }
          },
          {
            data: 'phone',
            name: 'phone',
            title: self.$t('table.phone'),
            render: (phone) => {
              if (phone === null) {
                return self.$t('string.na')
              }
              return phone
            }
          },
          {
            data: 'dob',
            name: 'dob',
            title: self.$t('table.dob'),
            render: (dob) => {
              if (dob === null) {
                return self.$t('string.na')
              }
              return self.$moment(dob).locale('en').format('DD/MM/YYYY')
            }
          },
          {
            data: 'is_published',
            name: 'is_published',
            title: self.$t('table.is_published'),
            render: (data) => {
              if (data !== "1") {
                return `<span class="label label-warning"><i class="fa fa-times"></i></span>`
              }
              return `<span class="label label-primary"><i class="fa fa-check-circle"></i></span>`
            }
          },
          {
            data: 'created_at',
            name: 'created_at',
            orderable: true,
            searchable: false,
            title: self.$t('table.createdAt'),
            render: (data) => {
              return this.getDate(data)
            }
          },
          {
            data: 'action',
            name: 'action',
            orderable: false,
            width: '100px',
            searchable: false,
            title: self.$t('table.action')
          }
        ],
        language: {
          url: self.getLocale,
        },
        order: [
          [9, 'desc']
        ],
        drawCallback () {
          $('[data-toggle="tooltip"]').tooltip()
        }
      })
    },
    enabledAction() {
      let self = this
      this.clearEventHandler([
        '.btn-edit',
        '.btn-show',
        '.btn-trash',
        '.sidebar-toggle',
      ])
      $(document).on('click', '.sidebar-toggle', function () {
        self.oTable.columns.adjust().draw()
      })
      $(document).on('click', '.btn-edit', function () {
        self.$router.push({
          name: self.modelRouteEdit,
          params: {
            architecture_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-show', function () {
        self.$router.push({
          name: self.modelRouteView,
          params: {
            architecture_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-trash', function () {
        self.deleteArchitecture($(this).attr('data-uuid'))
      })
    },
    deleteArchitecture(uuid) {
      let self = this
      this.$swal.fire({
        title: self.i18nSwalTitle,
        text: self.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: self.i18nSwalNo,
        confirmButtonText: self.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/architecture/delete', {
            architecture_uuid: uuid
          }).then(() => {
            this.showSwalSuccess()
            this.oTable.draw(true)
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
