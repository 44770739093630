import {getField, updateField} from "vuex-map-fields"

export const state = () => {
  return {
    company_management: {
      nationalities: [],
      countries: [],
      companyTypes: [],
      companySections: [],
      companyCategories: [],
    }
  }
}

export const getters = {
  getField
}

export const mutations = {
  updateField,
  setCompanyManagementOptions(state, data) {
    state.company_management.nationalities = data.nationalities
    state.company_management.countries = data.countries
    state.company_management.companyTypes = data.company_types
    state.company_management.companySections = data.company_sections
    state.company_management.companyCategories = data.company_categories
  }
}

export const actions = {
  fetchCompanyManagementOptions({commit}, vm) {
    vm.$axios.post(`${process.env.VUE_APP_API}/api/backend/company/registration/get-options`)
      .then(({data}) => {
        if (data.data) {
          commit('setCompanyManagementOptions', data.data)
        }
      })
      .catch((error) => {
        vm.onResponseError(error)
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
