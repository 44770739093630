<template>
  <div class="col-md-12">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t('string.investmentSection') }}
        </h3>
      </div>
      <div class="box-body">
        <table class="table table-bordered table-condensed table-striped">
          <tbody>
            <tr>
              <th>{{ $t('label.investmentCountry') }}</th>
              <td>
                <template v-if="project.investment_countries.length>0">
                  <p
                    v-for="(country, key) in project.investment_countries"
                    :key="key"
                  >
                    {{ country['name_' + $i18n.locale] }}
                  </p>
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr>
              <th>{{ $t('label.investmentBudget') }}</th>
              <td>
                <template v-if="project.investment_budget">
                  {{ project.investment_budget }}$
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvestmentInfoPanel",
  props: {
    project: {
      type: Object,
    }
  }
}
</script>

<style scoped>

</style>
