import Index from "./../../views/companyManagement/licenses/Index"
import List from "./../../views/companyManagement/licenses/List"
import Create from "./../../views/companyManagement/licenses/Create"

export const licenseCompanyRouters = {
  path: 'licenses',
  component: Index,
  redirect: 'licenses',
  meta: {
    permissions: [],
    groups: 'licenses-company',
  },
  children: [
    {
      path: '',
      name: 'list-licenses-company',
      component: List,
      meta: {
        permissions: [],
        groups: 'licenses-company',
        breadcrumb: [
          {
            name_en: 'The Licenses Company',
            name_km: 'ការផ្តល់អាជ្ញាប័ណ្ណអោយក្រុមហ៊ុន'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-licenses-company',
      component: Create,
      meta: {
        permissions: [],
        groups: 'licenses-company',
        breadcrumb: [
          {
            name_en: 'The Licenses Company',
            name_km: 'ការផ្តល់អាជ្ញាប័ណ្ណអោយក្រុមហ៊ុន'
          },
          {
            name_en: 'Create',
            name_km: 'បង្កើត'
          }
        ]
      }
    },
  ]
}
