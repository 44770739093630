<template>
  <div
    id="createPeopleModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="createPeopleModalLabel"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div
      class="modal-dialog tw-w-[90%] lg:tw-w-[80%]"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="resetForm"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4
            id="createPeopleModalLabel"
            class="modal-title"
          >
            {{ modalTitle }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="form-horizontal">
            <div class="row">
              <div class="col-md-6">
                <div
                  class="form-group row"
                  :class="{'has-error': validations.name_km}"
                >
                  <label
                    :class="labelClasses"
                    class="required"
                  >{{ $t('label.nameKm') }}</label>
                  <div :class="inputClasses">
                    <input
                      v-model="people.name_km"
                      :placeholder="$t('label.nameKm')"
                      class="form-control"
                      type="text"
                    >
                    <template v-if="validations.name_km">
                      <FiledIsRequired :msg="validations['name_km'][0]" />
                    </template>
                  </div>
                </div>

                <div
                  class="form-group row"
                  :class="{'has-error': validations.name_en}"
                >
                  <label
                    :class="labelClasses"
                    class="required"
                  >{{ $t('label.nameEn') }}</label>
                  <div :class="inputClasses">
                    <input
                      v-model="people.name_en"
                      :placeholder="$t('label.nameEn')"
                      class="form-control"
                      type="text"
                    >
                    <template v-if="validations.name_en">
                      <FiledIsRequired :msg="validations['name_en'][0]" />
                    </template>
                  </div>
                </div>

                <div
                  class="form-group row"
                  :class="{'has-error': validations.gender_id}"
                >
                  <label
                    :class="labelClasses"
                    class="required"
                  >{{ $t('label.gender') }}</label>
                  <div :class="inputClasses">
                    <select
                      v-model="people.gender_id"
                      class="form-control"
                    >
                      <option
                        selected
                        disabled
                        :value="null"
                      >
                        {{ $t('string.select_gender') }}
                      </option>
                      <option value="1">
                        {{ $t('label.male') }}
                      </option>
                      <option value="2">
                        {{ $t('label.female') }}
                      </option>
                    </select>
                    <template v-if="validations.gender_id">
                      <FiledIsRequired :msg="validations['gender_id'][0]" />
                    </template>
                  </div>
                </div>

                <div
                  class="form-group row"
                  :class="{'has-error': validations.national_id}"
                >
                  <label :class="labelClasses">{{ $t('label.nationalId') }}</label>
                  <div :class="inputClasses">
                    <input
                      v-model="people.national_id"
                      :placeholder="$t('label.nationalId')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>

                <div
                  class="form-group row"
                  :class="{'has-error': validations.passport}"
                >
                  <label :class="labelClasses">{{ $t('label.passport') }}</label>
                  <div :class="inputClasses">
                    <input
                      v-model="people.passport"
                      :placeholder="$t('label.passport')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>

                <div class="form-group row">
                  <label :class="labelClasses">{{ $t('label.email') }}</label>
                  <div :class="inputClasses">
                    <input
                      v-model="people.email"
                      :placeholder="$t('label.email')"
                      class="form-control"
                      type="email"
                    >
                  </div>
                </div>

                <div class="form-group row">
                  <label :class="labelClasses">{{ $t('label.dob') }}</label>
                  <div :class="inputClasses">
                    <date-picker
                      v-model="people.dob"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.dob')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label :class="labelClasses">{{ $t('label.placeOfBirth') }}</label>
                  <div :class="inputClasses">
                    <input
                      v-model="people.place_of_birth"
                      :placeholder="$t('label.placeOfBirth')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>

                <div class="form-group row">
                  <label :class="labelClasses">{{ $t('label.phone') }}</label>
                  <div :class="inputClasses">
                    <input
                      v-model="people.phone"
                      :placeholder="$t('label.phone')"
                      class="form-control"
                      type="tel"
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label :class="labelClasses">{{ $t('label.country') }}</label>
                  <div :class="inputClasses">
                    <select
                      v-model="people.country_id"
                      class="form-control"
                    >
                      <option
                        selected
                        disabled
                        :value="null"
                      >
                        {{ $t('string.select_country') }}
                      </option>
                      <option
                        v-for="(country, key) in countries"
                        :key="key"
                        :value="country.id"
                      >
                        {{ country['name_' + $i18n.locale] }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label :class="labelClasses">{{ $t('label.nationality') }}</label>
                  <div :class="inputClasses">
                    <select
                      v-model="people.nationality_id"
                      class="form-control"
                    >
                      <option
                        selected
                        disabled
                        :value="null"
                      >
                        {{ $t('string.select_nationality') }}
                      </option>
                      <option
                        v-for="(nationality, key) in nationalities"
                        :key="key"
                        :value="nationality.id"
                      >
                        {{ nationality['name_' + $i18n.locale] }}
                      </option>
                    </select>
                  </div>
                </div>

                <LocationInput
                  :input-classes="inputClasses"
                  :label-classes="labelClasses"
                />

                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.registered') }}</label>
                  <div class="col-sm-9">
                    <label class="margin-top-10">
                      <input
                        v-model="people.is_registered"
                        :value="true"
                        name="registration"
                        type="checkbox"
                      >
                      <template v-if="people.is_registered">{{ $t('string.true') }}</template>
                      <template v-else>{{ $t('string.false') }}</template>
                    </label>
                  </div>
                </div>

                <div class="form-group row">
                  <label :class="labelClasses">{{ $t('label.avatar') }}</label>
                  <div :class="inputClasses">
                    <input
                      ref="avatar"
                      name="avatar"
                      type="file"
                      accept="image/png,image/jpg,image/jpeg"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            data-dismiss="modal"
            @click="resetForm"
          >
            {{ $t('button.close') }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="addPeople"
          >
            {{ $t('button.save') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields"
import FiledIsRequired from "@/components/FiledIsRequired"
import LocationInput from "@/components/LocationInput"

export default {
  name: "CreatePeopleModal",
  components: {LocationInput, FiledIsRequired},
  data () {
    return {
      people: {
        name_km: null,
        name_en: null,
        gender_id: null,
        passport: null,
        email: null,
        dob: null,
        phone: null,
        national_id: null,
        country_id: null,
        nationality_id: null,
        home_number: null,
        street_number: null,
        group_number: null,
        village_id: null,
        commune_id: null,
        district_id: null,
        province_id: null,
        place_of_birth: null,
        is_registered: false
      },
      validations: {},
      nationalities: [],
      countries: [],
      genders: []
    }
  },
  computed: {
    ...mapFields('constructionCertifierLicense', {
      modalTitle: 'createPeopleModal.title',
      modalPeopleCategories: 'createPeopleModal.people_categories',
      newPeople: 'createPeopleModal.new_people',
    }),
    ...mapFields('location', {
      homeNumber: 'address.home_number',
      streetNumber: 'address.street_number',
      groupNumber: 'address.group_number',
      villageId: 'address.village_id',
      communeId: 'address.commune_id',
      districtId: 'address.district_id',
      provinceId: 'address.province_id',
    }),
    labelClasses () {
      return 'col-sm-3 control-label'
    },
    inputClasses () {
      return 'col-sm-9'
    }
  },
  mounted() {
    this.getOptionsAddingPeople()
  },
  beforeDestroy() {
    this.resetForm()
  },
  methods: {
    getOptionsAddingPeople () {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/construction-certifier-license/get-options-adding-people')
        .then(({data}) => {
          if (data.data) {
            const {genders, countries, nationalities} = data.data
            this.genders = genders
            this.countries = countries
            this.nationalities = nationalities
          }
        })
        .catch((error) => {
          this.onResponseError(error)
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    addPeople () {
      this.validations = {}

      const params = new FormData()
      if (this.modalPeopleCategories && Array.isArray(this.modalPeopleCategories) && this.modalPeopleCategories.length > 0) {
        params.append('category_ids[0]', this.modalPeopleCategories[0])
      }else {
        params.delete('category_ids[0]')
      }
      if (this.people.name_km) { params.append('name_km', this.people.name_km) }
      if (this.people.name_en) { params.append('name_en', this.people.name_en) }
      if (this.people.gender_id) { params.append('gender_id', this.people.gender_id) }
      if (this.people.national_id) { params.append('national_id', this.people.national_id) }
      if (this.people.passport) { params.append('passport', this.people.passport) }
      if (this.people.email) { params.append('email', this.people.email) }
      if (this.people.place_of_birth) { params.append('place_of_birth', this.people.place_of_birth) }
      if (this.people.phone) { params.append('phone', this.people.phone) }
      if (this.people.country_id) { params.append('country_id', this.people.country_id) }
      if (this.people.nationality_id) { params.append('nationality_id', this.people.nationality_id) }
      if (this.homeNumber) { params.append('home_number', this.homeNumber) }
      if (this.streetNumber) { params.append('street_number', this.streetNumber) }
      if (this.groupNumber) { params.append('group_number', this.groupNumber) }
      if (this.villageId) { params.append('village_id', this.villageId) }
      if (this.communeId) { params.append('commune_id', this.communeId) }
      if (this.districtId) { params.append('district_id', this.districtId) }
      if (this.provinceId) { params.append('province_id', this.provinceId) }
      if (this.people.is_registered) { params.append('is_registered', this.people.is_registered) }
      if (this.people.dob && this.$moment(this.people.dob).isValid()) {
        params.append('dob', this.$moment(this.people.dob).locale('en').format('YYYY-MM-DD'))
      } else {
        params.delete('dob')
      }
      if (this.$refs.avatar && this.$refs.avatar.files && this.$refs.avatar.files[0]) {
        params.append('avatar', this.$refs.avatar.files[0])
      } else {
        params.delete('avatar')
      }

      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/construction-certifier-license/add-people', params)
        .then(({data}) => {
          if (data.data) {
            this.newPeople = data.data
            this.showToastr()
            $('#createPeopleModal').modal('hide')
            this.resetForm()
            this.$emit("setNewPeople")
          }
        })
        .catch((error) => {
          if (error.response && error.response.status && error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    resetForm () {
      this.validations = {}
      this.modalPeopleCategories = null
      this.homeNumber = null
      this.streetNumber = null
      this.groupNumber = null
      this.villageId = null
      this.communeId = null
      this.districtId = null
      this.provinceId = null
      this.people = {
        name_km: null,
        name_en: null,
        gender_id: null,
        passport: null,
        email: null,
        dob: null,
        phone: null,
        national_id: null,
        country_id: null,
        nationality_id: null,
        home_number: null,
        street_number: null,
        group_number: null,
        village_id: null,
        commune_id: null,
        district_id: null,
        province_id: null,
        place_of_birth: null,
        is_registered: false
      }
    }
  },
}
</script>

<style scoped>

</style>
