<template>
  <XBox :title="$t('string.newDeikarbanhchoun')">
    <deikarbanh-choun-form />
  </XBox>
</template>

<script>
import DeikarbanhChounForm from "./Form"
import XBox from "@/components/Widgets/XBox"

export default {
  name: "Create",
  metaInfo() {
    return {
      title: this.$t('string.newDeikarbanhchoun'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  components: {XBox, DeikarbanhChounForm}
}
</script>

<style scoped>

</style>
