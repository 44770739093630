import Nationality from "./../../views/settings/nationality/Index"
import List from "./../../views/settings/nationality/List"
import Create from "./../../views/settings/nationality/Create"
import Edit from "./../../views/settings/nationality/Edit"
import Show from "./../../views/settings/nationality/Show"
import Permissions from "../../permissions"

export const nationalityRouters = {
  path: 'nationality',
  component: Nationality,
  redirect: 'nationality',
  meta: {
    permissions: [Permissions.backend.setting.nationality.manage],
    groups: 'nationality',
  },
  children: [
    {
      path: '',
      name: 'list-nationality',
      component: List,
      meta: {
        permissions: [Permissions.backend.setting.nationality.list],
        groups: 'nationality',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Nationality',
            name_km: 'សញ្ជាតិ'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-nationality',
      component: Create,
      meta: {
        permissions: [Permissions.backend.setting.nationality.store],
        groups: 'nationality',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'New Nationality',
            name_km: 'បន្ថែមសញ្ជាតិ'
          }
        ]
      }
    },
    {
      path: 'edit/:nationality_uuid',
      name: 'edit-nationality',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.setting.nationality.edit],
        groups: 'nationality',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Edit Nationality',
            name_km: 'កែប្រែសញ្ជាតិ'
          }
        ]
      }
    },
    {
      path: 'show/:nationality_uuid',
      name: 'show-nationality',
      component: Show,
      meta: {
        permissions: [Permissions.backend.setting.nationality.show],
        groups: 'nationality',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Show Nationality',
            name_km: 'បង្ហាញព័ត៏មានសញ្ជាតិ'
          }
        ]
      }
    }
  ]
}
