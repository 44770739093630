<template>
  <div class="row">
    <template v-if="!readonly">
      <div class="col-md-12 margin-bottom">
        <input
          ref="docs"
          type="file"
          style="display: none;"
          accept="image/jpg,image/png,image/jpeg,image/tif,image/tiff, .pdf"
          multiple
          @change="uploadImage"
        >
        <button
          class="btn btn-primary btn-md margin-r-5"
          @click="$refs.docs.click()"
        >
          <i class="fa fa-file-text" /> {{ $t('string.attachDocument') }}
        </button>
      </div>
    </template>
    <div class="col-md-12">
      <div class="row">
        <template v-if="mediaImages.length > 0">
          <draggable
            v-model="mediaImages"
            class="list-group"
            v-bind="dragOptions"
            @start="isDragging = true"
            @end="isDragging = false"
          >
            <transition-group
              type="transition"
              name="flip-list"
            >
              <template v-for="(doc, key) in mediaImages">
                <upload-media-preview
                  :key="key"
                  :doc="doc"
                  :readonly="readonly"
                  :class-column="column"
                  @fetchData="getImages"
                />
              </template>
            </transition-group>
          </draggable>
        </template>
        <template v-else>
          <div class="col-md-12">
            <p class="text-center">
              {{ $t('string.noResult') }}
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import UploadMediaPreview from "@/components/UploadMediaPreview"

export default {
  name: "ConstructionUploadDocument",
  components: {
    UploadMediaPreview,
    draggable
  },
  props: {
    column: {
      type: String,
      default: 'col-md-3 col-xs-6 margin-bottom'
    },
    path: {
      type: String,
      default: 'constructionLetter'
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mediaImages: [],
      validations: null,
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    }
  },
  created() {
    this.getImages()
  },
  methods: {
    uploadImage() {
      let params = new FormData()
      if (this.$refs.docs.files.length>0) {
        Array.from(this.$refs.docs.files).forEach((file, key) => {
          params.set(`document[${key}]`, file)
        })
      }
      params.set('construction_letter_uuid', this.$route.params.construction_letter_uuid)
      params.set('path', this.path)
      this.$isLoading(true)
      this.$axios.post(`${this.baseUrl}/api/backend/construction-letter/media/upload`, params)
        .then((res) => {
          if (res.data.data) {
            this.showToastr()
            this.mediaImages = res.data.data
          } else {
            this.mediaImages = []
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
            this.$toastr('error', this.validations['document'][0], this.$t('string.error'))
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getImages() {
      this.$axios.post(`${this.baseUrl}/api/backend/construction-letter/media/list`, {
        construction_letter_uuid: this.$route.params.construction_letter_uuid,
      }).then((res) => {
        if (res.data.data) {
          this.mediaImages = res.data.data
        } else {
          this.mediaImages = []
        }
      }).catch((error) => {
        this.onResponseError(error)
      })
    },
    updateOrder(media) {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/media/update-order-images', {
        media: media
      }).then(() => {
        this.showToastr()
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
  }
}
</script>

<style scoped>
  .file {
    width: 100%;
    position: relative;
    height: auto;
    border: 1px solid #0d6aad;
  }

  .file-header {
    background-color: #0d6aad;
    padding: 15px;
    color: #ffffff;
  }

  .file-footer {
    visibility: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35px;
    background-color: #dddddd;
    padding: 6px;
  }

  .file:hover .file-footer {
    visibility: visible;
  }

  .file-body {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
</style>
