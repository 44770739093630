<template>
  <table
    id="open-construction-letter-table"
    class="table table-hover table-striped table-bordered"
  >
    <thead>
      <tr>
        <th>{{ $t('table.constructionLetterNumber') }}</th>
        <th>{{ $t('table.constructionLetterDate') }}</th>
        <th>{{ $t('table.owners') }}</th>
        <th style="width: 180px;">
          {{ $t('table.constructionType') }}
        </th>
        <th>{{ $t('table.permitNumberConstructionLetter') }}</th>
        <th>{{ $t('table.permitDate') }}</th>
        <th>{{ $t('table.status') }}</th>
        <th>{{ $t('table.approvedBy') }}</th>
        <th>{{ $t('table.approvedAt') }}</th>
        <th style="width: 125px;">
          {{ $t('table.action') }}
        </th>
      </tr>
    </thead>
    <tbody />
  </table>
</template>

<script>
export default {
  name: "ConstructionLetterDataTable",
  props: {
    showRoute: {
      type: String,
      default: null,
    },
    approveRoute: {
      type: String,
      default: null,
    },
    editRoute: {
      type: String,
      default: null,
    },
    editNoteLetterRoute: {
      type: String,
      default: null,
    },
    apiUrl: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      oTable: null,
    }
  },
  computed: {
    api() {
      return `${this.baseUrl}/api/backend/construction-letter/open-construction-letter`
    }
  },
  watch: {
    '$i18n.locale'() {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.enabledDataTable()
      this.oTable.draw(true)
    }
  },
  mounted() {
    this.oTable = this.enabledDataTable()
    this.enabledAction()
  },
  beforeDestroy() {
    this.oTable.clear()
    this.oTable.destroy()
  },
  methods: {
    enabledDataTable() {
      let self = this
      return $('#open-construction-letter-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        sScrollX: "100%",
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: `${this.api}/datatable`,
          data: (d) => {
            d.type = this.type
          },
          error: (xhr) => {
            self.onResponseError(xhr)
          }
        },
        columns: [
          {
            data: 'number',
            name: 'number',
            title: self.$t('table.constructionLetterNumber'),
            render: (data) => {
              return data || this.$t('string.na')
            },
          },
          {
            data: 'date',
            name: 'date',
            title: self.$t('table.constructionLetterDate'),
            render: (data) => {
              return this.getDate(data, false, 'DD/MM/YYYY')
            },
          },
          {
            data: 'owners',
            name: 'owners',
            title: self.$t('table.owners'),
            orderable: false,
            render: (data, type, row) => {
              let owners = row['project']['owners']
              if (owners !== null && owners.length > 0) {
                let labels = ''
                owners.forEach((people) => {
                  labels += `<label class="label label-custom">${people['name_' + this.$i18n.locale]}</label> `
                })
                return labels
              }
              return this.$t('string.na')
            },
          },
          {
            data: 'construction_type',
            name: 'construction_type',
            title: self.$t('table.constructionType'),
            orderable: false,
            render: (data, type, row) => {
              let construct_type = row['project']['construction_type']
              if (construct_type !== null) {
                return construct_type['name_' + this.$i18n.locale]
              }
              return this.$t('string.na')
            },
          },
          {
            data: 'permit_number',
            name: 'permit_number',
            title: self.$t('table.permitNumberConstructionLetter'),
            orderable: false,
            render: (data, type, row) => {
              return row['project']['permit_number'] || this.$t('string.na')
            },
          },
          {
            data: 'permit_date',
            name: 'permit_date',
            title: self.$t('table.permitDate'),
            orderable: false,
            render: (data, type, row) => {
              let date = row['project']['permit_date']
              if (date !== null) {
                return this.getDate(date, false, 'DD/MM/YYYY')
              }
              return this.$t('string.na')
            },
          },
          {
            data: 'status',
            name: 'status',
            title: self.$t('table.status'),
            render: (data) => {
              let title = this.$t('string.approved')
              if (data === 'Draft') {
                title = this.$t('string.draft')
                return `<label class="label label-warning">${title}</label>`
              } else {
                return `<label class="label label-info">${title}</label>`
              }
            },
          },
          {
            data: 'approved_by',
            name: 'approved_by',
            title: self.$t('table.approvedBy'),
            render: (data) => {
              return data || this.$t('string.na')
            },
          },
          {
            data: 'approved_at',
            name: 'approved_at',
            title: self.$t('table.approvedAt'),
            render: (data) => {
              return this.getDate(data)
            },
          },
          {
            data: 'action',
            name: 'action',
            searchable: false,
            orderable: false,
            width: '150px',
            title: self.$t('table.action'),
            render: (data, type, row) => {
              let action = ''
              if (row['status'] !== 'Approved') {
                if (this.can([
                  this.$Permissions.backend.constructionLetter.openConstructionLetter.approve,
                  this.$Permissions.backend.constructionLetter.closeConstructionLetter.approve,
                ])) {
                  action += `<button class="btn btn-success btn-xs btn-approved" data-toggle="tooltip" data-placement="top" data-uuid="${row.uuid}" title="${this.$t('button.approve')}">
                          <i class="fa fa-check-circle"></i>
                        </button> `
                }
                if (this.can([
                  this.$Permissions.backend.constructionLetter.openConstructionLetter.edit,
                  this.$Permissions.backend.constructionLetter.closeConstructionLetter.edit,
                ])) {
                  action += `<button class="btn btn-primary btn-xs btn-edit" data-toggle="tooltip" data-placement="top" data-uuid="${row.uuid}" title="${this.$t('button.edit_construction_letter')}">
                          <i class="fa fa-edit"></i>
                        </button> `
                  action += `<button class="btn btn-warning btn-xs btn-edit-note-letter" data-toggle="tooltip" data-placement="top" data-uuid="${row.uuid}" title="${this.$t('button.edit_note_letter')}">
                          <i class="fa fa-pen-square"></i>
                        </button> `
                }
              }

              if (this.can([
                this.$Permissions.backend.constructionLetter.openConstructionLetter.show,
                this.$Permissions.backend.constructionLetter.closeConstructionLetter.show,
              ])) {
                action += `<button class="btn btn-info btn-xs btn-show" data-toggle="tooltip" data-placement="top" data-uuid="${row.uuid}" title="${this.$t('button.show')}">
                          <i class="fa fa-search"></i>
                        </button> `
              }
              if (row['status'] !== 'Approved') {
                if (this.can([
                  this.$Permissions.backend.constructionLetter.openConstructionLetter.delete,
                  this.$Permissions.backend.constructionLetter.closeConstructionLetter.delete,
                ])) {
                  action += `<button class="btn btn-danger btn-xs btn-delete" data-toggle="tooltip" data-placement="top" data-uuid="${row.uuid}" title="${this.$t('button.delete')}">
                          <i class="fa fa-trash"></i>
                        </button>`
                }
              }
              if (action === '') {
                action = this.$t('string.na')
              }
              return action
            },
          }
        ],
        language: {
          url: self.getLocale,
        },
        order: [
          [0, 'asc'],
          [1, 'desc'],
        ],
        drawCallback () {
          $('[data-toggle="tooltip"]').tooltip()
        }
      })
    },
    deleteRecord(construction_letter_uuid) {
      this.$swal({
        title: this.i18nSwalTitle,
        text: this.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.i18nSwalNo,
        confirmButtonText: this.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(`${this.api}/delete`, {
            construction_letter_uuid: construction_letter_uuid,
          })
            .then(() => {
              this.showSwalSuccess()
              this.oTable.draw(true)
            }).catch(error => {
              this.onResponseError(error)
            })
        }
      })
    },
    enabledAction() {
      let self = this
      this.clearEventHandler([
        '.btn-show',
        '.btn-delete',
        '.btn-approved',
        '.sidebar-toggle',
        '.btn-edit-note-letter',
      ])
      $(document).on('click', '.sidebar-toggle', function () {
        self.oTable.columns.adjust().draw()
      })
      $(document).on('click', '.btn-delete', function () {
        self.deleteRecord($(this).attr('data-uuid'))
      })
      $(document).on('click', '.btn-show', function () {
        self.$router.push({
          name: self.showRoute,
          params: {
            construction_letter_uuid: $(this).attr('data-uuid'),
          }
        }).catch(() => {
        })
      })
      $(document).on('click', '.btn-edit', function () {
        self.$router.push({
          name: self.editRoute,
          params: {
            construction_letter_uuid: $(this).attr('data-uuid'),
          }
        }).catch(() => {
        })
      })
      $(document).on('click', '.btn-edit-note-letter', function () {
        self.$router.push({
          name: self.editNoteLetterRoute,
          params: {
            construction_letter_uuid: $(this).attr('data-uuid'),
          }
        })
      })
      $(document).on('click', '.btn-approved', function () {
        self.$router.push({
          name: self.approveRoute,
          params: {
            construction_letter_uuid: $(this).attr('data-uuid'),
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
