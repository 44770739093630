<template>
  <div class="form-horizontal">
    <div
      class="form-group row"
      :class="getProjectValidation.required?'has-error':null"
    >
      <label class="col-xs-2 control-label required">{{ $t('string.searchProject') }}</label>
      <div class="col-xs-10">
        <multiselect
          v-model="selected_project"
          :options="projects"
          track-by="id"
          :disabled="editMode"
          :internal-search="false"
          :placeholder="$t('string.searchProject')"
          label="label"
          @search-change="searchProject"
        />
        <template v-if="getProjectValidation.required">
          <span class="label-error">{{ getProjectValidation.message }}</span>
        </template>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xs-2 control-label">{{ $t('string.selectConstructionLetters') }}</label>
      <div class="col-xs-10">
        <select
          v-model="construction_letter_selected_uuid"
          class="form-control"
          :disabled="editMode || !construction_letters.length>0"
        >
          <option
            disabled
            selected
            value="null"
          >
            {{ $t('string.selectConstructionLetters') }}
          </option>
          <option value="">
            {{ $t('string.unselect') }}
          </option>
          <option
            v-for="(letter, key) in construction_letters"
            :key="key"
            :value="letter.uuid"
          >
            {{ getLabelConstructionType }} {{ letter.number || 'N/A' }} - កាលបរិច្ឆេទអនុញ្ញាត
            <template v-if="letter.date!==null">
              <template v-if="$moment(letter.date).isValid()">
                {{ $moment(letter.date).format('DD/MM/YYYY') }}
              </template>
              <template v-else>
                N/A
              </template>
            </template>
            <template v-else>
              N/A
            </template>
          </option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-xs-10 col-xs-offset-2">
        <h4 class="main-title">
          {{ $t('string.participants') }}
        </h4>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-xs-2 control-label" />
      <div class="col-xs-10">
        <multiselect
          v-model="selected_officers"
          track-by="id"
          :internal-search="false"
          :multiple="true"
          :placeholder="$t('string.participants')"
          label="label"
          :options="officers"
          @search-change="searchOfficer"
        />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-xs-10 col-xs-offset-2">
        <h4 class="main-title">
          {{ $t('string.content') }}
        </h4>
      </div>
    </div>
    <div
      class="form-group row"
      :class="contentValidation?'has-error':null"
    >
      <label class="control-label col-sm-2" />
      <div class="col-sm-10">
        <div class="row">
          <div class="col-md-12 margin-bottom">
            <div class="input-group">
              <input
                v-model="content"
                type="text"
                :placeholder="$t('string.content')"
                class="form-control"
                @keydown.enter="addNewContent"
              >
              <span
                class="input-group-addon bg-gray"
                @click="addNewContent"
              >
                <i class="fa fa-plus" /> {{ $t('string.add') }}
              </span>
            </div>
            <template v-if="contentValidation">
              <span class="label-error">{{ $t('string.requiredMessage') }}</span>
            </template>
          </div>
          <div class="col-md-12">
            <template v-if="contents.length>0">
              <draggable
                v-model="contents"
                class="list-group"
                tag="ul"
                v-bind="contents"
                @start="isDragging = true"
                @end="isDragging = false"
              >
                <transition-group
                  type="transition"
                  name="flip-list"
                >
                  <li
                    v-for="element in contents"
                    :key="element.order"
                    class="list-group-item"
                  >
                    <div class="drag-item">
                      <div class="drag-icon">
                        -
                      </div>
                      <div class="drag-title">
                        {{ element.value }}
                      </div>
                      <div class="drag-action">
                        <button
                          class="btn btn-danger btn-xs"
                          @click="contents.splice(contents.indexOf(element), 1)"
                        >
                          <i class="fa fa-trash-alt" />
                        </button>
                      </div>
                    </div>
                  </li>
                </transition-group>
              </draggable>
            </template>
            <template v-else>
              <p class="text-muted text-center">
                {{ $t('string.noteNoContent') }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-xs-10 col-xs-offset-2">
        <h4 class="main-title">
          {{ $t('string.commandment') }}
        </h4>
      </div>
    </div>
    <div
      class="form-group row"
      :class="commandmentValidation?'has-error':null"
    >
      <label class="control-label col-sm-2" />
      <div class="col-sm-10">
        <div class="row">
          <div class="col-md-12 margin-bottom">
            <div class="input-group">
              <input
                v-model="commandment"
                type="text"
                :placeholder="$t('string.commandment')"
                class="form-control"
                @keydown.enter="addNewCommandment"
              >
              <span
                class="input-group-addon bg-gray"
                @click="addNewCommandment"
              >
                <i class="fa fa-plus" /> {{ $t('string.add') }}
              </span>
            </div>
            <template v-if="commandmentValidation">
              <span class="label-error">{{ $t('string.requiredMessage') }}</span>
            </template>
          </div>
          <div class="col-md-12">
            <template v-if="commandments.length>0">
              <draggable
                v-model="commandments"
                class="list-group"
                tag="ul"
                v-bind="commandments"
                @start="isDragging = true"
                @end="isDragging = false"
              >
                <transition-group
                  type="transition"
                  name="flip-list"
                >
                  <li
                    v-for="element in commandments"
                    :key="element.order"
                    class="list-group-item"
                  >
                    <div class="drag-item">
                      <div class="drag-icon">
                        -
                      </div>
                      <div class="drag-title">
                        {{ element.value }}
                      </div>
                      <div class="drag-action">
                        <button
                          class="btn btn-danger btn-xs"
                          @click="commandments.splice(commandments.indexOf(element), 1)"
                        >
                          <i class="fa fa-trash-alt" />
                        </button>
                      </div>
                    </div>
                  </li>
                </transition-group>
              </draggable>
            </template>
            <template v-else>
              <p class="text-muted text-center">
                {{ $t('string.noCommandments') }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-xs-10 col-xs-offset-2">
        <h4 class="main-title">
          {{ $t('string.engineeringFeature') }}
        </h4>
      </div>
    </div>
    <div
      class="form-group row"
      :class="engineeringFeatureValidation?'has-error':null"
    >
      <label class="control-label col-sm-2" />
      <div class="col-sm-10">
        <div class="row">
          <div class="col-md-12 margin-bottom">
            <div class="input-group">
              <input
                v-model="engineeringFeature"
                type="text"
                :placeholder="$t('string.engineeringFeature')"
                class="form-control"
                @keydown.enter="addNewEngineeringFeature"
              >
              <span
                class="input-group-addon bg-gray"
                @click="addNewEngineeringFeature"
              >
                <i class="fa fa-plus" /> {{ $t('string.add') }}
              </span>
            </div>
            <template v-if="engineeringFeatureValidation">
              <span class="label-error">{{ $t('string.requiredMessage') }}</span>
            </template>
          </div>
          <div class="col-md-12">
            <template v-if="engineeringFeatures.length>0">
              <draggable
                v-model="engineeringFeatures"
                class="list-group"
                tag="ul"
                v-bind="engineeringFeatures"
                @start="isDragging = true"
                @end="isDragging = false"
              >
                <transition-group
                  type="transition"
                  name="flip-list"
                >
                  <li
                    v-for="element in engineeringFeatures"
                    :key="element.order"
                    class="list-group-item"
                  >
                    <div class="drag-item">
                      <div class="drag-icon">
                        -
                      </div>
                      <div class="drag-title">
                        {{ element.value }}
                      </div>
                      <div class="drag-action">
                        <button
                          class="btn btn-danger btn-xs"
                          @click="engineeringFeatures.splice(engineeringFeatures.indexOf(element), 1)"
                        >
                          <i class="fa fa-trash-alt" />
                        </button>
                      </div>
                    </div>
                  </li>
                </transition-group>
              </draggable>
            </template>
            <template v-else>
              <p class="text-muted text-center">
                {{ $t('string.noEngineeringFeatures') }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-xs-10 col-xs-offset-2">
        <h4 class="main-title">
          {{ $t('string.constructionLetterCommand') }}
        </h4>
      </div>
    </div>
    <div
      class="form-group row"
      :class="commandValidation ?'has-error':null"
    >
      <label class="control-label col-sm-2" />
      <div class="col-sm-10">
        <div class="row">
          <div class="col-md-12 margin-bottom">
            <div class="input-group">
              <input
                v-model="command"
                type="text"
                :placeholder="$t('string.command')"
                class="form-control"
                @keydown.enter="addNewCommand"
              >
              <span
                class="input-group-addon bg-gray"
                @click="addNewCommand"
              >
                <i class="fa fa-plus" /> {{ $t('string.add') }}
              </span>
            </div>
            <template v-if="commandValidation">
              <span class="label-error">{{ $t('string.requiredMessage') }}</span>
            </template>
          </div>
          <div class="col-md-12">
            <template v-if="commands.length>0">
              <draggable
                v-model="commands"
                class="list-group"
                tag="ul"
                v-bind="commands"
                @start="isDragging = true"
                @end="isDragging = false"
              >
                <transition-group
                  type="transition"
                  name="flip-list"
                >
                  <li
                    v-for="element in commands"
                    :key="element.order"
                    class="list-group-item"
                  >
                    <div class="drag-item">
                      <div class="drag-icon">
                        -
                      </div>
                      <div class="drag-title">
                        {{ element.value }}
                      </div>
                      <div class="drag-action">
                        <button
                          class="btn btn-danger btn-xs"
                          @click="commands.splice(contents.indexOf(element), 1)"
                        >
                          <i class="fa fa-trash-alt" />
                        </button>
                      </div>
                    </div>
                  </li>
                </transition-group>
              </draggable>
            </template>
            <template v-else>
              <p class="text-muted text-center">
                {{ $t('string.commandsNoContent') }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-10 col-sm-offset-2">
        <button
          class="btn btn-primary btn-sm"
          @click="save"
        >
          {{ $t('button.save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {validationMixins} from "@/mixins/validations"
import {debounce} from "debounce"
import draggable from 'vuedraggable'

export default {
  name: "ConstructionLetterNoteForm",
  components: {
    draggable,
  },
  mixins: [validationMixins],
  props: {
    constructionLetterType: {
      type: String,
      default: 'open',
    }
  },
  data() {
    return {
      validations: null,
      selected_project: null,
      projects: [],

      contents: [],
      content: '',
      contentValidation: false,

      commandments: [],
      commandment: '',
      commandmentValidation: false,

      engineeringFeatures: [],
      engineeringFeature: '',
      engineeringFeatureValidation: false,

      construction_letters: [],
      construction_letter_selected_uuid: null,

      commands: [],
      command: '',
      commandValidation: false,

      officers: [],
      selected_officers: [],
    }
  },
  computed: {
    api() {
      return `${this.baseUrl}/api/backend/construction-letter/note`
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
    getLabelConstructionType() {
      if (this.constructionLetterType === 'open') {
        return "លេខលិខិតបើកការដ្ឋាន"
      } else if (this.constructionLetterType === 'close') {
        return "លេខលិខិតបិទការដ្ឋាន"
      } else {
        return "មិនស្គាល់តម្លៃឡើយ"
      }
    },
    editMode() {
      // eslint-disable-next-line no-prototype-builtins
      return this.$route.params && this.$route.params.hasOwnProperty('construction_letter_uuid')
    }
  },
  watch: {
    selected_project() {
      this.getConstructionLetters()
    },
    construction_letter_selected_uuid() {
      this.show()
      this.getCommands()
    }
  },
  methods: {
    addNewEngineeringFeature() {
      this.engineeringFeatureValidation = false
      if (this.engineeringFeature === '' || this.engineeringFeature === undefined) {
        this.engineeringFeatureValidation = true
        return 0
      }
      this.engineeringFeatures.push({
        id: parseInt(this.engineeringFeatures.length) + 1,
        value: this.engineeringFeature,
        order: parseInt(this.engineeringFeatures.length) + 1,
      })
      this.engineeringFeature = ''
    },
    addNewCommandment() {
      this.commandmentValidation = false
      if (this.commandment === '' || this.commandment === undefined) {
        this.commandmentValidation = true
        return 0
      }
      this.commandments.push({
        id: parseInt(this.commandments.length) + 1,
        value: this.commandment,
        order: parseInt(this.commandments.length) + 1,
      })
      this.commandment = ''
    },
    addNewCommand() {
      this.commandValidation = false
      if (this.command === '' || this.command === undefined) {
        this.commandValidation = true
        return 0
      }
      this.commands.push({
        id: parseInt(this.commands.length) + 1,
        value: this.command,
        order: parseInt(this.commands.length) + 1,
      })
      this.command = ''
    },
    save() {
      this.validations = null
      this.$isLoading(true)
      this.$axios.post(`${this.api}/store`, {
        project_id: this.selected_project !== null ? this.selected_project.id : null,
        type: this.constructionLetterType,
        construction_letter_uuid: this.construction_letter_selected_uuid,
        contents: this.contents,
        commands: this.commands,
        engineeringFeatures: this.engineeringFeatures,
        commandments: this.commandments,
        selected_officers: this.selected_officers,
      })
        .then(({data}) => {
          this.$router.push({
            name: `show-${this.constructionLetterType}-construction-letter`,
            params: {
              construction_letter_uuid: data.data.uuid,
            }
          }).catch(() => {
          })
        })
        .catch((error) => {
          this.$isLoading(false)
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    addNewContent() {
      this.contentValidation = false
      if (this.content === '' || this.content === undefined) {
        this.contentValidation = true
        return 0
      }
      this.contents.push({
        id: parseInt(this.contents.length) + 1,
        value: this.content,
        order: parseInt(this.contents.length) + 1,
      })
      this.content = ''
    },
    searchOfficer: debounce(function (query_text = '') {
      this.$axios.post(`${this.api}/search-officer`, {
        query_text: query_text
      }).then(({data}) => {
        if (data.data !== null && data.data.length > 0) {
          this.officers = data.data.map((officer) => {
            let label = officer.name
            if (officer.positions !== '') {
              label = `${label} - (${officer.positions})`
            }
            return {
              id: officer.id,
              label: label,
            }
          })
        } else {
          this.officers = []
        }
      })
    }),
    searchProject: debounce(function (query_text = '') {
      this.$axios.post(`${this.api}/search-project`, {
        query_text: query_text
      }).then(({data}) => {
        if (data.data !== null && data.data.length > 0) {
          this.projects = data.data.map((project) => {
            let item = {
              id: project.id,
              label: ''
            }
            if (project.permit_number !== null) {
              if (project.year !== null) {
                item.label += ` (${project.permit_number}-${project.year})`
              } else {
                item.label += ` (${project.permit_number})`
              }
            }
            if (project.construction_type !== null) {
              item.label += project.construction_type.name_km
            }
            return item
          })
        }
      })
    }),
    async show() {
      if (this.construction_letter_selected_uuid === null || this.construction_letter_selected_uuid === '') {
        this.contents = []
        return 0
      }
      let response = await this.$axios.post(`${this.api}/show`, {
        construction_letter_uuid: this.construction_letter_selected_uuid,
      })
      let contents = await response.data.data
      if (contents.length > 0) {
        this.contents = contents
      } else {
        this.contents = []
      }
    },
    async getCommands() {
      let type = ''
      switch (this.constructionLetterType) {
      case 'open':
        type = 'open_construction'
        break
      case 'close':
        type = 'close_construction'
        break
      case 'pending':
        type = 'pending_construction'
        break
      default:
        type = null
        break

      }
      let response = await this.$axios.post(`${this.api}/get-construction-letter-commands`, {
        construction_letter_uuid: this.construction_letter_selected_uuid,
        type: type,
      })
      let data = await response.data.data
      if (data.commands.length > 0) {
        this.commands = data.commands
      } else {
        this.commands = []
      }
      if (data.commandments.length > 0) {
        this.commandments = data.commandments
      } else {
        this.commandments = []
      }
      if (data.engineeringFeatures.length > 0) {
        this.engineeringFeatures = data.engineeringFeatures
      } else {
        this.engineeringFeatures = []
      }
      if (data.selected_officers.length > 0) {
        this.selected_officers = data.selected_officers
      } else {
        this.selected_officers = []
      }
    },
    async getConstructionLetters() {
      let response = await this.$axios.post(`${this.api}/get-construction-letters`, {
        type: this.constructionLetterType,
        project_id: this.selected_project !== null ? this.selected_project.id : null,
      })
      let data = await response.data.data
      if (data.length > 0) {
        this.construction_letters = data
      } else {
        this.construction_letters = []
      }
    },
    async getNoteLetter() {
      let response = await this.$axios.post(`${this.api}/get-note-letter`, {
        construction_letter_uuid: this.$route.params.construction_letter_uuid
      })
      let data = await response.data.data
      this.selected_project = data.project
      this.selected_officers = data.officers
      this.construction_letter_selected_uuid = data.construction_letter_selected_uuid
    }
  },
  mounted() {
    this.searchProject()
    this.searchOfficer()
    this.getCommands()
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty('construction_letter_uuid')) {
      this.getNoteLetter()
    }
  }
}
</script>

<style scoped>
.input-group-addon {
  cursor: pointer;
}

.drag-item {
  display: grid;
  grid-template-columns: 20px 1fr 30px;
}

p.text-muted {
  padding: 25px;
  background-color: #dddddd;
  width: 100%;
}
</style>
