<template>
  <div class="form-horizontal">
    <div
      :class="provinceValidation.required?'has-error':null"
      class="form-group row"
    >
      <label class="control-label col-sm-3 required">{{ $t('label.province') }}</label>
      <div class="col-sm-9">
        <select
          v-model="district.province_id"
          class="form-control"
        >
          <option
            v-for="(province, key) in provinces"
            :key="key"
            :value="province.id"
          >
            {{ province['name_' + $i18n.locale] }}
          </option>
        </select>
        <template v-if="provinceValidation.required">
          <span class="label-error">{{ provinceValidation.message }}</span>
        </template>
      </div>
    </div>
    <div
      :class="nameKmRequired.required?'has-error':null"
      class="form-group row"
    >
      <label class="control-label col-sm-3 required">{{ $t('string.nameDistrictInKm') }}</label>
      <div class="col-sm-9">
        <input
          v-model="district.name_km"
          :placeholder="$t('string.nameDistrictInKm')"
          class="form-control"
          type="text"
        >
        <template v-if="nameKmRequired.required">
          <span class="label-error">{{ nameKmRequired.message }}</span>
        </template>
      </div>
    </div>
    <div
      :class="nameEnRequired.required?'has-error':null"
      class="form-group row"
    >
      <label class="control-label col-sm-3 required">{{ $t('string.nameDistrictInEn') }}</label>
      <div class="col-sm-9">
        <input
          v-model="district.name_en"
          :placeholder="$t('string.nameDistrictInEn')"
          class="form-control"
          type="text"
        >
        <template v-if="nameEnRequired.required">
          <span class="label-error">{{ nameEnRequired.message }}</span>
        </template>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-9 col-sm-offset-3">
        <button
          class="btn btn-primary btn-sm margin-r-5"
          @click="store"
        >
          {{ $t('button.save') }}
        </button>
        <asora-back-button />
      </div>
    </div>
  </div>
</template>

<script>
import {validationMixins} from "@/mixins/validations"

export default {
  name: "DistrictForm",
  mixins: [validationMixins],
  data() {
    return {
      validations: null,
      district: {},
      provinces: [],
    }
  },
  computed: {
    urlAction() {
      if (this.$route.name === 'create-district') {
        return `${process.env.VUE_APP_API}/api/backend/district/create`
      }
      return `${process.env.VUE_APP_API}/api/backend/district/update`
    },
  },
  mounted() {
    this.getProvinces()
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty('district_id')) {
      this.getDistrict()
    }
  },
  methods: {
    store() {
      this.$isLoading(true)
      this.$axios.post(this.urlAction, this.district)
        .then(() => {
          this.showToastr()
          this.$router.back()
        })
        .catch((error) => {
          this.validations = null
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getDistrict() {
      this.$axios.post(`${process.env.VUE_APP_API}/api/backend/district/show`, {
        id: this.$route.params.district_id,
      }).then(({data}) => {
        this.district = data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    getProvinces() {
      this.$isLoading(true)
      this.$axios.post(`${process.env.VUE_APP_API}/api/backend/district/get-provinces`)
        .then(({data}) => {
          this.provinces = data.data
        }).catch((error) => {
          this.onResponseError(error)
          this.$router.back()
        }).finally(() => {
          this.$isLoading(false)
        })
    },
  }
}
</script>

<style scoped>

</style>
