<template>
  <div class="row">
    <div :class="mapContainerClass">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('label.map') }}
          </h3>
          <div class="box-tools">
            <div class="form-inline">
              <div class="input-group margin-r-5">
                <input
                  v-model="search.query_text"
                  :placeholder="$t('string.search')"
                  class="form-control input-sm"
                  type="text"
                >
                <span class="btn btn-default input-group-addon">
                  <i class="fa fa-search" />
                </span>
              </div>
              <button
                v-if="can($Permissions.backend.office.filter)"
                class="btn btn-danger btn-sm"
                @click="showFilter = !showFilter"
              >
                <i class="fa fa-filter" /> {{ $t('string.filter') }}
              </button>
            </div>
          </div>
        </div>
        <div class="box-body">
          <div class="row">
            <div class="col-md-12">
              <div
                v-if="locations.length > 0"
                class="thumbnail"
              >
                <GmapMap
                  :center="center"
                  :zoom="8"
                  map-type-id="roadmap"
                  style="width: 100%; height: 70vh;"
                >
                  <GmapInfoWindow
                    :opened="infoWinOpen"
                    :options="infoOptions"
                    :position="infoWindowPos"
                    @closeclick="infoWinOpen=false"
                  />
                  <GmapCluster>
                    <GmapMarker
                      v-for="(m, i) in locations"
                      :key="i"
                      :clickable="true"
                      :icon="{url: '/img/markers/blue20x20.png'}"
                      :position="m"
                      @click="toggleInfoWindow(m,i)"
                    />
                  </GmapCluster>
                </GmapMap>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="can($Permissions.backend.office.filter) && showFilter">
      <div class="col-md-2">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('string.filter') }}
            </h3>
          </div>
          <div class="box-body">
            <div class="form-group">
              <label>{{ $t('string.constructionStatus') }}</label>
              <select
                v-model="search.construct_type_id"
                class="form-control"
              >
                <option
                  disabled
                  selected
                  value="0"
                >
                  {{ $t('string.constructionType') }}
                </option>
                <option :value="1">
                  {{ $t('string.all') }}
                </option>
                <option :value="2">
                  {{ $t('string.openConstruction') }}
                </option>
                <option :value="3">
                  {{ $t('string.closeConstruction') }}
                </option>
                <option :value="4">
                  {{ $t('string.pendingConstruction') }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>{{ $t('string.projectType') }}</label>
              <select
                v-model="search.project_type_id"
                class="form-control"
              >
                <option
                  disabled
                  selected
                  value="0"
                >
                  {{ $t('string.projectType') }}
                </option>
                <option :value="-1">
                  {{ $t('string.all') }}
                </option>
                <option
                  v-for="(projectType, key) in projectTypes"
                  :key="key"
                  :value="projectType.id"
                >
                  {{ projectType['name_' + $i18n.locale] }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>{{ $t('string.location') }}</label>
              <select
                v-model="search.province_id"
                class="form-control"
              >
                <option
                  disabled
                  selected
                  value="0"
                >
                  {{ $t('string.province') }}
                </option>
                <option :value="-1">
                  {{ $t('string.all') }}
                </option>
                <option
                  v-for="(province, key) in provinces"
                  :key="key"
                  :value="province.id"
                >
                  {{ province['name_' + $i18n.locale] }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <button
                    class="btn btn-primary btn-block btn-sm"
                    @click="getOfficeLatLng"
                  >
                    {{ $t('string.apply') }}
                  </button>
                </div>
                <div class="col-md-6">
                  <button
                    class="btn btn-default btn-block btn-sm"
                    @click="resetSearchOption"
                  >
                    {{ $t('string.reset') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import GmapCluster from 'vue2-google-maps/src/components/cluster'

export default {
  name: 'List',
  metaInfo: {
    title: process.env.VUE_APP_NAME,
    titleTemplate: 'Project Map | %s'
  },
  components: {GmapCluster},
  data() {
    return {
      showFilter: false,
      center: {
        lat: 12.71112,
        lng: 104.88873
      },
      infoWindowPos: null,
      infoWinOpen: true,
      currentMidx: null,
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      },

      locations: [],
      provinces: [],
      projectTypes: [],
      search: {
        query_text: '',
        permit_number: '',
        province_id: 0,
        project_type_id: 0,
        construct_type_id: 0,
      },
    }
  },
  computed: {
    mapContainerClass() {
      return this.showFilter ? 'col-md-10' : 'col-md-12'
    }
  },
  watch: {
    "$i18n.locale"() {
      this.infoWinOpen = false
    }
  },
  created() {
    this.getOfficeLatLng()
    this.enabledAction()
  },
  methods: {
    enabledAction() {
      this.clearEventHandler([
        '.project-title'
      ])
      let self = this
      $(document).on('click', '.project-title', function () {
        self.$router.push({
          name: 'show-office',
          params: {
            office_uuid: $(this).attr('data-uuid')
          }
        })
      })
    },
    resetSearchOption() {
      this.search = {
        query_text: '',
        permit_number: '',
        province_id: 0,
        project_type_id: 0,
        construct_type_id: 0,
      }
      this.getOfficeLatLng()
    },
    getOfficeLatLng() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/office/get-office-latlng', this.search)
        .then((response) => {
          if (response.data.data) {
            this.locations = response.data.data
          } else {
            this.locations = [
              {lat: 12.71112, lng: 104.88873}
            ]
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    toggleInfoWindow(marker) {
      this.infoWinOpen = true
      this.infoWindowPos = marker
      this.center = marker
      this.infoOptions.content = `
          <div class="project-window"
               style="width: 300px !important;
               height: auto !important;
               overflow-x: hidden !important;
               position: relative !important;">
            <div class="project-window-header">
                <h4 class="project-title" data-uuid="${marker.uuid}">
                    <a href="#">${marker['name_' + this.$i18n.locale]}</a>
                </h4>
            </div>
            <div class="project-window-body">
                <div class="row">
                    <div class="col-md-12">
                        <table class="table table-condensed">
                            <tbody>
                              <tr>
                                <th>${this.$t('table.nameInKhmer')}</th>
                                <td class="text-right">${marker.name_km ? marker.name_km : this.$t('string.na')}</td>
                              </tr>
                              <tr>
                                <th>${this.$t('table.nameInEnglish')}</th>
                                <td class="text-right">${marker.name_en ? marker.name_en : this.$t('string.na')}</td>
                              </tr>
                              <tr>
                                <th>${this.$t('label.code')}</th>
                                <td class="text-right">${marker.code ? marker.code : this.$t('string.na')}</td>
                              </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
          </div>
        `
    },
  }
}
</script>

<style scoped>

</style>
