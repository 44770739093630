<template>
  <div class="col-md-12">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t('string.otherSectorInformation') }}
        </h3>
      </div>
      <div class="box-body">
        <template v-if="project.ministry_permits.length>0">
          <table class="table table-condensed table-striped table-bordered">
            <thead>
              <tr>
                <th>{{ $t('string.ministry') }}</th>
                <th>{{ $t('string.number') }}</th>
                <th>{{ $t('string.date') }}</th>
                <th>{{ $t('string.approvedBy') }}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <template v-if="project.ministry_permits.length>0">
                <tr
                  v-for="(ministry_permit, key) in project.ministry_permits"
                  :key="key"
                >
                  <td>
                    {{
                      ministry_permit.ministry_permit ? ministry_permit.ministry_permit['name_' + $i18n.locale] : $t('string.na')
                    }}
                  </td>
                  <td>{{ ministry_permit.number }}</td>
                  <td>{{ getDate(ministry_permit.date, false) }}</td>
                  <td>{{ ministry_permit.approved_by }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </template>
        <template v-else>
          <h3 class="text-center">
            {{ $t('string.na') }}
          </h3>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectorPermitPanel",
  props: {
    project: {
      type: Object,
    }
  }
}
</script>

<style scoped>

</style>
