<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.openConstructionLetterList') }}
      </h3>
      <div class="box-tools">
        <template v-if="can([Permissions.backend.constructionLetter.openConstructionLetter.store])">
          <router-link
            :to="{name: 'create-note-open-construction-letter'}"
            class="btn btn-success btn-sm margin-r-5"
          >
            <i class="fa fa-plus" /> {{ $t('string.addNoteLetter') }}
          </router-link>
        </template>
        <template v-if="can([Permissions.backend.constructionLetter.openConstructionLetter.store])">
          <router-link
            :to="{name: 'create-open-construction-letter'}"
            class="btn btn-primary btn-sm"
          >
            <i class="fa fa-plus" /> {{ $t('string.addOpenConstructionLetter') }}
          </router-link>
        </template>
      </div>
    </div>
    <div class="box-body">
      <construction-letter-data-table
        show-route="show-open-construction-letter"
        approve-route="approval-open-construction-letter"
        edit-route="edit-open-construction-letter"
        edit-note-letter-route="edit-note-open-construction-letter"
        type="open_construction"
        :api-url="`${baseUrl}/construction-letter/open-construction-letter`"
      />
    </div>
  </div>
</template>

<script>
import ConstructionLetterDataTable from "../includes/Datatable"
import Permissions from "@/permissions"

export default {
  name: "List",
  components: {ConstructionLetterDataTable},
  metaInfo() {
    return {
      title: this.$t('string.openConstructionLetterList'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  data() {
    return {
      Permissions
    }
  },
}
</script>

<style scoped>

</style>
