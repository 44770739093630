<template>
  <div class="form-horizontal">
    <div class="form-group row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <div
              class="form-group row"
              :class="getProjectValidation.required?'has-error':null"
            >
              <label class="col-xs-4 control-label required">{{ $t('string.searchProject') }}</label>
              <div class="col-xs-8">
                <multiselect
                  v-model="selected_project"
                  :options="projects"
                  track-by="id"
                  :internal-search="false"
                  :placeholder="$t('string.searchProject')"
                  label="label"
                  @search-change="searchProject"
                />
                <template v-if="getProjectValidation.required">
                  <span class="label-error">{{ getProjectValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <div
              class="form-group row"
              :class="requestedByValidation.required?'has-error':null"
            >
              <label class="col-xs-4 control-label required">{{ $t('string.closeRequestedBy') }}</label>
              <div class="col-xs-8">
                <input
                  v-model="close_construction_letter.requested_by"
                  type="text"
                  class="form-control"
                  :placeholder="$t('string.closeRequestedBy')"
                >
                <template v-if="requestedByValidation.required">
                  <span class="label-error">{{ requestedByValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div
              class="form-group row"
              :class="requestedDateValidation.required ? 'has-error':null"
            >
              <label class="col-xs-4 control-label required">{{ $t('string.closeRequestedAt') }}</label>
              <div class="col-xs-8">
                <date-picker
                  v-model="close_construction_letter.requested_date"
                  format="DD/MM/YYYY"
                  class="table-full-width"
                  :lang="datepickerLocale[$i18n.locale].lang"
                  :placeholder="$t('string.closeRequestedAt')"
                />
                <template v-if="requestedDateValidation.required">
                  <span class="label-error">{{ requestedDateValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <div
              class="form-group row"
              :class="closeConstructionNumberValidation.required?'has-error':null"
            >
              <label class="col-xs-4 control-label">{{ $t('string.closeConstructionNumber') }}</label>
              <div class="col-xs-8">
                <input
                  v-model="close_construction_letter.close_construction_number"
                  type="number"
                  class="form-control"
                  :placeholder="$t('string.closeConstructionNumber')"
                >
                <template v-if="closeConstructionNumberValidation.required">
                  <span class="label-error">{{ closeConstructionNumberValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div
              class="form-group row"
              :class="closeConstructionDateValidation.required?'has-error':null"
            >
              <label class="col-xs-4 control-label">{{ $t('string.closeConstructionDate') }}</label>
              <div class="col-xs-8">
                <date-picker
                  v-model="close_construction_letter.close_construction_date"
                  format="DD/MM/YYYY"
                  class="table-full-width"
                  :lang="datepickerLocale[$i18n.locale].lang"
                  :placeholder="$t('string.closeConstructionDate')"
                />
                <template v-if="closeConstructionDateValidation.required">
                  <span class="label-error">{{ closeConstructionDateValidation.message }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="references.length>0"
      class="form-group row"
    >
      <div class="col-xs-12">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group row">
              <label class="col-sm-2 control-label">{{ $t('string.announcementReferences') }}</label>
              <div class="col-sm-10">
                <draggable
                  v-model="references"
                  class="list-group"
                  tag="ul"
                  v-bind="dragOptions"
                  @start="isDragging = true"
                  @end="isDragging = false"
                >
                  <transition-group
                    type="transition"
                    name="flip-list"
                  >
                    <li
                      v-for="element in references"
                      :key="element.order"
                      class="list-group-item"
                    >
                      <div class="drag-item">
                        <div class="drag-icon">
                          -
                        </div>
                        <div class="drag-title">
                          {{ element.name }}
                        </div>
                        <div class="drag-action">
                          <button
                            class="btn btn-danger btn-xs"
                            @click="references.splice(references.indexOf(element), 1)"
                          >
                            <i class="fa fa-trash-alt" />
                          </button>
                        </div>
                      </div>
                    </li>
                  </transition-group>
                </draggable>
                <div class="row">
                  <div class="col-md-12">
                    <div
                      class="form-group row"
                      :class="reference_validation?'has-error':null"
                    >
                      <div class="col-md-12">
                        <div class="input-group">
                          <input
                            v-model="new_reference"
                            type="text"
                            :placeholder="$t('string.addNewReferences')"
                            class="form-control"
                            @keydown.enter="addNewReferences"
                          >
                          <span
                            class="input-group-addon bg-gray"
                            @click="addNewReferences"
                          >
                            <i class="fa fa-plus" /> {{ $t('string.add') }}
                          </span>
                        </div>
                        <template v-if="reference_validation">
                          <span class="label-error">{{ $t('string.requiredMessage') }}</span>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group row">
              <label class="col-sm-2 control-label" />
              <div class="col-sm-10">
                <div class="row">
                  <div class="col-xs-12">
                    <project-building-detail :project-uuid="selected_project ? selected_project.uuid : null" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="carbonCopies.length>0"
      class="form-group row"
    >
      <div class="col-xs-12">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group row">
              <label class="col-sm-2 control-label">{{ $t('string.carbonCopies') }}</label>
              <div class="col-sm-10">
                <draggable
                  v-model="carbonCopies"
                  class="list-group"
                  tag="ul"
                  v-bind="dragOptions"
                  @start="isDragging = true"
                  @end="isDragging = false"
                >
                  <transition-group
                    type="transition"
                    name="flip-list"
                  >
                    <li
                      v-for="element in carbonCopies"
                      :key="element.order"
                      class="list-group-item"
                    >
                      <div class="drag-item">
                        <div class="drag-icon">
                          -
                        </div>
                        <div class="drag-title">
                          {{ element.name }}
                        </div>
                        <div class="drag-action">
                          <button
                            class="btn btn-danger btn-xs"
                            @click="carbonCopies.splice(carbonCopies.indexOf(element), 1)"
                          >
                            <i class="fa fa-trash-alt" />
                          </button>
                        </div>
                      </div>
                    </li>
                  </transition-group>
                </draggable>
                <div class="row">
                  <div class="col-md-12">
                    <div
                      class="form-group row"
                      :class="carbon_copy_validation?'has-error':null"
                    >
                      <div class="col-md-12">
                        <div class="input-group">
                          <input
                            v-model="new_carbon_copy"
                            type="text"
                            :placeholder="$t('string.addNewCarbonCopy')"
                            class="form-control"
                            @keydown.enter="addNewCarbonCopy"
                          >
                          <span
                            class="input-group-addon bg-gray"
                            @click="addNewCarbonCopy"
                          >
                            <i class="fa fa-plus" /> {{ $t('string.add') }}
                          </span>
                        </div>
                        <template v-if="carbon_copy_validation">
                          <span class="label-error">{{ $t('string.requiredMessage') }}</span>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-xs-10 col-xs-offset-2">
        <button
          v-if="!hideSaveButton"
          class="btn btn-primary btn-sm margin-right-5"
          @click="saveAndUploadFile"
        >
          {{ $t('button.save') }}
        </button>
        <button
          v-if="!hideSaveButton && hasPreviewButton"
          class="btn btn-success btn-sm margin-right-5"
          @click="saveAndPreview"
        >
          {{ $t('button.save') }} & {{ $t('string.preview') }}
        </button>
        <template v-if="$route.params.hasOwnProperty('construction_letter_uuid')">
          <button
            class="btn btn-default btn-sm"
            @click="skip"
          >
            {{ $t('button.skip') }}
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from 'debounce'
import draggable from 'vuedraggable'
import {validationMixins} from "@/mixins/validations"
import ProjectBuildingDetail from "@/views/project/includes/components/ProjectBuildingDetail"
import {mapFields} from "vuex-map-fields"

export default {
  name: "CloseConstructionLetterForm",
  components: {
    ProjectBuildingDetail,
    draggable,
  },
  mixins: [validationMixins],
  data() {
    return {
      carbon_copy_validation: false,
      new_carbon_copy: '',

      reference_validation: false,
      new_reference: '',
      validations: null,

      close_construction_letter: {},
      projects: [],

      selected_project: null,
      project: null,

      references: [],
      carbonCopies: [],

      buildings: [],
    }
  },
  computed: {
    api() {
      return `${this.baseUrl}/api/backend/construction-letter/close-construction-letter`
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
    hideSaveButton() {
      return this.close_construction_letter &&
      // eslint-disable-next-line no-prototype-builtins
					this.close_construction_letter.hasOwnProperty('status') &&
					this.close_construction_letter.status === 'Approved'
    },
    previewURL() {
      let baseUrl = `${this.baseUrl}/construction-letter`
      let construction_letter_uuid = this.$route.params.construction_letter_uuid
      if (this.$route.name === 'edit-open-construction-letter') {
        return `${baseUrl}/open-construction-letter/preview/${construction_letter_uuid}`
      }
      return `${baseUrl}/close-construction-letter/preview/${construction_letter_uuid}`
    },
    hasPreviewButton () {
      return this.$route.params.construction_letter_uuid
    },
    ...mapFields('general', {
      show_form: 'show_form'
    })
  },
  watch: {
    selected_project() {
      if (this.selected_project !== null) {
        this.close_construction_letter.project = this.selected_project
        this.getProject()
          .then(() => {
            this.getReferences()
          })
          .then(() => {
            this.getCarbonCopies()
          })
          .finally(() => {
            this.$isLoading(false)
          })
      }
    }
  },
  methods: {
    skip() {
      this.$router.push({
        name: 'upload-close-construction-letter',
        params: {
          construction_letter_uuid: this.$route.params.construction_letter_uuid,
        }
      }).catch(() => {
      })
    },
    addNewCarbonCopy() {
      this.carbon_copy_validation = false
      let order = Math.max(...this.carbonCopies.map(item => parseInt(item.order)))
      if (this.new_carbon_copy === '' || this.new_carbon_copy === undefined) {
        this.carbon_copy_validation = true
        return 0
      }
      this.carbonCopies.push({
        id: parseInt(order) + 1,
        name: this.new_carbon_copy,
        type: 'Other',
        order: parseInt(order) + 1,
      })
      this.new_carbon_copy = ''
    },
    addNewReferences() {
      this.reference_validation = false
      let order = Math.max(...this.references.map(item => parseInt(item.order)))
      if (this.new_reference === '' || this.new_reference === undefined) {
        this.reference_validation = true
        return 0
      }
      this.references.push({
        id: parseInt(order) + 1,
        name: this.new_reference,
        type: 'Other',
        order: parseInt(order) + 1,
      })
      this.new_reference = ''
    },
    addNewBuilding() {},
    saveAndPreview() {
      const self = this
      this.save(function () {
        self.show_form = false
      })
    },
    saveAndUploadFile() {
      const self = this
      this.save(function (data) {
        self.showToastr()
        self.$router.push({
          name: 'upload-close-construction-letter',
          params: {
            construction_letter_uuid: data.data.uuid,
          }
        })
      })
    },
    save(callback) {
      this.$set(this.close_construction_letter, 'carbon_copies', this.carbonCopies)
      this.$set(this.close_construction_letter, 'references', this.references)
      this.$set(this.close_construction_letter, 'buildings', this.buildings)

      let requested_date = null
      if (this.close_construction_letter.requested_date !== '' && this.close_construction_letter.requested_date !== undefined) {
        if (this.$moment(this.close_construction_letter.requested_date).isValid()) {
          requested_date = this.$moment(this.close_construction_letter.requested_date).locale('en').format('YYYY-MM-DD')
        }
      }
      this.$set(this.close_construction_letter, 'requested_date', requested_date)

      let close_construction_date = null
      if (this.close_construction_letter.close_construction_date !== '' && this.close_construction_letter.close_construction_date !== undefined) {
        if (this.$moment(this.close_construction_letter.close_construction_date).isValid()) {
          close_construction_date = this.$moment(this.close_construction_letter.close_construction_date).locale('en').format('YYYY-MM-DD')
        }
      }
      this.$set(this.close_construction_letter, 'close_construction_date', close_construction_date)

      this.validations = null
      this.$isLoading(true)
      this.$axios.post(`${this.api}/store`, this.close_construction_letter)
        .then(({data}) => {
          this.$isLoading(false)
          callback(data)
        })
        .catch((error) => {
          this.$isLoading(false)
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    async getProject() {
      this.$isLoading(true)
      let response = await this.$axios.post(`${this.api}/get-project`, {
        project_id: this.selected_project.id,
        construction_letter_uuid: this.$route.params.construction_letter_uuid,
      })
      this.project = response.data.data
      if (this.project.buildings.length > 0) {
        this.buildings = this.project.buildings
      } else {
        this.buildings = []
      }
      if (this.project.owners.length > 0) {
        this.close_construction_letter.requested_by = this.project.owners.map((item) => {
          return item.name_km
        }).join(', ')
      }
    },
    async getReferences() {
      this.$isLoading(true)
      let construction_letter_uuid = null
      // eslint-disable-next-line no-prototype-builtins
      if (this.$route.params.hasOwnProperty('construction_letter_uuid')) {
        construction_letter_uuid = this.$route.params.construction_letter_uuid
      }
      let response = await this.$axios.post(`${this.api}/get-references`, {
        project_id: this.selected_project !== null ? this.selected_project.id : null,
        construction_letter_uuid: construction_letter_uuid,
      })
      this.references = response.data.data.map((item) => {
        return {id: item.id, name: item.title, order: item.id, type: item.type}
      })
    },
    async getCarbonCopies() {
      this.$isLoading(true)
      let construction_letter_uuid = null
      // eslint-disable-next-line no-prototype-builtins
      if (this.$route.params.hasOwnProperty('construction_letter_uuid')) {
        construction_letter_uuid = this.$route.params.construction_letter_uuid
      }
      let response = await this.$axios.post(`${this.api}/get-carbon-copies`, {
        project_id: this.selected_project !== null ? this.selected_project.id : null,
        construction_letter_uuid: construction_letter_uuid,
      })
      this.carbonCopies = response.data.data.map((item) => {
        return {
          id: item.id,
          name: item.title,
          order: item.id,
          type: item.type,
        }
      })
    },
    async getCloseConstructionLetter() {
      this.$isLoading(true)
      let response = await this.$axios.post(`${this.api}/show`, {
        construction_letter_uuid: this.$route.params.construction_letter_uuid,
      })
      let data = await response.data.data
      this.$set(this.close_construction_letter, 'uuid', data.uuid)
      this.$set(this.close_construction_letter, 'close_construction_number', data.number)

      if (this.$moment(data.date).isValid()) {
        this.$set(this.close_construction_letter, 'close_construction_date', this.$moment(data.date).locale('en').toDate())
      }

      // this.close_construction_letter.requested_by = data.requested_by
      if (this.$moment(data.requested_date).isValid()) {
        this.$set(this.close_construction_letter, 'requested_date', this.$moment(data.requested_date).locale('en').toDate())
      }

      this.selected_project = data.selected_project
      this.close_construction_letter.status = data.status

      // buildings
      this.buildings = data.buildings
    },
    searchProject: debounce(function (query_text = '') {
      this.$axios.post(`${this.api}/search-project`, {
        query_text: query_text
      }).then(({data}) => {
        if (data.data !== null && data.data.length > 0) {
          this.projects = data.data.map((project) => {
            let item = {
              id: project.id,
              label: ''
            }
            if (project.permit_number !== null) {
              if (project.year !== null) {
                item.label += ` (${project.permit_number}-${project.year})`
              } else {
                item.label += ` (${project.permit_number})`
              }
            }
            if (project.construction_type !== null) {
              item.label += project.construction_type.name_km
            }
            return item
          })
        }
      })
    }),
  },
  mounted() {
    this.getCarbonCopies().then(() => {
      this.getReferences().then(() => [
        this.$isLoading(false)
      ])
    })
    this.searchProject()
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty('construction_letter_uuid')) {
      this.getCloseConstructionLetter()
    }
  }
}
</script>

<style scoped>
  .button {
    margin-top: 35px;
  }

  .flip-list-move {
    transition: transform 0.5s;
  }

  .no-move {
    transition: transform 0s;
  }

  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .list-group {
    min-height: 20px;
  }

  .list-group-item {
    cursor: move;
  }

  .list-group-item i {
    cursor: pointer;
  }

  .drag-item {
    display: grid;
    grid-template-columns: 20px 1fr 30px;
  }

  .input-group-addon {
    cursor: pointer;
  }
</style>
