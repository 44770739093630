import {getField, updateField} from "vuex-map-fields"

const state = {
  address: {
    home_number: null,
    street_number: null,
    group_number: null,
    province_id: null,
    district_id: null,
    commune_id: null,
    village_id: null,
  },
  validations: {},
  reload: false,
}


const getters = {
  getField
}

const mutations = {
  updateField,
  setValidations(state, value) {
    state.validations = value
  },
  setProvince(state, value) {
    state.address.province_id = value
  },
  setDistrict(state, value) {
    state.address.district_id = value
  },
  setVillage(state, value) {
    state.address.village_id = value
  },
  setCommune(state, value) {
    state.address.commune_id = value
  },
  setHomeNumber(state, value) {
    state.address.home_number = value
  },
  setStreetNumber(state, value) {
    state.address.street_number = value
  },
  setGroupNumber(state, value) {
    state.address.group_number = value
  },
  setAddress(state, data) {
    state.address.province_id = data.province_id ? data.province_id : null
    state.address.district_id = data.district_id ? data.district_id : null
    state.address.commune_id = data.commune_id ? data.commune_id : null
    state.address.village_id = data.village_id ? data.village_id : null
    state.address.home_number = data.village_id ? data.home_number : null
    state.address.street_number = data.village_id ? data.street_number : null
    state.address.group_number = data.village_id ? data.group_number : null
    // eslint-disable-next-line no-prototype-builtins
    state.reload = data.hasOwnProperty('reload')
  }
}

const actions = {
  setAddress({commit}, data) {
    commit('setAddress', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
