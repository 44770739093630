import Index from "./../../views/constructionLetter/open/Index"
import List from "./../../views/constructionLetter/open/List"
import Create from "./../../views/constructionLetter/open/Create"
import Edit from "./../../views/constructionLetter/open/Edit"
import Show from "./../../views/constructionLetter/open/Show"
import Upload from "./../../views/constructionLetter/open/Upload"
import Approval from "./../../views/constructionLetter/open/Approval"
import Note from "./../../views/constructionLetter/open/Note"
import Permissions from "../../permissions"

export const openConstructionLetterRouters = {
  path: 'open-construction-letter',
  component: Index,
  redirect: 'open-construction-letter',
  meta: {
    permissions: [Permissions.backend.constructionLetter.openConstructionLetter.manage],
    groups: 'open-construction-letter',
  },
  children: [
    {
      path: '',
      name: 'list-open-construction-letter',
      component: List,
      meta: {
        permissions: [Permissions.backend.constructionLetter.openConstructionLetter.list],
        groups: 'open-construction-letter',
        breadcrumb: [
          {
            name_en: 'Construction Letter',
            name_km: 'លិខិតសម្រាប់ការដ្ឋាន'
          },
          {
            name_en: 'Open Construction Letter',
            name_km: 'លិខិតបើកការដ្ឋាន'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-open-construction-letter',
      component: Create,
      meta: {
        permissions: [Permissions.backend.constructionLetter.openConstructionLetter.store],
        groups: 'open-construction-letter',
        breadcrumb: [
          {
            name_en: 'Construction Letter',
            name_km: 'លិខិតសម្រាប់ការដ្ឋាន'
          },
          {
            name_en: 'Open Construction Letter',
            name_km: 'លិខិតបើកការដ្ឋាន'
          },
          {
            name_en: 'Create',
            name_km: 'បង្កើត'
          }
        ]
      }
    },
    {
      path: 'create-note',
      name: 'create-note-open-construction-letter',
      component: Note,
      meta: {
        permissions: [Permissions.backend.constructionLetter.openConstructionLetter.store],
        groups: 'open-construction-letter',
        breadcrumb: [
          {
            name_en: 'Construction Letter',
            name_km: 'លិខិតសម្រាប់ការដ្ឋាន'
          },
          {
            name_en: 'Open Construction Letter',
            name_km: 'លិខិតបើកការដ្ឋាន'
          },
          {
            name_en: 'Create',
            name_km: 'បង្កើត'
          }
        ]
      }
    },
    {
      path: 'edit-note/:construction_letter_uuid',
      name: 'edit-note-open-construction-letter',
      component: Note,
      meta: {
        permissions: [Permissions.backend.constructionLetter.openConstructionLetter.store],
        groups: 'open-construction-letter',
        breadcrumb: [
          {
            name_en: 'Construction Letter',
            name_km: 'លិខិតសម្រាប់ការដ្ឋាន'
          },
          {
            name_en: 'Open Construction Letter',
            name_km: 'លិខិតបើកការដ្ឋាន'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'edit/:construction_letter_uuid',
      name: 'edit-open-construction-letter',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.constructionLetter.openConstructionLetter.edit],
        groups: 'open-construction-letter',
        breadcrumb: [
          {
            name_en: 'Construction Letter',
            name_km: 'លិខិតសម្រាប់ការដ្ឋាន'
          },
          {
            name_en: 'Open Construction Letter',
            name_km: 'លិខិតបើកការដ្ឋាន'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'show/:construction_letter_uuid',
      name: 'show-open-construction-letter',
      component: Show,
      meta: {
        permissions: [Permissions.backend.constructionLetter.openConstructionLetter.show],
        groups: 'open-construction-letter',
        breadcrumb: [
          {
            name_en: 'Construction Letter',
            name_km: 'លិខិតសម្រាប់ការដ្ឋាន'
          },
          {
            name_en: 'Open Construction Letter',
            name_km: 'លិខិតបើកការដ្ឋាន'
          },
          {
            name_en: 'Show',
            name_km: 'បង្ហាញព័ត៏មាន'
          }
        ]
      }
    },
    {
      path: 'upload/:construction_letter_uuid',
      name: 'upload-open-construction-letter',
      component: Upload,
      meta: {
        permissions: [Permissions.backend.constructionLetter.openConstructionLetter.show],
        groups: 'open-construction-letter',
        breadcrumb: [
          {
            name_en: 'Construction Letter',
            name_km: 'លិខិតសម្រាប់ការដ្ឋាន'
          },
          {
            name_en: 'Open Construction Letter',
            name_km: 'លិខិតបើកការដ្ឋាន'
          },
          {
            name_en: 'Upload',
            name_km: 'បញ្ជូនឯកសារ'
          }
        ]
      }
    },
    {
      path: 'approval/:construction_letter_uuid',
      name: 'approval-open-construction-letter',
      component: Approval,
      meta: {
        permissions: [Permissions.backend.constructionLetter.openConstructionLetter.approve],
        groups: 'open-construction-letter',
        breadcrumb: [
          {
            name_en: 'Construction Letter',
            name_km: 'លិខិតសម្រាប់ការដ្ឋាន'
          },
          {
            name_en: 'Open Construction Letter',
            name_km: 'លិខិតបើកការដ្ឋាន'
          },
          {
            name_en: 'Approve',
            name_km: 'អនុម័ត'
          }
        ]
      }
    }
  ]
}
