<template>
  <div v-if="options.length > 0">
    <template v-for="(item, key) in options">
      <div
        :key="key"
        class="row"
      >
        <div class="col-md-12">
          <div
            class="box"
            :class="isAllowInspectionApp(item) ? 'box-danger' : 'box-primary'"
          >
            <div class="box-header with-border">
              <h3 class="box-title">
                {{ item['name_' + $i18n.locale] }}
                {{ isAllowInspectionApp(item) ? `(${$t('label.noDisplay')})` : '' }}
              </h3>
              <template v-if="item.show_view_mode">
                <div class="box-tools">
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="..."
                  >
                    <button
                      type="button"
                      class="btn btn-sm"
                      :class="item.view_mode === 'card' ? 'btn-primary':'btn-default'"
                      @click="changeViewMode(item, 'card')"
                    >
                      <i class="fas fa-th-large" />
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm"
                      :class="item.view_mode === 'list' ? 'btn-primary':'btn-default'"
                      @click="changeViewMode(item, 'list')"
                    >
                      <i class="fas fa-th-list" />
                    </button>
                  </div>
                </div>
              </template>
            </div>
            <div class="box-body">
              <template v-if="item.view_mode === 'list'">
                <upload-media-item-list
                  :path="item.path"
                  :type="item.type"
                  :accept-file="item.accept"
                  :size="item.size ? item.size : '10240'"
                  :model-name="modelName"
                  :copiable="!!item.copiable"
                  :button-delete-all="!!item.multiple_delete"
                  :button-refresh="!!item.button_refresh"
                  :copy-to-model-type="item.to_model_type"
                  :copy-to-model-path="item.to_model_path"
                  :draggable="item.draggable ? item.draggable : draggable"
                  :model-uuid="modelUuid"
                  :button-icon="buttonIcon"
                  :readonly="readonly"
                  :button-label="buttonLabel"
                  :column="previewColumn"
                />
              </template>
              <template v-else>
                <upload-media-item
                  :path="item.path"
                  :type="item.type"
                  :accept-file="item.accept"
                  :size="item.size ? item.size : '10240'"
                  :model-name="modelName"
                  :copiable="!!item.copiable"
                  :button-delete-all="!!item.multiple_delete"
                  :button-refresh="!!item.button_refresh"
                  :copy-to-model-type="item.to_model_type"
                  :copy-to-model-path="item.to_model_path"
                  :draggable="item.draggable ? item.draggable : draggable"
                  :model-uuid="modelUuid"
                  :button-icon="buttonIcon"
                  :readonly="readonly"
                  :button-label="buttonLabel"
                  :column="previewColumn"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import UploadMediaItem from "./UploadMediaItem"
import UploadMediaItemList from "./UploadMediaItemList"

export default {
  name: "UploadMedia",
  components: {
    UploadMediaItemList,
    UploadMediaItem
  },
  props: {
    modelName: {
      type: String,
      required: true
    },
    modelUuid: {
      type: String,
      required: true
    },
    previewColumn: {
      type: String,
      default: 'col-md-2 col-xs-4 margin-bottom'
    },
    buttonLabel: {
      type: String,
      default () {
        return this.$t('string.chooseImage')
      }
    },
    buttonIcon: {
      type: String,
      default: 'fa fa-image'
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      options: []
    }
  },
  computed: {
    ...mapState({
      reload: state => state.media.data.reload
    }),
  },
  watch: {
    reload() {
      // this.getMediaOptions()
    }
  },
  mounted() {
    this.getMediaOptions()
  },
  methods: {
    changeViewMode(value, mode) {
      if (mode) {
        if (mode === 'list') {
          value.view_mode = 'list'
        } else {
          value.view_mode = 'card'
        }
      }
    },
    isAllowInspectionApp (item) {
      // eslint-disable-next-line no-prototype-builtins
      return item.hasOwnProperty('allow_inspection_app') && item.allow_inspection_app === false
    },
    getMediaOptions() {
      this.$axios.post(this.$base_api + "/api/backend/media/get-option", {
        model_name: this.modelName
      }).then((response) => {
        this.options = response.data.data
      }).catch((error) => {
        this.onResponseError(error)
      })
    },
  }
}
</script>

<style scoped>

</style>
