<template>
  <div class="row">
    <div class="col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('label.showMission') }}
          </h3>
          <div class="box-tools">
            <template v-if="can($Permissions.backend.mission.edit)">
              <template v-if="showForm">
                <button
                  class="btn btn-primary btn-sm margin-right-5"
                  @click="$router.push({name: 'edit-mission', params: {mission_uuid: $route.params.mission_uuid}})"
                >
                  <i class="fa fa-edit" /> {{ $t('string.edit') }}
                </button>
              </template>
            </template>
            <asora-back-button />
          </div>
        </div>
        <div class="box-body">
          <div
            v-if="mission != null"
            class="form-horizontal"
          >
            <div class="form-group row">
              <div class="col-sm-3 control-label">
                {{ $t('label.missionTitle') }}
              </div>
              <div class="col-sm-9">
                <b>{{ mission.title }}</b>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-3 control-label">
                {{ $t('label.missionDescription') }}
              </div>
              <div class="col-sm-9">
                <b>{{ mission.title }}</b>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-3 control-label">
                {{ $t('label.startDate') }}
              </div>
              <div class="col-sm-9">
                <b>{{ getDate(mission.start_date, false) }}</b>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-3 control-label">
                {{ $t('label.endDate') }}
              </div>
              <div class="col-sm-9">
                <b>{{ getDate(mission.end_date, false) }}</b>
              </div>
            </div>
            <template v-if="mission.project">
              <div class="form-group row">
                <div class="col-sm-3 control-label">
                  {{ $t('label.project') }}
                </div>
                <div class="col-sm-9">
                  <b>
                    <router-link :to="{name: 'show-project', params:{project_uuid: mission.project.uuid}}">
                      {{ mission.project ? mission.project['name_' + $i18n.locale] : $t('string.na') }}
                    </router-link>
                  </b>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-sm-3 control-label">
                  {{ $t('label.permitNumber') }}
                </div>
                <div class="col-sm-9">
                  <b>
                    {{ mission.project.permit_number ? mission.project.permit_number : $t('string.na') }}
                  </b>
                </div>
              </div>
            </template>
            <div class="form-group row">
              <div class="col-sm-3 control-label">
                {{ $t('label.teamOfficers') }}
              </div>
              <div class="col-sm-9">
                <template v-if="mission.teams && mission.teams.length>0">
                  <span
                    v-for="(team, key) in mission.teams"
                    :key="key"
                    class="label label-primary"
                  >
                    {{ team['name_' + $i18n.locale] }}
                  </span>
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-3 control-label">
                {{ $t('label.officers') }}
              </div>
              <div class="col-sm-9">
                <template v-if="mission.officers && mission.officers.length>0">
                  <people-chip-item
                    v-for="(officer, key) in mission.officers"
                    :key="key"
                    :people="{
                      id: officer.id,
                      uuid: officer.uuid,
                      email: officer.email,
                      phone: officer.phone,
                      name_en: officer.name_en,
                      name_km: officer.name_km,
                      avatar: officer.avatar
                    }"
                    model="officer"
                  />
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <upload-media
        model-name="mission"
        :readonly="showForm"
        preview-column="col-md-4"
        :button-label="$t('label.chooseReference')"
        :model-uuid="$route.params.mission_uuid"
      />
    </div>
  </div>
</template>

<script>
import UploadMedia from "../../components/UploadMedia"
import PeopleChipItem from "../../components/PeopleChipItem"

export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('label.showMission'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {
    PeopleChipItem,
    UploadMedia,
  },
  data() {
    return {
      mission: null,
    }
  },
  computed: {
    showForm() {
      return this.$route.name === 'show-mission'
    },
  },
  created() {
    this.getMission()
  },
  methods: {
    getMission() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/mission/show', {
        mission_uuid: this.$route.params.mission_uuid,
      }).then((res) => {
        if (res.data.data) {
          this.mission = res.data.data
        } else {
          this.mission = null
        }
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-mission'})
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>
  .control-label {
    padding-top: 0;
  }
</style>
