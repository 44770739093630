<template>
  <construction-data-form
    :button="{
      label: $t('button.save'),
      btnClass: 'primary',
      handle: 'create'
    }"
    :title="$t('label.newConstructionData')"
  />
</template>

<script>
import ConstructionDataForm from "./Form"

export default {
  name: "Create",
  metaInfo() {
    return {
      title: this.$t('label.newConstructionData'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {ConstructionDataForm}
}
</script>

<style scoped>

</style>
