import {mapState} from 'vuex'

const helpers = {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    Vue.mixin({
      data() {
        return {
          administrators: [
            process.env.VUE_APP_ROLE_ADMIN,
            'admin'
          ],
          datepickerLocale: {
            km: {
              lang: {
                formatLocale: {
                  months: ['មករា', 'កុម្ភៈ', 'មីនា', 'មេសា', 'ឧសភា', 'មិថុនា', 'កក្កដា', 'សីហា', 'កញ្ញា', 'តុលា', 'វិច្ឆិកា', 'ធ្នូ'],
                  monthsShort: ['មករា', 'កុម្ភៈ', 'មីនា', 'មេសា', 'ឧសភា', 'មិថុនា', 'កក្កដា', 'សីហា', 'កញ្ញា', 'តុលា', 'វិច្ឆិកា', 'ធ្នូ'],
                  days: ['អា', 'ច', 'អ', 'ពុ', 'ព្រ', 'សុ', 'ស'],
                  weekdays: ['អា', 'ច', 'អ', 'ពុ', 'ព្រ', 'សុ', 'ស'],
                  weekdaysShort: ['អា', 'ច', 'អ', 'ពុ', 'ព្រ', 'សុ', 'ស'],
                  weekdaysMin: ['អា', 'ច', 'អ', 'ពុ', 'ព្រ', 'សុ', 'ស'],
                }
              }
            },
            en: {
              lang: 'en'
            }
          }
        }
      },
      computed: {
        getLocale() {
          return `/locale/${this.$i18n.locale}.json`
        },
        ...mapState({
          userRoles: state => state.user.data ? state.user.data.roles : null,
          userPermissions: state => state.user.data ? state.user.data.permissions : null,
        }),
        baseUrl() {
          return process.env.VUE_APP_API
        },
        i18nDeactive() {
          return this.$t('label.deactive')
        },
        i18nChangePassword() {
          return this.$t('label.changePassword')
        },
        i18nActive() {
          return this.$t('label.active')
        },
        i18nEdit() {
          return this.$t('label.edit')
        },
        i18nShow() {
          return this.$t('label.show')
        },
        i18nDelete() {
          return this.$t('label.delete')
        },
        i18nSwalTitle() {
          return this.$t('label.swal.title')
        },
        i18nSwalDesc() {
          return this.$t('label.swal.desc')
        },
        i18nSwalYes() {
          return this.$t('label.swal.yes')
        },
        i18nSwalNo() {
          return this.$t('label.swal.no')
        },
        i18nSwalSuccess() {
          return this.$t('label.swal.success')
        },
        i18nSwalDeleteLabel() {
          return this.$t('label.swal.deleteLabel')
        }
      },
      methods: {
        generateYears(start = process.env.VUE_APP_CCI_START_YEAR) {
          let years = []
          let current = parseInt(((new Date()).getFullYear()).toString())
          for (let year = start; year <= current; year++) {
            years.unshift(year)
          }
          return years
        },
        onClickOpenNewTab(route) {
          let routeData = this.$router.resolve(route)
          return window.open(routeData.href, '_blank')
        },
        getLinkOpenNewTab(route) {
          let routeData = this.$router.resolve(route)
          return routeData.href
        },
        goDashboard() {
          if (this.can([this.$Permissions.backend.dashboard.ministryLevel])) {
            return this.$router.push({name: 'level-ministry'}).catch(() => {
            })
          }
          if (this.can([this.$Permissions.backend.dashboard.provinceLevel])) {
            return this.$router.push({name: 'level-province'}).catch(() => {
            })
          }
          return this.$router.push({name: 'welcome'}).catch(() => {
          })
        },
        enabledColumnAdjust(oTable) {
          this.clearEventHandler(['.sidebar-toggle'])
          oTable.oTable.columns.adjust().draw()
          $(document).on('click', '.sidebar-toggle', function () {
            oTable.columns.adjust().draw()
          })
        },
        getDate(date, twoLines = true, format = process.env.VUE_APP_CCI_DATE_FORMAT) {
          if (date && this.$moment(date).isValid()) {
            if (twoLines) {
              return `
                        ${this.$moment(date).locale('en').format('DD/MM/YYYY')} <br/>
                        <small>${this.$moment(date).locale('en').format('HH:mm:ss A')}</small>
                    `
            }
            return this.$moment(date).locale('en').format(format)
          }
          return this.$t('string.na')
        },
        getPrepareCompany(company) {
          return {
            id: company.id,
            uuid: company.uuid,
            name_en: company.name_en,
            name_km: company.name_km,
            email: company.email,
            phone: company.phone,
            avatar: company.logo,
            id_card: company.first_registration_number,
            is_registered: company.is_registered,
          }
        },
        getPreparePeople(people) {
          return {
            name_en: people.name_en,
            name_km: people.name_km,
            id_card: people.national_id,
            phone: people.phone,
            email: people.email,
            avatar: people.avatar,
            uuid: people.uuid,
            is_registered: people.is_registered,
            passport_number: people.passport ? people.passport : people.passport_number,

          }
        },
        getPrepareArchitecture(architecture) {
          return {
            name_en: architecture.name_en,
            name_km: architecture.name_km,
            id_card: architecture.id_card,
            phone: architecture.phone,
            email: architecture.email,
            avatar: architecture.avatar,
            uuid: architecture.uuid,
            passport_number: architecture.passport_number,
            is_registered: architecture.is_registered,
          }
        },
        getBusinessDays(firstDate, secondDate) {
          let d1 = this.$moment(firstDate)
          let d2 = this.$moment(secondDate)
          const days = d2.diff(d1, "days") + 1
          let newDay = d1.toDate()
          let workingDays = 0
          let sundays = 0
          let saturdays = 0
          for (let i = 0; i < days; i++) {
            const day = newDay.getDay()
            newDay = d1.add(1, "days").toDate()
            const isWeekend = ((day % 6) === 0)
            if (!isWeekend) {
              workingDays++
            } else {
              // eslint-disable-next-line no-unused-vars
              if (day === 6) saturdays++
              // eslint-disable-next-line no-unused-vars
              if (day === 0) sundays++
            }
          }
          return workingDays
        },
        log(message) {
          console.log(`%c ${message}`, 'background: #4fc08d; padding: 4px; font-size: 10px; color: white; font-weight: bold; border-radius: 4px;')
        },
        checkValidationHasColumn(validations, column) {
          // eslint-disable-next-line no-prototype-builtins
          return validations !== null && validations.hasOwnProperty(column)
        },
        getValidationColumn(validations, column) {
          let message = {
            required: false,
            message: null
          }
          if (this.checkValidationHasColumn(validations, column)) {
            message = {
              required: true,
              message: validations[column][0],
            }
          }
          return message
        },
        // Return value that can be used in img tag. both src or image file
        createObjectURL(media) {
          if (media instanceof File) {
            return URL.createObjectURL(media)
          } else {
            return this.getSrc(media)
          }
        },
        getSrc(url) {
          if (!url) {
            return ""
          } else if (url.startsWith('http')) {
            return url
          } else {
            if (url.startsWith('/')) {
              return process.env.VUE_APP_API + url
            } else {
              return process.env.VUE_APP_API + '/' + url
            }
          }
        },
        // Prompt confirm promise
        onConfirm(options) {
          const self = this
          return new Promise((resolve, reject) => {
            this.$swal(Object.assign({
              title: self.i18nSwalTitle,
              text: self.i18nSwalDesc,
              type: 'warning',
              showCancelButton: true,
              cancelButtonText: self.i18nSwalNo,
              confirmButtonText: self.i18nSwalYes
            }, options)).then((result) => {
              if (result.value) {
                resolve({
                  accept: true
                })
              } else {
                reject({
                  accept: false
                })
              }
            })
          })
        },
        // Convert javascript object includes file to formdata
        getFormData(value, includeNull = true, exceptFields = [], keyTransforms, mappings) {
          let formData = new FormData()

          const appender = (value, prefixKey = "") => {

            const getStrippedKey = (key) => (key.replace(/\[[0-9]+\]/g, '[]'))

            // get key eg: fruit[0][apple] -> fruit[][apple]
            let strippedKey = getStrippedKey(prefixKey)

            // Exclude key
            if (exceptFields.includes(strippedKey)) return

            // map
            if (mappings && mappings[strippedKey] !== undefined) {
              value = mappings[strippedKey](value)
            }

            if (Array.isArray(value)) {
              value.forEach((data, index) => {
                appender(data, prefixKey + "[" + index + "]")
              })
            } else if (value === null || value === undefined) {
              if (includeNull) {
                formData.append(prefixKey, "")
              }
            } else if (value instanceof File) {
              formData.append(prefixKey, value)
            } else if (typeof value === 'object') {
              for (const key in value) {

                let formDataKey = prefixKey ? prefixKey + '[' + key + ']' : key
                let strippedPreKey = getStrippedKey(formDataKey)

                // Transform key
                if (keyTransforms && keyTransforms[strippedPreKey] !== undefined) {
                  formDataKey = prefixKey ? prefixKey + '[' + keyTransforms[strippedPreKey] + ']' : keyTransforms[strippedPreKey]
                }

                appender(value[key], formDataKey)
              }
            } else {
              formData.append(prefixKey, value)
            }
          }
          appender(value)
          return formData
        },
        // Convert text area content to HTML text
        getTextareaHtml(content) {
          if (!content) {
            return this.$t('label.na')
          }
          return content
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;")
            // .replace(/ /g, "&nbsp;")
            .replace(/\n/g, '<br/>')
        },
        // get name_en or name_km for object base on current locale
        getName(object) {
          if (object) {
            // eslint-disable-next-line no-prototype-builtins
            if (object.hasOwnProperty('name_' + this.$i18n.locale)) {
              return object['name_' + this.$i18n.locale]
              // eslint-disable-next-line no-prototype-builtins
            } else if (object.hasOwnProperty('name')) {
              return object.name
              // eslint-disable-next-line no-prototype-builtins
            } else if (object.hasOwnProperty('title')) {
              return object.title
            }
          }
          return this.$t('label.na')
        },
        showSwalSuccess() {
          this.$swal({
            type: 'success',
            text: this.i18nSwalSuccess,
            title: this.i18nSwalDeleteLabel,
            confirmButtonText: this.i18nSwalYes
          })
        },
        showToastr() {
          this.$toastr('success', this.$t('string.theRequestHaveBeenProcessed'), this.$t('string.success'))
        },
        onResponseError(error) {
          if (error.statusText) {
            this.$toastr('error', error.statusText, this.$t('string.error'))
          } else if (error.response) {
            if (error.response.status === 403) {
              this.$toastr('error', error.response.data.message, this.$t('string.error'))
            } else if (error.response.status === 422) {
              this.$toastr('error', error.response.data.message, this.$t('string.error'))
            } else if (error.response.status === 401) {
              this.$toastr('error', error.response.data.message, this.$t('string.error'))
              localStorage.clear()
              this.$router.push({name: 'login'})
              this.$store.commit('user/clearUser')
            } else {
              try {
                if (error.response.data.message && error.response.data.message['message_' + this.$i18n.locale]) {
                  this.$toastr('error', error.response.data.message['message_' + this.$i18n.locale], this.$t('string.error'))
                } else {
                  this.$toastr('error', error.response.statusText, this.$t('string.error'))
                }
              } catch (e) {
                this.$toastr('error', this.$t('string.somethingWentWrong'), this.$t('string.error'))
              }
            }
          }
          if (error.response === undefined) {
            localStorage.clear()
            this.$router.push({name: 'login'})
            this.$store.commit('user/clearUser')
          }
        },
        getUserInfo() {
          let self = this
          this.$axios.post(this.$base_api + '/api/backend/user/get-roles-and-permissions')
            .then((response) => {
              let result = response.data.data
              self.$store.dispatch('user/setUser', {user: result.user})
              // store roles
              self.$store.commit('user/setRoles', result.roles)

              // store permission
              self.$store.commit('user/setPermissions', result.permissions)
            }).catch(error => {
              this.onResponseError(error)
            })
        },
        clearEventHandler(classNames) {
          classNames.map((className) => {
            return $(document).off('click', className)
          })
        },
        can(permissions) {
          if (!permissions || permissions.length === 0 || this.$store.getters['user/isAdmin']) {
            return true
          }

          if (!this.userPermissions) {
            return false
          }

          return this.userPermissions.some(permission => permissions.includes(permission))
        },
        hasRoles(roles) {
          return this.$store.getters['user/isAdmin'] || this.onlyHasRoles(roles)
        },
        onlyHasRoles(roles) {
          if (!roles || roles.length === 0) {
            return true
          }

          if (!this.userRoles) {
            return false
          }

          return this.userRoles.some(role => roles.includes(role))
        },
        isAdmin() {
          if (!this.userRoles) {
            return false
          }
          return this.userRoles.some(role => this.administrators.includes(role))
        },
        formatPrice(value) {
          let val = (value / 1)
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
      }
    })
  }
}

export default helpers
