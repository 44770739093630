import Secondary from "../layouts/Secondary"
import Login from "../views/Login"

export const secondaryRouters = {
  path: '',
  name: 'secondary',
  component: Secondary,
  redirect: '/',
  children: [
    {
      path: '',
      name: 'login',
      component: Login,
      meta: {
        noAuth: true
      }
    }
  ]
}
