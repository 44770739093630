<template>
  <div class="change-avatar">
    <p
      class="text-center"
      style="margin-bottom: 10px;"
    >
      <img
        :src="imgDataUrl || '/images/avatar/avatar.png'"
        width="300"
        height="300"
        alt="User avatar"
      >
    </p>
    <a
      class="btn btn-primary"
      @click="toggleShow"
    >{{ $t('label.uploadYourAvatar') }}</a>
    <my-upload
      v-model="show"
      field="avatar"
      lang-type="en"
      :width="80"
      :height="80"
      :url="`${apiUrl}/api/auth/backend/change-avatar`"
      :params="params"
      :headers="headers"
      img-format="png"
      @crop-success="cropSuccess"
      @crop-upload-success="cropUploadSuccess"
      @crop-upload-fail="cropUploadFail"
    />
  </div>
</template>

<script>
import 'babel-polyfill'
import myUpload from 'vue-image-crop-upload'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ChangeAvatar',
  components: {
    'my-upload': myUpload
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API,
      show: false,
      params: {
        user_id: null,
        name: 'avatar'
      },
      headers: null,
      imgDataUrl: ''
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.data
    })
  },
  methods: {
    ...mapActions('user', {
      setUser: 'setUser'
    }),
    toggleShow() {
      this.show = !this.show
    },
    cropSuccess() {
    },
    cropUploadSuccess(jsonData) {
      this.$store.dispatch('user/setUser', {user: jsonData.data})
      this.imgDataUrl = this.apiUrl +'/'+ jsonData.data.avatar
    },
    cropUploadFail() {
    }
  },
  mounted() {
    this.headers = {
      'Authorization': 'Bearer ' + localStorage.getItem(this.$token),
      'Accept': 'application/json'
    }
    this.imgDataUrl = this.user.avatar ? this.apiUrl +'/'+ this.user.avatar : this.user.avatar
  }
}
</script>

<style scoped>
  .change-avatar {
    align-content: center;
    text-align: center;
    padding-top: 10vh;
    padding-bottom: 10vh;
  }
</style>
