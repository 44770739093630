import unauthorized from './../views/errors/401'

export const unauthorizedRouters = {
  path: 'unauthorized',
  name: 'unauthorized',
  component: unauthorized,
  meta: {
    noAuth: true
  },
}
