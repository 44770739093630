<template>
  <office-form
    :button="{
      label: $t('button.save'),
      btnClass: 'primary',
      handle: 'create'
    }"
    :title="$t('label.newOffice')"
  />
</template>

<script>
import OfficeForm from "./Form"

export default {
  name: "Create",
  components: {OfficeForm},
  metaInfo: {
    title: process.env.VUE_APP_NAME,
    titleTemplate: 'New Office | %s'
  }
}
</script>

<style scoped>

</style>
