<template>
  <div class="row">
    <div :class="mapContainerClass">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('label.map') }}
          </h3>
          <div class="box-tools">
            <div class="form-inline">
              <div class="input-group margin-r-5">
                <input
                  v-model="search.query_text"
                  :placeholder="$t('string.search')"
                  class="form-control input-sm"
                  type="text"
                >
                <span class="btn btn-default input-group-addon">
                  <i class="fa fa-search" />
                </span>
              </div>
              <template v-if="can($Permissions.backend.provinceProject.filter)">
                <button
                  class="btn btn-danger btn-sm"
                  @click="showFilter = !showFilter"
                >
                  <i class="fa fa-filter" /> {{ $t('string.filter') }}
                </button>
              </template>
            </div>
          </div>
        </div>
        <div class="box-body">
          <div class="row">
            <div class="col-md-12">
              <div
                v-if="locations.length > 0"
                class="thumbnail"
              >
                <GmapMap
                  :center="center"
                  :zoom="8"
                  map-type-id="roadmap"
                  style="width: 100%; height: 70vh;"
                >
                  <GmapInfoWindow
                    :opened="infoWinOpen"
                    :options="infoOptions"
                    :position="infoWindowPos"
                    @closeclick="infoWinOpen=false"
                  />
                  <GmapCluster>
                    <GmapMarker
                      v-for="(m, i) in locations"
                      :key="i"
                      :clickable="true"
                      :icon="{url: '/img/markers/blue20x20.png'}"
                      :position="m"
                      @click="toggleInfoWindow(m,i)"
                    />
                  </GmapCluster>
                </GmapMap>
              </div>
              <template v-else>
                <h3 class="text-center">
                  {{ $t('string.noResult') }}
                </h3>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="can($Permissions.backend.provinceProject.filter) && showFilter">
      <div class="col-md-2">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('string.filter') }}
            </h3>
          </div>
          <div class="box-body">
            <div class="form-group">
              <label>{{ $t('string.constructionStatus') }}</label>
              <select
                v-model="search.construct_type_id"
                class="form-control"
              >
                <option
                  disabled
                  selected
                  value="0"
                >
                  {{ $t('string.constructionType') }}
                </option>
                <option :value="1">
                  {{ $t('string.all') }}
                </option>
                <option :value="2">
                  {{ $t('string.openConstruction') }}
                </option>
                <option :value="3">
                  {{ $t('string.closeConstruction') }}
                </option>
                <option :value="4">
                  {{ $t('string.pendingConstruction') }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>{{ $t('string.projectType') }}</label>
              <select
                v-model="search.project_type_id"
                class="form-control"
              >
                <option
                  disabled
                  selected
                  value="0"
                >
                  {{ $t('string.projectType') }}
                </option>
                <option :value="-1">
                  {{ $t('string.all') }}
                </option>
                <option
                  v-for="(projectType, key) in projectTypes"
                  :key="key"
                  :value="projectType.id"
                >
                  {{ projectType['name_' + $i18n.locale] }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>{{ $t('string.location') }}</label>
              <select
                v-model="search.province_id"
                class="form-control"
              >
                <option
                  :value="null"
                  disabled
                  selected
                >
                  {{ $t('string.choose_province') }}
                </option>
                <option :value="-1">
                  {{ $t('string.all') }}
                </option>
                <option
                  v-for="(province, key) in provinces"
                  :key="key"
                  :value="province.id"
                >
                  {{ province['name_' + $i18n.locale] }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <button
                    class="btn btn-primary btn-block btn-sm"
                    @click="getProjectLatLng"
                  >
                    {{ $t('string.apply') }}
                  </button>
                </div>
                <div class="col-md-6">
                  <button
                    class="btn btn-default btn-block btn-sm"
                    @click="resetSearchOption"
                  >
                    {{ $t('string.reset') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import GmapCluster from 'vue2-google-maps/src/components/cluster'
import {mapGetters} from "vuex"

export default {
  name: 'List',
  metaInfo() {
    return {
      title: this.$t('label.map'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {GmapCluster},
  data() {
    return {
      showFilter: false,
      center: {
        lat: 12.71112,
        lng: 104.88873
      },
      infoWindowPos: null,
      infoWinOpen: true,
      currentMidx: null,
      infoOptions: {
        content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      },

      locations: [],
      allProvinces: [],
      projectTypes: [],
      search: {
        query_text: '',
        permit_number: '',
        province_id: null,
        project_type_id: 0,
        construct_type_id: 0,
        is_province_data_level: true,
      },
      listAllProvinceProjects: this.can(this.$Permissions.backend.provinceProject.listAll)
    }
  },
  computed: {
    mapContainerClass() {
      return this.showFilter ? 'col-md-10' : 'col-md-12'
    },
    ...mapGetters('project', {
      isProvinceDataLevel: 'isProvinceDataLevel'
    }),
    ...mapGetters('user', {
      userProvinces: 'provinces'
    }),
    provinces() {
      if (this.listAllProvinceProjects) {
        return this.allProvinces
      }
      if (this.userProvinces && Array.isArray(this.userProvinces)) {
        return this.userProvinces
      }
      return []
    },
  },
  watch: {
    "$i18n.locale"() {
      this.infoWinOpen = false
    }
  },
  created() {
    this.getProjectLatLng()
    this.getProjectType()
    this.getProvinces()
    this.enabledActions()
  },
  methods: {
    resetSearchOption() {
      this.search = {
        query_text: '',
        permit_number: '',
        province_id: null,
        project_type_id: 0,
        construct_type_id: 0,
        is_province_data_level: true,
      }
      this.getProjectLatLng()
    },
    getProjectLatLng() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project/get-project-latlng', this.search)
        .then((response) => {
          if (response.data.data) {
            this.locations = response.data.data
          } else {
            this.locations = [
              {lat: 12.71112, lng: 104.88873}
            ]
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    toggleInfoWindow(marker) {
      this.infoWinOpen = true
      this.infoWindowPos = marker
      this.center = marker
      let thumbnail1 = marker.cci_images[0] ? this.getSrc(marker.cci_images[0].src) : '/img/placeholder.png'
      let thumbnail2 = marker.cci_images[1] ? this.getSrc(marker.cci_images[1].src) : '/img/placeholder.png'
      let thumbnail3 = marker.cci_images[2] ? this.getSrc(marker.cci_images[2].src) : '/img/placeholder.png'
      this.infoOptions.content = `
          <div class="project-window"
               style="width: 300px !important;
               height: auto !important;
               font-family: var(--secondary-font);
               overflow-x: hidden !important;
               position: relative !important;">
            <div class="project-window-header">
                <h4 class="project-title"
                style="font-family: var(--secondary-font);"
                data-uuid="${marker.uuid}">
                    <a href="#">${marker['name_' + this.$i18n.locale]}</a>
                </h4>
            </div>
            <div class="project-window-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="image-container">
                            <div class="image-first"
                            style="background-image: url(${thumbnail1})"></div>
                            <div class="image-other">
                                <div class="image-other-first"
                                style="background-image: url(${thumbnail2})"></div>
                                <div class="image-other-second"
                                style="background-image: url(${thumbnail3})">
                                +${marker.cci_images.length > 3 ? (parseFloat(marker.cci_images.length) - 3) : '0'}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <table class="table table-condensed">
                            <tbody>
                              <tr>
                                <th>${this.$t('label.surface')}</th>
                                <td class="text-right">${marker.surface ? marker.surface : this.$t('string.na')}</td>
                              </tr>
                              <tr>
                                <th>${this.$t('label.nbFloor')}</th>
                                <td class="text-right">${marker.nb_floor_request ? marker.nb_floor_request : this.$t('string.na')}</td>
                              </tr>
                              <tr>
                                <th>${this.$t('label.landUniqueNumber')}</th>
                                <td class="text-right">${marker.land_unique_numbers ? marker.land_unique_numbers : this.$t('string.na')}</td>
                              </tr>
                              <tr>
                                <th>${this.$t('label.permitNumber')}</th>
                                <td class="text-right">${marker.permit_number ? marker.permit_number : this.$t('string.na')}</td>
                              </tr>
                              <tr>
                                <th>${this.$t('label.permitDate')}</th>
                                <td class="text-right">${this.getDate(marker.permit_date, false, 'DD/MM/YYYY')}</td>
                              </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
          </div>
        `
    },
    getProvinces() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/location/get-location')
        .then(({data}) => {
          if (data.data) {
            this.allProvinces = data.data
          } else {
            this.allProvinces = []
          }
        })
    },
    getProjectType() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-type/get-option')
        .then((res) => {
          if (res.data.data.length > 0) {
            this.projectTypes = res.data.data
          } else {
            this.projectTypes = []
          }
        })
    },
    enabledActions() {
      let self = this
      this.clearEventHandler([
        '.btn-learn-more',
        '.project-title',
      ])

      const routeName = this.isProvinceDataLevel ? 'show-province-project' : 'show-project'

      $(document).on('click', '.btn-learn-more', function () {
        self.$router.push({
          name: routeName,
          params: {
            project_uuid: $(this).attr('data-uuid')
          }
        })
      })

      $(document).on('click', '.project-title', function () {
        self.$router.push({
          name: routeName,
          params: {
            project_uuid: $(this).attr('data-uuid')
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.project-window {
  width: 300px !important;
  height: auto !important;
  position: relative !important;
  font-family: "KHMERMEF1",serif !important;
}

.gm-style-iw {
  height: auto !important;
  max-height: none !important;
}

.gm-style-iw-d {
  height: auto !important;
  max-height: none !important;
}
</style>
