const state = {
  modal: {
    architecture_type_id: 1,
  }
}

const getters = {}

const mutations = {
  setModalArchitectureTypeId(state, id) {
    state.modal.architecture_type_id = id
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
