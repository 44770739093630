<template>
  <div class="row">
    <div class="col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ title }}
          </h3>
          <div class="box-tools">
            <template v-if="can(editPermission)">
              <button
                class="btn btn-primary btn-sm margin-right-5"
                @click="$router.push({
                  name: modelRouteEdit,
                  params: {
                    architecture_uuid: $route.params.architecture_uuid,
                  }
                })"
              >
                <i class="fa fa-edit" /> {{ $t('button.edit') }}
              </button>
            </template>
            <asora-back-button />
          </div>
        </div>
        <div class="box-body">
          <div class="form-horizontal">
            <div class="form-group row">
              <div class="col-sm-9 col-sm-offset-3">
                <render-image-tag :src="architecture.avatar ? `${baseUrl}/${architecture.avatar}` : null" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.architectureType') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  <template v-if="architecture.architecture_type">
                    : {{ architecture.architecture_type['name_' + $i18n.locale ] }}
                  </template>
                  <template v-else>
                    : {{ $t('string.na') }}
                  </template>
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.uniqueNumber') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ architecture.unique_number }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.declarationNumberOfMinistry') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ architecture.registration_number }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.registration_expired_date') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ getDate(architecture.registration_expired_date, false, 'DD/MM/YYYY') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.nameKm') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ architecture.name_km }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.nameEn') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ architecture.name_en }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.gender') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  :
                  {{ architecture.gender ? architecture.gender['name_' + $i18n.locale] : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.dob') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ getDate(architecture.dob, false, 'DD/MM/YYYY') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.placeOfBirth') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  {{ architecture.place_of_birth || $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.degree') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  <template v-if="architecture.degree_id">
                    {{ architecture.degree['name_' + $i18n.locale ] }}
                  </template>
                  <template v-else>
                    {{ $t('string.na') }}
                  </template>
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.salary') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  {{ architecture.salary || $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.certificate_number') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ architecture.certificate_number || $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.graduateDate') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ getDate(architecture.graduation_date, false) }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.fromUniversity') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  <template v-if="architecture.university">
                    : {{ architecture.university['name_' + $i18n.locale] }}
                  </template>
                  <template v-else>
                    : {{ $t('string.na') }}
                  </template>
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.email') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  <a :href="`mailto:${architecture.email}`">
                    : {{ architecture.email ? architecture.email : $t('string.na') }}
                  </a>
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.phone') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  <a :href="`tel:${architecture.phone}`">
                    : {{ architecture.phone ? architecture.phone : $t('string.na') }}
                  </a>
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.nationality') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ architecture.nationality ? architecture.nationality['name_' + $i18n.locale] : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.nationalIdCard') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ architecture.national_id_card || $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.nationalIdCardCreationDate') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ getDate(architecture.national_id_card_creation_date, false) }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.passportNumber') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ architecture.passport_number || $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.homeNumber') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  :
                  {{ architecture.home_number ? architecture.home_number : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.streetNumber') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  :
                  {{ architecture.street_number ? architecture.street_number : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.groupNumber') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  :
                  {{ architecture.group_number ? architecture.group_number : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.province') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ architecture.province ? architecture.province['name_' + $i18n.locale] : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.district') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ architecture.district ? architecture.district['name_' + $i18n.locale] : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.commune') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ architecture.commune ? architecture.commune['name_' + $i18n.locale] : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.village') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ architecture.village ? architecture.village['name_' +$i18n.locale] : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.createdAt') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ getDate(architecture.created_at, false) }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.updatedAt') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ getDate(architecture.updated_at, false) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <upload-media
        model-name="architecture"
        :readonly="modeShow"
        preview-column="col-md-4"
        :button-label="$t('label.chooseReference')"
        :model-uuid="$route.params.architecture_uuid"
      />
    </div>
  </div>
</template>

<script>
import RenderImageTag from "../../components/RenderImageTag"
import UploadMedia from "../../components/UploadMedia"

export default {
  name: "ShowArchitecture",
  components: {UploadMedia, RenderImageTag},
  props: {
    modeShow: {
      type: Boolean,
      default: true,
    },
    modelRouteEdit: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    editPermission: {
      type: String,
    }
  },
  data() {
    return {
      architecture: {}
    }
  },
  created() {
    this.getArchitecture()
  },
  methods: {
    getArchitecture() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/architecture/show', {
        architecture_uuid: this.$route.params.architecture_uuid,
      }).then((res) => {
        this.architecture = res.data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>
  .control-label {
    padding-top: 0 !important;
  }
</style>
