<template>
  <div v-if="projectUuid">
    <!-- Button trigger modal -->
    <div class="margin-bottom">
      <button
        ref="openModal"
        type="button"
        class="btn btn-primary btn-sm"
        data-toggle="modal"
        data-target="#myModal"
      >
        <i class="fa fa-plus-circle" /> {{ $t('string.addBuilding') }}
      </button>
    </div>

    <!-- Modal -->
    <div
      id="myModal"
      class="modal fade"
      tabindex="-1"
      data-backdrop="static"
      data-keyboard="false"
      role="dialog"
      aria-labelledby="myModalLabel"
    >
      <div
        class="modal-dialog modal-lg"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <button
              ref="close"
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="resetForm"
            >
              <span
                aria-hidden="true"
              >&times;</span>
            </button>
            <h4
              id="myModalLabel"
              class="modal-title font-secondary"
            >
              <template v-if="action === 'create'">
                {{ $t('buildingDetail.string.newBuilding') }}
              </template>
              <template v-else>
                {{ $t('buildingDetail.string.editBuilding') }}
              </template>
            </h4>
          </div>
          <div class="modal-body">
            <h5 class="font-secondary">
              {{ $t('buildingDetail.string.buildingInformation') }}
            </h5>
            <div class="form-container">
              <div
                class="form-group row"
                :class="{'has-error' : validations.hasOwnProperty('name')}"
              >
                <div class="col-md-12">
                  <label class="required">{{ $t('buildingDetail.field.label.buildingName') }}</label>
                  <input
                    v-model="building.name"
                    type="text"
                    :placeholder="$t('buildingDetail.field.placeholder.buildingName')"
                    class="form-control"
                  >
                  <template v-if="validations.hasOwnProperty('name')">
                    <span class="label-error">{{ validations['name'][0] }}</span>
                  </template>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group padding-lr-15">
                    <label>{{ $t('buildingDetail.field.label.year') }}</label>
                    <input
                      v-model="building.year"
                      type="number"
                      :placeholder="$t('buildingDetail.field.label.year')"
                      class="form-control"
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group padding-lr-15">
                    <label>{{ $t('buildingDetail.field.label.usage_function') }}</label>
                    <input
                      v-model="building.usage_function"
                      type="text"
                      :placeholder="$t('buildingDetail.field.label.usage_function')"
                      class="form-control"
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group padding-lr-15">
                    <label>{{ $t('buildingDetail.field.label.total_construction_area') }}</label>
                    <input
                      v-model="building.total_construction_area"
                      type="number"
                      :placeholder="$t('buildingDetail.field.label.total_construction_area')"
                      class="form-control"
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group padding-lr-15">
                    <label>{{ $t('buildingDetail.field.label.total_construction_area_practical') }}</label>
                    <input
                      v-model="building.total_construction_area_practical"
                      type="number"
                      :placeholder="$t('buildingDetail.field.label.total_construction_area_practical')"
                      class="form-control"
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group padding-lr-15">
                    <label>{{ $t('buildingDetail.field.label.buildingAmount') }}</label>
                    <input
                      v-model="building.number_building"
                      type="number"
                      :placeholder="$t('buildingDetail.field.placeholder.buildingAmount')"
                      class="form-control"
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group padding-lr-15">
                    <label>{{ $t('buildingDetail.field.placeholder.buildingAmountPractical') }}</label>
                    <input
                      v-model="building.number_building_practical"
                      type="number"
                      :placeholder="$t('buildingDetail.field.placeholder.buildingAmountPractical')"
                      class="form-control"
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group padding-lr-15">
                    <label>{{ $t('buildingDetail.field.label.buildingFloorAmount') }}</label>
                    <input
                      v-model="building.floor"
                      type="number"
                      :placeholder="$t('buildingDetail.field.label.buildingFloorAmount')"
                      class="form-control"
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group padding-lr-15">
                    <label>{{ $t('buildingDetail.field.label.buildingFloorAmountPractical') }}</label>
                    <input
                      v-model="building.floor_practical"
                      type="number"
                      :placeholder="$t('buildingDetail.field.label.buildingFloorAmountPractical')"
                      class="form-control"
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group padding-lr-15">
                    <label>{{ $t('buildingDetail.field.label.buildingUndergroundFloorAmount') }}</label>
                    <input
                      v-model="building.underground_floor"
                      type="number"
                      :placeholder="$t('buildingDetail.field.label.buildingUndergroundFloorAmount')"
                      class="form-control"
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group padding-lr-15">
                    <label>{{ $t('buildingDetail.field.label.buildingUndergroundFloorAmount') }}</label>
                    <input
                      v-model="building.underground_floor_practical"
                      type="text"
                      :placeholder="$t('buildingDetail.field.label.buildingUndergroundFloorAmountPractical')"
                      class="form-control"
                    >
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group padding-lr-15">
                    <label>{{ $t('buildingDetail.field.label.buildingHigh') }}</label>
                    <div class="input-group">
                      <input
                        v-model="building.high"
                        type="number"
                        :placeholder="$t('buildingDetail.field.label.buildingHigh')"
                        class="form-control"
                      >
                      <span class="input-group-addon bg-gray">m</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group padding-lr-15">
                    <label>{{ $t('buildingDetail.field.label.buildingHigh') }}</label>
                    <div class="input-group">
                      <input
                        v-model="building.high_practical"
                        type="text"
                        :placeholder="$t('buildingDetail.field.label.buildingHighPractical')"
                        class="form-control"
                      >
                      <span class="input-group-addon bg-gray">m</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group padding-lr-15">
                    <label>{{ $t('buildingDetail.field.label.buildingDescription') }}</label>
                    <textarea
                      v-model="building.description"
                      :placeholder="$t('buildingDetail.field.label.buildingDescription')"
                      class="form-control"
                      rows="5"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group padding-lr-15">
                    <label>{{ $t('buildingDetail.field.label.buildingDescription') }}</label>
                    <textarea
                      v-model="building.description_practical"
                      :placeholder="$t('buildingDetail.field.label.buildingDescription')"
                      class="form-control"
                      rows="5"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group padding-lr-15">
                    <label>{{ $t('buildingDetail.field.label.total_floors') }}</label>
                    <input
                      v-model="building.total_floors"
                      type="number"
                      :placeholder="$t('buildingDetail.field.label.total_floors')"
                      class="form-control"
                    >
                  </div>
                </div>
              </div>
            </div>

            <h5 class="font-secondary">
              {{ $t('buildingDetail.string.buildingFloor') }}
            </h5>
            <div class="row">
              <div class="col-md-12">
                <div class="form-container">
                  <div class="row">
                    <div class="col-md-12">
                      <button
                        class="btn btn-primary btn-sm margin-bottom"
                        @click="building.floors.push({name: '', total_construction_area: '', total_construction_area_practical: ''})"
                      >
                        <i class="fa fa-plus-circle" /> {{ $t('buildingDetail.button.add') }}
                      </button>
                    </div>
                    <div class="col-md-12">
                      <table class="table table-bordered table-striped table-hover">
                        <thead>
                          <tr>
                            <td>{{ $t('buildingDetail.table.no') }}</td>
                            <td>{{ $t('buildingDetail.table.name') }}</td>
                            <td>{{ $t('buildingDetail.table.totalConstructionArea') }}</td>
                            <td>{{ $t('buildingDetail.table.totalConstructionAreaPractical') }}</td>
                            <td>{{ $t('buildingDetail.table.action') }}</td>
                          </tr>
                        </thead>
                        <tbody>
                          <template v-if="building.floors && building.floors.length">
                            <tr
                              v-for="(floor, key) in building.floors"
                              :key="key"
                            >
                              <td>{{ key + 1 }}</td>
                              <td>
                                <input
                                  v-model="floor.name"
                                  type="text"
                                  :placeholder="$t('buildingDetail.table.name')"
                                  class="form-control"
                                >
                              </td>
                              <td>
                                <input
                                  v-model="floor.total_construction_area"
                                  type="number"
                                  :placeholder="$t('buildingDetail.table.totalConstructionArea')"
                                  class="form-control"
                                >
                              </td>
                              <td>
                                <input
                                  v-model="floor.total_construction_area_practical"
                                  type="number"
                                  :placeholder="$t('buildingDetail.table.totalConstructionAreaPractical')"
                                  class="form-control"
                                >
                              </td>
                              <td>
                                <button
                                  class="btn btn-danger btn-xs"
                                  @click="building.floors.splice(building.floors.indexOf(floor), 1)"
                                >
                                  <i class="fa fa-trash" />
                                </button>
                              </td>
                            </tr>
                          </template>
                          <template v-else>
                            <tr>
                              <td
                                colspan="5"
                                class="text-center"
                              >
                                {{ $t('buildingDetail.string.noFloors') }}
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="clearfix" />
          </div>
          <div class="modal-footer">
            <button
              ref="buttonCancel"
              type="button"
              :disabled="isLoading"
              class="btn btn-default"
              data-dismiss="modal"
              @click="resetForm"
            >
              {{ $t('buildingDetail.button.cancel') }}
            </button>
            <template v-if="action === 'create'">
              <button
                type="button"
                :disabled="isLoading"
                class="btn btn-primary"
                @click="createBuilding"
              >
                <template v-if="isLoading">
                  <i class="fa fa-spinner fa-spin fa-fw" />
                </template>
                {{ $t('buildingDetail.button.save') }}
              </button>
            </template>
            <template v-else>
              <button
                type="button"
                :disabled="isLoading"
                class="btn btn-success"
                @click="updateBuilding"
              >
                <template v-if="isLoading">
                  <i class="fa fa-spinner fa-spin fa-fw" />
                </template>
                {{ $t('buildingDetail.button.update') }}
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
    <!--Project Building Detail-->
    <project-building-detail
      ref="projectBuildingDetail"
      :project-uuid="projectUuid"
      :enabled-title="false"
      :enabled-action="true"
    />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import ProjectBuildingDetail from "@/views/project/includes/components/ProjectBuildingDetail"

export default {
  name: "ProjectBuildingForm",
  components: {ProjectBuildingDetail},
  props: {
    projectUuid: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      validations: {},
      building: {
        floor: '',
        high: '',
        number_building: '',
        name: '',
        description: '',
        underground_floor: '',
        floor_practical: '',
        high_practical: '',
        number_building_practical: '',
        underground_floor_practical: '',
        description_practical: '',
        year: '',
        usage_function: '',
        total_construction_area: '',
        total_construction_area_practical: '',
        total_floors: '',
        floors: [],
      },
      isLoading: false,
      action: 'create'
    }
  },
  computed: {
    ...mapState({
      building_id: state => state.building.modal.building_id
    })
  },
  watch: {
    building_id () {
      if (this.building_id) {
        this.$refs.openModal.click()
        this.action = 'edit'
        this.getBuilding()
      } else {
        this.action = 'create'
      }
    }
  },
  methods: {
    resetForm() {
      this.validations = {}
      this.$store.commit('building/setBuildingId', null)
      this.building = {
        floor: '',
        high: '',
        number_building: '',
        name: '',
        description: '',
        underground_floor: '',
        floor_practical: '',
        high_practical: '',
        number_building_practical: '',
        underground_floor_practical: '',
        description_practical: '',
        year: '',
        usage_function: '',
        total_construction_area: '',
        total_construction_area_practical: '',
        total_floors: '',
        floors: [],
      }
    },
    createBuilding() {
      this.validations = {}
      this.isLoading = true
      this.$set(this.building, 'project_uuid', this.projectUuid)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-building/create', this.building)
        .then((response) => {
          if (response.data && response.data.data) {
            this.$refs.close.click()
            this.$refs.projectBuildingDetail.getProjectBuildingDetail()
            this.$toastr('success', this.$t('string.theRequestHaveBeenProcessed'), this.$t('string.success'))
            this.resetForm()
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updateBuilding() {
      this.validations = {}
      this.isLoading = true
      this.$set(this.building, 'building_id', this.building_id)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-building/update', this.building)
        .then((response) => {
          if (response.data && response.data.data) {
            this.$refs.close.click()
            this.$refs.projectBuildingDetail.getProjectBuildingDetail()
            this.$toastr('success', this.$t('string.theRequestHaveBeenProcessed'), this.$t('string.success'))
            this.resetForm()
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getBuilding() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-building/show-building', {
        building_id: this.building_id,
      }).then((response) => {
        if (response.data && response.data.data) {
          this.building = response.data.data
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
  }
}
</script>

<style scoped>
.padding-lr-15 {
  padding-left: 15px;
  padding-right: 15px;
}

label {
  /*display: none;*/
}

.modal-body {
  background-color: #f2f2f2;
}

.form-container {
  box-shadow: 0 0 24px rgba(0, 0, 0, .04);
  padding: 10px;
  background-color: #ffffff;
  margin-bottom: 15px !important;
  border-radius: 2px;
}
</style>
