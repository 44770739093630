import Index from "./../../views/companyManagement/certificates/Index"
import List from "./../../views/companyManagement/certificates/List"
import Create from "./../../views/companyManagement/certificates/Create"

export const certificateCompanyRouters = {
  path: 'certificates',
  component: Index,
  redirect: 'certificates',
  meta: {
    permissions: [],
    groups: 'certificates-company',
  },
  children: [
    {
      path: '',
      name: 'list-certificates-company',
      component: List,
      meta: {
        permissions: [],
        groups: 'certificates-company',
        breadcrumb: [
          {
            name_en: 'The Certificate Company',
            name_km: 'វិញ្ញាបនប័ត្រក្រុមហ៊ុន'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-certificates-company',
      component: Create,
      meta: {
        permissions: [],
        groups: 'certificates-company',
        breadcrumb: [
          {
            name_en: 'The Certificate Company',
            name_km: 'វិញ្ញាបនប័ត្រក្រុមហ៊ុន'
          },
          {
            name_en: 'Create',
            name_km: 'បង្កើត'
          }
        ]
      }
    },
  ]
}
