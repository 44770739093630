<template>
  <div class="role">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t('label.list.role') }}
        </h3>
        <div class="box-tools pull-right">
          <router-link
            v-if="can($Permissions.backend.administration.role.store)"
            type="button"
            :to="{name: 'create-role'}"
            class="btn btn-primary btn-sm"
          >
            <i class="fa fa-plus" />{{ $t('label.newRole') }}
          </router-link>
        </div>
      </div>
      <div class="box-body">
        <div>
          <table
            id="role-table"
            class="table table-full-width table-hover table-striped table-bordered"
          >
            <thead>
              <tr>
                <th>{{ $t('table.name') }}</th>
                <th>{{ $t('table.nameInEnglish') }}</th>
                <th>{{ $t('table.nameInKhmer') }}</th>
                <th>{{ $t('table.numberOfUsers') }}</th>
                <th>{{ $t('table.createdAt') }}</th>
                <th>{{ $t('table.action') }}</th>
              </tr>
            </thead>
            <tbody />
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'List',
  metaInfo() {
    return {
      title: this.$t('menu.role'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      oTable: null
    }
  },
  computed: {
    columns() {
      let self = this
      return [
        {
          data: 'name',
          name: 'name',
          title: self.$t('table.name')
        },
        {
          data: 'display_name_en',
          name: 'display_name_en',
          title: self.$t('table.nameInEnglish')
        },
        {
          data: 'display_name_km',
          name: 'display_name_km',
          title: self.$t('table.nameInKhmer')
        },
        {
          data: 'number_of_users',
          name: 'number_of_users',
          searchable: false,
          orderable: false,
          title: self.$t('table.numberOfUsers')
        },
        {
          data: 'created_at',
          name: 'created_at',
          width: '150px',
          searchable: false,
          title: self.$t('table.createdAt'),
          render: (data) => {
            return this.getDate(data)
          }
        },
        {
          data: 'action',
          name: 'action',
          searchable: false,
          orderable: false,
          width: '70px',
          title: self.$t('table.action')
        },
      ]
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.refreshTable()
      this.oTable.draw(true)
    }
  },
  mounted() {
    this.oTable = this.refreshTable()
    this.loadAction()
  },
  methods: {
    refreshDataTable() {
      this.oTable.draw(true)
    },
    deleteRole(id) {
      let self = this
      this.$swal.fire({
        title: self.i18nSwalTitle,
        text: self.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: self.i18nSwalNo,
        confirmButtonText: self.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/role/delete', {
            id: id
          }).then(() => {
            self.$swal({
              title: self.i18nSwalDeleteLabel,
              text: self.i18nSwalSuccess,
              type: 'success',
              confirmButtonText: self.$t('button.ok')
            })
            self.oTable.draw(true)
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
    refreshTable() {
      let self = this
      return $('#role-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: process.env.VUE_APP_API + '/api/backend/role/datatable',
          data: (d) => {
            d.edit = self.i18nEdit
            d.delete = self.i18nDelete
            d.language = self.$i18n.locale || 'en'
          },
          error: (xhr) => {
            self.onResponseError(xhr)
          }
        },
        columns: self.columns,
        language: {
          url: `/locale/${self.$i18n.locale}.json`
        },
        fixedColumns: true,
        order: [
          [5, 'desc']
        ]
      })
    },
    loadAction() {
      let self = this
      this.clearEventHandler([
        '.btn-edit',
        '.btn-delete'
      ])
      $(function () {
        $(document).on('click', '.btn-edit', function (e) {
          e.preventDefault()
          self.$router.push({name: 'edit-role', params: {id: $(this).attr('data-id')}})
        })
        $(document).on('click', '.btn-delete', function (e) {
          e.preventDefault()
          self.deleteRole($(this).attr('data-id'))
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
