<template>
  <li
    v-if="current_province"
    class="dropdown"
  >
    <a
      href="#"
      class="dropdown-toggle font-secondary"
      data-toggle="dropdown"
    >{{ $t('string.municipal') }}({{ current_province ? current_province['name_' + $i18n.locale] : 'N/A' }})</a>
    <ul
      class="dropdown-menu"
      role="menu"
    >
      <li
        v-for="(province, key) in provinces"
        :key="key"
        @click="switchCurrentProvince(province)"
      >
        <a href="">{{ province.name_km }}</a>
      </li>
    </ul>
  </li>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: "MunicipalOptions",
  computed: {
    ...mapGetters('user', {
      allProvinces: 'provinces',
      user: 'getUser',
    }),
    current_province () {
      if (this.user && this.user.current_province_id && this.allProvinces && Array.isArray(this.allProvinces)) {
        return this.allProvinces.find(p => p.id === this.user.current_province_id)
      }
      return null
    },
    provinces () {
      if (this.current_province && this.allProvinces && Array.isArray(this.allProvinces) && this.allProvinces.length > 0) {
        return this.allProvinces.filter(p => p.id !== this.current_province.id)
      }
      return []
    }
  },
  methods: {
    switchCurrentProvince (province) {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/user/switch-current-province', {
        province_id: province.id
      }).then(() => {
        window.location.reload()
      }).catch((error) => {
        this.onResponseError(error)
      })
    }
  }
}
</script>

<style scoped>

</style>
