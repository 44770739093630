<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ title }}
      </h3>
      <div class="box-tools">
        <slot name="box-tools" />
      </div>
    </div>
    <div class="box-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "XBox",
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
