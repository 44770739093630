<template>
  <div class="row">
    <div class="col-md-12">
      <dashboard-card
        :level_province="true"
        sub-title="menu.provinceAuthority"
      />
    </div>
  </div>
</template>

<script>
import DashboardCard from "../../../components/DashboardCard"

export default {
  name: 'DashboardLevelMinistry',
  components: {
    DashboardCard
  },
  metaInfo() {
    return {
      title: this.$t('menu.provinceAuthority'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      config: process.env,
      business_builders: [],
      floor: [],
      investment_budget: [],
      project_by_type: [],
      investment: {
        investment_budget_local: 0,
        investment_budget_local_year: 0,
        investment_budget_local_month: 0,
        investment_budget_overseas: 0,
        investment_budget_overseas_year: 0,
        investment_budget_overseas_month: 0,
        investment_budget_total: 0,
        investment_budget_total_year: 0,
        investment_budget_total_month: 0,
      },
      open_construction: 0,
      open_construction_year: 0,
      open_construction_month: 0,
      stop_construction: 0,
      stop_construction_year: 0,
      stop_construction_month: 0,
    }
  },
  created() {
    this.getDataDetailInvestmentBudget()
    this.getDataDashboardSummary()
    this.getDataDetailOpenStopConstruction()
  },
  methods: {
    getDataDashboardSummary() {
      this.$axios.post(this.$base_api + '/api/backend/report/get-data-for-dashboard-summary', {
        is_province_data_level: true
      })
        .then((res) => {
          let data = res.data.data
          this.business_builders = data.business_builders
          this.floor = data.floor
          this.investment_budget = data.investment_budget
          this.project_by_type = data.project_by_type
        })
    },

    getDataDetailInvestmentBudget() {
      this.$axios.post(this.$base_api + '/api/backend/report/get-detail-investment-budget', {
        is_province_data_level: true
      })
        .then((res) => {
          let data = res.data.data
          this.investment.investment_budget_local = this.formatPrice(data['investment_budget_local'])
          this.investment.investment_budget_local_year = this.formatPrice(data['investment_budget_local_year'])
          this.investment.investment_budget_local_month = this.formatPrice(data['investment_budget_local_month'])
          this.investment.investment_budget_overseas = this.formatPrice(data['investment_budget_overseas'])
          this.investment.investment_budget_overseas_year = this.formatPrice(data['investment_budget_overseas_year'])
          this.investment.investment_budget_overseas_month = this.formatPrice(data['investment_budget_overseas_month'])
          this.investment.investment_budget_total = this.formatPrice(parseFloat(data['investment_budget']))
          this.investment.investment_budget_total_year = this.formatPrice(parseFloat(data['investment_budget_year']))
          this.investment.investment_budget_total_month = this.formatPrice(parseFloat(data['investment_budget_month']))
        })
    },

    getDataDetailOpenStopConstruction() {
      this.$axios.post(this.$base_api + '/api/backend/report/get-detail-open-stop-construction', {
        is_province_data_level: true
      })
        .then((res) => {
          let data = res.data.data
          this.open_construction = this.formatPrice(data['open_construction'])
          this.open_construction_year = this.formatPrice(data['open_construction_year'])
          this.open_construction_month = this.formatPrice(data['open_construction_month'])
          this.stop_construction = this.formatPrice(data['stop_construction'])
          this.stop_construction_year = this.formatPrice(data['stop_construction_year'])
          this.stop_construction_month = this.formatPrice(data['stop_construction_month'])
        })
    }
  }
}
</script>

<style scoped>
  .column-1 {
    width: 30%;
  }

  .column-other {
    width: 20%;
  }
</style>
