<template>
  <div
    v-if="architecture"
    class="row"
  >
    <div class="col-md-12">
      <div class="nav-tabs-custom">
        <ul
          class="nav nav-tabs"
          role="tablist"
        >
          <li
            role="presentation"
            class="active"
          >
            <a
              href="#certificateOfDesign"
              aria-controls="certificateOfDesign"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.companyCertificateOfLicense') }}
            </a>
          </li>
          <li role="presentation">
            <a
              href="#note"
              aria-controls="note"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.noteLetter') }}
            </a>
          </li>
          <li role="presentation">
            <a
              href="#deikarbanhchoun"
              aria-controls="deikarbanhchoun"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.deikarbanhchounLetter') }}
            </a>
          </li>
          <li role="presentation">
            <a
              href="#docs"
              aria-controls="docs"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.documentLetterTab') }}
            </a>
          </li>
          <li role="presentation">
            <a
              href="#activityLogs"
              aria-controls="activityLogs"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.activityLogs') }}
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div
            id="certificateOfDesign"
            role="tabpanel"
            class="tab-pane active"
          >
            <div class="row">
              <div class="col-md-12">
                <div class="pull-right">
                  <button
                    class="btn btn-default margin-bottom"
                    @click="openUrl('certificate-of-design')"
                  >
                    <i class="fa fa-print" /> {{ $t('label.print') }}
                  </button>
                </div>
              </div>
            </div>
            <smart-iframe :src="getUrl('certificate-of-design')" />
          </div>
          <div
            id="note"
            role="tabpanel"
            class="tab-pane"
          >
            <template
              v-if="architecture.notes &&
                Array.isArray(architecture.notes) &&
                architecture.notes.length"
            >
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <select
                          v-model="noteUuid"
                          class="form-control"
                        >
                          <option
                            v-for="(note, key) in architecture.notes"
                            :key="key"
                          >
                            {{ note.uuid }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      v-if="noteUuid"
                      class="col-md-offset-3 col-md-6"
                    >
                      <div class="pull-right">
                        <button
                          class="btn btn-default margin-bottom"
                          @click="openNote"
                        >
                          <i class="fa fa-print" /> {{ $t('label.print') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="noteUuid"
                  class="col-md-12"
                >
                  <smart-iframe :src="`${baseUrl}/roubavontbokkol/note/${noteUuid}`" />
                </div>
              </div>
            </template>
            <template v-else>
              <under-development
                :box="false"
                :description="$t('string.haveNoteLetter')"
                svg="/img/svgs/blank_canvas.svg"
              />
            </template>
          </div>
          <div
            id="deikarbanhchoun"
            role="tabpanel"
            class="tab-pane"
          >
            <template
              v-if="architecture.deikarbanhchouns &&
                Array.isArray(architecture.deikarbanhchouns) &&
                architecture.deikarbanhchouns.length"
            >
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <select
                          v-model="deikarbanhchounUuid"
                          class="form-control"
                        >
                          <option
                            v-for="(deikarbanhchoun, key) in architecture.deikarbanhchouns"
                            :key="key"
                          >
                            {{ deikarbanhchoun.uuid }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div
                      v-if="deikarbanhchounUuid"
                      class="col-md-offset-3 col-md-6"
                    >
                      <div class="pull-right">
                        <button
                          class="btn btn-default margin-bottom"
                          @click="openDeikarbanhchoun"
                        >
                          <i class="fa fa-print" /> {{ $t('label.print') }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="deikarbanhchounUuid"
                  class="col-md-12"
                >
                  <smart-iframe :src="`${baseUrl}/roubavontbokkol/deikarbanhchoun/${deikarbanhchounUuid}`" />
                </div>
              </div>
            </template>
            <template v-else>
              <under-development
                :box="false"
                :description="$t('string.haveNoDeikarbanhchoun')"
                svg="/img/svgs/blank_canvas.svg"
              />
            </template>
          </div>
          <div
            id="docs"
            role="tabpanel"
            class="tab-pane"
          >
            <upload-media
              model-name="architecture"
              :readonly="true"
              preview-column="col-md-4"
              :button-label="$t('label.chooseReference')"
              :model-uuid="$route.params.roubavontbokkols_uuid"
            />
          </div>
          <div
            id="activityLogs"
            role="tabpanel"
            class="tab-pane"
          >
            <activity-logs
              model_type="architecture"
              :model_uuid="$route.params.roubavontbokkols_uuid"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmartIframe from "@/components/SmartIframe"
import UploadMedia from "@/components/UploadMedia"
import ActivityLogs from "@/components/ActivityLogs"
import UnderDevelopment from "@/components/UnderDevelopment"

export default {
  name: "RoubavontbokkolsTabs",
  components: {
    UnderDevelopment,
    ActivityLogs,
    UploadMedia,
    SmartIframe,
  },
  props: {
    architecture: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      noteUuid: null,
      deikarbanhchounUuid: null,
    }
  },
  watch: {
    architecture () {
      if (this.architecture) {
        this.setNoteDeikarbanhchounValues()
      }
    }
  },
  mounted() {
    this.setNoteDeikarbanhchounValues()
  },
  methods: {
    getUrl(endpoint) {
      return `${this.baseUrl}/certificates/roubavontbokkol/${endpoint}/${this.$route.params.roubavontbokkols_uuid}`
    },
    openUrl(endpoint) {
      window.open(this.getUrl(endpoint), '_blank')
    },
    openNote() {
      window.open(`${this.baseUrl}/roubavontbokkol/note/${this.noteUuid}`, '_blank')
    },
    openDeikarbanhchoun() {
      window.open(`${this.baseUrl}/roubavontbokkol/deikarbanhchoun/${this.deikarbanhchounUuid}`, '_blank')
    },
    setNoteDeikarbanhchounValues() {
      if (
        this.architecture &&
          Array.isArray(this.architecture.notes) &&
          this.architecture.notes.length
      ) {
        const notes = this.architecture.notes
        this.noteUuid = notes[0]['uuid']
      }
      if (
        this.architecture &&
          Array.isArray(this.architecture.deikarbanhchouns) &&
          this.architecture.deikarbanhchouns.length
      ) {
        const deikarbanhchouns = this.architecture.deikarbanhchouns
        this.deikarbanhchounUuid = deikarbanhchouns[0]['uuid']
      }
    }
  }
}
</script>

<style scoped>

</style>
