import MissionType from "./../../views/settings/missionType/Index"
import List from "./../../views/settings/missionType/List"
import Create from "./../../views/settings/missionType/Create"
import Edit from "./../../views/settings/missionType/Edit"
import Show from "./../../views/settings/missionType/Show"
import Permissions from "../../permissions"

export const missionTypeRouters = {
  path: 'mission-type',
  component: MissionType,
  redirect: 'mission-type',
  meta: {
    permissions: [Permissions.backend.setting.missionType.manage],
    groups: 'mission-type',
  },
  children: [
    {
      path: '',
      name: 'list-mission-type',
      component: List,
      meta: {
        permissions: [Permissions.backend.setting.missionType.list],
        groups: 'mission-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Mission Type',
            name_km: 'ប្រភេទបេសកម្ម'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-mission-type',
      component: Create,
      meta: {
        permissions: [Permissions.backend.setting.missionType.store],
        groups: 'mission-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Mission Type',
            name_km: 'ប្រភេទបេសកម្ម'
          },
          {
            name_en: 'New',
            name_km: 'បង្កើតថ្មី'
          }
        ]
      }
    },
    {
      path: 'edit/:mission_type_uuid',
      name: 'edit-mission-type',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.setting.missionType.edit],
        groups: 'mission-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Mission Type',
            name_km: 'ប្រភេទបេសកម្ម'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'show/:mission_type_uuid',
      name: 'show-mission-type',
      component: Show,
      meta: {
        permissions: [Permissions.backend.setting.missionType.show],
        groups: 'mission-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Mission Type',
            name_km: 'ប្រភេទបេសកម្ម'
          },
          {
            name_en: 'View',
            name_km: 'មើល'
          }
        ]
      }
    }
  ]
}
