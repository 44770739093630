import Permissions from "../../permissions"
import Index from '../../views/investorConstruction/personality/Index'
import Edit from '../../views/investorConstruction/personality/Edit'
import Show from '../../views/investorConstruction/personality/Show'
import List from '../../views/investorConstruction/personality/List'
import Create from '../../views/investorConstruction/personality/Create'

export const personalityInvestorConstructionRouters = {
  path: 'personality',
  name: 'personality',
  component: Index,
  redirect: 'personality',
  meta: {
    permissions: [Permissions.backend.investorConstruction.personality.manage],
    groups: 'personality',
  },
  children: [
    {
      path: '',
      name: 'list-personality',
      component: List,
      meta: {
        permissions: [Permissions.backend.investorConstruction.personality.list],
        groups: 'personality',
        breadcrumb: [
          {
            name_en: 'Investor Construction',
            name_km: 'អ្នកវិនិយោគក្នុងវិស័យសំណង់'
          },
          {
            name_en: 'Personality',
            name_km: 'រូនវន្តបុគ្គល'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-personality',
      component: Create,
      meta: {
        permissions: [Permissions.backend.investorConstruction.personality.store],
        groups: 'personality',
        breadcrumb: [
          {
            name_en: 'Investor Construction',
            name_km: 'អ្នកវិនិយោគក្នុងវិស័យសំណង់'
          },
          {
            name_en: 'Personality',
            name_km: 'រូនវន្តបុគ្គល'
          },
          {
            name_en: 'Create',
            name_km: 'បង្កើតថ្មី'
          }
        ]
      }
    },
    {
      path: 'show/:people_uuid',
      name: 'show-personality',
      component: Show,
      meta: {
        permissions: [Permissions.backend.investorConstruction.personality.show],
        groups: 'personality',
        breadcrumb: [
          {
            name_en: 'Investor Construction',
            name_km: 'អ្នកវិនិយោគក្នុងវិស័យសំណង់'
          },
          {
            name_en: 'Personality',
            name_km: 'រូនវន្តបុគ្គល'
          },
          {
            name_en: 'Show',
            name_km: 'មើល'
          }
        ]
      }
    },
    {
      path: 'edit/:people_uuid',
      name: 'edit-personality',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.investorConstruction.personality.edit],
        groups: 'personality',
        breadcrumb: [
          {
            name_en: 'Investor Construction',
            name_km: 'អ្នកវិនិយោគក្នុងវិស័យសំណង់'
          },
          {
            name_en: 'Personality',
            name_km: 'រូនវន្តបុគ្គល'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
  ]
}
