<template>
  <div
    class="chip-user margin-right-5"
    :class="registrationStatus"
  >
    <div
      class="chip-user-avatar"
      :style="`background-image: url('${avatar}')`"
    />
    <div class="chip-user-name">
      {{ people['name_' + $i18n.locale] }}
    </div>
    <div class="popup-user-info">
      <div class="popup-user-box">
        <div class="popup-user-body">
          <div class="popup-user-avatar">
            <img
              :src="avatar"
              style="width: 100px;height: 100px;"
              alt="Avatar"
            >
          </div>
          <div class="popup-user-content">
            <div class="row">
              <div class="col-md-12">
                <p class="text-black text-bold">
                  <strong>{{ people['name_' + $i18n.locale] }}</strong>
                </p>
                <p class="text-muted">
                  <template v-if="people.id_card">
                    {{ people.id_card }}
                  </template>
                  <template v-else-if="people.passport_number">
                    {{ people.passport_number }}
                  </template>
                  <template v-else-if="people.passport">
                    {{ people.passport }}
                  </template>
                  <template v-else>
                    {{ $t('popup.idCard') }}: {{ $t('string.na') }}
                  </template>
                </p>
                <p class="text-muted">
                  <template v-if="people.phone">
                    {{ people.phone }}
                  </template>
                  <template v-else>
                    {{ $t('popup.phone') }}: {{ $t('string.na') }}
                  </template>
                </p>
                <p class="text-muted">
                  <template v-if="people.email">
                    {{ people.email }}
                  </template>
                  <template v-else>
                    {{ $t('popup.email') }}: {{ $t('string.na') }}
                  </template>
                </p>
              </div>
            </div>
          </div>
        </div>
        <template v-if="model!==''">
          <div class="popup-user-footer">
            <template v-if="model==='people'">
              <button
                class="btn btn-primary btn-xs pull-right"
                @click="onOpenNewTab('show-personality', 'people')"
              >
                <i class="fa fa-search" /> {{ $t('label.show') }}
              </button>
            </template>
            <template v-if="model==='architecture'">
              <button
                class="btn btn-primary btn-xs pull-right"
                @click="onOpenNewTab('show-professional-study-planner', 'architecture')"
              >
                <i class="fa fa-search" /> {{ $t('label.show') }}
              </button>
            </template>
            <template v-if="model==='officer'">
              <button
                class="btn btn-primary btn-xs pull-right"
                @click="onOpenNewTab('show-officer', 'officer')"
              >
                <i class="fa fa-search" /> {{ $t('label.show') }}
              </button>
            </template>
            <template v-if="model==='company'">
              <button
                class="btn btn-primary btn-xs pull-right"
                @click="onOpenNewTab('show-company', 'company')"
              >
                <i class="fa fa-search" /> {{ $t('label.show') }}
              </button>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PeopleChipItem",
  props: {
    people: {
      type: Object,
      required: true
    },
    model: {
      type: String,
      default: '',
    }
  },
  computed: {
    avatar() {
      if (this.people.avatar) {
        return `${this.baseUrl}/${this.people.avatar}`
      }
      return '/images/avatar/avatar.png'
    },
    registrationStatus() {
      let className = ''
      // eslint-disable-next-line no-prototype-builtins
      if (this.people.hasOwnProperty('is_registered')) {
        if (!this.people.is_registered) {
          className = 'bg-red'
        }
      }
      return className
    },
  },
  methods: {
    onOpenNewTab(routeName, param_name) {
      let routeData = null
      let uuid = this.people.uuid
      switch (param_name) {
      case 'company':
        routeData = this.$router.resolve({name: routeName, params: {company_uuid: uuid}})
        break
      case 'architecture':
        routeData = this.$router.resolve({name: routeName, params: {architecture_uuid: uuid}})
        break
      case 'officer':
        routeData = this.$router.resolve({name: routeName, params: {officer_uuid: uuid}})
        break
      case 'people':
        routeData = this.$router.resolve({name: routeName, params: {people_uuid: uuid}})
        break
      default:
        this.onResponseError("Access link is not available!")
        return
      }
      window.open(routeData.href, '_blank')
    }
  }
}
</script>

<style scoped lang="sass">
.bg-red .chip-user-name
  color: #ffffff !important

.chip-user
  position: relative
  text-align: left !important

  &:hover .popup-user-info
    visibility: visible

  .popup-user-info
    visibility: hidden
    position: absolute
    background: #ffffff
    min-width: 300px
    height: auto
    border: 1px #ddd solid
    z-index: 9999
    box-shadow: 0 0 8px -2px #cccccc
    transform: translateY(90px)
    border-radius: 4px

    .popup-user-body
      padding: 6px
      display: flex

      .popup-user-content
        padding-top: 4px
        padding-left: 6px

        p
          line-height: 1
          text-wrap: avoid
          font-size: 14px

    .popup-user-footer
      border-top: 1px #cccccc solid
      background: #f2f2f2
      padding: 6px
      display: flex
      justify-content: flex-end
</style>
