import Note from '@/views/roubavontbokkols/notes/Index'
import List from '@/views/roubavontbokkols/notes/List'
import Create from '@/views/roubavontbokkols/notes/Create'
import Show from '@/views/roubavontbokkols/notes/Show'
import Edit from '@/views/roubavontbokkols/notes/Edit'
import Permissions from "@/permissions"

const note = Permissions.backend.roubavontbokkol.note

const getMeta = (permissions, link) => {
  return {
    permissions,
    groups: 'note-roubavontbokkols',
    breadcrumb: [
      {
        name_en: 'Note',
        name_km: 'លិខិតកំណត់បង្ហាញ'
      },
      link
    ]
  }
}

export const roubavontbokkolNoteRouters = {
  path: 'note',
  component: Note,
  redirect: 'note',
  meta: {
    permissions: [note.manage],
    groups: 'note-roubavontbokkols',
  },
  children: [
    {
      path: '',
      name: 'list-note-roubavontbokkols',
      component: List,
      meta: getMeta([note.list], {
        name_en: 'List',
        name_km: 'បញ្ជី'
      })
    },
    {
      path: 'create',
      name: 'create-note-roubavontbokkols',
      component: Create,
      meta: getMeta([note.store], {
        name_en: 'Create',
        name_km: 'បង្កើត'
      })
    },
    {
      path: 'edit/:roubavontbokkols_uuid',
      name: 'edit-note-roubavontbokkols',
      component: Edit,
      meta: getMeta([note.edit], {
        name_en: 'Edit',
        name_km: 'កែសម្រួល'
      })
    },
    {
      path: 'show/:roubavontbokkols_uuid',
      name: 'show-note-roubavontbokkols',
      component: Show,
      meta: getMeta([note.show], {
        name_en: 'Show',
        name_km: 'មើល'
      })
    }
  ]
}
