import News from "./../views/news/Index"
import List from "./../views/news/List"
import Create from "./../views/news/Create"
import Edit from "./../views/news/Edit"
import Show from "./../views/news/Show"
import Permissions from "../permissions"

export const newsRouters = {
  path: 'news',
  component: News,
  redirect: 'news',
  meta: {
    permissions: [Permissions.backend.news.manage],
    groups: 'news',
  },
  children: [
    {
      path: '',
      name: 'list-news',
      component: List,
      meta: {
        permissions: [Permissions.backend.news.list],
        groups: 'news',
        breadcrumb: [
          {
            name_en: 'News',
            name_km: 'ព័ត៌មានប្រចាំថ្ងៃក្រសួង'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-news',
      component: Create,
      meta: {
        permissions: [Permissions.backend.news.store],
        groups: 'news',
        breadcrumb: [
          {
            name_en: 'News',
            name_km: 'ព័ត៌មានប្រចាំថ្ងៃក្រសួង'
          },
          {
            name_en: 'Create',
            name_km: 'បន្ថែមថ្មី'
          }
        ]
      }
    },
    {
      path: 'edit/:news_id',
      name: 'edit-news',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.news.edit],
        groups: 'news',
        breadcrumb: [
          {
            name_en: 'News',
            name_km: 'ព័ត៌មានប្រចាំថ្ងៃក្រសួង'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'show/:news_id',
      name: 'show-news',
      component: Show,
      meta: {
        permissions: [Permissions.backend.news.show],
        groups: 'news',
        breadcrumb: [
          {
            name_en: 'News',
            name_km: 'ព័ត៌មានប្រចាំថ្ងៃក្រសួង'
          },
          {
            name_en: 'Show',
            name_km: 'មើល'
          }
        ]
      }
    }
  ]
}
