export const state = () => {
  return {
    fetchDocuments: false
  }
}

export const getters = {
  fetchDocuments: state => state.fetchDocuments
}

export const mutations = {
  onTabDocument(state) {
    if (!state.fetchDocuments) {
      state.fetchDocuments = true
    }
  },

  resetFetchDocument(state) {
    state.fetchDocuments = false
  }
}
export const actions = {
  onTabDocument({commit}) {
    commit('onTabDocument')
  },

  resetFetchDocument({commit}) {
    commit('resetFetchDocument')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
