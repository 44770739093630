<template>
  <architecture-data-table
    :architecture-category-id="[2]"
    :architecture-type-id="[1]"
    :permission-create="Permissions.backend.hasBoard.architecture.store"
    :permission-filter="Permissions.backend.hasBoard.architecture.filter"
    :title="$t('string.hasBoardArchitecture')"
    model-route-create="create-has-board-architecture"
    model-route-edit="edit-has-board-architecture"
    model-route-view="show-has-board-architecture"
  />
</template>

<script>
import ArchitectureDataTable from "../../architecture/Table"
import Permissions from "@/permissions"
export default {
  name: "List",
  components: {ArchitectureDataTable},
  metaInfo() {
    return {
      title: this.$t('string.hasBoardArchitecture'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      Permissions
    }
  }
}
</script>

<style scoped>

</style>
