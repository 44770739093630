<template>
  <div class="form-horizontal">
    <div
      :class="`${nameKmRequired.required ? 'has-error' : ''}`"
      class="form-group row"
    >
      <label class="col-sm-3 control-label required">{{ $t('label.nameKm') }}</label>
      <div class="col-sm-9">
        <input
          v-model="projectSubType.name_km"
          :placeholder="$t('label.nameKm')"
          class="form-control"
          type="text"
        >
        <template v-if="nameKmRequired.required">
          <span class="label-error">{{ nameKmRequired.message }}</span>
        </template>
      </div>
    </div>

    <div
      :class="`${nameEnRequired.required ? 'has-error' : ''}`"
      class="form-group row"
    >
      <label class="col-sm-3 control-label required">{{ $t('label.nameEn') }}</label>
      <div class="col-sm-9">
        <input
          v-model="projectSubType.name_en"
          :placeholder="$t('label.nameEn')"
          class="form-control"
          type="text"
        >
        <template v-if="nameEnRequired.required">
          <span class="label-error">{{ nameEnRequired.message }}</span>
        </template>
      </div>
    </div>

    <div
      :class="`${projectTypeValidation.required ? 'has-error' : ''}`"
      class="form-group"
    >
      <label class="col-sm-3 control-label required">{{ $t('label.projectType') }}</label>
      <div class="col-sm-9">
        <select
          v-if="projectTypes.length>0"
          v-model="projectSubType.project_type_id"
          class="form-control"
        >
          <option
            disabled
            selected
            value="0"
          >
            {{ $t('label.projectType') }}
          </option>
          <option
            v-for="(projectType, key) in projectTypes"
            :key="key"
            :value="projectType.id"
          >
            {{ projectType['name_' + $i18n.locale] }}
          </option>
        </select>
        <template v-if="projectTypeValidation.required">
          <span class="label-error">{{ projectTypeValidation.message }}</span>
        </template>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-9 col-sm-offset-3">
        <button
          class="btn btn-primary btn-sm"
          @click="store"
        >
          {{ $t('button.save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {validationMixins} from "@/mixins/validations"

export default {
  name: "ProjectSubTypeForm",
  mixins: [validationMixins],
  data() {
    return {
      projectSubType: {
        project_type_id: 1,
        name_en: '',
        name_km: '',
      },
      validations: null,
      projectTypes: [],
    }
  },
  computed: {
    action() {
      let baseUrl = process.env.VUE_APP_API
      if (this.$route.name === 'create-project-sub-type') {
        return `${baseUrl}/api/backend/project-sub-type/create`
      }
      return `${baseUrl}/api/backend/project-sub-type/update`
    }
  },
  mounted() {
    const promise = new Promise((resolve => {
      this.getProjectTypes()
      return resolve({})
    }))
    promise.then(() => {
      if (this.$route.params.project_sub_type_uuid) {
        this.getProjectSubType()
      }
    })
  },
  methods: {
    store() {
      this.$isLoading(true)
      this.$axios.post(this.action, this.projectSubType)
        .then(() => {
          this.showToastr()
          this.$router.push({name: 'list-project-sub-type'})
        })
        .catch((error) => {
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
          } else {
            this.validations = null
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getProjectTypes() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-type/get-option')
        .then((res) => {
          if (res.data.data) {
            this.projectTypes = res.data.data
          } else {
            this.projectTypes = []
          }
        }).catch((error) => {
          this.onResponseError(error)
        })
    },
    getProjectSubType() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-sub-type/show', {
        project_sub_type_uuid: this.$route.params.project_sub_type_uuid,
      }).then(({data}) => {
        this.projectSubType = data.data
        this.$set(this.projectSubType, 'project_sub_type_uuid', data.data.uuid)
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-project-sub-type'})
      }).finally(() => {
        this.$isLoading(false)
      })
    },
  }
}
</script>

<style scoped>

</style>
