<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.closeConstructionLetterNote') }}
      </h3>
      <div class="box-tools">
        <button-back-to-list />
      </div>
    </div>
    <div class="box-body">
      <div class="row">
        <div class="col-sm-10">
          <construction-letter-note-form construction-letter-type="close" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConstructionLetterNoteForm from "../includes/ConstructionLetterNoteForm"
import ButtonBackToList from "../includes/ButtonBackToList"

export default {
  name: "Note",
  metaInfo() {
    return {
      title: this.$t('string.closeConstructionLetterNote'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  components: {ButtonBackToList, ConstructionLetterNoteForm}
}
</script>

<style scoped>

</style>
