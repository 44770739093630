export default {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    Vue.mixin({
      methods: {
        getNumbers(number) {
          let lang = this.$i18n.locale
          switch (lang) {
          case 'km':
            return this.convert_number_to_km(number)
          case 'en':
            return this.convert_number_to_en(number)
          default:
            return '[Unsupported Number]'
          }
        },
        convert_number_to_km(number) {
          try {
            let map = {
              '0': '០',
              '1': '១',
              '2': '២',
              '3': '៣',
              '4': '៤',
              '5': '៥',
              '6': '៦',
              '7': '៧',
              '8': '៨',
              '9': '៩',
            }
            let array_numbers = number.split("")
            if (Array.isArray(array_numbers) && array_numbers.length > 0) {
              return array_numbers.map((value) => {
                // eslint-disable-next-line no-prototype-builtins
                if (map.hasOwnProperty(value)) {
                  return map[value]
                } else {
                  return value
                }
              }).join("")
            }
            return number
          } catch (e) {
            return number
          }
        },
        convert_number_to_en(number) {
          try {
            let map = {
              '០': '0',
              '១': '1',
              '២': '2',
              '៣': '3',
              '៤': '4',
              '៥': '5',
              '៦': '6',
              '៧': '7',
              '៨': '8',
              '៩': '9',
            }
            let array_numbers = number.split("")
            if (Array.isArray(array_numbers) && array_numbers.length > 0) {
              return array_numbers.map((value) => {
                // eslint-disable-next-line no-prototype-builtins
                if (map.hasOwnProperty(value)) {
                  return map[value]
                } else {
                  return value
                }
              }).join("")
            }
            return number
          } catch (e) {
            return number
          }
        },

        date_format_km_en(date, locale,) {
          if (date) {
            if (locale === 'km') {
              return this.$moment(date).locale('km').format('ថ្ងៃទីDD ខែMMMM ឆ្នាំYYYY')
            } else {
              return this.$moment(date).locale('en').format('DD MMMM YYYY')
            }
          }
          return null
        }
      }
    })
  }
}
