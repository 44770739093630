<template>
  <detail-letter
    :type-letter="$t('string.closeConstructionLetterLabel')"
  />
</template>

<script>
import DetailLetter from "../includes/DetailLetter"

export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('string.showCloseConstructionLetter'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  components: {DetailLetter}
}
</script>

<style scoped>

</style>
