import Vue from 'vue'
import {getField, updateField} from "vuex-map-fields"

const state = {
  data: null,
  administrators: [
    process.env.VUE_APP_ROLE_ADMIN,
    'admin'
  ],
  provinces: [],
  province_id: null
}


const getters = {
  getField,
  getUser: (state) => {
    return state.data
  },
  isAdmin: (state) => {
    if (state.data) {
      return state.data.roles.some(role => state.administrators.includes(role))
    } else {
      return false
    }
  },
  provinces: state => state.provinces,
  current_province_id: (state) => {
    if (state.data && state.data.current_province_id) {
      return state.data.current_province_id
    }
    return null
  }
}

const mutations = {
  updateField,
  setUser(state, data) {
    if (!state.data) {
      Vue.set(state, 'data', {})
    }
    for (const attribute of Object.keys(data.user)) {
      if (!['roles', 'permissions'].includes(attribute)) {
        Vue.set(state.data, attribute, data.user[attribute])
      }
    }
  },
  setRoles(state, roles) {
    if (state.data) {
      state.data.roles = roles
    } else {
      console.log("User data not yet defined")
    }
  },
  setPermissions(state, permissions) {
    if (state.data) {
      state.data.permissions = permissions
    } else {
      console.log("User data not yet defined")
    }
  },

  clearUser (state) {
    state.data = null
  },

  setProvinces (state, provinces) {
    state.provinces = provinces
  }
}

const actions = {
  setUser({commit}, data) {
    commit('setUser', data)
  },
  setRoles({commit}, data) {
    commit('setRoles', data)
  },
  setPermissions({commit}, data) {
    commit('setPermissions', data)
  },
  clearUser({commit}) {
    commit('clearUser')
  },
  setProvinces({commit}, provinces) {
    commit('setProvinces', provinces)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
