<template>
  <div class="row">
    <div class="col-md-12">
      <table class="table table-striped table-bordered table-condensed">
        <thead>
          <tr>
            <th>{{ $t('string.user') }}</th>
            <th>{{ $t('string.logName') }}</th>
            <th>{{ $t('string.description') }}</th>
            <th>{{ $t('string.createdAt') }}</th>
            <th>{{ $t('table.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="logs && Array.isArray(logs) && logs.length>0">
            <tr
              v-for="(log, key) in logs"
              :key="key"
            >
              <td>
                <template v-if="log.user">
                  {{ log.user.first_name }} {{ log.user.last_name }}
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
              <td>
                <span v-html="getLabel(log)" />
              </td>
              <td>{{ log.description || $t('string.na') }}</td>
              <td>{{ getDate(log.created_at, false) }}</td>
              <td>
                <button
                  class="btn btn-info btn-xs"
                  @click="onViewDetail(log)"
                >
                  <i class="fas fa-search" />
                </button>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td
                colspan="4"
                class="text-muted text-center"
              >
                {{ $t('string.noResultFound') }}
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <ActivityLogsDetailModal />
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields"
import ActivityLogsDetailModal from "@/components/Modal/ActivityLogsDetailModal"

export default {
  name: "ActivityLogs",
  components: {ActivityLogsDetailModal},
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    model_uuid: {
      type: String,
      required: true,
    },
    // eslint-disable-next-line vue/prop-name-casing
    model_type: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      logs: [],
    }
  },
  computed: {
    ...mapFields('activityLogs', {
      logItem: 'log'
    })
  },
  mounted() {
    this.getProjectActivityLogs()
  },
  methods: {
    getLabel(log) {
      const {log_name} = log
      if (log_name) {
        let className = 'info'
        if (log_name.startsWith('update')) {
          className = 'primary'
        } else if (log_name.startsWith('sync')) {
          className = 'info'
        } else if (log_name.includes('published')) {
          className = 'success'
        } else if (log_name.includes('unpublished')) {
          className = 'warning'
        } else if (log_name.includes('remove') || log_name.includes('delete')) {
          className = 'danger'
        }
        return `<span class="label label-${className}">${log_name}</span>`
      }
      return this.$t('string.na')
    },
    getProjectActivityLogs() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/activity-log/get-activity-logs', {
        model_uuid: this.model_uuid,
        model_type: this.model_type,
      }).then(({data}) => {
        if (data.data && data.data.length > 0) {
          this.logs = data.data
        }
      })
    },
    onViewDetail (log) {
      this.logItem = log
      $('#activityLogsModal').modal('show')
    }
  }
}
</script>

<style scoped>

</style>
