<template>
  <div class="form-horizontal">
    <div class="form-group row">
      <label class="control-label col-sm-3">{{ $t('label.province') }}</label>
      <div class="col-sm-9">
        <select
          v-model="province_id"
          class="form-control"
          @change="getDistrict(province_id)"
        >
          <option
            v-for="(province, key) in provinces"
            :key="key"
            :value="province.id"
          >
            {{ province['name_' + $i18n.locale] }}
          </option>
        </select>
      </div>
    </div>
    <div
      :class="districtValidation.required?'has-error':null"
      class="form-group row"
    >
      <label class="control-label col-sm-3 required">{{ $t('label.district') }}</label>
      <div class="col-sm-9">
        <select
          v-model="commune.district_id"
          class="form-control"
        >
          <option
            v-for="(district, key) in districts"
            :key="key"
            :value="district.id"
          >
            {{ district['name_' + $i18n.locale] }}
          </option>
        </select>
        <template v-if="districtValidation.required">
          <span class="label-error">{{ districtValidation.message }}</span>
        </template>
      </div>
    </div>
    <div
      :class="nameKmRequired.required?'has-error':null"
      class="form-group row"
    >
      <label class="control-label col-sm-3 required">{{ $t('string.nameCommuneInKm') }}</label>
      <div class="col-sm-9">
        <input
          v-model="commune.name_km"
          :placeholder="$t('string.nameCommuneInKm')"
          class="form-control"
          type="text"
        >
        <template v-if="nameKmRequired.required">
          <span class="label-error">{{ nameKmRequired.message }}</span>
        </template>
      </div>
    </div>
    <div
      :class="nameEnRequired.required?'has-error':null"
      class="form-group row"
    >
      <label class="control-label col-sm-3 required">{{ $t('string.nameCommuneInEn') }}</label>
      <div class="col-sm-9">
        <input
          v-model="commune.name_en"
          :placeholder="$t('string.nameCommuneInEn')"
          class="form-control"
          type="text"
        >
        <template v-if="nameEnRequired.required">
          <span class="label-error">{{ nameEnRequired.message }}</span>
        </template>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-9 col-sm-offset-3">
        <button
          class="btn btn-primary btn-sm margin-r-5"
          @click="store"
        >
          {{ $t('button.save') }}
        </button>
        <asora-back-button />
      </div>
    </div>
  </div>
</template>

<script>
import {validationMixins} from "@/mixins/validations"

export default {
  name: "CommuneForm",
  mixins: [validationMixins],
  data() {
    return {
      validations: null,
      commune: {},
      provinces: [],
      districts: [],
      province_id: null,
    }
  },
  computed: {
    urlAction() {
      if (this.$route.name === 'create-commune') {
        return `${process.env.VUE_APP_API}/api/backend/commune/create`
      }
      return `${process.env.VUE_APP_API}/api/backend/commune/update`
    },
  },
  mounted() {
    this.getProvinces()
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty('commune_id')) {
      this.getCommune()
    }
  },
  methods: {
    store() {
      this.$isLoading(true)
      this.$axios.post(this.urlAction, this.commune)
        .then(() => {
          this.showToastr()
          this.$router.back()
        })
        .catch((error) => {
          this.validations = null
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getCommune() {
      this.$axios.post(`${process.env.VUE_APP_API}/api/backend/commune/show`, {
        id: this.$route.params.commune_id,
      }).then(({data}) => {
        this.commune = data.data
        if (this.commune.district && this.commune.district.province) {
          this.province_id = this.commune.district.province.id
          this.getDistrict(this.province_id)
        }
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    async getProvinces() {
      this.$isLoading(true)
      let response = await this.$axios.post(`${process.env.VUE_APP_API}/api/backend/commune/get-provinces`)
      this.provinces = await response.data.data
      this.province_id = this.provinces[0]['id']
      this.getDistrict(this.province_id)
    },
    getDistrict(province_id) {
      this.$axios.post(`${process.env.VUE_APP_API}/api/backend/commune/get-districts`, {
        province_id: province_id,
      }).then(({data}) => {
        this.districts = data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      }).finally(() => {
        this.$isLoading(false)
      })
    },
  }
}
</script>

<style scoped>

</style>
