<template>
  <div class="row">
    <div :class="divContainerClass">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('string.company') }}
          </h3>
          <div class="box-tools">
            <button
              v-if="can([Permissions.backend.investorConstruction.company.store])"
              class="btn btn-primary btn-sm margin-right-5"
              @click="$router.push({name: 'create-company'})"
            >
              <i class="fa fa-plus" /> {{ $t('button.newCompany') }}
            </button>
            <template v-if="can([Permissions.backend.investorConstruction.company.filter])">
              <button
                class="btn btn-danger btn-sm"
                @click="toggleFilter"
              >
                <i class="fa fa-filter" /> {{ $t('string.filter') }}
              </button>
            </template>
          </div>
        </div>
        <div class="box-body">
          <div>
            <table
              id="company-table"
              class="table table-hover table-striped table-bordered"
            >
              <thead>
                <tr>
                  <th class="width-avatar">
                    {{ $t('table.logo') }}
                  </th>
                  <th>{{ $t('table.registrationNumberMinistryOfCommerce') }}</th>
                  <th>{{ $t('table.year') }}</th>
                  <th>{{ $t('table.name') }}</th>
                  <th>{{ $t('table.companyOwner') }}</th>
                  <th>{{ $t('table.email') }}</th>
                  <th>{{ $t('table.phone') }}</th>
                  <th>{{ $t('table.status') }}</th>
                  <th class="width-created-at">
                    {{ $t('table.createdAt') }}
                  </th>
                  <th style="width: 120px !important;">
                    {{ $t('table.action') }}
                  </th>
                </tr>
              </thead>
              <tbody />
            </table>
          </div>
        </div>
      </div>
    </div>
    <template v-if="can([Permissions.backend.investorConstruction.company.filter]) && showFilter">
      <div class="col-md-2 pl-md-0">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('string.filter') }}
            </h3>
          </div>
          <div class="box-body">
            <div class="form-group">
              <label>{{ $t('label.companyType') }}</label>
              <select
                v-model="obj.company_type_id"
                class="form-control"
                name="team-category"
              >
                <option
                  :value="null"
                  selected
                >
                  {{ $t('string.all') }}
                </option>
                <template v-for="(item, key) in company_types">
                  <option
                    :key="key"
                    :value="item.id"
                  >
                    {{ item['name_' + $i18n.locale] }}
                  </option>
                </template>
              </select>
            </div>
            <div class="form-group">
              <label>{{ $t('label.country') }}</label>
              <input
                v-model="obj.country"
                :placeholder="$t('label.country')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('label.companyName') }}</label>
              <input
                v-model="obj.company_name"
                :placeholder="$t('label.companyName')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.companyOwner') }}</label>
              <input
                v-model="obj.company_owner"
                :placeholder="$t('table.companyOwner')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.ceoList') }}</label>
              <input
                v-model="obj.company_ceo"
                :placeholder="$t('table.ceoList')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.ctoList') }}</label>
              <input
                v-model="obj.company_cto"
                :placeholder="$t('table.ctoList')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.registrationNumberMinistryOfCommerce') }}</label>
              <input
                v-model="obj.company_registration_number"
                :placeholder="$t('table.registrationNumberMinistryOfCommerce')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.email') }}</label>
              <input
                v-model="obj.company_email"
                :placeholder="$t('table.email')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.phone') }}</label>
              <input
                v-model="obj.company_phone"
                :placeholder="$t('table.phone')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <div class="row">
                <div class="form-group col-md-6">
                  <button
                    class="btn btn-primary btn-block btn-sm"
                    @click="search"
                  >
                    <i class="fa fa-search" />
                    {{ $t('button.search') }}
                  </button>
                </div>
                <div class="form-group col-md-6 pl-lg-0">
                  <button
                    class="btn btn-default btn-block btn-sm"
                    @click="reset"
                  >
                    <i class="fas fa-redo-alt" />
                    {{ $t('button.reset') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Permissions from "@/permissions"
export default {
  name: "List",
  metaInfo() {
    return {
      title: this.$t('string.company'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      Permissions,
      oTable: null,
      showFilter: false,
      company_types: [],
      obj: {
        company_type_id: null,
        country: null,
        company_name: null,
        company_owner: null,
        company_ceo: null,
        company_cto: null,
        company_registration_number: null,
        company_email: null,
        company_phone: null,
      }
    }
  },
  computed: {
    divContainerClass() {
      return this.showFilter ? 'col-md-10' : 'col-md-12'
    }
  },
  watch: {
    '$i18n.locale' () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.refreshTable()
      this.oTable.draw(true)
    }
  },
  mounted() {
    this.oTable = this.refreshTable()
    this.enabledAction()
  },
  beforeDestroy() {
    this.oTable.clear()
    this.oTable.destroy()
  },
  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter
      this.oTable.columns.adjust().draw()
    },
    search() {
      this.oTable.draw(true)
    },
    reset() {
      this.obj = {
        company_type_id: null,
        country: null,
        company_name: null,
        company_owner: null,
        company_ceo: null,
        company_cto: null,
        company_registration_number: null,
        company_email: null,
        company_phone: null,
      }
      this.oTable.draw(true)
    },
    refreshTable() {
      let self = this
      return $('#company-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: process.env.VUE_APP_API + '/api/backend/company/datatable',
          data: (d) => {
            d.edit = self.$t('label.edit')
            d.show = self.$t('label.show')
            d.delete = self.$t('label.delete')
            d.company_category_ids = [1]
            d.company_type_id = self.obj.company_type_id
            d.country = self.obj.country
            d.company_name = self.obj.company_name
            d.company_owner = self.obj.company_owner
            d.company_ceo = self.obj.company_ceo
            d.company_cto = self.obj.company_cto
            d.company_registration_number = self.obj.company_registration_number
            d.company_email = self.obj.company_email
            d.company_phone = self.obj.company_phone
          },
          error: (xhr) => {
            self.onResponseError(xhr)
          }
        },
        columns: [
          {
            data: 'logo',
            name: 'logo',
            title: self.$t('table.logo'),
            render: (data, type, row) => {
              if (row.logo) {
                return `<img src="${process.env.VUE_APP_API}/${data}" alt="Logo" class="datatable-image-size"/>`
              }
              return `<img src="/img/company.png" alt="Logo" class="datatable-image-size"/>`
            },
            searchable: false,
            orderable: false,
          },
          {
            data: 'first_registration_number',
            name: 'first_registration_number',
            title: self.$t('table.registrationNumberMinistryOfCommerce'),
            render: (data) => {
              if (data) {
                return data
              } else {
                return self.$t('string.na')
              }
            },
          },
          {
            data: 'year',
            name: 'year',
            title: self.$t('table.year'),
            render: (data) => {
              if (data) {
                return data
              } else {
                return self.$t('string.na')
              }
            }
          },
          {
            data: 'name_km',
            name: 'name_km',
            title: self.$t('table.name'),
            orderable: false,
            render: (data, type, row) => {
              if (data) {
                return row['name_' + this.$i18n.locale]
              } else {
                return self.$t('string.na')
              }
            }
          },
          {
            data: 'owner_list',
            name: 'owner_list',
            title: self.$t('table.companyOwner'),
          },
          {
            data: 'email',
            name: 'email',
            title: self.$t('table.email'),
            render: (email) => {
              if (email === null) {
                return self.$t('string.na')
              }
              return email
            }
          },
          {
            data: 'phone',
            name: 'phone',
            title: self.$t('table.phone'),
            render: (phone) => {
              if (phone === null) {
                return self.$t('string.na')
              }
              return phone
            }
          },
          {
            data: 'is_published',
            name: 'is_published',
            title: self.$t('table.is_published'),
            render: (data) => {
              if (data !== "1") {
                return `<span class="label label-warning"><i class="fa fa-times"></i></span>`
              }
              return `<span class="label label-primary"><i class="fa fa-check-circle"></i></span>`
            }
          },
          {
            data: 'created_at',
            name: 'created_at',
            orderable: true,
            searchable: false,
            title: self.$t('table.createdAt'),
            render: (data) => {
              return this.getDate(data)
            }
          },
          {
            data: 'action',
            name: 'action',
            orderable: false,
            searchable: false,
            title: self.$t('table.action')
          }
        ],
        language: {
          url: self.getLocale,
        },
        order: [
          [8, 'desc']
        ],
        drawCallback () {
          $('[data-toggle="tooltip"]').tooltip()
        }
      })
    },
    enabledAction() {
      let self = this
      this.clearEventHandler([
        '.btn-edit',
        '.btn-show',
        '.btn-trash',
        '.btn-toggle',
        '.btn-change-category',
        '.sidebar-toggle',
      ])
      $(document).on('click', '.sidebar-toggle', function () {
        self.oTable.columns.adjust().draw()
      })
      $(document).on('click', '.btn-change-category', function () {
        self.$router.push({
          name: 'change-category-company',
          params: {
            company_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-edit', function () {
        self.$router.push({
          name: 'edit-company',
          params: {
            company_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-show', function () {
        self.$router.push({
          name: 'show-company',
          params: {
            company_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-trash', function () {
        self.deleteCompany($(this).attr('data-uuid'))
      })
      $(document).on('click', '.btn-toggle', function () {
        self.toggleCompany($(this).attr('data-uuid'))
      })
    },
    deleteCompany(uuid) {
      let self = this
      this.$swal.fire({
        title: self.i18nSwalTitle,
        text: self.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: self.i18nSwalNo,
        confirmButtonText: self.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/company/delete', {
            company_uuid: uuid
          }).then(() => {
            this.showSwalSuccess()
            this.oTable.draw(true)
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
    toggleCompany(uuid) {
      this.$swal.fire({
        title: this.$t('string.publishingArchitecture'),
        text: this.$t('string.doYouWantToToggleArchitectureOnMobileApp?'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('string.no'),
        confirmButtonText: this.$t('string.yes')
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/company/toggle-published', {
            company_uuid: uuid
          }).then(() => {
            this.showToastr()
            this.oTable.draw(true)
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
