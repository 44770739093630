export const companyValidationMixins = {
  computed: {
    nameKmValidations() {
      return this.getValidationColumn(this.validations, 'name_km')
    },
    nameEnValidations() {
      return this.getValidationColumn(this.validations, 'name_en')
    },
    ownersValidations() {
      return this.getValidationColumn(this.validations, 'owners')
    },
    companyTypeValidations() {
      return this.getValidationColumn(this.validations, 'company_type_id')
    },
    companySectionValidations() {
      return this.getValidationColumn(this.validations, 'company_section_id')
    },
    countryValidations() {
      return this.getValidationColumn(this.validations, 'country_id')
    },
    companyCategoryValidations() {
      return this.getValidationColumn(this.validations, 'categories')
    },
    issuedNumberValidations() {
      return this.getValidationColumn(this.validations, 'issued_number')
    },
    issuedDateValidations() {
      return this.getValidationColumn(this.validations, 'issued_date')
    },
    approvedByValidations() {
      return this.getValidationColumn(this.validations, 'approved_by')
    },
    approvedAtValidations() {
      return this.getValidationColumn(this.validations, 'approved_at')
    },
  }
}
