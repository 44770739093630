<template>
  <div class="row">
    <div :class="divContainerClass">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('string.occupancy') }}
          </h3>
          <div class="box-tools">
            <button
              v-if="can([$Permissions.backend.certificate.occupancy.store])"
              class="btn btn-primary btn-sm margin-right-5"
              @click="$router.push({name: 'store-certificate-occupancy'})"
            >
              <i class="fa fa-plus" /> {{ $t('button.add') }}
            </button>
            <template v-if="can([$Permissions.backend.certificate.occupancy.filter])">
              <button
                class="btn btn-danger btn-sm"
                @click="toggleFilter"
              >
                <i class="fa fa-filter" /> {{ $t('string.filter') }}
              </button>
            </template>
          </div>
        </div>
        <div class="box-body">
          <div>
            <table
              id="occupancy-table"
              class="table table-hover table-full-width table-striped table-bordered"
            >
              <thead>
                <tr>
                  <th>{{ $t('table.constructionOnTheLandParcel') }}</th>
                  <th>{{ $t('table.buildingPermitNumber') }}</th>
                  <th>{{ $t('table.certificateNumber') }}</th>
                  <th>{{ $t('table.constructionSiteOpeningPermitNumber') }}</th>
                  <th>{{ $t('table.constructionOwner') }}</th>
                  <th>{{ $t('table.asBuildDrawingNumber') }}</th>
                  <th>{{ $t('table.asBuildDrawingDate') }}</th>
                  <th>{{ $t('table.constructionOccupancy') }}</th>
                  <th>{{ $t('table.totalFloorArea') }}</th>
                  <th style="width: 140px;">
                    {{ $t('table.action') }}
                  </th>
                </tr>
              </thead>
              <tbody />
            </table>
          </div>
        </div>
      </div>
    </div>
    <template v-if="can([$Permissions.backend.certificate.occupancy.filter]) && showFilter">
      <div class="col-md-2 pl-md-0">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('string.filter') }}
            </h3>
            <div class="box-tools">
              <button
                class="btn btn-primary btn-sm margin-r-5"
                @click="search"
              >
                <i class="fa fa-search" />
                {{ $t('button.search') }}
              </button>
              <button
                class="btn btn-default btn-sm"
                @click="reset"
              >
                <i class="fas fa-redo-alt" />
              </button>
            </div>
          </div>
          <div class="box-body">
            <!--លេខវិញ្ញាបនបត្រ-->
            <div class="form-group">
              <label class="text-bold text-mlmupc-primary">{{ $t('label.occupancy_number') }}</label>
              <input
                v-model="occupancy_number"
                type="text"
                :placeholder="$t('label.certificate_number')"
                class="form-control"
              >
            </div>
            <!--កាលបរិច្ឆេទវិញ្ញាបនបត្រ-->
            <div class="form-group">
              <label class="text-bold text-mlmupc-primary">{{ $t('label.occupancy_date') }}</label>
              <date-picker
                v-model="occupancy_date"
                type="date"
                :placeholder="$t('label.occupancy_date')"
                format="DD/MM/YYYY"
                class="table-full-width"
              />
            </div>
            <!--លេខក្បាលដី-->
            <div class="form-group">
              <label class="text-bold text-mlmupc-primary">{{ $t('label.land_number') }}</label>
              <input
                v-model="land_number"
                type="text"
                :placeholder="$t('label.land_number')"
                class="form-control"
              >
            </div>
            <!--លេខលិខិតអនុញ្ញាត-->
            <div class="form-group">
              <label class="text-bold text-mlmupc-primary">{{ $t('table.permitNumber') }}</label>
              <input
                v-model="permit_number"
                type="text"
                :placeholder="$t('table.permitNumber')"
                class="form-control"
              >
            </div>
            <!--ឆ្នាំលិខិតអនុញ្ញាត-->
            <div class="form-group">
              <label class="text-bold text-mlmupc-primary">{{ $t('label.permitYear') }}</label>
              <select
                v-model="permit_number_year"
                class="form-control"
              >
                <option :value="null">
                  {{ $t('string.all') }}
                </option>
                <option
                  v-for="(year, key) in years"
                  :key="key"
                  :value="year"
                >
                  {{ year }}
                </option>
              </select>
            </div>
            <!--ម្ចាស់សំណង់-->
            <div class="form-group">
              <label>{{ $t('table.owners') }}</label>
              <input
                v-model="owner_name"
                type="text"
                :placeholder="$t('label.owner_name')"
                class="form-control"
              >
            </div>
            <!--លេខលិខិតបើកការដ្ឋាន-->
            <div class="form-group">
              <label>{{ $t('label.open_construction_number') }}</label>
              <input
                v-model="open_construction_number"
                type="text"
                :placeholder="$t('table.permitNumber')"
                class="form-control"
              >
            </div>
            <!--កាលបរិច្ឆេទលិខិតបើកការដ្ឋាន-->
            <div class="form-group">
              <label>{{ $t('label.open_construction_date') }}</label>
              <date-picker
                v-model="open_construction_date"
                type="date"
                :placeholder="$t('label.open_construction_date')"
                format="DD/MM/YYYY"
                class="table-full-width"
              />
            </div>
            <!--មុខងារប្រើប្រាស់សំណង់-->
            <div class="form-group">
              <label>{{ $t('label.construction_occupancy') }}</label>
              <input
                v-model="construction_occupancy"
                type="text"
                :placeholder="$t('label.construction_occupancy')"
                class="form-control"
              >
            </div>
            <!--លេខលិខិតអនុលោមភាព-->
            <div class="form-group">
              <label>{{ $t('label.compliance_number') }}</label>
              <input
                v-model="compliance_number"
                type="text"
                :placeholder="$t('label.compliance_number')"
                class="form-control"
              >
            </div>
            <!--កាលបរិច្ឆេទលិខិតអនុលោមភាព-->
            <div class="form-group">
              <label>{{ $t('label.compliance_date') }}</label>
              <date-picker
                v-model="compliance_date"
                type="date"
                :placeholder="$t('label.compliance_date')"
                format="DD/MM/YYYY"
                class="table-full-width"
              />
            </div>
            <!--បញ្ជាក់អនុលោមភាពដោយ-->
            <div class="form-group">
              <label>{{ $t('label.issued_by') }}</label>
              <input
                v-model="compliance_by"
                type="text"
                :placeholder="$t('label.issued_by')"
                class="form-control"
              >
            </div>
            <!--ការអនុម័តលើវិញ្ញាបនប័ត្រ-->
            <div class="form-group">
              <label>{{ $t('label.approval') }}</label>
              <select
                v-model="approved"
                name="project_types"
                class="form-control"
              >
                <option
                  :value="null"
                  selected
                >
                  {{ $t('string.all') }}
                </option>
                <template v-for="(item, key) in optionApproveds">
                  <option
                    :key="key"
                    :value="item.value"
                  >
                    {{ item['label_' + $i18n.locale] }}
                  </option>
                </template>
              </select>
            </div>
            <!--សិក្សាប្លង់ស្ថាបត្យកម្មដោយ-->
            <div class="form-group">
              <label>{{ $t('label.architecture_design_by') }}</label>
              <input
                v-model="archi_design_by"
                type="text"
                :placeholder="$t('label.architecture_design_by')"
                class="form-control"
              >
            </div>
            <!--ត្រួតពិនិត្យប្លង់ស្ថាបត្យកម្មដោយ-->
            <div class="form-group">
              <label>{{ $t('label.architecture_design_reviewed_by') }}</label>
              <input
                v-model="archi_design_reviewed_by"
                type="text"
                :placeholder="$t('label.architecture_design_reviewed_by')"
                class="form-control"
              >
            </div>
            <!--សិក្សាដោយ-->
            <div class="form-group">
              <label>{{ $t('label.structural_design_by') }}</label>
              <input
                v-model="structural_design_by"
                type="text"
                :placeholder="$t('label.structural_design_by')"
                class="form-control"
              >
            </div>

            <!--ត្រួតពិនិត្យគ្រឿងផ្គុំសំណង់ដោយ-->
            <div class="form-group">
              <label>{{ $t('label.structural_design_reviewed_by') }}</label>
              <input
                v-model="structural_design_reviewed_by"
                type="text"
                :placeholder="$t('label.structural_design_reviewed_by')"
                class="form-control"
              >
            </div>
            <!--ប្លង់គ្រឿងផ្គុំសំណង់-->
            <div class="form-group">
              <label>{{ $t('label.structural_design') }}</label>
              <input
                v-model="structural_design"
                type="text"
                :placeholder="$t('label.structural_design')"
                class="form-control"
              >
            </div>

            <div class="form-group">
              <div class="row">
                <div class="form-group col-md-6">
                  <button
                    class="btn btn-primary btn-block btn-sm"
                    @click="search"
                  >
                    <i class="fa fa-search" />
                    {{ $t('button.search') }}
                  </button>
                </div>
                <div class="form-group col-md-6 pl-lg-0">
                  <button
                    class="btn btn-default btn-block btn-sm"
                    @click="reset"
                  >
                    <i class="fas fa-redo-alt" />
                    {{ $t('button.reset') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'List',
  metaInfo() {
    return {
      title: this.$t('string.occupancy'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      optionApproveds: [
        {label_en: 'Approved', label_km: 'បានអនុម័ត', value: 'approved'},
        {label_en: 'Not Approve', label_km: 'មិនទាន់បានអនុម័ត', value: 'not_approve'},
      ],
      years: [],
      approved: null,
      oTable: null,
      showFilter: false,
      permit_number_year: null,
      occupancy_number: null,
      occupancy_date: null,
      ctd: null,
      land_number: null,
      permit_number: null,
      owner_name: null,
      construction_occupancy: null,
      open_construction_number: null,
      open_construction_date: null,
      ocd: null,
      compliance_by: null,
      compliance_number: null,
      compliance_date: null,
      ccd: null,
      archi_design_by: null,
      archi_design_reviewed_by: null,
      structural_design_by: null,
      structural_design_reviewed_by: null,
      structural_design: null,
    }
  },
  computed: {
    divContainerClass() {
      return this.showFilter ? 'col-md-10' : 'col-md-12'
    },

    api() {
      return `${this.baseUrl}/api/backend/certificate-occupancy`
    }
  },

  watch: {
    '$i18n.locale': function () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.refreshTable()
      this.oTable.draw(true)
    }
  },
  mounted() {
    this.oTable = this.refreshTable()
    this.enabledAction()
    this.years = this.generateYears()
  },
  beforeDestroy() {
    this.oTable.clear()
    this.oTable.destroy()
  },
  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter
      this.oTable.columns.adjust().draw()
    },

    search() {
      if (this.occupancy_date) {
        this.ctd = this.$moment(this.certificate_date).locale('en').format('YYYY-MM-DD')
      } else {
        this.ctd = null
      }

      if (this.open_construction_date) {
        this.ocd = this.$moment(this.open_construction_date).locale('en').format('YYYY-MM-DD')
      } else {
        this.ocd = null
      }

      if (this.compliance_date) {
        this.ccd = this.$moment(this.compliance_date).locale('en').format('YYYY-MM-DD')
      } else {
        this.ccd = null
      }

      this.oTable.draw(true)
    },
    reset() {
      this.occupancy_number = null
      this.occupancy_date = null
      this.ctd = null
      this.permit_number_year = null
      this.permit_number = null
      this.land_number = null
      this.owner_name = null
      this.construction_occupancy = null
      this.open_construction_number = null
      this.open_construction_date = null
      this.ocd = null
      this.compliance_by = null
      this.compliance_number = null
      this.compliance_date = null
      this.ccd = null
      this.archi_design_by = null
      this.archi_design_reviewed_by = null
      this.structural_design_by = null
      this.structural_design_reviewed_by = null
      this.structural_design = null
      this.approved = null
      this.oTable.draw(true)
    },

    refreshTable() {
      let self = this
      return $('#occupancy-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: process.env.VUE_APP_API + '/api/backend/certificate-occupancy/datatable',
          data: (d) => {
            d.occupancy_number = this.occupancy_number
            d.occupancy_date = this.ctd
            d.permit_number = this.permit_number
            d.permit_number_year = this.permit_number_year
            d.land_number = this.land_number
            d.owner_name = this.owner_name
            d.construction_occupancy = this.construction_occupancy
            d.open_construction_number = this.open_construction_number
            d.open_construction_date = this.ocd
            d.compliance_by = this.compliance_by
            d.compliance_number = this.compliance_number
            d.compliance_date = this.ccd
            d.archi_design_by = this.archi_design_by
            d.archi_design_reviewed_by = this.archi_design_reviewed_by
            d.structural_design_by = this.structural_design_by
            d.structural_design_reviewed_by = this.structural_design_reviewed_by
            d.structural_design = this.structural_design
            d.approved = this.approved
          },
          error: (xhr) => {
            self.onResponseError(xhr)
          }
        },
        columns: [
          {
            data: 'construction_on_the_land_parcel',
            name: 'construction_on_the_land_parcel',
            title: self.$t('table.constructionOnTheLandParcel'),
            render: (data) => {
              return data || this.$t('string.na')
            }
          },
          {
            data: 'building_permit_number',
            name: 'building_permit_number',
            title: self.$t('table.buildingPermitNumber'),
            render: (data) => {
              return data || this.$t('string.na')
            }
          },
          {
            data: 'building_permit_date',
            name: 'building_permit_date',
            title: self.$t('table.constructionSiteOpeningPermitNumber'),
            render: (data) => {
              return this.getDate(data, false, 'DD/MM/YYYY') || this.$t('string.na')
            }
          },
          {
            data: 'issued_number_en',
            name: 'issued_number_en',
            title: self.$t('table.certificateNumber'),
            render: (data) => {
              return data || this.$t('string.na')
            }
          },
          {
            data: 'construction_owner_km',
            name: 'construction_owner_km',
            title: self.$t('table.constructionOwner'),
            render: (data) => {
              return data || this.$t('string.na')
            }
          },
          {
            data: 'as_build_drawing_number',
            name: 'as_build_drawing_number',
            title: self.$t('table.asBuildDrawingNumber'),
            render: (data) => {
              return data || this.$t('string.na')
            }
          },
          {
            data: 'as_build_drawing_date',
            name: 'as_build_drawing_date',
            title: self.$t('table.asBuildDrawingDate'),
            render: (data) => {
              return this.getDate(data, false, 'DD/MM/YYYY') || this.$t('string.na')
            }
          },
          {
            data: 'construction_occupancy_km',
            name: 'construction_occupancy_km',
            title: self.$t('table.constructionOccupancy'),
            render: (data) => {
              return data || this.$t('string.na')
            }
          },
          {
            data: 'total_floor_area',
            name: 'total_floor_area',
            title: self.$t('table.totalFloorArea'),
            render: (data) => {
              return data || this.$t('string.na')
            }
          },
          {
            data: 'action',
            name: 'action',
            orderable: false,
            searchable: false,
            width: '140px',
            title: self.$t('table.action'),
            render: (data, type, row) => {
              let action = ''
              if (this.can([this.$Permissions.backend.certificate.occupancy.show])) {
                action += `<button class="btn btn-info btn-xs btn-show" data-uuid="${row.uuid}" data-toggle="tooltip" data-placement="top" title="${self.$t('label.show')}"><i class="fa fa-search"></i></button> `
              }
              if (this.can([this.$Permissions.backend.certificate.occupancy.edit])) {
                action += `<button class="btn btn-primary btn-xs btn-edit" data-uuid="${row.uuid}" data-toggle="tooltip" data-placement="top" title="${self.$t('label.edit')}"><i class="fa fa-edit"></i></button> `
              }
              if (this.can([this.$Permissions.backend.certificate.occupancy.approve])) {
                if (!row['is_approved']) {
                  action += `<button class="btn btn-success btn-xs btn-approved" data-uuid="${row.uuid}" data-toggle="tooltip" data-placement="top" title="${self.$t('label.approve')}"><i class="fa fa-check-circle"></i></button> `
                }
              }
              if (this.can([this.$Permissions.backend.certificate.occupancy.print])) {
                action += `<button class="btn btn-default btn-xs btn-print" data-uuid="${row.uuid}" data-toggle="tooltip" data-placement="top" title="${self.$t('label.print')}"><i class="fa fa-print"></i></button> `
              }
              if (this.can([this.$Permissions.backend.certificate.occupancy.delete])) {
                if (!row['is_approved']) {
                  action += `<button class="btn btn-danger btn-xs btn-delete" data-uuid="${row.uuid}" data-toggle="tooltip" data-placement="top" title="${self.$t('label.delete')}"><i class="fa fa-trash"></i></button>`
                }
              }
              return action
            }
          }
        ],
        language: {
          url: self.getLocale,
        },
        order: [
          [1, 'desc']
        ],
        drawCallback () {
          $('[data-toggle="tooltip"]').tooltip()
        }
      })
    },
    enabledAction() {
      let self = this
      this.clearEventHandler([
        '.btn-edit',
        '.btn-show',
        '.btn-print',
        '.btn-approved',
        '.btn-delete',
        '.sidebar-toggle'
      ])
      $(document).on('click', '.sidebar-toggle', function () {
        self.oTable.columns.adjust().draw()
      })

      $(document).on('click', '.btn-edit', function () {
        self.$router.push({
          name: 'edit-certificate-occupancy',
          params: {
            occupancy_uuid: $(this).attr('data-uuid')
          }
        })
      })

      $(document).on('click', '.btn-show', function () {
        self.$router.push({
          name: 'show-certificate-occupancy',
          params: {
            occupancy_uuid: $(this).attr('data-uuid')
          }
        })
      })

      $(document).on('click', '.btn-approved', function () {
        self.$router.push({
          name: 'approved-certificate-occupancy',
          params: {
            occupancy_uuid: $(this).attr('data-uuid')
          }
        })
      })

      $(document).on('click', '.btn-print', function (e) {
        e.preventDefault()
        let occupancy_uuid = $(this).attr('data-uuid')
        window.open(`${self.baseUrl}/certificates/occupancy/print/${occupancy_uuid}`, '_blank')
      })

      $(document).on('click', '.btn-delete', function () {
        self.deleteCertificate($(this).attr('data-uuid'))
      })
    },
    deleteCertificate(uuid) {
      this.$swal({
        title: this.i18nSwalTitle,
        text: this.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.i18nSwalNo,
        confirmButtonText: this.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(`${this.api}/delete`, {
            occupancy_uuid: uuid,
          })
            .then(() => {
              this.showSwalSuccess()
              this.oTable.draw(true)
            }).catch(error => {
              this.onResponseError(error)
            })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
