<template>
  <div class="row">
    <div :class="divContainerClass">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('label.lawRegulation') }}
          </h3>
          <div class="box-tools">
            <template v-if="$Permissions.backend.lawRegulation.store">
              <button
                v-if="can($Permissions.backend.lawRegulation.store)"
                class="btn btn-primary btn-sm margin-r-5"
                @click="$router.push({name: 'create-law-regulation'})"
              >
                <i class="fa fa-plus" /> {{ $t('string.add') }}
              </button>
            </template>
            <template v-if="can($Permissions.backend.lawRegulation.filter)">
              <button
                class="btn btn-danger btn-sm"
                @click="toggleFilter"
              >
                <i class="fa fa-filter" /> {{ $t('string.filter') }}
              </button>
            </template>
          </div>
        </div>
        <div class="box-body">
          <div>
            <table
              id="law-regulation-table"
              class="table table-hover table-full-width table-striped table-bordered"
            >
              <thead>
                <tr>
                  <th>{{ $t('label.lawRegulationTitleKm') }}</th>
                  <th>{{ $t('label.lawRegulationTitleEn') }}</th>
                  <th>{{ $t('table.issuedBy') }}</th>
                  <th>{{ $t('table.issuedNumber') }}</th>
                  <th>{{ $t('table.effectiveDate') }}</th>
                  <th>{{ $t('table.published') }}</th>
                  <th>{{ $t('table.type') }}</th>
                  <th>{{ $t('table.createdAt') }}</th>
                  <th>{{ $t('table.action') }}</th>
                </tr>
              </thead>
              <tbody />
            </table>
          </div>
        </div>
      </div>
    </div>
    <template v-if="showFilter">
      <div class="col-md-2 pl-md-0">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('string.filter') }}
            </h3>
          </div>
          <div class="box-body">
            <div class="form-group">
              <label>{{ $t('table.title') }}</label>
              <input
                v-model="title"
                :placeholder="$t('table.title')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.issuedNumber') }}</label>
              <input
                v-model="issued_number"
                :placeholder="$t('table.issuedNumber')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.type') }}</label>
              <select
                v-model="type"
                class="form-control"
                name="project_types"
              >
                <option
                  :value="null"
                  disabled
                  selected
                >
                  {{ $t('label.chooselawRegulationType') }}
                </option>
                <template v-for="(item, key) in law_regulation_types">
                  <option
                    :key="key"
                    :value="item.id"
                  >
                    {{ item['name_' + $i18n.locale] }}
                  </option>
                </template>
              </select>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="form-group col-md-6">
                  <button
                    class="btn btn-primary btn-block btn-sm"
                    @click="search"
                  >
                    <i class="fa fa-search" />
                    {{ $t('button.search') }}
                  </button>
                </div>
                <div class="form-group col-md-6 pl-lg-0">
                  <button
                    class="btn btn-default btn-block btn-sm"
                    @click="reset"
                  >
                    <i class="fas fa-redo-alt" />
                    {{ $t('button.reset') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'List',
  metaInfo() {
    return {
      title: this.$t('menu.lawRegulation'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      law_regulation_types: [],
      issued_number: null,
      type: null,
      title: null,
      showFilter: false,
      oTable: null
    }
  },
  computed: {
    divContainerClass() {
      return this.showFilter ? 'col-md-10' : 'col-md-12'
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.refreshTable()
      this.oTable.draw(true)
    }
  },
  mounted() {
    this.oTable = this.refreshTable()
    this.enabledAction()
    this.getLawRegulationType()
  },
  beforeDestroy() {
    this.oTable.clear()
    this.oTable.destroy()
  },
  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter
      this.oTable.columns.adjust().draw()
    },
    search() {
      this.oTable.draw(true)
    },
    reset() {
      this.issued_number = null
      this.type = null
      this.title = null
      this.oTable.draw(true)
    },
    getLawRegulationType() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/law-regulation-type/get-option')
        .then((res) => {
          this.law_regulation_types = res.data.data
        })
        .catch((error) => {
          this.onResponseError(error)
        })
    },

    refreshTable() {
      let self = this
      return $('#law-regulation-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: process.env.VUE_APP_API + '/api/backend/law-regulation/datatable',
          data: (d) => {
            d.edit = self.$t('label.edit')
            d.show = self.$t('label.show')
            d.delete = self.$t('label.delete')
            d.title = self.title
            d.type = self.type
            d.issued_number = self.issued_number
          },
          error: (xhr) => {
            self.onResponseError(xhr)
          }
        },
        columns: [
          {
            data: 'title_km',
            name: 'title_km',
            title: self.$t('label.lawRegulationTitleKm'),
            orderable: false,
          },
          {
            data: 'title_en',
            name: 'title_en',
            title: self.$t('label.lawRegulationTitleEn'),
            orderable: false,
          },
          {
            data: 'issued_by',
            name: 'issued_by',
            title: self.$t('table.issuedBy'),
            width: '90px',
            render: (data) => {
              if (data !== null) {
                return data
              }
              return self.$t('string.na')
            }
          },
          {
            data: 'issued_number',
            name: 'issued_number',
            title: self.$t('table.issuedNumber'),
            render: (data) => {
              if (data !== null) {
                return data
              }
              return self.$t('string.na')
            }
          },
          {
            data: 'effective_date',
            name: 'effective_date',
            title: self.$t('table.effectiveDate'),
            width: '130px',
            render: (data) => {
              if (data !== null) {
                return data
              }
              return self.$t('string.na')
            }
          },
          {
            data: 'is_published',
            name: 'is_published',
            title: self.$t('table.published'),
            render: (data) => {
              if (data !== "1") {
                return `<span class="label label-warning"><i class="fa fa-times"></i></span>`
              }
              return `<span class="label label-primary"><i class="fa fa-check-circle"></i></span>`
            }
          },
          {
            data: 'type.name_en',
            name: 'type.name_en',
            title: self.$t('table.type'),
            render: (data, type, row) => {
              if (row['type'] !== null) {
                return row['type']['name_' + this.$i18n.locale]
              }
              return this.$t('string.na')
            }
          },
          {
            data: 'created_at',
            name: 'created_at',
            orderable: true,
            searchable: false,
            width: '120px',
            title: self.$t('table.createdAt'),
            render: (data) => {
              return this.getDate(data)
            }
          },
          {
            data: 'action',
            name: 'action',
            orderable: false,
            width: '100px',
            searchable: false,
            title: self.$t('table.action')
          }
        ],
        language: {
          url: self.getLocale,
        },
        order: [
          [2, 'desc']
        ]
      })
    },
    enabledAction() {
      let self = this
      this.clearEventHandler([
        '.btn-edit',
        '.btn-show',
        '.btn-trash',
        '.btn-toggle',
        '.sidebar-toggle',
      ])
      $(document).on('click', '.sidebar-toggle', function () {
        self.oTable.columns.adjust().draw()
      })
      $(document).on('click', '.btn-edit', function () {
        self.$router.push({
          name: 'edit-law-regulation',
          params: {
            law_regulation_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-show', function () {
        self.$router.push({
          name: 'show-law-regulation',
          params: {
            law_regulation_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-trash', function () {
        self.deleteLawRegulation($(this).attr('data-uuid'))
      })
      $(document).on('click', '.btn-toggle', function () {
        self.toggleLawRegulation($(this).attr('data-uuid'))
      })
    },
    deleteLawRegulation(uuid) {
      let self = this
      this.$swal.fire({
        title: self.i18nSwalTitle,
        text: self.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: self.i18nSwalNo,
        confirmButtonText: self.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/law-regulation/delete', {
            law_regulation_uuid: uuid
          }).then(() => {
            this.showSwalSuccess()
            this.oTable.draw(true)
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
    toggleLawRegulation(uuid) {
      this.$swal({
        title: this.$t('string.publishingLaw'),
        text: this.$t('string.doYouWantToToggleLawOnMobileApp?'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('string.no'),
        confirmButtonText: this.$t('string.yes')
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/law-regulation/toggle', {
            law_regulation_uuid: uuid
          }).then(() => {
            this.showToastr()
            this.oTable.draw(true)
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
