import Permissions from "../../permissions"
import Index from '../../views/noneBoard/architecture/Index'
import Edit from '../../views/noneBoard/architecture/Edit'
import Show from '../../views/noneBoard/architecture/Show'
import List from '../../views/noneBoard/architecture/List'
import Create from '../../views/noneBoard/architecture/Create'

export const noneBoardArchitectureRouters = {
  path: 'architecture',
  name: 'none-board-architecture',
  component: Index,
  redirect: 'architecture',
  meta: {
    permissions: [Permissions.backend.noneBoard.architecture.manage],
    groups: 'none-board-architecture',
  },
  children: [
    {
      path: '',
      name: 'list-none-board-architecture',
      component: List,
      meta: {
        permissions: [Permissions.backend.noneBoard.architecture.list],
        groups: 'none-board-architecture',
        breadcrumb: [
          {
            name_en: 'None Board of the professional',
            name_km: 'អ្នកវិជ្ជាជីវៈមិនទាន់មានគណៈ'
          },
          {
            name_en: 'None Board Architecture',
            name_km: 'គណៈស្ថាបត្យករកម្ពុជា'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-none-board-architecture',
      component: Create,
      meta: {
        permissions: [Permissions.backend.noneBoard.architecture.store],
        groups: 'none-board-architecture',
        breadcrumb: [
          {
            name_en: 'None Board of the professional',
            name_km: 'អ្នកវិជ្ជាជីវៈមិនទាន់មានគណៈ'
          },
          {
            name_en: 'None Board Architecture',
            name_km: 'គណៈស្ថាបត្យករកម្ពុជា'
          },
          {
            name_en: 'Create',
            name_km: 'បន្ថែមថ្មី'
          }
        ]
      }
    },
    {
      path: 'show/:architecture_uuid',
      name: 'show-none-board-architecture',
      component: Show,
      meta: {
        permissions: [Permissions.backend.noneBoard.architecture.show],
        groups: 'none-board-architecture',
        breadcrumb: [
          {
            name_en: 'None Board of the professional',
            name_km: 'អ្នកវិជ្ជាជីវៈមិនទាន់មានគណៈ'
          },
          {
            name_en: 'None Board Architecture',
            name_km: 'គណៈស្ថាបត្យករកម្ពុជា'
          },
          {
            name_en: 'Show',
            name_km: 'មើល'
          }
        ]
      }
    },
    {
      path: 'edit/:architecture_uuid',
      name: 'edit-none-board-architecture',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.noneBoard.architecture.edit],
        groups: 'none-board-architecture',
        breadcrumb: [
          {
            name_en: 'None Board of the professional',
            name_km: 'អ្នកវិជ្ជាជីវៈមិនទាន់មានគណៈ'
          },
          {
            name_en: 'None Board Architecture',
            name_km: 'គណៈស្ថាបត្យករកម្ពុជា'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
  ]
}
