import Permissions from "../../permissions"
import Index from "../../views/inspection/Index"
import {inspectionDraftRouters} from "./inspectiondDraft"
import {inspectionSubmitRouters} from "./inspectionSubmit"

export const inspectionRouters = {
  path: 'inspection',
  name: 'inspection',
  component: Index,
  meta: {
    permissions: [Permissions.backend.inspection.manage],
    groups: 'inspection',
  },
  children: [
    inspectionDraftRouters,
    inspectionSubmitRouters
  ]
}

