<template>
  <div
    v-if="note"
    class="row"
  >
    <div class="col-md-12">
      <div class="nav-tabs-custom">
        <ul
          class="nav nav-tabs"
          role="tablist"
        >
          <li
            role="presentation"
            class="active"
          >
            <a
              href="#noteLetter"
              aria-controls="noteLetter"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.noteLetter') }}
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div
            id="noteLetter"
            role="tabpanel"
            class="tab-pane active"
          >
            <div class="row">
              <div class="col-md-12">
                <div class="pull-right">
                  <button
                    class="btn btn-default margin-bottom"
                    @click="openUrl"
                  >
                    <i class="fa fa-print" /> {{ $t('label.print') }}
                  </button>
                </div>
              </div>
            </div>
            <smart-iframe :src="`${baseUrl}/roubavontbokkol/note/${note.uuid}`" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmartIframe from "@/components/SmartIframe"

export default {
  name: "RoubavontbokkolsShowNoteTabs",
  components: {
    SmartIframe,
  },
  props: {
    note: {
      type: Object,
      required: true,
    }
  },
  methods: {
    openUrl() {
      window.open(`${this.baseUrl}/roubavontbokkol/note/${this.note.uuid}`, '_blank')
    }
  }
}
</script>

<style scoped>

</style>
