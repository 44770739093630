<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.showConstructionLetterCommand') }}
      </h3>
      <div class="box-tools">
        <button
          v-if="can([$Permissions.backend.setting.constructionLetterCommand.edit])"
          class="btn btn-primary btn-sm margin-right-5"
          @click="$router.push({
            name: 'edit-construction-letter-command',
            params: {
              construction_letter_command_uuid: $route.params.construction_letter_command_uuid,
            }
          })"
        >
          <i class="fa fa-edit" /> {{ $t('button.edit') }}
        </button>
        <asora-back-button />
      </div>
    </div>
    <div
      v-if="construction_letter_command!==null"
      class="box-body"
    >
      <div class="form-horizontal">
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('string.command') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ construction_letter_command.value }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('string.type') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ getConstructionLetterCommandType }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.status') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ construction_letter_command.enable ? $t('label.enable') : $t('label.disable') }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.createdAt') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ getDate(construction_letter_command.created_at, false) }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.updatedAt') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ getDate(construction_letter_command.updated_at, false) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('string.showConstructionLetterCommand'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      construction_letter_command: null,
    }
  },
  computed: {
    getConstructionLetterCommandType() {
      if (this.construction_letter_command === null) {
        return this.$t('string.na')
      } else {
        switch (this.construction_letter_command.type) {
        case 'open_construction':
          return this.$t('string.openConstruction')
        case 'close_construction':
          return this.$t('string.closeConstruction')
        case 'pending_construction':
          return this.$t('string.pendingConstruction')
        default:
          return this.$t('string.na')
        }
      }
    },
  },
  mounted() {
    this.getConstructionLetterCommand()
  },
  methods: {
    getConstructionLetterCommand() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/construction-letter-command/show', {
        construction_letter_command_uuid: this.$route.params.construction_letter_command_uuid,
      }).then((res) => {
        this.construction_letter_command = res.data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-construction-letter-command'})
      }).finally(() => {
        this.$isLoading(false)
      })
    },
  }
}
</script>

<style scoped>
.control-label {
  padding-top: 0 !important;
}
</style>
