<template>
  <table
    id="activity-table"
    class="table table-hover table-full-width table-striped table-bordered"
  >
    <thead>
      <tr>
        <th>{{ $t('table.createdAt') }}</th>
        <th>{{ $t('table.duration') }}</th>
        <th>{{ $t('table.logName') }}</th>
        <th>{{ $t('table.description') }}</th>
        <th>{{ $t('table.user') }}</th>
        <th>{{ $t('table.action') }}</th>
      </tr>
    </thead>
    <tbody />
  </table>
</template>

<script>
export default {
  name: "LogActivityTable",
  data() {
    return {
      logActivityTable: null,
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.logActivityTable.clear()
      this.logActivityTable.destroy()
      this.logActivityTable = this.refreshTable()
      this.logActivityTable.draw(true)
    }
  },
  mounted() {
    this.logActivityTable = this.refreshTable()
    this.enabledAction()
  },
  beforeDestroy() {
    this.logActivityTable.clear().destroy()
  },
  methods: {
    reRenderColumn() {
      this.logActivityTable.draw()
      this.logActivityTable.columns.adjust()
    },
    refreshTable() {
      let self = this
      return $('#activity-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: process.env.VUE_APP_API + '/api/backend/activity-log/datatable',
          data: (d) => {
            d.edit = this.$t('label.edit')
            d.show = this.$t('label.show')
            d.delete = this.$t('label.delete')
          },
          error: (xhr) => {
            self.onResponseError(xhr)
          }
        },
        columns: [
          {
            data: 'created_at',
            name: 'created_at',
            title: 'Creation Date',
            render: (data) => {
              return this.getDate(data)
            },
          },
          {
            data: 'created_at',
            name: 'created_at',
            title: 'Creation Date',
            render: (data) => {
              if (this.$moment(data).isValid()) {
                return this.$moment(data).locale('en').fromNow()
              }
              return this.$t('string.na')
            },
          },
          {
            data: 'log_name',
            name: 'log_name',
            title: 'Log Name',
            render: (data) => {
              let label = data
              let className = 'primary'
              if (label === 'updated') className = 'info'
              if (label === 'deleted') className = 'danger'
              return `<label class="label label-${className}">${label}</label>`
            }
          },
          {
            data: 'description',
            name: 'description',
            title: 'Description',
          },
          {
            data: 'user_name',
            name: 'user_name',
            title: 'User',
          },
          {
            data: 'action',
            name: 'action',
            orderable: false,
            searchable: false,
            title: 'Action',
            render: (data, type, row) => {
              let action = ''
              if (this.can(this.$Permissions.backend.setting.activityLogs.show)) {
                action += `<button class="btn btn-primary btn-xs btn-show" data-id="${row.id}"><i class="fa fa-search"></i></button>`
              }
              if (action === '') {
                action = this.$t('string.noAccess')
              }
              return action
            },
          }
        ],
        language: {
          url: self.getLocale,
        },
        order: [
          [0, 'desc']
        ]
      })
    },
    enabledAction() {
      let self = this
      self.clearEventHandler([
        '.btn-show'
      ])
      $(document).on('click', '.btn-show', function () {
        self.$router.push({
          name: 'show-activity',
          params: {
            id: $(this).attr('data-id')
          }
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
