import Commune from "./../../views/settings/commune/Index"
import List from "./../../views/settings/commune/List"
import Create from "../../views/settings/commune/Create"
import Edit from "../../views/settings/commune/Edit"
import Show from "../../views/settings/commune/Show"
import Permissions from "../../permissions"

export const communeRouters = {
  path: 'commune',
  component: Commune,
  redirect: 'commune',
  meta: {
    groups: 'commune',
    permissions: [Permissions.backend.setting.commune.manage]
  },
  children: [
    {
      path: '',
      name: 'list-commune',
      component: List,
      meta: {
        groups: 'commune',
        permissions: [Permissions.backend.setting.commune.list],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Commune',
            name_km: 'ឃុំ/សង្កាត់'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-commune',
      component: Create,
      meta: {
        groups: 'district',
        permissions: [Permissions.backend.setting.commune.store],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'District',
            name_km: 'ខេត្ត/រាជធានី'
          },
          {
            name_en: 'Create',
            name_km: 'បង្កើត'
          }
        ]
      }
    },
    {
      path: 'edit/:commune_id',
      name: 'edit-commune',
      component: Edit,
      meta: {
        groups: 'district',
        permissions: [Permissions.backend.setting.commune.edit],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'District',
            name_km: 'ស្រុក/ខណ្ឌ'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'show/:commune_id',
      name: 'show-commune',
      component: Show,
      meta: {
        groups: 'district',
        permissions: [Permissions.backend.setting.commune.show],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'District',
            name_km: 'ស្រុក/ខណ្ឌ'
          },
          {
            name_en: 'Show',
            name_km: 'មើល'
          }
        ]
      }
    },
  ]
}
