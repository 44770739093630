import CompanySection from "./../../views/settings/companySection/Index"
import List from "./../../views/settings/companySection/List"
import Create from "./../../views/settings/companySection/Create"
import Edit from "./../../views/settings/companySection/Edit"
import Show from "./../../views/settings/companySection/Show"
import Permissions from "../../permissions"

export const companySectionRouters = {
  path: 'company-section',
  component: CompanySection,
  redirect: 'company-section',
  meta: {
    permissions: [Permissions.backend.setting.companySection.manage],
    groups: 'company-section',
  },
  children: [
    {
      path: '',
      name: 'list-company-section',
      component: List,
      meta: {
        permissions: [Permissions.backend.setting.companySection.list],
        groups: 'company-section',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Company Section',
            name_km: 'ផ្នែកក្រុមហ៊ុន'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-company-section',
      component: Create,
      meta: {
        permissions: [Permissions.backend.setting.companySection.store],
        groups: 'company-section',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'New Company Section',
            name_km: 'បន្ថែមផ្នែកក្រុមហ៊ុន'
          }
        ]
      }
    },
    {
      path: 'edit/:company_section_uuid',
      name: 'edit-company-section',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.setting.companySection.edit],
        groups: 'company-section',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Edit Company Section',
            name_km: 'កែប្រែផ្នែកក្រុមហ៊ុន'
          }
        ]
      }
    },
    {
      path: 'show/:company_section_uuid',
      name: 'show-company-section',
      component: Show,
      meta: {
        permissions: [Permissions.backend.setting.companySection.show],
        groups: 'company-section',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Show Company Section',
            name_km: 'បង្ហាញព័ត៏មានផ្នែកក្រុមហ៊ុន'
          }
        ]
      }
    }
  ]
}
