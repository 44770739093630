<template>
  <div class="col-md-12">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t('string.unit') }}
        </h3>
      </div>
      <div class="box-body">
        <div class="row">
          <div class="col-md-12">
            <h5 class="main-title">
              <strong>{{ $t('string.buildingInput') }}</strong>
            </h5>
          </div>
          <div class="col-md-12">
            <project-building-detail
              :enabled-action="false"
              :enabled-title="false"
              :project-uuid="$route.params.project_uuid"
            />
          </div>
          <div class="col-md-12">
            <table class="table table-condensed table-bordered table-striped">
              <thead>
                <tr>
                  <th />
                  <th>{{ $t('string.request') }}</th>
                  <th>{{ $t('string.practical') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>{{ $t('label.nbBuilding') }}</th>
                  <td>{{ project.nb_building_request || $t('string.na') }}</td>
                  <td>{{ project.nb_building || $t('string.na') }}</td>
                </tr>
                <tr>
                  <th>{{ $t('label.nbFloor') }}</th>
                  <td>{{ project.nb_floor_request ? `${project.nb_floor_request}` : $t('string.na') }}</td>
                  <td>{{ project.nb_floor ? `${project.nb_floor}` : $t('string.na') }}</td>
                </tr>
                <tr>
                  <th>{{ $t('label.nbUndergroundFloor') }}</th>
                  <td>
                    {{
                      project.nb_underground_floor_request ? `${project.nb_underground_floor_request}` :
                      $t('string.na')
                    }}
                  </td>
                  <td>{{ project.nb_underground_floor ? `${project.nb_underground_floor}` : $t('string.na') }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-12">
            <table class="table table-condensed table-bordered table-striped">
              <tbody>
                <tr
                  v-for="(row, key) in columns"
                  :key="key"
                >
                  <th>{{ row.label }}</th>
                  <td>
                    <template v-if="project[row.key]">
                      {{ project[row.key] }} <span v-html="row.subfix" />
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>

                <tr>
                  <th>{{ $t('label.unit') }}</th>
                  <td>
                    <template v-if="project.units.length > 0">
                      <div
                        v-for="(unit, key) in project.units"
                        :key="key"
                      >
                        <template v-if="unit.pivot.value_en>0">
                          <label>{{ unit.pivot.value_en }} {{ unit['name_' + $i18n.locale] }}</label>
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectBuildingDetail from "@/views/project/includes/components/ProjectBuildingDetail"

export default {
  name: "ConstructionUnitPanel",
  components: {ProjectBuildingDetail},
  props: {
    project: {
      type: Object,
    }
  },
  computed: {
    columns() {
      return [
        {label: this.$t('label.high'), key: 'high', subfix: 'm'},
        {label: this.$t('label.surface'), key: 'surface', subfix: 'm<sup>2</sup>'},
        {label: this.$t('label.surface_bottom_cell'), key: 'surface_bottom_cell', subfix: 'm<sup>2</sup>'},
        {label: this.$t('label.constructionSurface'), key: 'construction_surface', subfix: 'm<sup>2</sup>'},
        {
          label: this.$t('label.constructionSurfaceCanProcessed'),
          key: 'construction_surface_can_processed',
          subfix: 'm<sup>2</sup>'
        },
        {label: this.$t('label.index'), key: 'index_permit', subfix: ''},
        {label: this.$t('label.indexPractical'), key: 'index_practical', subfix: ''},
        {label: this.$t('label.indexOver'), key: 'index_over', subfix: ''},
        {label: this.$t('label.surface_index_over'), key: 'surface_index_over', subfix: 'm<sup>2</sup>'},
        {
          label: this.$t('label.surface_percentage_of_land_used'),
          key: 'surface_percentage_of_land_used_law',
          subfix: '%'
        },
        {
          label: this.$t('label.surface_percentage_of_bottom'),
          key: 'surface_percentage_of_land_used_practical',
          subfix: '%'
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>
