import Layout from "../../views/administrations/permission/Index"
import List from "../../views/administrations/permission/List"
import Permissions from "../../permissions"

export const permissionRouters = {
  path: 'permission',
  component: Layout,
  redirect: 'permission',
  meta: {
    permissions: [Permissions.backend.administration.permission.manage],
    groups: 'permission',
  },
  children: [
    {
      path: '',
      name: 'list-permission',
      component: List,
      meta: {
        permissions: [Permissions.backend.administration.permission.list],
        groups: 'permission',
        breadcrumb: [
          {
            name_en: 'Administration',
            name_km: 'រដ្ឋបាល'
          },
          {
            name_en: 'Permission',
            name_km: 'សិទ្ធិ'
          }
        ]
      }
    }
  ]
}
