const state = {
  data: {
    nationalities: [],
    projectTypes: [],
    constructionTypes: [],
    countries: [],
    companySections: [],
    companyTypes: [],
    people: [],
    architectures: [],
    companies: [],
    isProvinceDataLevel: false,
  },
  project_is_published: false
}


const getters = {
  isProvinceDataLevel: state => state.isProvinceDataLevel,
  project_is_published: state => state.project_is_published
}

const mutations = {
  setNationalities(state, value) {
    state.data.nationalities = value
  },
  setProjectTypes(state, value) {
    state.data.projectTypes = value
  },
  setConstructionTypes(state, value) {
    state.data.constructionTypes = value
  },
  setCountries(state, value) {
    state.data.countries = value
  },
  setCompanySections(state, value) {
    state.data.companySections = value
  },
  setCompanyTypes(state, value) {
    state.data.companyTypes = value
  },
  setPeople(state, value) {
    state.data.people = value
  },
  setArchitectures(state, value) {
    state.data.architectures = value
  },
  setCompanies(state, value) {
    state.data.companies = value
  },
  setIsProvinceDataLevel(state, value) {
    state.isProvinceDataLevel = value
  },
  setProjectIsPublished(state, value) {
    state.project_is_published = value
  }
}

const actions = {
  setIsProvinceDataLevel({commit}, value) {
    commit('setIsProvinceDataLevel', value)
  },
  setProjectIsPublished({commit}, value) {
    commit('setProjectIsPublished', value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
