<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        <template v-if="show_form">
          {{ $t('string.editOpenConstructionLetter') }}
        </template>
        <template v-else>
          {{ $t('string.preview_document') }}
        </template>
      </h3>
      <div class="box-tools tw-space-x-3">
        <button-back-to-list />
        <template v-if="hasPreviewButton">
          <button
            v-if="show_form"
            class="btn btn-success btn-sm"
            @click="show_form = false"
          >
            <i class="fa fa-search" /> {{ $t('string.preview') }}
          </button>
          <button
            v-else
            class="btn btn-default btn-sm"
            @click="show_form = true"
          >
            <i class="fa fa-window-restore" /> {{ $t('string.back_to_form') }}
          </button>
        </template>
      </div>
    </div>
    <div class="box-body">
      <template v-if="show_form">
        <open-construction-letter-form />
      </template>
      <template v-else>
        <smart-iframe
          ref="previewIframe"
          :src="previewURL"
        />
      </template>
    </div>
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields"
import OpenConstructionLetterForm from "./Form"
import SmartIframe from "@/components/SmartIframe"
import ButtonBackToList from "../includes/ButtonBackToList"

export default {
  name: "Edit",
  metaInfo() {
    return {
      title: this.$t('string.editOpenConstructionLetter'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  components: {
    SmartIframe,
    ButtonBackToList,
    OpenConstructionLetterForm
  },
  computed: {
    ...mapFields('general', {
      show_form: 'show_form'
    }),
    previewURL() {
      return `${this.baseUrl}/construction-letter/open-construction-letter/preview/${this.$route.params.construction_letter_uuid}`
    },
    hasPreviewButton () {
      return this.$route.params.construction_letter_uuid
    }
  },
  beforeDestroy() {
    this.show_form = true
  }
}
</script>

<style scoped>

</style>
