import ConstructionLetterCommand from "./../../views/settings/constructionLetterCommand/Index"
import List from "./../../views/settings/constructionLetterCommand/DataTable"
import Create from "./../../views/settings/constructionLetterCommand/Create"
import Edit from "./../../views/settings/constructionLetterCommand/Edit"
import Show from "./../../views/settings/constructionLetterCommand/Show"
import Permissions from "../../permissions"

export const constructionLetterCommandRouters = {
  path: 'construction-letter-command',
  component: ConstructionLetterCommand,
  redirect: 'construction-letter-command',
  meta: {
    groups: 'construction-letter-command',
    permissions: [Permissions.backend.setting.constructionLetterCommand.manage]
  },
  children: [
    {
      path: '',
      name: 'list-construction-letter-command',
      component: List,
      meta: {
        groups: 'construction-letter-command',
        permissions: [Permissions.backend.setting.constructionLetterCommand.list],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Construction Letter Command',
            name_km: 'យោលបល់ការិយាល័យជំនាញ'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-construction-letter-command',
      component: Create,
      meta: {
        groups: 'construction-letter-command',
        permissions: [Permissions.backend.setting.constructionLetterCommand.store],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Construction Letter Command',
            name_km: 'យោលបល់ការិយាល័យជំនាញ'
          },
          {
            name_en: 'New',
            name_km: 'បង្កើតថ្មី'
          }
        ]
      }
    },
    {
      path: 'edit/:construction_letter_command_uuid',
      name: 'edit-construction-letter-command',
      component: Edit,
      meta: {
        groups: 'construction-letter-command',
        permissions: [Permissions.backend.setting.constructionLetterCommand.edit],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Construction Letter Command',
            name_km: 'យោលបល់ការិយាល័យជំនាញ'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'show/:construction_letter_command_uuid',
      name: 'show-construction-letter-command',
      component: Show,
      meta: {
        groups: 'construction-letter-command',
        permissions: [Permissions.backend.setting.constructionLetterCommand.show],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Construction Letter Command',
            name_km: 'យោលបល់ការិយាល័យជំនាញ'
          },
          {
            name_en: 'View',
            name_km: 'មើល'
          }
        ]
      }
    }
  ]
}
