import Settings from "./../../views/settings/Index"
import {genderRouters} from "./gender"
import Permissions from "../../permissions"
import {projectTypeRouters} from "./projectType"
import {projectUnitRouters} from "./projectUnit"
import {villageRouters} from "./village"
import {communeRouters} from "./commune"
import {districtRouters} from "./district"
import {provinceRouters} from "./province"
import {nationalityRouters} from "./nationality"
import {countryRouters} from "./country"
import {companyTypeRouters} from "./companyType"
import {companySectionRouters} from "./companySection"
import {constructionTypeRouters} from "./constructionType"
import {architectureTypeRouters} from "./architectureType"
import {positionRouters} from "./position"
import {lawRegulationTypeRouters} from "./lawRegulationType"
import {insuranceCompanyRouters} from "./insuranceCompany"
import {ministryPermitOrLicenseRouters} from "./ministryPermitOrLicense"
import {activitiesRouters} from "./activity"
import {missionTypeRouters} from "./missionType"
import {universityRouters} from "./university"
import {landUniqueNumberTypeRouters} from "./landUniqueNumberType"
import {teamCategoryRouters} from "./teamCategory"
import {titleRouters} from "./title"
import {projectSubTypeRouters} from "./projectSubType"
import {carbonCopyRouters} from "./carbonCopy"
import {announcementReferenceRouters} from "./announcementReference"
import {constructionLetterCommandRouters} from "./constructionLetterCommand"

export const settingRouters = {
  path: 'settings',
  name: 'settings',
  component: Settings,
  redirect: 'gender',
  meta: {
    permissions: [Permissions.backend.setting.manage]
  },
  children: [
    titleRouters,
    teamCategoryRouters,
    positionRouters,
    genderRouters,
    companyTypeRouters,
    companySectionRouters,
    countryRouters,
    nationalityRouters,
    projectTypeRouters,
    projectUnitRouters,
    villageRouters,
    communeRouters,
    districtRouters,
    provinceRouters,
    constructionTypeRouters,
    architectureTypeRouters,
    lawRegulationTypeRouters,
    insuranceCompanyRouters,
    ministryPermitOrLicenseRouters,
    activitiesRouters,
    missionTypeRouters,
    universityRouters,
    landUniqueNumberTypeRouters,
    projectSubTypeRouters,
    carbonCopyRouters,
    announcementReferenceRouters,
    constructionLetterCommandRouters,
  ]
}
