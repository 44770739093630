<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('label.showCompanySection') }}
      </h3>
      <div class="box-tools">
        <button
          v-if="can($Permissions.backend.setting.companySection.edit)"
          class="btn btn-primary btn-sm margin-right-5"
          @click="$router.push({
            name: 'edit-company-section',
            params: {
              company_section_uuid: $route.params.company_section_uuid,
            }
          })"
        >
          <i class="fa fa-edit" /> {{ $t('button.edit') }}
        </button>
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <div class="form-horizontal">
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.nameKm') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ companySection.name_km }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.nameEn') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ companySection.name_en }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.createdAt') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ getDate(companySection.created_at, false) }}
            </p>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.updatedAt') }}</label>
          <div class="col-sm-9">
            <p class="text-bold">
              : {{ getDate(companySection.updated_at, false) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('label.showCompanySection'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      companySection: {}
    }
  },
  created() {
    this.getCompanySection()
  },
  methods: {
    getCompanySection() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/company-section/show', {
        company_section_uuid: this.$route.params.company_section_uuid,
      }).then((res) => {
        this.companySection = res.data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-company-section'})
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>
.control-label {
  padding-top: 0 !important;
}
</style>
