<template>
  <project-edit
    :is-province-data-level="true"
  />
</template>

<script>
import ProjectEdit from "../project/Edit"

export default {
  name: "Edit",
  metaInfo() {
    return {
      title: this.$t('label.editProject'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {ProjectEdit}
}
</script>

<style scoped>

</style>
