<template>
  <div class="row">
    <template v-if="company">
      <div class="col-md-12 margin-bottom">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('string.detail') }}
            </h3>
            <div class="box-tools">
              <ButtonActions />
              <template v-if="company && company.hasOwnProperty('uuid')">
                <template v-if="company.hasOwnProperty('is_published') && company.is_published">
                  <button
                    class="btn btn-warning btn-sm margin-right-5"
                    @click="toggleCompany(company.uuid)"
                  >
                    <i class="fas fa-eye-slash" />
                  </button>
                </template>
                <template v-else>
                  <button
                    class="btn btn-danger btn-sm margin-right-5"
                    @click="toggleCompany(company.uuid)"
                  >
                    <i class="fas fa-bullhorn" />
                  </button>
                </template>
              </template>
              <asora-back-button />
            </div>
          </div>
          <div class="box-body">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr>
                  <th style="width: 210px !important;">
                    {{ $t('string.companyLogo') }}
                  </th>
                  <td>
                    <template v-if="company.logo">
                      <img
                        :src="`${baseUrl}/${company.logo}`"
                        class="datatable-image-size"
                      >
                    </template>
                    <template v-else>
                      <img
                        src="/img/company.png"
                        class="datatable-image-size"
                      >
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('string.companyName') }}</th>
                  <td>{{ company['name_' + $i18n.locale] || $t('string.na') }}</td>
                </tr>
                <tr>
                  <th>{{ $t('string.companyOwner') }}</th>
                  <td>
                    <template v-if="company.owners && company.owners.length >0">
                      <template v-for="(people, key) in company.owners">
                        <people-chip-item
                          :key="key"
                          :people="people"
                          model="people"
                        />
                      </template>
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('string.companyProjectManager') }}</th>
                  <td>
                    <template v-if="company.project_managers && company.project_managers.length >0">
                      <template v-for="(people, key) in company.project_managers">
                        <people-chip-item
                          :key="key"
                          :people="people"
                          model="people"
                        />
                      </template>
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('string.companyCeo') }}</th>
                  <td>
                    <template v-if="company.ceos && company.ceos.length >0">
                      <template v-for="(ceo, key) in company.ceos">
                        <people-chip-item
                          :key="key"
                          :people="ceo"
                          model="people"
                        />
                      </template>
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('string.companyCto') }}</th>
                  <td>
                    <template v-if="company.ctos && company.ctos.length >0">
                      <template v-for="(cto, key) in company.ctos">
                        <people-chip-item
                          :key="key"
                          :people="cto"
                          model="people"
                        />
                      </template>
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('string.firstRegistrationNumber') }}</th>
                  <td>{{ company['first_registration_number_' + $i18n.locale] || $t('string.na') }}</td>
                </tr>
                <tr>
                  <th>{{ $t('label.first_registration_date') }}</th>
                  <td>
                    {{
                      getNumbers(getDate(company.first_registration_date, false, 'DD/MM/YYYY')) || $t('string.na')
                    }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('string.certificateOfMefNumber') }}</th>
                  <td>{{ getNumbers(company.certificate_of_mef_number) || $t('string.na') }}</td>
                </tr>
                <tr>
                  <th>{{ $t('string.certificateOfMefDate') }}</th>
                  <td>
                    {{
                      getNumbers(getDate(company.certificate_of_mef_date, false, 'DD/MM/YYYY')) || $t('string.na')
                    }}
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('string.companyType') }}</th>
                  <td>
                    <template v-if="company.company_type">
                      {{ company.company_type['name_' + $i18n.locale] }}
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('string.companySection') }}</th>
                  <td>
                    <template v-if="company.company_section">
                      {{ company.company_section['name_' + $i18n.locale] }}
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('string.category') }}</th>
                  <td>
                    <template v-if="company.categories && company.categories.length >0">
                      <ul style="margin: 0; padding: 0 0 0 15px;">
                        <template v-for="(category, key) in company.categories">
                          <li :key="key">
                            {{ category['name_' + $i18n.locale] }}
                          </li>
                        </template>
                      </ul>
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('string.companyCountry') }}</th>
                  <td>
                    <template v-if="company.country">
                      {{ company.country['name_' + $i18n.locale] }}
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('string.companyNationality') }}</th>
                  <td>
                    <template v-if="company.nationality">
                      {{ company.nationality['name_' + $i18n.locale] }}
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('string.companyDescription') }}</th>
                  <td>{{ company.description || $t('string.na') }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-12 margin-bottom">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('label.declarationNumberOfMinistry') }}
            </h3>
          </div>
          <div class="box-body">
            <template v-if="company.ministry_registrations !== null && company.ministry_registrations.length > 0">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>{{ $t('label.number') }}</th>
                    <th>{{ $t('label.date') }}</th>
                    <th>{{ $t('label.effective') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(ministry_registration, key) in company.ministry_registrations"
                    :key="key"
                  >
                    <td>{{ ministry_registration.number }}</td>
                    <td>{{ ministry_registration.issued_date || $t('string.na') }}</td>
                    <td>{{ ministry_registration.effective_date || $t('string.na') }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
            <template v-else>
              {{ $t('string.na') }}
            </template>
          </div>
        </div>
      </div>
      <div class="col-md-12 margin-bottom">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('string.companyTechnician') }}
            </h3>
          </div>
          <div class="box-body">
            <template v-if="company.technicians !== null && company.technicians.length > 0">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>{{ $t('label.name') }}</th>
                    <th>{{ $t('label.phone') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(technician, key) in company.technicians"
                    :key="key"
                  >
                    <td>{{ technician['name_' + $i18n.locale] }}</td>
                    <td>{{ technician.phone || $t('string.na') }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
            <template v-else>
              {{ $t('string.na') }}
            </template>
          </div>
        </div>
      </div>
      <div class="col-md-12 margin-bottom">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('string.contact') }}
            </h3>
          </div>
          <div class="box-body">
            <table class="table table-bordered table-striped">
              <tbody>
                <tr>
                  <th>{{ $t('string.email') }}</th>
                  <td>{{ company.email || $t('string.na') }}</td>
                </tr>
                <tr>
                  <th>{{ $t('string.phone') }}</th>
                  <td>{{ company.phone || $t('string.na') }}</td>
                </tr>
                <tr>
                  <th>{{ $t('string.website') }}</th>
                  <td>{{ company.website || $t('string.na') }}</td>
                </tr>
                <tr>
                  <th>{{ $t('string.homeNumber') }}</th>
                  <td>{{ company.home_number || $t('string.na') }}</td>
                </tr>
                <tr>
                  <th>{{ $t('string.streetNumber') }}</th>
                  <td>{{ company.street_number || $t('string.na') }}</td>
                </tr>
                <tr>
                  <th>{{ $t('string.groupNumber') }}</th>
                  <td>{{ company.group_number || $t('string.na') }}</td>
                </tr>
                <tr>
                  <th>{{ $t('label.village') }}</th>
                  <td>
                    <template v-if="company.village">
                      {{ company.village['name_' + $i18n.locale] }}
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('label.commune') }}</th>
                  <td>
                    <template v-if="company.commune">
                      {{ company.commune['name_' + $i18n.locale] }}
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('label.district') }}</th>
                  <td>
                    <template v-if="company.district">
                      {{ company.district['name_' + $i18n.locale] }}
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('label.province') }}</th>
                  <td>
                    <template v-if="company.province">
                      {{ company.province['name_' + $i18n.locale] }}
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <p>{{ $t('string.map') }}</p>
                    <GmapMap
                      :center="{lat:company.lat ? company.lat : 11.5760397, lng:company.lng ? company.lng : 104.9230512}"
                      :zoom="15"
                      map-type-id="terrain"
                      style="width: 100%; height: 430px"
                    >
                      <GmapMarker
                        :icon="{url: '/img/markers/blue20x20.png'}"
                        :position="{lat:company.lat ? company.lat : 11.5760397, lng:company.lng ? company.lng : 104.9230512}"
                      />
                    </GmapMap>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <p class="text-muted text-center">
        {{ $t('string.noResult') }}
      </p>
    </template>
  </div>
</template>

<script>
import ButtonActions from "@/views/companyManagement/companies/includes/ButtonActions"

export default {
  name: "ShowCompanyDetail",
  components: {ButtonActions},
  data() {
    return {
      company: null,
    }
  },
  mounted() {
    this.getCompany()
  },
  methods: {
    toggleCompany(uuid) {
      this.$swal({
        title: this.$t('string.publishingArchitecture'),
        text: this.$t('string.doYouWantToToggleArchitectureOnMobileApp?'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('string.no'),
        confirmButtonText: this.$t('string.yes')
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/company/toggle-published', {
            company_uuid: uuid
          }).then(({data}) => {
            this.showToastr()
            this.company.is_published = data.data.is_published
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
    getCompany() {
      let api = `${process.env.VUE_APP_API}/api/backend/company/registration/show`
      this.$isLoading(true)
      this.$axios.post(api, {
        company_uuid: this.$route.params.company_uuid,
      }).then(({data}) => {
        this.company = data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
