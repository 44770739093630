import Certificates from "../../views/certificates/Index"
import {certificatesOccupancyRouters} from "./occupancy"
import Permissions from "../../permissions"

export const certificatesRouters = {
  path: 'certificates',
  name: 'certificates',
  component: Certificates,
  meta: {
    permissions: [Permissions.backend.certificate.manage],
    groups: 'certificates',
  },
  children: [
    certificatesOccupancyRouters,
  ]
}
