<template>
  <div>
    <template v-if="src">
      <a
        :href="src"
        target="_blank"
      >
        <img
          :src="src"
          :style="`width: ${width}px; height: ${height}px`"
          alt="image"
        >
      </a>
    </template>
    <template v-else>
      <img
        src="/images/avatar/avatar.png"
        :style="`width: ${width}px; height: ${height}px`"
        alt="image"
      >
    </template>
  </div>
</template>

<script>
export default {
  name: "RenderImageTag",
  props: {
    src: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      default: 80
    },
    height: {
      type: Number,
      default: 80
    },
    className: {
      type: String,
      default: 'profile-user-img img-circle'
    }
  }
}
</script>

<style scoped>

</style>
