<template>
  <detail-letter
    :type-letter="$t('string.openConstructionLetterLabel')"
  />
</template>

<script>
import DetailLetter from "../includes/DetailLetter"

export default {
  name: "Show",
  components: {DetailLetter},
  metaInfo() {
    return {
      title: this.$t('string.showOpenConstructionLetter'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
}
</script>

<style scoped>

</style>
