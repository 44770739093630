<template>
  <div
    v-if="doc"
    :class="classColumn"
  >
    <div class="file file-primary">
      <div class="file-header">
        <template v-if="doc.filename!==null">
          {{ doc.filename }}
        </template>
        <template v-else>
          .{{ doc.mine_type.toString().toUpperCase() }}
          <small>({{ parseFloat((doc.size/(1024*1024))).toFixed(2) }} Mb)</small>
        </template>
      </div>
      <template v-if="mimesType.image.includes(doc.mine_type)">
        <viewer>
          <img
            :src="getSrc(doc.src)"
            class="file-body"
            alt="Image"
          >
        </viewer>
      </template>

      <template v-else-if="mimesType.pdf.includes(doc.mine_type)">
        <div
          class="file-body"
          :style="`background-image: url('/img/pdf.png')`"
        />
      </template>

      <template v-else-if="mimesType.word.includes(doc.mine_type)">
        <div
          class="file-body"
          :style="`background-image: url('/img/docx.png')`"
        />
      </template>

      <template v-else-if="mimesType.excel.includes(doc.mine_type)">
        <div
          class="file-body"
          :style="`background-image: url('/img/xls.png')`"
        />
      </template>
      <template v-else-if="mimesType.powerPoint.includes(doc.mine_type)">
        <div
          class="file-body"
          :style="`background-image: url('/img/ppt.png')`"
        />
      </template>
      <template v-else>
        <div
          class="file-body"
          :style="`background-image: url('/img/txt.png')`"
        />
      </template>
      <div class="file-footer">
        <button
          v-if="!readonly"
          class="btn btn-danger btn-xs margin-r-5"
          @click="deleteMedia(doc.uuid)"
        >
          <i class="fas fa-trash-alt" />
        </button>
        <button
          class="btn btn-info btn-xs margin-r-5"
          @click="preview(doc)"
        >
          <i class="fa fa-search" />
        </button>
        <a
          :href="`${getSrc(doc.src)}`"
          download
          target="_blank"
          class="btn btn-success btn-xs margin-r-5"
        >
          <i class="fa fa-download" />
        </a>
        <template v-if="copiable">
          <button
            class="btn btn-primary btn-xs"
            @click="copyMedia(doc)"
          >
            <i class="far fa-copy" />
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadMediaPreview",
  props: {
    classColumn: {
      type: String,
      default: ''
    },
    doc: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    copiable: {
      type: Boolean,
      default: false,
    },
    copyToModelType: {
      type: String,
      default: null
    },
    copyToModelPath: {
      type: String,
      default: null,
    },
    modelUuid: {
      type: String,
      default: null,
    },
    modelName: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      mimesType: {
        image: ['png', 'jpg', 'jpeg'],
        pdf: ['pdf'],
        word: ['doc', 'docx'],
        excel: ['xls', 'xlsx'],
        powerPoint: ['ppt', 'pptx']
      }
    }
  },
  methods: {
    preview(doc) {
      if (doc.mine_type === 'pdf') {
        this.onClickOpenNewTab({
          name: 'preview-pdf',
          params: {
            path: doc.src
          }
        })
      } else {
        window.open(`${this.baseUrl}/${doc.src}`, '_blank')
      }
    },
    deleteMedia(uuid) {
      this.$swal({
        title: this.i18nSwalTitle,
        text: this.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.i18nSwalNo,
        confirmButtonText: this.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/media/delete', {
            media_uuid: uuid
          }).then(() => {
            this.showSwalSuccess()
            this.$emit('fetchData')
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
    copyMedia(media) {
      this.$swal({
        title: this.$t('string.copyMedia'),
        text: this.$t('string.doYouWantToCopyMedia?'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('string.no'),
        confirmButtonText: this.$t('string.yesIAgree')
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/media/copy', {
            media_uuid: media.uuid,
            model_name: this.modelName,
            model_uuid: this.modelUuid,
            to_model_type: this.copyToModelType,
            to_model_path: this.copyToModelPath,
          }).then(() => {
            this.$swal({
              type: 'success',
              text: this.$t('string.theDocumentHaveBeenSuccessfullyCopied'),
              title: this.$t('string.copied'),
              confirmButtonText: this.i18nSwalYes
            })
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
  }
}
</script>

<style scoped>
  .file {
    width: 100%;
    position: relative;
    height: auto;
    border: 1px solid #0d6aad;
    margin-bottom: 10px;
  }

  .file-header {
    background-color: #0d6aad;
    padding: 15px;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .file-footer {
    visibility: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35px;
    background-color: #dddddd;
    padding: 6px;
  }

  .file:hover .file-footer {
    visibility: visible;
  }

  .file-body {
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
</style>
