<template>
  <div>
    <template v-if="box">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ title }}
          </h3>
        </div>
        <div class="box-body">
          <div class="under-development">
            <div class="tw-space-y-8">
              <p class="text-center">
                <img
                  :src="svg"
                  style="width: 30%;"
                  alt="image"
                >
              </p>
              <h3 class="font-km-bold text-center font-secondary">
                {{ description }}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="under-development">
        <div class="tw-space-y-8">
          <p class="text-center">
            <img
              :src="svg"
              style="width: 30%;"
              alt="image"
            >
          </p>
          <h3 class="font-km-bold text-center font-secondary">
            {{ description }}
          </h3>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "UnderDevelopment",
  props: {
    box: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'កំពុងស្ថិតក្នុងការអភិវឌ្ឍន៍នៅឡើយ'
    },
    description: {
      type: String,
      default: 'កំពុងស្ថិតក្នុងការអភិវឌ្ឍន៍នៅឡើយ'
    },
    svg: {
      type: String,
      default: '/img/svgs/development.svg'
    },
  }
}
</script>

<style scoped>
.under-development {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width: 100%;
}
</style>
