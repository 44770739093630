<template>
  <news-form />
</template>

<script>

import NewsForm from "./Form"

export default {
  name: "Create",
  metaInfo() {
    return {
      title: this.$t('string.add_new_news'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {NewsForm}
}
</script>

<style scoped>

</style>
