<template>
  <div class="row">
    <div class="col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ title }}
          </h3>
          <div class="box-tools">
            <template v-if="can(editPermission)">
              <button
                class="btn btn-primary btn-sm margin-right-5"
                @click="$router.push({
                  name: nameRouteEdit,
                  params: {
                    people_uuid: $route.params.people_uuid,
                  }
                })"
              >
                <i class="fa fa-edit" /> {{ $t('button.edit') }}
              </button>
            </template>
            <asora-back-button />
          </div>
        </div>
        <div class="box-body">
          <div class="form-horizontal">
            <div class="form-group row">
              <div class="col-sm-9 col-sm-offset-3">
                <render-image-tag :src="people.avatar ? `${baseUrl}/${people.avatar}` : null" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.nameKm') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ people.name_km }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.nameEn') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ people.name_en }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.nationalId') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ people.national_id ? people.national_id : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.passport') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ people.passport ? people.passport : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.email') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  <a :href="`mailto:${people.email}`">: {{ people.email ? people.email : $t('string.na') }}</a>
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.phone') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  <a :href="`tel:${people.phone}`">: {{ people.phone ? people.phone : $t('string.na') }}</a>
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.gender') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{
                    people.gender ? people.gender['name_' + $i18n.locale] :
                    $t('string.na')
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.dob') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ getDate(people.dob, false, 'DD/MM/YYYY') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.placeOfBirth') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ people.place_of_birth ? people.place_of_birth : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.nationality') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ people.nationality ? people.nationality['name_' + $i18n.locale] : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.country') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ people.country ? people.country['name_' + $i18n.locale] : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.homeNumber') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  :
                  {{ people.home_number ? people.home_number : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.streetNumber') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  :
                  {{ people.street_number ? people.street_number : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.groupNumber') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  :
                  {{ people.group_number ? people.group_number : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.province') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ people.province ? people.province['name_' + $i18n.locale] : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.district') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ people.district ? people.district['name_' + $i18n.locale] : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.commune') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ people.commune ? people.commune['name_' + $i18n.locale] : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.village') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ people.village ? people.village['name_' + $i18n.locale] : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.createdAt') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ getDate(people.created_at, false) }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.updatedAt') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ getDate(people.updated_at, false) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <upload-media
        :button-label="$t('label.chooseReference')"
        :model-uuid="$route.params.people_uuid"
        :readonly="modeShow"
        button-icon="far fa-file-alt"
        model-name="people"
        preview-column="col-xs-6 margin-bottom"
      />
    </div>
  </div>
</template>

<script>
import RenderImageTag from "../../components/RenderImageTag"
import UploadMedia from "../../components/UploadMedia"

export default {
  name: "ShowPeople",
  components: {
    UploadMedia,
    RenderImageTag,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    nameRouteEdit: {
      type: String,
      required: true,
    },
    modeShow: {
      type: Boolean,
      required: false,
    },
    editPermission: {
      type: String,
    }
  },
  data() {
    return {
      people: {}
    }
  },
  created() {
    this.getPeople()
  },
  methods: {
    getPeople() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/people/show', {
        people_uuid: this.$route.params.people_uuid,
      }).then((res) => {
        this.people = res.data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>
.control-label {
  padding-top: 0 !important;
}
</style>
