import CompanyRegistrations from '../../views/companyManagement/Index'
import {registerCompanyRouters} from "./company"
import {licenseCompanyRouters} from "./license"
import {certificateCompanyRouters} from "./certificate"
import {noteLicenseCompanyRouters} from "./note"
import {deikarbanhchounRouters} from "./deikarbanhchoun"
import Permissions from "@/permissions"

export const companyManagementRouters = {
  path: 'company-management',
  name: 'company-management',
  component: CompanyRegistrations,
  meta: {
    permissions: [Permissions.backend.companyManagement.manage],
    groups: 'company-management',
  },
  children: [
    registerCompanyRouters,
    licenseCompanyRouters,
    certificateCompanyRouters,
    noteLicenseCompanyRouters,
    deikarbanhchounRouters,
  ]
}
