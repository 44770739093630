<template>
  <div
    id="architectureModal"
    aria-labelledby="architectureModalLabel"
    class="modal fade"
    data-backdrop="static"
    data-keyboard="false"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal-dialog modal-lg-custom"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
            @click="resetForm"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4
            id="architectureModalLabel"
            class="modal-title"
          >
            <strong>{{ $t('string.newArchitecture') }}</strong>
          </h4>
        </div>
        <div class="modal-body modal-body-custom">
          <div class="form-horizontal">
            <div class="row">
              <div class="col-sm-6 col-md-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-section">
                      <div class="box-header with-border">
                        <h3 class="box-title">
                          {{ $t('string.aboutRegistration') }}
                        </h3>
                      </div>
                      <div class="box-body">
                        <div
                          :class="registrationNumberRequired.required?'has-error':null"
                          class="form-group row"
                        >
                          <label class="col-sm-12">{{ $t('label.registrationNumber') }}</label>
                          <div class="col-sm-12">
                            <input
                              v-model="architecture.registration_number"
                              :placeholder="$t('label.registrationNumber')"
                              class="form-control"
                              type="text"
                            >
                            <template v-if="registrationNumberRequired.required">
                              <span class="label-error">{{ registrationNumberRequired.message }}</span>
                            </template>
                          </div>
                        </div>

                        <div
                          :class="uniqueNumberRequired.required?'has-error':null"
                          class="form-group row"
                        >
                          <label class="col-sm-12">{{ $t('label.uniqueNumber') }}</label>
                          <div class="col-sm-12">
                            <input
                              v-model="architecture.unique_number"
                              :placeholder="$t('label.uniqueNumber')"
                              class="form-control"
                              type="text"
                            >
                            <template v-if="uniqueNumberRequired.required">
                              <span class="label-error">{{ uniqueNumberRequired.message }}</span>
                            </template>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-12">{{ $t('label.registration_expired_date') }}</label>
                          <div class="col-sm-12">
                            <date-picker
                              v-model="architecture.registration_expired_date"
                              :lang="datepickerLocale[$i18n.locale].lang"
                              :placeholder="$t('label.registration_expired_date')"
                              class="table-full-width"
                              format="DD/MM/YYYY"
                              type="date"
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-12">{{ $t('label.certificate_number') }}</label>
                          <div class="col-sm-12">
                            <input
                              v-model="architecture.certificate_number"
                              :placeholder="$t('label.certificate_number')"
                              class="form-control"
                              type="text"
                            >
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-12">{{ $t('label.graduateDate') }}</label>
                          <div class="col-sm-12">
                            <date-picker
                              v-model="architecture.graduation_date"
                              :lang="datepickerLocale[$i18n.locale].lang"
                              :placeholder="$t('label.graduateDate')"
                              class="table-full-width"
                              format="DD/MM/YYYY"
                              type="date"
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-12">{{ $t('label.fromUniversity') }}</label>
                          <div class="col-sm-12">
                            <multiselect
                              v-model="architecture.university_id"
                              :label="'name_' + $i18n.locale"
                              :options="universities"
                              :placeholder="$t('string.chooseUniversity')"
                              track-by="id"
                            >
                              <template slot="noOptions">
                                {{ $t('string.noOptions') }}
                              </template>
                              <template slot="noResult">
                                {{ $t('string.noResult') }}
                              </template>
                            </multiselect>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-12">{{ $t('label.nationalIdCard') }}</label>
                          <div class="col-sm-12">
                            <input
                              v-model="architecture.national_id_card"
                              :placeholder="$t('label.nationalIdCard')"
                              class="form-control"
                              type="text"
                            >
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-12">{{ $t('label.nationalIdCardCreationDate') }}</label>
                          <div class="col-sm-12">
                            <date-picker
                              v-model="architecture.national_id_card_creation_date"
                              :lang="datepickerLocale[$i18n.locale].lang"
                              :placeholder="$t('label.nationalIdCardCreationDate')"
                              class="table-full-width"
                              format="DD/MM/YYYY"
                              type="date"
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-12">{{ $t('label.passportNumber') }}</label>
                          <div class="col-sm-12">
                            <input
                              v-model="architecture.passport_number"
                              :placeholder="$t('label.passportNumber')"
                              class="form-control"
                              type="text"
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-md-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-section">
                      <div class="box-header with-border">
                        <h3 class="box-title">
                          {{ $t('string.aboutArchitecture') }}
                        </h3>
                      </div>
                      <div class="box-body">
                        <div
                          :class="nameKmRequired.required? 'has-error':null"
                          class="form-group row"
                        >
                          <label class="col-sm-12 required">{{ $t('label.nameKm') }}</label>
                          <div class="col-sm-12">
                            <input
                              v-model="architecture.name_km"
                              :placeholder="$t('label.nameKm')"
                              class="form-control"
                              type="text"
                            >
                            <template v-if="nameKmRequired.required">
                              <span class="label-error">{{ nameKmRequired.message }}</span>
                            </template>
                          </div>
                        </div>

                        <div
                          :class="nameEnRequired.required?'has-error':null"
                          class="form-group row"
                        >
                          <label class="col-sm-12 required">{{ $t('label.nameEn') }}</label>
                          <div class="col-sm-12">
                            <input
                              v-model="architecture.name_en"
                              :placeholder="$t('label.nameEn')"
                              class="form-control"
                              type="text"
                            >
                            <template v-if="nameEnRequired.required">
                              <span class="label-error">{{ nameEnRequired.message }}</span>
                            </template>
                          </div>
                        </div>

                        <div
                          :class="genderRequired.required?'has-error':null"
                          class="form-group row"
                        >
                          <label class="col-sm-12 required">{{ $t('label.gender') }}</label>
                          <div class="col-sm-12">
                            <select
                              v-model="architecture.gender_id"
                              class="form-control"
                            >
                              <option value="1">
                                {{ $t('label.male') }}
                              </option>
                              <option value="2">
                                {{ $t('label.female') }}
                              </option>
                            </select>
                            <template v-if="genderRequired.required">
                              <span class="label-error">{{ genderRequired.message }}</span>
                            </template>
                          </div>
                        </div>

                        <!--<div class="form-group row"
                             :class="architectureRequired.required ? ' has-error' : null">
                          <label class="col-sm-12">{{ $t('label.type') }}</label>
                          <div class="col-sm-12">
                            <select class="form-control" v-model="architecture.architecture_type_id">
                              <option value="2">{{ $t('label.engineer') }}</option>
                              <option value="1">{{ $t('label.architecture') }}</option>
                            </select>
                            <template v-if="architectureRequired.required">
                              <span class="label-error">{{ architectureRequired.message }}</span>
                            </template>
                          </div>
                        </div>-->

                        <div class="form-group row">
                          <label class="col-sm-12">{{ $t('label.dob') }}</label>
                          <div class="col-sm-12">
                            <date-picker
                              v-model="architecture.dob"
                              :lang="datepickerLocale[$i18n.locale].lang"
                              :placeholder="$t('label.dob')"
                              class="table-full-width"
                              format="DD/MM/YYYY"
                              type="date"
                            />
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-12">{{ $t('label.email') }}</label>
                          <div class="col-sm-12">
                            <input
                              v-model="architecture.email"
                              :placeholder="$t('label.email')"
                              class="form-control"
                              type="email"
                            >
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-12">{{ $t('label.phone') }}</label>
                          <div class="col-sm-12">
                            <input
                              v-model="architecture.phone"
                              :placeholder="$t('label.phone')"
                              class="form-control"
                              type="tel"
                            >
                          </div>
                        </div>

                        <div class="form-group row">
                          <label class="col-sm-12">{{ $t('label.nationality') }}</label>
                          <div class="col-sm-12">
                            <select
                              v-model="architecture.nationality_id"
                              class="form-control"
                            >
                              <option value="">
                                {{ $t('string.none') }}
                              </option>
                              <option
                                v-for="(nationality, key) in nationalities"
                                :key="key"
                                :value="nationality.id"
                              >
                                {{ nationality['name_' + $i18n.locale] }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 col-md-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-section">
                      <div class="box-header with-border">
                        <h3 class="box-title">
                          {{ $t('string.aboutAddress') }}
                        </h3>
                      </div>
                      <div class="box-body">
                        <location-input
                          input-classes="col-sm-12"
                          label-classes="col-sm-12"
                        />

                        <div class="form-group row">
                          <label class="col-sm-12">{{ $t('label.avatar') }}</label>
                          <div class="col-sm-12">
                            <input
                              ref="avatar"
                              name="avatar"
                              type="file"
                            > <br>
                            <template v-if="architecture.avatar">
                              <render-image-tag
                                :src="architecture.avatar ? `${baseUrl}/${architecture.avatar}` : null"
                              />
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            ref="close"
            class="btn btn-default"
            data-dismiss="modal"
            type="button"
            @click="resetForm"
          >
            {{ $t('button.close') }}
          </button>
          <button
            class="btn btn-primary"
            type="button"
            @click="store"
          >
            {{ $t('button.save') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import {debounce} from 'debounce'
import LocationInput from "@/components/LocationInput"
import {validationMixins} from "@/mixins/validations"

export default {
  name: "ArchitectureModal",
  components: {LocationInput},
  mixins: [validationMixins],
  data() {
    return {
      architecture: {},
      validations: null,
      params: new FormData(),
      nationalities: [],
      universities: [],
    }
  },
  computed: {
    ...mapState({
      address: state => state.location.address,
      storage: state => state.project.data,
      modal: state => state.architecture.modal,
    })
  },
  watch: {
    'architecture.national_id_card'() {
      if (this.architecture.national_id_card !== '') {
        this.architecture.nationality_id = 33
      } else {
        this.architecture.nationality_id = ''
      }
    }
  },
  methods: {
    store() {
      this.$isLoading(true)
      this.prepareParams()
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/architecture/create', this.params)
        .then(() => {
          this.showToastr()
          this.$emit('fetchData')
          this.resetForm()
        })
        .catch((error) => {
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
            this.$store.commit('location/setValidations', res.data.errors)
          } else {
            this.$store.commit('location/setValidations', null)
            this.onResponseError(error)
            this.validations = null
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    prepareParams() {
      let dates = [
        'dob',
        'graduation_date',
        'registration_expired_date',
        'national_id_card_creation_date'
      ]
      for (let key in this.architecture) {
        if (dates.some(item => item === key)) {
          if (this.$moment(this.architecture[key]).isValid()) {
            let value = this.$moment(this.architecture[key]).locale('en').format('YYYY-MM-DD')
            this.params.set(key, value)
          }
        } else {
          let value = typeof (this.architecture[key]) === 'string' ? (this.architecture[key]).trim() : this.architecture[key]
          if (value === null || value === '') {
            this.params.delete(key)
          } else {
            // eslint-disable-next-line no-prototype-builtins
            if (value.hasOwnProperty('id')) {
              value = value.id
            }
            this.params.set(key, value)
          }
        }
      }
      this.params.set('architecture_type_id', this.modal.architecture_type_id)
      this.params.set('architecture_category_id', 3) // architecture category
      if (this.$refs.avatar.files.length > 0) {
        this.params.set('avatar', this.$refs.avatar.files[0])
      } else {
        this.params.delete('avatar')
      }
      for (let key in this.address) {
        if (this.address[key] !== '' && this.address[key] !== null) {
          this.params.set(key, this.address[key])
        } else {
          this.params.delete(key)
        }
      }
    },
    getNationalities() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/nationality/get-nationalities')
        .then((res) => {
          if (res.data.data) {
            this.nationalities = res.data.data
          } else {
            this.nationalities = []
          }
          this.$store.commit('project/setNationalities', this.nationalities)
        })
        .catch((error) => {
          this.onResponseError(error)
        })
    },
    getUniversities: debounce(function (query_text = '') {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/architecture/get-universities', {
        query_text: query_text,
      })
        .then((res) => {
          this.universities = res.data.data
        }).catch((error) => {
          this.onResponseError(error)
          this.$router.back()
        })
    }, 200),
    resetForm() {
      this.validations = null
      this.$refs.close.click()
      this.architecture = {}
      for (let key of this.params.keys()) {
        this.params.delete(key)
      }
    }
  },
  created() {
    this.getUniversities()
    if (this.storage.nationalities.length > 0) {
      this.nationalities = this.storage.nationalities
    } else {
      this.getNationalities()
    }
  }
}
</script>

<style scoped>

</style>
