<template>
  <router-view />
</template>

<script>
import {mapActions} from "vuex"

export default {
  name: "ProjectLevelProvince",
  mounted() {
    this.setIsProvinceDataLevel(true)
  },
  beforeDestroy() {
    this.setIsProvinceDataLevel(false)
  },
  methods: {
    ...mapActions('project', {
      setIsProvinceDataLevel: 'setIsProvinceDataLevel'
    })
  }
}
</script>

<style scoped>

</style>
