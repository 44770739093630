<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.newRoubavontbokkols') }}
      </h3>
    </div>
    <div class="box-body">
      <roubavontbokkols-from />
    </div>
  </div>
</template>

<script>
import RoubavontbokkolsFrom from "./Form"

export default {
  name: "Create",
  components: {RoubavontbokkolsFrom},
  metaInfo() {
    return {
      title: this.$t('string.newRoubavontbokkols'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
}
</script>

<style scoped>

</style>
