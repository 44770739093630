const state = {
  modal: {
    building_id: null,
  }
}

const getters = {}

const mutations = {
  setBuildingId(state, id) {
    state.modal.building_id = id
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
