<template>
  <div class="row">
    <div :class="containerClass">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ title }}
          </h3>
          <div class="box-tools">
            <template v-if="can($Permissions.backend.team.edit)">
              <button
                v-if="showForm"
                class="btn btn-primary btn-sm margin-right-5"
                @click="$router.push({name: 'edit-team',params: {team_uuid: $route.params.team_uuid}})"
              >
                <i class="fa fa-edit" /> {{ $t('button.edit') }}
              </button>
            </template>
            <asora-back-button />
          </div>
        </div>
        <div class="box-body">
          <div class="form-horizontal">
            <div
              :class="nameKmValidation.required? ' has-error': null"
              class="form-group row"
            >
              <label
                :class="labelClasses"
                class="required"
              >
                {{ $t('label.team_name_km') }}
              </label>
              <div :class="inputClasses">
                <input
                  v-model="team.name_km"
                  :disabled="showForm"
                  :placeholder="$t('label.team_name_km')"
                  class="form-control"
                  type="text"
                >
                <template v-if="nameKmValidation.required">
                  <span class="label-error">{{ nameKmValidation.message }}</span>
                </template>
              </div>
            </div>

            <div
              :class="nameEnValidation.required? ' has-error': null"
              class="form-group row"
            >
              <label
                :class="labelClasses"
                class="required"
              >{{ $t('label.team_name_en') }}</label>
              <div :class="inputClasses">
                <input
                  v-model="team.name_en"
                  :disabled="showForm"
                  :placeholder="$t('label.team_name_en')"
                  class="form-control"
                  type="text"
                >
                <template v-if="nameEnValidation.required">
                  <span class="label-error">{{ nameEnValidation.message }}</span>
                </template>
              </div>
            </div>

            <div
              :class="teamCategoryValidation.required? ' has-error': null"
              class="form-group row"
            >
              <label
                :class="labelClasses"
                class="required"
              >{{ $t('label.teamCategory') }}</label>
              <div :class="inputClasses">
                <select
                  v-if="teamCategories.length>0"
                  v-model="team.team_category_id"
                  :disabled="showForm"
                  class="form-control"
                >
                  <option
                    v-for="(category, key) in teamCategories"
                    :key="key"
                    :value="category.id"
                  >
                    {{ category['name_' + $i18n.locale] }}
                  </option>
                </select>
                <template v-if="teamCategoryValidation.required">
                  <span class="label-error">{{ teamCategoryValidation.message }}</span>
                </template>
              </div>
            </div>

            <div
              :class="leaderValidation.required? ' has-error' : null"
              class="form-group row"
            >
              <label :class="`${labelClasses} required`">{{ $t('label.teamLeader') }}</label>
              <div :class="inputClasses">
                <multiselect
                  v-if="!showForm"
                  v-model="team.leader"
                  :disabled="showForm"
                  :label="`name_${$i18n.locale}`"
                  :options="officers"
                  :placeholder="$t('string.chooseTeamLeader')"
                />
                <template v-if="leaderValidation.required">
                  <span class="label-error">{{ leaderValidation.message }}</span>
                </template>
              </div>
              <template v-if="team.leader && team.leader !== null">
                <div
                  :class="officerChipContainer"
                  class="margin-top-10"
                >
                  <people-chip-item
                    :people="{
                      name_en: team.leader.name_en,
                      name_km: team.leader.name_km,
                      uuid: team.leader.uuid,
                      avatar: team.leader.avatar,
                      phone: team.leader.phone,
                      email: team.leader.email,
                      id_card: null,
                    }"
                    model="officer"
                  />
                </div>
              </template>
            </div>

            <div
              :class="officerValidation.required? ' has-error':null"
              class="form-group row"
            >
              <label
                :class="labelClasses"
                class="required"
              >{{ $t('label.member') }}</label>
              <div :class="`${inputClasses} margin-bottom`">
                <multiselect
                  v-if="!showForm"
                  v-model="team.members"
                  :disabled="showForm"
                  :label="`name_${$i18n.locale}`"
                  :multiple="true"
                  :options="officers"
                  :placeholder="$t('string.chooseMembers')"
                  track-by="id"
                />
                <template v-if="officerValidation.required">
                  <span class="label-error">{{ officerValidation.message }}</span>
                </template>
              </div>
              <template v-if="team.members && team.members.length>0">
                <div :class="officerChipContainer">
                  <people-chip-item
                    v-for="(officer, key) in team.members"
                    :key="key"
                    :people="{
                      name_en: officer.name_en,
                      name_km: officer.name_km,
                      uuid: officer.uuid,
                      avatar: officer.avatar,
                      phone: officer.phone,
                      email: officer.email,
                      id_card: null,
                    }"
                    model="officer"
                  />
                </div>
              </template>
            </div>

            <div
              :class="provinceValidation.required? ' has-error' : null"
              class="form-group row"
            >
              <label :class="`${labelClasses} required`">{{ $t('table.responseProvinces') }}</label>
              <div :class="inputClasses">
                <multiselect
                  v-model="team.provinces"
                  :disabled="showForm"
                  :label="`name_${$i18n.locale}`"
                  :multiple="true"
                  :options="provinces"
                  :placeholder="$t('label.provinces')"
                />
                <template v-if="provinceValidation.required">
                  <span class="label-error">{{ provinceValidation.message }}</span>
                </template>
              </div>
            </div>

            <div class="form-group row">
              <label :class="labelClasses">{{ $t('label.teamReferenceNumber') }}</label>
              <div :class="inputClasses">
                <input
                  v-model="team.reference_number"
                  :disabled="showForm"
                  :placeholder="$t('label.teamReferenceNumber')"
                  class="form-control"
                  type="text"
                >
              </div>
            </div>

            <div class="form-group row">
              <label :class="labelClasses">{{ $t('label.description') }}</label>
              <div :class="inputClasses">
                <textarea
                  v-model="team.description"
                  :disabled="showForm"
                  :placeholder="$t('label.description')"
                  class="form-control"
                  rows="5"
                />
              </div>
            </div>

            <div
              v-if="!showForm"
              class="form-group row"
            >
              <div :class="buttonClasses">
                <template v-if="button.handle==='create'">
                  <button
                    :class="`btn btn-primary btn-sm`"
                    @click="storeTeam"
                  >
                    {{ button.label }}
                  </button>
                </template>
                <template v-else>
                  <button
                    :class="`btn btn-primary btn-sm`"
                    @click="storeTeam"
                  >
                    {{ button.label }}
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="edit || showForm">
      <div class="col-md-6">
        <upload-media
          :model-uuid="$route.params.team_uuid"
          :readonly="showForm"
          button-icon="far fa-file-alt"
          :button-label="$t('label.chooseReference')"
          model-name="team"
          preview-column="col-xs-6 margin-bottom"
        />
      </div>
    </template>
  </div>
</template>

<script>
import UploadMedia from "../../components/UploadMedia"
import PeopleChipItem from "../../components/PeopleChipItem"

export default {
  name: "TeamForm",
  components: {
    PeopleChipItem,
    UploadMedia,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    button: {
      type: Object,
      required: true
    },
    labelClasses: {
      type: String,
      default: 'col-sm-3 control-label'
    },
    inputClasses: {
      type: String,
      default: 'col-sm-9'
    },
    buttonClasses: {
      type: String,
      default: 'col-sm-9 col-sm-offset-3'
    },
  },
  data() {
    return {
      team: {},
      validations: null,
      params: new FormData(),
      officers: [],
      provinces: [],
      teamCategories: [],
    }
  },
  computed: {
    nameEnValidation() {
      return this.getValidationColumn(this.validations, 'name_en')
    },
    nameKmValidation() {
      return this.getValidationColumn(this.validations, 'name_km')
    },
    teamCategoryValidation() {
      return this.getValidationColumn(this.validations, 'team_category_id')
    },
    officerValidation() {
      return this.getValidationColumn(this.validations, 'member_ids')
    },
    provinceValidation() {
      return this.getValidationColumn(this.validations, 'province_ids')
    },
    leaderValidation() {
      return this.getValidationColumn(this.validations, 'leader_id')
    },
    containerClass() {
      return this.$route.name === 'create-team' ? 'col-md-12' : 'col-md-6'
    },
    edit() {
      return this.$route.name === 'edit-team'
    },
    showForm() {
      return this.$route.name === 'show-team'
    },
    officerChipContainer() {
      return this.$route.name === 'create-team' ? 'col-md-offset-3 col-md-9' : 'col-md-12'
    },
    urlAction() {
      return this.edit ? 'update' : 'create'
    }
  },
  created() {
    this.getTeamCategories()
    this.getProvince()
    this.getOfficers()
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty('team_uuid')) {
      this.getTeam()
    }
  },
  methods: {
    async getProvince() {
      const response = await this.$axios.post(process.env.VUE_APP_API + '/api/backend/location/get-location')
      this.provinces = await response.data.data
    },
    storeTeam() {
      if (this.team.uuid) {
        this.team.team_uuid = this.team.uuid
      }
      // leader
      // eslint-disable-next-line no-prototype-builtins
      if (this.team.hasOwnProperty('leader') && this.team.leader !== null) {
        this.team.leader_id = this.team.leader.id
      }
      // provinces
      // eslint-disable-next-line no-prototype-builtins
      if (this.team.hasOwnProperty('provinces') && this.team.provinces.length > 0) {
        this.team.province_ids = this.team.provinces.map(province => province.id)
      }
      // members
      // eslint-disable-next-line no-prototype-builtins
      if (this.team.hasOwnProperty('members') && this.team.members.length > 0) {
        this.team.member_ids = this.team.members.map(officer => officer.id)
      }
      this.$isLoading(true)
      this.$axios.post(`${process.env.VUE_APP_API}/api/backend/team/${this.urlAction}`, this.team)
        .then(() => {
          this.showToastr()
          this.$router.push({name: 'list-team'})
        })
        .catch((error) => {
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
            this.$store.commit('location/setValidations', res.data.errors)
          } else {
            this.$store.commit('location/setValidations', null)
            this.validations = null
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getTeam() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/team/show', {
        team_uuid: this.$route.params.team_uuid,
      }).then((res) => {
        this.team = res.data.data
        if (this.team.response_provinces.length > 0) {
          this.$set(this.team, 'provinces', this.team.response_provinces.map(province => province))
        }
        if (this.team.leader.length > 0) {
          let leader = this.team.leader[0]
          this.$set(this.team, 'leader', {
            id: leader.id,
            name_en: `${leader.name_en}`,
            name_km: `${leader.name_km}`,
            avatar: leader.avatar,
            uuid: leader.uuid,
            email: leader.email,
            phone: leader.phone,
            id_card: null,
          })
        }
        if (this.team.members.length > 0) {
          this.$set(this.team, 'members', this.team.members.map((officer) => {
            return {
              name_en: `${officer.name_en}`,
              name_km: `${officer.name_km}`,
              id: officer.id,
              avatar: officer.avatar,
              uuid: officer.uuid,
              email: officer.email,
              phone: officer.phone,
              id_card: null,
            }
          }))
        } else {
          this.team.members = []
        }
      }).catch((error) => {
        this.onResponseError(error)
      })
    },
    getOfficers() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/officer/get-officers')
        .then((res) => {
          if (res.data.data) {
            this.officers = res.data.data
          } else {
            this.officers = []
          }
        }).catch((error) => {
          this.onResponseError(error)
        })
    },
    getTeamCategories() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/team-category/get-option')
        .then((res) => {
          if (res.data.data) {
            this.teamCategories = res.data.data
          } else {
            this.teamCategories = []
          }
        }).catch((error) => {
          this.onResponseError(error)
        })
    }
  }
}
</script>

<style scoped>

</style>
