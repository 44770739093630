<template>
  <div>
    <div class="row tw-space-y-3">
      <div class="col-xs-12">
        <div class="tw-flex tw-justify-between tw-items-center">
          <h3 class="tw-text-2xl tw-font-bold tw-m-0 tw-p-0">
            {{ $t('string.manage_construction_license') }}
          </h3>
          <div class="tw-space-x-2">
            <button
              class="btn btn-default btn-sm"
              :disabled="!selectedLicense"
              @click="onPrint"
            >
              <i class="fas fa-print" /> {{ $t('button.print') }}
            </button>
            <button
              class="btn btn-primary btn-sm"
              data-toggle="modal"
              data-target="#constructionLicenseModal"
              @click="onAddNewCertifier"
            >
              <i class="fa fa-plus-circle" /> {{ $t('button.add') }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-xs-12">
        <ConstructionCertifierLicenseList
          ref="constructionCertifierLicenseList"
        />
      </div>
    </div>
    <ConstructionLicenseModal
      @refreshList="refreshList"
    />
    <ApproveConstructionLicenseModal
      @refreshList="refreshList"
    />
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields"
import ConstructionLicenseModal from "@/views/company/includes/ConstructionLicenseModal"
import ConstructionCertifierLicenseList from "@/views/company/includes/ConstructionCertifierLicenseList"
import ApproveConstructionLicenseModal from "@/views/company/includes/ApproveConstructionLicenseModal"

export default {
  name: "ManageConstructionLicense",
  components: {ApproveConstructionLicenseModal, ConstructionCertifierLicenseList, ConstructionLicenseModal},
  computed: {
    ...mapFields('company', {
      companyUuid: 'constructionCertifier.companyUuid',
      selectedLicense: 'constructionCertifier.selectedLicense',
      editLicenseUuid: 'constructionCertifier.editLicenseUuid',
    })
  },
  beforeDestroy() {
    this.companyUuid = null
    this.selectedLicense = null
    this.editLicenseUuid = null
  },
  methods: {
    onAddNewCertifier () {
      this.companyUuid = this.$route.params.company_uuid
    },
    refreshList () {
      this.$refs.constructionCertifierLicenseList.getConstructionCertifierLicenseList()
    },
    onPrint () {
      if (this.selectedLicense && this.selectedLicense.uuid) {
        window.open(`${this.baseUrl}/certificates/construction-certifier-license/print/${this.selectedLicense.uuid}`, '_blank')
      }
    }
  }
}
</script>

<style scoped>

</style>
