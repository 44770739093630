import Position from "./../../views/settings/position/Index"
import List from "./../../views/settings/position/List"
import Create from "./../../views/settings/position/Create"
import Edit from "./../../views/settings/position/Edit"
import Show from "./../../views/settings/position/Show"
import Permissions from "../../permissions"

export const positionRouters = {
  path: 'position',
  component: Position,
  redirect: 'position',
  meta: {
    permissions: [Permissions.backend.setting.position.manage],
    groups: 'position',
  },
  children: [
    {
      path: '',
      name: 'list-position',
      component: List,
      meta: {
        permissions: [Permissions.backend.setting.position.list],
        groups: 'position',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Position',
            name_km: 'មុខតំណែង'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-position',
      component: Create,
      meta: {
        permissions: [Permissions.backend.setting.position.store],
        groups: 'position',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'New Position',
            name_km: 'មុខតំណែងថ្មី'
          }
        ]
      }
    },
    {
      path: 'edit/:position_uuid',
      name: 'edit-position',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.setting.position.edit],
        groups: 'position',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Edit Position',
            name_km: 'កែប្រែមុខតំណែង'
          }
        ]
      }
    },
    {
      path: 'show/:position_uuid',
      name: 'show-position',
      component: Show,
      meta: {
        permissions: [Permissions.backend.setting.position.show],
        groups: 'position',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Show Position',
            name_km: 'បង្ហាញព័ត៌មានមុខតំណែង'
          }
        ]
      }
    }
  ]
}
