<template>
  <inspection-show />
</template>

<script>
import InspectionShow from "../sections/InspectionShow"
export default {
  name: "Show",
  components: {InspectionShow}
}
</script>

<style scoped>

</style>
