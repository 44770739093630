<template>
  <div
    class="form-group row"
    :class="{'has-error' : validator}"
  >
    <label :class="`${labelClass} ${required ? 'required': ''}`">{{ label }}</label>
    <div :class="inputWrapperClass">
      <select
        v-bind="$attrs"
        class="form-control"
        @change="$emit('input', $event.target.value)"
      >
        <option
          v-for="(item, key) in options"
          :key="key"
          :value="item.id"
        >
          <template v-if="item.label">
            {{ item.label }}
          </template>
          <template v-else>
            {{ item['name_' + $i18n.locale] }}
          </template>
        </option>
      </select>
      <template v-if="validator">
        <span class="label-error">{{ validator[0] }}</span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "XSelect",
  inheritAttrs: true,
  props: {
    label: {
      type: String,
      required: true
    },
    validator: {
      type: Array,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    labelClass: {
      type: String,
      default: 'col-sm-4 control-label'
    },
    inputWrapperClass: {
      type: String,
      default: 'col-sm-8'
    },
    options: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style scoped>

</style>
