var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn btn-primary btn-sm dropdown-toggle margin-right-5",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" "+_vm._s(_vm.$t('string.action'))+" "),_c('span',{staticClass:"caret"})]),_c('ul',{staticClass:"dropdown-menu"},[_c('li',[_c('router-link',{attrs:{"to":{
          name: 'first-registration-register-company',
          params: {
            company_uuid: _vm.companyUuid
          }
        }}},[_vm._v(" "+_vm._s(_vm.$t('string.requesting_for_first_registration'))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
          name: 'renew-license-register-company',
          params: {
            company_uuid: _vm.companyUuid
          }
        }}},[_vm._v(" "+_vm._s(_vm.$t('string.requesting_renew_license'))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
          name: 'up-downgrade-type-register-company',
          params: {
            company_uuid: _vm.companyUuid
          }
        }}},[_vm._v(" "+_vm._s(_vm.$t('string.requesting_upgrade_type'))+" ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
          name: 'up-downgrade-type-register-company',
          params: {
            company_uuid: _vm.companyUuid
          }
        }}},[_vm._v(" "+_vm._s(_vm.$t('string.requesting_downgrade_type'))+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }