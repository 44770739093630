<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('label.landUniqueNumberType') }}
      </h3>
      <div class="box-tools">
        <button
          v-if="can($Permissions.backend.setting.landUniqueNumberType.store)"
          class="btn btn-primary btn-sm"
          @click="$router.push({name: 'create-land-unique-number-type'})"
        >
          <i class="fa fa-plus" /> {{ $t('button.newLandUniqueNumberType') }}
        </button>
      </div>
    </div>
    <div class="box-body">
      <div>
        <table
          id="land-unique-number-type"
          class="table table-hover table-full-width table-striped table-bordered"
        >
          <thead>
            <tr>
              <th>{{ $t('table.nameInKhmer') }}</th>
              <th>{{ $t('table.nameInEnglish') }}</th>
              <th>{{ $t('table.createdAt') }}</th>
              <th>{{ $t('table.action') }}</th>
            </tr>
          </thead>
          <tbody />
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'List',
  metaInfo() {
    return {
      title: this.$t('menu.landUniqueNumberType'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      oTable: null
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.refreshTable()
      this.oTable.draw(true)
    }
  },
  mounted() {
    this.oTable = this.refreshTable()
    this.enabledAction()
  },
  beforeDestroy() {
    this.oTable.clear()
    this.oTable.destroy()
  },
  methods: {
    refreshTable() {
      let self = this
      return $('#land-unique-number-type').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: process.env.VUE_APP_API + '/api/backend/land-unique-number-type/datatable',
          data: (d) => {
            d.edit = this.$t('label.edit')
            d.show = this.$t('label.show')
            d.delete = this.$t('label.delete')
          },
          error: (xhr) => {
            self.onResponseError(xhr)
          }
        },
        columns: [
          {data: 'name_km', name: 'name_km', title: this.$t('table.nameInKhmer')},
          {data: 'name_en', name: 'name_en', title: this.$t('table.nameInEnglish')},
          {
            data: 'created_at',
            name: 'created_at',
            orderable: true,
            searchable: false,
            title: this.$t('table.createdAt'),
            render: (data) => {
              return this.getDate(data)
            }
          },
          {
            data: 'action',
            name: 'action',
            orderable: false,
            searchable: false,
            title: this.$t('table.action'),
          }
        ],
        language: {
          url: self.getLocale,
        },
        order: [
          [2, 'desc']
        ]
      })
    },
    enabledAction() {
      let self = this
      this.clearEventHandler([
        '.btn-edit',
        '.btn-show',
        '.btn-trash',
      ])
      $(document).on('click', '.btn-edit', function () {
        self.$router.push({
          name: 'edit-land-unique-number-type',
          params: {
            land_unique_number_type_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-show', function () {
        self.$router.push({
          name: 'show-land-unique-number-type',
          params: {
            land_unique_number_type_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-trash', function () {
        self.deleteRecord($(this).attr('data-uuid'))
      })
    },
    deleteRecord(uuid) {
      let self = this
      this.$swal.fire({
        title: self.i18nSwalTitle,
        text: self.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: self.i18nSwalNo,
        confirmButtonText: self.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/land-unique-number-type/delete', {
            land_unique_number_type_uuid: uuid
          }).then(() => {
            this.showSwalSuccess()
            this.oTable.draw(true)
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
