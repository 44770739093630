<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.changeCompanyCategory') }}
      </h3>
      <div class="box-tools">
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <div class="form-horizontal">
        <div
          class="form-group row"
          :class="categoryValidation.required?'has-error': null"
        >
          <label class="col-md-3 control-label">{{ $t('string.category') }}</label>
          <div class="col-md-9">
            <multiselect
              v-model="values"
              :multiple="true"
              track-by="id"
              :label="`name_` + $i18n.locale"
              :placeholder="$t('string.chooseCategory')"
              :options="categories"
            >
              <template slot="noOptions">
                {{ $t('string.noOptions') }}
              </template>
              <template slot="noResult">
                {{ $t('string.noResult') }}
              </template>
            </multiselect>
            <template v-if="categoryValidation.required">
              <span class="label-error">{{ categoryValidation.message }}</span>
            </template>
          </div>
        </div>
        <div class="form-group row">
          <div class="col-md-offset-3 col-md-9">
            <button
              class="btn btn-info btn-md"
              @click="update"
            >
              {{ $t('string.update') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangeCategory",
  metaInfo() {
    return {
      title: this.$t('string.changeCompanyCategory'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      categories: [],
      validations: null,
      values: [],
    }
  },
  computed: {
    categoryValidation() {
      return this.getValidationColumn(this.validations, 'values')
    }
  },
  created() {
    this.getCategories()
    this.getCompanyCategories()
  },
  methods: {
    update() {
      if (!this.values.length > 0) {
        this.values = []
      }
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/company/update-company-categories', {
        company_uuid: this.$route.params.company_uuid,
        values: this.values.map(category => category.id),
      })
        .then(() => {
          this.showToastr()
          this.$router.back()
        })
        .catch((error) => {
          this.validations = null
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getCategories() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/company/get-categories')
        .then((response) => {
          this.categories = response.data.data
        })
    },
    getCompanyCategories() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/company/get-company-categories', {
        company_uuid: this.$route.params.company_uuid,
      })
        .then((response) => {
          this.values = response.data.data
        })
        .catch((error) => {
          this.onResponseError(error)
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  }
}
</script>

<style scoped>

</style>
