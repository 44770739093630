<template>
  <div class="user">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t('label.list.user') }}
        </h3>
        <div class="pull-right box-tools">
          <router-link
            v-if="can($Permissions.backend.administration.user.store)"
            :to="{name: 'create-user'}"
            class="btn btn-primary btn-sm"
          >
            <i class="fa fa-plus" /> {{ $t('button.newUser') }}
          </router-link>
        </div>
      </div>
      <div class="box-body">
        <div>
          <table
            id="user-table"
            class="table table-hover table-full-width table-striped table-bordered"
          >
            <thead>
              <tr>
                <th>{{ $t('table.name') }}</th>
                <th>{{ $t('table.username') }}</th>
                <th>{{ $t('table.gender') }}</th>
                <th>{{ $t('table.confirmed') }}</th>
                <th>{{ $t('table.role') }}</th>
                <th>{{ $t('table.createdAt') }}</th>
                <th>{{ $t('table.action') }}</th>
              </tr>
            </thead>
            <tbody />
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'List',
  metaInfo() {
    return {
      title: this.$t('string.userList'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      oTable: null
    }
  },
  computed: {
    columns() {
      return [
        {data: 'name', name: 'name', title: this.$t('table.name')},
        {data: 'username', name: 'username', title: this.$t('table.username')},
        {data: 'gender', name: 'gender', title: this.$t('table.gender')},
        {data: 'confirmed', name: 'confirmed', title: this.$t('table.confirmed'), searchable: false},
        {data: 'role', name: 'role', title: this.$t('table.role'), searchable: true, orderable: false},
        {
          data: 'created_at',
          name: 'created_at',
          width: '150px',
          searchable: false,
          title: this.$t('table.createdAt'),
          render: (data) => {
            return this.getDate(data)
          }
        },
        {
          data: 'action',
          name: 'action',
          width: '150px',
          orderable: false,
          searchable: false,
          title: this.$t('table.action')
        }
      ]
    }
  },
  watch: {
    '$i18n.locale': function () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.refreshTable()
      this.oTable.draw(true)
    }
  },
  mounted() {
    this.oTable = this.refreshTable()
    this.loadAction()
  },
  methods: {
    refreshTable() {
      let self = this
      return $('#user-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: process.env.VUE_APP_API + '/api/backend/user/datatable',
          data: (d) => {
            d.active = self.i18nActive
            d.edit = self.i18nEdit
            d.show = self.i18nShow
            d.delete = self.i18nDelete
            d.deactive = self.i18nDeactive
            d.changePassword = self.i18nChangePassword
            d.language = self.$i18n.locale || 'en'
          },
          error: (xhr) => {
            self.onResponseError(xhr)
          }
        },
        columns: self.columns,
        language: {
          url: `/locale/${self.$i18n.locale}.json`
        },
        fixedColumns: true,
        order: [
          [5, 'desc']
        ]
      })
    },
    deleteUser(uuid) {
      let self = this
      this.$swal.fire({
        title: self.i18nSwalTitle,
        text: self.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: self.i18nSwalNo,
        confirmButtonText: self.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/user/delete', {
            uuid: uuid
          }).then(() => {
            this.showSwalSuccess()
            this.oTable.draw(true)
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
    toggleUserAccess(uuid) {
      this.$swal.fire({
        title: this.$t('string.areYouSure?'),
        text: this.$t('string.theActionWillToggleUserAccess'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('string.yesIWish!')
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/user/toggle', {
            uuid: uuid
          }).then(() => {
            this.showToastr()
            this.oTable.draw(true)
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
    loadAction() {
      let self = this
      this.clearEventHandler([
        '.btn-delete-user',
        '.btn-toggle-user',
        '.btn-show-user',
        '.btn-edit-user',
        '.btn-reset-password-user'
      ])
      $(function () {
        $(document).on('click', '.btn-delete-user', function (e) {
          e.preventDefault()
          self.deleteUser($(this).attr('data-uuid'))
        })
        $(document).on('click', '.btn-toggle-user', function (e) {
          e.preventDefault()
          self.toggleUserAccess($(this).attr('data-uuid'))
        })
        $(document).on('click', '.btn-show-user', function (e) {
          e.preventDefault()
          self.$router.push({
            name: 'show-user',
            params: {
              uuid: $(this).attr('data-uuid')
            }
          })
        })
        $(document).on('click', '.btn-edit-user', function (e) {
          e.preventDefault()
          self.$router.push({
            name: 'edit-user',
            params: {
              uuid: $(this).attr('data-uuid')
            }
          })
        })
        $(document).on('click', '.btn-reset-password-user', function (e) {
          e.preventDefault()
          self.$router.push({
            name: 'reset-password',
            params: {
              uuid: $(this).attr('data-uuid')
            }
          })
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
