<template>
  <div
    v-if="note"
    class="row"
  >
    <div class="col-md-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('string.showRoubavontbokkolNote') }}
          </h3>
          <div class="box-tools">
            <router-link
              class="btn btn-primary btn-sm margin-right-5"
              :to="{
                name: 'edit-note-roubavontbokkols',
                params: {roubavontbokkols_uuid: $route.params.roubavontbokkols_uuid}
              }"
            >
              <i class="fa fa-edit" />
            </router-link>
            <ButtonBack />
          </div>
        </div>
        <div class="box-body">
          <table class="table table-bordered table-striped">
            <tbody>
              <tr>
                <td>{{ $t('string.roubavontbokkol') }}</td>
                <td>
                  <div
                    @click="onClickOpenNewTab(
                      $router.push({
                        name: 'show-professional-study-planner',
                        params: {architecture_uuid: note.architecture.uuid}})
                    )"
                  >
                    <a href="javascript:void(0)">
                      {{ note.architecture ? `${note.architecture['name_' + $i18n.locale]}` : 'N/A' }}
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td>{{ $t('string.registration_number') }}</td>
                <td>{{ note.architecture ? note.architecture.registration_number : 'N/A' }}</td>
              </tr>
              <tr>
                <td>{{ $t('string.unique_number') }}</td>
                <td>{{ note.architecture ? note.architecture.unique_number : 'N/A' }}</td>
              </tr>
              <tr>
                <td>{{ $t('string.owner_requested_at') }}</td>
                <td>{{ getDate(note.owner_requested_at, false, 'DD/MM/YYYY') }}</td>
              </tr>
              <tr>
                <td>{{ $t('label.createdAt') }}</td>
                <td>{{ getDate(note.created_at, false) }}</td>
              </tr>
              <tr>
                <td>{{ $t('label.updatedAt') }}</td>
                <td>{{ getDate(note.updated_at, false) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      v-if="Array.isArray(note.references)"
      class="col-md-12"
    >
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('string.references') }}
          </h3>
        </div>
        <div class="box-body">
          <ul v-if="note.references && Array.isArray(note.references) && note.references.length > 0">
            <li
              v-for="(item, key) in note.references"
              :key="key"
            >
              {{ item.value }}
            </li>
          </ul>
          <template v-else>
            <p class="text-muted text-center">
              {{ $t('string.noteNoContent') }}
            </p>
          </template>
        </div>
      </div>
    </div>
    <div
      v-if="Array.isArray(note.officer_comments)"
      class="col-md-12"
    >
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('string.officerComments') }}
          </h3>
        </div>
        <div class="box-body">
          <ul v-if="note.officer_comments && Array.isArray(note.officer_comments) && note.officer_comments.length > 0">
            <li
              v-for="(item, key) in note.officer_comments"
              :key="key"
            >
              {{ item.value }}
            </li>
          </ul>
          <template v-else>
            <p class="text-center text-muted">
              {{ $t('string.commandsNoContent') }}
            </p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonBack from "@/components/ButtonBack"

export default {
  name: "RoubavontbokkolsNoteInformation",
  components: {ButtonBack},
  props: {
    note: {
      type: Object,
      default: null,
    }
  },
}
</script>

<style scoped>

</style>
