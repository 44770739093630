<template>
  <footer class="main-footer">
    <div class="pull-right hidden-xs">
      អភិវឌ្ឍន៍ដោយ <a
        href="https://asorasoft.com"
        target="_blank"
      >Asorasoft.com</a>
    </div>
    &copy; រក្សាសិទ្ធិដោយ <strong>អគ្គនាយកដ្ឋានសំណង់</strong>
  </footer>
</template>

<script>
export default {
  name: "AsoraFooter",
  data() {
    return {
      appName: process.env.VUE_APP_COMPANY_NAME,
      appUrl: process.env.VUE_APP_COMPANY_URL,
    }
  }
}
</script>

<style scoped>

</style>
