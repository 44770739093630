import Permissions from "../../permissions"
import Index from '../../views/noneBoard/Index'
import {noneBoardArchitectureRouters} from "./architecture"
import {noneBoardEngineerRouters} from "./engineer"

export const noneBoardRouters = {
  path: 'none-board',
  name: 'none-board',
  component: Index,
  meta: {
    permissions: [Permissions.backend.noneBoard.manage],
    groups: 'none-board',
  },
  children: [
    noneBoardArchitectureRouters,
    noneBoardEngineerRouters,
  ]
}
