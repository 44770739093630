import Activity from "./../../views/settings/activities/Index"
import List from "./../../views/settings/activities/List"
import Show from "./../../views/settings/activities/Show"
import Permissions from "../../permissions"

export const activitiesRouters = {
  path: 'activity',
  component: Activity,
  redirect: 'activity',
  meta: {
    permissions: [Permissions.backend.setting.activityLogs.manage],
    groups: 'activity',
  },
  children: [
    {
      path: '',
      name: 'list-activity',
      component: List,
      meta: {
        permissions: [Permissions.backend.setting.activityLogs.list],
        groups: 'activity',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Activity Logs',
            name_km: 'Activity Logs'
          },
          {
            name_en: 'List',
            name_km: 'List'
          }
        ]
      }
    },
    {
      path: 'show/:id',
      name: 'show-activity',
      component: Show,
      meta: {
        permissions: [Permissions.backend.setting.activityLogs.show],
        groups: 'activity',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Activity Logs',
            name_km: 'Activity Logs'
          },
          {
            name_en: 'Show',
            name_km: 'Show'
          }
        ]
      }
    },
  ]
}
