<template>
  <div class="col-md-12">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t('string.constructionHasProblem') }}
        </h3>
      </div>
      <div class="box-body">
        <highcharts :options="chartOptions" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConstructHasProblem',
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'column'
        },

        title: {
          text: this.$t('string.constructionHasProblem'),
        },

        xAxis: {
          categories: [],
          labels: {
            x: -10
          }
        },

        yAxis: {
          title: {
            text: this.$t('string.total'),
          }
        },

        series: [
          {
            name: this.$t('string.noPermit'),
            data: []
          },
          {
            name: this.$t('string.openPermit'),
            data: []
          },
          {
            name: this.$t('string.stopPermit'),
            data: []
          }
        ]
      }
    }
  },
  watch: {
    "$i18n.locale" () {
      this.chartOptions.title.text = this.$t('string.constructionHasProblem')
      this.chartOptions.yAxis.title.text = this.$t('string.total')
      this.chartOptions.series[0].name = this.$t('string.noPermit')
      this.chartOptions.series[1].name = this.$t('string.openPermit')
      this.chartOptions.series[2].name = this.$t('string.stopPermit')
    }
  },
  created() {
    this.getProjectsByPermit()
  },
  methods: {
    getProjectsByPermit() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/report/get-projects-by-permit')
        .then((res) => {
          let data = res.data.data
          this.chartOptions.xAxis.categories = data['categories']
          this.chartOptions.series[0]['data'] = data['no_permits']
          this.chartOptions.series[1]['data'] = data['open_permits']
          this.chartOptions.series[2]['data'] = data['stop_permits']
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  }
}
</script>

<style>
</style>
