<template>
  <div class="reset-password">
    <div class="row">
      <div class="col-md-12">
        <form
          method="POST"
          action="/"
        >
          <div
            class="form-group row"
            :class="{'has-error' : validations.old_password}"
          >
            <label
              for="email"
              class="col-md-4 col-form-label text-md-right"
            >{{ $t('label.oldPassword') }}</label>

            <div class="col-md-6">
              <input
                id="email"
                v-model="user.old_password"
                type="password"
                :placeholder="$t('label.oldPassword')"
                class="form-control"
                name="email"
                autofocus
              >
              <span
                v-if="validations.old_password"
                class="help-block"
                role="alert"
              >
                <strong>{{ validations['old_password'][0] }}</strong>
              </span>
            </div>
          </div>

          <div
            class="form-group row"
            :class="{'has-error' : validations.password}"
          >
            <label
              for="password"
              class="col-md-4 col-form-label text-md-right"
            >{{ $t('label.password') }}</label>

            <div class="col-md-6">
              <input
                id="password"
                v-model="user.password"
                type="password"
                :placeholder="$t('label.password')"
                class="form-control"
                name="password"
              >
              <span
                v-if="validations.password"
                class="help-block"
                role="alert"
              >
                <strong>{{ validations['password'][0] }}</strong>
              </span>
            </div>
          </div>

          <div
            class="form-group row"
            :class="{'has-error' : validations.password}"
          >
            <label
              for="password-confirm"
              class="col-md-4 col-form-label text-md-right"
            >{{ $t('label.confirmPassword') }}</label>
            <div class="col-md-6">
              <input
                id="password-confirm"
                v-model="user.password_confirmation"
                type="password"
                :placeholder="$t('label.confirmPassword')"
                class="form-control"
                name="password_confirmation"
              >
              <span
                v-if="validations.password"
                class="help-block"
                role="alert"
              >
                <strong>{{ validations['password'][0] }}</strong>
              </span>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-4" />
            <div class="col-md-6">
              <button
                type="submit"
                class="btn btn-primary"
                @click="resetPassword"
              >
                {{ $t('button.resetPassword') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResetPassword',
  data() {
    return {
      user: {
        password: '',
        old_password: '',
        password_confirmation: ''
      },
      validations: {}
    }
  },
  methods: {
    resetPassword(e) {
      e.preventDefault()
      this.validations = {}
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/auth/backend/reset-password', this.user)
        .then((response) => {
          this.validations = null
          let result = response.data.data
          let token = result.access_token
          let refresh = result.refresh_token

          localStorage.setItem(this.$token, token)
          localStorage.setItem(process.env.VUE_APP_REFRESH_TOKEN, refresh)

          this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          $.ajaxSetup({
            headers: {
              'Accept': 'application/json',
              'Authorization': "Bearer " + token,
            }
          })
          this.$router.push({name: "admin"})
        }).catch((error) => {
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        }).finally(() => {
          this.$isLoading(false)
        })
    }
  }
}
</script>

<style scoped>
.reset-password {
  padding: 10px;
}

.text-md-right {
  text-align: right;
}
</style>
