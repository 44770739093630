<template>
  <div class="row">
    <div class="col-md-12">
      <ul class="timeline">
        <li>
          <i class="fa fa-check-circle bg-blue" />
          <div class="timeline-item">
            <h3 class="timeline-header">
              <b>{{ $t('string.ownerRequested') }}</b>
            </h3>
            <div class="timeline-body">
              <div class="form-horizontal">
                <div class="form-group row">
                  <div class="col-sm-4 col-sm-offset-3">
                    <b>{{ $t('string.ownerRequested') }}</b>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.ownerRequestedDate') }}</label>
                  <div class="col-sm-4">
                    <date-picker
                      v-model="project.owner_requested_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.ownerRequestedDate')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                      @change="onChangeOwnerRequestedAt"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-9 col-sm-offset-3">
                    <b>ឃុំ/សង្កាត់</b> <strong>({{ $t('string.taken') }} {{ communeTaken }} {{
                      $t('string.days')
                    }})</strong>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.requestedAt') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="communeCreatedNotBeforeToday" -->
                    <date-picker
                      v-model="project.commune_created_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.requestedAt')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.releasedAt') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="communeNotBeforeToday" -->
                    <date-picker
                      v-model="project.commune_released_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.releasedAt')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                      @change="onChangeCommuneReleasedAt"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.permitNumber') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.commune_permit_number"
                      :placeholder="$t('label.permitNumber')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.approvedBy') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.commune_approved_by"
                      :placeholder="$t('label.approvedBy')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>


                <div class="form-group row">
                  <div class="col-sm-9 col-sm-offset-3">
                    <b>ការិយាល័យ ដនសភ ក្រុង/ស្រុក/ខណ្ឌ</b> <strong> ({{ $t('string.taken') }} {{ officialDnspTaken }} {{
                      $t('string.days')
                    }})</strong>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.requestedAt') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="officialDnspCreatedNotBeforeToday" -->
                    <date-picker
                      v-model="project.official_dnsp_created_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.requestedAt')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.releasedAt') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="officialDnspNotBeforeToday" -->
                    <date-picker
                      v-model="project.official_dnsp_released_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.releasedAt')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                      @change="onChangeOfficialDnspReleasedAt"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.permitNumber') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.official_dnsp_permit_number"
                      :placeholder="$t('label.permitNumber')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.approvedBy') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.official_dnsp_approved_by"
                      :placeholder="$t('label.approvedBy')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-9 col-sm-offset-3">
                    <b>រដ្ឋបាលក្រុង/ស្រុក/ខណ្ឌ</b> <strong> ({{ $t('string.taken') }} {{ administrationProvinceTaken }}
                      {{ $t('string.days') }})</strong>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.requestedAt') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="administrationProvinceCreatedNotBeforeToday" -->
                    <date-picker
                      v-model="project.administration_province_created_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.requestedAt')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.releasedAt') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="administrationProvinceNotBeforeToday" -->
                    <date-picker
                      v-model="project.administration_province_released_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.releasedAt')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                      @change="onChangeAdministrationProvinceReleasedAt"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.permitNumber') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.administration_province_permit_number"
                      :placeholder="$t('label.permitNumber')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.approvedBy') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.administration_province_approved_by"
                      :placeholder="$t('label.approvedBy')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>


                <div class="form-group row">
                  <div class="col-sm-9 col-sm-offset-3">
                    <b>មន្ទីរដនសសរាជធានី/ខេត្ត</b> <strong> ({{ $t('string.taken') }} {{ officerDnspTaken }} {{
                      $t('string.days')
                    }})</strong>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.requestedAt') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="officerDnspProvinceCreatedNotBeforeToday" -->
                    <date-picker
                      v-model="project.officer_dnsp_province_created_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.requestedAt')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.releasedAt') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="officerDnspProvinceNotBeforeToday" -->
                    <date-picker
                      v-model="project.officer_dnsp_province_released_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.releasedAt')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                      @change="onChangeOfficerDnspProvinceReleasedAt"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.permitNumber') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.officer_dnsp_province_permit_number"
                      :placeholder="$t('label.permitNumber')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.approvedBy') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.officer_dnsp_province_approved_by"
                      :placeholder="$t('label.approvedBy')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>


                <div class="form-group row">
                  <div class="col-sm-9 col-sm-offset-3">
                    <b>រដ្ឋបាលរាជធានី/ខេត្ត</b> <strong> ({{ $t('string.taken') }} {{ administrationCityProvince }} {{
                      $t('string.days')
                    }})</strong>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.requestedAt') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="administrationCityProvinceCreatedNotBeforeToday" -->
                    <date-picker
                      v-model="project.administration_city_province_created_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.requestedAt')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.releasedAt') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="administrationCityProvinceNotBeforeToday" -->
                    <date-picker
                      v-model="project.administration_city_province_released_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.releasedAt')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.permitNumber') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.administration_city_province_permit_number"
                      :placeholder="$t('label.permitNumber')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.approvedBy') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.administration_city_province_approved_by"
                      :placeholder="$t('label.approvedBy')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <i class="fa fa-check-circle bg-blue" />
          <div class="timeline-item">
            <span
              :class="!entryValidationTaken.is_normal ? 'bg-red': null"
              class="time"
            >
              <i class="fa fa-clock-o" />
              {{ $t('string.taken') }} {{ entryTaken }} {{ $t('string.days') }}
              <template v-if="!entryValidationTaken.is_normal">
                <span class="text-black">
                  ({{ $t('string.exceededSnippetDays') }} {{ entryValidationTaken.duration }} {{ $t('string.days') }})
                </span>
              </template>
            </span>
            <h3 class="timeline-header">
              <b>{{ $t('string.singleWindow') }}</b>
            </h3>
            <div class="timeline-body">
              <div class="form-horizontal">
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.in') }}</label>
                  <div class="col-sm-4">
                    <date-picker
                      v-model="project.entry_created_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.in')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.out') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="entryNotBeforeToday" -->
                    <date-picker
                      v-model="project.entry_released_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.out')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.permitNumber') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.entry_permit_number"
                      :placeholder="$t('label.permitNumber')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.approvedBy') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.entry_approved_by"
                      :placeholder="$t('label.approvedBy')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <i class="fa fa-clock-o bg-blue" />
          <div class="timeline-item">
            <span
              :class="!directorGeneralOfConstructionValidationTaken.is_normal ? 'bg-red': null"
              class="time"
            >
              <i class="fa fa-clock-o" />
              {{ $t('string.taken') }} {{ directorGeneralOfConstructionTaken }} {{ $t('string.days') }}
              <template v-if="!directorGeneralOfConstructionValidationTaken.is_normal">
                <span class="text-black">
                  ({{ $t('string.exceededSnippetDays') }} {{
                    directorGeneralOfConstructionValidationTaken.duration
                  }} {{ $t('string.days') }})
                </span>
              </template>
            </span>
            <h3 class="timeline-header">
              <b>{{ $t('string.directorGeneralOfConstruction') }}</b>
            </h3>
            <div class="timeline-body">
              <div class="form-horizontal">
                <div class="form-group row">
                  <div class="col-sm-9 col-sm-offset-3 padding-right-5">
                    <b>{{ $t('string.directorGeneralOfConstruction') }}</b>
                    <div class="pull-right">
                      <div
                        :class="!firstBoardOfConstructionValidationTaken.is_normal?'bg-red':null"
                        class="sub-time"
                      >
                        <i class="fa fa-clock-o" /> {{ $t('string.taken') }} {{ firstBoardOfConstructionTaken }}
                        {{ $t('string.days') }}
                        <template v-if="!firstBoardOfConstructionValidationTaken.is_normal">
                          <span class="text-black">
                            ({{ $t('string.exceededSnippetDays') }} {{
                              firstBoardOfConstructionValidationTaken.duration
                            }} {{ $t('string.days') }})
                          </span>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.in') }}</label>
                  <div class="col-sm-4">
                    <date-picker
                      v-model="project.first_board_of_construction_created_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.in')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.out') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="firstBoardOfConstructionNotBeforeToday" -->
                    <date-picker
                      v-model="project.first_board_of_construction_released_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.out')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                      @change="onChangeFirstBoardOfConstructionReleasedAt"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.permitNumber') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.first_board_of_construction_permit_number"
                      :placeholder="$t('label.permitNumber')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.approvedBy') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.first_board_of_construction_approved_by"
                      :placeholder="$t('label.approvedBy')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
              </div>
              <div class="form-horizontal">
                <div class="form-group row">
                  <div class="col-sm-9 col-sm-offset-3">
                    <b>{{ $t('string.studyPlanSection') }}</b>
                    <div class="pull-right">
                      <div
                        :class="!boardOfStudyPlansValidation.is_normal?'bg-red':null"
                        class="sub-time"
                      >
                        <i class="fa fa-clock-o" /> {{ $t('string.taken') }} {{ boardOfStudyPlansTaken }}
                        {{ $t('string.days') }}
                        <template v-if="!boardOfStudyPlansValidation.is_normal">
                          <span class="text-black">
                            ({{ $t('string.exceededSnippetDays') }} {{
                              boardOfStudyPlansValidation.duration
                            }} {{ $t('string.days') }})
                          </span>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.in') }}</label>
                  <div class="col-sm-4">
                    <date-picker
                      v-model="project.board_of_study_plans_created_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.in')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.out') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="boardOfStudyPlansNotBeforeToday" -->
                    <date-picker
                      v-model="project.board_of_study_plans_released_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.out')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                      @change="onChangeBoardOfStudyPlansReleasedAt"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.permitNumber') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.board_of_study_plans_permit_number"
                      :placeholder="$t('label.permitNumber')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.approvedBy') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.board_of_study_plans_approved_by"
                      :placeholder="$t('label.approvedBy')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
              </div>
              <div class="form-horizontal">
                <div class="form-group row">
                  <div class="col-sm-9 col-sm-offset-3">
                    <b>{{ $t('string.directorGeneralOfConstruction') }}</b>
                    <div class="pull-right">
                      <div
                        :class="!secondBoardOfConstructionValidationTaken.is_normal?'bg-red':null"
                        class="sub-time"
                      >
                        <i class="fa fa-clock-o" /> {{ $t('string.taken') }} {{ secondBoardOfConstructionTaken }}
                        {{ $t('string.days') }}
                        <template v-if="!secondBoardOfConstructionValidationTaken.is_normal">
                          <span class="text-black">
                            ({{ $t('string.exceededSnippetDays') }} {{
                              secondBoardOfConstructionValidationTaken.duration
                            }} {{ $t('string.days') }})
                          </span>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.in') }}</label>
                  <div class="col-sm-4">
                    <date-picker
                      v-model="project.second_board_of_construction_created_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.in')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.out') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="secondBoardOfConstructionNotBeforeToday" -->
                    <date-picker
                      v-model="project.second_board_of_construction_released_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.out')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.permitNumber') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.second_board_of_construction_permit_number"
                      :placeholder="$t('label.permitNumber')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.approvedBy') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.second_board_of_construction_approved_by"
                      :placeholder="$t('label.approvedBy')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <i class="fa fa-check-circle bg-blue" />
          <div class="timeline-item">
            <span
              :class="!castreTakenValidationTaken.is_normal ? 'bg-red': null"
              class="time"
            >
              <i class="fa fa-clock-o" />
              {{ $t('string.taken') }} {{ castreTaken }} {{ $t('string.days') }}
              <template v-if="!castreTakenValidationTaken.is_normal">
                <span class="text-black">
                  ({{ $t('string.exceededSnippetDays') }} {{ castreTakenValidationTaken.duration }} {{
                    $t('string.days')
                  }})
                </span>
              </template>
            </span>
            <h3 class="timeline-header">
              <b>{{ $t('string.Superintendent_of_Land') }}</b>
            </h3>
            <div class="timeline-body">
              <div class="form-horizontal">
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.in') }}</label>
                  <div class="col-sm-4">
                    <date-picker
                      v-model="project.cadastre_created_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.in')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.out') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="castreNotBeforeToday" -->
                    <date-picker
                      v-model="project.cadastre_released_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.out')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.permitNumber') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.cadastre_permit_number"
                      :placeholder="$t('label.permitNumber')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.approvedBy') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.cadastre_approved_by"
                      :placeholder="$t('label.approvedBy')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <i class="fa fa-clock-o bg-blue" />
          <div class="timeline-item">
            <span
              :class="!landAndUrbanTakenValidationTaken.is_normal ? 'bg-red': null"
              class="time"
            >
              <i class="fa fa-clock-o" />
              {{ $t('string.taken') }} {{ landAndUrbanTaken }} {{ $t('string.days') }}
              <template v-if="!landAndUrbanTakenValidationTaken.is_normal">
                <span class="text-black">
                  ({{ $t('string.exceededSnippetDays') }} {{
                    landAndUrbanTakenValidationTaken.duration
                  }} {{ $t('string.days') }})
                </span>
              </template>
            </span>
            <h3 class="timeline-header">
              <b>{{ $t('string.landUrbanization') }}</b>
            </h3>
            <div class="timeline-body">
              <div class="form-horizontal">
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.in') }}</label>
                  <div class="col-sm-4">
                    <date-picker
                      v-model="project.land_urbanization_created_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.in')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.out') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="landUrbanNotBeforeToday" -->
                    <date-picker
                      v-model="project.land_urbanization_released_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.out')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                      @change="onChangeLandUrbanizationReleasedAt"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.permitNumber') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.land_urbanization_permit_number"
                      :placeholder="$t('label.permitNumber')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.approvedBy') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.land_urbanization_approved_by"
                      :placeholder="$t('label.approvedBy')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <i class="fa fa-clock-o bg-blue" />
          <div class="timeline-item">
            <span class="time">
              <i class="fa fa-clock-o" />
              {{ $t('string.taken') }} {{ donTaken }} {{ $t('string.days') }}
              <!--<template v-if="!donTakenValidationTaken.is_normal">
                 <span class="text-black">
                   ({{ $t('string.exceededSnippetDays') }} {{ donTakenValidationTaken.duration }} {{ $t('string.days') }})
                 </span>
              </template>-->
            </span>
            <h3 class="timeline-header">
              <b>សរុបរបាយការណ៌នៅអគ្គនាយកដ្ឋានដែនដីនរគរូបយីកម្ម</b>
            </h3>
            <div class="timeline-body">
              <div class="form-horizontal">
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.in') }}</label>
                  <div class="col-sm-4">
                    <date-picker
                      v-model="project.don_created_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.in')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.out') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="donNotBeforeToday" -->
                    <date-picker
                      v-model="project.don_released_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.out')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                      @change="onChangeDonReleasedAt"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.permitNumber') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.don_permit_number"
                      :placeholder="$t('label.permitNumber')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.approvedBy') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.don_approved_by"
                      :placeholder="$t('label.approvedBy')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <i class="fa fa-clock-o bg-blue" />
          <div class="timeline-item">
            <span class="time">
              <i class="fa fa-clock-o" />
              {{ $t('string.taken') }} {{ asnTakenTotal }} {{ $t('string.days') }}
            </span>
            <h3 class="timeline-header">
              <b>ហត្តលេខាឯកសារ ៥ក្បាលពី ដន មកដល់ អសណ</b>
            </h3>
            <div class="timeline-body">
              <div class="form-horizontal">
                <div class="form-group row">
                  <div class="col-sm-9 col-sm-offset-3 padding-right-5">
                    <b>អគ្គនាយកដ្ឋានសំណង់</b>
                    <div class="pull-right">
                      <div class="sub-time">
                        <i class="fa fa-clock-o" /> {{ $t('string.taken') }} {{ asnTaken }} {{ $t('string.days') }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.in') }}</label>
                  <div class="col-sm-4">
                    <date-picker
                      v-model="project.asn_created_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.in')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.out') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="asnNotBeforeToday" -->
                    <date-picker
                      v-model="project.asn_released_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.out')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                      @change="onChangeAsnReleasedAt"
                    />
                  </div>
                </div>
              </div>
              <div class="form-horizontal">
                <div class="form-group row">
                  <div class="col-sm-9 col-sm-offset-3">
                    <b>នាយកដ្ឋានសិក្សាគម្រោងប្លង់</b>
                    <div class="pull-right">
                      <div class="sub-time">
                        <i class="fa fa-clock-o" /> {{ $t('string.taken') }} {{ asnBoardOfStudyPlansTaken }} {{
                          $t('string.days')
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.in') }}</label>
                  <div class="col-sm-4">
                    <date-picker
                      v-model="project.asn_board_of_study_plans_created_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.in')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.out') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="asnBoardOfStudyPlansNotBeforeToday" -->
                    <date-picker
                      v-model="project.asn_board_of_study_plans_released_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.out')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                      @change="onChangeAsnBoardOfStudyPlansReleasedAt"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.permitNumber') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.asn_board_of_study_plans_permit_number"
                      :placeholder="$t('label.permitNumber')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.approvedBy') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.asn_board_of_study_plans_approved_by"
                      :placeholder="$t('label.approvedBy')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
              </div>
              <div class="form-horizontal">
                <div class="form-group row">
                  <div class="col-sm-9 col-sm-offset-3">
                    <b>អគ្គនាយកដ្ឋានសំណង់</b>
                    <div class="pull-right">
                      <div class="sub-time">
                        <i class="fa fa-clock-o" /> {{ $t('string.taken') }} {{ asnSecondBoardOfConstructionTaken }} {{
                          $t('string.days')
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.in') }}</label>
                  <div class="col-sm-4">
                    <date-picker
                      v-model="project.asn_second_board_of_construction_created_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.in')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.out') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="asnSecondBoardOfConstructionNotBeforeToday" -->
                    <date-picker
                      v-model="project.asn_second_board_of_construction_released_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.out')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.permitNumber') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.asn_second_board_of_construction_permit_number"
                      :placeholder="$t('label.permitNumber')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.approvedBy') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.asn_second_board_of_construction_approved_by"
                      :placeholder="$t('label.approvedBy')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <i class="fa fa-clock-o bg-blue" />
          <div class="timeline-item">
            <!--<span class="time" :class="!permitTakenValidationTaken.is_normal ? 'bg-red': null">
              <i class="fa fa-clock-o"></i>
              {{ $t('string.taken') }} {{ permitTaken }} {{ $t('string.days') }}
              <template v-if="!permitTakenValidationTaken.is_normal">
                 <span class="text-black">
                   ({{ $t('string.exceededSnippetDays') }} {{ permitTakenValidationTaken.duration }} {{ $t('string.days') }})
                 </span>
              </template>
            </span>-->
            <h3 class="timeline-header">
              <b>លិខិតអនុញ្ញាតសាងសង់</b>
            </h3>
            <div class="timeline-body">
              <div class="form-horizontal">
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.in') }}</label>
                  <div class="col-sm-4">
                    <date-picker
                      v-model="project.permit_created_at"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.in')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.out') }}</label>
                  <div class="col-sm-4">
                    <!-- :disabled-date="permitBeforeToday" -->
                    <date-picker
                      v-model="project.permit_date"
                      :disabled="true"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('label.out')"
                      class="table-full-width"
                      format="DD/MM/YYYY"
                      type="date"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.permitNumber') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.permit_number"
                      :disabled="true"
                      :placeholder="$t('label.permitNumber')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-3 control-label">{{ $t('label.approvedBy') }}</label>
                  <div class="col-sm-4">
                    <input
                      v-model="project.permit_approved_by"
                      :placeholder="$t('label.approvedBy')"
                      class="form-control"
                      type="text"
                    >
                  </div>
                </div>
                <div
                  v-if="$route.name!=='show-project'"
                  class="form-group row"
                >
                  <div class="col-sm-9 col-sm-offset-3">
                    <button
                      v-if="$Permissions.backend.provinceProject.storeProcessRequesting"
                      class="btn btn-sm btn-primary"
                      @click="storeProcess"
                    >
                      {{ $t('button.update') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <i class="fa fa-circle" />
        </li>
      </ul>
    </div>
    <div class="col-md-12 margin-top-15">
      <div class="table-responsive">
        <table class="table table-condensed table-bordered table-striped">
          <thead>
            <tr>
              <th>{{ $t('string.location') }}</th>
              <th>{{ $t('string.taken') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ដំណើរស្នើសុំនៅរាជធានី/ខេត្ត</td>
              <td>{{ provinceTaken }} {{ $t('string.days') }}</td>
            </tr>
            <tr>
              <td>ដំណើរស្នើសុំនៅក្រសួង</td>
              <td>{{ ministryTaken }} {{ $t('string.days') }}</td>
            </tr>
            <tr>
              <td class="main-title">
                សរុប
              </td>
              <td><strong>{{ totalTaken }} {{ $t('string.days') }}</strong></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {projectRequestRefOnChangeMixins} from "../../mixins/ProjectRequestRefOnChange"
import {optionsMixin} from "../../mixins/options"

export default {
  name: "ProjectRequestingTab",
  mixins: [projectRequestRefOnChangeMixins, optionsMixin],
  data() {
    return {
      project: {
        owner_requested_at: '',

        commune_created_at: '',
        commune_released_at: '',
        commune_permit_number: '',
        commune_approved_by: '',

        official_dnsp_created_at: '',
        official_dnsp_released_at: '',
        official_dnsp_permit_number: '',
        official_dnsp_approved_by: '',

        administration_province_created_at: '',
        administration_province_released_at: '',
        administration_province_permit_number: '',
        administration_province_approved_by: '',

        officer_dnsp_province_created_at: '',
        officer_dnsp_province_released_at: '',
        officer_dnsp_province_permit_number: '',
        officer_dnsp_province_approved_by: '',

        administration_city_province_created_at: '',
        administration_city_province_released_at: '',
        administration_city_province_permit_number: '',
        administration_city_province_approved_by: '',

        entry_released_at: '',
        entry_created_at: '',
        entry_permit_number: '',
        entry_approved_by: '',

        land_urbanization_created_at: '',
        land_urbanization_released_at: '',
        land_urbanization_permit_number: '',
        land_urbanization_approved_by: '',

        first_board_of_construction_created_at: '',
        first_board_of_construction_released_at: '',
        first_board_of_construction_permit_number: '',
        first_board_of_construction_approved_by: '',

        board_of_study_plans_created_at: '',
        board_of_study_plans_released_at: '',
        board_of_study_plans_permit_number: '',
        board_of_study_plans_approved_by: '',

        second_board_of_construction_created_at: '',
        second_board_of_construction_released_at: '',
        second_board_of_construction_permit_number: '',
        second_board_of_construction_approved_by: '',

        asn_created_at: '',
        asn_released_at: '',
        asn_permit_number: '',
        asn_approved_by: '',

        asn_board_of_study_plans_created_at: '',
        asn_board_of_study_plans_released_at: '',
        asn_board_of_study_plans_permit_number: '',
        asn_board_of_study_plans_approved_by: '',

        asn_second_board_of_construction_created_at: '',
        asn_second_board_of_construction_released_at: '',
        asn_second_board_of_construction_permit_number: '',
        asn_second_board_of_construction_approved_by: '',

        don_created_at: '',
        don_released_at: '',
        don_permit_number: '',
        don_approved_by: '',

        cadastre_created_at: '',
        cadastre_released_at: '',
        cadastre_permit_number: '',
        cadastre_approved_by: '',

        permit_created_at: '',
        permit_date: '',
        permit_number: '',
        permit_approved_by: '',
      },
      maxDurationDayTaken: {
        communeTaken: 2,
        officialDnspTaken: 2,
        administrationProvinceTaken: 2,
        officerDnspProvince: 2,
        administrationCityProvinceTaken: 2,
        entryTaken: 1,
        landAndUrbanTaken: 14,
        firstBoardOfConstruction: 1,
        boardOfStudyPlans: 8,
        secondBoardOfConstruction: 3,
        directorGeneralOfConstructionTaken: 12,
        asnTaken: 2,
        asnSecondBoardOfConstruction: 2,
        asnBoardOfStudyPlans: 2,
        donTaken: 2,
        castreTaken: 4,
        permitTaken: 10,
      }
    }
  },
  computed: {
    provinceTaken() {
      if (this.$moment(this.project.owner_requested_at).isValid() && this.$moment(this.project.administration_city_province_released_at).isValid()) {
        return this.getBusinessDays(this.project.owner_requested_at, this.project.administration_city_province_released_at)
      }
      return 0
    },
    ministryTaken() {
      if (this.$moment(this.project.entry_created_at).isValid() && this.$moment(this.project.permit_date).isValid()) {
        return this.getBusinessDays(this.project.entry_created_at, this.project.permit_date)
      }
      return 0
    },
    communeTaken() {
      if (this.$moment(this.project.commune_created_at).isValid() && this.$moment(this.project.commune_released_at).isValid()) {
        return this.getBusinessDays(this.project.commune_created_at, this.project.commune_released_at)
      }
      return 0
    },
    officialDnspTaken() {
      if (this.$moment(this.project.official_dnsp_created_at).isValid() && this.$moment(this.project.official_dnsp_released_at).isValid()) {
        return this.getBusinessDays(this.project.official_dnsp_created_at, this.project.official_dnsp_released_at)
      }
      return 0
    },
    administrationProvinceTaken() {
      if (this.$moment(this.project.administration_province_created_at).isValid() && this.$moment(this.project.administration_province_released_at).isValid()) {
        return this.getBusinessDays(this.project.administration_province_created_at, this.project.administration_province_released_at)
      }
      return 0
    },
    officerDnspTaken() {
      if (this.$moment(this.project.officer_dnsp_province_created_at).isValid() && this.$moment(this.project.officer_dnsp_province_released_at).isValid()) {
        return this.getBusinessDays(this.project.officer_dnsp_province_created_at, this.project.officer_dnsp_province_released_at)
      }
      return 0
    },
    administrationCityProvince() {
      if (this.$moment(this.project.administration_city_province_created_at).isValid() && this.$moment(this.project.administration_city_province_released_at).isValid()) {
        return this.getBusinessDays(this.project.administration_city_province_created_at, this.project.administration_city_province_released_at)
      }
      return 0
    },
    entryTaken() {
      if (this.$moment(this.project.entry_released_at).isValid() && this.$moment(this.project.entry_created_at).isValid()) {
        return this.getBusinessDays(this.project.entry_created_at, this.project.entry_released_at)
      }
      return 0
    },
    entryValidationTaken() {
      return this.getValidateDate('entryTaken', this.entryTaken)
    },
    castreTaken() {
      if (this.$moment(this.project.cadastre_created_at).isValid() && this.$moment(this.project.cadastre_released_at).isValid()) {
        return this.getBusinessDays(this.project.cadastre_created_at, this.project.cadastre_released_at)
      }
      return 0
    },
    castreTakenValidationTaken() {
      return this.getValidateDate('castreTaken', this.castreTaken)
    },
    landAndUrbanTaken() {
      if (this.$moment(this.project.land_urbanization_created_at).isValid() && this.$moment(this.project.land_urbanization_released_at).isValid()) {
        return this.getBusinessDays(this.project.land_urbanization_created_at, this.project.land_urbanization_released_at)
      }
      return 0
    },
    landAndUrbanTakenValidationTaken() {
      return this.getValidateDate('landAndUrbanTaken', this.landAndUrbanTaken)
    },
    donTaken() {
      if (this.$moment(this.project.don_created_at).isValid() && this.$moment(this.project.don_released_at).isValid()) {
        return this.getBusinessDays(this.project.don_created_at, this.project.don_released_at)
      }
      return 0
    },
    donTakenValidationTaken() {
      return this.getValidateDate('donTaken', this.donTaken)
    },
    asnTakenTotal() {
      return parseFloat(this.asnTaken)
        + parseFloat(this.asnBoardOfStudyPlansTaken)
        + parseFloat(this.asnSecondBoardOfConstructionTaken)
    },
    asnTaken() {
      if (this.$moment(this.project.asn_created_at).isValid() && this.$moment(this.project.asn_released_at).isValid()) {
        return this.getBusinessDays(this.project.asn_created_at, this.project.asn_released_at)
      }
      return 0
    },
    asnSecondBoardOfConstructionTaken() {
      if (this.$moment(this.project.asn_second_board_of_construction_created_at).isValid() && this.$moment(this.project.asn_second_board_of_construction_released_at).isValid()) {
        return this.getBusinessDays(this.project.asn_second_board_of_construction_created_at, this.project.asn_second_board_of_construction_released_at)
      }
      return 0
    },
    asnBoardOfStudyPlansTaken() {
      if (this.$moment(this.project.asn_board_of_study_plans_created_at).isValid() && this.$moment(this.project.asn_board_of_study_plans_released_at).isValid()) {
        return this.getBusinessDays(this.project.asn_board_of_study_plans_created_at, this.project.asn_board_of_study_plans_released_at)
      }
      return 0
    },
    permitTaken() {
      if (this.$moment(this.project.permit_created_at).isValid() && this.$moment(this.project.permit_date).isValid()) {
        return this.getBusinessDays(this.project.permit_created_at, this.project.permit_date)
      }
      return 0
    },
    administrationCityProvinceTaken() {
      if (this.$moment(this.project.administration_city_province_created_at).isValid() && this.$moment(this.project.administration_city_province_released_at).isValid()) {
        return this.getBusinessDays(this.project.administration_city_province_created_at, this.project.administration_city_province_released_at)
      }
      return 0
    },
    firstBoardOfConstructionTaken() {
      if (this.$moment(this.project.first_board_of_construction_created_at).isValid() && this.$moment(this.project.first_board_of_construction_released_at).isValid()) {
        return this.getBusinessDays(this.project.first_board_of_construction_created_at, this.project.first_board_of_construction_released_at)
      }
      return 0
    },
    secondBoardOfConstructionTaken() {
      if (this.$moment(this.project.second_board_of_construction_created_at).isValid() && this.$moment(this.project.second_board_of_construction_released_at).isValid()) {
        return this.getBusinessDays(this.project.second_board_of_construction_created_at, this.project.second_board_of_construction_released_at)
      }
      return 0
    },
    boardOfStudyPlansTaken() {
      if (this.$moment(this.project.board_of_study_plans_created_at).isValid() && this.$moment(this.project.board_of_study_plans_released_at).isValid()) {
        return this.getBusinessDays(this.project.board_of_study_plans_created_at, this.project.board_of_study_plans_released_at)
      }
      return 0
    },
    directorGeneralOfConstructionTaken() {
      return parseFloat(this.firstBoardOfConstructionTaken) +
        parseFloat(this.secondBoardOfConstructionTaken) +
        parseFloat(this.boardOfStudyPlansTaken)
    },
    directorGeneralOfConstructionValidationTaken() {
      return this.getValidateDate('directorGeneralOfConstructionTaken', this.directorGeneralOfConstructionTaken)
    },
    firstBoardOfConstructionValidationTaken() {
      return this.getValidateDate('firstBoardOfConstruction', this.firstBoardOfConstructionTaken)
    },
    boardOfStudyPlansValidation() {
      return this.getValidateDate('boardOfStudyPlans', this.boardOfStudyPlansTaken)
    },
    secondBoardOfConstructionValidationTaken() {
      return this.getValidateDate('secondBoardOfConstruction', this.secondBoardOfConstructionTaken)
    },
    permitTakenValidationTaken() {
      return this.getValidateDate('permitTaken', this.permitTaken)
    },
    asnTakenValidationTaken() {
      return this.getValidateDate('asnTaken', this.asnTaken)
    },
    asnSecondBoardOfConstructionValidationTaken() {
      return this.getValidateDate('asnSecondBoardOfConstruction', this.asnSecondBoardOfConstructionTaken)
    },
    asnBoardOfStudyPlansValidationTaken() {
      return this.getValidateDate('asnBoardOfStudyPlans', this.asnBoardOfStudyPlansTaken)
    },
    totalTaken() {
      return parseFloat(this.provinceTaken) +
        parseFloat(this.ministryTaken)
    }
  },
  watch: {
    'project.entry_created_at'() {
      let date = this.project.entry_created_at
      if (this.project.cadastre_created_at === null || this.project.cadastre_created_at === '') {
        this.project.cadastre_created_at = date
      }
      if (this.project.land_urbanization_created_at === null || this.project.land_urbanization_created_at === '') {
        this.project.land_urbanization_created_at = date
      }
      if (this.project.first_board_of_construction_created_at === null || this.project.first_board_of_construction_created_at === '') {
        this.project.first_board_of_construction_created_at = date
      }
    },
    'project.owner_requested_at'() {
      this.project.permit_created_at = this.project.owner_requested_at
    },
  },
  created() {
    this.getProcess()
    if (
      this.$route.name === 'show-project' ||
      this.$route.name === 'show-province-project'
    ) {
      $(function () {
        $('input').attr('disabled', 'disabled')
      })
    }
  },
  methods: {
    communeCreatedNotBeforeToday(date) {
      return date < this.project.owner_requested_at
    },
    communeNotBeforeToday(date) {
      return date < this.project.commune_created_at
    },
    officialDnspCreatedNotBeforeToday(date) {
      return date < this.project.commune_released_at
    },
    officialDnspNotBeforeToday(date) {
      return date < this.project.official_dnsp_created_at
    },
    administrationProvinceCreatedNotBeforeToday(date) {
      return date < this.project.official_dnsp_released_at
    },
    administrationProvinceNotBeforeToday(date) {
      return date < this.project.administration_province_created_at
    },
    officerDnspProvinceCreatedNotBeforeToday(date) {
      return date < this.project.administration_province_released_at
    },
    officerDnspProvinceNotBeforeToday(date) {
      return date < this.project.officer_dnsp_province_created_at
    },
    administrationCityProvinceCreatedNotBeforeToday(date) {
      return date < this.project.officer_dnsp_province_released_at
    },
    administrationCityProvinceNotBeforeToday(date) {
      return date < this.project.administration_city_province_created_at
    },
    entryNotBeforeToday(date) {
      return date < this.project.entry_created_at
    },
    castreNotBeforeToday(date) {
      return date < this.project.cadastre_created_at
    },
    firstBoardOfConstructionNotBeforeToday(date) {
      return date < this.project.first_board_of_construction_created_at
    },
    boardOfStudyPlansNotBeforeToday(date) {
      return date < this.project.board_of_study_plans_created_at
    },
    secondBoardOfConstructionNotBeforeToday(date) {
      return date < this.project.second_board_of_construction_created_at
    },
    landUrbanNotBeforeToday(date) {
      return date < this.project.land_urbanization_created_at
    },
    donNotBeforeToday(date) {
      return date < this.project.don_created_at
    },
    asnNotBeforeToday(date) {
      return date < this.project.asn_created_at
    },
    asnBoardOfStudyPlansNotBeforeToday(date) {
      return date < this.project.asn_board_of_study_plans_created_at
    },
    asnSecondBoardOfConstructionNotBeforeToday(date) {
      return date < this.project.asn_second_board_of_construction_created_at
    },
    permitBeforeToday(date) {
      return date < this.project.permit_created_at
    },
    getValidateDate(section, taken) {
      let result = {
        is_normal: true,
        duration: 0,
      }
      let item = null
      for (let key in this.maxDurationDayTaken) {
        if (key === section) {
          item = this.maxDurationDayTaken[key]
        }
      }
      if (item !== null && (parseFloat(taken) > parseFloat(item))) {
        result = {
          is_normal: false,
          duration: parseFloat(taken) - parseFloat(item),
        }
      }
      return result
    },
    storeProcess() {
      let params = new FormData()
      params.set('project_uuid', this.$route.params.project_uuid)
      for (let key in this.project) {
        let value = this.project[key]
        if (value !== '' && value !== null) {
          if (key.endsWith('_at') || key.endsWith('_date')) {
            if (this.$moment(value).isValid()) {
              value = this.$moment(value).locale('en').format('YYYY-MM-DD')
            }
          }
          params.set(key, value)
        } else {
          params.set(key, '')
        }
      }
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project/store-process', params)
        .then(() => {
          this.showToastr()
          this.getProcess()
        }).catch((error) => {
          this.onResponseError(error)
        }).finally(() => {
          this.$isLoading(false)
        })
    },
    getProcess() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project/get-process', {
        project_uuid: this.$route.params.project_uuid,
      }).then((res) => {
        if (res.data.data) {
          let project = res.data.data
          for (let key in project) {
            if (project[key] !== null) {
              if ((key.endsWith('_at') || key.endsWith('_date'))) {
                if (this.$moment(project[key]).isValid()) {
                  this.$set(this.project, key, this.$moment(project[key]).toDate())
                } else {
                  this.$set(this.project, key, '')
                }
              } else {
                this.$set(this.project, key, project[key])
              }
            } else {
              this.setDefaultApprovedBy(key)
            }
          }
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    setDefaultApprovedBy(key) {
      switch (key) {
      case 'entry_approved_by':
        this.project.entry_approved_by = this.officerAllowPermits.entry
        break
      case 'first_board_of_construction_approved_by':
        this.project.first_board_of_construction_approved_by = this.officerAllowPermits.board_of_construction
        break
      case 'second_board_of_construction_approved_by':
        this.project.second_board_of_construction_approved_by = this.officerAllowPermits.board_of_construction
        break
      case 'board_of_study_plans_approved_by':
        this.project.board_of_study_plans_approved_by = this.officerAllowPermits.board_of_study
        break
      case 'cadastre_approved_by':
        this.project.cadastre_approved_by = this.officerAllowPermits.castre
        break
      case 'land_urbanization_approved_by':
        this.project.land_urbanization_approved_by = this.officerAllowPermits.ministry_land
        break
      case 'don_approved_by':
        this.project.don_approved_by = this.officerAllowPermits.ministry_land
        break
      case 'asn_approved_by':
        this.project.asn_approved_by = this.officerAllowPermits.board_of_construction
        break
      case 'asn_board_of_study_plans_approved_by':
        this.project.asn_board_of_study_plans_approved_by = this.officerAllowPermits.board_of_study
        break
      case 'asn_second_board_of_construction_approved_by':
        this.project.asn_second_board_of_construction_approved_by = this.officerAllowPermits.board_of_construction
        break
      case 'permit_approved_by':
        this.project.permit_approved_by = this.officerAllowPermits.permit
        break
      }
    }
  },
}
</script>

<style scoped>
.timeline > li > .timeline-item > .time, .sub-time {
  font-size: 16px;
  background: #00c0ef;
  padding: 4px 8px;
  color: #ffffff;
}
</style>
