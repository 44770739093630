export const state = () => {
  return {
    projects: []
  }
}

export const getters = {
  getProjects: state => state.projects
}

export const mutations = {
  selectProject(state, project) {
    state.projects.push(project)
  },

  deselectProject(state, uuid) {
    const _project = state.projects.find(m => m.uuid === uuid)
    if (_project) {
      state.projects.splice(state.projects.indexOf(_project), 1)
    }
  },

  resetProjects (state) {
    state.projects = []
  }
}

export const actions = {
  selectProject({commit}, project) {
    commit('selectProject', project)
  },

  deselectProject({commit}, uuid) {
    commit('deselectProject', uuid)
  },

  resetProjects({commit}) {
    commit('resetProjects')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
