<template>
  <div class="row">
    <div class="col-md-6">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('string.showMinistryOfLandOfficer') }}
          </h3>
          <div class="box-tools">
            <button
              v-if="can($Permissions.backend.officer.edit)"
              class="btn btn-primary btn-sm margin-right-5"
              @click="$router.push({
                name: 'edit-officer',
                params: {
                  officer_uuid: $route.params.officer_uuid,
                }
              })"
            >
              <i class="fa fa-edit" /> {{ $t('button.edit') }}
            </button>
            <asora-back-button />
          </div>
        </div>
        <div class="box-body">
          <div class="form-horizontal">
            <div class="form-group row">
              <div class="col-sm-9 col-sm-offset-3">
                <render-image-tag :src="officer.avatar ? `${baseUrl}/${officer.avatar}` : null" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.title') }}</label>
              <div class="col-sm-9">
                <template v-if="officer.title_id">
                  <p class="text-bold">
                    : {{ officer.title['name_' + $i18n.locale] }}
                  </p>
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.nameInKm') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ officer.name_km }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.nameInEn') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ officer.name_en }}
                </p>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.email') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  <a :href="`mailto:${officer.email}`">
                    : {{ officer.email ? officer.email : $t('string.na') }}
                  </a>
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.phone') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  <a :href="`tel:${officer.phone}`">
                    : {{ officer.phone ? officer.phone : $t('string.na') }}
                  </a>
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.gender') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  :
                  {{ officer.gender ? officer.gender['name_' + $i18n.locale] : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('table.ministryOrProvinceOfficer') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ officer.imported_from || $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.officeOrWorkplace') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  :
                  {{ officer.place ? officer.place['name_' + $i18n.locale] : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.position') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  :
                  <template v-if="officer.positions && officer.positions!==null && officer.positions.length>0">
                    {{ officer.positions[0]['name_' + $i18n.locale] }}
                  </template>
                  <template v-else>
                    {{ $t('string.na') }}
                  </template>
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.homeNumber') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  :
                  {{ officer.home_number ? officer.home_number : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.streetNumber') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  :
                  {{ officer.street_number ? officer.street_number : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.groupNumber') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  :
                  {{ officer.group_number ? officer.group_number : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.province') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ officer.province ? officer.province['name_' + $i18n.locale] : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.district') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{
                    officer.district ? officer.district['name_' +
                      $i18n.locale] :
                    $t('string.na')
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.commune') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{
                    officer.commune ? officer.commune['name_' +
                      $i18n.locale] :
                    $t('string.na')
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.village') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{
                    officer.village ? officer.village['name_' +
                      $i18n.locale] :
                    $t('string.na')
                  }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.createdAt') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ getDate(officer.created_at, false) }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.updatedAt') }}</label>
              <div class="col-sm-9">
                <p class="text-bold">
                  : {{ getDate(officer.updated_at, false) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <upload-media
        :button-label="$t('label.chooseReference')"
        :model-uuid="$route.params.officer_uuid"
        :readonly="showForm"
        model-name="officer"
        preview-column="col-md-4"
      />
    </div>
  </div>
</template>

<script>
import RenderImageTag from "../../components/RenderImageTag"
import UploadMedia from "../../components/UploadMedia"

export default {
  name: "Show",
  components: {
    UploadMedia,
    RenderImageTag,
  },
  metaInfo() {
    return {
      title: this.$t('string.showOfficer'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      officer: {}
    }
  },
  computed: {
    showForm() {
      return this.$route.name === 'show-officer'
    }
  },
  created() {
    this.getOfficer()
  },
  methods: {
    getOfficer() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/officer/show', {
        officer_uuid: this.$route.params.officer_uuid,
      }).then((res) => {
        this.officer = res.data.data
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>
.control-label {
  padding-top: 0 !important;
}
</style>
