<template>
  <div
    id="constructionLicenseModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="constructionLicenseModalLabel"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div
      class="modal-dialog modal-lg tw-w-[90%]"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            aria-label="Close"
            @click="onClose"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4
            id="constructionLicenseModalLabel"
            class="modal-title"
          >
            {{ $t('string.manage_construction_license') }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="form-horizontal">
            <div class="row">
              <div class="col-sm-6">
                <div
                  class="form-group row"
                  :class="{'has-error' : validations.construction_certifier_number || validations.construction_certifier_suffix}"
                >
                  <label class="col-sm-4 control-label required">{{ $t('field.label.construction_certifier_number') }}</label>
                  <div class="col-sm-8">
                    <div class="input-group">
                      <input
                        v-model="certifierLicense.construction_certifier_number"
                        type="text"
                        class="form-control"
                        :placeholder="$t('field.placeholder.construction_certifier_number')"
                      >
                      <span class="input-group-addon">-</span>
                      <input
                        v-model="certifierLicense.construction_certifier_suffix"
                        type="text"
                        class="form-control"
                        :placeholder="$t('field.placeholder.construction_certifier_number_suffix')"
                      >
                    </div>
                    <template v-if="validations.construction_certifier_number">
                      <FiledIsRequired :msg="validations['construction_certifier_number'][0]" />
                    </template>
                    <template v-else-if="validations.construction_certifier_suffix">
                      <FiledIsRequired :msg="validations['construction_certifier_suffix'][0]" />
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div
                  class="form-group row"
                  :class="{'has-error' : validations.first_issue_date || validations.valid_until}"
                >
                  <label class="col-sm-4 control-label required">{{ $t('field.label.construction_certifier_first_issue_date') }}</label>
                  <div class="col-sm-8">
                    <div class="input-group">
                      <date-picker
                        v-model="certifierLicense.first_issue_date"
                        :lang="datepickerLocale[$i18n.locale].lang"
                        :placeholder="$t('field.placeholder.construction_certifier_valid_until_date')"
                        type="date"
                        format="DD/MM/YYYY"
                        class="form-control tw-w-full tw-p-0 tw-border-none tw-rounded-none"
                      />
                      <span class="input-group-addon">-</span>
                      <date-picker
                        v-model="certifierLicense.valid_until"
                        :lang="datepickerLocale[$i18n.locale].lang"
                        :placeholder="$t('field.placeholder.construction_certifier_valid_until_date')"
                        type="date"
                        format="DD/MM/YYYY"
                        class="form-control tw-w-full tw-p-0 tw-border-none tw-rounded-none"
                      />
                    </div>
                    <template v-if="validations.valid_until">
                      <FiledIsRequired :msg="validations['valid_until'][0]" />
                    </template>
                    <template v-else-if="validations.first_issue_date">
                      <FiledIsRequired :msg="validations['first_issue_date'][0]" />
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div
                  class="form-group row"
                  :class="{'has-error' : validations.construction_certifier_mfc_number}"
                >
                  <label class="col-sm-4 control-label required">{{ $t('field.label.construction_certifier_mfc_license') }}</label>
                  <div class="col-sm-8">
                    <input
                      v-model="certifierLicense.construction_certifier_mfc_number"
                      type="text"
                      class="form-control"
                      :placeholder="$t('field.placeholder.construction_certifier_mfc_number')"
                    >
                    <template v-if="validations.construction_certifier_mfc_number">
                      <FiledIsRequired :msg="validations['construction_certifier_mfc_number'][0]" />
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div
                  class="form-group row"
                  :class="{'has-error' : validations.construction_certifier_mfc_date}"
                >
                  <label class="col-sm-4 control-label required">{{ $t('field.label.construction_certifier_mfc_date') }}</label>
                  <div class="col-sm-8">
                    <date-picker
                      v-model="certifierLicense.construction_certifier_mfc_date"
                      :lang="datepickerLocale[$i18n.locale].lang"
                      :placeholder="$t('field.placeholder.construction_certifier_valid_until_date')"
                      type="date"
                      format="DD/MM/YYYY"
                      class="form-control tw-w-full tw-p-0 tw-border-none tw-rounded-none"
                    />
                    <template v-if="validations.construction_certifier_mfc_date">
                      <FiledIsRequired :msg="validations['construction_certifier_mfc_date'][0]" />
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div
                  class="form-group row"
                  :class="{'has-error' : validations.company_name_km}"
                >
                  <label class="col-sm-4 control-label required">{{ $t('field.label.construction_certifier_company_name_km') }}</label>
                  <div class="col-sm-8">
                    <input
                      v-model="certifierLicense.company_name_km"
                      type="text"
                      class="form-control"
                      :placeholder="$t('field.placeholder.construction_certifier_company_name_km')"
                    >
                    <template v-if="validations.company_name_km">
                      <FiledIsRequired :msg="validations['company_name_km'][0]" />
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div
                  class="form-group row"
                  :class="{'has-error' : validations.company_name_en}"
                >
                  <label class="col-sm-4 control-label required">{{ $t('field.label.construction_certifier_company_name_en') }}</label>
                  <div class="col-sm-8">
                    <input
                      v-model="certifierLicense.company_name_en"
                      type="text"
                      class="form-control"
                      :placeholder="$t('field.placeholder.construction_certifier_company_name_en')"
                    >
                    <template v-if="validations.company_name_en">
                      <FiledIsRequired :msg="validations['company_name_en'][0]" />
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div
                  class="form-group row"
                  :class="{'has-error' : validations.president_ceo_km}"
                >
                  <label class="col-sm-4 control-label required">{{ $t('field.label.construction_certifier_president_ceo_km') }}</label>
                  <div class="col-sm-8">
                    <input
                      v-model="certifierLicense.president_ceo_km"
                      type="text"
                      class="form-control"
                      :placeholder="$t('field.placeholder.construction_certifier_president_ceo_km')"
                    >
                    <template v-if="validations.president_ceo_km">
                      <FiledIsRequired :msg="validations['president_ceo_km'][0]" />
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div
                  class="form-group row"
                  :class="{'has-error' : validations.president_ceo_en}"
                >
                  <label class="col-sm-4 control-label required">{{ $t('field.label.construction_certifier_president_ceo_en') }}</label>
                  <div class="col-sm-8">
                    <input
                      v-model="certifierLicense.president_ceo_en"
                      type="text"
                      class="form-control"
                      :placeholder="$t('field.placeholder.construction_certifier_president_ceo_en')"
                    >
                    <template v-if="validations.president_ceo_en">
                      <FiledIsRequired :msg="validations['president_ceo_en'][0]" />
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div
                  class="form-group row"
                  :class="{'has-error' : validations.technical_director_km}"
                >
                  <label class="col-sm-4 control-label required">{{ $t('field.label.construction_certifier_technical_director_km') }}</label>
                  <div class="col-sm-8">
                    <input
                      v-model="certifierLicense.technical_director_km"
                      type="text"
                      class="form-control"
                      :placeholder="$t('field.placeholder.construction_certifier_technical_director_km')"
                    >
                    <template v-if="validations.technical_director_km">
                      <FiledIsRequired :msg="validations['technical_director_km'][0]" />
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div
                  class="form-group row"
                  :class="{'has-error' : validations.technical_director_en}"
                >
                  <label class="col-sm-4 control-label required">{{ $t('field.label.construction_certifier_technical_director_en') }}</label>
                  <div class="col-sm-8">
                    <input
                      v-model="certifierLicense.technical_director_en"
                      type="text"
                      class="form-control"
                      :placeholder="$t('field.placeholder.construction_certifier_technical_director_en')"
                    >
                    <template v-if="validations.technical_director_en">
                      <FiledIsRequired :msg="validations['technical_director_en'][0]" />
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div
                  class="form-group row"
                  :class="{'has-error' : validations.class_km}"
                >
                  <label class="col-sm-4 control-label required">{{ $t('field.label.construction_certifier_class_km') }}</label>
                  <div class="col-sm-8">
                    <input
                      v-model="certifierLicense.class_km"
                      type="text"
                      class="form-control"
                      :placeholder="$t('field.placeholder.construction_certifier_class_km')"
                    >
                    <template v-if="validations.class_km">
                      <FiledIsRequired :msg="validations['class_km'][0]" />
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div
                  class="form-group row"
                  :class="{'has-error' : validations.class_en}"
                >
                  <label class="col-sm-4 control-label required">{{ $t('field.label.construction_certifier_class_en') }}</label>
                  <div class="col-sm-8">
                    <input
                      v-model="certifierLicense.class_en"
                      type="text"
                      :placeholder="$t('field.placeholder.construction_certifier_class_en')"
                      class="form-control"
                    >
                    <template v-if="validations.class_en">
                      <FiledIsRequired :msg="validations['class_en'][0]" />
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div
                  class="form-group row"
                  :class="{'has-error' : validations.level_km}"
                >
                  <label class="col-sm-4 control-label required">{{ $t('field.label.construction_certifier_level_km') }}</label>
                  <div class="col-sm-8">
                    <input
                      v-model="certifierLicense.level_km"
                      :placeholder="$t('field.placeholder.construction_certifier_level_km')"
                      type="text"
                      class="form-control"
                    >
                    <template v-if="validations.level_km">
                      <FiledIsRequired :msg="validations['level_km'][0]" />
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div
                  class="form-group row"
                  :class="{'has-error' : validations.level_en}"
                >
                  <label class="col-sm-4 control-label required">{{ $t('field.label.construction_certifier_level_en') }}</label>
                  <div class="col-sm-8">
                    <input
                      v-model="certifierLicense.level_en"
                      type="text"
                      :placeholder="$t('field.placeholder.construction_certifier_level_en')"
                      class="form-control"
                    >
                    <template v-if="validations.level_en">
                      <FiledIsRequired :msg="validations['level_en'][0]" />
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div
                  class="form-group row"
                  :class="{'has-error' : validations.head_office_km}"
                >
                  <label class="col-sm-4 control-label required">{{ $t('field.label.construction_certifier_head_office_km') }}</label>
                  <div class="col-sm-8">
                    <textarea
                      v-model="certifierLicense.head_office_km"
                      type="text"
                      :placeholder="$t('field.placeholder.construction_certifier_head_office_km')"
                      rows="4"
                      class="form-control"
                    />
                    <template v-if="validations.head_office_km">
                      <FiledIsRequired :msg="validations['head_office_km'][0]" />
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div
                  class="form-group row"
                  :class="{'has-error' : validations.head_office_en}"
                >
                  <label class="col-sm-4 control-label required">{{ $t('field.label.construction_certifier_head_office_en') }}</label>
                  <div class="col-sm-8">
                    <textarea
                      v-model="certifierLicense.head_office_en"
                      type="text"
                      :placeholder="$t('field.placeholder.construction_certifier_head_office_en')"
                      rows="4"
                      class="form-control"
                    />
                    <template v-if="validations.head_office_en">
                      <FiledIsRequired :msg="validations['head_office_en'][0]" />
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-default"
            @click="onClose"
          >
            {{ $t('button.close') }}
          </button>
          <template v-if="editLicenseUuid">
            <button
              type="button"
              class="btn btn-info"
              @click="update"
            >
              {{ $t('button.update') }}
            </button>
          </template>
          <template v-else>
            <button
              type="button"
              class="btn btn-primary"
              @click="save"
            >
              {{ $t('button.save') }}
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields"
import FiledIsRequired from "@/components/FiledIsRequired"

export default {
  name: "ConstructionLicenseModal",
  components: {FiledIsRequired},
  data () {
    return {
      certifierLicense: {
        company_uuid: null,
        class_en: null,
        class_km: null,
        company_name_en: null,
        company_name_km: null,
        construction_certifier_mfc_date: null,
        construction_certifier_mfc_number: null,
        construction_certifier_number: null,
        construction_certifier_suffix: null,
        first_issue_date: null,
        head_office_en: null,
        head_office_km: null,
        level_en: null,
        level_km: null,
        president_ceo_en: null,
        president_ceo_km: null,
        technical_director_en: null,
        technical_director_km: null,
        valid_until: null,
      },
      validations: {}
    }
  },
  computed: {
    ...mapFields('company', {
      companyUuid: 'constructionCertifier.companyUuid',
      editLicenseUuid: 'constructionCertifier.editLicenseUuid',
      selectedLicense: 'constructionCertifier.selectedLicense',
    })
  },
  watch: {
    companyUuid () {
      if (this.companyUuid) {
        this.getPrepareData()
      }
    },
    editLicenseUuid () {
      if (this.editLicenseUuid) {
        this.getPrepareData()
      }
    }
  },
  methods: {
    getPrepareData () {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/construction-certifier-license/get-prepare-data', {
        company_uuid: this.$route.params.company_uuid,
        edit_license_uuid: this.editLicenseUuid
      }).then(({data}) => {
        if (data.data) {
          this.certifierLicense = data.data
          const {valid_until, first_issue_date, construction_certifier_mfc_date} = data.data

          this.certifierLicense.valid_until = null
          if (valid_until && this.$moment(valid_until).isValid()) {
            this.certifierLicense.valid_until = this.$moment(valid_until).toDate()
          }

          this.certifierLicense.first_issue_date = null
          if (first_issue_date && this.$moment(first_issue_date).isValid()) {
            this.certifierLicense.first_issue_date = this.$moment(first_issue_date).toDate()
          }

          this.certifierLicense.construction_certifier_mfc_date = null
          if (construction_certifier_mfc_date && this.$moment(construction_certifier_mfc_date).isValid()) {
            this.certifierLicense.construction_certifier_mfc_date = this.$moment(construction_certifier_mfc_date).toDate()
          }
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    save () {
      this.validations = {}
      let params = Object.assign({}, this.certifierLicense)
      params.company_uuid = this.$route.params.company_uuid
      const valid_until = params.valid_until
      if (valid_until && this.$moment(valid_until).isValid()) {
        params.valid_until = this.$moment(valid_until).locale('en').format('YYYY-MM-DD')
      } else {
        params.valid_until = null
      }

      const {
        first_issue_date,
        construction_certifier_mfc_date
      } = params

      if (first_issue_date && this.$moment(first_issue_date).isValid()) {
        params.first_issue_date = this.$moment(first_issue_date).format('YYYY-MM-DD')
      } else {
        params.first_issue_date = null
      }

      if (construction_certifier_mfc_date && this.$moment(construction_certifier_mfc_date).isValid()) {
        params.construction_certifier_mfc_date  = this.$moment(construction_certifier_mfc_date).format('YYYY-MM-DD')
      } else {
        params.construction_certifier_mfc_date = null
      }

      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/construction-certifier-license/create', params)
        .then(({data}) => {
          if (data.data) {
            this.resetForm()
            this.showToastr()
            this.$emit('refreshList')
            $('#constructionLicenseModal').modal('hide')
          }
        })
        .catch((error) => {
          if (error.response && error.response.status && error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    update () {
      this.validations = {}
      let params = Object.assign({}, this.certifierLicense)
      params.construction_certifier_license_uuid = this.editLicenseUuid
      const valid_until = params.valid_until
      if (valid_until && this.$moment(valid_until).isValid()) {
        params.valid_until = this.$moment(valid_until).locale('en').format('YYYY-MM-DD')
      } else {
        params.valid_until = null
      }

      const {
        first_issue_date,
        construction_certifier_mfc_date
      } = params

      if (first_issue_date && this.$moment(first_issue_date).isValid()) {
        params.first_issue_date = this.$moment(first_issue_date).format('YYYY-MM-DD')
      } else {
        params.first_issue_date = null
      }

      if (construction_certifier_mfc_date && this.$moment(construction_certifier_mfc_date).isValid()) {
        params.construction_certifier_mfc_date  = this.$moment(construction_certifier_mfc_date).format('YYYY-MM-DD')
      } else {
        params.construction_certifier_mfc_date = null
      }

      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/construction-certifier-license/update', params)
        .then(({data}) => {
          if (data.data) {
            this.selectedLicense = null
            this.resetForm()
            this.showToastr()
            this.$emit('refreshList')
            $('#constructionLicenseModal').modal('hide')
          }
        })
        .catch((error) => {
          if (error.response && error.response.status && error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    resetForm () {
      this.companyUuid = null
      this.editLicenseUuid = null
      this.validations = {}
      this.certifierLicense = {
        company_uuid: null,
        class_en: null,
        class_km: null,
        company_name_en: null,
        company_name_km: null,
        construction_certifier_mfc_date: null,
        construction_certifier_mfc_number: null,
        construction_certifier_number: null,
        construction_certifier_suffix: null,
        first_issue_date: null,
        head_office_en: null,
        head_office_km: null,
        level_en: null,
        level_km: null,
        president_ceo_en: null,
        president_ceo_km: null,
        technical_director_en: null,
        technical_director_km: null,
        valid_until: null,
      }
    },
    onClose () {
      this.resetForm()
      $('#constructionLicenseModal').modal('hide')
    }
  }
}
</script>

<style scoped>

</style>
