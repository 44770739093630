<template>
  <show-people
    :edit-permission="$Permissions.backend.investorConstruction.personality.edit"
    :mode-show="true"
    :title="$t('string.showPersonality')"
    name-route-edit="edit-personality"
  />
</template>

<script>
import ShowPeople from "../../people/Show"

export default {
  name: "Show",
  components: {ShowPeople},
  metaInfo() {
    return {
      title: this.$t('string.showPersonality'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
}
</script>

<style scoped>

</style>
