<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.newDeikarbanhchoun') }}
      </h3>
    </div>
    <div class="box-body">
      <deikarbanh-choun-form />
    </div>
  </div>
</template>

<script>
import DeikarbanhChounForm from "./Form"

export default {
  name: "Create",
  metaInfo() {
    return {
      title: this.$t('string.newDeikarbanhchoun'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  components: {DeikarbanhChounForm}
}
</script>

<style scoped>

</style>
