<template>
  <mission-form
    :title="$t('label.newMission')"
    :button="{}"
  />
</template>

<script>
import MissionForm from "./Form"
export default {
  name: "Create",
  metaInfo() {
    return {
      title: this.$t('label.newMission'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {MissionForm}
}
</script>

<style scoped>

</style>
