<template>
  <div class="form-horizontal">
    <div
      :class="{'has-error' : validations.company_uuid}"
      class="form-group row"
    >
      <label class="col-xs-2 control-label required">{{ $t('string.searchCompany') }}</label>
      <div class="col-xs-10">
        <multiselect
          v-model="selected_company"
          :disabled="disabled"
          :options="companies"
          track-by="uuid"
          :internal-search="false"
          :placeholder="$t('string.searchCompany')"
          label="label"
          @search-change="searchCompany"
        />
        <template v-if="validations.company_uuid">
          <span class="label-error">{{ validations.company_uuid[0] }}</span>
        </template>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-xs-10 col-xs-offset-2">
        <h4 class="main-title">
          {{ $t('string.deikarbanhchounReference') }}
        </h4>
      </div>
    </div>

    <div
      :class="{'has-error' : contentValidation}"
      class="form-group row"
    >
      <label class="control-label col-sm-2" />
      <div class="col-sm-10">
        <div class="row">
          <div class="col-md-12 margin-bottom">
            <div class="input-group">
              <input
                v-model="content"
                :placeholder="$t('string.enter_deikarbanhchoun_reference')"
                class="form-control"
                type="text"
                @keydown.enter="addNewContent"
              >
              <span
                class="input-group-btn"
                @click="addNewContent"
              >
                <button class="btn btn-default">
                  <i class="fa fa-plus" /> {{ $t('string.add') }}
                </button>
              </span>
            </div>
            <template v-if="contentValidation">
              <span class="label-error">{{ $t('string.requiredMessage') }}</span>
            </template>
          </div>

          <div class="col-md-12">
            <template v-if="contents.length>0">
              <draggable
                v-model="contents"
                v-bind="contents"
                class="list-group"
                tag="ul"
                @end="isDragging = false"
                @start="isDragging = true"
              >
                <transition-group
                  name="flip-list"
                  type="transition"
                >
                  <li
                    v-for="element in contents"
                    :key="element.order"
                    class="list-group-item"
                  >
                    <div class="drag-item">
                      <div class="drag-icon">
                        -
                      </div>
                      <div class="drag-title">
                        {{ element.value }}
                      </div>
                      <div class="drag-action">
                        <button
                          class="btn btn-danger btn-xs"
                          @click="onDelete(element)"
                        >
                          <i class="fa fa-trash-alt" />
                        </button>
                      </div>
                    </div>
                  </li>
                </transition-group>
              </draggable>
            </template>
            <template v-else>
              <p class="text-muted text-center">
                {{ $t('string.no_deikarbanhchoun_reference') }}
              </p>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-10 col-sm-offset-2">
        <button
          class="btn btn-primary btn-sm margin-r-5"
          @click="save"
        >
          {{ $t('button.save') }}
        </button>
        <asora-back-button />
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import {debounce} from 'debounce'

export default {
  name: "DeikarbanhchounForm",
  components: {
    draggable,
  },
  data() {
    return {
      contents: [],
      content: '',
      contentValidation: false,
      selected_company: null,
      companies: [],
      validations: {},
      deikarbanhchoun: null
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
    disabled() {
      return !!this.$route.params.deikarbanhchoun_uuid
    }
  },
  watch: {
    selected_company() {
      if (this.selected_company && this.selected_company.uuid && !this.$route.params.deikarbanhchoun_uuid) {
        this.show()
      }
    },
    deikarbanhchoun() {
      if (this.deikarbanhchoun && this.deikarbanhchoun.company) {
        const company = this.deikarbanhchoun.company
        this.selected_company = {
          uuid: company.uuid,
          label: `(${company.first_registration_number}) - ${company.name_km}`
        }
      }
    }
  },
  methods: {
    addNewContent() {
      this.contentValidation = false
      if (this.content === '' || this.content === undefined) {
        this.contentValidation = true
        return 0
      }
      this.contents.push({
        id: parseInt(this.contents.length) + 1,
        value: this.content,
        order: parseInt(this.contents.length) + 1,
      })
      this.content = ''
    },
    save() {
      this.validations = {}
      this.$isLoading(true)
      let params = {
        contents: this.contents,
        company_uuid: this.selected_company && this.selected_company.uuid ? this.selected_company.uuid : null,
        deikarbanhchoun_uuid: this.$route.params.deikarbanhchoun_uuid ? this.$route.params.deikarbanhchoun_uuid : null
      }

      this.$axios.post(`${this.baseUrl}/api/backend/company/deikarbanhchouns/store`, params)
        .then(({data}) => {
          if (data.data) {
            this.showToastr()
            this.$router.push({
              name: 'list-deikarbanhchouns-company'
            })
          }
        })
        .catch((error) => {
          this.$isLoading(false)
          if (error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    show() {
      let api = `${this.baseUrl}/api/backend/company/deikarbanhchouns/get-deikarbanhchoun-by-company`
      let params = {
        company_uuid: this.selected_company ? this.selected_company.uuid : null
      }

      if (this.$route.params.deikarbanhchoun_uuid) {
        api = `${this.baseUrl}/api/backend/company/deikarbanhchouns/show`
        params = {
          deikarbanhchoun_uuid: this.$route.params.deikarbanhchoun_uuid
        }
      }

      this.$axios.post(api, params)
        .then(({data}) => {
          if (data.data) {
            const deikarbanhchoun = data.data
            this.deikarbanhchoun = deikarbanhchoun

            if (deikarbanhchoun && deikarbanhchoun.company) {
              this.company = deikarbanhchoun.company
            }

            let company_deikarbanhchoun_items = deikarbanhchoun.company_deikarbanhchoun_items
            if (company_deikarbanhchoun_items && company_deikarbanhchoun_items.length > 0) {
              this.contents = company_deikarbanhchoun_items.map(item => {
                return {
                  id: item.id,
                  value: item.value,
                  order: item.order,
                }
              })
            }
          }
        })
    },
    onDelete(element) {
      this.onConfirm()
        .then((accept) => {
          if (accept) {
            this.contents.splice(this.contents.indexOf(element), 1)
          }
        })
    },
    searchCompany: debounce(function (query_text = null) {
      this.$axios.post(`${this.baseUrl}/api/backend/company/notes/search-company`, {
        query_text: query_text
      }).then(({data}) => {
        this.companies = data.data
      })
    }),
  },
  mounted() {
    this.searchCompany()
    if (this.$route.params.deikarbanhchoun_uuid) {
      this.show()
    }
  }
}
</script>

<style scoped>
.drag-item {
  display: grid;
  grid-template-columns: 20px 1fr 30px;
}

p.text-muted {
  padding: 25px;
  background-color: #dddddd;
  width: 100%;
}
</style>
