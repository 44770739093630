<template>
  <div class="col-md-12">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t('string.permit') }}
        </h3>
      </div>
      <div class="box-body">
        <div class="row">
          <div class="col-md-12">
            <p><strong>{{ $t('string.permitSection') }}</strong></p>
          </div>
          <div class="col-md-12">
            <table class="table table-striped table-bordered">
              <tbody>
                <tr>
                  <th>{{ $t('label.permitNumber') }}</th>
                  <td>
                    <template v-if="project.permit_number">
                      {{ project.permit_number }}
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('label.permitDate') }}</th>
                  <td>
                    <template v-if="project.permit_date">
                      {{ getDate(project.permit_date, false, 'DD/MM/YYYY') }}
                    </template>
                    <template v-else>
                      {{ $t('string.na') }}
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-12">
            <p><strong>{{ $t('label.openConstructionSection') }}</strong></p>
          </div>
          <div class="col-md-12">
            <template
              v-if="project.open_construction_numbers
                && project.open_construction_numbers.length>0"
            >
              <table class="table table-striped">
                <thead>
                  <tr>
                    <td>{{ $t('string.times') }}</td>
                    <td>{{ $t('string.number') }}</td>
                    <td>{{ $t('string.date') }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, key) in project.open_construction_numbers"
                    :key="key"
                  >
                    <td>{{ (key + 1) }}</td>
                    <td>{{ item.number || $t('string.na') }}</td>
                    <td>{{ getDate(item.date, false, 'DD/MM/YYYY') }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
            <template v-else>
              {{ $t('string.na') }}
            </template>
          </div>
          <div class="col-md-12">
            <p><strong>{{ $t('label.closeConstructionSection') }}</strong></p>
          </div>
          <div class="col-md-12">
            <template
              v-if="project.stop_construction_numbers
                && project.stop_construction_numbers.length>0"
            >
              <table class="table table-striped">
                <thead>
                  <tr>
                    <td>{{ $t('string.times') }}</td>
                    <td>{{ $t('string.number') }}</td>
                    <td>{{ $t('string.date') }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, key) in project.stop_construction_numbers"
                    :key="key"
                  >
                    <td>{{ (key + 1) }}</td>
                    <td>{{ item.number || $t('string.na') }}</td>
                    <td>{{ getDate(item.date, false) }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
            <template v-else>
              {{ $t('string.na') }}
            </template>
          </div>
          <div class="col-md-12">
            <p><strong>{{ $t('label.constructionPendingSection') }}</strong></p>
          </div>
          <div class="col-md-12">
            <template
              v-if="project.pending_construction_numbers
                && project.pending_construction_numbers.length>0"
            >
              <table class="table table-striped">
                <thead>
                  <tr>
                    <td>{{ $t('string.times') }}</td>
                    <td>{{ $t('string.number') }}</td>
                    <td>{{ $t('string.date') }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, key) in project.pending_construction_numbers"
                    :key="key"
                  >
                    <td>{{ (key + 1) }}</td>
                    <td>{{ item.number || $t('string.na') }}</td>
                    <td>{{ getDate(item.date, false, 'DD/MM/YYYY') }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
            <template v-else>
              {{ $t('string.na') }}
            </template>
          </div>
          <div class="col-md-12">
            <p><strong>{{ $t('label.complaint') }}</strong></p>
          </div>
          <div class="col-md-12">
            <template
              v-if="project.complaints
                && project.complaints.length>0"
            >
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>{{ $t('string.complaintName') }}</th>
                    <th>{{ $t('string.complaintDate') }}</th>
                    <th>{{ $t('string.complaintPhone') }}</th>
                    <th>{{ $t('string.complaintDesc') }}</th>
                    <th>{{ $t('string.complaintSolution') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, key) in project.complaints"
                    :key="key"
                  >
                    <td>{{ item.name }}</td>
                    <td>{{ getDate(item.date, false) }}</td>
                    <td>{{ item.phone }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.solution }}</td>
                  </tr>
                </tbody>
              </table>
            </template>
            <template v-else>
              {{ $t('string.na') }}
            </template>
          </div>
          <div class="col-md-12">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <th>{{ $t('label.isConstructedBeforeDate?') }}</th>
                  <td>
                    <p>
                      {{ project.is_constructed_before_date ? $t('string.true') : $t('string.false') }}
                    </p>
                    <p v-if="project.is_constructed_before_date===true">
                      {{ $t('string.description') }}: {{ project.is_constructed_before_date_description }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PermitPanel",
  props: {
    project: {
      type: Object,
    }
  }
}
</script>

<style scoped>

</style>
