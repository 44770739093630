import TeamCategory from "./../../views/settings/teamCategory/Index"
import List from "./../../views/settings/teamCategory/List"
import Create from "./../../views/settings/teamCategory/Create"
import Edit from "./../../views/settings/teamCategory/Edit"
import Show from "./../../views/settings/teamCategory/Show"
import Permissions from "../../permissions"

export const teamCategoryRouters = {
  path: 'team-category',
  component: TeamCategory,
  redirect: 'team-category',
  meta: {
    groups: 'team-category',
    permissions: [Permissions.backend.setting.teamCategory.manage]
  },
  children: [
    {
      path: '',
      name: 'list-team-category',
      component: List,
      meta: {
        groups: 'team-category',
        permissions: [Permissions.backend.setting.teamCategory.list],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Team Category',
            name_km: 'ប្រភេទក្រុមមន្ត្រី'
          },
          {
            name_en: 'List',
            name_km: 'បញ្ជី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-team-category',
      component: Create,
      meta: {
        groups: 'team-category',
        permissions: [Permissions.backend.setting.teamCategory.store],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Team Category',
            name_km: 'ប្រភេទក្រុមមន្ត្រី'
          },
          {
            name_en: 'New',
            name_km: 'បង្កើតថ្មី'
          }
        ]
      }
    },
    {
      path: 'edit/:team_category_uuid',
      name: 'edit-team-category',
      component: Edit,
      meta: {
        groups: 'team-category',
        permissions: [Permissions.backend.setting.teamCategory.edit],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Team Category',
            name_km: 'ប្រភេទក្រុមមន្ត្រី'
          },
          {
            name_en: 'Edit',
            name_km: 'កែសម្រួល'
          }
        ]
      }
    },
    {
      path: 'show/:team_category_uuid',
      name: 'show-team-category',
      component: Show,
      meta: {
        groups: 'team-category',
        permissions: [Permissions.backend.setting.teamCategory.show],
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Team Category',
            name_km: 'ប្រភេទក្រុមមន្ត្រី'
          },
          {
            name_en: 'View',
            name_km: 'មើល'
          }
        ]
      }
    }
  ]
}
