import Error from './../views/errors/Error'
import Primary from "../layouts/Primary"

export const errorsRouters = {
  path: '*',
  name: 'errors',
  component: Primary,
  meta: {
    noAuth: true
  },
  children: [
    {
      path: '*',
      component: Error,
      meta: {
        breadcrumb: [
          {
            name_en: 'Error',
            name_km: 'Error'
          },
          {
            name_en: '404',
            name_km: '404'
          }
        ]
      }
    }
  ]
}
