<template>
  <div class="col-md-12">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t('string.architecturesSection') }}
        </h3>
      </div>
      <div class="box-body">
        <table class="table table-striped table-bordered table-condensed">
          <tbody>
            <tr
              v-for="(column, index) in columnsArray"
              :key="index+3"
            >
              <th>{{ column.label }}</th>
              <td>
                <template v-if="project[column.key] && project[column.key].length>0">
                  <template v-for="(model, subIndex) in project[column.key]">
                    <people-chip-item
                      :key="subIndex"
                      :model="column.model_type"
                      :people="model.model_name === 'company' ? getPrepareCompany(model) : getPrepareArchitecture(model)"
                    />
                  </template>
                </template>
                <template v-else>
                  {{ $t('string.na') }}
                </template>
              </td>
            </tr>
            <tr
              v-for="(checkbox, checkboxIndex) in columnsSingleCheckbox"
              :key="checkboxIndex"
            >
              <th>{{ checkbox.label }}</th>
              <td>{{ getSystemLabel(project[checkbox.key]) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ArchitecturalPanel",
  props: {
    project: {
      type: Object,
    }
  },
  computed: {
    columnsArray() {
      return [
        {
          model_type: 'company',
          label: this.$t('label.drawnByCompany'),
          key: 'architectural_drawn_by_companies',
        },
        {
          model_type: 'architecture',
          label: this.$t('label.drawnBy'),
          key: 'architectural_drawn_by_architects',
        },
        {
          model_type: 'company',
          label: this.$t('label.studyByCompany'),
          key: 'architectural_studied_by_companies',
        },
        {
          model_type: 'architecture',
          label: this.$t('label.studiedBy'),
          key: 'architectural_studied_by_architects',
        },
        {
          model_type: 'company',
          label: this.$t('label.confirmAndReviewedBy'),
          key: 'architectural_confirmed_and_checked_by_companies',
        },
        {
          model_type: 'architecture',
          label: this.$t('label.confirmAndCheckBy'),
          key: 'architectural_confirmed_and_checked_by_architects',
        },
        {
          model_type: 'company',
          label: this.$t('label.confirmAndReviewedBy'),
          key: 'architectural_confirmed_and_checked_by_companies',
        },
        {
          model_type: 'architecture',
          label: this.$t('label.confirmAndCheckBy'),
          key: 'architectural_confirmed_and_checked_by_architects',
        },
      ]
    },
    columnsSingleCheckbox() {
      return [
        {label: this.$t('label.fireExtinguisherSystem'), key: 'architectural_has_fire_system'},
        {label: this.$t('label.wastewaterTreatmentTank'), key: 'architectural_has_water_system'},
        {label: this.$t('label.disabilityGradient'), key: 'architectural_has_gradient_disability'},
      ]
    }
  },
  methods: {
    getSystemLabel(str) {
      switch (str) {
      case 'have':
        return this.$t('string.have')
      case 'does_not_have':
        return this.$t('string.noHave')
      case 'none_confirmed':
        return this.$t('string.noneConfirmed')
      default:
        return this.$t('string.na')
      }
    }
  }
}
</script>

<style scoped>

</style>
