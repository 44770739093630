<template>
  <div class="row">
    <div class="col-md-12">
      <hr>
    </div>
    <div class="form-group row">
      <div class="col-sm-12 margin-left-15">
        <h4 class="main-title">
          {{ title }}
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectHeaderSection",
  props: {
    title: {
      type: String,
      required: true,
    }
  }
}
</script>

<style scoped>
  hr {
    margin: 0;
    padding: 0;
    border: 0.6px solid #e6e6e6 !important;
  }
</style>
