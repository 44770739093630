<template>
  <company-show
    :mode-show="true"
    :edit-permission="[
      Permissions.backend.businessBuilders.checkAndConfirmPlanCompany.edit,
      Permissions.backend.businessBuilders.checkAndConfirmConstructionCompany.edit,
    ]"
    name-route-edit="edit-check-and-confirm-construction-company"
    :title="$t('string.showCheckAndConfirmConstructionCompany')"
  />
</template>

<script>
import CompanyShow from "../../company/Show"
import Permissions from '@/permissions'

export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('string.showCheckAndConfirmConstructionCompany'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {CompanyShow},
  data () {
    return {
      Permissions
    }
  }
}
</script>

<style scoped>

</style>
