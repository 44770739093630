<template>
  <inspection-list
    status="draft"
    model-route-view="show-inspection-draft"
  />
</template>

<script>
import InspectionList from "../sections/InspectionList"
export default {
  name: "List",
  components: {InspectionList}
}
</script>

<style scoped>

</style>
