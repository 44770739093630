<template>
  <div class="row">
    <div :class="divContainerClass">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('string.officer') }}
          </h3>
          <div class="box-tools">
            <button
              v-if="can([Permissions.backend.officer.store])"
              class="btn btn-primary btn-sm margin-right-5"
              @click="$router.push({name: 'create-officer'})"
            >
              <i class="fa fa-plus" /> {{ $t('button.newOfficer') }}
            </button>
            <template v-if="can([Permissions.backend.officer.filter])">
              <button
                class="btn btn-danger btn-sm"
                @click="toggleFilter"
              >
                <i class="fa fa-filter" /> {{ $t('string.filter') }}
              </button>
            </template>
          </div>
        </div>
        <div class="box-body">
          <div>
            <table
              id="officer-table"
              class="table table-hover table-full-width table-striped table-bordered"
            >
              <thead>
                <tr>
                  <th class="width-avatar">
                    {{ $t('table.avatar') }}
                  </th>
                  <th>{{ $t('label.name') }}</th>
                  <th class="width-gender">
                    {{ $t('table.gender') }}
                  </th>
                  <th>{{ $t('table.place') }}</th>
                  <th style="width: 100px !important;">
                    {{ $t('table.ministryOrProvinceOfficer') }}
                  </th>
                  <th>{{ $t('table.phone') }}</th>
                  <th>{{ $t('table.email') }}</th>
                  <th class="width-created-at">
                    {{ $t('table.createdAt') }}
                  </th>
                  <th class="width-action">
                    {{ $t('table.action') }}
                  </th>
                </tr>
              </thead>
              <tbody />
            </table>
          </div>
        </div>
      </div>
    </div>
    <template v-if="can([Permissions.backend.officer.filter]) && showFilter">
      <div class="col-md-2 pl-md-0">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">
              {{ $t('string.filter') }}
            </h3>
          </div>
          <div class="box-body">
            <div class="form-group">
              <label>{{ $t('label.officerName') }}</label>
              <input
                v-model="name"
                :placeholder="$t('label.officerName')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('label.title') }}</label>
              <select
                v-model="title_id"
                class="form-control"
                name="team-category"
              >
                <option
                  :value="null"
                  selected
                >
                  {{ $t('string.all') }}
                </option>
                <option
                  v-for="(title, key) in titles"
                  :key="key"
                  :value="title.id"
                >
                  {{ title['name_' + $i18n.locale] }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>{{ $t('table.phone') }}</label>
              <input
                v-model="phone"
                :placeholder="$t('table.phone')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.email') }}</label>
              <input
                v-model="email"
                :placeholder="$t('table.email')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.place') }}</label>
              <input
                v-model="office"
                :placeholder="$t('table.place')"
                class="form-control"
                type="text"
              >
            </div>
            <div class="form-group">
              <label>{{ $t('table.ministryOrProvinceOfficer') }}</label>
              <select
                v-model="loaction_office"
                class="form-control"
                name="team-category"
              >
                <option
                  :value="null"
                  selected
                >
                  {{ $t('string.all') }}
                </option>
                <option
                  v-for="(option, key) in ministryOrProvince"
                  :key="key"
                  :value="option.value"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <div class="row">
                <div class="form-group col-md-6">
                  <button
                    class="btn btn-primary btn-block btn-sm"
                    @click="search"
                  >
                    <i class="fa fa-search" />
                    {{ $t('button.search') }}
                  </button>
                </div>
                <div class="form-group col-md-6 pl-lg-0">
                  <button
                    class="btn btn-default btn-block btn-sm"
                    @click="reset"
                  >
                    <i class="fas fa-redo-alt" />
                    {{ $t('button.reset') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Permissions from "@/permissions"
export default {
  name: 'List',
  metaInfo() {
    return {
      title: this.$t('menu.officer'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      Permissions,
      showFilter: false,
      oTable: null,
      teams: [],
      team: 'all',
      name: null,
      title_id: null,
      titles: [],
      phone: null,
      email: null,
      office: null,
      loaction_office: null
    }
  },
  computed: {
    ministryOrProvince() {
      return [
        {value: 'ministry', label: this.$t('string.ministry')},
        {value: 'province', label: this.$t('string.department')},
      ]
    },
    divContainerClass() {
      return this.showFilter ? 'col-md-10' : 'col-md-12'
    }
  },
  watch: {
    '$i18n.locale' () {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.refreshTable()
      this.oTable.draw(true)
    }
  },
  mounted() {
    this.oTable = this.refreshTable()
    this.enabledAction()
    this.getTitle()
    this.getTeams()
  },
  beforeDestroy() {
    this.oTable.clear()
    this.oTable.destroy()
  },
  methods: {
    toggleFilter() {
      this.showFilter = !this.showFilter
      this.oTable.columns.adjust().draw()
    },
    search() {
      this.oTable.draw(true)
    },
    reset() {
      this.name = null
      this.title_id = null
      this.phone = null
      this.email = null
      this.office = null
      this.loaction_office = null
      this.oTable.draw(true)
    },
    getTitle() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/title/get-option')
        .then((res) => {
          this.titles = res.data.data
        }).catch((error) => {
          this.onResponseError(error)
          this.$router.back()
        })
    },
    refreshTable() {
      let self = this
      return $('#officer-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: process.env.VUE_APP_API + '/api/backend/officer/datatable',
          data: (d) => {
            d.edit = self.$t('label.edit')
            d.show = self.$t('label.show')
            d.delete = self.$t('label.delete')
            d.name = self.name
            d.title_id = self.title_id
            d.phone = self.phone
            d.email = self.email
            d.office = self.office
            d.loaction_office = self.loaction_office
          },
          error: (xhr) => {
            self.onResponseError(xhr)
          }
        },
        columns: [
          {
            data: 'avatar',
            name: 'avatar',
            title: self.$t('table.avatar'),
            render: (data, type, row) => {
              if (row.avatar) {
                return `<img src="${process.env.VUE_APP_API}/${data}" alt="Avatar" class="profile-user-img img-circle datatable-image-size"/>`
              } else
                return `<img src="/images/avatar/avatar.png" alt="Avatar" class="profile-user-img img-circle datatable-image-size"/>`
            },
            searchable: false,
            orderable: false,
          },
          {
            data: 'name_km',
            name: 'name_km',
            title: self.$t('label.name'),
            render: (data, type, row) => {
              return row['name_' + this.$i18n.locale]
            }
          },
          {
            data: 'gender.name_en',
            name: 'gender.name_en',
            title: self.$t('table.gender'),
            render: (data, type, row) => {
              if (row['gender'] !== null) {
                return row['gender']['name_' + self.$i18n.locale]
              }
              return self.$t('string.na')
            }
          },
          {
            data: 'place.name_en',
            name: 'place.name_en',
            title: self.$t('table.place'),
            render: (data, type, row) => {
              if (row['place'] !== null) {
                return row['place']['name_' + self.$i18n.locale]
              }
              return self.$t('string.na')
            }
          },
          {
            data: 'level',
            name: 'level',
            title: self.$t('table.ministryOrProvinceOfficer'),
          },
          {
            data: 'phone',
            name: 'phone',
            title: self.$t('table.phone'),
            render: (phone) => {
              if (phone === null) {
                return self.$t('string.na')
              }
              return phone
            }
          },
          {
            data: 'email',
            name: 'email',
            title: self.$t('table.email'),
            render: (email) => {
              if (email === null) {
                return self.$t('string.na')
              }
              return email
            }
          },
          {
            data: 'created_at',
            name: 'created_at',
            orderable: true,
            searchable: false,
            title: self.$t('table.createdAt'),
            render: (data) => {
              return this.getDate(data)
            }
          },
          {
            data: 'action',
            name: 'action',
            width: '100px',
            orderable: false,
            searchable: false,
            title: self.$t('table.action')
          }
        ],
        language: {
          url: self.getLocale,
        },
        order: [
          [7, 'desc']
        ],
        drawCallback () {
          $('[data-toggle="tooltip"]').tooltip()
        }
      })
    },
    enabledAction() {
      let self = this
      this.clearEventHandler([
        '.btn-edit',
        '.btn-show',
        '.btn-trash',
        '.sidebar-toggle'
      ])
      $(document).on('click', '.sidebar-toggle', function () {
        self.oTable.columns.adjust().draw()
      })
      $(document).on('click', '.btn-edit', function () {
        self.$router.push({
          name: 'edit-officer',
          params: {
            officer_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-show', function () {
        self.$router.push({
          name: 'show-officer',
          params: {
            officer_uuid: $(this).attr('data-uuid')
          }
        })
      })
      $(document).on('click', '.btn-trash', function () {
        self.deleteOfficer($(this).attr('data-uuid'))
      })
    },
    deleteOfficer(uuid) {
      let self = this
      this.$swal.fire({
        title: self.i18nSwalTitle,
        text: self.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: self.i18nSwalNo,
        confirmButtonText: self.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$axios.post(process.env.VUE_APP_API + '/api/backend/officer/delete', {
            officer_uuid: uuid
          }).then(() => {
            this.showSwalSuccess()
            this.oTable.draw(true)
          }).catch(error => {
            this.onResponseError(error)
          })
        }
      })
    },
    getTeams() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/team/get-option')
        .then(({data}) => {
          this.teams = data.data
        })
        .catch(error => {
          this.onResponseError(error)
        })
    }
  }
}
</script>

<style scoped>

</style>
