<template>
  <div class="error-page">
    <h2 class="headline text-yellow">
      401
    </h2>
    <div class="error-content">
      <h3>
        <i class="fa fa-warning text-yellow" /> {{ $t('http.401') }}
      </h3>
      <p>
        The HTTP <strong>401 Unauthorized</strong> client error status response code indicates that the request has not
        been applied because it lacks valid authentication credentials for the target resource.
      </p>
      <button
        class="btn btn-primary btn-sm"
        @click="goDashboard"
      >
        Go to Dashboard
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Unauthorized",
  metaInfo() {
    return {
      title: 'Unauthorized',
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  }
}
</script>

<style scoped>
</style>
