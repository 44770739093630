import Administration from "./../../views/administrations/Index"
import {userRouters} from "./user"
import {roleRouters} from "./role"
import {permissionRouters} from "./permission"
import Permissions from "../../permissions"

export const administrationRouters = {
  path: 'administration',
  name: 'administration',
  component: Administration,
  redirect: 'user',
  meta: {
    permissions: [Permissions.backend.setting.manage]
  },
  children: [
    userRouters,
    roleRouters,
    permissionRouters,
  ]
}
