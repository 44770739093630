<template>
  <div class="row">
    <div :class="containerClass">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ title }}
          </h3>
          <div class="box-tools">
            <asora-back-button />
          </div>
        </div>
        <div class="box-body">
          <div class="form-horizontal">
            <div
              class="form-group row"
              :class="{'has-error': validations.name_km}"
            >
              <label
                :class="labelClasses"
                class="required"
              >{{ $t('label.nameKm') }}</label>
              <div :class="inputClasses">
                <input
                  v-model="people.name_km"
                  :placeholder="$t('label.nameKm')"
                  class="form-control"
                  type="text"
                >
                <template v-if="validations.name_km">
                  <FiledIsRequired :msg="validations['name_km'][0]" />
                </template>
              </div>
            </div>

            <div
              class="form-group row"
              :class="{'has-error': validations.name_en}"
            >
              <label
                :class="labelClasses"
                class="required"
              >{{ $t('label.nameEn') }}</label>
              <div :class="inputClasses">
                <input
                  v-model="people.name_en"
                  :placeholder="$t('label.nameEn')"
                  class="form-control"
                  type="text"
                >
                <template v-if="validations.name_en">
                  <FiledIsRequired :msg="validations['name_en'][0]" />
                </template>
              </div>
            </div>

            <div
              class="form-group row"
              :class="{'has-error': validations.national_id}"
            >
              <label :class="labelClasses">{{ $t('label.nationalId') }}</label>
              <div :class="inputClasses">
                <input
                  v-model="people.national_id"
                  :placeholder="$t('label.nationalId')"
                  class="form-control"
                  type="text"
                >
                <template v-if="validations.national_id">
                  <FiledIsRequired :msg="validations['national_id'][0]" />
                </template>
              </div>
            </div>

            <div
              class="form-group row"
              :class="{'has-error': validations.passport}"
            >
              <label :class="labelClasses">{{ $t('label.passport') }}</label>
              <div :class="inputClasses">
                <input
                  v-model="people.passport"
                  :placeholder="$t('label.passport')"
                  class="form-control"
                  type="text"
                >
                <template v-if="validations.passport">
                  <FiledIsRequired :msg="validations['passport'][0]" />
                </template>
              </div>
            </div>

            <div
              class="form-group row"
              :class="{'has-error': validations.gender_id}"
            >
              <label
                :class="labelClasses"
                class="required"
              >{{ $t('label.gender') }}</label>
              <div :class="inputClasses">
                <select
                  v-model="people.gender_id"
                  class="form-control"
                >
                  <option
                    selected
                    disabled
                    :value="null"
                  >
                    {{ $t('string.select_gender') }}
                  </option>
                  <option value="1">
                    {{ $t('label.male') }}
                  </option>
                  <option value="2">
                    {{ $t('label.female') }}
                  </option>
                </select>
                <template v-if="validations.gender_id">
                  <FiledIsRequired :msg="validations['gender_id'][0]" />
                </template>
              </div>
            </div>

            <div class="form-group row">
              <label :class="labelClasses">{{ $t('label.email') }}</label>
              <div :class="inputClasses">
                <input
                  v-model="people.email"
                  :placeholder="$t('label.email')"
                  class="form-control"
                  type="email"
                >
              </div>
            </div>

            <div class="form-group row">
              <label :class="labelClasses">{{ $t('label.dob') }}</label>
              <div :class="inputClasses">
                <date-picker
                  v-model="people.dob"
                  :lang="datepickerLocale[$i18n.locale].lang"
                  :placeholder="$t('label.dob')"
                  class="table-full-width"
                  format="DD/MM/YYYY"
                  type="date"
                />
              </div>
            </div>

            <div class="form-group row">
              <label :class="labelClasses">{{ $t('label.placeOfBirth') }}</label>
              <div :class="inputClasses">
                <input
                  v-model="people.place_of_birth"
                  :placeholder="$t('label.placeOfBirth')"
                  class="form-control"
                  type="text"
                >
              </div>
            </div>

            <div class="form-group row">
              <label :class="labelClasses">{{ $t('label.phone') }}</label>
              <div :class="inputClasses">
                <input
                  v-model="people.phone"
                  :placeholder="$t('label.phone')"
                  class="form-control"
                  type="tel"
                >
              </div>
            </div>

            <div class="form-group row">
              <label :class="labelClasses">{{ $t('label.country') }}</label>
              <div :class="inputClasses">
                <select
                  v-model="people.country_id"
                  class="form-control"
                >
                  <option
                    selected
                    disabled
                    :value="null"
                  >
                    {{ $t('string.select_country') }}
                  </option>
                  <option
                    v-for="(country, key) in countries"
                    :key="key"
                    :value="country.id"
                  >
                    {{ country['name_' + $i18n.locale] }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label :class="labelClasses">{{ $t('label.nationality') }}</label>
              <div :class="inputClasses">
                <select
                  v-model="people.nationality_id"
                  class="form-control"
                >
                  <option
                    selected
                    disabled
                    :value="null"
                  >
                    {{ $t('string.select_nationality') }}
                  </option>
                  <option
                    v-for="(nationality, key) in nationalities"
                    :key="key"
                    :value="nationality.id"
                  >
                    {{ nationality['name_' + $i18n.locale] }}
                  </option>
                </select>
              </div>
            </div>

            <location-input
              :input-classes="inputClasses"
              :label-classes="labelClasses"
            />

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.registered') }}</label>
              <div class="col-sm-9">
                <label class="margin-top-10">
                  <input
                    v-model="people.is_registered"
                    :value="true"
                    name="registration"
                    type="checkbox"
                  >
                  <template v-if="people.is_registered">{{ $t('string.true') }}</template>
                  <template v-else>{{ $t('string.false') }}</template>
                </label>
              </div>
            </div>

            <div class="form-group row">
              <label :class="labelClasses">{{ $t('label.avatar') }}</label>
              <div :class="inputClasses">
                <input
                  ref="avatar"
                  name="avatar"
                  type="file"
                > <br>
                <template v-if="people.avatar">
                  <render-image-tag :src="people.avatar ? `${baseUrl}/${people.avatar}` : null" />
                </template>
              </div>
            </div>

            <div class="form-group row">
              <div :class="buttonClasses">
                <template v-if="button.handle==='create'">
                  <button
                    :class="`btn btn-${button.btnClass} btn-sm`"
                    @click="storePeople"
                  >
                    {{ button.label }}
                  </button>
                </template>
                <template v-else>
                  <button
                    :class="`btn btn-${button.btnClass} btn-sm`"
                    @click="storePeople"
                  >
                    {{ button.label }}
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="modeEdit"
      class="col-md-6"
    >
      <div class="clearfix" />
      <upload-media
        :button-label="$t('label.chooseReference')"
        :model-uuid="$route.params.people_uuid"
        :readonly="modeShow"
        button-icon="far fa-file-alt"
        model-name="people"
        preview-column="col-xs-6 margin-bottom"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import LocationInput from "../../components/LocationInput"
import RenderImageTag from "../../components/RenderImageTag"
import UploadMedia from "../../components/UploadMedia"
import FiledIsRequired from "@/components/FiledIsRequired"

export default {
  name: "PeopleForm",
  components: {
    FiledIsRequired,
    UploadMedia,
    RenderImageTag,
    LocationInput,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    button: {
      type: Object,
      required: true
    },
    labelClasses: {
      type: String,
      default: 'col-sm-3 control-label'
    },
    inputClasses: {
      type: String,
      default: 'col-sm-9'
    },
    buttonClasses: {
      type: String,
      default: 'col-sm-9 col-sm-offset-3'
    },
    peopleCategoryId: {
      type: String,
      required: true,
    },
    modeEdit: {
      type: Boolean,
      default: false,
    },
    modeShow: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      people: {
        gender_id: null,
        country_id: null,
        nationality_id: null,
      },
      validations: {},
      params: new FormData(),
      countries: [],
      nationalities: [],
    }
  },
  computed: {
    ...mapState({
      address: state => state.location.address
    }),
    containerClass() {
      return this.modeEdit ? 'col-md-6 col-xs-12' : 'col-md-12'
    },
    urlAction() {
      return this.modeEdit ? 'update' : 'create'
    }
  },
  watch: {
    'people.national_id'() {
      if (this.people.national_id !== '') {
        this.people.country_id = 1
        this.people.nationality_id = 33
      } else {
        this.people.country_id = ''
        this.people.nationality_id = ''
      }
    }
  },
  created() {
    this.getCountries()
    this.getNationalities()
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty('people_uuid')) {
      this.getPeople()
    }
  },
  methods: {
    storePeople() {
      if (this.people.uuid) {
        this.people.people_uuid = this.people.uuid
      }
      this.prepareParams()
      this.$isLoading(true)
      this.$axios.post(`${process.env.VUE_APP_API}/api/backend/people/${this.urlAction}`, this.params)
        .then(() => {
          this.showToastr()
          this.$router.back()
        })
        .catch((error) => {
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
            this.$store.commit('location/setValidations', res.data.errors)
          } else {
            this.$store.commit('location/setValidations', null)
            this.validations = null
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    prepareParams() {
      this.params.set('people_category_id', this.peopleCategoryId)
      for (let key in this.people) {
        if (key === 'dob') {
          this.params.set(key, this.$moment(this.people[key]).locale('en').format('YYYY-MM-DD'))
        } else {
          let value = typeof (this.people[key]) === 'string' ? (this.people[key]).trim() : this.people[key]
          if (value === null || value === '') {
            this.params.delete(key)
          } else {
            this.params.set(key, value)
          }
        }
      }
      if (this.$refs.avatar.files.length > 0) {
        this.params.set('avatar', this.$refs.avatar.files[0])
      } else {
        this.params.delete('avatar')
      }
      for (let key in this.address) {
        if (this.address[key] !== null && this.address[key] !== '') {
          this.params.set(key, this.address[key])
        } else {
          this.params.delete(key)
        }
      }
    },
    getPeople() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/people/show', {
        people_uuid: this.$route.params.people_uuid,
      }).then((res) => {
        this.people = res.data.data
        if (this.$moment(this.people.dob).isValid()) {
          this.people.dob = this.$moment(this.people.dob).toDate()
        } else {
          this.people.dob = this.$moment().toDate()
        }
        this.$store.commit('location/setAddress', {
          province_id: this.people.province_id,
          district_id: this.people.district_id,
          commune_id: this.people.commune_id,
          village_id: this.people.village_id,
          home_number: this.people.home_number,
          street_number: this.people.street_number,
          reload: true,
        })
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      })
    },
    getCountries() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/country/get-countries')
        .then((res) => {
          this.countries = res.data.data
        }).catch((error) => {
          this.onResponseError(error)
          this.$router.back()
        })
    },
    getNationalities() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/nationality/get-nationalities')
        .then((res) => {
          this.nationalities = res.data.data
        }).catch((error) => {
          this.onResponseError(error)
          this.$router.back()
        })
    }
  }
}
</script>

<style scoped>

</style>
