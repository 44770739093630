<template>
  <officer-from
    :button="{
      label: $t('button.update'),
      btnClass: 'info',
      handle: 'edit'
    }"
    :title="$t('string.editOfficer')"
  />
</template>

<script>
import OfficerFrom from "./Form"

export default {
  name: "Edit",
  components: {OfficerFrom},
  metaInfo() {
    return {
      title: this.$t('string.editOfficer'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
}
</script>

<style scoped>

</style>
