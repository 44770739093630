<template>
  <div class="row">
    <div class="col-xs-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ title }}
          </h3>
          <div class="box-tools">
            <template v-if="can(editPermission)">
              <button
                v-if="modeShow"
                class="btn btn-primary btn-sm margin-r-5"
                @click="$router.push({
                  name: nameRouteEdit,
                  params: {
                    mission_uuid: $route.params.company_uuid
                  }
                })"
              >
                <i class="fa fa-edit" /> {{ $t('string.edit') }}
              </button>
              <PublishCompanyButton />
            </template>
            <asora-back-button />
          </div>
        </div>
        <div class="box-body">
          <div class="form-horizontal">
            <div class="form-group row">
              <div class="col-sm-7 col-sm-offset-5">
                <RenderImageTag :src="company.logo ? `${baseUrl}/${company.logo}` : null" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">{{ $t('label.nameKm') }}</label>
              <div class="col-sm-7">
                <p class="text-bold">
                  : {{ company.name_km }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">{{ $t('label.nameEn') }}</label>
              <div class="col-sm-7">
                <p class="text-bold">
                  : {{ company.name_en }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">{{ $t('field.label.company_owner_name') }}</label>
              <div class="col-sm-7">
                <template v-if="company.owners && company.owners.length >0">
                  :
                  <template v-for="(people, key) in company.owners">
                    <PeopleChipItem
                      :key="key"
                      :people="people"
                      model="people"
                    />
                  </template>
                </template>
                <template v-else>
                  <p>: {{ $t('string.na') }}</p>
                </template>
              </div>
            </div>
            <div
              v-if="!investorCompany"
              class="form-group row"
            >
              <label class="col-sm-5 text-right">{{ $t('label.companyCto') }}</label>
              <div class="col-sm-7">
                <template v-if="company.ctos && company.ctos.length >0">
                  :
                  <template v-for="(cto, key) in company.ctos">
                    <people-chip-item
                      :key="key"
                      :people="cto"
                      model="people"
                    />
                  </template>
                </template>
                <template v-else>
                  <p>: {{ $t('string.na') }}</p>
                </template>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">{{ $t('label.technicians') }}</label>
              <div class="col-sm-7">
                <template v-if="company.technicians && company.technicians.length >0">
                  :
                  <template v-for="(ceo, key) in company.technicians">
                    <PeopleChipItem
                      :key="key"
                      :people="ceo"
                      model="people"
                    />
                  </template>
                </template>
                <template v-else>
                  <p>: {{ $t('string.na') }}</p>
                </template>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">{{ $t('label.firstRegistrationNumber') }}</label>
              <div class="col-sm-7">
                <p class="text-bold">
                  : {{ company.first_registration_number ? company.first_registration_number : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">{{ $t('label.first_registration_date') }}</label>
              <div class="col-sm-7">
                <p class="text-bold">
                  : {{ getDate(company.first_registration_date, false, 'DD/MM/YYYY') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">{{ $t('label.firstRegistrationYear') }}</label>
              <div class="col-sm-7">
                <p class="text-bold">
                  : {{ company.year ? company.year : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">{{ $t('label.certificateOfMefNumber') }}</label>
              <div class="col-sm-7">
                <p class="text-bold">
                  : {{ company.certificate_of_mef_number || $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">{{ $t('field.label.construction_certifier_mfc_date') }}</label>
              <div class="col-sm-7">
                <p class="text-bold">
                  : {{ getDate(company.certificate_of_mef_date, false, 'DD/MM/YYYY') }}
                </p>
              </div>
            </div>
            <template v-if="!investorCompany">
              <div class="form-group row">
                <label class="col-sm-5 text-right">{{ $t('label.companyType') }}</label>
                <div class="col-sm-7">
                  <p class="text-bold">
                    : {{ company.company_type ? company.company_type['name_' + $i18n.locale] : $t('string.na') }}
                  </p>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-5 text-right">{{ $t('label.companySection') }}</label>
                <div class="col-sm-7">
                  <p class="text-bold">
                    : {{ company.company_section ? company.company_section['name_' + $i18n.locale] : $t('string.na') }}
                  </p>
                </div>
              </div>
            </template>
            <div class="form-group row">
              <label class="col-sm-5 text-right">{{ $t('label.email') }}</label>
              <div class="col-sm-7">
                <p class="text-bold">
                  <a :href="`mailto:${company.email}`">
                    : {{ company.email ? company.email : $t('string.na') }}</a>
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">{{ $t('label.phone') }}</label>
              <div class="col-sm-7">
                <p class="text-bold">
                  <a :href="`tel:${company.phone}`">: {{ company.phone ? company.phone : $t('string.na') }}</a>
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">{{ $t('label.website') }}</label>
              <div class="col-sm-7">
                <p class="text-bold">
                  <a
                    :href="`${company.website}`"
                    target="_blank"
                  >
                    : {{ company.website ? company.website : $t('string.na') }}
                  </a>
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">{{ $t('label.country') }}</label>
              <div class="col-sm-7">
                <p class="text-bold">
                  : {{ company.country ? company.country['name_' + $i18n.locale] : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">{{ $t('label.nationality') }}</label>
              <div class="col-sm-7">
                <p class="text-bold">
                  :
                  {{ company.nationality ? company.nationality['name_' + $i18n.locale] : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">{{ $t('label.address') }}</label>
              <div class="col-sm-7">
                <p class="text-bold">
                  : {{ company['full_address_' + $i18n.locale] ? company['full_address_' + $i18n.locale] : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">{{ $t('label.description') }}</label>
              <div class="col-sm-7">
                <p class="text-bold">
                  : {{ company.description ? company.description : $t('string.na') }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">{{ $t('label.createdAt') }}</label>
              <div class="col-sm-7">
                <p class="text-bold">
                  : {{ getDate(company.created_at, false) }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-5 text-right">{{ $t('label.updatedAt') }}</label>
              <div class="col-sm-7">
                <p class="text-bold">
                  : {{ getDate(company.updated_at, false) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-12">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ $t('string.map') }}
          </h3>
        </div>
        <div class="box-body">
          <template v-if="company.lat !== null && company.lng !== null">
            <div class="thumbnail no-margin">
              <GmapMap
                :center="{lat:company.lat, lng: company.lng}"
                :zoom="15"
                map-type-id="terrain"
                style="width: 100%; height: 430px"
              >
                <GmapMarker
                  :icon="{url: '/img/markers/blue20x20.png'}"
                  :position="{lat:company.lat, lng:company.lng}"
                />
              </GmapMap>
            </div>
          </template>
          <template v-else>
            {{ $t('string.na') }}
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RenderImageTag from "@/components/RenderImageTag"
import PeopleChipItem from "@/components/PeopleChipItem"
import PublishCompanyButton from "@/components/PublishCompanyButton"

export default {
  name: "CompanyDetail",
  components: {
    PublishCompanyButton,
    RenderImageTag,
    PeopleChipItem
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    modeShow: {
      type: Boolean,
      default: true,
    },
    nameRouteEdit: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    editPermission: {
      type: Array,
      default: null
    },
    investorCompany: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ministry_registrations () {
      if (this.company && this.company.ministry_registrations && Array.isArray(this.company.ministry_registrations)) {
        return this.company.ministry_registrations
      }
      return []
    }
  },
}
</script>

<style scoped>

</style>
