<template>
  <div class="row">
    <div :class="containerClasses">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ title }}
          </h3>
          <div class="box-tools">
            <asora-back-button />
          </div>
        </div>
        <div class="box-body">
          <div class="form-horizontal">
            <div
              class="form-group row"
              :class="missionTypeValidation.required ? ' has-error':''"
            >
              <label :class="`${labelClasses} required`">{{ $t('label.missionType') }}</label>
              <div :class="inputClasses">
                <multiselect
                  v-model="mission.mission_type"
                  track-by="id"
                  :label="`name_${$i18n.locale}`"
                  :placeholder="$t('label.missionType')"
                  :options="missionTypes"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
                <template v-if="missionTypeValidation.required">
                  <span class="label-error">
                    {{ missionTypeValidation.message }}
                  </span>
                </template>
              </div>
            </div>

            <div
              class="form-group row"
              :class="titleValidation.required ? ' has-error':''"
            >
              <label :class="`${labelClasses} required`">{{ $t('label.missionTitle') }}</label>
              <div :class="inputClasses">
                <input
                  v-model="mission.title"
                  type="text"
                  :placeholder="$t('label.missionTitle')"
                  class="form-control"
                >
                <template v-if="titleValidation.required">
                  <span class="label-error">
                    {{ titleValidation.message }}
                  </span>
                </template>
              </div>
            </div>
            <div
              class="form-group row"
              :class="descriptionValidation.required ? ' has-error':''"
            >
              <label :class="`${labelClasses} required`">{{ $t('label.missionDescription') }}</label>
              <div :class="inputClasses">
                <textarea
                  v-model="mission.description"
                  :placeholder="$t('label.missionDescription')"
                  rows="4"
                  class="form-control"
                />
                <template v-if="descriptionValidation.required">
                  <span class="label-error">{{ descriptionValidation.message }}</span>
                </template>
              </div>
            </div>

            <div
              class="form-group row"
              :class="dateValidation.required ? ' has-error':''"
            >
              <label :class="`${labelClasses} required`">{{ $t('label.date') }}</label>
              <div :class="inputClasses">
                <date-picker
                  v-model="mission.date"
                  type="datetime"
                  range
                  format="DD/MM/YYYY"
                  :lang="datepickerLocale[$i18n.locale].lang"
                  :placeholder="$t('label.date')"
                  class="table-full-width"
                />
                <template v-if="dateValidation.required">
                  <span class="label-error">{{ dateValidation.message }}</span>
                </template>
              </div>
            </div>

            <div
              class="form-group row"
              :class="projectValidation.required ? ' has-error':''"
            >
              <label :class="`${labelClasses}`">{{ $t('label.project') }}</label>
              <div :class="inputClasses">
                <multiselect
                  v-model="mission.project"
                  label="name"
                  track-by="id"
                  :placeholder="$t('string.searchProject')"
                  open-direction="bottom"
                  :options="projects"
                  :searchable="true"
                  :internal-search="false"
                  :close-on-select="true"
                  :options-limit="300"
                  :limit="3"
                  :max-height="600"
                  :show-no-results="false"
                  :hide-selected="true"
                  @search-change="searchProject"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
                <template v-if="projectValidation.required">
                  <span class="label-error">{{ projectValidation.message }}</span>
                </template>
              </div>
            </div>

            <div class="form-group row">
              <label :class="labelClasses">{{ $t('label.team') }}</label>
              <div :class="inputClasses">
                <multiselect
                  v-model="mission.teams"
                  :placeholder="$t('string.chooseTeams')"
                  track-by="id"
                  :multiple="true"
                  :label="'name_' + $i18n.locale"
                  :options="teams"
                />
              </div>
            </div>
            <div class="form-group row">
              <label :class="labelClasses">{{ $t('label.officers') }}</label>
              <div :class="inputClasses">
                <multiselect
                  v-model="mission.officers"
                  track-by="id"
                  :multiple="true"
                  :placeholder="$t('string.chooseOfficers')"
                  :label="`name_${$i18n.locale}`"
                  :options="officers"
                />
              </div>
            </div>

            <div
              class="form-group row"
              :class="referenceNumberValidation.required ? ' has-error':''"
            >
              <label :class="`${labelClasses}`">{{ $t('label.referenceNumber') }}</label>
              <div :class="inputClasses">
                <input
                  v-model="mission.reference_number"
                  type="text"
                  :placeholder="$t('label.referenceNumber')"
                  class="form-control"
                >
                <template v-if="referenceNumberValidation.required">
                  <span class="label-error">{{ referenceNumberValidation.message }}</span>
                </template>
              </div>
            </div>

            <div class="form-group row">
              <div :class="`${buttonClass}`">
                <template v-if="button.handle">
                  <button
                    class="btn btn-primary btn-sm"
                    @click="store"
                  >
                    {{ $t('button.update') }}
                  </button>
                </template>
                <template v-else>
                  <button
                    class="btn btn-primary btn-sm"
                    @click="store"
                  >
                    {{ $t('button.save') }}
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="editForm">
      <div class="col-md-6">
        <upload-media
          model-name="mission"
          preview-column="col-md-4"
          :button-label="$t('label.chooseReference')"
          :model-uuid="$route.params.mission_uuid"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from 'debounce'
import UploadMedia from "../../components/UploadMedia"
import {validationMixins} from "@/mixins/validations"

export default {
  name: "MissionForm",
  components: {
    UploadMedia
  },
  mixins: [validationMixins],
  props: {
    button: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    labelClasses: {
      type: String,
      default: 'col-sm-3 control-label'
    },
    inputClasses: {
      type: String,
      default: 'col-sm-9'
    }
  },
  data() {
    return {
      validations: null,
      mission: {},
      officers: [],
      teams: [],
      projects: [],
      params: new FormData(),
      missionTypes: [],
    }
  },
  computed: {
    containerClasses() {
      return this.$route.name === 'edit-mission' ? 'col-md-6' : 'col-md-12'
    },
    editForm() {
      return this.$route.name === 'edit-mission'
    },
    buttonClass() {
      return this.$route.name === 'edit-mission' ? 'col-md-12' : 'col-sm-offset-3 col-sm-9'
    },
    urlAction() {
      return this.editForm ? 'update' : 'create'
    }
  },
  methods: {
    searchProject: debounce(function (query_text = null) {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project/get-option', {
        query_text: query_text
      }).then((res) => {
        if (res.data.data) {
          this.projects = res.data.data
        } else {
          this.projects = []
        }
      })
    }, 200),
    getOfficers() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/officer/get-option')
        .then((res) => {
          if (res.data.data) {
            this.officers = res.data.data
          } else {
            this.officers = []
          }
        }).catch((error) => {
          this.onResponseError(error)
          this.$router.push({name: 'list-mission'})
        })
    },
    getTeams() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/team/get-option')
        .then((res) => {
          if (res.data.data) {
            this.teams = res.data.data
          } else {
            this.teams = []
          }
        }).catch((error) => {
          this.onResponseError(error)
          this.$router.push({name: 'list-mission'})
        })
    },
    getMission() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/mission/show', {
        mission_uuid: this.$route.params.mission_uuid,
      }).then((res) => {
        if (res.data.data) {
          this.mission = res.data.data
          if (this.mission.start_date) {
            this.mission.date = [
              this.$moment(this.mission.start_date).toDate(),
              this.$moment(this.mission.end_date).toDate(),
            ]
          }
          if (this.mission.project !== null) {
            let project = this.mission.project
            this.mission.project = {
              id: project.id,
              name: `${project.name_en} (${project.permit_number ? project.permit_number : ''} - ${project.name_km})`
            }
          }
        } else {
          this.mission = {}
        }
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-mission'})
      })
    },
    store() {
      if (this.mission.uuid) {
        this.mission.mission_uuid = this.mission.uuid
      }
      this.prepareParams()
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + `/api/backend/mission/${this.urlAction}`, this.params)
        .then(() => {
          this.showToastr()
          this.$router.push({name: 'list-mission'})
        })
        .catch((error) => {
          let res = error.response
          this.validations = null
          if (res.status === 422) {
            this.validations = res.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    prepareParams() {
      for (let key in this.mission) {
        if (key === 'date') {
          if (this.mission[key].length === 2) {
            let date = this.mission[key]
            this.params.set('start_date', this.$moment(date[0]).locale('en').format('YYYY-MM-DD HH:mm:ss'))
            this.params.set('end_date', this.$moment(date[1]).locale('en').format('YYYY-MM-DD HH:mm:ss'))
          } else {
            this.params.delete('start_date')
            this.params.delete('end_date')
          }
        } else {
          let value = typeof (this.mission[key]) === 'string' ? (this.mission[key]).trim() : this.mission[key]
          if (value === null || value === '') {
            this.params.delete(key)
          } else {
            if (key === 'project') {
              this.params.set('project_id', value.id)
            } else if (key === 'mission_type') {
              this.params.set('mission_type_id', value.id)
            } else if (key === 'teams') {
              if (this.mission[key].length > 0) {
                this.mission[key].forEach((team, key) => {
                  this.params.set(`team_ids[${key}]`, team.id)
                })
              } else {
                this.params.delete('team_ids')
              }
            } else if (key === 'officers') {
              if (this.mission[key].length > 0) {
                this.mission[key].forEach((officer, key) => {
                  this.params.set(`officer_ids[${key}]`, officer.id)
                })
              } else {
                this.params.delete('officer_ids')
              }
            } else {
              this.params.set(key, value)
            }
          }
        }
      }
    },
    getMissionTypes() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/mission/get-mission-types')
        .then((res) => {
          this.missionTypes = res.data.data
        })
        .catch((error) => {
          this.onResponseError(error)
          this.$router.push({name: 'list-mission'})
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
  },
  created() {
    this.getMissionTypes()
    this.getTeams()
    this.getOfficers()
    this.searchProject()
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty("mission_uuid")) {
      this.getMission()
    }
  }
}
</script>

<style scoped>

</style>
