<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('label.newLawRegulationType') }}
      </h3>
      <div class="box-tools">
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <div class="form-horizontal">
        <div
          :class="nameKmValidation.required ? ' has-error' : null"
          class="form-group row"
        >
          <label class="col-sm-3 control-label required">{{ $t('label.nameKm') }}</label>
          <div class="col-sm-9">
            <input
              v-model="lawRegulationType.name_km"
              :placeholder="$t('label.nameKm')"
              class="form-control"
              type="text"
            >
            <template v-if="nameKmValidation.required">
              <span class="label-error">{{ nameKmValidation.message }}</span>
            </template>
          </div>
        </div>

        <div
          :class="nameEnValidation.required ? ' has-error' : null"
          class="form-group row"
        >
          <label class="col-sm-3 control-label required">{{ $t('label.nameEn') }}</label>
          <div class="col-sm-9">
            <input
              v-model="lawRegulationType.name_en"
              :placeholder="$t('label.nameEn')"
              class="form-control"
              type="text"
            >
            <template v-if="nameEnValidation.required">
              <span class="label-error">{{ nameEnValidation.message }}</span>
            </template>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.description') }}</label>
          <div class="col-sm-9">
            <textarea
              v-model="lawRegulationType.description"
              :placeholder="$t('label.description')"
              class="form-control"
              rows="3"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 control-label">{{ $t('label.isConstruction') }}</label>
          <div class="col-sm-9">
            <label>
              <input
                v-model="lawRegulationType.is_construction"
                :value="true"
                type="checkbox"
              >
            </label>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-9 col-sm-offset-3">
            <button
              class="btn btn-primary btn-sm"
              @click="newLawRegulationType"
            >
              {{ $t('button.save') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Create",
  metaInfo() {
    return {
      title: this.$t('label.newLawRegulationType'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      lawRegulationType: {},
      validations: null,
    }
  },
  computed: {
    nameKmValidation() {
      return this.getValidationColumn(this.validations, 'name_km')
    },
    nameEnValidation() {
      return this.getValidationColumn(this.validations, 'name_en')
    }
  },
  methods: {
    newLawRegulationType() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/law-regulation-type/create', this.lawRegulationType)
        .then(() => {
          this.showToastr()
          this.$router.push({name: 'list-law-regulation-type'})
        })
        .catch((error) => {
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
          } else {
            this.validations = null
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    }
  }
}
</script>

<style scoped>

</style>
