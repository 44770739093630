import ProjectType from "./../../views/settings/constructionType/Index"
import List from "./../../views/settings/constructionType/List"
import Create from "./../../views/settings/constructionType/Create"
import Edit from "./../../views/settings/constructionType/Edit"
import Show from "./../../views/settings/constructionType/Show"
import Permissions from "../../permissions"

export const constructionTypeRouters = {
  path: 'construction-type',
  component: ProjectType,
  redirect: 'construction-type',
  meta: {
    permissions: [Permissions.backend.setting.constructionType.manage],
    groups: 'construction-type',
  },
  children: [
    {
      path: '',
      name: 'list-construction-type',
      component: List,
      meta: {
        permissions: [Permissions.backend.setting.constructionType.list],
        groups: 'construction-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Construction Type',
            name_km: 'ប្រភេទសំណង់'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-construction-type',
      component: Create,
      meta: {
        permissions: [Permissions.backend.setting.constructionType.store],
        groups: 'construction-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'New Construction Type',
            name_km: 'បន្ថែមប្រភេទសំណង់'
          }
        ]
      }
    },
    {
      path: 'edit/:construction_type_uuid',
      name: 'edit-construction-type',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.setting.constructionType.edit],
        groups: 'construction-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Edit Construction Type',
            name_km: 'កែប្រែសំណង់'
          }
        ]
      }
    },
    {
      path: 'show/:construction_type_uuid',
      name: 'show-construction-type',
      component: Show,
      meta: {
        permissions: [Permissions.backend.setting.constructionType.show],
        groups: 'construction-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Show Construction Type',
            name_km: 'បង្ហាញព័ត៏មានប្រភេទសំណង់'
          }
        ]
      }
    }
  ]
}
