<template>
  <people-form
    :button="buttonProperties"
    :people-category-id="peopleCategoryId"
    :title="title"
  />
</template>

<script>
import PeopleForm from "./Form"

export default {
  name: "CreatePeople",
  components: {PeopleForm},
  props: {
    title: {
      type: String,
      required: true,
    },
    peopleCategoryId: {
      type: String,
      required: true,
    },
  },
  computed: {
    buttonProperties() {
      return {
        label: this.$t('button.save'),
        btnClass: 'primary',
        handle: 'create'
      }
    }
  }
}
</script>

<style scoped>

</style>
