<template>
  <div>
    <div
      class="form-group row"
      :class="{'has-error': validations.home_number}"
    >
      <label :class="`${labelClasses} ${isRequired.home_number ? 'required' : ''}`">
        {{ $t('label.homeNumber') }}</label>
      <div :class="inputClasses">
        <input
          v-model="address.home_number"
          type="text"
          :placeholder="$t('label.homeNumber')"
          class="form-control"
        >
        <template v-if="validations.home_number">
          <FiledIsRequired :msg="validations['home_number'][0]" />
        </template>
      </div>
    </div>

    <div
      class="form-group row"
      :class="{'has-error': validations.street_number}"
    >
      <label :class="`${labelClasses} ${isRequired.street_number ? 'required' : ''}`">
        {{ $t('label.streetNumber') }}</label>
      <div :class="inputClasses">
        <input
          v-model="address.street_number"
          type="text"
          :placeholder="$t('label.streetNumber')"
          class="form-control"
        >
        <template v-if="validations.street_number">
          <FiledIsRequired :msg="validations['street_number'][0]" />
        </template>
      </div>
    </div>

    <div
      class="form-group row"
      :class="{'has-error': validations.group_number}"
    >
      <label :class="`${labelClasses} ${isRequired.group_number ? 'required' : ''}`">
        {{ $t('label.groupNumber') }}</label>
      <div :class="inputClasses">
        <input
          v-model="address.group_number"
          type="text"
          :placeholder="$t('label.groupNumber')"
          class="form-control"
        >
        <template v-if="validations.group_number">
          <FiledIsRequired :msg="validations['group_number'][0]" />
        </template>
      </div>
    </div>

    <div
      v-if="provinces.length>0"
      class="form-group row"
      :class="{'has-error': validations.province_id}"
    >
      <label :class="`${labelClasses} ${isRequired.province ? 'required' : ''}`">
        {{ $t('label.province') }}
      </label>
      <div :class="inputClasses">
        <select
          v-model="address.province_id"
          class="form-control"
          @change="getDistrict"
        >
          <option
            disabled
            selected
            :value="null"
          >
            {{ $t('string.select_province') }}
          </option>
          <option
            v-for="(province, key) in provinces"
            :key="key"
            :value="province.id"
          >
            {{ province['name_' + $i18n.locale] }}
          </option>
        </select>
        <template v-if="validations.province_id">
          <FiledIsRequired :msg="validations['province_id'][0]" />
        </template>
      </div>
    </div>
    <div
      class="form-group row"
      :class="{'has-error': validations.district_id}"
    >
      <label :class="`${labelClasses} ${isRequired.district ? 'required' : ''}`">
        {{ $t('label.district') }}</label>
      <div :class="inputClasses">
        <select
          v-model="address.district_id"
          class="form-control"
          :disabled="!address.province_id"
          @change="getCommune"
        >
          <option
            disabled
            selected
            :value="null"
          >
            {{ $t('string.select_district') }}
          </option>
          <option
            v-for="(district, key) in districts"
            :key="key"
            :value="district.id"
          >
            {{ district['name_' + $i18n.locale] }}
          </option>
        </select>
        <template v-if="validations.district_id">
          <FiledIsRequired :msg="validations['district_id'][0]" />
        </template>
      </div>
    </div>
    <div
      class="form-group row"
      :class="{'has-error': validations.commune_id}"
    >
      <label :class="`${labelClasses} ${isRequired.commune ? 'required' : ''}`">{{ $t('label.commune') }}</label>
      <div :class="inputClasses">
        <select
          v-model="address.commune_id"
          class="form-control"
          :disabled="!address.district_id"
          @change="getVillage"
        >
          <option
            disabled
            selected
            :value="null"
          >
            {{ $t('string.select_commune') }}
          </option>
          <option
            v-for="(commune, key) in communes"
            :key="key"
            :value="commune.id"
          >
            {{ commune['name_' + $i18n.locale] }}
          </option>
        </select>
        <template v-if="validations.commune_id">
          <FiledIsRequired :msg="validations['commune_id'][0]" />
        </template>
      </div>
    </div>
    <div
      class="form-group row"
      :class="{'has-error': validations.village_id}"
    >
      <label :class="`${labelClasses} ${isRequired.village ? 'required' : ''}`">
        {{ $t('label.village') }}
      </label>
      <div :class="inputClasses">
        <select
          v-model="address.village_id"
          class="form-control"
          :disabled="!address.commune_id"
        >
          <option
            disabled
            selected
            :value="null"
          >
            {{ $t('string.select_village') }}
          </option>
          <option
            v-for="(village, key) in villages"
            :key="key"
            :value="village.id"
          >
            {{ village['name_' + $i18n.locale] }}
          </option>
        </select>
        <template v-if="validations.village_id">
          <FiledIsRequired :msg="validations['village_id'][0]" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import FiledIsRequired from "@/components/FiledIsRequired"

export default {
  name: "LocationInput",
  components: {FiledIsRequired},
  props: {
    isRequired: {
      type: Object,
      default: () => {
        return {
          group_number: false,
          street_number: false,
          home_number: false,
          province: false,
          district: false,
          commune: false,
          village: false
        }
      }
    },
    labelClasses: {
      type: String,
      default: 'col-sm-3 control-label'
    },
    inputClasses: {
      type: String,
      default: 'col-sm-9'
    }
  },
  data() {
    return {
      provinces: [],
      districts: [],
      communes: [],
      villages: []
    }
  },
  computed: {
    ...mapState({
      address: state => state.location.address,
      validations: state => state.location.validations,
      reload: state => state.location.reload,
    })
  },
  watch: {
    'address.village_id'() {
      this.$store.commit('location/setVillage', this.address.village_id)
    },
    'address.home_number'() {
      this.$store.commit('location/setHomeNumber', this.address.home_number)
    },
    'address.street_number'() {
      this.$store.commit('location/setStreetNumber', this.address.street_number)
    },
    'address.group_number'() {
      this.$store.commit('location/setGroupNumber', this.address.group_number)
    },
    reload() {
      if (this.reload === true) {
        this.getProvince()
      }
    }
  },
  async mounted() {
    await this.getProvince()
  },
  beforeDestroy() {
    this.$store.commit('location/setAddress', {
      province_id: null,
      district_id: null,
      commune_id: null,
      village_id: null,
      home_number: null,
      street_number: null,
      group_number: null,
    })
  },
  methods: {
    async getProvince() {
      const response = await this.$axios.post(process.env.VUE_APP_API + '/api/backend/location/get-location')
      this.provinces = await response.data.data
      if (this.address.province_id !== null) {
        await this.getDistrict().then(() => {
          if (this.address.district_id !== null) {
            this.getCommune().then(() => {
              if (this.address.commune_id !== null) {
                this.getVillage()
              }
            })
          }
        })
      }
    },
    async getDistrict() {
      this.$store.commit('location/setProvince', this.address.province_id)
      const response = await this.$axios.post(process.env.VUE_APP_API + '/api/backend/location/get-location', {
        type: 'district',
        parent_id: this.address.province_id
      })
      this.districts = await response.data.data
    },
    async getCommune() {
      this.$store.commit('location/setDistrict', this.address.district_id)
      const response = await this.$axios.post(process.env.VUE_APP_API + '/api/backend/location/get-location', {
        type: 'commune',
        parent_id: this.address.district_id
      })
      this.communes = await response.data.data
    },
    async getVillage() {
      this.$store.commit('location/setCommune', this.address.commune_id)
      const response = await this.$axios.post(process.env.VUE_APP_API + '/api/backend/location/get-location', {
        type: 'village',
        parent_id: this.address.commune_id
      })
      this.villages = await response.data.data
    }
  }
}
</script>

<style scoped>

</style>
