<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.infoCertificateOfOccupancy') }}
      </h3>
      <div class="box-tools">
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <template v-if="certificate_occupancy!==null">
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <td>{{ $t('string.certificateNumber') }}</td>
              <td>{{ getNumbers(certificate_occupancy['issued_number_' + $i18n.locale]) || $t('string.na') }}</td>
            </tr>
            <tr>
              <td>{{ $t('string.issuedBy') }}</td>
              <td>{{ certificate_occupancy.issued_by || $t('string.na') }}</td>
            </tr>
            <tr>
              <td>{{ $t('string.issuedAt') }}</td>
              <td>{{ getNumbers(getDate(certificate_occupancy.issued_at, false, 'DD/MM/YYYY')) }}</td>
            </tr>
            <tr>
              <td>{{ $t('string.constructionOnTheLandParcel') }}</td>
              <td v-html="getNumbers(getRawValue(certificate_occupancy.construction_on_the_land_parcel))" />
            </tr>
            <tr>
              <td>{{ $t('string.address') }}</td>
              <td v-html="getRawValue(certificate_occupancy['address_' + $i18n.locale ])" />
            </tr>
            <tr>
              <td>{{ $t('string.constructionOwner') }}</td>
              <td v-html="getRawValue(certificate_occupancy['construction_owner_' + $i18n.locale ])" />
            </tr>
            <tr>
              <td>{{ $t('string.totalFloorArea') }}</td>
              <td v-html="getNumbers(getRawValue(certificate_occupancy.total_floor_area))" />
            </tr>
            <template v-if="project!==null">
              <tr>
                <td>{{ $t('string.permitNumberStr') }}</td>
                <td v-html="getNumbers(getRawValue(project.permit_number))" />
              </tr>
              <tr>
                <td>{{ $t('string.permitDateStr') }}</td>
                <td>{{ getNumbers(getDate(project.permit_date, false, 'DD/MM/YYYY')) }}</td>
              </tr>
            </template>
            <tr>
              <td>{{ $t('string.planEditingPermitNumber') }}</td>
              <td>{{ getNumbers(getRawValue(certificate_occupancy['plan_editing_permit_number'])) }}</td>
            </tr>
            <tr>
              <td>{{ $t('string.planEditingPermitDate') }}</td>
              <td>{{ getNumbers(getDate(certificate_occupancy['plan_editing_permit_date'], false, 'DD/MM/YYYY')) }}</td>
            </tr>
            <tr>
              <td>{{ $t('string.constructionSiteOpeningPermitNumber') }}</td>
              <td v-html="getNumbers(getRawValue(certificate_occupancy.construction_site_opening_permit_number))" />
            </tr>
            <tr>
              <td>{{ $t('string.constructionSiteOpeningPermitDate') }}</td>
              <td>
                {{
                  getNumbers(getDate(certificate_occupancy.construction_site_opening_permit_date, false, 'DD/MM/YYYY'))
                }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('string.asBuiltDrawingNumber') }}</td>
              <td v-html="getNumbers(getRawValue(certificate_occupancy.as_build_drawing_number))" />
            </tr>
            <tr>
              <td>{{ $t('string.asBuiltDrawingDate') }}</td>
              <td>{{ getNumbers(getDate(certificate_occupancy.as_build_drawing_date, false, 'DD/MM/YYYY')) }}</td>
            </tr>
            <tr>
              <td>{{ $t('string.certificateOfCompliance') }}</td>
              <td>
                {{ getNumbers(getRawValue(certificate_occupancy.compliance_number)) }} {{ compliance_number_suffix }}
              </td>
            </tr>
            <tr>
              <td>{{ $t('string.certificateOfComplianceDate') }}</td>
              <td>{{ getNumbers(getDate(certificate_occupancy.compliance_date, false, 'DD/MM/YYYY')) }}</td>
            </tr>
            <tr>
              <td>{{ $t('string.certificateOfComplianceBy') }}</td>
              <td v-html="getRawValue(certificate_occupancy['compliance_by_' + $i18n.locale])" />
            </tr>
            <tr>
              <td>{{ $t('string.architectureDesignBy') }}</td>
              <td v-html="getRawValue(certificate_occupancy['architecture_design_by_' + $i18n.locale])" />
            </tr>
            <tr>
              <td>{{ $t('string.architectureDesignReviewedBy') }}</td>
              <td v-html="getRawValue(certificate_occupancy['architecture_design_reviewed_by_' + $i18n.locale])" />
            </tr>
            <tr>
              <td>{{ $t('string.structuralDesign') }}</td>
              <td v-html="getRawValue(certificate_occupancy['structural_design_' + $i18n.locale])" />
            </tr>
            <tr>
              <td>{{ $t('string.structuralDesignBy') }}</td>
              <td v-html="getRawValue(certificate_occupancy['structural_design_by_' + $i18n.locale])" />
            </tr>
            <tr>
              <td>{{ $t('string.structuralDesignReviewedBy') }}</td>
              <td v-html="getRawValue(certificate_occupancy['structural_design_reviewed_by_' + $i18n.locale])" />
            </tr>
            <tr>
              <td>{{ $t('string.mepSystemReviewedBy') }}</td>
              <td v-html="getRawValue(certificate_occupancy['mep_system_reviewed_by_' + $i18n.locale])" />
            </tr>
            <tr>
              <td>{{ $t('string.fireSafetySystemInspectedBy') }}</td>
              <td v-html="getRawValue(certificate_occupancy['fire_safety_system_inspected_by_' + $i18n.locale])" />
            </tr>
            <tr>
              <td>{{ $t('string.buildingWorkBy') }}</td>
              <td v-html="getRawValue(certificate_occupancy['building_work_by_' + $i18n.locale])" />
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        <p>{{ $t('string.na') }}</p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoCertificateOfOccupancy",
  data() {
    return {
      certificate_occupancy: null,
      project: null,
      open_construction_letter: null,
    }
  },
  computed: {
    compliance_number_suffix () {
      if (this.certificate_occupancy) {
        const {
          compliance_number_suffix,
          certificate_of_compliance_number_suffix_en
        } = this.certificate_occupancy
        if (compliance_number_suffix && certificate_of_compliance_number_suffix_en) {
          return this.$i18n.locale === 'en' ? certificate_of_compliance_number_suffix_en : compliance_number_suffix
        } else if (compliance_number_suffix && !certificate_of_compliance_number_suffix_en) {
          return compliance_number_suffix
        } else if (!compliance_number_suffix && certificate_of_compliance_number_suffix_en) {
          return certificate_of_compliance_number_suffix_en
        }
      }
      return null
    }
  },
  mounted() {
    this.getCertificateOfOccupancy()
  },
  methods: {
    async getCertificateOfOccupancy() {
      let response = await this.$axios.post(process.env.VUE_APP_API + '/api/backend/certificate-occupancy/show', {
        occupancy_uuid: this.$route.params.occupancy_uuid,
      })
      let data = await response.data.data
      this.certificate_occupancy = data.occupancy
      this.project = data.project
      this.open_construction_letter = data.open_construction_letter
    },
    getRawValue(value) {
      try {
        if (value === null || value === undefined || value === '') {
          return this.$t('string.na')
        }
        return value
      } catch (e) {
        return this.$t('string.na')
      }
    }
  }
}
</script>

<style scoped>
td >>> .font-km-bold {
  font-family: "Khmer MEF2", serif !important;
}

td >>> .font-en-bold {
  font-family: "Times New Roman", serif !important;
}
</style>
