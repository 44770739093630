<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.openConstructionDocument') }}
      </h3>
      <div class="box-tools">
        <button-back-to-list />
      </div>
    </div>
    <div class="box-body">
      <template v-if="project_uuid!==null">
        <construction-upload-document
          path="constructionLetter/open"
          column="col-xs-6 col-sm-2"
        />
      </template>
      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-success"
            @click="backToConstructionLetterList"
          >
            <i class="fa fa-check-circle" /> {{ $t('button.done') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConstructionUploadDocument from "../includes/ConstructionUploadDocument"
import ButtonBackToList from "../includes/ButtonBackToList"

export default {
  name: "Upload",
  components: {ButtonBackToList, ConstructionUploadDocument},
  metaInfo() {
    return {
      title: this.$t('string.openConstructionDocument'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  data() {
    return {
      project_uuid: null,
      project_id: null,
    }
  },
  computed: {
    api() {
      return `${this.baseUrl}/api/backend/construction-letter/open-construction-letter`
    },
  },
  mounted() {
    this.getProjectUuid()
  },
  methods: {
    showConstructionLetter() {
      this.$router.push({
        name: 'show-open-construction-letter',
        params: {
          construction_letter_uuid: this.$route.params.construction_letter_uuid,
        }
      })
    },
    async getProjectUuid() {
      let response = await this.$axios.post(`${this.api}/get-project-uuid-value`, {
        construction_letter_uuid: this.$route.params.construction_letter_uuid,
      })
      let data = await response.data.data
      this.project_uuid = data.project_uuid
      this.project_id = data.project_id
    },
    previewLetter() {
      window.open(
        `${this.baseUrl}/construction-letter/open-construction-letter/preview/${this.$route.params.construction_letter_uuid}`,
        '_blank'
      )
    },
    backToConstructionLetterList() {
      this.$router.push({name: 'list-open-construction-letter',})
    }
  }
}
</script>

<style scoped>

</style>
