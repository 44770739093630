<template>
  <architecture-data-table
    :architecture-category-id="[1]"
    :architecture-type-id="[2]"
    :permission-create="Permissions.backend.noneBoard.engineer.store"
    :permission-filter="Permissions.backend.noneBoard.engineer.filter"
    :title="$t('string.noneBoardEngineer')"
    model-route-create="create-none-board-engineer"
    model-route-edit="edit-none-board-engineer"
    model-route-view="show-none-board-engineer"
  />
</template>

<script>
import ArchitectureDataTable from "../../architecture/Table"
import Permissions from "@/permissions"
export default {
  name: "List",
  components: {ArchitectureDataTable},
  metaInfo() {
    return {
      title: this.$t('string.noneBoardEngineer'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      Permissions
    }
  }
}
</script>

<style scoped>

</style>
