<template>
  <div
    id="peopleModal"
    aria-labelledby="peopleModalLabel"
    class="modal fade"
    data-backdrop="static"
    data-keyboard="false"
    role="dialog"
    tabindex="-1"
  >
    <div
      class="modal-dialog modal-lg"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            aria-label="Close"
            class="close"
            data-dismiss="modal"
            type="button"
            @click="resetForm"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4
            id="peopleModalLabel"
            class="modal-title"
          >
            {{ $t('string.newPeople') }}
          </h4>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-6">
              <div
                :class="nameKmRequired.required?'has-error':null"
                class="form-group row"
              >
                <label class="col-sm-12 required">{{ $t('label.nameKm') }}</label>
                <div class="col-sm-12">
                  <input
                    v-model="people.name_km"
                    :placeholder="$t('label.nameKm')"
                    class="form-control"
                    type="text"
                  >
                  <template v-if="nameKmRequired.required">
                    <span class="label-error">{{ nameKmRequired.message }}</span>
                  </template>
                </div>
              </div>

              <div
                :class="nameEnRequired.required?'has-error':null"
                class="form-group row"
              >
                <label class="col-sm-12 required">{{ $t('label.nameEn') }}</label>
                <div class="col-sm-12">
                  <input
                    v-model="people.name_en"
                    :placeholder="$t('label.nameEn')"
                    class="form-control"
                    type="text"
                  >
                  <template v-if="nameEnRequired.required">
                    <span class="label-error">{{ nameEnRequired.message }}</span>
                  </template>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12">{{ $t('label.nationalId') }}</label>
                <div class="col-sm-12">
                  <input
                    v-model="people.national_id"
                    :placeholder="$t('label.nationalId')"
                    class="form-control"
                    type="text"
                  >
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12">{{ $t('label.passport') }}</label>
                <div class="col-sm-12">
                  <input
                    v-model="people.passport"
                    :placeholder="$t('label.passport')"
                    class="form-control"
                    type="text"
                  >
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12">{{ $t('label.dob') }}</label>
                <div class="col-sm-12">
                  <date-picker
                    v-model="people.dob"
                    :lang="datepickerLocale[$i18n.locale].lang"
                    :placeholder="$t('string.chooseDate')"
                    class="table-full-width"
                    format="DD/MM/YYYY"
                    type="date"
                  />
                </div>
              </div>

              <div
                :class="genderRequired.required?'has-error':null"
                class="form-group row"
              >
                <label class="col-sm-12 required">{{ $t('label.gender') }}</label>
                <div class="col-sm-12">
                  <select
                    v-model="people.gender_id"
                    class="form-control"
                  >
                    <option value="1">
                      {{ $t('label.male') }}
                    </option>
                    <option value="2">
                      {{ $t('label.female') }}
                    </option>
                  </select>
                  <template v-if="genderRequired.required">
                    <span class="label-error">{{ genderRequired.message }}</span>
                  </template>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12">{{ $t('label.country') }}</label>
                <div class="col-sm-12">
                  <select
                    v-model="people.country_id"
                    class="form-control"
                  >
                    <option value="">
                      {{ $t('string.none') }}
                    </option>
                    <option
                      v-for="(country, key) in countries"
                      :key="key"
                      :value="country.id"
                    >
                      {{ country['name_' + $i18n.locale] }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12">{{ $t('label.nationality') }}</label>
                <div class="col-sm-12">
                  <select
                    v-model="people.nationality_id"
                    class="form-control"
                  >
                    <option value="">
                      {{ $t('string.none') }}
                    </option>
                    <option
                      v-for="(nationality, key) in nationalities"
                      :key="key"
                      :value="nationality.id"
                    >
                      {{ nationality['name_' + $i18n.locale] }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group row">
                <label class="col-sm-12">{{ $t('label.email') }}</label>
                <div class="col-sm-12">
                  <input
                    v-model="people.email"
                    :placeholder="$t('label.email')"
                    class="form-control"
                    type="email"
                  >
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-12">{{ $t('label.phone') }}</label>
                <div class="col-sm-12">
                  <input
                    v-model="people.phone"
                    :placeholder="$t('label.phone')"
                    class="form-control"
                    type="tel"
                  >
                </div>
              </div>

              <location-input
                input-classes="col-sm-12"
                label-classes="col-sm-12"
              />

              <div class="form-group row">
                <label class="col-sm-12">{{ $t('label.avatar') }}</label>
                <div class="col-sm-12">
                  <input
                    ref="avatar"
                    name="avatar"
                    type="file"
                  > <br>
                  <template v-if="people.avatar">
                    <render-image-tag :src="people.avatar ? `${baseUrl}/${people.avatar}` : null" />
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            ref="close"
            class="btn btn-default"
            data-dismiss="modal"
            type="button"
            @click="resetForm"
          >
            {{ $t('button.close') }}
          </button>
          <button
            class="btn btn-primary"
            type="button"
            @click="store"
          >
            {{ $t('button.save') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import {validationMixins} from "@/mixins/validations"
import LocationInput from "@/components/LocationInput"

export default {
  name: "PeopleModal",
  components: {LocationInput},
  mixins: [validationMixins],
  data() {
    return {
      people: {},
      validations: null,
      params: new FormData(),
    }
  },
  computed: {
    ...mapState({
      address: state => state.location.address,
      countries: state => state.project.data.countries,
      nationalities: state => state.project.data.nationalities,
    })
  },
  watch: {
    'people.national_id'() {
      if (this.people.national_id !== '') {
        this.people.country_id = 1
        this.people.nationality_id = 33
      } else {
        this.people.country_id = ''
        this.people.nationality_id = ''
      }
    }
  },
  methods: {
    store() {
      this.$isLoading(true)
      this.prepareParams()
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/people/create', this.params)
        .then(() => {
          this.showToastr()
          this.$emit('fetchData')
          this.resetForm()
        })
        .catch((error) => {
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
            this.$store.commit('location/setValidations', res.data.errors)
          } else {
            this.$store.commit('location/setValidations', null)
            this.validations = null
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    prepareParams() {
      for (let key in this.people) {
        if (key === 'dob') {
          this.params.set(key, this.$moment(this.people[key]).locale('en').format('YYYY-MM-DD'))
        } else {
          let value = typeof (this.people[key]) === 'string' ? (this.people[key]).trim() : this.people[key]
          if (value === null || value === '') {
            this.params.delete(key)
          } else {
            this.params.set(key, value)
          }
        }
      }
      if (this.$refs.avatar.files.length > 0) {
        this.params.set('avatar', this.$refs.avatar.files[0])
      } else {
        this.params.delete('avatar')
      }
      this.params.set('is_registered', false)
      this.params.set('people_category_id', 1)
      // address
      for (let key in this.address) {
        if (this.address[key] !== '' && this.address[key] !== null) {
          this.$set(this.params, key, this.address[key])
        } else {
          this.params.delete(key)
        }
      }
    },
    resetForm() {
      this.validations = null
      this.people = {}
      this.$refs.close.click()
    }
  },
}
</script>

<style scoped>

</style>
