<template>
  <div class="row">
    <div class="col-md-12">
      <dashboard-card />
    </div>

    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">
                {{ $t('count.project') }}
              </h3>
            </div>
            <div class="box-body">
              <!--              ចំនួនបើកការដ្ឋាន/បិទការដ្ឋាន-->
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th class="column-1" />
                    <th class="column-other">
                      {{ $t('label.total') }}
                    </th>
                    <th class="column-other">
                      {{ $t('label.thisYear') }}
                    </th>
                    <th class="column-other">
                      {{ $t('label.thisMonth') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="column-1">
                    <td>{{ $t('label.openConstructionTotal') }}</td>
                    <td>{{ open_construction }}</td>
                    <td>{{ open_construction_year }}</td>
                    <td>{{ open_construction_month }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('label.stopConstructionTotal') }}</td>
                    <td>{{ stop_construction }}</td>
                    <td>{{ stop_construction_year }}</td>
                    <td>{{ stop_construction_month }}</td>
                  </tr>
                </tbody>
              </table>
              <br>
              <!--              កម្ពស់អគារ ( ជាន់ )-->
              <template v-if="floor">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th class="column-1">
                        {{ $t('label.buildingHeight') }} ( {{ $t('label.floor') }} )
                      </th>
                      <th class="column-other">
                        {{ $t('label.total') }}
                      </th>
                      <th class="column-other">
                        {{ $t('label.thisYear') }}
                      </th>
                      <th class="column-other">
                        {{ $t('label.thisMonth') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(item, key) in floor">
                      <tr :key="key">
                        <td>{{ item['name_' + $i18n.locale] }}</td>
                        <td>
                          <span>{{ $t('label.building') }} : </span><span class="text-bold">{{ formatPrice(item.number_building_total) }}</span><br>
                        </td>
                        <td>
                          <span>{{ $t('label.building') }} : </span><span class="text-bold">{{ formatPrice(item.number_building_year) }}</span><br>
                        </td>
                        <td>
                          <span>{{ $t('label.building') }} : </span><span class="text-bold">{{ formatPrice(item.number_building_month) }}</span><br>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </template>
              <br>
              <!--              ប្រភេទគម្រោង-->
              <table-report-project-by-type :array-data="project_by_type" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <template v-if="investment_budget">
      <div class="col-md-12">
        <list-report-investment-budget
          :investment="investment"
          :data="investment_budget"
        />
      </div>
    </template>

    <template v-if="business_builders">
      <div class="col-md-12">
        <list-report-business-builder :data="business_builders" />
      </div>
    </template>

    <construct-by-location />
    <construct-by-year />
    <construct-has-problem />
  </div>
</template>

<script>
import ConstructByLocation from "../sections/ConstructByLocation"
import ConstructByYear from "../sections/ConstructByYear"
import ConstructHasProblem from "../sections/ConstructHasProblem"
import ListReportBusinessBuilder from "@/components/ListReportBusinessBuilder"
import ListReportInvestmentBudget from "@/components/ListReportInvestmentBudget"
import TableReportProjectByType from "@/components/TableReportProjectByType"
import DashboardCard from "@/components/DashboardCard"

export default {
  name: 'DashboardLevelMinistry',
  components: {
    DashboardCard,
    TableReportProjectByType,
    ListReportInvestmentBudget,
    ListReportBusinessBuilder,
    ConstructHasProblem,
    ConstructByYear,
    ConstructByLocation
  },
  metaInfo() {
    return {
      title: this.$t('menu.ministryAuthority'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      config: process.env,
      business_builders: [],
      floor: [],
      investment_budget: [],
      project_by_type: [],
      investment: {
        investment_budget_local: 0,
        investment_budget_local_year: 0,
        investment_budget_local_month: 0,
        investment_budget_overseas: 0,
        investment_budget_overseas_year: 0,
        investment_budget_overseas_month: 0,
        investment_budget_total: 0,
        investment_budget_total_year: 0,
        investment_budget_total_month: 0,
      },
      open_construction: 0,
      open_construction_year: 0,
      open_construction_month: 0,
      stop_construction: 0,
      stop_construction_year: 0,
      stop_construction_month: 0,

    }
  },
  created() {
    this.getDataDetailInvestmentBudget()
    this.getDataDashboardSummary()
    this.getDataDetailOpenStopConstruction()
  },
  methods: {
    getDataDashboardSummary() {
      this.$axios.post(this.$base_api + '/api/backend/report/get-data-for-dashboard-summary', {
        is_province_data_level: false
      })
        .then((res) => {
          let data = res.data.data
          this.business_builders = data.business_builders
          this.floor = data.floor
          this.investment_budget = data.investment_budget
          this.project_by_type = data.project_by_type
        })
    },

    getDataDetailInvestmentBudget() {
      this.$axios.post(this.$base_api + '/api/backend/report/get-detail-investment-budget', {
        is_province_data_level: false
      })
        .then((res) => {
          let data = res.data.data
          this.investment.investment_budget_local = this.formatPrice(data['investment_budget_local'])
          this.investment.investment_budget_local_year = this.formatPrice(data['investment_budget_local_year'])
          this.investment.investment_budget_local_month = this.formatPrice(data['investment_budget_local_month'])
          this.investment.investment_budget_overseas = this.formatPrice(data['investment_budget_overseas'])
          this.investment.investment_budget_overseas_year = this.formatPrice(data['investment_budget_overseas_year'])
          this.investment.investment_budget_overseas_month = this.formatPrice(data['investment_budget_overseas_month'])
          this.investment.investment_budget_total = this.formatPrice(parseFloat(data['investment_budget']))
          this.investment.investment_budget_total_year = this.formatPrice(parseFloat(data['investment_budget_year']))
          this.investment.investment_budget_total_month = this.formatPrice(parseFloat(data['investment_budget_month']))
        })
    },

    getDataDetailOpenStopConstruction() {
      this.$axios.post(this.$base_api + '/api/backend/report/get-detail-open-stop-construction', {
        is_province_data_level: false
      })
        .then((res) => {
          let data = res.data.data
          this.open_construction = this.formatPrice(data['open_construction'])
          this.open_construction_year = this.formatPrice(data['open_construction_year'])
          this.open_construction_month = this.formatPrice(data['open_construction_month'])
          this.stop_construction = this.formatPrice(data['stop_construction'])
          this.stop_construction_year = this.formatPrice(data['stop_construction_year'])
          this.stop_construction_month = this.formatPrice(data['stop_construction_month'])
        })
    }
  }
}
</script>

<style scoped>
  .column-1 {
    width: 30%;
  }

  .column-other {
    width: 20%;
  }
</style>
