export const projectRequestRefOnChangeMixins = {
  methods: {
    onChangeOwnerRequestedAt(date) {
      this.project.commune_created_at = date
    },
    onChangeCommuneReleasedAt(date) {
      this.project.official_dnsp_created_at = date
    },
    onChangeOfficialDnspReleasedAt(date) {
      this.project.administration_province_created_at = date
    },
    onChangeAdministrationProvinceReleasedAt(date) {
      this.project.officer_dnsp_province_created_at = date
    },
    onChangeOfficerDnspProvinceReleasedAt(date) {
      this.project.administration_city_province_created_at = date
    },
    onChangeFirstBoardOfConstructionReleasedAt(date) {
      this.project.board_of_study_plans_created_at = date
    },
    onChangeBoardOfStudyPlansReleasedAt(date) {
      this.project.second_board_of_construction_created_at = date
    },
    onChangeLandUrbanizationReleasedAt(date) {
      this.project.don_created_at = date
    },
    onChangeDonReleasedAt(date) {
      this.project.asn_created_at = date
    },
    onChangeAsnReleasedAt(date) {
      this.project.asn_board_of_study_plans_created_at = date
    },
    onChangeAsnBoardOfStudyPlansReleasedAt(date) {
      this.project.asn_second_board_of_construction_created_at = date
    }
  }
}
