<template>
  <div>
    <table class="table table-bordered table-bordered">
      <thead>
        <tr>
          <th>{{ $t('field.label.construction_certifier_number') }}</th>
          <th>{{ $t('field.label.certificate_number_of_ccl') }}</th>
          <th>{{ $t('field.label.approved_by') }}</th>
          <th>{{ $t('field.placeholder.construction_certifier_valid_until_date') }}</th>
          <th>{{ $t('table.action') }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="licenses && Array.isArray(licenses) && licenses.length > 0">
          <tr
            v-for="(license, key) in licenses"
            :key="key"
            :class="{'tw-bg-green-100' : selectedLicense && selectedLicense.id === license.id}"
          >
            <td>{{ license.construction_certifier_number }} / {{ license.construction_certifier_suffix }}</td>
            <td>{{ license.certificate_number || $t('string.na') }}</td>
            <td>{{ license.approved_by || $t('string.na') }}</td>
            <td>{{ getDate(license.valid_until, false, 'DD/MM/YYYY') }}</td>
            <td class="tw-space-x-1">
              <button
                class="btn btn-default btn-xs"
                @click="onSelected(license)"
              >
                <i class="fas fa-info-circle" />
              </button>
              <button
                v-if="!license.approved"
                class="btn btn-primary btn-xs"
                @click="onApprove(license)"
              >
                <i class="fa fa-check-circle" />
              </button>
              <button
                v-if="!license.approved"
                class="btn btn-info btn-xs"
                @click="onEdit(license)"
              >
                <i class="fas fa-edit" />
              </button>
              <button
                v-if="!license.approved"
                class="btn btn-danger btn-xs"
                @click="onDelete(license)"
              >
                <i class="fas fa-trash" />
              </button>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr class="text-center">
            <td colspan="5">
              {{ $t('string.no_result') }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields"

export default {
  name: "ConstructionCertifierLicenseList",
  data() {
    return {
      licenses: []
    }
  },
  computed: {
    ...mapFields('company', {
      selectedLicense: 'constructionCertifier.selectedLicense',
      editLicenseUuid: 'constructionCertifier.editLicenseUuid',
      approveLicenseUuid: 'constructionCertifier.approveLicenseUuid',
    })
  },
  mounted() {
    this.getConstructionCertifierLicenseList()
  },
  methods: {
    getConstructionCertifierLicenseList() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/construction-certifier-license/list', {
        company_uuid: this.$route.params.company_uuid
      }).then(({data}) => {
        if (data.data && Array.isArray(data.data) && data.data.length > 0) {
          this.licenses = data.data
          this.selectedLicense = this.licenses[0]
        }
      }).catch((error) => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    onSelected(license) {
      this.selectedLicense = license
    },
    onEdit(license) {
      this.editLicenseUuid = license.uuid
      $('#constructionLicenseModal').modal('show')
    },
    onApprove(license) {
      this.approveLicenseUuid = license.uuid
      $('#approveConstructionLicenseModal').modal('show')
    },
    onDelete(license) {
      this.onConfirm({})
        .then((accept) => {
          if (accept) {
            this.$axios.post(process.env.VUE_APP_API + '/api/backend/construction-certifier-license/delete', {
              license_uuid: license.uuid
            }).then(() => {
              this.showToastr()
              this.getConstructionCertifierLicenseList()
            }).catch((error) => {
              if (error.response && error.response.status && error.response.status === 422) {
                this.validations = error.response.data.errors
              } else {
                this.onResponseError(error)
              }
            }).finally(() => {
              this.$isLoading(false)
            })
          }
        })
    }
  }
}
</script>

<style scoped>

</style>
