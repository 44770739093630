<template>
  <div class="col-md-12">
    <div class="box box-primary">
      <div class="box-header with-border">
        <h3 class="box-title">
          {{ $t('string.constructByYear') }}
        </h3>
      </div>
      <div class="box-body">
        <highcharts :options="chartOptions" />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ConstructByYear',
  data() {
    return {
      chartOptions: {
        title: {
          text: this.$t('string.constructionByYear'),
        },

        yAxis: {
          title: {
            text: this.$t('string.total')
          }
        },

        xAxis: {
          title: {
            text: this.$t('string.year'),
            categories: [],
          }
        },

        series: [
          {
            name: this.$t('string.total'),
            type: 'column',
            colorByPoint: true,
            data: [],
            showInLegend: false
          }
        ]
      },
    }
  },
  watch: {
    "$i18n.locale" () {
      this.chartOptions.title.text = this.$t('string.constructionByYear')
      this.chartOptions.yAxis.title.text = this.$t('string.total')
      this.chartOptions.xAxis.title.text = this.$t('string.year')
      this.chartOptions.series[0].name = this.$t('string.total')
    }
  },
  created() {
    this.getProjectsByYear()
  },
  methods: {
    getProjectsByYear() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/report/get-projects-by-year')
        .then((res) => {
          let data = res.data.data
          this.chartOptions.series[0].data = data.series
          this.chartOptions.xAxis.categories = data.xAxis
        })
    }
  }
}
</script>

<style>
</style>
