<template>
  <div class="row">
    <div class="col-md-9">
      <h3 class="font-secondary">
        {{ title }}
      </h3>

      <div
        :class="{'has-error' : validations['title']}"
        class="form-group"
      >
        <input
          v-model="news.title"
          :placeholder="$t('placeholder.enter_title_here')"
          class="form-control"
          type="text"
        >
        <span
          v-if="validations['title']"
          class="label-error"
        >{{ validations['title'][0] }}</span>
      </div>

      <div
        :class="{'has-error' : validations.short_description}"
        class="form-group"
      >
        <textarea
          v-model="news.short_description"
          :placeholder="$t('placeholder.enter_short_description_here')"
          class="form-control"
          rows="5"
        />
        <span
          v-if="validations['short_description']"
          class="label-error"
        >{{ validations['short_description'][0] }}</span>
      </div>

      <div
        :class="{'has-error' : validations.content}"
        class="form-group"
      >
        <div class="quill-editor-wrapper">
          <quill-editor
            ref="myQuillEditor"
            v-model="news.content"
            :options="editorOption"
          />
        </div>
        <span
          v-if="validations['content']"
          class="label-error"
        >{{ validations['content'][0] }}</span>
      </div>
    </div>
    <div class="col-md-3">
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default publishing-mr-55">
            <div class="panel-heading">
              <h3 class="panel-title">
                {{ $t('string.publishing_types') }}
              </h3>
            </div>
            <div
              v-if="$route.name === 'edit-news'"
              class="panel-body"
            >
              <div class="form-group">
                <label>
                  <input
                    v-model="news.is_published"
                    :value="false"
                    name="publishing_type"
                    type="radio"
                  > {{ $t('string.draft') }}
                </label>
              </div>

              <div class="form-group">
                <label>
                  <input
                    v-model="news.is_published"
                    :value="true"
                    name="publishing_type"
                    type="radio"
                  > {{ $t('string.publish') }}
                </label>
              </div>
            </div>
            <div class="panel-footer">
              <div class="publishing-tool">
                <button
                  :disabled="!$route.params.news_id"
                  class="btn btn-danger-outline btn-sm"
                  @click="onDelete"
                >
                  {{ $t('string.move_to_trash') }}
                </button>
                <div>
                  <button
                    class="btn btn-default btn-sm margin-right-5"
                    @click="$router.back()"
                  >
                    {{ $t('button.back') }}
                  </button>
                  <button
                    class="btn btn-primary btn-sm"
                    @click="saveNews"
                  >
                    {{ $t('button.save') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h3 class="panel-title">
                {{ $t('string.categories') }}
              </h3>
            </div>
            <div class="panel-body">
              <template v-for="(category, key) in newsCategories">
                <div
                  :key="key"
                  class="form-group"
                >
                  <label>
                    <input
                      v-model="news.news_category_id"
                      :value="category.id"
                      name="categories"
                      type="radio"
                    > {{ category.name }}
                  </label>
                </div>
              </template>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h3 class="panel-title">
                {{ $t('string.thumbnail') }}
              </h3>
            </div>
            <div class="panel-body">
              <div>
                <img
                  :src="imageUrl"
                  alt="News Thumbnail"
                  class="news-thumbnail"
                  @error="replaceImageByDefault"
                >
                <input
                  id="thumbnail"
                  ref="thumbnail"
                  class="hidden"
                  name="thumbnail"
                  type="file"
                  @change="onFileChange"
                >
              </div>
            </div>
            <div class="panel-footer">
              <div class="thumbnail-tools">
                <button
                  class="btn btn-default btn-sm margin-right-5"
                  @click="generateThumbnail"
                >
                  <i class="fa fa-image" /> {{ $t('string.generate_thumbnail') }}
                </button>
                <label
                  class="btn btn-primary btn-sm margin-right-5"
                  for="thumbnail"
                >
                  <i class="fa fa-image" /> {{ $t('string.choose_thumbnail') }}
                </label>
                <button
                  :disabled="!news.thumbnail"
                  class="btn btn-danger btn-sm"
                  @click="removeThumbnail"
                >
                  <i class="fa fa-trash" /> {{ $t('string.remove') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import {quillEditor} from 'vue-quill-editor'

export default {
  name: 'NewsForm',
  components: {
    quillEditor
  },
  data() {
    return {
      editorOption: {
        placeholder: this.$t('string.enter_content_here')
      },
      newsCategories: [],
      news: {
        id: null,
        content: null,
        is_published: true,
        news_category_id: 1,
        short_description: null,
        title: null
      },
      validations: {},
      imageUrl: '/img/thumbnail.png'
    }
  },
  computed: {
    endPoint() {
      if (this.$route.params.news_id) {
        return `${this.baseUrl}/api/backend/news/update`
      }
      return `${this.baseUrl}/api/backend/news/create`
    },
    title() {
      if (this.$route.params.news_id) {
        return this.$t('string.edit_a_news')
      }
      return this.$t('string.add_new_news')
    },
  },
  mounted() {
    this.getNewsCategories()
    if (this.$route.params.news_id) {
      this.getNews()
    }
  },
  methods: {
    onEditorChange({html}) {
      this.content = html
    },
    getNewsCategories() {
      this.$axios.post(this.baseUrl + '/api/backend/news/get-news-categories')
        .then(({data}) => {
          if (data.data) {
            this.newsCategories = data.data
          } else {
            this.newsCategories = []
          }
        })
        .catch((error) => {
          this.onResponseError(error)
        })
    },
    getNews() {
      this.$isLoading(true)
      this.$axios.post(this.baseUrl + '/api/backend/news/show', {
        news_id: this.$route.params.news_id
      })
        .then(({data}) => {
          if (data.data) {
            this.news = data.data
            this.news.news_id = this.news.id
            if (this.news.thumbnail) {
              this.imageUrl = `${this.baseUrl}/${this.news.thumbnail}`
            } else {
              this.imageUrl = '/img/thumbnail.png'
            }
          } else {
            this.news = {}
          }
        })
        .catch((error) => {
          this.onResponseError(error)
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    saveNews() {
      let params = new FormData()
      Object.keys(this.news).forEach((key) => {
        if (this.news[key] || this.news[key] === false || this.news[key] === true) {
          params.set(key, this.news[key])
        } else {
          params.delete(this.news[key])
        }
      })

      if (this.$refs.thumbnail.files && this.$refs.thumbnail.files.length) {
        params.set('thumbnail', this.$refs.thumbnail.files[0])
      } else {
        params.delete('thumbnail')
      }

      this.validations = {}
      this.$isLoading(true)
      this.$axios.post(this.endPoint, params)
        .then(() => {
          this.$router.push({
            name: 'list-news'
          })
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.validations = error.response.data.errors
          } else {
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    onDelete() {
      this.$swal({
        title: this.i18nSwalTitle,
        text: this.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.i18nSwalNo,
        confirmButtonText: this.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$isLoading(true)
          this.$axios.post(`${this.baseUrl}/api/backend/news/delete`, {
            news_id: this.$route.params.news_id
          }).then(() => {
            this.showSwalSuccess()
            this.$router.push({
              name: 'list-news'
            })
          }).catch(error => {
            this.onResponseError(error)
          }).finally(() => {
            this.$isLoading(false)
          })
        }
      })
    },
    removeThumbnail() {
      this.$swal({
        title: this.i18nSwalTitle,
        text: this.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.i18nSwalNo,
        confirmButtonText: this.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$isLoading(true)
          this.$axios.post(`${this.baseUrl}/api/backend/news/remove-thumbnail`, {
            news_id: this.$route.params.news_id
          }).then(() => {
            this.showSwalSuccess()
            this.getNews()
          }).catch(error => {
            this.onResponseError(error)
          }).finally(() => {
            this.$isLoading(false)
          })
        }
      })
    },
    generateThumbnail() {
      this.$isLoading(true)
      this.$axios.post(`${this.baseUrl}/api/backend/news/generate-thumbnail`, {
        news_id: this.$route.params.news_id
      }).then(() => {
        this.showToastr()
        this.getNews()
      }).catch(error => {
        this.onResponseError(error)
      }).finally(() => {
        this.$isLoading(false)
      })
    },
    onFileChange(e) {
      const file = e.target.files[0]
      if (file) {
        this.imageUrl = URL.createObjectURL(file)
      }
    },
    replaceImageByDefault(e) {
      e.target.src = '/img/thumbnail.png'
    }
  }
}
</script>

<style>
.publishing-mr-55 {
  margin-top: 55px;
}

.btn-danger-outline,
.btn-danger-outline:hover {
  background: transparent;
  padding-left: 0;
  padding-right: 0;
  color: var(--danger);
  outline: none;
}

.publishing-tool {
  display: flex;
  justify-content: space-between;
}

.quill-editor-wrapper {
  background: white;
}

.ql-container {
  min-height: 520px !important;
}

.news-thumbnail {
  width: 100%;
  height: auto;
}

.thumbnail-tools {
  display: flex;
  justify-content: flex-end;
}

.ql-editor.ql-blank::before {
  font-style: normal;
}
</style>
