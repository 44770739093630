const state = {
  data: {
    project_type_id: null,
  }
}
const getters = {}

const mutations = {
  setProjectTypeId(state, project_type_id) {
    state.data.project_type_id = project_type_id
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
