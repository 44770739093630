<template>
  <div :class="wrapperClass">
    <div
      class="form-group row"
      :class="`${columnValidation && columnValidation.required ? ' has-error' : ''} ${formGroupClasses}`"
    >
      <label :class="`${labelClass} ${columnValidation ? ' required':''}`">
        {{ label }}
      </label>
      <div :class="inputClass">
        <slot />
        <template v-if="columnValidation && columnValidation.required">
          <span class="label-error">{{ columnValidation.message }}</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectFormInput",
  props: {
    wrapperClass: {
      type: String,
      default: 'col-sm-6',
    },
    formGroupClasses: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: 'col-sm-9',
    },
    labelClass: {
      type: String,
      default: 'col-sm-3 control-label',
    },
    // eslint-disable-next-line vue/require-default-prop
    columnValidation: {
      type: Object
    },
    label: {
      type: String,
      required: true,
    },
  }
}
</script>

<style scoped>

</style>
