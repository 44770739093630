<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('menu.news') }}
      </h3>
      <div class="box-tools">
        <template v-if="can([$Permissions.backend.news.store])">
          <router-link
            :to="{name: 'create-news'}"
            class="btn btn-primary btn-sm"
          >
            <i class="fa fa-plus" /> {{ $t('string.add') }}
          </router-link>
        </template>
      </div>
    </div>
    <div class="box-body">
      <div>
        <table
          id="news-table"
          class="table table-hover table-full-width table-striped table-bordered"
        >
          <thead>
            <tr>
              <th>{{ $t('table.thumbnail') }}</th>
              <th>{{ $t('table.news_title') }}</th>
              <th>{{ $t('table.news_category') }}</th>
              <th>{{ $t('table.publishing') }}</th>
              <th>{{ $t('table.createdAt') }}</th>
              <th>{{ $t('table.action') }}</th>
            </tr>
          </thead>
          <tbody />
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'List',
  metaInfo() {
    return {
      title: this.$t('menu.news'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data() {
    return {
      oTable: null
    }
  },
  watch: {
    '$i18n.locale'() {
      this.oTable.clear()
      this.oTable.destroy()
      this.oTable = this.refreshTable()
      this.oTable.draw(true)
    }
  },
  mounted() {
    this.oTable = this.refreshTable()
    this.enabledAction()
  },
  beforeDestroy() {
    this.oTable.clear()
    this.oTable.destroy()
  },
  methods: {
    refreshTable() {
      let self = this
      return $('#news-table').DataTable({
        stateSave: true,
        processing: true,
        serverSide: true,
        scrollX: true,
        pageLength: process.env.VUE_APP_PAGE_LENGTH,
        ajax: {
          method: 'POST',
          url: process.env.VUE_APP_API + '/api/backend/news/list',
          error: (xhr) => {
            self.onResponseError(xhr)
          },
          data: (d) => {
            d.edit = this.$t('string.edit')
            d.show = this.$t('string.show')
            d.delete = this.$t('string.delete')
          },
        },
        columns: [
          {
            data: 'thumbnail',
            name: 'thumbnail',
            title: self.$t('table.thumbnail'),
            width: '100px',
            render: (data) => {
              if (data) {
                let path = data
                if (!data.toString().includes('storage/news')) {
                  path = `storage/news/${path}`
                }
                return `<img src="${this.baseUrl}/${path}" class="thumbnail" style="width: 80px; height: auto;"/>`
              }
              return '<img src="/img/thumbnail_sm.png" class="thumbnail" style="width: 80px; height: auto;"/>'
            }
          },
          {data: 'title', name: 'title', title: self.$t('table.news_title')},
          {
            data: 'news_category.name',
            name: 'newsCategory.name',
            title: self.$t('table.news_category'),
            width: '100px',
            orderable: false,
            render: (data) => {
              if (data) {
                return data
              }
              return this.$t('string.na')
            }
          },
          {
            data: 'is_published',
            name: 'is_published',
            title: self.$t('table.publishing'),
            width: '100px',
            render: (data) => {
              if (data) {
                return `<span class="label label-primary"><i class="fa fa-check-circle"></i></span>`
              }
              return `<span class="label label-warning"><i class="fa fa-times"></i></span>`
            }
          },
          {
            data: 'created_at',
            name: 'created_at',
            searchable: false,
            width: '100px',
            title: self.$t('table.createdAt'),
            render: (data) => {
              return this.getDate(data)
            }
          },
          {
            data: 'action',
            name: 'action',
            orderable: false,
            searchable: false,
            title: self.$t('table.action'),
            width: '140px',
            render: (data, type, row) => {
              let action = ''
              if (this.$Permissions.backend.news.toggle) {
                if (row['is_published']) {
                  action += `<button class="btn btn-default btn-xs btn-toggle" title="${this.$t('string.toggle')}" data-news-id="${row['id']}"><i class="fa fa-toggle-off"></i></button> `
                } else {
                  action += `<button class="btn btn-warning btn-xs btn-toggle" title="${this.$t('string.toggle')}" data-news-id="${row['id']}"><i class="fa fa-toggle-on"></i></button> `
                }
              }
              if (this.$Permissions.backend.news.edit) {
                action += `<button class="btn btn-primary btn-xs btn-edit" title="${this.$t('string.edit')}" data-news-id="${row['id']}"><i class="fa fa-edit"></i></button> `
              }
              if (this.$Permissions.backend.news.show) {
                action += `<button class="btn btn-info btn-xs btn-show" title="${this.$t('string.show')}" data-news-id="${row['id']}"><i class="fa fa-search"></i></button> `
              }
              if (this.$Permissions.backend.news.delete) {
                action += `<button class="btn btn-danger btn-xs btn-delete" title="${this.$t('string.delete')}" data-news-id="${row['id']}"><i class="fa fa-trash"></i></button>`
              }
              return action
            }
          },
        ],
        language: {
          url: self.getLocale,
        },
        fixedColumns: true,
        order: [
          [4, 'desc']
        ]
      })
    },
    enabledAction() {
      let self = this
      this.clearEventHandler([
        '.btn-show',
        '.btn-edit',
        '.btn-delete',
        '.btn-toggle',
      ])
      $(document).on('click', '.btn-show', function () {
        self.$router.push({
          name: 'show-news',
          params: {
            news_id: $(this).attr('data-news-id')
          }
        })
      })
      $(document).on('click', '.btn-edit', function () {
        self.$router.push({
          name: 'edit-news',
          params: {
            news_id: $(this).attr('data-news-id')
          }
        })
      })
      $(document).on('click', '.btn-delete', function () {
        self.onDelete({
          url: '/api/backend/news/delete',
          params: {
            news_id: $(this).attr('data-news-id')
          }
        })
      })
      $(document).on('click', '.btn-toggle', function () {
        self.onToggle($(this).attr('data-news-id'))
      })
    },
    onDelete({url, params}) {
      this.$swal({
        title: this.i18nSwalTitle,
        text: this.i18nSwalDesc,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.i18nSwalNo,
        confirmButtonText: this.i18nSwalYes
      }).then((result) => {
        if (result.value) {
          this.$isLoading(true)
          this.$axios.post(`${process.env.VUE_APP_API}/${url}`, params)
            .then(() => {
              this.showSwalSuccess()
              this.oTable.draw()
            })
            .catch(error => {
              this.onResponseError(error)
            })
            .finally(() => {
              this.$isLoading(false)
            })
        }
      })
    },
    onToggle(news_id) {
      this.$swal({
        title: this.i18nSwalTitle,
        text: this.$t('string.do_you_want_to_toggle_this_news?'),
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: this.i18nSwalNo,
        confirmButtonText: this.$t('string.yes')
      }).then((result) => {
        if (result.value) {
          this.$isLoading(true)
          this.$axios.post(this.baseUrl + '/api/backend/news/toggle', {news_id: news_id})
            .then(() => {
              this.showSwalSuccess()
              this.oTable.draw()
            })
            .catch(error => {
              this.onResponseError(error)
            })
            .finally(() => {
              this.$isLoading(false)
            })
        }
      })
    }
  },
}
</script>

<style scoped>

</style>
