import ArchitectureType from "./../../views/settings/architectureType/Index"
import List from "./../../views/settings/architectureType/List"
import Create from "./../../views/settings/architectureType/Create"
import Edit from "./../../views/settings/architectureType/Edit"
import Show from "./../../views/settings/architectureType/Show"
import Permissions from "../../permissions"

export const architectureTypeRouters = {
  path: 'architecture-type',
  component: ArchitectureType,
  redirect: 'architecture-type',
  meta: {
    permissions: [Permissions.backend.setting.architectureType.manage],
    groups: 'architecture-type',
  },
  children: [
    {
      path: '',
      name: 'list-architecture-type',
      component: List,
      meta: {
        permissions: [Permissions.backend.setting.architectureType.list],
        groups: 'architecture-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Architecture Type',
            name_km: 'ប្រភេទវិស្វករ'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-architecture-type',
      component: Create,
      meta: {
        permissions: [Permissions.backend.setting.architectureType.store],
        groups: 'architecture-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'New Architecture Type',
            name_km: 'បន្ថែមប្រភេទវិស្វករ'
          }
        ]
      }
    },
    {
      path: 'edit/:architecture_type_uuid',
      name: 'edit-architecture-type',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.setting.architectureType.edit],
        groups: 'architecture-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Edit Architecture Type',
            name_km: 'កែប្រែសំណង់'
          }
        ]
      }
    },
    {
      path: 'show/:architecture_type_uuid',
      name: 'show-architecture-type',
      component: Show,
      meta: {
        permissions: [Permissions.backend.setting.architectureType.show],
        groups: 'architecture-type',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Show Architecture Type',
            name_km: 'បង្ហាញព័ត៏មានប្រភេទវិស្វករ'
          }
        ]
      }
    }
  ]
}
