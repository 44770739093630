<template>
  <div class="row">
    <div :class="containerColumn">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ title }}
          </h3>
          <div class="box-tools">
            <PublishCompanyButton />
            <asora-back-button />
          </div>
        </div>
        <div class="box-body">
          <div class="form-horizontal">
            <div
              class="form-group row"
              :class="{'has-error': validations.name_km}"
            >
              <label class="col-sm-4 control-label required">{{ $t('field.label.company_name_km') }}</label>
              <div class="col-sm-8">
                <input
                  v-model="company.name_km"
                  type="text"
                  :placeholder="$t('field.placeholder.company_name_km')"
                  class="form-control"
                >
                <template v-if="validations.name_km">
                  <FiledIsRequired :msg="validations['name_km'][0]" />
                </template>
              </div>
            </div>

            <div
              class="form-group row"
              :class="{'has-error': validations.name_en}"
            >
              <label class="col-sm-4 control-label required">{{ $t('field.label.company_name_en') }}</label>
              <div class="col-sm-8">
                <input
                  v-model="company.name_en"
                  type="text"
                  :placeholder="$t('field.placeholder.company_name_en')"
                  class="form-control"
                >
                <template v-if="validations.name_en">
                  <FiledIsRequired :msg="validations['name_en'][0]" />
                </template>
              </div>
            </div>

            <div
              class="form-group row"
              :class="{'has-error': validations.owners}"
            >
              <label class="col-sm-4 control-label required">{{ $t('field.label.company_owner_name') }}</label>
              <div class="col-sm-8">
                <div class="input-group">
                  <multiselect
                    v-model="company.owners"
                    :label="`name_${$i18n.locale}`"
                    track-by="id"
                    :placeholder="$t('field.placeholder.company_owner_name')"
                    :select-label="$t('string.press_enter_to_select')"
                    :deselect-label="$t('string.press_enter_to_remove')"
                    :multiple="true"
                    :options="people"
                    :searchable="true"
                    :internal-search="false"
                    :clear-on-select="false"
                    @search-change="searchOwners"
                  >
                    <template slot="noOptions">
                      {{ $t('string.noOptions') }}
                    </template>
                    <template slot="noResult">
                      {{ $t('string.noResult') }}
                    </template>
                  </multiselect>
                  <span class="input-group-btn">
                    <button
                      class="btn btn-primary tw-h-[43px]"
                      type="button"
                      @click="addOwnerPeople"
                    >
                      <i class="fa fa-plus-circle" /> {{ $t('button.add') }}
                    </button>
                  </span>
                </div>
                <template v-if="validations.owners">
                  <FiledIsRequired :msg="validations['owners'][0]" />
                </template>
              </div>
              <template v-if="company.owners && company.owners.length>0">
                <div class="col-sm-8 col-sm-offset-4 margin-top-15">
                  <people-chip-item
                    v-for="(item, key) in company.owners"
                    :key="key"
                    :people="item"
                    model="people"
                  />
                </div>
              </template>
            </div>

            <div
              v-if="!investorCompany"
              class="form-group row"
            >
              <label class="col-sm-4 control-label">{{ $t('field.label.cto_name') }}</label>
              <div class="col-sm-8">
                <div class="input-group">
                  <multiselect
                    v-model="company.cto"
                    :label="`name_${$i18n.locale}`"
                    track-by="id"
                    :tags="true"
                    :placeholder="$t('field.placeholder.cto_name')"
                    :select-label="$t('string.press_enter_to_select')"
                    :deselect-label="$t('string.press_enter_to_remove')"
                    :multiple="true"
                    :options="people"
                    :searchable="true"
                    :internal-search="false"
                    :clear-on-select="false"
                    @search-change="searchOwners"
                  >
                    <template slot="noOptions">
                      {{ $t('string.noOptions') }}
                    </template>
                    <template slot="noResult">
                      {{ $t('string.noResult') }}
                    </template>
                  </multiselect>
                  <span class="input-group-btn">
                    <button
                      class="btn btn-primary tw-h-[43px]"
                      type="button"
                      @click="addCtoPeople"
                    >
                      <i class="fa fa-plus-circle" /> {{ $t('button.add') }}
                    </button>
                  </span>
                </div>
              </div>
              <template v-if="company.cto && company.cto.length>0">
                <div class="col-sm-8 col-sm-offset-4 margin-top-15">
                  <people-chip-item
                    v-for="(item, key) in company.cto"
                    :key="key"
                    :people="item"
                    model="people"
                  />
                </div>
              </template>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('field.label.technician_name') }}</label>
              <div class="col-sm-8">
                <div class="input-group">
                  <multiselect
                    v-model="company.technicians"
                    :label="`name_${$i18n.locale}`"
                    track-by="id"
                    :tags="true"
                    :placeholder="$t('field.placeholder.technician_name')"
                    :select-label="$t('string.press_enter_to_select')"
                    :deselect-label="$t('string.press_enter_to_remove')"
                    :multiple="true"
                    :options="people"
                    :searchable="true"
                    :internal-search="false"
                    :clear-on-select="false"
                    @search-change="searchOwners"
                  >
                    <template slot="noOptions">
                      {{ $t('string.noOptions') }}
                    </template>
                    <template slot="noResult">
                      {{ $t('string.noResult') }}
                    </template>
                  </multiselect>
                  <span class="input-group-btn">
                    <button
                      class="btn btn-primary tw-h-[43px]"
                      type="button"
                      @click="addTechnicianPeople"
                    >
                      <i class="fa fa-plus-circle" /> {{ $t('button.add') }}
                    </button>
                  </span>
                </div>
                <template v-if="company.technicians && company.technicians.length>0">
                  <people-chip-item
                    v-for="(item, key) in company.technicians"
                    :key="key"
                    :people="item"
                    model="people"
                  />
                </template>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.firstRegistrationNumber') }}</label>
              <div class="col-sm-8">
                <input
                  v-model="company.first_registration_number"
                  type="text"
                  :placeholder="$t('label.firstRegistrationNumber')"
                  class="form-control"
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.first_registration_date') }}</label>
              <div class="col-sm-8">
                <date-picker
                  v-model="company.first_registration_date"
                  :lang="datepickerLocale[$i18n.locale].lang"
                  :placeholder="$t('label.first_registration_date')"
                  type="date"
                  format="DD/MM/YYYY"
                  class="table-full-width"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.certificateOfMefNumber') }}</label>
              <div class="col-sm-8">
                <input
                  v-model="company.certificate_of_mef_number"
                  type="text"
                  :placeholder="$t('label.certificateOfMefNumber')"
                  class="form-control"
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('field.label.construction_certifier_mfc_date') }}</label>
              <div class="col-sm-8">
                <date-picker
                  v-model="company.certificate_of_mef_date"
                  :lang="datepickerLocale[$i18n.locale].lang"
                  :placeholder="$t('field.placeholder.construction_certifier_mfc_date')"
                  type="date"
                  format="DD/MM/YYYY"
                  class="table-full-width"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.firstRegistrationYear') }}</label>
              <div class="col-sm-8">
                <input
                  v-model="company.year"
                  type="number"
                  :placeholder="$t('label.firstRegistrationYear')"
                  class="form-control"
                >
              </div>
            </div>

            <div
              v-if="!investorCompany"
              class="form-group row"
            >
              <label class="col-sm-4 control-label">{{ $t('label.companyType') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="company.company_type"
                  :label="`name_${$i18n.locale}`"
                  track-by="id"
                  :placeholder="$t('string.chooseCompanyType')"
                  :options="companyTypes"
                  :searchable="true"
                  :internal-search="false"
                  :clear-on-select="false"
                  @search-change="getCompanyTypes"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>

            <div
              v-if="!investorCompany"
              class="form-group row"
            >
              <label class="col-sm-4 control-label">{{ $t('label.companySection') }}</label>
              <div class="col-sm-8">
                <multiselect
                  v-model="company.company_section"
                  :label="`name_${$i18n.locale}`"
                  track-by="id"
                  :placeholder="$t('string.chooseCompanySection')"
                  :options="companySections"
                  :searchable="true"
                  :internal-search="false"
                  :clear-on-select="false"
                  @search-change="getCompanySections"
                >
                  <template slot="noOptions">
                    {{ $t('string.noOptions') }}
                  </template>
                  <template slot="noResult">
                    {{ $t('string.noResult') }}
                  </template>
                </multiselect>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.email') }}</label>
              <div class="col-sm-8">
                <input
                  v-model="company.email"
                  type="email"
                  :placeholder="$t('label.email')"
                  class="form-control"
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.phone') }}</label>
              <div class="col-sm-8">
                <input
                  v-model="company.phone"
                  type="number"
                  :placeholder="$t('label.phone')"
                  class="form-control"
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.website') }}</label>
              <div class="col-sm-8">
                <input
                  v-model="company.website"
                  type="url"
                  :placeholder="$t('label.website')"
                  class="form-control"
                >
              </div>
            </div>

            <div
              class="form-group row"
              :class="{'has-error' : validations.country_id}"
            >
              <label class="col-sm-4 control-label required">{{ $t('label.country') }}</label>
              <div class="col-sm-8">
                <select
                  v-model="company.country_id"
                  class="form-control"
                >
                  <option
                    selected
                    disabled
                    :value="null"
                  >
                    {{ $t('string.select_country') }}
                  </option>
                  <option
                    v-for="(country, key) in countries"
                    :key="key"
                    :value="country.id"
                  >
                    {{ country['name_' + $i18n.locale] }}
                  </option>
                </select>
                <template v-if="validations.country_id">
                  <FiledIsRequired :msg="validations['country_id'][0]" />
                </template>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.nationality') }}</label>
              <div class="col-sm-8">
                <select
                  v-model="company.nationality_id"
                  class="form-control"
                >
                  <option
                    selected
                    disabled
                    :value="null"
                  >
                    {{ $t('string.select_nationality') }}
                  </option>
                  <option
                    v-for="(nationality, key) in nationalities"
                    :key="key"
                    :value="nationality.id"
                  >
                    {{ nationality['name_' + $i18n.locale] }}
                  </option>
                </select>
              </div>
            </div>

            <location-input
              label-classes="col-sm-4 control-label"
              input-classes="col-sm-8"
            />

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.lat') }}</label>
              <div class="col-sm-8">
                <input
                  v-model="company.lat"
                  type="number"
                  :placeholder="$t('label.lat')"
                  class="form-control"
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.lng') }}</label>
              <div class="col-sm-8">
                <input
                  v-model="company.lng"
                  type="number"
                  :placeholder="$t('label.lng')"
                  class="form-control"
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.map') }}</label>
              <div class="col-sm-8">
                <div class="thumbnail no-margin-bottom">
                  <GmapMap
                    :center="{lat:company.lat, lng:company.lng}"
                    :zoom="15"
                    map-type-id="terrain"
                    style="width: 100%; height: 430px"
                  >
                    <GmapMarker
                      :clickable="true"
                      :draggable="true"
                      :icon="{url: '/img/markers/blue20x20.png'}"
                      :position="{lat:company.lat, lng:company.lng}"
                      @dragend="movePinOnGoogleMap"
                    />
                  </GmapMap>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.description') }}</label>
              <div class="col-sm-8">
                <textarea
                  v-model="company.description"
                  name="description"
                  :placeholder="$t('label.description')"
                  rows="5"
                  class="form-control"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-4 control-label">{{ $t('label.logo') }}</label>
              <div class="col-sm-8">
                <input
                  ref="logo"
                  type="file"
                  name="logo"
                > <br>
                <template v-if="company.logo">
                  <render-image-tag :src="company.logo ? `${baseUrl}/${company.logo}` : null" />
                </template>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-8 col-sm-offset-4">
                <template v-if="button.handle==='create'">
                  <button
                    :class="`btn btn-${button.btnClass} btn-sm`"
                    @click="storeCompany"
                  >
                    {{ button.label }}
                  </button>
                </template>
                <template v-else>
                  <button
                    :class="`btn btn-${button.btnClass} btn-sm`"
                    @click="storeCompany"
                  >
                    {{ button.label }}
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="modeEdit"
      class="col-md-6"
    >
      <upload-media
        model-name="company"
        preview-column="col-md-4"
        :button-label="$t('label.chooseReference')"
        :model-uuid="$route.params.company_uuid"
      />
    </div>
    <portal to="modal">
      <CreatePeopleModal
        @setNewPeople="setNewPeople"
      />
    </portal>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {debounce} from 'debounce'
import LocationInput from "@/components/LocationInput"
import RenderImageTag from "@/components/RenderImageTag"
import UploadMedia from "@/components/UploadMedia"
import PeopleChipItem from "@/components/PeopleChipItem"
import FiledIsRequired from "@/components/FiledIsRequired"
import CreatePeopleModal from "@/views/company/includes/CreatePeopleModal"
import {mapFields} from "vuex-map-fields"
import PublishCompanyButton from "@/components/PublishCompanyButton"

export default {
  name: "CompanyForm",
  components: {
    PublishCompanyButton,
    CreatePeopleModal,
    FiledIsRequired,
    PeopleChipItem,
    UploadMedia,
    RenderImageTag,
    LocationInput,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    button: {
      type: Object,
      required: true
    },
    modeEdit: {
      type: Boolean,
      default: false
    },
    modeShow: {
      type: Boolean,
      default: false
    },
    specialistType: {
      type: String,
      required: true,
    },
    companyCategoryIds: {
      type: Array,
      required: true,
    },
    investorCompany: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      company: {
        lat: 11.576310,
        lng: 104.923104,
        country_id: null,
        nationality_id: null,
        owners: [],
        cto: [],
        technicians: []
      },
      validations: {},
      params: new FormData(),
      nationalities: [],
      countries: [],
      people: [],
      companyTypes: [],
      companySections: []
    }
  },
  computed: {
    ...mapState({
      address: state => state.location.address
    }),
    labelColumns() {
      return this.modeEdit ? "col-md-12" : "col-sm-3 control-label"
    },
    inputColumns() {
      return this.modeEdit ? "col-md-12" : "col-sm-9"
    },
    containerColumn() {
      return this.modeEdit ? 'col-md-6' : 'col-md-12'
    },
    urlAction() {
      return this.modeEdit ? 'update' : 'create'
    },
    ...mapFields('constructionCertifierLicense', {
      modalTitle: 'createPeopleModal.title',
      modalPeopleCategories: 'createPeopleModal.people_categories',
      modalPeopleType: 'createPeopleModal.people_type',
      modalNewPeople: 'createPeopleModal.new_people',
    })
  },
  created() {
    this.getCompanySections()
    this.getCompanyTypes()
    this.getCountries()
    this.getNationalities()
    this.searchOwners('')
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty('company_uuid')) {
      this.getCompany()
    }
  },
  methods: {
    searchOwners: debounce(function (query) {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/people/remote-option', {
        query_text: query
      }).then((res) => {
        if (res.data.data.length > 0) {
          this.people = res.data.data
        } else {
          this.people = []
        }
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      })
    }, 200),
    movePinOnGoogleMap(e) {
      let latLng = e.latLng
      this.company.lat = parseFloat(parseFloat(latLng.lat()).toFixed(6))
      this.company.lng = parseFloat(parseFloat(latLng.lng()).toFixed(6))
    },
    storeCompany() {
      this.validations = {}
      if (this.company.uuid) {
        this.company.company_uuid = this.company.uuid
      }
      this.prepareParams()
      this.$isLoading(true)
      this.$axios.post(`${process.env.VUE_APP_API}/api/backend/company/${this.urlAction}`, this.params)
        .then(() => {
          this.showToastr()
          this.$router.back()
        })
        .catch((error) => {
          if (error.response && error.response.status === 422) {
            this.validations = error.response.data.errors
            this.$store.commit('location/setValidations', error.response.data.errors)
          } else {
            this.$store.commit('location/setValidations', {})
            this.validations = {}
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    prepareParams() {
      this.params.set('investor_company', this.investorCompany)
      this.companyCategoryIds.forEach((category, key) => {
        this.params.set(`company_category_ids[${key}]`, category)
      })

      for (let key in this.company) {
        let value = typeof (this.company[key]) === 'string' ? (this.company[key]).trim() : this.company[key]
        if (value === null || value === '') {
          this.params.delete(key)
        } else {
          if (key === 'owners' && value.length > 0) {
            value.forEach((item, i) => {
              this.params.set(`${key}[${i}]`, item.id)
            })
          } else if (key === 'first_registration_date') {
            if (this.$moment(value).isValid()) {
              this.params.set('first_registration_date', this.$moment(value).locale('en').format('YYYY-MM-DD'))
            } else {
              this.params.delete(key)
            }
          } else if (key === 'certificate_of_mef_date') {
            if (this.$moment(value).isValid()) {
              this.params.set('certificate_of_mef_date', this.$moment(value).locale('en').format('YYYY-MM-DD'))
            } else {
              this.params.delete(key)
            }
          } else if (key === 'nationality') {
            this.params.set('nationality_id', value.id)
          } else if (key === 'country') {
            this.params.set('country_id', value.id)
          } else if (key === 'company_type') {
            this.params.set('company_type_id', value.id)
          } else if (key === 'province') {
            this.params.set('province_id', value.id)
          } else if (key === 'commune') {
            this.params.set('commune_id', value.id)
          } else if (key === 'district') {
            this.params.set('district_id', value.id)
          } else if (key === 'village') {
            this.params.set('village_id', value.id)
          } else if (key === 'company_section') {
            this.params.set('company_section_id', value.id)
          } else if (key === 'ceo' && value.length > 0) {
            value.forEach((item, i) => {
              this.params.set(`${key}[${i}]`, item.id)
            })
          } else if (key === 'cto' && value.length > 0) {
            value.forEach((item, i) => {
              this.params.set(`${key}[${i}]`, item.id)
            })
          } else if (key === 'project_managers' && value.length > 0) {
            value.forEach((item, i) => {
              this.params.set(`${key}[${i}]`, item.id)
            })
          } else if (key === 'technicians' && value.length > 0) {
            value.forEach((item, i) => {
              this.params.set(`${key}[${i}]`, item.id)
            })
          } else {
            this.params.set(key, value)
          }
        }
      }

      if (this.$refs.logo.files.length > 0) {
        this.params.set('logo', this.$refs.logo.files[0])
      } else {
        this.params.delete('logo')
      }

      for (let key in this.address) {
        if (this.address[key] !== null && this.address[key] !== '') {
          this.params.set(key, this.address[key])
        } else {
          this.params.delete(key)
        }
      }
      this.removeUnusedParams()
    },
    removeUnusedParams() {
      let excludes = [
        'nationality',
        'village',
        'district',
        'commune',
        'province',
        'ceos',
        'ctos',
        'ctos',
        'country',
        'ministry_registrations',
      ]
      for (let pair of this.params.entries()) {
        if (excludes.includes(pair[0])) {
          this.params.delete(pair[0])
        }
      }
    },
    getCompany() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/company/show', {
        company_uuid: this.$route.params.company_uuid,
      }).then(({data}) => {
        if (data.data) {
          const company = data.data

          if (company.lat !== null) {
            company.lat = parseFloat(company.lat)
          } else {
            company.lat = this.company.lat
          }
          if (company.lng !== null) {
            company.lng = parseFloat(company.lng)
          } else {
            company.lng = this.company.lng
          }
          this.company = company

          // set first registration date
          if (this.company.first_registration_date && this.$moment(this.company.first_registration_date).isValid()) {
            this.$set(this.company, 'first_registration_date', this.$moment(this.company.first_registration_date).locale('en').toDate())
          }

          if (this.company.certificate_of_mef_date && this.$moment(this.company.certificate_of_mef_date).isValid()) {
            this.$set(this.company, 'certificate_of_mef_date', this.$moment(this.company.certificate_of_mef_date).locale('en').toDate())
          }

          this.$set(this.company, 'ceo', [])
          if (company.ceos && Array.isArray(company.ceos) && company.ceos.length > 0) {
            this.company.ceo = company.ceos
          }

          this.$set(this.company, 'cto', company.ctos || [])

          this.$set(this.company, 'technicians', company.technicians || [])

          this.$store.commit('location/setAddress', {
            province_id: this.company.province_id,
            district_id: this.company.district_id,
            commune_id: this.company.commune_id,
            village_id: this.company.village_id,
            home_number: this.company.home_number,
            street_number: this.company.street_number,
            group_number: this.company.group_number,
            reload: true,
          })
        }
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      })
    },
    getNationalities() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/nationality/get-nationalities')
        .then((res) => {
          this.nationalities = res.data.data
        }).catch((error) => {
          this.onResponseError(error)
          this.$router.back()
        })
    },
    getCountries() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/country/get-countries')
        .then((res) => {
          this.countries = res.data.data
        }).catch((error) => {
          this.onResponseError(error)
          this.$router.back()
        })
    },
    getCompanyTypes: debounce(function (query) {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/company-type/get-company-types', {
        query_text: query,
      }).then((res) => {
        if (res.data.data.length > 0) {
          this.companyTypes = res.data.data
        } else {
          this.companyTypes = []
        }
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      })
    }, 200),
    getCompanySections: debounce(function (query) {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/company-section/get-company-sections', {
        query_text: query
      }).then((res) => {
        if (res.data.data.length > 0) {
          this.companySections = res.data.data
        } else {
          this.companySections = []
        }
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.back()
      })
    }),
    addOwnerPeople () {
      this.modalTitle = this.$t('string.create_owner_people')
      this.modalPeopleCategories = [1]
      this.modalPeopleType = 'owner'
      $('#createPeopleModal').modal('show')
    },
    addCtoPeople () {
      this.modalTitle = this.$t('string.create_cto_people')
      this.modalPeopleCategories = [2]
      this.modalPeopleType = 'cto'
      $('#createPeopleModal').modal('show')
    },
    addTechnicianPeople () {
      this.modalTitle = this.$t('string.create_technician_people')
      this.modalPeopleCategories = [2]
      this.modalPeopleType = 'technician'
      $('#createPeopleModal').modal('show')
    },
    setNewPeople () {
      switch (this.modalPeopleType) {
      case 'owner':
        this.company.owners.push(this.modalNewPeople)
        break
      case 'cto':
        this.company.cto.push(this.modalNewPeople)
        break
      case 'technician':
        this.company.technicians.push(this.modalNewPeople)
        break
      }
    }
  },
}
</script>

<style scoped>
.no-margin-bottom {
  margin-bottom: 0 !important;
}

.btn-remove {
  background: var(--danger) !important;
  color: var(--white) !important;
  border-color: var(--danger) !important;
}
</style>
