<template>
  <div class="row">
    <div :class="containerColumn">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ title }}
          </h3>
          <div class="box-tools">
            <asora-back-button />
          </div>
        </div>
        <div class="box-body">
          <div class="form-horizontal">
            <div
              :class="firstNameValidation.required?' has-error' : null"
              class="form-group row"
            >
              <label class="col-sm-3 control-label required">{{ $t('label.nameInKm') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="officer.name_km"
                  :placeholder="$t('label.nameInKm')"
                  class="form-control"
                  type="text"
                >
                <template v-if="firstNameValidation.required">
                  <span class="label-error">{{ firstNameValidation.message }}</span>
                </template>
              </div>
            </div>

            <div
              :class="lastNameValidation.required?' has-error' : null"
              class="form-group row"
            >
              <label class="col-sm-3 control-label required">{{ $t('label.nameInEn') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="officer.name_en"
                  :placeholder="$t('label.nameInEn')"
                  class="form-control"
                  type="text"
                >
                <template v-if="lastNameValidation.required">
                  <span class="label-error">{{ lastNameValidation.message }}</span>
                </template>
              </div>
            </div>

            <div
              :class="titleValidation.required?' has-error' : null"
              class="form-group row"
            >
              <label class="col-sm-3 control-label required">{{ $t('label.title') }}</label>
              <div class="col-sm-9">
                <select
                  v-model="officer.title_id"
                  class="form-control"
                >
                  <option
                    v-for="(_title, key) in titles"
                    :key="key"
                    :value="_title.id"
                  >
                    {{ _title['name_' + $i18n.locale] }}
                  </option>
                </select>
                <template v-if="titleValidation.required">
                  <span class="label-error">{{ titleValidation.message }}</span>
                </template>
              </div>
            </div>

            <div
              :class="genderValidation.required?' has-error' : null"
              class="form-group row"
            >
              <label class="col-sm-3 control-label required">{{ $t('label.gender') }}</label>
              <div class="col-sm-9">
                <multiselect
                  v-model="officer.gender_id"
                  :label="`name_${$i18n.locale}`"
                  :options="genders"
                  :placeholder="$t('string.chooseGender')"
                  track-by="id"
                />
                <template v-if="genderValidation.required">
                  <span class="label-error">{{ genderValidation.message }}</span>
                </template>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.phone') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="officer.phone"
                  :placeholder="$t('label.phone')"
                  class="form-control"
                  type="tel"
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.email') }}</label>
              <div class="col-sm-9">
                <input
                  v-model="officer.email"
                  :placeholder="$t('label.email')"
                  class="form-control"
                  type="email"
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('table.ministryOrProvinceOfficer') }}</label>
              <div class="col-sm-9">
                <select
                  v-model="officer.imported_from"
                  class="form-control"
                >
                  <option
                    v-for="(option, key) in ministryOrProvince"
                    :key="key"
                    :value="option.value"
                  >
                    {{ option.label }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.officeOrWorkplace') }}</label>
              <div class="col-sm-9">
                <multiselect
                  v-model="officer.place_id"
                  :label="`name_${$i18n.locale}`"
                  :options="places"
                  :placeholder="$t('string.chooseOffice')"
                  track-by="id"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.position') }}</label>
              <div class="col-sm-9">
                <multiselect
                  v-model="officer.position_id"
                  :disabled="!officer.place_id"
                  :label="`name_${$i18n.locale}`"
                  :options="positions"
                  :placeholder="$t('string.choosePosition')"
                  track-by="id"
                />
              </div>
            </div>

            <location-input />

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.picture') }}</label>
              <div class="col-sm-9">
                <input
                  ref="avatar"
                  name="avatar"
                  type="file"
                > <br>
                <template v-if="officer.avatar">
                  <render-image-tag :src="officer.avatar ? `${baseUrl}/${officer.avatar}` : null" />
                </template>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-offset-3 col-sm-9">
                <h3 class="main-title font-size-16">
                  {{ $t('string.connectWithUser') }}
                </h3>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 control-label">{{ $t('label.user') }}</label>
              <div class="col-sm-9">
                <multiselect
                  v-model="officer.user_id"
                  :options="users"
                  :placeholder="$t('string.chooseUser')"
                  label="name"
                  track-by="id"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-9 col-sm-offset-3">
                <template v-if="button.handle==='create'">
                  <button
                    :class="`btn btn-${button.btnClass} btn-sm`"
                    @click="storeOfficer"
                  >
                    {{ button.label }}
                  </button>
                </template>
                <template v-else>
                  <button
                    :class="`btn btn-${button.btnClass} btn-sm`"
                    @click="storeOfficer"
                  >
                    {{ button.label }}
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="editForm"
      class="col-md-6"
    >
      <upload-media
        :button-label="$t('label.chooseReference')"
        :model-uuid="$route.params.officer_uuid"
        model-name="officer"
        preview-column="col-md-4"
      />
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import LocationInput from "../../components/LocationInput"
import RenderImageTag from "../../components/RenderImageTag"
import UploadMedia from "../../components/UploadMedia"

export default {
  name: "OfficerFrom",
  components: {
    UploadMedia,
    RenderImageTag,
    LocationInput,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    button: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      officer: {
        officer_category_id: 3,
      },
      validations: null,
      params: new FormData(),
      places: [],
      users: [],
      titles: [],
      positions: [],
    }
  },
  computed: {
    ministryOrProvince() {
      return [
        {value: 'ministry', label: this.$t('string.ministry')},
        {value: 'province', label: this.$t('string.department')},
      ]
    },
    firstNameValidation() {
      return this.getValidationColumn(this.validations, 'name_km')
    },
    lastNameValidation() {
      return this.getValidationColumn(this.validations, 'name_en')
    },
    genderValidation() {
      return this.getValidationColumn(this.validations, 'gender_id')
    },
    titleValidation() {
      return this.getValidationColumn(this.validations, 'title_id')
    },
    genders() {
      return [
        {id: 1, name_en: 'Male', name_km: 'ប្រុស'},
        {id: 2, name_en: 'Female', name_km: 'ស្រី'},
      ]
    },
    ...mapState({
      address: state => state.location.address
    }),
    labelColumns() {
      return this.$route.name === 'edit-officer' ? "col-md-12" : "col-sm-3 control-label"
    },
    inputColumns() {
      return this.$route.name === 'edit-officer' ? "col-md-12" : "col-sm-9"
    },
    containerColumn() {
      return this.$route.name === 'edit-officer' ? 'col-md-6' : 'col-md-12'
    },
    editForm() {
      return this.$route.name === 'edit-officer'
    },
    urlAction() {
      return this.editForm ? 'update' : 'create'
    }
  },
  created() {
    this.getTitle()
    this.getPlaces()
    this.getUsers()
    this.getPosition()
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty('officer_uuid')) {
      this.getOfficer()
    }
  },
  methods: {
    storeOfficer() {
      this.prepareParams()
      this.$isLoading(true)
      this.$axios.post(`${process.env.VUE_APP_API}/api/backend/officer/${this.urlAction}`, this.params)
        .then((res) => {
          this.showToastr()
          if (this.can(this.$Permissions.backend.officer.edit)) {
            this.$router.push({
              name: 'edit-officer',
              params: {
                officer_uuid: res.data.data.uuid,
              }
            })
          } else {
            this.$router.push({name: 'list-officer'})
          }
        })
        .catch((error) => {
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
            this.$store.commit('location/setValidations', res.data.errors)
          } else {
            this.$store.commit('location/setValidations', null)
            this.onResponseError(error)
            this.validations = null
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    prepareParams() {
      for (let key in this.officer) {
        let value = this.officer[key]
        if (value === null || value === '') {
          this.params.delete(key)
        } else {
          // eslint-disable-next-line no-prototype-builtins
          if (value.hasOwnProperty('id')) {
            value = value.id
          }
          this.params.set(key, value)
        }
      }

      if (this.$refs.avatar.files.length > 0) {
        this.params.set('avatar', this.$refs.avatar.files[0])
      } else {
        this.params.delete('avatar')
      }

      for (let key in this.address) {
        if (this.address[key] !== '' && this.address[key] !== null) {
          this.params.set(key, this.address[key])
        }
      }

      if (this.officer.uuid) {
        this.params.set('officer_uuid', this.officer.uuid)
      }
    },
    getOfficer() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/officer/show', {
        officer_uuid: this.$route.params.officer_uuid,
      }).then((res) => {
        this.officer = res.data.data
        this.officer.gender_id = this.officer.gender
        if (this.officer.positions && this.officer.positions.length > 0) {
          this.officer.position_id = this.officer.positions[0]
        }
        if (this.officer.place) {
          this.officer.place_id = this.officer.place
        }
        if (this.officer.user) {
          let user = this.officer.user
          this.officer.user_id = {
            id: user.id,
            name: `${user.first_name} ${user.last_name}`,
          }
        }
        this.$store.commit('location/setAddress', {
          province_id: this.officer.province_id,
          district_id: this.officer.district_id,
          commune_id: this.officer.commune_id,
          village_id: this.officer.village_id,
          home_number: this.officer.home_number,
          street_number: this.officer.street_number,
          reload: true,
        })
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-officer'})
      })
    },
    getPlaces() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/office/get-option')
        .then((res) => {
          this.places = res.data.data
        }).catch((error) => {
          this.onResponseError(error)
          this.$router.back()
        })
    },
    getPosition() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/position/get-option')
        .then(({data}) => {
          this.positions = data.data
        }).catch((error) => {
          this.onResponseError(error)
          this.$router.back()
        })
    },
    getUsers() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/officer/get-users')
        .then((res) => {
          this.users = res.data.data
        }).catch((error) => {
          this.onResponseError(error)
          this.$router.back()
        })
    },
    getTitle() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/title/get-option')
        .then((res) => {
          this.titles = res.data.data
        }).catch((error) => {
          this.onResponseError(error)
          this.$router.back()
        })
    },
  }
}
</script>

<style scoped>

</style>
