<template>
  <div class="row">
    <div :class="containerColumn">
      <div class="box box-primary">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ title }}
          </h3>
          <div class="box-tools">
            <asora-back-button />
          </div>
        </div>
        <div class="box-body">
          <div class="form-horizontal">
            <div class="form-group row">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-12 col-md-6">
                    <div
                      :class="provinceIdValidation.required?' has-error' : null"
                      class="form-group row"
                    >
                      <label class="col-sm-2 col-md-4 control-label required">{{ $t('label.province') }}</label>
                      <div class="col-sm-10 col-md-8">
                        <multiselect
                          v-model="constructionData.province"
                          :label="`name_${$i18n.locale}`"
                          :options="provinces"
                          :placeholder="$t('string.chooseProvince')"
                          track-by="id"
                        />
                        <template v-if="provinceIdValidation.required">
                          <span class="label-error">{{ provinceIdValidation.message }}</span>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-6">
                    <div
                      :class="startDateValidation.required ? 'has-error' : null"
                      class="form-group row"
                    >
                      <label class="col-sm-4 control-label required">{{
                        $t('string.startDateConstructionData')
                      }}</label>
                      <div class="col-sm-8">
                        <date-picker
                          v-model="constructionData.start_date"
                          :lang="datepickerLocale[$i18n.locale].lang"
                          :placeholder="$t('string.startDateConstructionData')"
                          class="table-full-width"
                          format="DD/MM/YYYY"
                        />
                        <template v-if="startDateValidation.required">
                          <span class="label-error">{{ startDateValidation.message }}</span>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div
                      :class="endDateValidation.required?' has-error' : null"
                      class="form-group row"
                    >
                      <label class="col-sm-4 control-label required">{{ $t('string.endDateConstructionData') }}</label>
                      <div class="col-sm-8">
                        <date-picker
                          v-model="constructionData.end_date"
                          :lang="datepickerLocale[$i18n.locale].lang"
                          :placeholder="$t('string.endDateConstructionData')"
                          class="table-full-width"
                          format="DD/MM/YYYY"
                        />
                        <template v-if="endDateValidation.required">
                          <span class="label-error">{{ endDateValidation.message }}</span>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <template
              v-if="constructionData.project_provincial_level_data && constructionData.project_provincial_level_data.length > 0"
            >
              <template v-for="(item, key) in constructionData.project_provincial_level_data">
                <div :key="key">
                  <div class="row">
                    <div class="col-sm-12 col-md-6">
                      <div class="col-sm-10 col-sm-offset-2 col-md-8 col-md-offset-4">
                        <h4 class="main-title">
                          {{ item['name_' + $i18n.locale] ? item['name_' + $i18n.locale] : null }}
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-sm-12">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group row">
                            <label class="col-sm-4 control-label">{{ $t('label.has_rule') }}</label>
                            <div class="col-sm-8">
                              <input
                                v-model="item.has_rule"
                                class="form-control"
                                type="number"
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group row">
                            <label class="col-sm-4 control-label">{{ $t('label.no_rule') }}</label>
                            <div class="col-sm-8">
                              <input
                                v-model="item.no_rule"
                                class="form-control"
                                type="number"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group row">
                            <label class="col-sm-4 control-label">{{ $t('table.totalFloorArea') }}</label>
                            <div class="col-sm-8">
                              <div class="input-group">
                                <input
                                  v-model="item.area"
                                  class="form-control"
                                  type="number"
                                >
                                <span class="input-group-addon bg-gray">m<sup>2</sup></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group row">
                            <label class="col-sm-4 control-label">{{ $t('label.ProjectPrice') }}</label>
                            <div class="col-sm-8">
                              <div class="input-group">
                                <vue-numeric
                                  v-model="item.investment_budget"
                                  :max="99999999999999999999"
                                  :min="0"
                                  :placeholder="$t('label.investmentBudget')"
                                  :precision="2"
                                  class="form-control"
                                  decimal-separator="."
                                  separator=","
                                />
                                <span class="input-group-addon bg-gray">{{ $t('currency.usd') }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12">
                      <div class="row">
                        <div class="col-sm-6">
                          <div class="form-group row">
                            <label class="col-sm-4 control-label">{{ $t('label.nbBuildingOrHouse') }}</label>
                            <div class="col-sm-8">
                              <input
                                v-model="item.number_building"
                                class="form-control"
                                type="number"
                              >
                            </div>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="form-group row">
                            <label class="col-sm-4 control-label">{{ $t('label.nbWorkers') }}</label>
                            <div class="col-sm-8">
                              <input
                                v-model="item.number_worker"
                                class="form-control"
                                type="number"
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <div class="form-group row">
              <div class="col-sm-10 col-sm-offset-2">
                <template v-if="button.handle==='create'">
                  <button
                    :class="`btn btn-${button.btnClass} btn-sm`"
                    @click="store"
                  >
                    {{ button.label }}
                  </button>
                </template>
                <template v-else>
                  <button
                    :class="`btn btn-${button.btnClass} btn-sm`"
                    @click="store"
                  >
                    {{ button.label }}
                  </button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConstructionDataForm",
  props: {
    title: {
      type: String,
      required: true
    },
    button: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      projectTypes: [],
      projectSubTypes: [],
      validations: [],
      chooseProjectTypes: [],
      chooseProjectSubTypes: [],
      provinces: [],
      constructionData: {
        start_date: null,
        end_date: null,
        province: null,
        project_provincial_level_data: [],
      }
    }
  },
  computed: {
    startDateValidation() {
      return this.getValidationColumn(this.validations, 'start_date')
    },
    endDateValidation() {
      return this.getValidationColumn(this.validations, 'end_date')
    },
    provinceIdValidation() {
      return this.getValidationColumn(this.validations, 'province')
    },
    containerColumn() {
      return this.$route.name === 'edit-officer' ? 'col-md-6' : 'col-md-12'
    },
    editForm() {
      return this.$route.name === 'edit-officer'
    },
    urlAction() {
      return this.editForm ? 'update' : 'create'
    }
  },
  mounted() {
    this.getProvinces()
    this.getProjectTypes()
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route.params.hasOwnProperty('construction_data_uuid')) {
      this.show()
    }
  },
  methods: {
    getProjectTypes() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-data-provincial-level/get-project-type')
        .then((response) => {
          if (response.data.data) {
            this.projectTypes = response.data.data
            this.projectTypes.forEach((item) => {
              if (item.investment_budget === null) {
                item.investment_budget = 0
              }
            })
            this.constructionData.project_provincial_level_data = this.projectTypes
          }
        })
        .catch((error) => {
          this.onResponseError(error)
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getProvinces() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/location/get-location')
        .then((response) => {
          if (response.data.data) {
            this.provinces = response.data.data
          }
        })
        .catch((error) => {
          this.onResponseError(error)
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    store() {
      let start_date = null
      let end_date = null
      if (this.constructionData.start_date) {
        start_date = this.$moment(this.constructionData.start_date).locale('en').format('YYYY-MM-DD')
      }
      if (this.constructionData.end_date) {
        end_date = this.$moment(this.constructionData.end_date).locale('en').format('YYYY-MM-DD')
      }
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-data-provincial-level/store', {
        start_date: start_date,
        end_date: end_date,
        province: this.constructionData.province,
        project_provincial_level_data: this.constructionData.project_provincial_level_data
      }).then((response) => {
        this.showToastr()
        if (this.can(this.$Permissions.backend.provinceConstructionData.edit)) {
          this.$router.push({
            name: 'edit-construction-data',
            params: {
              construction_data_uuid: response.data.data.uuid,
            }
          })
        } else {
          this.$router.push({name: 'list-construction-data'})
        }
      }).catch((error) => {
        let res = error.response
        if (res.status === 422) {
          this.validations = res.data.errors
        } else {
          this.validations = null
        }
        this.onResponseError(error)
      })
    },
    show() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/project-data-provincial-level/show', {
        uuid: this.$route.params.construction_data_uuid
      }).then((res) => {
        this.constructionData = res.data.data
        if (this.constructionData.investment_budget === null) {
          this.constructionData.investment_budget = 0
        }
        this.constructionData.start_date = this.$moment(this.constructionData.start_date).locale('en').toDate()
        this.constructionData.end_date = this.$moment(this.constructionData.end_date).locale('en').toDate()
      }).catch((error) => {
        this.onResponseError(error)
      })
    }
  }
}
</script>

<style scoped>

</style>
