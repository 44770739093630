<template>
  <div class="row">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ProjectFormSection"
}
</script>

<style scoped>

</style>
