import Layout from "../views/account/Layout"
import Index from "../views/account/Index"

export const accountRouters = {
  path: 'account',
  name: 'account',
  component: Layout,
  redirect: 'account',
  children: [
    {
      path: '',
      name: 'profile',
      component: Index
    }
  ]
}
