<template>
  <company-data-table
    :title="$t('menu.check_and_confirm_construction_certifier_company')"
    name-route-create="create-check-and-confirm-construction-company"
    name-route-edit="edit-check-and-confirm-construction-company"
    name-route-show="show-check-and-confirm-construction-company"
    name-route-change-category="change-category-check-and-confirm-construction-company"
    :company-category-ids="[4,5]"
    :permission-create="[
      Permissions.backend.businessBuilders.checkAndConfirmPlanCompany.store,
      Permissions.backend.businessBuilders.checkAndConfirmConstructionCompany.store,
    ]"
    :permission-filter="[
      Permissions.backend.businessBuilders.checkAndConfirmPlanCompany.filter,
      Permissions.backend.businessBuilders.checkAndConfirmConstructionCompany.filter,
    ]"
  />
</template>

<script>
import CompanyDataTable from "../../company/DataTable"
import Permissions from '@/permissions'

export default {
  name: "List",
  components: {CompanyDataTable},
  metaInfo() {
    return {
      title: this.$t('string.checkAndConfirmConstructionCompany'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  data () {
    return {
      Permissions
    }
  }
}
</script>

  <style scoped>

  </style>
