<template>
  <architecture-from
    :button="buttonProperties"
    architecture-type-id="2"
    architecture-category-id="3"
    :title="$t('string.addProfessionalStudyPlanner')"
  />
</template>

<script>
import ArchitectureFrom from "../../architecture/Form"

export default {
  name: "Create",
  components: {ArchitectureFrom},
  metaInfo() {
    return {
      title: this.$t('string.addProfessionalStudyPlanner'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  computed: {
    buttonProperties() {
      return {
        label: this.$t('button.save'),
        btnClass: 'primary',
        handle: 'create'
      }
    }
  }
}
</script>

<style scoped>

</style>
