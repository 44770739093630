import Layout from "../../views/administrations/role/Index"
import List from "../../views/administrations/role/List"
import Create from "../../views/administrations/role/Create"
import Edit from "../../views/administrations/role/Edit"
import Permissions from "../../permissions"

export const roleRouters = {
  path: 'role',
  component: Layout,
  redirect: 'role',
  meta: {
    permissions: [Permissions.backend.administration.role.manage],
    groups: 'role',
  },
  children: [
    {
      path: '',
      name: 'list-role',
      component: List,
      meta: {
        permissions: [Permissions.backend.administration.role.list],
        groups: 'role',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Role List',
            name_km: 'បញ្ជីតួនាទី'
          }
        ]
      }
    },
    {
      path: 'create',
      name: 'create-role',
      component: Create,
      meta: {
        permissions: [Permissions.backend.administration.role.store],
        groups: 'role',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Role List',
            name_km: 'បញ្ជីតួនាទី',
            route: {name: 'list-role'}
          },
          {
            name_en: 'Create Role',
            name_km: 'បន្ថែមតួនាទី'
          }
        ]
      }
    },
    {
      path: 'show/:id',
      name: 'show-role',
      component: Create,
      meta: {
        permissions: [Permissions.backend.administration.role.show],
        groups: 'role',
        breadcrumb: [
          {
            name_en: 'Settings',
            name_km: 'ការកំណត់'
          },
          {
            name_en: 'Role List',
            name_km: 'បញ្ជីតួនាទី',
            route: {name: 'list-role'}
          },
          {
            name_en: 'Role Detail',
            name_km: 'លម្អីតព័ត៌មានតួនាទី'
          }
        ]
      }
    },
    {
      path: 'edit/:id',
      name: 'edit-role',
      component: Edit,
      meta: {
        permissions: [Permissions.backend.administration.role.edit],
        groups: 'role',
        breadcrumb: [
          {
            name_en: 'Administration',
            name_km: 'រដ្ឋបាល'
          },
          {
            name_en: 'Role List',
            name_km: 'បញ្ជីតួនាទី',
            route: {name: 'list-role'}
          },
          {
            name_en: 'Edit Role',
            name_km: 'កែប្រែព័ត៌មានតួនាទី'
          }
        ]
      }
    }
  ]
}
