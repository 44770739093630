<template>
  <div class="form-horizontal">
    <div
      :class="titleKmValidation.required ? ' has-error' : null"
      class="form-group row"
    >
      <label class="col-sm-3 control-label required">{{ $t('label.lawRegulationTitleKm') }}</label>
      <div class="col-sm-9">
        <input
          v-model="lawRegulation.title_km"
          :placeholder="$t('label.lawRegulationTitleKm')"
          class="form-control"
          type="text"
        >
        <template v-if="titleKmValidation.required">
          <span class="label-error">{{ titleKmValidation.message }}</span>
        </template>
      </div>
    </div>

    <div
      :class="titleEnValidation.required ? ' has-error' : null"
      class="form-group row"
    >
      <label class="col-sm-3 control-label required">{{ $t('label.lawRegulationTitleEn') }}</label>
      <div class="col-sm-9">
        <input
          v-model="lawRegulation.title_en"
          :placeholder="$t('label.lawRegulationTitleEn')"
          class="form-control"
          type="text"
        >
        <template v-if="titleEnValidation.required">
          <span class="label-error">{{ titleEnValidation.message }}</span>
        </template>
      </div>
    </div>

    <div
      :class="lawRegulationTypeValidation.required ? ' has-error' : null"
      class="form-group row"
    >
      <label class="col-sm-3 control-label required">{{ $t('label.lawRegulationType') }}</label>
      <div class="col-sm-9">
        <multiselect
          v-model="lawRegulation.law_regulation_type"
          :label="`name_${$i18n.locale}`"
          :options="lawRegulationTypes"
          :placeholder="$t('label.lawRegulationType')"
        />
        <template v-if="lawRegulationTypeValidation.required">
          <span class="label-error">{{ lawRegulationTypeValidation.message }}</span>
        </template>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-3 control-label">{{ $t('label.issuedBy') }}</label>
      <div class="col-sm-9">
        <input
          v-model="lawRegulation.issued_by"
          :placeholder="$t('label.issuedBy')"
          class="form-control"
          type="text"
        >
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-3 control-label">{{ $t('label.issuedNumber') }}</label>
      <div class="col-sm-9">
        <input
          v-model="lawRegulation.issued_number"
          :placeholder="$t('label.issuedNumber')"
          class="form-control"
          type="text"
        >
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-3 control-label">{{ $t('label.effectiveDate') }}</label>
      <div class="col-sm-9">
        <date-picker
          v-model="lawRegulation.effective_date"
          :lang="datepickerLocale[$i18n.locale].lang"
          :placeholder="$t('label.effectiveDate')"
          class="table-full-width"
          format="DD/MM/YYYY"
          type="date"
        />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-3 control-label">{{ $t('label.description') }}</label>
      <div class="col-sm-9">
        <textarea
          v-model="lawRegulation.description"
          :placeholder="$t('label.description')"
          class="form-control"
          rows="3"
        />
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-3 control-label">{{ $t('label.isPublished') }}</label>
      <div class="col-sm-9">
        <label>
          <input
            v-model="lawRegulation.is_published"
            :value="true"
            type="checkbox"
          >
        </label>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-3 control-label">{{ $t('label.resource') }}</label>
      <div class="col-sm-9">
        <input
          ref="resource"
          accept="application/pdf"
          name="resource"
          type="file"
        >
      </div>
    </div>

    <template v-if="lawRegulation.resource && lawRegulation.resource!==null">
      <div class="form-group row">
        <label class="col-sm-3 control-label" />
        <div class="col-sm-9">
          <a
            :href="`${baseUrl}/${lawRegulation.resource}`"
            class="text-red"
            target="_blank"
          >
            <i class="fas fa-5x fa-file-pdf" />
          </a>
        </div>
      </div>
    </template>

    <div class="form-group row">
      <div class="col-sm-9 col-sm-offset-3">
        <button
          class="btn btn-primary btn-sm"
          @click="storeLawRegulation"
        >
          {{ $t('button.save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LawRegulationForm",
  data() {
    return {
      lawRegulation: {},
      validations: null,
      lawRegulationTypes: [],
    }
  },
  computed: {
    titleKmValidation() {
      return this.getValidationColumn(this.validations, 'title_km')
    },
    titleEnValidation() {
      return this.getValidationColumn(this.validations, 'title_en')
    },
    lawRegulationTypeValidation() {
      return this.getValidationColumn(this.validations, 'law_regulation_type_id')
    },
    urlAction() {
      return this.$route.name === 'create-law-regulation' ? 'create' : 'update'
    }
  },
  mounted() {
    this.getLawRegulationType()
    if (this.$route.name === 'edit-law-regulation') {
      this.getLawRegulation()
    }
  },
  methods: {
    storeLawRegulation() {
      let params = new FormData()
      for (let key in this.lawRegulation) {
        if (this.lawRegulation[key] !== '' && this.lawRegulation[key] !== null) {
          if (key === 'law_regulation_type') {
            params.set('law_regulation_type_id', this.lawRegulation[key]['id'])
          } else if (key === 'effective_date' && this.$moment(this.lawRegulation[key]).isValid()) {
            params.set(key, this.$moment(this.lawRegulation[key]).locale('en').format('YYYY-MM-DD'))
          } else if (key === 'uuid') {
            params.set('law_regulation_uuid', this.lawRegulation[key])
          } else {
            params.set(key, this.lawRegulation[key])
          }
        } else {
          params.delete(key)
        }
      }
      if (this.$refs.resource.files.length > 0) {
        params.set('resource', this.$refs.resource.files[0])
      }
      this.$isLoading(true)
      this.$axios.post(`${process.env.VUE_APP_API}/api/backend/law-regulation/${this.urlAction}`, params)
        .then(() => {
          this.showToastr()
          this.$router.push({name: 'list-law-regulation'})
        })
        .catch((error) => {
          let res = error.response
          if (res.status === 422) {
            this.validations = res.data.errors
          } else {
            this.validations = null
            this.onResponseError(error)
          }
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getLawRegulationType() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/law-regulation-type/get-option')
        .then((res) => {
          this.lawRegulationTypes = res.data.data
        })
        .catch(() => {
          this.$router.push({name: 'list-law-regulation'})
        })
        .finally(() => {
          this.$isLoading(false)
        })
    },
    getLawRegulation() {
      this.$isLoading(true)
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/law-regulation/show', {
        law_regulation_uuid: this.$route.params.law_regulation_uuid,
      }).then((res) => {
        let data = res.data.data
        this.lawRegulation = data
        if (data.effective_date !== null) {
          this.$set(this.lawRegulation, 'effective_date', this.$moment(data.effective_date).toDate())
        }
        if (data.type !== null) {
          this.$set(this.lawRegulation, 'law_regulation_type', data.type)
        }
      }).catch((error) => {
        this.onResponseError(error)
        this.$router.push({name: 'list-law-regulation'})
      }).finally(() => {
        this.$isLoading(false)
      })
    }
  }
}
</script>

<style scoped>

</style>
