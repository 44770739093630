<template>
  <div class="row">
    <div class="col-md-12">
      <div class="nav-tabs-custom">
        <ul
          class="nav nav-tabs"
          role="tablist"
        >
          <li
            role="presentation"
            class="active"
          >
            <a
              href="#roubavontbokkolFormTab"
              aria-controls="roubavontbokkolFormTab"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.roubavontbokkolFormTab') }}
            </a>
          </li>
          <li role="presentation">
            <a
              href="#docs"
              aria-controls="docs"
              role="tab"
              data-toggle="tab"
            >
              {{ $t('string.documentLetterTab') }}
            </a>
          </li>
          <li class="pull-right">
            <asora-back-button />
          </li>
        </ul>
        <div class="tab-content">
          <div
            id="roubavontbokkolFormTab"
            role="tabpanel"
            class="tab-pane active"
          >
            <roubavontbokkols-from />
          </div>
          <div
            id="docs"
            role="tabpanel"
            class="tab-pane"
          >
            <upload-media
              model-name="architecture"
              preview-column="col-md-2"
              :button-label="$t('label.chooseReference')"
              :model-uuid="$route.params.roubavontbokkols_uuid"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadMedia from "../../../components/UploadMedia"
import RoubavontbokkolsFrom from "./Form"

export default {
  name: "Edit",
  metaInfo() {
    return {
      title: this.$t('string.editRoubavontbokkol'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  components: {
    RoubavontbokkolsFrom,
    UploadMedia,
  }
}
</script>

<style scoped>

</style>
