<template>
  <officer-from
    :button="{
      label: $t('button.save'),
      btnClass: 'primary',
      handle: 'create'
    }"
    :title="$t('string.newOfficer')"
  />
</template>

<script>
import OfficerFrom from "./Form"

export default {
  name: "Create",
  components: {OfficerFrom},
  metaInfo() {
    return {
      title: this.$t('string.newOfficer'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
}
</script>

<style scoped>

</style>
