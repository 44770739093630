<template>
  <div
    v-if="activityLog!==null"
    class="box box-primary"
  >
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.activityLogs') }}
      </h3>
      <div class="box-tools">
        <template v-if="isProject">
          <button
            class="btn btn-primary btn-sm margin-r-5"
            href=""
            @click="onClickOpenNewTab({
              name: 'show-project',
              params: {
                project_uuid: activityLog.subject.uuid
              }
            })"
          >
            <i class="fa fa-search" /> {{ $t('string.view') }}
          </button>
        </template>
        <asora-back-button />
      </div>
    </div>
    <div class="box-body">
      <template v-if="activityLog!==null">
        <table class="table table-bordered table-condensed table-hover table-striped">
          <tbody>
            <tr>
              <th class="tw-[200px]">
                Subject Type
              </th>
              <td>{{ activityLog.subject_type }}</td>
            </tr>
            <tr>
              <th class="tw-[200px]">
                Action
              </th>
              <td>{{ activityLog.log_name }}</td>
            </tr>
            <tr>
              <th class="tw-[200px]">
                Description
              </th>
              <td>{{ activityLog.description }}</td>
            </tr>
            <tr>
              <th class="tw-[200px]">
                Properties
              </th>
              <td>
                <vue-code-highlight>
                  {{ activityLog.properties || $t('string.na') }}
                </vue-code-highlight>
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </div>
  </div>
</template>

<script>
import {component as VueCodeHighlight} from 'vue-code-highlight'

export default {
  name: "Show",
  metaInfo() {
    return {
      title: this.$t('string.activityLogs'),
      titleTemplate: '%s | ' + this.$t('string.appName'),
    }
  },
  components: {VueCodeHighlight},
  data() {
    return {
      activityLog: null,
    }
  },
  computed: {
    isProject() {
      if (this.activityLog) {
        const {subject_type} = this.activityLog
        return subject_type === 'App\\Models\\Project'
      }
      return false
    }
  },
  mounted() {
    this.getLogs()
  },
  methods: {
    getLogs() {
      this.$axios.post(process.env.VUE_APP_API + '/api/backend/activity-log/show', {
        id: this.$route.params.id,
      }).then((res) => {
        this.activityLog = res.data.data
      }).catch((error) => {
        this.onResponseError(error)
      })
    }
  }
}
</script>

<style scoped>

</style>
